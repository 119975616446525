import { BookingRules } from './../../models/new-activity/activity-availability-response.model';
import { ActivityOption } from './../../models/new-activity/activity-option.model';
import { ProductHandlerService } from './../../services/product-handler.service';
import { Router } from '@angular/router';
import { DataService } from 'src/app/services/data.service';
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { HotelAvailabilityOptions } from 'src/app/models/new-hotel/hotel-availability-response.model';
import { IOptions, ECardType } from '../card/models/options.model';
import { RequestHandlerService } from 'src/app/services/request-handler.service';
import { ActivityResult } from 'src/app/models/new-activity/activity-availability-response.model';
import { EProduct } from '../searched/models/searched.model';
import { DataStoreService } from '../../services/data-store.service';
import { AlertErrorComponent } from './alert-error/alert-error.component';

@Component({
  selector: 'app-product-detail',
  templateUrl: './product-detail.component.html',
  styleUrls: ['./product-detail.component.scss']
})
export class ProductDetailComponent implements OnInit {

  isDescriptionShowingMore = false;
  
  constructor(
    public dialogRef: MatDialogRef<ProductDetailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private productHandlerService: ProductHandlerService,
    private requestHandlerService: RequestHandlerService,
    private router: Router,
    private dataStore: DataStoreService,
    private dataService: DataService,
    public dialog: MatDialog

  ) { }

  get stars() {
    return parseInt((this.product as HotelAvailabilityOptions).category);
  }

  get product(): any {
    return this.productHandlerService.selectedProduct;
  }

  get productType() {
    return this.productHandlerService.productType;
  }

  get selectedRadioOption() {
    return this.product.selectedOption.selectedMeetingPoint;
  }

  get cardOptions(): IOptions {
    const cardType = this.productHandlerService.productType === EProduct.ACTIVITIES ? 'layout-three' : ECardType.ROOMS;
    return {
      type: cardType,
      hasTitle: true,
      hasRefundable: true,
      isViewDetail: true,
      selectedRadioOption: this.selectedRadioOption,
    };
  }

  get isValidActivity() {
    if (this.productType === EProduct.ACTIVITIES) {
      return this.product.selectedOption.bookingCode && this.product.selectedOption.serviceCode;
    } else {
      return true;
    }
  }

  ngOnInit() {
  }

  close() {
    this.dialogRef.close();
  }

  cardListener(ev) {
    this.product.selectedOption = ev;
    this.productHandlerService.selectedProduct = this.product;
  }

  continue() {
    const productType = this.productHandlerService.productType;
    if (productType === EProduct.HOTELS) {
      this.requestHandlerService.sendBookingRulesRequest(this.requestHandlerService.bookingRulesRequest).then(res => {
        this.product.bookingRules = res;
        this.productHandlerService.selectedProduct = this.product;
        this.router.navigate([productType + '/registry']);
        this.close();
      }).catch(err => {
        const heightPadding = this.dataStore.isMobileDevice ? '0px' : '40px';
      const widthPadding = this.dataStore.isMobileDevice ? '0px' : '16vw';
      const EProductSelected = this.dataService.productSelected;
      this.dialog.open(AlertErrorComponent, {
        maxWidth: '43vw',
        width: 'calc(50vw - ' + widthPadding + ')',
        height: 'calc(50vh - ' + heightPadding + ')',
        minHeight: '54vh',
        data: {
          tittle: 'ALERT.ERROR_BOOKING.title',
          error: 'ALERT.ERROR_BOOKING.boddy'
        }
      });
      });
    } else {
      if (this.isValidActivity) {
        this.close();
        this.router.navigate([productType + '/registry']);
      }
    }
  }

  getProductRadio(ev) {
    if (this.productType === EProduct.ACTIVITIES) {
      this.setActivityMeetingPoint(ev);
    }
  }

  getProductDropdown(ev: any) {
    if (this.productType === EProduct.ACTIVITIES) {
      ev.product.selectedDate = ev.option;
      this.product.selectedOption = ev.product;
      this.productHandlerService.selectedProduct = this.product;
      this.getActivityBookingRules();
    }
  }

  setActivityMeetingPoint(option) {
    this.product.selectedOption = (this.product as ActivityResult).options.find(item => {
      if (item.id === option.product.id) {
        item.selectedMeetingPoint = option.option;
      }
      return item.id === option.product.id;
    });
    this.productHandlerService.selectedProduct = this.product;
  }

  getActivityBookingRules() {
    const request = this.requestHandlerService.bookingRulesRequest;
    this.requestHandlerService.sendBookingRulesRequest(request).then((bookingRules: BookingRules) => {

      const product = (this.product as ActivityResult);
      product.bookingRules = bookingRules;
      const selectedOption = product.options.find(item => {
        if (item.id === product.selectedOption.id) {
          if (product.bookingRules.meetingPoints) {
            item.meetingPoint = product.bookingRules.meetingPoints;
            item.selectedMeetingPoint = item.meetingPoint[0];
            item.bookingCode = product.bookingRules.bookingCode;
            item.serviceCode = product.bookingRules.serviceCode;
          }
          item.cancellationPolicy = product.bookingRules.cancellationPolicy;
          return item;
        }
      });
      product.selectedOption = selectedOption;
      product.selectedOption.selectedDate.price.totalPrice = bookingRules.totalPrice;
      product.selectedOption.selectedDate.price.providerTotalPrice = bookingRules.providerTotalPrice;
      this.productHandlerService.selectedProduct = product;

    }).catch(err => {
      const heightPadding = this.dataStore.isMobileDevice ? '0px' : '40px';
      const widthPadding = this.dataStore.isMobileDevice ? '0px' : '16vw';
      const EProductSelected = this.dataService.productSelected;
      this.dialog.open(AlertErrorComponent, {
        maxWidth: '50vw',
        width: 'calc(50vw - ' + widthPadding + ')',
        height: 'calc(50vh - ' + heightPadding + ')',
        data: {
          tittle: 'ALERT.ERROR_BOOKING.title',
          error: 'ALERT.ERROR_BOOKING.boddy'
        }
      });
    });
  }

  isProductSelected(product: any) {
    return (this.productHandlerService.selectedProduct as any).selectedOption.id === product.id;
  }
}
