import { StoreServiceLibrary } from '@wearenovae/novae-core-services';
import { Component, OnInit, ViewChild, HostListener, Output, EventEmitter, Input, OnChanges } from '@angular/core';
import { SearchFlightFixedRatesComponent } from '../search-flight-fixed-rates.component';
import { ViewEncapsulation } from '@angular/core';
import { LanguageService } from 'src/app/component/language/language.service';
import { TranslateService } from '@ngx-translate/core';

import { SmartProfileService } from 'src/app/services/smart-profile.service';
import { DataStoreService } from '../../../services/data-store.service';
import { LoginService } from '../../../container/shared/login/login.service';
import { DataService } from 'src/app/services/data.service';
import { keyJsonFixedRates } from 'src/app/resources/keys.resources';

@Component({
  selector: 'app-pasajeros-fixed-rates',
  templateUrl: './pasajeros-fixed-rates.component.html',
  styleUrls: ['./pasajeros-fixed-rates.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PasajerosFixedRatesComponent implements OnInit, OnChanges {
  qAdultos: number = 1;
  textAdults: string;
  qNinos: number = 0;
  textNinos: string;
  qBebes: number = 0;
  textBebes: string;
  pasajerosRsm: string;
  economica = false;
  ejecutiva = false;
  primera = false;
  economicaPremium = false;
  cabinClasses = [];
  validateCabin: boolean;
  validateQPassengers: boolean;
  preferCabin: string;
  translationTextAdultsSingular: string;
  translationTextChildrenSingular: string;
  translationTextInfantsSingular: string;
  translationTextAdultsPlural: string;
  translationTextChildrenPlural: string;
  translationTextInfantsPlural: string;
  qTotal: number = 1;
  continueClick: boolean;
  pointUser: number;

  @ViewChild('personasCmp') personasCmp;
  @Output() sendDataPassenger = new EventEmitter<object>();
  @Input() cabinClassSelected: string;
  @Input() disabledMore: boolean;
  disabledMoreBebes: boolean;

  constructor(
    private search: SearchFlightFixedRatesComponent,
    private dataStore: DataStoreService,
    private data: DataService,
    private languageService: LanguageService,
    private translateService: TranslateService,
    private loginService: LoginService,
    private smartProfileService: SmartProfileService,
    private storeLibrary: StoreServiceLibrary
  ) {
  }

  ngOnInit() {
    this.languageService.systemLanguajeChange.subscribe((languaje) => {
      if (languaje) {
        this.initValues();
        this.validatePointsFirst();
      }
    });
    this.smartProfileService.profileDataLoadedChange.subscribe(dataLoaded => {
      if (dataLoaded) {
        if (this.dataStore.smartProfilePreferences && this.dataStore.smartProfilePreferences.flightClass) {
          this.preferCabin = this.dataStore.smartProfilePreferences.flightClass;
          this.preferCabinFn(this.preferCabin);
          this.qAdultos = this.dataStore.qAdultos;
          this.qNinos = this.dataStore.qNinos;
          this.qBebes = this.dataStore.qBebes;
        }
      } else {
        this.economica = true;
        this.ejecutiva = false;
        this.primera = false;
        this.economicaPremium = false;
        this.search.cabinClasses.push('Y');
      }
      this.qAdultos = this.dataStore.qAdultos;
      this.qNinos = this.dataStore.qNinos;
      this.qBebes = this.dataStore.qBebes;
    });

  }

  /**
   * cerrar el panel si hacen click afuera
   */
  @HostListener('document:click', ['$event.target'])
  onClick(targetElement) {
    const clickedInside = this.personasCmp.nativeElement.contains(targetElement) ||
      (targetElement && (<HTMLElement>targetElement).getAttribute('attr-open-app-pasajeros'));

    if (!clickedInside) {
      this.search.showPassengerForm = true;
    }
  }

  private initValues() {
    this.translateService.get([
      'PASSENGERS.adult',
      'PASSENGERS.indicate_passengers',
      'PASSENGERS.translation_text_adults_singular',
      'PASSENGERS.translation_text_children_singular',
      'PASSENGERS.translation_text_infant_singular',
      'PASSENGERS.translation_text_adults_plural',
      'PASSENGERS.translation_text_children_plural',
      'PASSENGERS.translation_text_infants_plural'
    ]).subscribe((translations) => {

      this.translationTextAdultsSingular = translations['PASSENGERS.translation_text_adults_singular'];
      this.translationTextChildrenSingular = translations['PASSENGERS.translation_text_children_singular'];
      this.translationTextInfantsSingular = translations['PASSENGERS.translation_text_infant_singular'];
      this.translationTextAdultsPlural = translations['PASSENGERS.translation_text_adults_plural'];
      this.translationTextChildrenPlural = translations['PASSENGERS.translation_text_children_plural'];
      this.translationTextInfantsPlural = translations['PASSENGERS.translation_text_infants_plural'];
    });
  }

  preferCabinFn(preferCabin) {
    switch (preferCabin) {
      case 'Y':
        this.search.cabinClasses.push('Y');
        this.economica = true;
        break;
      case 'F':
        this.search.cabinClasses.push('F');
        this.economica = false;
        this.primera = true;
        break;
      case 'B':
        this.search.cabinClasses.push('B');
        this.economica = false;
        this.ejecutiva = true;
        break;
    }
  }

  pullData() {
    const qTotal = this.qAdultos + this.qNinos;
    this.qTotal = qTotal;
    let objPssengers = {
      qTotal,
      qAdultos: this.qAdultos,
      qNinos: this.qNinos,
      qBebes: this.qBebes,
      cabinClasses: this.cabinClassSelected
    };
    this.search.isCollapsed = true;
    this.search.showPassengerForm = true;
    this.search.showPassengerFormControl = false;
    this.dataStore.qAdultos = this.qAdultos;
    this.dataStore.qNinos = this.qNinos;
    this.dataStore.qBebes = this.qBebes;
    this.sendDataPassenger.emit(objPssengers);
  }


  mas(type: string) {
    if (this.qAdultos + this.qNinos + this.qBebes < 8) {
      if (this.continueClick || type == 'B') {
        switch (type) {
          case 'A':
            this.qAdultos++;
            this.disabledMoreBebes = false;
            break;
          case 'N':
            this.qNinos++;
            break;
          case 'B':
            if (!this.disabledMoreBebes) {
              this.qBebes++;
              if (this.qAdultos >= (this.qBebes + 1)) {
                this.disabledMoreBebes = false;
              } else {
                this.disabledMoreBebes = true;
              }
            }
            break;
        }
      }
      this.validateMoreThaOnce();
    } else {
      this.disabledMoreBebes = true;
    }
  }

  menos(type: string) {
    switch (type) {
      case 'A':
        if (this.qAdultos > 1) {
          if (this.qBebes >= this.qAdultos) {
            this.qBebes--;
          }
          this.qAdultos--;
        }
        break;
      case 'N':
        if (this.qNinos > 0) {
          this.qNinos--;
        }
        break;
      case 'B':
        if (this.qBebes > 0) {
          this.qBebes--;
          this.disabledMoreBebes = false;
        }
        break;
    }
    this.validateMoreThaOnce();
  }

  validatePointsFirst() {
    const userPoints = JSON.parse(this.storeLibrary.getCardScotiaPointsSelected() || '{"quantityPoints":0}');
    this.pointUser = Number(userPoints.quantityPoints);
    let fixedRatesSelected = JSON.parse(sessionStorage.getItem(keyJsonFixedRates.fixed_rates_selected));
    if (this.pointUser >= (fixedRatesSelected.value * 2)) {
      this.disabledMore = false;
      this.continueClick = true;
    } else {
      this.disabledMore = true;
      this.continueClick = false;
    }
  }

  validateMoreThaOnce() {
    const userPoints = JSON.parse(this.storeLibrary.getCardScotiaPointsSelected() || '{"quantityPoints":0}');
    this.pointUser = Number(userPoints.quantityPoints);
    let fixedRatesSelected = JSON.parse(sessionStorage.getItem(keyJsonFixedRates.fixed_rates_selected));
    if (this.pointUser > (fixedRatesSelected.value * (this.qAdultos + this.qNinos + 1))) {
      this.disabledMore = false;
      this.continueClick = true;
    } else {
      this.disabledMore = true;
      this.continueClick = false;
    }
  }

  ngOnChanges() {
    let objPssengers = {
      qTotal: this.qTotal,
      qAdultos: this.qAdultos,
      qNinos: this.qNinos,
      qBebes: this.qBebes,
      cabinClasses: this.cabinClassSelected
    };

    this.sendDataPassenger.emit(objPssengers);
  }
}
