import { Component, OnInit } from '@angular/core';
import { GenericCardImage } from '../../../../../../models/genericCard/generic-card-image';
import { AbstractCardComponent } from '../../abstract-card.component';

@Component({
  selector: 'app-abstract-card-image',
  templateUrl: './abstract-card-image.component.html',
  styleUrls: ['./abstract-card-image.component.scss']
})
export class AbstractCardImageComponent implements OnInit {
  image: GenericCardImage = {
    imgURL: './assets/images/empty_car.png',
    label: 'default'
  };

  constructor(
    public cardCmp: AbstractCardComponent
  ) {
  }

  ngOnInit() {
    this.imgToUse();
  }

  imgToUse() {
    const img = this.cardCmp.product.images;
    if (img !== undefined && img !== null && img[0] !== undefined) {
      this.image = img[0];
    }
  }
}
