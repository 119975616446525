import { DataService } from 'src/app/services/data.service';
import { Injectable } from '@angular/core';
import { HotelAvailabilityOptions } from '../models/new-hotel/hotel-availability-response.model';
import { EProduct } from '../component/searched/models/searched.model';
import { Journey } from '../models/new-flight/journey.model';
import { RentacarAvailabilityOption } from '../models/new-rentacar/rentacar-availability-option.model';
import { ActivityResult } from '../models/new-activity/activity-availability-response.model';

@Injectable({
  providedIn: 'root'
})
export class ProductHandlerService {

  private product: any;

  constructor(
    private dataService: DataService
  ) {
    this.setSelectedProduct();
  }

  get productType() {
    return this.dataService.productSelected as EProduct;
  }

  get selectedProduct(): HotelAvailabilityOptions | Journey | RentacarAvailabilityOption | ActivityResult {
    switch (this.productType) {
      case EProduct.HOTELS:
        return this.product as HotelAvailabilityOptions;
        break;
      case EProduct.FLIGHTS:
        return this.product as Journey;
        break;
      case EProduct.CARS:
        return this.product as RentacarAvailabilityOption;
        break;
      case EProduct.ACTIVITIES:
        return this.product as ActivityResult;
        break;
    }
  }

  set selectedProduct(product: HotelAvailabilityOptions | Journey | RentacarAvailabilityOption | ActivityResult) {
    this.product = product;
    sessionStorage[this.sessionVariable] = product ? JSON.stringify(this.product) : sessionStorage[this.sessionVariable];
  }

  get sessionVariable() {
    switch (this.productType) {
      case EProduct.HOTELS:
        return 'selectedHotel';
        break;
      case EProduct.FLIGHTS:
        return 'selected_jounrney';
        break;
      case EProduct.CARS:
        return 'selectedRentaCar';
        break;
      case EProduct.ACTIVITIES:
        return 'selectedActivity';
        break;
      case EProduct.BENEFITS:
        return 'selectedBenefit';
        break;
    }
  }

  setSelectedProduct() {
    this.product = this.product
      ? this.product
      : sessionStorage[this.sessionVariable]
      ? JSON.parse(sessionStorage[this.sessionVariable])
      : {};
  }

  clearSelectedProduct() {
    this.product = undefined;
    delete sessionStorage[this.sessionVariable];
  }
}
