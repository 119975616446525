<div class="select-container">
    <i class="icon-calendario"></i>
    <i class="icon-arrow-down"></i>
    <h6 innerHTML="{{ 'ACTIVITY_DETAIL.date' | translate }}">
        Fecha y Horario
    </h6>
    <select class="material_select"
        (change)="selectOption($event.target.value)">
        <option value="0">
            {{'ACTIVITY_DETAIL.select_option'|translate}}
        </option>
        <option *ngFor="let value of dropdownOptions; let i = index" [value]="parseValue(value)">
            {{ formatTime(value.startDate) }}
        </option>
    </select>
</div>