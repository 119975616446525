import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { keysUniversal, StoreServiceLibrary } from '@wearenovae/novae-core-services';
import { Entry } from 'contentful';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { ActionEventService } from 'src/app/services/action-event.service';
import { ContentfulService } from 'src/app/services/contentful/contentful.service';
import { DataService } from 'src/app/services/data.service';
import { InitService } from 'src/app/services/init.service';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-ctf-slider',
  templateUrl: './ctf-slider.component.html',
  styleUrls: ['./ctf-slider.component.scss']
})
export class CtfSliderComponent implements OnInit {
  public type = 'component';
  public disabled = false;
  uri = environment.uriSlider;
  
  config: SwiperConfigInterface;
  sliders: Entry<any>[] = [];
  
  contentful = {
    space: 'space',
    accessToken: 'accessToken',
    slidersCode: {
    }
  };
  
  constructor(
    private actionsEventService: ActionEventService,
    private initService: InitService,
    private data: DataService,
    public router: Router,
    private _storeLibrary: StoreServiceLibrary,
    private contentfulService: ContentfulService
  ) {
  }

  ngOnInit() {    
    this.initService.isInitializedBrand.subscribe((isInit) => {
      if (isInit) {
        let contentful = this._storeLibrary.getJustOneUniversalPropertie(keysUniversal.contentful);
        if (contentful != '') {
          this.contentful = JSON.parse(contentful);
        }
        
        this.countryCodeChange();
        this.loadSliders(this.contentful.slidersCode[this._storeLibrary.getItemFromStorage('CountrySelected')]);
      }
    });
  }

  private loadSliders(contentType:string) {
    this.contentfulService.getContentful(Object.assign({ 'content_type': contentType })).then((entry: any) => {
      this.sliders = entry.sort(function(a, b) {
        return a.fields.order - b.fields.order;
      });
      this.initSwiper();
    });
  }

  countryCodeChange() {
    this.actionsEventService.countryCodeChange.subscribe((countryCodeChange) => {
      this.loadSliders(this.contentful.slidersCode[countryCodeChange.code]);
    });
  }
  
  initSwiper() {
    this.config = {
      direction: 'vertical',
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
        type: 'bullets',
        currentClass: 'slider-counter',
        renderFraction: function (currentClass) {
          return '<span class="' + currentClass + '"></span>';
        }
      },
      simulateTouch: false,
      allowTouchMove: false,
      autoplay: {
        delay: 30000,
      },
      slidesPerView: 1,
      scrollbar: false,
      navigation: false,
      loop: true,
      breakpoints: {
        768: {
          slidesPerView: 1,
          direction: 'horizontal',
          pagination: {
            el: '.swiper-pagination',
            clickable: true,
            type: 'bullets',
            currentClass: 'swiper-pagination-current'
          },
        }
      }
    };
  }

  openWindow(item) {
    if (item.fields['action'] && item.fields['action'].includes('routing')) {
      if (item.fields['section']) {
        this.router.navigateByUrl(item.fields['route'], { state: { section: item.fields['section'] }});
      } else {
        this.router.navigate([item.fields['route']]);
      }
    } else if (item.fields['action'] && item.fields['action'].includes('whatsapp')) {
      const urlWhatsappWelcome = this._storeLibrary.getJustOneUniversalPropertie(keysUniversal.app_url_whatsapp_welcome);
      this.data.openUrlExternal(urlWhatsappWelcome, '_blank');
    } else {
      this.data.openUrlExternal(item.fields['action'], '_blank');
    }
  }
}
