import { Component, Input, OnInit } from '@angular/core';
import { DataStoreService } from '../../services/data-store.service';
import { OcultadorService } from '../../pages/home/ocultador.service';
import { InitService } from '../../services/init.service';
import { ActionEventService } from '../../services/action-event.service';

@Component({
  selector: 'app-footer-all',
  templateUrl: './footer-all.component.html',
  styleUrls: ['./footer-all.component.css']
})
export class FooterAllComponent implements OnInit {

  @Input('brand') brand: string;
  isAmex: boolean = true;

  constructor(
    private dataStoreService: DataStoreService,
    public ocultadorService: OcultadorService,
    private initService: InitService,
    private actionEventService: ActionEventService) { }

  ngOnInit(): void {
    this.initService.isInitializedBrand.subscribe((isInitialized) => {
      if (isInitialized) {
        this.definedFooterAmex();
      }
    });
  }

  definedFooterAmex() {
    this.actionEventService.changeFooter.subscribe((isChange) => {
      if (this.dataStoreService.brand === 'scotia') {
        this.isAmex = isChange;
      } else {
        this.isAmex = false;
      }
    });
  }


}
