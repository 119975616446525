import { Component, HostListener, Input, OnInit, ViewChild, EventEmitter, Output } from '@angular/core';
import moment from 'moment';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { DataService } from 'src/app/services/data.service';
import { Router } from '@angular/router';
import { DataStoreService } from '../../services/data-store.service';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from '../language/language.service';
import { StoreServiceLibrary, FixedRatesService, GeneralUtilService } from '@wearenovae/novae-core-services';
import { DebounceCall } from '../../decorators/debounce.decorator';
import { formatDate } from '@angular/common';
import { locale } from 'moment';
import { keyJsonFixedRates, keysUniversal } from 'src/app/resources/keys.resources';
import { InitService } from '../../services/init.service';
import { JuniperService } from '../../services/juniper.service';


@Component({
  selector: 'app-search-flight-fixed-rates',
  templateUrl: './search-flight-fixed-rates.component.html',
  styleUrls: ['./search-flight-fixed-rates.component.scss']
})
export class SearchFlightFixedRatesComponent implements OnInit {
  @Output() notifyHaveParameters = new EventEmitter<object>();
  @Output() notifyValueData = new EventEmitter<Number>();
  @Input() typeSelect: string;

  objFixedRates = {};
  disabledMore: boolean = false;

  @ViewChild('dateStart') dateStart;
  @ViewChild('dateEnd') dateEnd;

  minDateEnd;
  selected = {
    startDate: moment(new Date()),
    endDate: moment(new Date())
  };

  @Input() benefits;
  now = new Date();
  minDate = moment(new Date());
  maxDate = moment(new Date());

  urlSearchIframe = '';
  iframeScrollHeight = '100%';
  iframeScrollWidth = '100%';
  classSearchContent = 'buscador-flotante';

  departureAirportCity: string;
  arrivalAirportCity: string;
  startDate: string;
  endDate: string;
  startDateText: string;
  endDateText: string;
  totalNights: any;
  showPassengerForm = true;
  showPassengerFormControl = true;
  public isCollapsed = false;
  qAdultos = 1;
  qNinos = 0;
  qBebes = 0;
  tramoCalendar: boolean;
  tramo: string;
  locale: any = {
    format: '',
    autoclose: true
  };
  n = 0;
  test: any;
  passengerTranslations = [];
  form: FormGroup;
  dateNotSelected = false;
  originNotSelected = false;
  destinationNotSelected = false;
  passengersNotSelected = false;
  cabinClasses = [];
  validatorOriginDestiniDif = false;
  preferOrigin: string;
  departureAirportCityText: string;
  arrivalAirportCityText: string;
  placeholderOrigin: string;
  placeholderDestination: string;
  notFoundText: string;
  palceholderSelectDate: string;
  textDay: string;
  textDays: string;
  textOneWay: string;
  diaDeLaSemanaFormat: string;
  textTo: string;
  allCitiesOrigin = [];
  allCitiesDestinations = [];
  searchSbs: Subscription;
  translations = [];
  passengers: object;
  fixedRatesSelected: object;
  idOrigin: number = 0;
  notHaveIdOrigin = true;

  objSearch = {
    destinationIATA: '49240',
    originIATA: '49431',
    startDate: '2021-03-09',
    endDate: '2021-03-16',
    adt: '',
    chd: '',
    inf: '',
    ages: '',
    flightClass: '',
    appendHashParams: '',
    subtotal: 0
  };

  showLoadIframe = {
    showIframe: false,
    showTable: true,
    loadUrlJuniper: ''
  }

  get qNights() {
    return this.form.value.dateSelect.endDate.diff(this.form.value.dateSelect.startDate, 'days');
  }

  get placeholderPassenger() {
    let placeho = '';
    if (this.dataStore.qAdultos > 0) {
      if (this.dataStore.qAdultos === 1) {
        placeho = this.dataStore.qAdultos + ' ' + this.passengerTranslations['translation_text_adults_singular'];
      } else {
        placeho = this.dataStore.qAdultos + ' ' + this.passengerTranslations['translation_text_adults_plural'];
      }
      if (this.dataStore.qNinos > 0) {
        if (this.dataStore.qNinos === 1) {
          placeho = placeho + ', ' + this.dataStore.qNinos + ' ' + this.passengerTranslations['translation_text_children_singular'];
        } else {
          placeho = placeho + ', ' + this.dataStore.qNinos + ' ' + this.passengerTranslations['translation_text_children_plural'];
        }
      }
      if (this.dataStore.qBebes > 0) {
        if (this.dataStore.qBebes === 1) {
          placeho = placeho + ', ' + this.dataStore.qBebes + ' ' + this.passengerTranslations['translation_text_infant_singular'];
        } else {
          placeho = placeho + ', ' + this.dataStore.qBebes + ' ' + this.passengerTranslations['translation_text_infants_plural'];
        }
      }
    } else {
      if (this.dataStore.qNinos > 0) {
        if (this.dataStore.qNinos === 1) {
          placeho = this.dataStore.qNinos + ' ' + this.passengerTranslations['translation_text_children_singular'];
        } else {
          placeho = this.dataStore.qNinos + ' ' + this.passengerTranslations['translation_text_children_singular'];
        }
      }
      if (this.dataStore.qBebes > 0) {
        if (this.dataStore.qBebes === 1) {
          placeho = placeho + ', ' + this.dataStore.qBebes + ' ' + this.passengerTranslations['translation_text_infant_singular'];
        } else {
          placeho = placeho + ', ' + this.dataStore.qBebes + ' ' + this.passengerTranslations['translation_text_infants_plural'];
        }
      }
    }
    return placeho;
  }


  constructor(
    private data: DataService,
    private router: Router,
    private dataStore: DataStoreService,
    protected $gaService: GoogleAnalyticsService,
    private translateService: TranslateService,
    private languageService: LanguageService,
    private storeLibrary: StoreServiceLibrary,
    private initService: InitService,
    private fixedRatesService: FixedRatesService,
    private juniperService: JuniperService,
    private generalUtilService: GeneralUtilService
  ) {
    this.form = this.createFormSearchGroup();
    this.initService.isInitializedBrand.subscribe((isInitialized) => {
      this.urlSearchIframe = this.storeLibrary.getJustOneUniversalPropertie(keysUniversal.URL_TRAVEL_JUNIPER) || '';
      let objConditionsDates = JSON.parse(this.storeLibrary.getJustOneUniversalPropertie(keysUniversal.DEFAULT_FIXED_RATE) || '');
      this.minDate = moment(new Date().setDate(this.now.getDate() + objConditionsDates.minDate));
      this.selected.startDate = moment(new Date().setDate(this.now.getDate() + objConditionsDates.minDate));
      this.selected.endDate = moment(new Date().setDate(this.now.getDate() + objConditionsDates.minDate));
      // this.urlSearchIframe = 'http://localhost:8080/test_widget_onepocket_web/testPlugin1.html';
    });
  }

  async ngOnInit() {
    this.tramoCalendar = false;
    this.form.get('tramo').setValue('idaVuelta');
    this.tramo = 'idaVuelta';
    this.objFixedRates = JSON.parse(sessionStorage.getItem(keyJsonFixedRates.json_fixed_rates));
    await this.getTranslations();
  }

  createFormSearchGroup() {
    return new FormGroup({
      origen: new FormControl(null, [Validators.required]),
      destino: new FormControl(null, [Validators.required]),
      dateSelect: new FormControl({
        startDate: moment(new Date()),
        endDate: moment(new Date())
      }, [Validators.required]),
      tramo: new FormControl(null, [Validators.required]),
      passenger: new FormControl(null),
    });
  }

  showPassengerFormFn() {
    if (this.showPassengerFormControl) {
      this.showPassengerForm = false;
    } else {
      this.showPassengerFormControl = true;
    }
  }

  async getTranslations() {
    await this.languageService.systemLanguajeChange.subscribe(
      (language) => {
        if (language) {
          this.translateService.get([
            'DATE.format',
            'DATE.format_weekday',
            'SEARCH_FLIGHTS.placeholder_origin',
            'SEARCH_FLIGHTS.placeholder_destination',
            'SEARCH_FLIGHTS.not_found_text',
            'SEARCH_FLIGHTS.select_Date',
            'SEARCH_FLIGHTS.text_day',
            'SEARCH_FLIGHTS.text_days',
            'SEARCH_FLIGHTS.one_way',
            'SEARCH_FLIGHTS.to',
            'PASSENGERS'
          ]).subscribe(
            (translations) => {
              // local para los calendarios
              locale(language);
              this.locale.format = translations['DATE.format'];
              this.locale.daysOfWeek = moment.weekdaysMin();
              this.locale.monthNames = moment.monthsShort();
              // fix hack para actualizar los selectores de fecha
              this.tramoCalendar = !this.tramoCalendar;
              setTimeout(() => {
                this.tramoCalendar = !this.tramoCalendar;
              }, 100);
              this.diaDeLaSemanaFormat = translations['DATE.format_weekday'];
              this.placeholderOrigin = translations['SEARCH_FLIGHTS.placeholder_origin'];
              this.placeholderDestination = translations['SEARCH_FLIGHTS.placeholder_destination'];
              this.notFoundText = translations['SEARCH_FLIGHTS.not_found_text'];
              this.palceholderSelectDate = translations['SEARCH_FLIGHTS.select_Date'];
              this.textDay = translations['SEARCH_FLIGHTS.text_day'];
              this.textDays = translations['SEARCH_FLIGHTS.text_days'];
              this.textOneWay = translations['SEARCH_FLIGHTS.one_way'];
              this.textTo = translations['SEARCH_FLIGHTS.to'];
              this.translations = translations['SEARCH_FLIGHTS'];
              this.passengerTranslations = translations['PASSENGERS'];

            });
        }
      }
    );
  }

  tramoFn(tramo:string) {
    if (tramo === 'oneWay') {
      this.form.value.dateSelect.endDate = this.form.value.dateSelect.startDate;
      this.endDate = formatDate(this.form.value.dateSelect.startDate, 'yyyy-MM-dd', locale()).toString();
      this.endDateText = formatDate(this.form.value.dateSelect.startDate, 'EEE dd/MM', locale('es')).toLocaleString();
    }
    this.tramo = tramo;
    this.tramoCalendar = tramo !== 'idaVuelta';
  }

  @DebounceCall(250)
  onSearchOrigin($event) {
    this.allCitiesOrigin = [];
    this.originNotSelected = false;
    this.destinationNotSelected = false;
    // nuevo filtro de ciudades consolid
    this.searchSbs = this.fixedRatesService.getOrigins(this.objFixedRates['product']['id']).subscribe((res: any) => {
      if (res && res.origins && res.origins.countries.length > 0) {
        this.allCitiesOrigin = res.origins.countries;
        this.notHaveIdOrigin = false;
      }
    });
  }

  @DebounceCall(250)
  onSearchDestinations($event) {
    if (!this.notHaveIdOrigin) {
      this.allCitiesDestinations = [];
      this.originNotSelected = false;
      this.destinationNotSelected = false;
      this.idOrigin = this.form.value.origen['id'];
      // si la busqueda es de al menos 3 letras
      // nuevo filtro de ciudades consolid
      this.searchSbs = this.fixedRatesService.getDestinations(this.objFixedRates['product']['id'], this.idOrigin).subscribe((res: any) => {
        if (res && res.destinations && res.destinations.countries.length > 0) {
          this.allCitiesDestinations = res.destinations.countries;
        }
      });
    }
  }

  returnTrue = () => true;

  openedChangeStart(opened: any) {
    // if (!opened) {
    this.selected = this.form.value.dateSelect;
    this.dateStart.nativeElement.value = this.form.value.dateSelect.startDate.format('MM/DD/YYYY');
    this.dateEnd.nativeElement.value = this.form.value.dateSelect.endDate.format('MM/DD/YYYY');
    this.minDateEnd = this.form.value.dateSelect.startDate;
  }

  openedChangeEnd(opened: any) {
    // if (!opened) {
    this.dateStart.nativeElement.value = this.form.value.dateSelect.startDate.format('MM/DD/YYYY');
    this.dateEnd.nativeElement.value = this.form.value.dateSelect.endDate.format('MM/DD/YYYY');
    this.selected.endDate = this.form.value.dateSelect;
    // }
  }

  invertFlightSelection() {
    if (this.form.controls['origen'].value !== undefined && this.form.controls['destino'].value !== undefined) {
      const nuevoDestino = this.form.controls['destino'].value;
      const nuevoOrigen = this.form.controls['origen'].value;
      this.form.controls.origen.setValue(nuevoDestino);
      this.form.controls.destino.setValue(nuevoOrigen);
    }
  }

  @HostListener('window:message', ['$event'])
  receiveMessage(event) {
    const iframe = document.getElementById('iframe_fixed_rates');
    if (event.data) {
      let dataEvent: any;
      try {
        dataEvent = JSON.parse(event.data);
      } catch (error) {
        dataEvent = event.data;
      }
      if (typeof dataEvent === 'object' && dataEvent.scrollHeight) {
        this.iframeScrollHeight = dataEvent.scrollHeight + 'px';
      } else if (dataEvent === 'getUrlWidgetPortal') {
        const message = {
          0: 'urlWidgetPortal',
          1: window.location.origin
        };
        this.data.sendPostMessage(message, '*', iframe);
      } else if (dataEvent === 'getUrlPortal') {
        const message = {
          0: 'urlPortal',
          1: window.location.origin
        };

        this.data.sendPostMessage(message, '*', iframe);
      }
    }
  }

  loadDataPasanger(event) {
    this.passengers = event;
    this.fixedRatesSelected = JSON.parse(sessionStorage.getItem('FIXED_RATES_SELECTED'));
    let valueTosend = (Number(this.fixedRatesSelected['value']) * (Number(this.passengers['qNinos']) + Number(this.passengers['qAdultos'])));
    this.objFixedRates['total'] = valueTosend;
    this.objFixedRates['passenger'] = this.passengers['qTotal'];
    sessionStorage.setItem(keyJsonFixedRates.json_fixed_rates, JSON.stringify(this.objFixedRates));
    this.notifyValueData.emit(valueTosend);
  }

  search(event) {
    const iframe = document.getElementById('iframe_fixed_rates');
    this.objFixedRates['product']['quantity'] = this.passengers['qTotal'];

    if (this.objFixedRates && this.objFixedRates['product']['class']) {
      if (this.objFixedRates['product']['class'] === 'Business') {
        this.objFixedRates['product']['class'] = 'b';
      } else if (this.objFixedRates['product']['class'] === 'Economy') {
        this.objFixedRates['product']['class'] = 'e';
      }
    }

    const objSendProduct = this.generalUtilService.getProductToken(this.objFixedRates['product']['id'], this.objFixedRates['product']['class'], this.objFixedRates['product']['key'], this.objFixedRates['product']['quantity'], this.objFixedRates['product']['otherInformation']);
    this.objFixedRates['product'] = objSendProduct;
    sessionStorage.setItem(keyJsonFixedRates.json_fixed_rates, JSON.stringify(this.objFixedRates));
    this.showLoadIframe.showIframe = true;
    this.showLoadIframe.showTable = false;
    this.objSearch.destinationIATA = this.form.value.origen['iso'];
    this.objSearch.originIATA = this.form.value.destino['iso'];
    this.objSearch.startDate = formatDate(this.form.value.dateSelect.startDate, 'yyyy-MM-dd', locale()).toString();
    this.objSearch.endDate = formatDate(this.form.value.dateSelect.endDate, 'yyyy-MM-dd', locale()).toString();
    this.objSearch.adt = this.passengers['qAdultos'];
    this.objSearch.chd = this.passengers['qNinos'];
    this.objSearch.inf = this.passengers['qBebes'];

    if (this.passengers['qBebes']) {
      this.objSearch.chd = `${Number(this.passengers['qNinos']) + Number(this.passengers['qBebes'])}`;
    }
    this.proccesAges(this.passengers);
    this.objSearch.flightClass = this.objFixedRates['product']['class'];
    this.objSearch.subtotal = this.objFixedRates['subtotal'];
    this.showLoadIframe.loadUrlJuniper = this.juniperService.searchFlightsJuniperScotia('searchflights', this.objSearch);

    this.notifyHaveParameters.emit(this.showLoadIframe);
    sessionStorage.setItem('FIXED_RATES_SELECTED', JSON.stringify(this.fixedRatesSelected));
  }

  proccesAges(info) {
    if (info['qNinos']) {
      for (let index = 0; index < info['qNinos']; index++) {
        this.objSearch.ages += `08`;
      }
    }
    if (info['qBebes']) {
      for (let index = 0; index < info['qBebes']; index++) {
        this.objSearch.ages += `01`;
      }
    }
  }

  sendPostMessage(iframe: any, message: any, targetOrigin: string) {
    if (iframe === null) {
      return;
    }
    const iWindow = (<HTMLIFrameElement>iframe).contentWindow;
    iWindow.postMessage(message, targetOrigin);
  }
}
