import { Component, OnInit, HostListener, EventEmitter, Input, ViewChild, Output, Renderer2, ElementRef } from '@angular/core';
import { environment } from '../../../environments/environment';
import { DataService } from 'src/app/services/data.service';
import { InitService } from '../../services/init.service';
import { DataStoreService } from '../../services/data-store.service';
import { LoginService } from '../../container/shared/login/login.service';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AccountResultDto, StoreServiceLibrary, ScotiaImplementationService, UserData } from '@wearenovae/novae-core-services';
import { DebounceCall } from '../../decorators/debounce.decorator';
import { LoginComponent } from '../../container/shared/login/login.component';
import { getCurrencySymbol } from '@angular/common';
import { EProduct } from '../searched/models/searched.model';
import { LoadingScreenService } from '../../services/loading-screen.service';
import { ActionEventService } from '../../services/action-event.service';
import { AlertErrorComponent } from '../product-detail/alert-error/alert-error.component';
import { keysUniversal } from '../../resources/keys.resources';
import { keysPostMessage } from 'src/app/resources/keys.resources';
import { UtilsResource } from 'src/app/resources/UtilsResource.resource';

declare var androidProxy: any;
declare var window: any;


@Component({
  selector: 'app-scotia-cards',
  templateUrl: './scotia-cards.component.html',
  styleUrls: ['./scotia-cards.component.scss']
})
export class ScotiaCardsComponent implements OnInit {

  @ViewChild('onePocketIframe') iframe;
  @ViewChild('loadingVideo') loadingVideo;

  elementCardScotia: any;
  iframeScrollHeight = '100%';
  iframeScrollWidth = '100%';
  iframeMinHeigth = '400px';

  @Input() iframeURL: string;
  @Input() token: string;
  @Input() enabled;

  contador: number;
  public loaderSlider: string;
  onePocketUrl = this.storeServiceLibrary.getJustOneUniversalPropertie(keysUniversal.onePocket_url) || '';
  // onePocketUrl = 'http://localhost:8100/';

  // @Input()
  public loaded = false;
  public moneyAmount = 0;
  public pointsAmount = 0;
  public getCurrencySymbol = getCurrencySymbol;

  private initiated = false;
  private totalCostValue = 0;


  @Input()
  public moneyCurrency = '';

  @Output()
  public getPorcentaje = new EventEmitter(true);

  private iframeBaseUrl: string;
  useJwtToken: boolean;

  constructor(
    private dataService: DataService,
    private initService: InitService,
    public dataStore: DataStoreService,
    private translate: TranslateService,
    private router: Router,
    private loginService: LoginService,
    private dialog: MatDialog,
    private storeServiceLibrary: StoreServiceLibrary,
    private scotiaServiceImplementation: ScotiaImplementationService,
    private loadingScreenService: LoadingScreenService,
    private dialogRef: MatDialogRef<ScotiaCardsComponent>,
    private eventService: ActionEventService,
    private render: Renderer2,

  ) {
  }

  ngOnInit() {
    this.enabled = true;
    this.elementCardScotia = document.getElementById('scotia-cards');
    this.render.setStyle(this.elementCardScotia, 'display', 'none');
    this.starLoading();
    
    this.useJwtToken = this.dataService.getJwtUse();
    this.initService.isInitializedBrand.subscribe(isInitialized => {
      if (isInitialized) {
        this.initiated = true;
        this.loaded = false;
        this.iframeURL = `${this.onePocketUrl}#/onepocket-points`;

        const pathArray = this.iframeURL.split('/');
        const protocol = pathArray[0];
        const host = pathArray[2];
        this.iframeBaseUrl = protocol + '//' + host;
      }
    });

    this.definedLangLoader();
    

  }

  ngAfterContentChecked() {
    this.definedLangLoader();
  }

  definedLangLoader() {
    if (this.translate.currentLang.valueOf() === 'en') {
      if (this.dataStore.brand === 'scotia') {
        if (this.dataStore.creditCardDefult === 'amex') {
          this.loaderSlider = '../../../assets/images/scotia/gif-loader-scotia-amex.gif';
        } else {
          this.loaderSlider = '../../../assets/images/scotia/gif-loader-scotia-non-amex.gif';
        }
      } else if (this.dataStore.brand === 'myrewards') {
        this.loaderSlider = '../../../assets/images/myrewards-miles/loaders/loader-slide-en.gif';
      } else {
        this.loaderSlider = '../../../assets/loader/loader-slide-en.gif';
      }
    } else {
      if (this.dataStore.brand === 'myrewards') {
        this.loaderSlider = '../../../assets/images/myrewards-miles/loaders/loader-slide-es.gif';
      } else {
        this.loaderSlider = '../../../assets/loader/loader-slide-es.gif';
      }
    }
  }

  async playVideo() {
    await this.loadingVideo.nativeElement.play();
  }

  @HostListener('window:message', ['$event'])
  messageListener(event: MessageEvent) {
    if (environment.production) {
    }

    if (this.iframeURL && event.data.typeMessage === 'INIT_ONEPOCKET_PAGE') {
      this.starLoading();
      this.loaded = true;
      this.dataService.productSelected = EProduct.HOME;
      this.dataStore.preloaderImg = EProduct.GENERAL;
      this.sendTokenAuth();
      this.changeLanguage();
    } else if (this.iframeURL && event.data.typeMessage === 'COMPLETE_SAVE_DATA_USER') {
      this.dataService.productSelected = EProduct.HOME;
      this.dataStore.preloaderImg = EProduct.GENERAL;
    } else if (this.iframeURL && event.data.typeMessage === 'CARD_SCOTIA_SHOW_MODAL') {
      const showModal = event.data.showModal;
      if (showModal) {
        this.render.setStyle(this.elementCardScotia, 'display', 'block');
        this.stopLoading();
      } else {
        this.render.setStyle(this.elementCardScotia, 'display', 'none');
        this.starLoading();
      }
    } else if (this.iframeURL && event.data.typeMessage === 'CARD_STORAGE_SAVE_DATA') {
      const dataCard = event.data.objectCard;
      this.dataService.setDataCard(dataCard);
      this.dataService.getInfoCard();
      //this.callSetCardData(dataCard);
      this.dialogRef.close();
    } else if (this.iframeURL && event.data.typeMessage === 'SHOW_ERROR_IN_ALLETRAVEL') {
      const idError = event.data.idError;
      let message;
      this.loadingScreenService.stopLoading();
      this.translate.get('ALERT.ERROR_BOOKING.boddy').subscribe(translate => message = translate);
      this.errorModal(`${ message } ${idError}`);
    }
  }


  callSetCardData(selectedCard) {
    this.scotiaServiceImplementation.setCardScotiaPointsSelected(selectedCard, this.useJwtToken).subscribe((resp) => {
      this.eventService.infoUserCard(selectedCard);
      this.eventService.notifySaveInStorage(true);
      this.loadingScreenService.stopLoading();
    }, (err) => {
      UtilsResource.NSMarkLogError('CARD SCOTIA POINTS SELECTED ERROR',err);
    });
  }

  starLoading() {
    this.loadingScreenService.startLoading();
  }


  stopLoading() {
    this.loadingScreenService.stopLoading();
  }

  sendTokenAuth() {
    const iframe = document.getElementById('iframe_onepocket_points');
    const userData = this.storeServiceLibrary.getUserData();
    const message = {
      typeMessage: keysPostMessage.tokenAuth,
      user: userData
    };
    this.sendPostMessage(iframe, message, '*');
  }


  errorModal(message) {
    const heightPadding = this.dataStore.isMobileDevice ? '0px' : '40px';
    const widthPadding = this.dataStore.isMobileDevice ? '0px' : '16vw';
    this.dialog.open(AlertErrorComponent, {
      maxWidth: '50vw',
      width: 'calc(50vw - ' + widthPadding + ')',
      height: 'calc(50vh - ' + heightPadding + ')',
      minHeight: '59vh',
      disableClose: true,
      data: {
        tittle: 'ALERT.ERROR_BOOKING.title',
        error: `${message}`
      }
    });
  }


  sendPostMessage(iframe: any, message: any, targetOrigin: string) {
    const iWindow = (<HTMLIFrameElement>iframe).contentWindow;
    iWindow.postMessage(message, '*');
  }


  ngOnDestroy(): void {
    window.removeEventListener('message', event => this.messageListener(event), false);
  }

  changeLanguage() {
    const iframe = document.getElementById('iframe_onepocket_points');
    const userData = this.storeServiceLibrary.getUserData();
    const message = {
      typeMessage: 'LANGUAGE_CHANGE', 
      language: this.translate.currentLang 
    };
    this.sendPostMessage(iframe, message, '*');
  }

}

