<div class="container-info bl-millas">
  <div class="item first-mt">
    <i class="travel-icon icon-stepper-card"></i>
    <small innerHTML="{{'SEGMENT.INFO.total'|translate}}">Total a pagar: </small>&nbsp;
    <span *ngIf="totalMilesPrice" class="price-millas">{{totalMilesPrice | milesFormat}} <b innerHTML="{{'SEGMENT.INFO.miles'|translate}}">Millas</b> </span>
    <span *ngIf="(totalMilesPrice && totalMoneyPrice)" class="mas">+</span>
    <small *ngIf="totalMoneyPrice" class="price">{{ currency }}{{totalMoneyPrice | milesFormat}}</small>
  </div>
  <div class="item mt-1">
    <small><i class="travel-icon icon-luggage"></i> {{ baggageType }}</small>
    <small><i class="travel-icon icon-plane-vertical"></i> {{data.getCabinType(info.cabin)}}</small>
    <small><i class="travel-icon icon-information"></i> {{data.getRefundableType(info.refundable)}}</small>
  </div>
</div>


