import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { SearchErrorType } from 'src/app/models/search-error-type.model';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { DataStoreService } from '../../../services/data-store.service';

@Component({
  selector: 'app-no-results',
  templateUrl: './no-results.component.html',
  styleUrls: ['./no-results.component.scss']
})
export class NoResultsComponent implements OnInit {

  public searchErrorType: SearchErrorType;

  constructor(
    private data: DataService,
    private location: Location,
    private router: Router,
    private dataStore: DataStoreService,
  ) { }

  ngOnInit() {
    history.replaceState(null, null, '/home');
    history.pushState(null, null, '/home');
    this.location.replaceState('/results/noResults');
    this.searchErrorType = this.data.flightSearchError;
  }

  goBack() {
    this.router.navigateByUrl('travel');
  }
}
