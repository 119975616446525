import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { locale, Moment } from 'moment';
import { formatDate } from '@angular/common';
import { DataService } from '../../../../services/data.service';
import moment from 'moment';
import { DataStoreService } from '../../../../services/data-store.service';
import { GoogleAnalyticsAction } from '../../../../models/GoogleAnalyticsEvent';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from 'src/app/component/language/language.service';
import { DebounceCall } from 'src/app/decorators/debounce.decorator';
import { Subscription } from 'rxjs';
import { LoginService } from '../../../../container/shared/login/login.service';
import { TruncatePipe } from 'src/app/pipes/truncate.pipe';
import { SearchedService } from 'src/app/component/searched/searched.service';
import { SearchedFlight } from 'src/app/component/searched/models/searched-flight.model';
import { SmartProfileService } from 'src/app/services/smart-profile.service';
import { CalendarSearchDirective } from '../../../../directives/calendar-search.directive';

import data from 'src/app/resources/screenAndEventsTracking.json';
import { GoogleAnalytics } from '../../../../resources/GoogleAnalytics.resource';
import { InitService } from '../../../../services/init.service';

@Component({
  selector: 'app-search-flight',
  templateUrl: './search-flight.component.html',
  styleUrls: ['./search-flight.component.scss']
})
export class SearchFlightComponent implements OnInit {

  @ViewChild('dateStart') dateStart;
  @ViewChild('dateEnd') dateEnd;

  minDateEnd;
  selected = {
    startDate: moment(new Date()),
    endDate: moment(new Date())
  };


  @Input() benefits;
  minDate = moment(new Date());
  departureAirportCity: string;
  arrivalAirportCity: string;
  startDate: string;
  endDate: string;
  startDateText: string;
  endDateText: string;
  totalNights: any;
  showPassengerForm = true;
  showPassengerFormControl = true;
  public isCollapsed = false;
  qAdultos = 1;
  qNinos = 0;
  qBebes = 0;
  tramoCalendar: boolean;
  tramo: string;
  locale: any = {
    format: '',
    autoclose: true
  };
  n = 0;
  test: any;
  passengerTranslations = [];
  form: FormGroup;
  dateNotSelected = false;
  originNotSelected = false;
  destinationNotSelected = false;
  passengersNotSelected = false;
  cabinClasses = [];
  validatorOriginDestiniDif = false;
  preferOrigin: string;
  departureAirportCityText: string;
  arrivalAirportCityText: string;
  placeholderOrigin: string;
  placeholderDestination: string;
  notFoundText: string;
  palceholderSelectDate: string;
  textDay: string;
  textDays: string;
  textOneWay: string;
  diaDeLaSemanaFormat: string;
  textTo: string;
  allCities = [];
  searchSbs: Subscription;
  translations = [];

  get qNights() {
    return this.form.value.dateSelect.endDate.diff(this.form.value.dateSelect.startDate, 'days');
  }

  get placeholderPassenger() {
    let placeho = '';
    if (this.dataStore.qAdultos > 0) {
      if (this.dataStore.qAdultos === 1) {
        placeho = this.dataStore.qAdultos + ' ' + this.passengerTranslations['translation_text_adults_singular'];
      } else {
        placeho = this.dataStore.qAdultos + ' ' + this.passengerTranslations['translation_text_adults_plural'];
      }
      if (this.dataStore.qNinos > 0) {
        if (this.dataStore.qNinos === 1) {
          placeho = placeho + ', ' + this.dataStore.qNinos + ' ' + this.passengerTranslations['translation_text_children_singular'];
        } else {
          placeho = placeho + ', ' + this.dataStore.qNinos + ' ' + this.passengerTranslations['translation_text_children_plural'];
        }
      }
      if (this.dataStore.qBebes > 0) {
        if (this.dataStore.qBebes === 1) {
          placeho = placeho + ', ' + this.dataStore.qBebes + ' ' + this.passengerTranslations['translation_text_infant_singular'];
        } else {
          placeho = placeho + ', ' + this.dataStore.qBebes + ' ' + this.passengerTranslations['translation_text_infants_plural'];
        }
      }
    } else {
      if (this.dataStore.qNinos > 0) {
        if (this.dataStore.qNinos === 1) {
          placeho = this.dataStore.qNinos + ' ' + this.passengerTranslations['translation_text_children_singular'];
        } else {
          placeho = this.dataStore.qNinos + ' ' + this.passengerTranslations['translation_text_children_singular'];
        }
      }
      if (this.dataStore.qBebes > 0) {
        if (this.dataStore.qBebes === 1) {
          placeho = placeho + ', ' + this.dataStore.qBebes + ' ' + this.passengerTranslations['translation_text_infant_singular'];
        } else {
          placeho = placeho + ', ' + this.dataStore.qBebes + ' ' + this.passengerTranslations['translation_text_infants_plural'];
        }
      }
    }
    return placeho;
  }

  constructor(
    private data: DataService,
    private router: Router,
    private dataStore: DataStoreService,
    protected $gaService: GoogleAnalyticsService,
    private translateService: TranslateService,
    private languageService: LanguageService,
    private loginService: LoginService,
    private truncatePipe: TruncatePipe,
    private searchedService: SearchedService,
    private smartProfileService: SmartProfileService,
    private initService: InitService
  ) {
    this.form = this.createFormSearchGroup();
  }

  showPassengerFormFn() {
    if (this.showPassengerFormControl) {
      this.showPassengerForm = false;
    } else {
      this.showPassengerFormControl = true;
    }
  }

  createFormSearchGroup() {
    return new FormGroup({
      origen: new FormControl(null, [Validators.required]),
      destino: new FormControl(null, [Validators.required]),
      dateSelect: new FormControl({
        startDate: moment(new Date()),
        endDate: moment(new Date())
      }, [Validators.required]),
      tramo: new FormControl(null, [Validators.required]),
      passenger: new FormControl(null, [Validators.required]),
    });
  }

  async ngOnInit() {
    // this.trackingManager.setCurrentScreen('/flights', data.FLIGHTS.flightsHomeScreen);
    this.tramoCalendar = false;
    this.form.get('tramo').setValue('idaVuelta');
    this.tramo = 'idaVuelta';
    /*    this.getPreferences();*/
    // this.loginService.loginChange.subscribe(isLoged => {
    //   if (isLoged) {
    //     this.getPreferences();
    //   } else {
    //     this.form.get('origen').setValue(null);
    //     this.form.get('destino').setValue(null)
    //   }
    // });
    this.smartProfileService.profileDataLoadedChange.subscribe(dataLoaded => {
      if (dataLoaded) {
        this.getPreferences();
      } else {
        this.form.get('origen').setValue(null);
        this.form.get('destino').setValue(null);
      }
    });
    await this.getTranslations();
    this.initService.isInitializedBrand.subscribe((isInit) => {
      if (isInit) {
        GoogleAnalytics.setCurrentScreen('/flights');
      }
    });
  }

  getPreferences() {
    if (this.dataStore.smartProfilePreferences) {
      if (this.dataStore.smartProfilePreferences.flightOrigin) {
        this.departureAirportCityText = this.dataStore.smartProfilePreferences.flightOrigin;
        this.form.get('origen').setValue({
          id: this.dataStore.smartProfilePreferences.flightOriginId,
          name: this.dataStore.smartProfilePreferences.flightOrigin,
          iata: 'Ida'
        });
      }
      if (this.dataStore.smartProfilePreferences.flightDestiny) {
        this.arrivalAirportCityText = this.dataStore.smartProfilePreferences.flightDestiny;
        this.form.get('destino').setValue({
          id: this.dataStore.smartProfilePreferences.flightDestinyId,
          name: this.dataStore.smartProfilePreferences.flightDestiny,
          iata: 'Vuelta'
        });
      }
    }
  }

  async getTranslations() {
    await this.languageService.systemLanguajeChange.subscribe(
      (language) => {
        if (language) {
          this.translateService.get([
            'DATE.format',
            'DATE.format_weekday',
            'SEARCH_FLIGHTS.placeholder_origin',
            'SEARCH_FLIGHTS.placeholder_destination',
            'SEARCH_FLIGHTS.not_found_text',
            'SEARCH_FLIGHTS.select_Date',
            'SEARCH_FLIGHTS.text_day',
            'SEARCH_FLIGHTS.text_days',
            'SEARCH_FLIGHTS.one_way',
            'SEARCH_FLIGHTS.to',
            'PASSENGERS'
          ]).subscribe(
            (translations) => {
              // local para los calendarios
              locale(language);
              this.locale.format = translations['DATE.format'];
              this.locale.daysOfWeek = moment.weekdaysMin();
              this.locale.monthNames = moment.monthsShort();

              // fix hack para actualizar los selectores de fecha
              this.tramoCalendar = !this.tramoCalendar;
              setTimeout(() => {
                this.tramoCalendar = !this.tramoCalendar;
              }, 100);

              this.diaDeLaSemanaFormat = translations['DATE.format_weekday'];
              this.placeholderOrigin = translations['SEARCH_FLIGHTS.placeholder_origin'];
              this.placeholderDestination = translations['SEARCH_FLIGHTS.placeholder_destination'];
              this.notFoundText = translations['SEARCH_FLIGHTS.not_found_text'];
              this.palceholderSelectDate = translations['SEARCH_FLIGHTS.select_Date'];
              this.textDay = translations['SEARCH_FLIGHTS.text_day'];
              this.textDays = translations['SEARCH_FLIGHTS.text_days'];
              this.textOneWay = translations['SEARCH_FLIGHTS.one_way'];
              this.textTo = translations['SEARCH_FLIGHTS.to'];
              this.translations = translations['SEARCH_FLIGHTS'];
              this.passengerTranslations = translations['PASSENGERS'];

            });
        }
      }
    );
  }

  tramoFn(tramo) {
    if (tramo === 'oneWay') {
      this.form.value.dateSelect.endDate = this.form.value.dateSelect.startDate;
      this.endDate = formatDate(this.form.value.dateSelect.startDate, 'yyyy-MM-dd', locale()).toString();
      this.endDateText = formatDate(this.form.value.dateSelect.startDate, 'EEE dd/MM', locale('es')).toLocaleString();
    }
    this.tramo = tramo;
    this.tramoCalendar = tramo !== 'idaVuelta';
  }

  @DebounceCall(250)
  onSearch($event) {
    if (this.searchSbs && !this.searchSbs.closed) {
      // si la busqueda anterior no terminó, la cancelamos
      this.searchSbs.unsubscribe();
    }

    this.allCities = [];
    this.originNotSelected = false;
    this.destinationNotSelected = false;
    const searched = $event.term;
    // si la busqueda es de al menos 3 letras

    if (searched.length <= 2) {
      return;
    }


    // nuevo filtro de ciudades consolid
    this.searchSbs = this.data.getCitiesConsolid($event.term).subscribe((res) => {
      if (res && res.locations && res.locations.length > 0) {
        this.allCities = res.locations;
        this.allCities.map((item) => {
          switch (item.type) {
            case 1:
              item.typeString = 'Airports';
              break;
            case 2:
              item.typeString = 'Cities';
              break;
            default:
              break;
          }
          return item;
        });
        if (this.allCities.length > 1) {
          this.allCities = this.allCities.sort((a, b) => {
            a.name = a.description;
            a.id = a.code;
            b.name = b.description;
            b.id = b.code;
            if (a.typeString < b.typeString) { return -1; }
            if (a.typeString > b.typeString) { return 1; }
            return 0;
          });
        } else if (this.allCities.length === 1) {
          this.allCities[0].name = this.allCities[0].description;
          this.allCities[0].id = this.allCities[0].code

        }
      }
    });
  }

  /**
   * hamemos que el ng-select retorne siempre true en el search
   * porque lo que filtramos es cambar el array de items (onSearch)
   * y el componente esta pensado para tener en el array todo cargado
   * y buscar mostrando y ocutlando sobre esos items
   */
  returnTrue = () => true;

  onChange() {
  }

  setValues() {
    if (this.form.value.origen &&
      this.form.value.origen.name &&
      this.form.value.destino &&
      this.form.value.destino.name) {
      this.departureAirportCityText = this.truncatePipe.transform(this.form.value.origen.name, 42);
      this.arrivalAirportCityText = this.truncatePipe.transform(this.form.value.destino.name, 42);
    }

    if (this.form.value.origen) {
      this.departureAirportCity = this.form.value.origen.id;
    }

    if (this.form.value.destino) {
      this.arrivalAirportCity = this.form.value.destino.id;
    }

    this.startDate = formatDate(
      this.form.value.dateSelect.startDate,
      'yyyy-MM-dd',
      locale(this.languageService.systemLanguajeChange.value)
    ).toString();

    this.endDate = formatDate(
      this.form.value.dateSelect.endDate,
      'yyyy-MM-dd',
      locale(this.languageService.systemLanguajeChange.value)
    ).toString();

    this.startDateText = formatDate(
      this.form.value.dateSelect.startDate,
      this.diaDeLaSemanaFormat,
      locale(this.languageService.systemLanguajeChange.value)
    ).toLocaleString();

    this.endDateText = formatDate(
      this.form.value.dateSelect.endDate,
      this.diaDeLaSemanaFormat,
      locale(this.languageService.systemLanguajeChange.value)
    ).toLocaleString();

    this.totalNights = this.qNights;
    if (this.totalNights === 0) {
      this.totalNights = this.textOneWay;
    } else {
      if (this.totalNights > 1) {
        this.totalNights = this.totalNights + ' ' + this.textDays;
      } else {
        this.totalNights = this.totalNights + ' ' + this.textDay;
      }
    }
  }

  search() {
    this._sendGoogleAnalyticsEvents();
    if (!this.benefits) {
      this.setValues();
      if (this.departureAirportCity !== this.arrivalAirportCity) {
        this.validatorOriginDestiniDif = false;
        if (this.form.value.dateSelect.startDate !== '' && this.form.value.dateSelect.endDate !== '') {
          if (this.form.value.dateSelect.endDate.diff(this.form.value.dateSelect.startDate, 'years') > 1) {
            // todo
          }
          this.dateNotSelected = false;
        } else {
          this.dateNotSelected = true;
        }
        if (this.form.value.origen && this.form.value.origen !== '') {
          this.originNotSelected = false;
        } else {
          this.originNotSelected = true;
        }
        if (this.form.value.destino && this.form.value.destino !== '') {
          this.destinationNotSelected = false;
        } else {
          this.destinationNotSelected = true;
        }
        if ((this.dataStore.qAdultos + this.dataStore.qNinos + this.dataStore.qBebes) > 0) {
          this.passengersNotSelected = false;
        } else {
          this.passengersNotSelected = true;
        }

        if (!(this.destinationNotSelected || this.passengersNotSelected || this.originNotSelected || this.destinationNotSelected)) {
          this.dateNotSelected = false;
          if (this.form.controls['tramo'].value === 'idaVuelta') {
            this.data.flightAvailabilityRequest = {
              journey: [{
                departureAirportCity: this.departureAirportCity,
                arrivalAirportCity: this.arrivalAirportCity,
                flightDate: this.startDate
              },
              {
                departureAirportCity: this.arrivalAirportCity,
                arrivalAirportCity: this.departureAirportCity,
                flightDate: this.endDate
              }],
              passengers: [{ type: 'ADT', count: this.dataStore.qAdultos },
              { type: 'CHD', count: this.dataStore.qNinos },
              { type: 'INF', count: this.dataStore.qBebes }],
              cabinClasses: this.cabinClasses,
              targetCurrency: this.dataStore.brandTargetCurrency,
              language: this.languageService.systemLanguajeChange.value.toUpperCase()
            };

          } else {
            this.data.flightAvailabilityRequest = {
              journey: [{
                departureAirportCity: this.departureAirportCity,
                arrivalAirportCity: this.arrivalAirportCity,
                flightDate: this.startDate
              },
              ],
              passengers: [{ type: 'ADT', count: this.dataStore.qAdultos },
              { type: 'CHD', count: this.dataStore.qNinos },
              { type: 'INF', count: this.dataStore.qBebes }],
              cabinClasses: this.cabinClasses,
              targetCurrency: this.dataStore.brandTargetCurrency,
              language: this.languageService.systemLanguajeChange.value.toUpperCase()
            };
          }

          // valores que fueron buscados
          this.searchedService.setModel(<SearchedFlight>{
            origin: this.form.get('origen').value,
            destination: this.form.get('destino').value,
            journey: this.form.get('tramo').value,
            date: {
              start: this.form.get('dateSelect').value.startDate,
              end: this.form.get('dateSelect').value.endDate
            },
            quantity: {
              adults: this.dataStore.qAdultos,
              ninos: this.dataStore.qNinos,
              babes: this.dataStore.qBebes
            },
            cabinClass: {
              economy: this.cabinClasses.indexOf('Y') !== -1,
              economyPremium: this.cabinClasses.indexOf('P') !== -1,
              executive: this.cabinClasses.indexOf('B') !== -1,
              first: this.cabinClasses.indexOf('F') !== -1,
              combinada: false
            }
          });

          this.data.saveFlightRequest(this.data.flightAvailabilityRequest);
          this.router.navigate(['flights/results']);
        }
      } else {
        this.validatorOriginDestiniDif = true;
      }
    } else {
      this.router.navigate(['benefits']);
    }
  }

  invertFlightSelection() {
    if (this.form.controls['origen'].value !== undefined && this.form.controls['destino'].value !== undefined) {
      const nuevoDestino = this.form.controls['destino'].value;
      const nuevoOrigen = this.form.controls['origen'].value;
      this.form.controls.origen.setValue(nuevoDestino);
      this.form.controls.destino.setValue(nuevoOrigen);
    }
  }

  /**
   * obtiene los parametros para enviar a Google Analytics
   */
  private _sendGoogleAnalyticsEvents() {
    let origin, destination, journeyTypeParam, startDate, endDate, adults, kids2_12, kids0_2, travellerCount, travelClass;

    // Origen
    const Source = this.form.get('origen').value;
    if (Source && Source.type && Source.iata) {
      origin = Source.type + ', ' + Source.iata;
    }

    // Destino
    const Destination = this.form.get('destino').value;
    if (Destination && Destination.type && Destination.iata) {
      destination = Destination.type + ', ' + Destination.iata;
    }

    // Trayecto
    const trayecto = this.form.get('tramo').value;
    if (trayecto) {
      const journeyType = trayecto === 'idaVuelta' ? 'round-trip' : 'one-way';
      journeyTypeParam = journeyType;
    }

    // Fecha
    const dateSelect = this.form.get('dateSelect').value;
    if (dateSelect) {
      if (dateSelect.startDate) {
        startDate = this._getStringDateFromMoment(<Moment>dateSelect.startDate);
      }
      if (dateSelect.endDate) {
        endDate = this._getStringDateFromMoment(<Moment>dateSelect.endDate);
      }
    }

    // Número de viajeros
    if (this.dataStore.qAdultos) {
      adults = this.dataStore.qAdultos.toString();
    }
    if (this.dataStore.qNinos) {
      kids2_12 = this.dataStore.qNinos.toString();
    }
    if (this.dataStore.qBebes) {
      kids0_2 = this.dataStore.qBebes.toString();
    }
    const numberOfTraveller = this.dataStore.qAdultos + this.dataStore.qNinos + this.dataStore.qBebes;
    if (numberOfTraveller) {
      travellerCount = numberOfTraveller.toString();
    }

    // Clase
    if (this.cabinClasses) {
      this.cabinClasses.forEach((clase) => {
        switch (clase) {
          case 'Y':
            travelClass = 'Economy';
            break;
          case 'B':
            travelClass = 'Business';
            break;
          case 'F':
            travelClass = 'First';
            break;
          case 'P':
            travelClass = 'EconomyPremium';
            break;
        }
      });
    }
    let paramsFlights = {
      'origin': origin,
      'destination': destination,
      'journeyTypeParam': journeyTypeParam,
      'startDate': startDate,
      'endDate': endDate,
      'adults': adults,
      'kids2_12': kids2_12,
      'kids0_2': kids0_2,
      'travellerCount': travellerCount,
      'travelClass': travelClass
    }
    // this.trackingManager.trackEventString(data.FLIGHTS.searchFlightButton, data.FLIGHTS.flightsHomeScreen, '', JSON.stringify(paramsFlights));
    GoogleAnalytics.sendEventFirebase('ga_event', data.FLIGHTS.flightsHomeScreen, data.FLIGHTS.searchFlightButton, JSON.stringify(paramsFlights));
  }

  /**
   * Formatea una fecha de tipo Moment
   * @param fecha que se formatea
   * @return la fecha en formato DD-MM-YYYY
   */
  private _getStringDateFromMoment(fecha: Moment): string {
    return fecha.format('DD-MM-YYYY');
  }




  openedChangeStart(opened: any) {
    // if (!opened) {
    this.selected = this.form.value.dateSelect;
    this.dateStart.nativeElement.value = this.form.value.dateSelect.startDate.format('MM/DD/YYYY');
    this.dateEnd.nativeElement.value = this.form.value.dateSelect.endDate.format('MM/DD/YYYY');
    this.minDateEnd = this.form.value.dateSelect.startDate;

  }

  openedChangeEnd(opened: any) {
    // if (!opened) {
    this.dateStart.nativeElement.value = this.form.value.dateSelect.startDate.format('MM/DD/YYYY');
    this.dateEnd.nativeElement.value = this.form.value.dateSelect.endDate.format('MM/DD/YYYY');
    this.selected = this.form.value.dateSelect;

    // }
  }
}
