import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { IBenefitGroup, IBenefitResponse, IBenefits } from '../../models/benefitResponce-model';
import { DataStoreService } from '../../services/data-store.service';
import { DataService } from '../../services/data.service';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { FilterDataType, FilterGroupType, IFilterParams } from '../shared/filters/filterParams';
import { SearchErrorType } from '../../models/search-error-type.model';
import { ProductDto } from '../../models/benefit-old/product-response.model';
import { ProductOfferService, StoreServiceLibrary, AccountSumaryDto } from '@wearenovae/novae-core-services';
import { TranslateService } from '@ngx-translate/core';
import { InitService } from '../../services/init.service';
import { LanguageService } from '../../component/language/language.service';
import { LoginComponent } from '../shared/login/login.component';
import { MatDialog } from '@angular/material/dialog';
import { UtilsResource } from 'src/app/resources/UtilsResource.resource';

@Component({
  selector: 'app-benefit-card',
  templateUrl: './benefit-card.component.html',
  styleUrls: ['./benefit-card.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class BenefitCardComponent implements OnInit, OnDestroy {
  translations = [];
  availabilitySubscription: Subscription;
  maxValueF = 200;
  porcentajeConversion = 100;
  showPriceSlider = false;
  benefitsResponse: IBenefitResponse[];
  isMobile = false;
  filterShow: number;
  tab = 1;
  showFilters = false;
  filteredID: any = {
    type: 'flight',
    values: []
  };

  filtersParamsList: IFilterParams[] = [
    {
      groupTitle: 'Nombre',
      groupName: 'name',
      groupType: FilterGroupType.textAutocompleteFG,
      optionValuePath: 'data.productName',
      optionsIdsPath: 'data.id',
      optionType: FilterDataType.autocompleteFilter,
      //Deben ser expresiones regulares
      includeText: undefined,
      excludeText: undefined
    },
    {
      groupTitle: 'Precio',
      groupName: 'price',
      groupType: FilterGroupType.sliderFG,
      optionValuePath: 'data.productRate',
      optionsIdsPath: 'data.id',
      currencyPath: 'data.productCurrency',
      optionType: FilterDataType.priceFilter,
      //Deben ser expresiones regulares
      includeText: undefined,
      excludeText: undefined
    }
  ];
  textBENEFIT: any;
  userBenefit: any;
  userInfo: AccountSumaryDto;
  formatLabel(value: number) {
    return '€' + value;
  }

  constructor(
    private router: Router,
    public dataStore: DataStoreService,
    public dataService: DataService,
    private _productOfferService: ProductOfferService,
    private _storeLibrary: StoreServiceLibrary,
    private translateService: TranslateService,
    private languageService: LanguageService,
    private initService: InitService,
    private dialog: MatDialog,
  ) {
    this.languageService.systemLanguajeChange.subscribe(language => {
      if (language) {
        this.getTranslations();
      }
    });
  }

  // mostrar listado de resultados de beneficios
  getBenefitList() {
    this.availabilitySubscription = this.dataService.sendRequestBenefitList().subscribe(data => {
      // FORMATEAR DATA PARA FUNCIONAMIENTO DE FILTROS
      this.benefitsResponse = this.formatData(data.benefits, data.queryUUID);
      if (!this.benefitsResponse || !this.benefitsResponse.length) {
        this.dataService.flightSearchError = this.textBENEFIT;
        this.router.navigate(['benefits/noResults']);
      }
      this.dataService.saveSelectedBenefit(this.benefitsResponse[0]);
      this.actualizarPorcentaje(100);
    }, (err) => {
      this.dataService.flightSearchError = this.textBENEFIT;
      this.router.navigate(['benefits/noResults']);
    });
  }

  formatData(data: IBenefitResponse[], queryUUID: string): IBenefitResponse[] {
    data.forEach((benefit, i) => {
      benefit.id = i;
      benefit.price = { totalPrice: benefit.productRate + '' };
      benefit.queryUUID = queryUUID;
    });
    return data;
  }

  ngOnInit() {
    this.dataStore.mainFormTab = 'results';
    this.initService.isInitializedBrand.subscribe(isInitialized => {
      if (isInitialized) {
        this.getBenefitList();
        this.getUserBenefit();
      }
    });
  }

  private getTranslations() {
    this.translateService.get([
      'NO_RESULTS.BENEFIT',
      'BENEFIT_CARD'
    ]).subscribe((trans) => {
      this.textBENEFIT = trans['NO_RESULTS.BENEFIT'] as SearchErrorType;
      this.translations = trans['BENEFIT_CARD'];
    });
  }

  getUserBenefit(): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      if (this.dataService.smartProfile
        && this.dataService.smartProfile.accountResultDto
        && this.dataService.smartProfile.accountResultDto.accountSumaryDto) {
        const userData = this._storeLibrary.getUserData();
        userData.accountSumaryDto = this.dataService.smartProfile.accountResultDto.accountSumaryDto;
        userData.authenticationToken = this.dataService.token;

        this._storeLibrary.setUserData(userData);
        this.userInfo = this.dataService.smartProfile.accountResultDto.accountSumaryDto;

        const subs = this.dataService.sendRequestProductByUser().subscribe(userProduct => {
          this.userBenefit = userProduct;
          if (subs && !subs.closed) {
            subs.unsubscribe();
          }
          resolve(true);
        }, error => {
          UtilsResource.NSMarkLogError('GET USER BENEFIT ERROR', error);
        });
      }
    });
  }

  /**
   * cargar user info
   */
  checkUserInto() {
    // si no estoy logueado, pido loguearse
    if (!this.dataService.token) {
      const dialogRef = this.dialog.open(LoginComponent, {
        panelClass: 'login-dialog'
      });

      dialogRef.afterClosed().subscribe(successful => {
        if (successful) {
          // si ingreso y/o se registró correctamente
          this.getUserBenefit().then((isUserBenefitLoaded) => {
            if (isUserBenefitLoaded) {
              this.seeCertificates();
            }
          });
        }
      });
    } else if (this.dataService.token && !this.userInfo) {
      // si estoy logueado, pero no hay userInfo
      this.getUserBenefit().then((isUserBenefitLoaded) => {
        if (isUserBenefitLoaded) {
          this.seeCertificates();
        }
      });
    } else {
      // si ya estoy logueado y hay user info cargo el tab 1
      this.tab = 1;
    }
  }

  seeCertificates() {
    if (this.userInfo) {
      this.tab = 2;
    } else {
      this.checkUserInto();
    }
  }

  onSelect(item: IBenefitResponse) {
    this.dataService.saveSelectedBenefit(item);
  }

  aplicaFiltro(value) {
    this.maxValueF = value;
    this.filterShow = value;
  }

  newFormatData(data: any) {
    const formattedData: ProductDto[] = [];
    data.forEach((item, i) => {
      item.id = i;
      item.price = {
        totalPrice: item.productRate
      };
      formattedData.push(item);
    });
    return formattedData;
  }

  selectOption() {
    this.router.navigate(['/benefits/registry']);
  }

  actualizarPorcentaje(evt) {
    const conversionRate = this.dataService.conversionRate;
    const userPointsSP = this.dataService.userPointsSP;
    this.dataService.getPriceConversion(this.benefitsResponse, conversionRate, evt ? evt : 0, userPointsSP);
    this.porcentajeConversion = evt;
  }

  filterListener(filteredIDs) {
    this.filteredID = {
      type: 'flight',
      values: filteredIDs.IDs,
      isFiltered: filteredIDs.isFiltered
    };
    if (this.filteredID.values.length > 0) {
      const selectedBenefit = this.benefitsResponse.find(item => {
        return item.id === this.filteredID.values[0];
      });
      this.dataService.saveSelectedBenefit(selectedBenefit);
    }
  }

  ngOnDestroy(): void {
    if (this.availabilitySubscription && !this.availabilitySubscription.closed) {
      this.availabilitySubscription.unsubscribe();
    }
  }

}
