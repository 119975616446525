import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { formatDate } from '@angular/common';
import { locale } from 'moment';
import { LanguageService } from 'src/app/component/language/language.service';
import { IActivityOption } from 'src/app/models/activities/response/activity-availability.model';

@Component({
  selector: 'app-activity-detail-card',
  templateUrl: './activity-detail-card.component.html',
  styleUrls: ['./activity-detail-card.component.scss']
})
export class ActivityDetailCardComponent implements OnInit {

  @Input() isSelected = false;
  @Input() option: IActivityOption;
  @Output() selected = new EventEmitter();

  translations = [];
  meetingPoint = 0;

  get moneyPrice() {
    return this.option.selectedDate.price.calculatedPrice;
  }
  get pointsPrice() {
    return this.option.selectedDate.price.pointsPrice;
  }
  constructor(
    private languageService: LanguageService,
    private translateService: TranslateService
  ) { }

  ngOnInit() {
    this.getTranslations();
  }

  selectOption(option, i = null) {
    if (i) {
      option.selectedDate = option.dates[i];
    }
    delete option.selectedMeetingPoint;
    this.selected.emit(option);
  }

  formatTime(time) {
    try {
      return formatDate(
        time,
        this.translations['DATE']['format_weekday'],
        locale(this.languageService.systemLanguajeChange.value)
      ).toString();
    } catch (e) {
      return time;
    }
  }

  selectMeetingPoint(value) {
    this.option.selectedMeetingPoint = this.option.meetingPoints[value];
    this.selected.emit(this.option);
  }

  getTranslations() {
    return new Promise<any>((resolve, response) => {
      this.languageService.systemLanguajeChange.subscribe(language => {
        if (language) {
          this.translateService.get([
            'DATE'
          ]).subscribe((trns) => {
            this.translations = trns;
            resolve(true);
          });
        }
      });
    });
  }
}
