<div class="title-info title-icon">
  <span [ngClass]="flightIndex == 0 ? 'icon-vuelo-ida' : 'icon-vuelo-vuelta'"
    innerHTML="{{ flightType | translate}}">
    Vuelo de ida
  </span>
  <small matTooltip="{{flight.origin.longName+' / '+flight.destination.longName}}">
    {{flight.origin.longName.substr(0,15)}} /
    {{flight.destination.longName.substr(0,15)}}</small>
</div>

<ng-container
  *ngFor="let segment of flight.segments; let i = index; let l = last;">

  <div class="datos-col row">
    <div class="col-12">
      <span class="mr-5"><strong innerHTML="{{ 'CONFIRMATION.FLIGHT.flight' | translate }}">Vuelo:
        </strong>{{ segment.flightNumber }}</span>
      <span
        class="mr-5"><strong>{{ segment.company.longName }}</strong></span>
    </div>
  </div>

  <div class="datos-col many-col">
    <div class="container-iata">
      <h3 class="iata" matTooltip="{{segment.origin.longName+' - '+segment.destination.longName}}">
        {{ segment.origin.iata }} -
        {{ segment.destination.iata }}</h3>
      <p matTooltip="{{segment.origin.longName+' - '+segment.destination.longName}}">
        {{ segment.origin.longName.substr(0,15) }} -
        {{ segment.destination.longName.substr(0,15) }}</p>
    </div>

    <div>
      <p>
        <strong> {{ segment.departureHour }} a
          {{ segment.arrivalHour }} </strong>
        <span
          *ngIf="data.getDaysDurationDiff(segment.departureDate, segment.arrivalDate) > 0">
          <sup>
            +{{data.getDaysDurationDiff(segment.departureDate, segment.arrivalDate)}}
          </sup>
        </span>
      </p>
      <p>{{ segment.departureDate | date: translationDate['format_weekday'] }}</p>
    </div>

    <div>
      <p><strong>{{ data.getCabinType(segment.info.cabin) }}</strong>
      </p>
    </div>

    <div>
      <p><strong>{{ segment.flightDuration }}</strong></p>
    </div>

    <div>
      <i class="travel-icon icon-maleta"></i>
      <p>
        <strong>{{ data.getBaggageType(segment.info.baggage) }}</strong>
      </p>
    </div>

  </div>

  <ng-container *ngIf="!l">
    <div class="datos-escala">
      <span><strong>{{'SEARCH_FLIGHTS.scale_of'|translate}}
          {{ segment.stopDuration }}</strong></span>
      <small matTooltip="{{ 'CONFIRMATION.FLIGHT.change_plane_in' |translate:'{city:\'' + segment.destination.longName + '\', iata: \'' + segment.destination.iata + '\'}'}}"
        innerHTML="{{ 'CONFIRMATION.FLIGHT.change_plane_in' |translate:'{city:\'' + segment.destination.longName.substr(0,15) + '\', iata: \'' + segment.destination.iata + '\'}'}}">
        Cambio de Avión en
      </small>
    </div>
  </ng-container>

</ng-container>