import { Component, OnInit } from '@angular/core';
import { CardLayoutTwoComponent } from '../card-layout-two.component';
import { CardLayoutTwoModel } from '../../../models/card-layout-two.model';
import { IOptions, ECardType } from '../../../models/options.model';

@Component({
  selector: 'app-card-description',
  templateUrl: './card-description.component.html',
  styleUrls: ['./card-description.component.scss']
})
export class CardDescriptionComponent implements OnInit {

  product: CardLayoutTwoModel;
  options: IOptions;

  constructor(public layout: CardLayoutTwoComponent) {}

  get refundable() {
    const refundable = this.product.refundable();
    const translations = this.layout.translations;
    return refundable === null ? translations['check_refundable']
          : refundable ? translations['refundable']
          : translations['no_refundable'];
  }

  get breakfast() {
    try {
      const info = this.product.info.find(item => item.type === 'breakfast');
      return info ? info : false;
    } catch (e) {
      return false;
    }
  }

  get wifi() {
    try {
      const info = this.product.info.find(item => item.type === 'wifi');
      return info ? info : false;
    } catch(e) {
      return false;
    }
  }

  get ACRISS() {
    try {
      const info = this.product.info.find(item => item.type === 'ACRISS');
      return info;
    } catch(e) {
      return undefined;
    }
  }

  get showAmenities() {
    return this.options.type === ECardType.HOTELS;
  }

  get showNightGuest() {
    return this.options.type === ECardType.ROOMS;
  }

  get showCarACRISS() {
    return this.options.type === ECardType.CARS;
  }

  ngOnInit() {
    this.product = this.layout.product;
    this.options = this.layout.options;
  }

}
