<section class="seccion-5">
    <div class="container">
        <div class="row">
            <div class="col">
                <img class="phone" src="../assets/images/about/en/phone-s5.png" alt="">
            </div>
            <div class="col bl-left">
                <h1 innerHTML="{{'LANDING_ABOUT.SECTION_THIRD.SECTION1.title'| translate}}"></h1>
                <p class="especial" innerHTML="{{'LANDING_ABOUT.SECTION_THIRD.SECTION1.parrafo_default1'| translate: '{appName:\'' + appName +'\'}' }}"></p>

                

                <button (click)="openSection()">Learn more!</button>

            </div>
        </div>
    </div>
</section>

<section class="benefits">
    <div class="arrow-up" (click)="closeSection()"></div>

    <div class="container">

        <div class="bl-row align-top">
            <div class="bl-center">
                <h1>A new world called for a new way of traveling.</h1>
                <p class="intro">All your reservations now include best-in-class travel benefits. Welcome to the hassle-free way of journeying. Welcome to the upper way.</p>

                <ul class="list-benefits">
                    <span><strong>Travel with the flexibility you deserve.</strong></span>
                    <li>
                        <p><strong>100% changeable reservations,</strong> all included in your purchase
                        </p>
                    </li>
                </ul>

                <ul class="list-benefits">
                    <span>Receive instant compensation directly on your Miles Digital Card:</span>
                    <li>
                        <p><strong>US$ 200</strong> for baggage delay</p>
                    </li>
                    <li>
                        <p><strong>US$ 600</strong> for lost baggage</p>
                    </li>
                    <li>
                        <p><strong>US$ 100</strong> of proactive compensation for flight delay of over 6 hours</p>
                    </li>
                </ul>

                <ul class="list-benefits">
                    <span><strong>Traveling is your thing. Caring is ours.</strong></span>
                    <li>
                        <p><strong>US$ 50,000</strong> for medical emergencies - including COVID-19</p>
                    </li>
                    <li>
                        <p><strong>Coverage in Schengen community countries</strong></p>
                    </li>
                </ul>

                <ul class="list-benefits">
                    <span>More benefits than you expected.</span>
                    <li>
                        <p><strong>US$ 500</strong> in purchase protection</p>
                    </li>
                    <li>
                        <p><strong>US$ 500</strong> in personal device protection</p>
                    </li>
                    <li>
                        <p><strong>US$ 5,000,000</strong> in cyber risk protection</p>
                    </li>
                </ul>

                <small>Voyage booking reservations and bundle benefits and services are a proprietary product, provided by Miles by novae. The benefits herein described under sections Fly & Stay (read Terms and Conditions) apply to purchases and/or reservations of flights and hotels. Flex way benefits (changes or modifications at no charge) apply to flight, hotel and car rental reservations.</small>
            </div>
        </div>
    </div>
</section>


<section class="section-6">
    <div class="container">
        <h1 class="center gradient" innerHTML="{{'LANDING_ABOUT.SECTION_THIRD.SECTION2.title'| translate}}"></h1>
        <div class="row">
            <div class="col-md-6 padding-inferior">
                <ul class="lista-numeros">
                    <li><span innerHTML="{{'LANDING_ABOUT.SECTION_THIRD.SECTION2.list_text1'| translate:'{brand:\'' + brand + '\'}'}}"></span></li>
                    <li><span innerHTML="{{'LANDING_ABOUT.SECTION_THIRD.SECTION2.list_text2'| translate}}"></span></li>
                    <li><span innerHTML="{{'LANDING_ABOUT.SECTION_THIRD.SECTION2.list_text3'| translate :'{brand:\'' + brand + '\'}'}}"></span></li>
                </ul>
                <span class="letra-pequenia" innerHTML="{{ 'LANDING_ABOUT.letra_pequenia3' | translate:'{brand:\'' + brand + '\'}' }}"></span>
                <button (click)="openLogin()">{{'LANDING_ABOUT.singIn' | translate}}</button>
            </div>
            <div class="col-md-6">
                <span class="imagen-seccion-mano2"></span>
            </div>
        </div>
    </div>
</section>
<section class="section-7">
    <div class="container">
        <p innerHTML="{{'LANDING_ABOUT.SECTION_THIRD.SECTION3.parrafo_default1'| translate: '{appName:\'' + appName +'\', brand:\'' + brand + '\'}' }}"></p>
        <div class="line"></div>

        <div class="bl-column">
            <span class="gradient" innerHTML="{{'LANDING_ABOUT.SECTION_THIRD.SECTION3.parrafo_default2'| translate:'{brand:\'' + brand + '\'}'}}"></span>
        </div>


        <!-- <div class="bl-experience">
            <h1 class="gradient" innerHTML="{{'LANDING_ABOUT.SECTION_THIRD.SECTION3.parrafo_default3'| translate}}"></h1>

            <div class="row">
                <div class="col">
                    <img src="../assets/images/about/icon-manage.png" alt="">
                    <p innerHTML="{{'LANDING_ABOUT.SECTION_THIRD.SECTION3.list_text1'| translate}}"></p>
                </div>
                <div class="col">
                    <img src="../assets/images/about/icon-alle.png" alt="">
                    <p innerHTML="{{'LANDING_ABOUT.SECTION_THIRD.SECTION3.list_text2'| translate}}"></p>
                </div>
                <div class="col">
                    <img src="../assets/images/about/icon-call.png" alt="">
                    <p innerHTML="{{'LANDING_ABOUT.SECTION_THIRD.SECTION3.list_text3'| translate:'{brand:\'' + brand + '\'}'}}"></p>
                </div>
            </div>
        </div>

        <div class="bl-center">
            <span class="text-download" innerHTML="{{ 'LANDING_ABOUT.text_download'| translate:'{appName:\'' + appName + '\'}' }}"></span>

            <app-download-store [class]="'bl-stores'"></app-download-store>
        </div> -->
    </div>
</section>

<section class="seccion-9">
    <div class="container">

        <div class="row">
            <div class="col-12 col-md-6 padding-inferior">
                <h1 class="gradient" innerHTML="{{'LANDING_ABOUT.SECTION_THIRD.SECTION3.title'| translate}}"></h1>

                <ul class="bl-list">
                    <li innerHTML="{{'LANDING_ABOUT.SECTION_THIRD.SECTION3.list_text4'| translate}}"></li>
                    <li innerHTML="{{'LANDING_ABOUT.SECTION_THIRD.SECTION3.list_text5'| translate}}"></li>
                </ul>
                
            </div>
            <div class="col-12 col-md-6">
                <img class="phone" src="../assets/images/about/en/phone-help.png" alt="">

            </div>
        </div>
    </div>
</section>



<section class="section-8">
    <div class="container">
        <h1 class="gradient" innerHTML="{{'LANDING_ABOUT.SECTION_THIRD.SECTION3.parrafo_default4'| translate}}"></h1>
        <p innerHTML="{{'LANDING_ABOUT.SECTION_THIRD.SECTION3.parrafo_default5'| translate}}"></p>
        <div class="bl-center">
            <p innerHTML="{{'LANDING_ABOUT.SECTION_THIRD.SECTION3.parrafo_default6'| translate: '{appName:\'' + appName +'\'}' }}"></p>
            <!-- <span innerHTML="{{ 'LANDING_ABOUT.text_download'| translate}}"></span> -->
            <app-download-store [class]="'bl-stores'"></app-download-store>
        </div>
    </div>
</section>