import { CardLayoutThreeModule } from './layouts/card-layout-three/card-layout-three.module';
import { CardLayoutThreeComponent } from './layouts/card-layout-three/card-layout-three.component';
import { CardLayoutTwoComponent } from './layouts/card-layout-two/card-layout-two.component';
import { CardLayoutTwoModule } from './layouts/card-layout-two/card-layout-two.module';
import { CardLayoutOneModule } from './layouts/card-layout-one/card-layout-one.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CardComponent } from './card.component';
import { CardLayoutOneComponent } from './layouts/card-layout-one/card-layout-one.component';



@NgModule({
  declarations: [CardComponent],
  imports: [
    CommonModule,
    CardLayoutOneModule,
    CardLayoutTwoModule,
    CardLayoutThreeModule
  ],
  exports: [
    CardComponent
  ],
  entryComponents: [
    CardLayoutOneComponent,
    CardLayoutTwoComponent,
    CardLayoutThreeComponent
  ]
})
export class CardModule { }
