<div class="abstract-card-image-container">
  <div class="row align-items-stretch">

    <app-abstract-card-image class="col-md-4"></app-abstract-card-image>

    <div class="detail-container col-md-4">
      <app-abstract-card-detail></app-abstract-card-detail>
    </div>

    <div class="col-md-4 price-container">
      <app-abstract-card-price></app-abstract-card-price>
    </div>

  </div>
</div>
