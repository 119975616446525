import { Component, OnInit, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-detail-route',
  templateUrl: './detail-route.component.html',
  styleUrls: ['./detail-route.component.scss']
})
export class DetailRouteComponent implements OnInit {

  @Input() segment;
  @Input() isLastSegment;

  get cabin() {
    return this.getCabinType(this.segment.info.cabin);
  }
  get baggage() {
    return this.getBaggageType(this.segment.info.baggage);
  }

  translations: any;
  constructor(private translateService: TranslateService) { }

  ngOnInit() {
    this.translateService.get([
      'LAYOUT_ONE.baggage_pieces',
      'LAYOUT_ONE.one_baggage_piece_included',
      'LAYOUT_ONE.kilograms',
      'LAYOUT_ONE.hand_baggage_only',
      'LAYOUT_ONE.cabin_economy',
      'LAYOUT_ONE.cabin_bussines',
      'LAYOUT_ONE.cabin_first_class',
      'LAYOUT_ONE.cabin_premium_economy',
      'LAYOUT_ONE.cabin_combined',
    ]).subscribe((translations) => {
      this.translations = translations;
    });
  }

  getBaggageType(baggage) {
    let label = '';

    if (baggage.indexOf('PC') > -1) {
      label = ' ' + this.translations['LAYOUT_ONE.baggage_pieces'];
    } else if (baggage.indexOf('K') > -1) {
      label = ' ' + this.translations['LAYOUT_ONE.kilograms'];
    }
    const qty = parseInt(baggage);
    if (qty === 1) {
      return this.translations['LAYOUT_ONE.one_baggage_piece_included'];
    } else if (qty > 1) {
      return qty + label;
    } else {
      return this.translations['LAYOUT_ONE.hand_baggage_only'];
    }
  }
  getCabinType(cabin) {
    switch (cabin) {
      case 'Y':
        return this.translations['LAYOUT_ONE.cabin_economy']; // 'Económica';
        break;
      case 'B':
        return this.translations['LAYOUT_ONE.cabin_bussines']; // 'Ejecutiva';
        break;
      case 'F':
        return this.translations['LAYOUT_ONE.cabin_first_class']; // 'Primera Clase';
        break;
      case 'P':
        return this.translations['LAYOUT_ONE.cabin_premium_economy']; // 'Premium Economica';
        break;
      case 'M':
        return this.translations['LAYOUT_ONE.cabin_combined']; // 'Combinado';
        break;
      default:
        break;
    }
  }

}
