import { Component, OnInit, Output, AfterViewInit, EventEmitter, Input } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { RegistryLocationService } from 'src/app/services/registry-location.service';
import { CountryDto } from '@wearenovae/novae-core-services';
import { DataStoreService } from 'src/app/services/data-store.service';
import { TravellerData } from 'src/app/component/traveller-form/models/form-config.model';

@Component({
   selector: 'app-contact-form',
   templateUrl: './contact-form.component.html',
   styleUrls: ['./contact-form.component.scss']
})
export class ContactFormComponent implements OnInit, AfterViewInit {

   @Output() formIsOk = new EventEmitter();
   @Input() travellerData: TravellerData;

   get defaultTravellerData() {
      return {
         firstname: '',
         lastname: '',
         birthDate: '',
         isoCountry: '0',
         city: '0',
         documentNumber: '',
         email: '',
         phoneCountry: '',
         phoneArea: '',
         phoneNumber: ''
      };
   }

   countryList: CountryDto[];
   cityList: any[] = [];
   citiesActive = false;

   contactForm: FormGroup;

   /**
    * true si el form control name is valid
    */
   isFormControlValid(control: FormControl): boolean {
      return control.valid;
   }

   /**
    * true si el form control name is valid
    */
   isFormControlInvalid(control: FormControl): boolean {
      return control.invalid && control.touched;
   }

   constructor(private registryLocationService: RegistryLocationService,
      public dataStore: DataStoreService) {
   }

   async ngOnInit() {
      await this.registryLocationService.getCountries();

      const pax = (this.travellerData) ? this.travellerData : this.defaultTravellerData;
      this.createForm(pax);

      this.countryList = this.registryLocationService.countries;
      this.fillCityList();

      this.contactForm.statusChanges.subscribe(status => {
         if (status === 'INVALID') {
            this.formIsOk.emit(false);
         } else {
            this.formIsOk.emit(this.contactForm.value);
         }
      });
   }

   createForm(pax) {
      this.contactForm = new FormGroup({
         firstName: new FormControl(pax.firstname,
            [
               Validators.required,
               Validators.pattern('^[a-zA-ZZñÑáéíóúÁÉÍÓÚ]+(?: [a-zA-ZZñÑáéíóúÁÉÍÓÚ]+)* ?$')
            ]),
         lastName: new FormControl(pax.lastname,
            [
               Validators.required,
               Validators.pattern('^[a-zA-ZZñÑáéíóúÁÉÍÓÚ]+(?: [a-zA-ZZñÑáéíóúÁÉÍÓÚ]+)* ?$')
            ]),
         email: new FormControl(pax.email,
            [
               Validators.required,
               Validators.pattern('[a-zA-ZñÑ0-9.!#$%&\'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*')
            ]),
         phone: new FormControl('',
            [
               Validators.required,
               Validators.pattern('[0-9]*')
            ]),
         city: new FormControl(pax.city,
            [
               Validators.required,
               Validators.pattern('^((?!0).)*$')
            ]),
         country: new FormControl(pax.isoCountry,
            [
               Validators.required,
               Validators.pattern('^((?!0).)*$')
            ])
      });
   }

   async fillCityList() {
      if (this.contactForm.value.country && this.contactForm.value.country !== '0') {
         const country = this.countryList.find(item => {
            return item.iso === this.contactForm.value.country;
         });
         await this.registryLocationService.getCities(country.id);
         this.cityList = (this.registryLocationService.cities) ? this.registryLocationService.cities
            : [{
               active: true,
               countryId: 315,
               id: 1253,
               indicative: '0',
               name: 'Otro'
            }];
         this.dataStore.citiesList = this.cityList;

         if (this.cityList != null) {
            this.citiesActive = true;
            this.checkIsValidCity();
         } else if (this.cityList === undefined) {
            this.citiesActive = false;
         }
      } else {
         this.cityList = [];
         this.citiesActive = false;
      }
   }

   ngAfterViewInit() {
   }

   onSubmit() {
   }

   checkIsValidCity() {
      const isInList = this.cityList.find(item => {
         return item.name === this.contactForm.controls.city.value;
      });
      if (!isInList) { this.contactForm.get('city').setValue('0'); }
   }

}
