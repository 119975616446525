import { Component, OnInit, AfterViewInit } from '@angular/core';
import { DataService } from '../../services/data.service';
import { ActionEventService } from '../../services/action-event.service';
import { InitService } from '../../services/init.service';
import { EProduct } from '../../component/searched/models/searched.model';
import { StoreServiceLibrary } from '@wearenovae/novae-core-services';
import { keysUniversal } from 'src/app/resources/keys.resources';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.css']
})
export class NewsComponent implements OnInit, AfterViewInit {

  isoCoutry: string;
  activateDetail: boolean = false;
  newsData = [];
  titleNews: string = '';
  newsDataFilter = [];
  newDetail: object;

  constructor(private init: InitService,
    private actionEventService: ActionEventService,
    private storeLibrary: StoreServiceLibrary) {
    this.init.isInitializedBrand.subscribe((isInit) => {
      if (isInit) {
        this.actionEventService.setItemActiveTab(EProduct.NEWS);
        this.getCards();
        this.getCountryCodeStore();
        this.changeNewsCountry();
        this.filterData();
      }
    });
  }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
  }

  goToDetail(newDetail) {
    this.newDetail = newDetail;
    this.activateDetail = true;
  }

  changeNewsCountry() {
    this.actionEventService.countryCodeChange.subscribe((countryCodeChange) => {
      this.isoCoutry = countryCodeChange.code;
      this.newsDataFilter = [];
      this.filterData();
    });
  }

  getCountryCodeStore() {
    let countryCode = this.storeLibrary.getItemFromStorage('CountrySelected');
    this.isoCoutry = countryCode;
  }

  getCards() {
    let countryNews = JSON.parse(this.storeLibrary.getJustOneUniversalPropertie(keysUniversal.NEWS_CARDS));
    this.newsData = countryNews['cards'];
    this.titleNews = countryNews['title'];
  }

  showNews(event) {
    this.activateDetail = event;
  }

  filterData() {
    this.newsData.map((newInfo) => {
      if (newInfo.countryCode == this.isoCoutry) {
        this.newsDataFilter.push(newInfo);
      }
    });
  }


}
