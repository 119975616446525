import { Component, OnInit, AfterViewInit, OnDestroy, Renderer2 } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AccountSumaryDto, AuthService, StoreServiceLibrary, UserData } from '@wearenovae/novae-core-services';
import { DataService } from '../../services/data.service';
import { InitService } from '../../services/init.service';
import { environment } from '../../../environments/environment';
import { DataStoreService } from '../../services/data-store.service';


@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit, AfterViewInit, OnDestroy {

  token: string;
  channel: string;
  lang: string;

  newPwd = '';
  confirmPwd = '';
  message: string;
  expreg = new RegExp('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$');
  respuesta: any;
  userId: string;
  showForm: boolean;
  errorServiceMessage: boolean;

  classPwd1 = false;
  classPwd2 = false;

  useJwtToken: boolean;
  userData: UserData;

  email: string;
  href = 'milescare://com.upperclub.lifemiles/home';
  languaje: string;
  appName: string;
  showButton: boolean;

  isActiveCard = false;
  isResetPass = false;
  activeReset = true;


  constructor(private render: Renderer2,
    private router: ActivatedRoute,
    private translate: TranslateService,
    private authService: AuthService,
    private dataService: DataService,
    private storeLibrary: StoreServiceLibrary,
    private initService: InitService,
    private storeService: DataStoreService) { }

  ngOnInit(): void {
    this.getParams();
    this.showForm = true;
    this.useJwtToken = this.dataService.getJwtUse();
    this.initService.isInitializedBrand.subscribe((isInit) => {
      if (isInit) {
        this.appName = environment.brands[this.storeService.brand].appName;
      }
    });
  }

  ngAfterViewInit() {
    this.getNabHeader();
  }


  getParams() {
    this.router.queryParams.subscribe((params) => {
      if (params['token']) {
        this.token = params['token'];
      }

      if (params['channel']) {
        this.channel = params['channel'];
      }

      if (params['lang']) {
        this.lang = params['lang'];
      }

    });
  }

  async validatePass() {
    if (!this.expreg.test(this.newPwd)) {
      this.message = await this.translate.get('RECOVERY_PASS.patternPass').toPromise();
      this.classPwd1 = true;
      return;
    }

    if (this.confirmPwd.length === 0) {
      this.message = await this.translate.get('RECOVERY_PASS.confirmPass').toPromise();
      this.classPwd2 = true;
      return;
    }

    if (this.newPwd !== this.confirmPwd) {
      this.message = await this.translate.get('RECOVERY_PASS.matchPass').toPromise();
      return;
    }

    this.vaidateEmailUser();
    this.isResetPass = true;
    this.activeReset = false;
    this.authService.resetPassword(this.channel, this.token, this.newPwd, this.confirmPwd, this.useJwtToken).subscribe((resp) => {
      this.email = resp['email'];
      this.isResetPass = true;
      this.activeReset = false;
    }, (err) => {
      if (err.code === '100') {
        this.message = err.message;
      } else {
        this.errorService();
      }
    });

  }

  async errorService() {
    this.message = await this.translate.get('RECOVERY_PASS.matchPass').toPromise();
  }

  vaidateEmailUser() {
    this.userData = this.storeLibrary.getUserData();
    if (this.userData.accountSumaryDto) {
      if (!this.userData.accountSumaryDto.emailaddress) {
        this.userData.accountSumaryDto.emailaddress = '';
      }
    } else {
      this.userData.accountSumaryDto = new AccountSumaryDto();
      this.userData.accountSumaryDto.emailaddress = '';
    }

    this.storeLibrary.setUserData(this.userData);
  }

  cleanErros() {
    this.classPwd1 = false;
    this.classPwd2 = false;
    this.message = '';
  }

  getNabHeader() {
    const elementRef = document.getElementById('appNav');
    this.render.setStyle(elementRef, 'display', 'none');
  }

  ngOnDestroy(): void {
    const elementRef = document.getElementById('appNav');
    this.render.setStyle(elementRef, 'display', 'block');
  }


}
