<div class="bl-general">
    <h2 class="nombre-hotel">{{hotel.name}} </h2>

    <p class="direccion-hotel">{{hotel.address}}</p>
    <p class="numero-habitaciones">{{roomName}}</p>
    <p class="numero-habitaciones">{{board}}</p>

    <div class="registro-persona">
        <small class="dias">
            {{nights}}
        </small>
        <small class="personas">
            <i class="travel-icon icon-people"></i>{{guests}}
        </small>
        <small class="habitaciones">
            <i class="travel-icon icon-stepper-acommodation"></i>{{rooms}}
        </small>
    </div>

    <div class="registro-persona cancellationPolicies">
        <h2 innerHTML="{{'PURCHASE_SUMARY.HOTEL.cancellation_policies' | translate}}">
            Políticas de cancelación
        </h2>
        <span class="no-cancellationPolicies" *ngIf="!cancellationPolicies; else mostrarPoliticas">
            <i class="icon-reembolsable"></i>
            {{ translationsHotel['no_cancellation_policies'] }}
        </span>
        <ng-template #mostrarPoliticas>
            <div class="with-cancellationPolicies">
                <i class="icon-reembolsable"></i>
                <ul>
                    <li *ngFor="let value of cancellationPolicies.refundableInfo.cancellationPolicy">
                        <span>
                            <strong innerHTML="{{ 'PURCHASE_SUMARY.cancellation_from' | translate:'{date:\'' + (fromDate(value)) + '\'}' }}">{{ value.from | date: translationDate['pipe_date'] }}</strong>
                            <!-- -
                            {{
                                value.to
                                ? (value.to|date: translationDate['pipe_date'])
                                : translationsHotel['no_limit_date']
                            }} -->
                        </span>
                        <br>

                        <small *ngIf="value.percent === '0.0'" innerHTML="{{ 'PURCHASE_SUMARY.penalty' | translate: '{amount:\'' + currency + (value.amount | milesFormat) + '\'}'}}"></small>
                        <small *ngIf="+value.amount === 0 && value.percent !== '0.0'" innerHTML="{{ 'PURCHASE_SUMARY.penalty_percent' | translate: '{amount:\'' + value.percent + '\'}'}}"></small>
                    </li>
                </ul>
            </div>
        </ng-template>
    </div>

    <div class="icon-detalles"></div>

    <div class="b-right">
        <div class="detail-date">
            <h2 innerHTML="{{'PURCHASE_SUMARY.HOTEL.check_in' | translate }}">
                Check in
            </h2>
            <div class="icon-detalles in"></div>
            <small class="info-detalle">
                {{dateCheckIn}}
            </small>
        </div>

        <div class="detail-date">
            <h2 innerHTML="{{'PURCHASE_SUMARY.HOTEL.check_out' | translate }}">
                Check out
            </h2>
            <div class="icon-detalles out"></div>
            <small class="info-detalle">
                {{dateCheckOut}}
            </small>
        </div>
    </div>
</div>