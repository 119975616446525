import { IPlace, IRange } from './searched.model';
import { BehaviorSubject } from 'rxjs';
import { formatDate } from '@angular/common';
import { locale } from 'moment';
import moment from 'moment';

export class SearchedFlight {
    origin: IPlace;
    destination: IPlace;
    journey: 'oneWay' | 'idaVuelta';
    date: IRange;
    quantity: {
        adults: number;
        ninos: number;
        babes: number;
    };
    cabinClass: {
        economy: boolean;
        economyPremium: boolean;
        executive: boolean;
        first: boolean;
        combinada: boolean;
    };

    private get translations() {
        return this.translations$.value['ISearched'] ? this.translations$.value['ISearched']['SearchedFlight'] : [];
    }

    private get translationsDate() {
        return this.translations$.value['DATE'] || [];
    }

    constructor(
        public obj: SearchedFlight,
        private translations$: BehaviorSubject<any>,
        private language$: BehaviorSubject<any>
    ) {
        if (!this.obj.origin) {
            this.obj.origin = { type: '', id: 0, name: '', iata: ''};
        }
        if (!this.obj.destination) {
            this.obj.destination = { type: '', id: 0, name: '', iata: '' };
        }
        if (!this.obj.quantity) {
            this.obj.quantity = { adults: 0, ninos: 0, babes: 0};
        }
        if (!this.obj.cabinClass) {
            this.obj.cabinClass = { economy: true, economyPremium: false, executive: false, first: false, combinada: false };
        }
        this.origin = this.obj.origin;
        this.destination = this.obj.destination;
        this.journey = this.obj.journey;
        this.date = this.obj.date;
        this.quantity = this.obj.quantity;
        this.cabinClass = this.obj.cabinClass;
    }

    /**
     * retorna las fechas en formato "humano legible"
     * resolviendo el formato para cada idioma
     */
    get dateToHumanReadable(): IRange {
        return {
            start: formatDate(
                this.date.start,
                this.translationsDate['format_weekday'],
                locale(this.language$.value)
            ).toString(),
            end: formatDate(
                this.date.end,
                this.translationsDate['format_weekday'],
                locale(this.language$.value)
            ).toString()
        };
    }

    /**
     * retorna cantidad de días entre la ida y la vuelta
     */
    get qDays(): number {
        return moment(this.date.end).diff(this.date.start, 'days') + 1;
    }

    /**
     * retorna cantidad de días entre la ida y la vuelta más texto "días" traducido
     */
    get qDaysToString(): string {
        return this.qDays + ' ' + (this.qDays === 1 ? this.translations['day'] : this.translations['days']);
    }

    get qAdultsToString(): string {
        return this.quantity.adults + ' ' + (this.quantity.adults === 1 ? this.translations['adult'] : this.translations['adults']);
    }

    get qNinosToString(): string {
        return this.quantity.ninos + ' ' + (this.quantity.adults === 1 ? this.translations['child'] : this.translations['children']);
    }

    get qBebesToString(): string {
        return this.quantity.babes + ' ' + (this.quantity.adults === 1 ? this.translations['infant'] : this.translations['infants']);
    }

    get journeyToHumanReadable(): string {
        return this.translations[this.journey];
    }
}
