<div class="row text-center">
    <div class="col-5 origin-destination">
        <p>{{ segment.departureHour }}</p>
        <p matTooltip="{{segment.origin.longName}}">{{ segment.origin.longName.substr(0,15) }}</p>
        <p class="iata" matTooltip="{{segment.origin.longName}}">
            {{ segment.origin.iata }}
        </p>
    </div>
    <div class="col-2"></div>
    <div class="col-5 origin-destination">
        <p>{{ segment.arrivalHour }}</p>
        <p matTooltip="{{segment.destination.longName}}">{{ segment.destination.longName.substr(0,15) }}</p>
        <p class="iata" matTooltip="{{segment.destination.longName}}">
            {{ segment.destination.iata }}
        </p>
    </div>
</div>

<div class="info-container">
    <div class="airline-img">
        <div>
            <img src="/assets/airlines/{{segment.company.iata}}.png" alt="logo" class="img-fluid" appFallBackImg="/assets/airlines/default.png" onerror="this.src='/assets/airlines/default.png';">
            <span>
                {{'SEGMENT.DETAIL_ROUTE.flight_text'|translate}}
                <br>
                <strong>{{segment.flightNumber}}</strong>
            </span>
        </div>
    </div>
    <div class="cabin">
        <span>{{ cabin }}</span>
    </div>
    <div class="duration">
        <span>
            {{'SEGMENT.DETAIL_ROUTE.duration'|translate}}
            <br>
            <strong>{{segment.flightDuration}}</strong>
        </span>
    </div>
    <div class="baggage">
        <i class="travel-icon icon-luggage d-block"></i>
        <span>{{ baggage }}</span>
    </div>
</div>