<div #personasCmp class="bl-personas" name="personas">
    <div class="passengers">
        <h5 innerHTML="{{'PASSENGERS.passengers'|translate}}">
            Passengers
        </h5>
        <div class="persons">
            <div class="item">
                <span innerHTML="{{'PASSENGERS.adults'|translate:'{value:\'+11\'}'}}">
          Adults (+11)
        </span>
                <div class="range-age">
                    <a class="menos" (click)="menos('A')"></a>
                    <input type="number" readonly [(ngModel)]="qAdultos">
                    <a class="mas" (click)="mas('A')" [ngClass]="{ 'disable-more': disabledMore }"></a>
                </div>
            </div>
            <div class="item">
                <span innerHTML="{{'PASSENGERS.children'|translate:'{value:\'2-11\'}'}}">
          Children (2-11)
        </span>
                <div class="range-age">
                    <a class="menos" (click)="menos('N')"></a>
                    <input type="number" readonly [(ngModel)]="qNinos">
                    <a class="mas" (click)="mas('N')" [ngClass]="{ 'disable-more': disabledMore }"></a>
                </div>
            </div>
            <div class="item">
                <span innerHTML="{{'PASSENGERS.infants'|translate:'{value:\'-2\'}'}}">
          Infants (-2)
        </span>
                <div class="range-age">
                    <a class="menos" (click)="menos('B')"></a>
                    <input type="number" readonly [(ngModel)]="qBebes">
                    <a class="mas" (click)="mas('B')" [ngClass]="{ 'disable-more': disabledMoreBebes }"></a>
                </div>
            </div>
            <div *ngIf="validateQPassengers" class="alert_red" innerHTML="{{'PASSENGERS.at_least_one_passenger'|translate}}">
                At least one passenger
            </div>
        </div>
<!--         <h5 innerHTML="{{'PASSENGERS.class'|translate}}">
            Class
        </h5>
        <div class="row cabinRow">

            <div class="col-6">
                <mat-checkbox [(ngModel)]="economica">
                    <span innerHTML="{{'PASSENGERS.economy'|translate}}">
            Economy
          </span>
                </mat-checkbox>
            </div>

            <div class="col-6">
                <mat-checkbox [(ngModel)]="ejecutiva">
                    <span innerHTML="{{'PASSENGERS.executive'|translate}}">
            Executive
          </span>
                </mat-checkbox>
            </div>

            <div class="col-6">
                <mat-checkbox [(ngModel)]="economicaPremium">
                    <span innerHTML="{{'PASSENGERS.econ_premium'|translate}}">
            Econ premium
          </span>
                </mat-checkbox>
            </div>

            <div class="col-6">
                <mat-checkbox [(ngModel)]="primera">
                    <span innerHTML="{{'PASSENGERS.first'|translate}}">
            First
          </span>
                </mat-checkbox>
            </div>
        </div>
        <div *ngIf="validateCabin" class="alert_red" innerHTML="{{'PASSENGERS.choose_class'|translate}}">
            Please choose class
        </div> -->
    </div>
    <div class="bg-btn"></div>
    <div class="btn-aplicar" (click)="pullData()" innerHTML="{{'PASSENGERS.apply'|translate}}">
        Apply
    </div>
</div>