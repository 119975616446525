import { Component, OnInit, Input } from '@angular/core';
import moment from 'moment';
import { LanguageService } from '../../../../../language/language.service';

@Component({
  selector: 'app-detail-date',
  templateUrl: './detail-date.component.html',
  styleUrls: ['./detail-date.component.scss']
})
export class DetailDateComponent implements OnInit {

  @Input() flight;
  constructor(private languageService: LanguageService) { }

  ngOnInit() {
  }

  SegmentDepartureDate(departureDate) {
    return moment(departureDate).locale(this.languageService.systemLanguajeChange.value).format('ddd DD/MM');
  }
}
