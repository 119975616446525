<section class="gray section-cero">
    <div class="container">
        <div class="bl-coming section-friday">
            <div class="bl-center">

                <div class="bl-avaliable">
                    {{'LANDING.'+brand+'.SECTION_FIRST.SECTION0.available_now' | translate}}
                </div>

                <app-black-friday *ngIf="brand !='firstcitizens'"></app-black-friday>


                <h1>{{'LANDING.'+brand+'.SECTION_FIRST.SECTION0.say_hello_to' | translate}}</h1>
                <span class="text-gradient"><strong>{{'LANDING.'+brand+'.SECTION_FIRST.SECTION0.title_app_name' |
                        translate}}</strong></span>

                <h3>{{'LANDING.'+brand+'.SECTION_FIRST.SECTION0.description_1' | translate}}
                    <strong>{{'LANDING.'+brand+'.SECTION_FIRST.SECTION0.description_2' | translate}}
                        <br>{{'LANDING.'+brand+'.SECTION_FIRST.SECTION0.description_3' | translate}}</strong>
                    <br>{{'LANDING.'+brand+'.SECTION_FIRST.SECTION0.description_4' | translate}}
                </h3>

                <div class="store">
                    <span>{{'LANDING.'+brand+'.SECTION_FIRST.SECTION0.download_app' | translate}}</span>
                    <app-download-store [class]="'logo'"></app-download-store>
                </div>
                <img class="img-coming" src="./assets/images/{{brand}}-miles/bg-mobiles.png" alt="fc">
            </div>
        </div>
    </div>
</section>


<section class="section-uno">
    <div class="container">
        <h1 class="text-gradient">{{'LANDING.'+brand+'.SECTION_FIRST.SECTION1.jump_in' | translate}}</h1>

        <div class="bl-row">
            <div class="bl-left">
                <div class="bloque-video">
                    <div class="iphone-video">
                        <video class="video-vimeo" loop muted playsinline autoplay oncanplay="this.play()"
                            onloadedmetadata="this.muted=true">
                            <source [src]="urlsVideos.two.url | srcSanitizer" type="video/mp4">
                        </video>
                    </div>
                    <img class="imagen-video" src="./assets/images/{{brand}}-miles/bg-iphone-miles.png" alt="">
                </div>
            </div>
            <div class="bl-right">
                <ul class="items-number">
                    <li><span>{{'LANDING.'+brand+'.SECTION_FIRST.SECTION1.1' | translate}}</span>
                        <p>{{'LANDING.'+brand+'.SECTION_FIRST.SECTION1.list_desc_1' |
                            translate}}<strong><br>{{'LANDING.'+brand+'.SECTION_FIRST.SECTION1.reg_desc_1' |
                                translate}}</strong></p>
                    </li>
                    <li><span>{{'LANDING.'+brand+'.SECTION_FIRST.SECTION1.2' | translate}}</span>
                        <p>{{'LANDING.'+brand+'.SECTION_FIRST.SECTION1.reg_desc_2' | translate}}</p>
                    </li>
                    <li><span>{{'LANDING.'+brand+'.SECTION_FIRST.SECTION1.3' | translate}}</span>
                        <p>{{'LANDING.'+brand+'.SECTION_FIRST.SECTION1.reg_desc_3' | translate}}</p>
                    </li>
                    <li><span>{{'LANDING.'+brand+'.SECTION_FIRST.SECTION1.4' | translate}}</span>
                        <p innerHTML="{{'LANDING.'+brand+'.SECTION_FIRST.SECTION1.reg_desc_4' | translate}}"></p>
                    </li>
                </ul>
            </div>
        </div>

        <!-- <mat-tab-group mat-align-tabs="center">
            <mat-tab label="{{ 'LANDING.'+brand+'.SECTION_FIRST.SECTION0.already_registered' | translate }}">
                <div class="bl-row">
                    <div class="bl-left">
                        <div class="bloque-video">
                            <div class="iphone-video">
                                <video class="video-vimeo" loop muted playsinline autoplay oncanplay="this.play()" onloadedmetadata="this.muted=true">
                                    <source [src]="urlsVideos.one.url | srcSanitizer" type="video/mp4">
                                </video>
                            </div>
                            <img class="imagen-video" src="./assets/images/{{brand}}-miles/bg-iphone-miles.png" alt="">
                        </div>
                    </div>
                    <div class="bl-right">
                        <ul class="items-number">
                            <li><span>{{'LANDING.'+brand+'.SECTION_FIRST.SECTION1.1' | translate}}</span>
                                <p>{{'LANDING.'+brand+'.SECTION_FIRST.SECTION1.list_desc_1' | translate}}<strong>{{'LANDING.'+brand+'.SECTION_FIRST.SECTION1.list_desc_1.1' | translate}}</strong></p>
                            </li>
                            <li><span>{{'LANDING.'+brand+'.SECTION_FIRST.SECTION1.2' | translate}}</span>
                                <p>{{'LANDING.'+brand+'.SECTION_FIRST.SECTION1.list_desc_2' | translate}}<strong>{{'LANDING.'+brand+'.SECTION_FIRST.SECTION1.list_desc_2.1' | translate}}</strong>{{'LANDING.'+brand+'.SECTION_FIRST.SECTION1.list_desc_2.2' |
                                    translate}}
                                </p>
                            </li>
                            <li><span>{{'LANDING.'+brand+'.SECTION_FIRST.SECTION1.3' | translate}}</span>
                                <p>{{'LANDING.'+brand+'.SECTION_FIRST.SECTION1.list_desc_3' | translate}}</p>
                            </li>
                            <li><span>{{'LANDING.'+brand+'.SECTION_FIRST.SECTION1.4' | translate}}</span>
                                <p>{{'LANDING.'+brand+'.SECTION_FIRST.SECTION1.list_desc_4' | translate}}<strong>{{'LANDING.'+brand+'.SECTION_FIRST.SECTION1.list_desc_4.1' | translate}}
                                    </strong>{{'LANDING.'+brand+'.SECTION_FIRST.SECTION1.list_desc_4.2' | translate}}<strong>{{'LANDING.'+brand+'.SECTION_FIRST.SECTION1.list_desc_4.3' | translate}}</strong></p>
                            </li>
                        </ul>
                    </div>
                </div>
            </mat-tab>

            <mat-tab label="Not registered yet?">
                <div class="bl-row">
                    <div class="bl-left">
                        <div class="bloque-video">
                            <div class="iphone-video">
                                <video class="video-vimeo" loop muted playsinline autoplay oncanplay="this.play()" onloadedmetadata="this.muted=true"> 
                                    <source [src]="urlsVideos.two.url | srcSanitizer"  type="video/mp4">
                                </video>
                            </div>
                            <img class="imagen-video" src="./assets/images/{{brand}}-miles/bg-iphone-miles.png" alt="">
                        </div>
                    </div>
                    <div class="bl-right">
                        <ul class="items-number">
                            <li><span>{{'LANDING.'+brand+'.SECTION_FIRST.SECTION1.1' | translate}}</span>
                                <p>{{'LANDING.'+brand+'.SECTION_FIRST.SECTION1.list_desc_1' | translate}}<strong>{{'LANDING.'+brand+'.SECTION_FIRST.SECTION1.reg_desc_1' | translate}}</strong></p>
                            </li>
                            <li><span>{{'LANDING.'+brand+'.SECTION_FIRST.SECTION1.2' | translate}}</span>
                                <p>{{'LANDING.'+brand+'.SECTION_FIRST.SECTION1.reg_desc_2' | translate}}</p>
                            </li>
                            <li><span>{{'LANDING.'+brand+'.SECTION_FIRST.SECTION1.3' | translate}}</span>
                                <p>{{'LANDING.'+brand+'.SECTION_FIRST.SECTION1.reg_desc_3' | translate}}</p>
                            </li>
                            <li><span>{{'LANDING.'+brand+'.SECTION_FIRST.SECTION1.4' | translate}}</span>
                                <p>{{'LANDING.'+brand+'.SECTION_FIRST.SECTION1.reg_desc_4' | translate}}</p>
                            </li>
                            <li><span>{{'LANDING.'+brand+'.SECTION_FIRST.SECTION1.5' | translate}}</span>
                                <p>{{'LANDING.'+brand+'.SECTION_FIRST.SECTION1.reg_desc_5' | translate}} <strong>{{'LANDING.'+brand+'.SECTION_FIRST.SECTION1.reg_desc_5.1' | translate}}</strong></p>
                            </li>
                        </ul>
                    </div>
                </div>

            </mat-tab>
        </mat-tab-group> -->

        <div class="download">
            <h2 class="text-gradient"><strong>{{'LANDING.'+brand+'.SECTION_FIRST.SECTION1.reg_download' |
                    translate}}<br> {{'LANDING.'+brand+'.SECTION_FIRST.SECTION1.reg_download_2' | translate}}</strong>
            </h2>
            <div class="store">
                <app-download-store [class]="'logo'"></app-download-store>
            </div>
        </div>
    </div>
</section>

<section class="section-uno">
    <div class="container">
        <h2 class="text-gradient" innerHTML="{{'LANDING.'+brand+'.SECTION_TWO.SECTION_LSHOP.desc_1' | translate}}"></h2>

        <ul class="logos-coming logos-fc"
            *ngIf="brand ==='firstcitizens' || brand === 'cibc' || brand === 'bancoindustrial'">
            <li><img src="./assets/images/{{brand}}-miles/logof-amazon.png" alt="mr"></li>
            <li><img src="./assets/images/{{brand}}-miles/logof-ebay.png" alt="mr"></li>
            <li><img src="./assets/images/{{brand}}-miles/logof-apple.png" alt="mr"></li>
            <li><img src="./assets/images/{{brand}}-miles/logof-uber.png" alt="mr"></li>

            <li><img src="./assets/images/{{brand}}-miles/logof-netflix.png" alt="mr"></li>
            <li><img src="./assets/images/{{brand}}-miles/ticketmaster.png" alt="ncb"></li>
            <li><img src="./assets/images/{{brand}}-miles/logof-microsoft.png" alt="mr"></li>
            <li><img src="./assets/images/{{brand}}-miles/airbnb.png" alt="ncb"></li>
        </ul>
        <ul class="logos-coming logos-fc logos-comercios-apap"
            *ngIf="brand ==='firstcitizens'|| brand ==='apap' || brand ==='myrewards' || brand ==='ncb'">
           <div class="bl-first">
            <li><img src="./assets/images/{{brand}}-miles/logof-amazon.png" alt="mr"></li>
            <li><img src="./assets/images/{{brand}}-miles/logof-ebay.png" alt="mr"></li>
            <li><img src="./assets/images/{{brand}}-miles/logof-macys.png" alt="mr"></li>
            <li><img src="./assets/images/{{brand}}-miles/airbnb.png" alt="ncb"></li>
           </div> 
            <li><img src="./assets/images/{{brand}}-miles/logof-hbomax.png" alt="ncb"></li>
            <li><img src="./assets/images/{{brand}}-miles/logof-disney.png" alt="ncb"></li>
            <li><img src="./assets/images/{{brand}}-miles/logof-prime.png" alt="ncb"></li>
            <li><img src="./assets/images/{{brand}}-miles/logof-netflix.png" alt="mr"></li>
            <li><img src="./assets/images/{{brand}}-miles/logof-spotify.png" alt="ncb"></li>
            <li><img src="./assets/images/{{brand}}-miles/logof-microsoft.png" alt="mr"></li>
            <li><img src="./assets/images/{{brand}}-miles/logof-apple.png" alt="mr"></li>
            <li><img src="./assets/images/{{brand}}-miles/logof-uber.png" alt="mr"></li>
            <li><img src="./assets/images/{{brand}}-miles/logof-starb.png" alt="ncb"></li>
            <li><img src="./assets/images/{{brand}}-miles/ticketmaster.png" alt="ncb"></li>
        </ul>

        <div class="row-shoponline">
            <div class="bl-item">
                <p innerHTML="{{'LANDING.'+brand+'.SECTION_TWO.SECTION_LSHOP.desc_2' | translate}}"></p>
                <button class="btn button" (click)="openSection('1')">
                    {{'LANDING.'+brand+'.SECTION_THREE.SECTION_MPOWER.btnLeranMore' | translate}}
                </button>
            </div>
            <div class="bl-item">
                <p innerHTML="{{'LANDING.'+brand+'.SECTION_TWO.SECTION_LSHOP.desc_3' | translate}}"></p>
                <button class="btn button" (click)="openSection('2')">
                    {{'LANDING.'+brand+'.SECTION_THREE.SECTION_MPOWER.btnLeranMore' | translate}}
                </button>
            </div>
        </div>
    </div>
</section>