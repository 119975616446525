import { BehaviorSubject, Observable } from 'rxjs';
import { Injectable, Inject, OnInit } from '@angular/core';
import { DataService } from './data.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DataStoreService } from './data-store.service';
import { UniversalService, StoreServiceLibrary, GeneralUtilService } from '@wearenovae/novae-core-services';
import { environment } from 'src/environments/environment';
import { DOCUMENT } from '@angular/common';
import { UtilsResource } from '../resources/UtilsResource.resource';
import { GenerateService } from './generate.service';


@Injectable({
  providedIn: 'root'
})
export class InitService implements OnInit {

  /**
   * emite true cuando el brand esta inicializado
   */
  isInitializedBrand = new BehaviorSubject(false);
  idApplication: string;

  universalJwtUrl: string;
  universalJwtUN: string;
  universalJwtPW: string;
  useJwtToken: boolean;

  contGetConfig = 0;
  contUniversal = 0;

  constructor(
    @Inject(DOCUMENT) private _DOCUMENT,
    public router: Router,
    public dataService: DataService,
    public activatedRoute: ActivatedRoute,
    public dataStore: DataStoreService,
    private universalService: UniversalService,
    private storeLibrary: StoreServiceLibrary,
    private _generalUtilService: GeneralUtilService,
    private _generalService: GenerateService,
  ) {
    this.initBrand().then(() => {
      this.getConfigUser().then(() => {
        this.initApplicationProperties().then((arePropertiesInitialized) => {
          if (arePropertiesInitialized) {
            this.isInitializedBrand.next(true);
          } else {
            UtilsResource.NSMarkLogError('ERROR INIT BRAND: ', 'BRAND properties uninitialized');
          }
        });
      }, err => {
        UtilsResource.NSMarkLogError('ERROR INIT BRAND', 'BRAND uninitialized');
      });
    }, ((err) => {
      UtilsResource.NSMarkLogError('ERROR INIT BRAND', 'BRAND uninitialized');
    }));
  }

  ngOnInit() {
  }

  /**
   * El brand puede llegar por multiples lugares
   * cada lugar tiene una prioridad. leer el brand desde:
   * - URL, si no
   * - SESION, si no
   * - queryParams, si no
   * - default ('novae')
   */
  private initBrand(): Promise<boolean> {
    this.isWordPresentInUrl();
    return new Promise<any>((resolve, reject) => {

      // obtener el brand según orden de prioridad

      this.getBrandFromQueryParams().then((isBrandSetFromQueryParams) => {
        if (this.setBrandFrom('URL')) {
          resolve(true);
        } else if (isBrandSetFromQueryParams) {
          this.setBrandFrom('SESSION');
          resolve(true);
        } else if (this.setBrandFrom('default')) {
          resolve(true);
        } else {
          // error de lógica de la función
          // siempre debe existir algún brand
          reject();
        }
      });

    });
  }

  /**
   * lee el brand desde donde le piden por parámetro
   * si pudo obtener el brand desde ese lugar retorna true, si no retorna false
   */
  private setBrandFrom(LOGIC: 'URL' | 'SESSION' | 'default'): boolean {
    switch (LOGIC) {

      case 'URL':
        // obtener el brand de la URL
        return this.isWordPresentInUrl();
      case 'SESSION':
        // obtener el brand de una variable de session
        if (this.dataStore.brand) {
          this.dataService.saveBrand(this.dataStore.brand);
          return true;
        }
        break;
      case 'default':
        // setear el brand default (cuando no hay otro de mayor prioridad)
        this.dataService.saveBrand('miles');
        return true;
    }

    // no se pudo obtener el brand desde el lugar solicitado
    return false;
  }

  /**
   * retorna true si word está presente en la url
   */
  private isWordPresentInUrl() {
    const mapBrand = new Map(Object.entries(environment.subdomainBrands));
    for (const entry of mapBrand.entries()) {
      if (this._DOCUMENT.location.host.toLowerCase().indexOf(entry[0].toLowerCase()) > -1) {
        sessionStorage.setItem('brand', `${entry[1]}`);
        sessionStorage.setItem('key', `${entry[0]}`);
        this.dataService.saveBrand(entry[1]);
        return true;
      }
    }
    return false;
  }

  /**
   * le damos XX milisegundos al sistema,
   * para que obtenga el brand desde los query params
   */
  private getBrandFromQueryParams(): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      let timeout;
      const subs = this.activatedRoute.queryParams.subscribe(params => {

        if (timeout) {
          clearTimeout(timeout);
        }
        timeout = setTimeout(() => {
          // no hay brand desde queryParams
          resolve(null);
          subs.unsubscribe();
        }, 200);

        // brand desde queryParams
        if (params['brand'] !== undefined) {
          this.dataService.saveBrand(params['brand']);
          subs.unsubscribe();
          resolve(true);
        } else if (params['channelid'] !== undefined) {
          // tslint:disable-next-line: forin
          for (const brand in environment.brands) {
            if (environment.brands[brand].channelId.toString() === params['channelid']) {
              this.dataService.saveBrand(brand);
              break;
            }
          }
          subs.unsubscribe();
          resolve(true);
        }
      });
    });
  }

  /**
   * cargar urls del sistema, según el brand, desde la biblioteca de novae
   */
  private initApplicationProperties() {
    const secretKey = atob(environment.brands[this.dataStore.brand].idSK);
    const URL_UNIVERSAL = environment.brands[this.dataStore.brand].novaeUniversalUrlBase;
    this.idApplication = environment.brands[this.dataStore.brand].applicationId;
    this.universalJwtUrl = environment.brands[this.dataStore.brand].UNIVERSAL_GET_JWTTOKEN_URL || '';
    this.universalJwtUN = environment.brands[this.dataStore.brand].UNIVERSAL_GET_JWTTOKEN_UN || '';
    this.universalJwtPW = environment.brands[this.dataStore.brand].UNIVERSAL_GET_JWTTOKEN_PW || '';
    this.useJwtToken = this.dataService.getJwtUse();

    const fpuniversal = environment.brands[this.dataStore.brand].fpUniversal || null;
    const fpUniversaljwt = environment.brands[this.dataStore.brand].fpUniversaljwt || null;
    const reqPass = environment.brands[this.dataStore.brand].reqPass;
    const resPass = environment.brands[this.dataStore.brand].resPass;

    return new Promise<boolean>((resolve, reject) => {
      this.universalService.getAllPropertiesByApplicationidVSDecrypt(
        URL_UNIVERSAL,
        btoa(this.idApplication + ''),
        secretKey,
        atob(this.universalJwtUrl),
        atob(this.universalJwtUN),
        atob(this.universalJwtPW),
        this.useJwtToken,
        fpuniversal,
        fpUniversaljwt
      ).toPromise()
        .then((res) => {
          /*/ ACA - Test
          res.propertyDtoList.find((item) => { if (item.key.includes('_TYPE_URLS_EXTERNAL_TRAVEL')) item.value = '[{"url":"https://scotia-rewards.com/#/travel-app?PaymentMethod=OnePocket","name":"flights","lang":{"es":"Vuelos","en":"Flights"},"active":true},{"url":"https://scotia-rewards.com/#/travel-app?PaymentMethod=OnePocket","name":"hotels","lang":{"es":"Hoteles","en":"Hotels"},"active":true},{"url":"https://scotia-rewards.com/#/travel-app?PaymentMethod=OnePocket","name":"cars","lang":{"es":"Autos","en":"Cars"},"active":true},{"url":"https://scotia-rewards.com/#/travel-app?PaymentMethod=OnePocket","name":"activities","lang":{"es":"Actividades","en":"activities"},"active":false},{"url":"https://scotia-rewards.com/#/travel-app?PaymentMethod=OnePocket","name":"vacation homes","lang":{"es":"casas vacacionales","en":"vacation homes"},"active":false},{"url":"https://scotia-rewards.com/#/travel-app?PaymentMethod=OnePocket","name":"transfers","lang":{"es":"Transferencias","en":"Transfers"},"active":false}]'}); 
          //*/

          this.storeLibrary.setUniversalProperties(res.propertyDtoList);
          // this.getJWTTokenService.setJwtTokenAuth(true);
          // this.getJWTTokenService.setJwtTokenCreditCard(true);
          resolve(true);
        }).catch((err) => {
          resolve(false);
        });
    });
  }

  getConfigUser() {
    return new Promise((res, rej) => {
      
      let urlConfig = environment.brands[this.dataStore.brand].CONFIG_USER_GET_JWTTOKEN_URL || '';
      let localDate = atob(environment.brands[this.dataStore.brand].timeZoneDefault || '');
      let languajeTimeDefault = atob(environment.brands[this.dataStore.brand].languajeTimeDefault || '');

      this.idApplication = environment.brands[this.dataStore.brand].applicationId;
      this.universalJwtUN = environment.brands[this.dataStore.brand].UNIVERSAL_GET_JWTTOKEN_UN || '';
      this.universalJwtPW = environment.brands[this.dataStore.brand].UNIVERSAL_GET_JWTTOKEN_PW || '';
      this.useJwtToken = this.dataService.getJwtUse();

      if (this.contGetConfig == 0) {
        let date = new Date();
        this._generalUtilService.setLocalDate(date);
      } else {
        let date = new Date().toLocaleString(languajeTimeDefault, {
          timeZone: localDate
        });

        let dateTmp = new Date(date);

        this._generalUtilService.setLocalDate(dateTmp);
      }

      let isCallGetCountry = this._generalService.getCallGetCountry();
      if (!isCallGetCountry) {
        res(true);
        return;
      }

      this.universalService.getConfigGlobalUser(urlConfig, null).subscribe((data: any) => {

        let isValidLocalDate = this.dataService.validateLocalDate(data?.timezone, data?.date);
        if (isValidLocalDate) {
          res(true);
        } else {
          res(true);
          // this.loadUniversalZoneDefault();
        }
      }, err => {
        this.contGetConfig++;
        if (this.contGetConfig == 1) {
          // this.getConfigUserDefault();
          res(true);
          // rej(true);
        } else {
          rej(true);
        }
      });
    });
  }

  loadUniversalZoneDefault() {
    this.contUniversal++;
    let localDate = atob(environment.brands[this.dataStore.brand].timeZoneDefault || '');
    let languajeTimeDefault = atob(environment.brands[this.dataStore.brand].languajeTimeDefault || '');

    let date = new Date().toLocaleString(languajeTimeDefault, {
      timeZone: localDate
    });

    let dateTemp = new Date(date);
    UtilsResource.NSMarkLog('DATE TEMP - ', dateTemp);
    this._generalUtilService.setLocalDate(dateTemp);

    if (this.contUniversal == 1) {
      this.initApplicationProperties().then((arePropertiesInitialized) => {
        if (arePropertiesInitialized) {
          this.isInitializedBrand.next(true);
        } else {
          UtilsResource.NSMarkLogError('ERROR INIT BRAND: ', 'BRAND properties uninitialized');
        }
      });
    }
  }

  getConfigUserDefault() {
    this.getConfigUser().then(resp => {
      this.initApplicationProperties().then((arePropertiesInitialized) => {
        if (arePropertiesInitialized) {
          this.isInitializedBrand.next(true);
        } else {
          UtilsResource.NSMarkLogError('ERROR INIT BRAND: ', 'BRAND properties uninitialized');
        }
        UtilsResource.ShowLog('Universal LOAD FINISH - getConfigUserDefault');
      }, err => {
        UtilsResource.ShowLog('Universal ERROR - getConfigUserDefault');
      });
    }, err => {
      UtilsResource.ShowLog('Universal ERROR - getConfigUserDefault');

    })
  }

}

