<div class="container">
  <div class="b-registro-pasajeros">
    <div class="row">
      <div class="col-xs-12" id="no-worries">
        <div id="tittleBenefits" class="tittle display-none" >
          <span innerHTML="{{'LANDING.'+brand+'.ONEPOCKETPAY.notEnough' | translate}}">Not enough<br>points?
            No<br>problem.</span>
          <p innerHTML="{{'LANDING.'+brand+'.ONEPOCKETPAY.event' | translate}}">Even if you don't have enough points,
            you can always split your payment.</p>
        </div>
        <div id="bannerHeaderBenefits" class="display-none">
          <div class="banner-expedia">
            <div class="content">
              <img class="logo" src="./assets/images/cryptmi/logo-expedia.png" alt="cryptmi">
              <img class="rewards" src="./assets/images/cryptmi/rewards.png" alt="cryptmi">
            </div>
          </div>
        </div>
        <div class="benefits-proposal benefits-flex-traveling"
          *ngIf="brand == 'scotia' || brand == 'firstcitizens' || brand == 'cibc' || brand == 'apap' || brand == 'ncb' || brand == 'myrewards' || brand == 'cryptmi'">
          <div class="traveling">
            <h2 id="newWordBenefits" class="taveling__tittle"
              innerHTML="{{'LANDING.'+brand+'.ONEPOCKETPAY.new_world' | translate}}"></h2>
            <p id="yourReservationsBenefits" class="traveling__paragraph"
              innerHTML="{{'LANDING.'+brand+'.ONEPOCKETPAY.your_reservations' | translate}}"></p>
            <div class="traveling__block block-first">
              <div class="traveling__block_left">
                <div class="traveling__bl traveling__bl--banner traveling__bl--banner-1 traveling__bl--last">
                  <img [src]="benefitsImages[language].image1" alt="">
                </div>
              </div>
              <div class="traveling__block_right">
                <div class="traveling__bl traveling__bl--brand">
                  <span class="icon-bullet"></span>
                  <p class="traveling__text traveling__text--bullet"
                    innerHTML="{{'LANDING.'+brand+'.ONEPOCKETPAY.changeable' | translate}}"></p>
                </div>
                <div class="traveling__bl traveling__bl--second traveling__bl--blocks">
                  <div class="traveling__item">
                    <span class="icon-bullet"></span>
                    <p class="traveling__text" innerHTML="{{'LANDING.'+brand+'.ONEPOCKETPAY.instant' | translate}}"></p>
                  </div>
                  <div class="traveling__block traveling__block--inside">
                    <div class="traveling__block_left traveling__block_left--inside">
                      <div class="traveling__bl traveling__bl--first traveling__bl--column">
                        <span innerHTML="{{'LANDING.'+brand+'.ONEPOCKETPAY.boyage_value' | translate}}"></span>
                        <p class="traveling__inside"
                          innerHTML="{{'LANDING.'+brand+'.ONEPOCKETPAY.baggage' | translate}}"></p>
                      </div>
                      <div class="traveling__bl traveling__bl--first traveling__bl--column traveling__bl--last">
                        <span innerHTML="{{'LANDING.'+brand+'.ONEPOCKETPAY.lost_value' | translate}}"></span>
                        <p class="traveling__inside" innerHTML="{{'LANDING.'+brand+'.ONEPOCKETPAY.lost' | translate}}">
                        </p>
                      </div>
                    </div>
                    <div class="traveling__block_right traveling__block_right--inside">
                      <div
                        class="traveling__bl traveling__bl--first traveling__bl--content-first traveling__bl--column traveling__bl--last">
                        <span innerHTML="{{'LANDING.'+brand+'.ONEPOCKETPAY.proactive_value' | translate}}"></span>
                        <p class="traveling__inside"
                          innerHTML="{{'LANDING.'+brand+'.ONEPOCKETPAY.proactive' | translate}}"></p>
                      </div>
                    </div>
                  </div>              
                </div>
                <div class="traveling__flex">
                  <div class="traveling__bl traveling__bl--second traveling__bl--flex-bloque traveling__bl--last">
                    <span class="icon-bullet"></span>
                    <p class="traveling__text" innerHTML="{{'LANDING.'+brand+'.ONEPOCKETPAY.carrier' | translate}}"></p>
                  </div>
                  <div class="traveling__info" *ngIf="showTermsAndConditions">
                    <div class="icon-info" (click)="openTyC()"></div>
                  </div>
                </div>
                <p class="terminoscondiciones TyCSection" innerHTML="{{'LANDING.'+brand+'.ONEPOCKETPAY.termAndConditions' | translate: { 'urlTermAndConditions': urlTermAndConditions } }}">
                  Aplican Términos y Condiciones. <a href='#' target="_blank">Haz clic aquí</a> para conocer más.
                </p>

              </div>
            </div>
            <div class="traveling__block">
              <div class="traveling__block_left">
                <div class="traveling__bl traveling__bl--banner traveling__bl--banner-2 traveling__bl--last">
                  <img [src]="benefitsImages[language].image2" alt="">
                </div>
              </div>
              <div class="traveling__block_right">
                <div class="traveling__bl traveling__bl--third traveling__bl--community">
                  <div class="block-bl">
                    <span class="icon-bullet"></span>
                    <p class="traveling__text traveling__text--bullet" innerHTML="{{'LANDING.'+brand+'.ONEPOCKETPAY.medical' | translate}}">
                      <span class="wrap">COVID-19</span>
                    </p>
                  </div>
                  <div class="block-bl">
                    <img [src]="srcCommunity" alt="">
                    <p class="traveling__text traveling__text--bullet"
                      innerHTML="{{'LANDING.'+brand+'.ONEPOCKETPAY.medical_2' | translate}}"></p>
                  </div>
                </div>
                <div class="traveling__bl  traveling__bl--fourth traveling__bl--last">
                  <ul>
                    <li>
                      <span class="icon-bullet"></span>
                      <p class="traveling__text" innerHTML="{{'LANDING.'+brand+'.ONEPOCKETPAY.sports' | translate}}">
                      </p>
                    </li>
                    <li>
                      <span class="icon-bullet"></span>
                      <p class="traveling__text" innerHTML="{{'LANDING.'+brand+'.ONEPOCKETPAY.dental' | translate}}">
                      </p>
                    </li>
                    <li>
                      <span class="icon-bullet"></span>
                      <p class="traveling__text"
                        innerHTML="{{'LANDING.'+brand+'.ONEPOCKETPAY.prescribed' | translate}}">
                      </p>
                    </li>
                    <li>
                      <span class="icon-bullet"></span>
                      <p class="traveling__text"
                        innerHTML="{{'LANDING.'+brand+'.ONEPOCKETPAY.emergency_pregnancy' | translate}}">
                      </p>
                    </li>
                    <li>
                      <span class="icon-bullet"></span>
                      <p class="traveling__text"
                        innerHTML="{{'LANDING.'+brand+'.ONEPOCKETPAY.emergency_evacuation' | translate}}">
                      </p>
                    </li>
                  </ul>
                </div>  
                <!-- <p class="terminoscondiciones">Aplican Términos y Condiciones. <a href='#'>Haz clic aquí</a> para conocer más.</p> -->
                <p class="terminoscondiciones TyCSection" innerHTML="{{'LANDING.'+brand+'.ONEPOCKETPAY.termAndConditions' | translate: { 'urlTermAndConditions': urlTermAndConditions } }}">
                  Aplican Términos y Condiciones. <a href='#' target="_blank">Haz clic aquí</a> para conocer más.
                </p>
              
                <div class="traveling__info" *ngIf="showTermsAndConditions">
                  <div class="icon-info" (click)="openTyC()"></div>
                </div>
              </div>
            </div>
            <div class="traveling__block">
              <div class="traveling__block_left">
                <div class="traveling__bl traveling__bl--banner traveling__bl--banner-3 traveling__bl--last">
                  <img [src]="benefitsImages[language].image3" alt="">
                </div>
              </div>
              <div class="traveling__block_right">
                <div class="traveling__row">
                  <div class="traveling__block_left traveling__block_left--row">
                    <div class="traveling__bl traveling__bl--fifth traveling__bl--column">
                      <img [src]="srcPurchase" alt="">
                      <span
                        innerHTML="{{'LANDING.'+brand+'.ONEPOCKETPAY.purchase_protection_value' | translate}}"></span>
                      <p class="traveling__inside traveling__inside--benefits"
                        innerHTML="{{'LANDING.'+brand+'.ONEPOCKETPAY.purchase_protection' | translate}}"></p>
                    </div>
                  </div>
                  <div class="traveling__block_right traveling__block_left--row">
                    <div class="traveling__bl traveling__bl--fifth traveling__bl--column">
                      <img [src]="srcPersonal" alt="">
                      <span
                        innerHTML="{{'LANDING.'+brand+'.ONEPOCKETPAY.personal_protection_value' | translate}}"></span>
                      <p class="traveling__inside"
                        innerHTML="{{'LANDING.'+brand+'.ONEPOCKETPAY.personal_protection' | translate}}"></p>
                    </div>
                  </div>
                </div>
                <div class="traveling__flex traveling__flex--benefits">
                  <div class="traveling__bl traveling__bl--fifth traveling__bl--benefits traveling__bl--last">
                    <div class="icon">
                      <img [src]="srcCyber" alt="">
                    </div>
                    <div class="text">
                      <p class="traveling__text" innerHTML="{{'LANDING.'+brand+'.ONEPOCKETPAY.cyber_risk' | translate}}">
                      </p>
                    </div>
                  </div>

                  <div class="traveling__info" *ngIf="showTermsAndConditions">
                    <div class="icon-info" (click)="openTyC()"></div>
                  </div>
                </div>
                <p class="terminoscondiciones TyCSection" innerHTML="{{'LANDING.'+brand+'.ONEPOCKETPAY.termAndConditions' | translate: { 'urlTermAndConditions': urlTermAndConditions } }}">
                  Aplican Términos y Condiciones. <a href='#' target="_blank">Haz clic aquí</a> para conocer más.
                </p>

              </div>              
            </div>      
            <div class="traveling__block traveling__block--full traveling__upper-person" *ngIf="brand != 'scotia'">
              <div class="left">
                <h3 class="traveling__title" innerHTML="{{'LANDING.'+brand+'.ONEPOCKETPAY.earn_banner_title' | translate}}">
                </h3>
                <img class="traveling__img" [src]="srcUpperMilesPerson" alt="">
              </div>
              <div class="right">
                <p class="traveling__txt" innerHTML="{{'LANDING.'+brand+'.ONEPOCKETPAY.earn_banner_descript' | translate}}"></p>
              </div>
              <div class="traveling__info" *ngIf="showTermsAndConditions">
                <div class="icon-info" (click)="openTyC()"></div>
              </div>
            </div>
          </div>
          <p class="traveling__text traveling__text--bullet tittle-terminos">
            <span innerHTML="{{'LANDING.'+brand+'.ONEPOCKETPAY.voyage_reservations_title' | translate}}"></span>
          </p>
          <p class="benefits-proposal__text" innerHTML="{{'LANDING.'+brand+'.ONEPOCKETPAY.voyage_reservations' | translate}}">
          </p>
        </div>
        <div class="benefits-protegido bene-desktop" *ngIf="brand === 'credomatic'">
          <h1>¡Estás a punto de viajar con los mejores beneficios!</h1>
          <div class="bl-bene">
            <app-coupon-promotion></app-coupon-promotion>
            <!-- <div class="coupon" *ngIf="promotion?.code && typePromotion == 'CODE' && brand == 'credomatic'">
                <div class="description">
                  <h2>¡ Tenemos un cupón de descuento para ti !</h2>
                  <h3 *ngIf="promotion.typeDiscount == 'PORCENTAGE'">Hasta <span>{{ promotion.percentage }}</span>% Dto.</h3>
                  <h3 *ngIf="promotion.typeDiscount == 'VALUEDISCOUNT'">Hasta <span>US$ {{ promotion.valueDiscount }}</span></h3>

                  <h2 *ngIf="promotion?.namePromotion">En tu compra con {{ promotion.namePromotion }}</h2>
                </div>
                <div class="block-code">
                  <p>
                    Agregalo después de seleccionar tu forma de pago
                    <span *ngIf="promotion?.currency == 'MONEY'">en dinero</span>
                    <span *ngIf="promotion?.currency == 'MILES'">en puntos</span>
                  </p>
                  <div class="code">
                    {{ promotion?.code }}
                  </div>
                  <span>Válido hasta {{ promotion?.fechafin | date:"dd/MM/yyyy" }}</span>
                </div>
              </div>
  
              <div class="discount" *ngIf="promotion && typePromotion == 'SYSTEM' && brand == 'credomatic'">
                <div class="description">
                  <h2 *ngIf="promotion.typeDiscount == 'PORCENTAGE'">Descuentos <br> hasta {{ promotion.percentage }}%</h2>
                  <h2 *ngIf="promotion.typeDiscount == 'VALUEDISCOUNT'">Descuentos <br> hasta US$ {{ promotion.valueDiscount }}</h2>
                  <h2 *ngIf="promotion?.namePromotion">En tu compra con {{ promotion.namePromotion }}</h2>
                  <img src="assets/images/credomatic-miles/discount.svg" alt="">
                </div>
                <div class="date">
                  <span>Válido hasta {{ promotion?.fechafin | date:"dd/MM/yyyy" }}</span>
                </div>
              </div> -->
            <div class="bloque">
              <div class="bl-img">
                <img src="assets/images/credomatic-miles/icon-1.svg" alt="">
                <span>Haz que tu viaje sea flexible: Viaja <strong>flex</strong>.</span>
              </div>
              <div class="bl-text">
                <ul>
                  <li><strong>Sin costo adicional por cambios o cancelaciones.</strong> Todas las reservas son 100%
                    modificables.
                  </li>
                  <li><strong>Compensación instantánea en tu tarjeta digital Miles:</strong>
                    <ul class="internas">
                      <li><strong>US$ 200</strong> por demora de equipaje.</li>
                      <li><strong>US$ 600</strong> por pérdida de equipaje.</li>
                      <li><strong>US$ 100</strong> por retraso de vuelo por más de 6 horas.</li>
                      <li><strong>US$ 500</strong> por pérdida, robo o daño de artículos comprados durante el viaje.
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
            <div class="bloque">
              <div class="bl-img">
                <img src="assets/images/credomatic-miles/icon-2.svg" alt="">
                <span>Viaja tranquilo con <strong>protección COVID-19</strong></span>
              </div>
              <div class="bl-text">
                <ul>
                  <li><strong>US$ 50.000 por asistencia médica</strong> y dental de emergencia incluyendo protección
                    COVID-19.</li>
                  <li><strong>Transporte de emergencia sin costo</strong> en caso de evacuación médica.</li>
                  <li><strong>Alojamiento y gastos diarios incluidos</strong> por causa de enfermedad o accidente.</li>
                </ul>
              </div>
            </div>
            <div class="bloque">
              <div class="bl-img">
                <img src="assets/images/credomatic-miles/icon-3.svg" alt="">
                <span>Gana más beneficios. ¡Gana <strong>upperMiles!</strong></span>
              </div>
              <div class="bl-text">
                <ul>
                  <li><strong>Acumula 3X más millas</strong> cuando reservas con puntos, MillasPlus y demás recompensas.
                  </li>
                  <li><strong>Combina tus puntos y recompensas</strong> con tus tarjetas BAC Credomatic.</li>
                  <li><strong>Unifica todos tus programas de lealtad en uno solo.</strong></li>
                  <li><strong>Paga en cuotas sin interés*.</strong></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>