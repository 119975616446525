<div>
  <div class="row">

    <div class="col-md-4 col-12 image-container">
      <app-card-image class="thumbnail-container ps-0"></app-card-image>
    </div>

    <div class="col-md-4 col-12 detail-container">
      <app-card-detail></app-card-detail>
    </div>

    <div class="col-md-4 col-12 text-center price-container">
      <app-card-price></app-card-price>
    </div>

  </div>
</div>
