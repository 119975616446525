<form [formGroup]="form" novalidate>
  <div class="origen-destino">
    <div class="origenes-in bl-salida">
      <label>
        <strong>{{ 'FIXED_RATES.origin' | translate }}</strong>
      </label>
      <ng-select formControlName="origen" class="autocompleteSearch" id="origen" [items]="allCitiesOrigin"
        bindLabel="airport" [searchFn]="returnTrue" dropdownPosition="bottom" placeholder="{{placeholderOrigin}}"
        [virtualScroll]="true" (click)="onSearchOrigin($event)" [notFoundText]="notFoundText" #origen
        [searchable]="false">
        <ng-template ng-option-tmp let-item="item" let-search="searchTerm">
          <div>
            <span>{{item.airport}}, {{item.country}}</span>
          </div>
        </ng-template>
      </ng-select>
      <div class="alert_red" *ngIf="originNotSelected"
        innerHTML="{{'SEARCH_FLIGHTS.please_indicate_origin'|translate}}">
        Please indicate the origin of your trip
      </div>
      <div class="alert_red" *ngIf="validatorOriginDestiniDif"
        innerHTML="{{'SEARCH_FLIGHTS.must_be_different'|translate}}">
        Origin and destination must be different
      </div>
    </div>
    <div class="ciudades icon-invert" (click)="invertFlightSelection()">
      <span class="icono-invertir"></span>
    </div>
    <div class="origenes-in bl-llegada">
      <label>
        <strong>{{ 'FIXED_RATES.destination' | translate }}</strong>
      </label>
      <ng-select formControlName="destino" class="autocompleteSearch" [ngClass]="{'disabled': notHaveIdOrigin}"
        id="destino" [items]="allCitiesDestinations" bindLabel="airport" groupBy="typeString" [searchFn]="returnTrue"
        dropdownPosition="bottom" placeholder="{{placeholderDestination}}" [virtualScroll]="true"
        (click)="onSearchDestinations($event)" [notFoundText]="notFoundText" #destino [searchable]="false"
        [hideSelected]="notHaveIdOrigin">
        <ng-template ng-option-tmp let-item="item" let-search="searchTerm">
          <div>
            <span>{{item.airport}}, {{item.country}}</span>
          </div>
        </ng-template>
      </ng-select>
      <div class="alert_red" *ngIf="destinationNotSelected"
        innerHTML="{{'SEARCH_FLIGHTS.please_indicate_destination'|translate}}">
        Please indicate the destination of your trip
      </div>
      <div class="alert_red" *ngIf="validatorOriginDestiniDif"
        innerHTML="{{'SEARCH_FLIGHTS.must_be_different'|translate}}">
        Origin and destination must be different
      </div>
    </div>
  </div>
  <div class="bl-input-date">
    <!--     <div class="tramoClasss">
      <label innerHTML="{{'SEARCH_FLIGHTS.journey'|translate}}">
        Journey
      </label>
      <ng-select class="tramoClass" [searchable]="false" [clearable]="false" labelForId="Ida y vuelta"
        formControlName="tramo" (change)="tramoFn($event)">
        <ng-option value="oneWay" [innerHTML]="'SEARCH_FLIGHTS.one_way'|translate">
          One way
        </ng-option>
        <ng-option value="idaVuelta" [innerHTML]="'SEARCH_FLIGHTS.round_trip'|translate">
          Round trip
        </ng-option>
      </ng-select>
    </div> -->

    <div class="fecha-ida">
      <div class="fecha-inputs" *ngIf="tramoCalendar === false">
        <div class="bl-input-icon">
          <label [innerHTML]="'SEARCH_FLIGHTS.departure'|translate">
            <strong>Departure</strong>
          </label>
          <input #dateStart name="dateStart" [(ngModel)]="selected" class="dateSelect form-control" type="text"
            appCalendarSearch readonly formControlName="dateSelect" placeholder="Select Date" [linkedCalendars]="true"
            [showDropdowns]="true" [autoApply]="true" [locale]="locale" [singleDatePicker]="true" [minDate]="minDate"
            [dateShowTogether]="false" [isStart]="true" />
        </div>
        <div class="bl-input-icon icon-last">
          <label [innerHTML]="'SEARCH_FLIGHTS.return'|translate">
            <strong>Return</strong>
          </label>
          <input #dateEnd name="dateEnd" [(ngModel)]="selected" class="dateSelect form-control" type="text"
            appCalendarSearch readonly formControlName="dateSelect" placeholder="Select Date" [linkedCalendars]="true"
            [showDropdowns]="true" [autoApply]="true" [locale]="locale" [singleDatePicker]="true" [minDate]="minDate"
            [dateShowTogether]="false" [isEnd]="true" />
        </div>
      </div>

      <div class="fecha-inputs" *ngIf="tramoCalendar === true ">
        <div class="bl-input-icon bl-oneway ">
          <input [(ngModel)]="selected" class="dateSelect form-control" type="text" ngxDaterangepickerMd readonly
            formControlName="dateSelect" lastDayOfPreviousMonthClass="disabled-previous-day"
            firstDayOfNextMonthClass="disabled-nex-day" placeholder="{{palceholderSelectDate}}" [showDropdowns]="true"
            [linkedCalendars]="true" [autoApply]="true" [locale]="locale" [singleDatePicker]="true"
            [minDate]="minDate" />
        </div>
      </div>
      <div class="alert_red" *ngIf="dateNotSelected" [innerHTML]="'SEARCH_FLIGHTS.indicate_start_end'|translate">
        You must indicate the start and end date of your trip
      </div>
    </div>

    <div class="pasajero" (click)="showPassengerFormFn()" [attr.aria-expanded]="!showPassengerForm">
      <label [innerHTML]="'SEARCH_FLIGHTS.passengers'|translate">
        <strong>Passengers and Class</strong>
      </label>
      <input readonly class="pasangerForm" [placeholder]="placeholderPassenger" attr-open-app-pasajeros="true" />
      <app-pasajeros-fixed-rates [ngbCollapse]="showPassengerForm" (sendDataPassenger)="loadDataPasanger($event)"
        [cabinClassSelected]="typeSelect" [disabledMore]="disabledMore">
      </app-pasajeros-fixed-rates>
      <div class="alert_red" *ngIf="passengersNotSelected"
        [innerHTML]="'SEARCH_FLIGHTS.number_of_passengers'|translate">
        You must indicate the number of passengers
      </div>
      <div class="alert_red" *ngIf="dateNotSelected" [innerHTML]="'SEARCH_FLIGHTS.indicate_start_end'|translate">
        You must indicate the start and end date of your trip
      </div>
    </div>
    <div class="bl-class disable">
      <label [innerHTML]="'SEARCH_FLIGHTS.class'|translate">
        <strong>Class</strong>
      </label>
      <input readonly class="pasangerForm" [placeholder]="typeSelect" />
    </div>
  </div>
</form>
<div class="buscar" (click)="search($event)" [ngClass]="{'btn-disable': !form.valid, 'btn-active': form.valid}">
  <span class="btn" [innerHTML]="'SEARCH_FLIGHTS.search'|translate"
    [ngClass]="{'btn-disable': !form.valid, 'btn-active': form.valid}">
    Search
  </span>
</div>