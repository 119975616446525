import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { locale } from 'moment';
import { formatDate } from '@angular/common';
import { Router } from '@angular/router';
import { DataService } from '../../../../services/data.service';
import moment from 'moment';
import { DataStoreService } from '../../../../services/data-store.service';
import { ViewEncapsulation } from '@angular/core';
import { DebounceCall } from '../../../../decorators/debounce.decorator';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { TruncatePipe } from 'src/app/pipes/truncate.pipe';
import { SearchedService } from 'src/app/component/searched/searched.service';
import { SearchedCar } from 'src/app/component/searched/models/searched-car.model';
import { LanguageService } from 'src/app/component/language/language.service';

import data from 'src/app/resources/screenAndEventsTracking.json';
import { GoogleAnalytics } from '../../../../resources/GoogleAnalytics.resource';
import { InitService } from '../../../../services/init.service';

export interface CarAvailabilityRequest {
  start: string;
  end: string;
  hour: string;
  hourEnd: string;
  originZone: string;
  destinationZone: string;
  countryOfResidence: string;
  ageDriver: string;
  targetCurrency: string;
  language?: string;
}

/**
 * detalle de búsqueda de un Car (lo que muestra en app-main-form)
 */
export interface ICarInfo {
  lugarRetiroDevolucionIguales: boolean;
  retiro: {
    iata: string,
    name: string,
    fecha: string,
    hora: string
  };
  devolucion: {
    iata: string,
    name: string,
    fecha: string,
    hora: string
  };
}

@Component({
  selector: 'app-search-cars',
  templateUrl: './search-cars.component.html',
  styleUrls: ['./search-cars.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SearchCarsComponent implements OnInit {

  @ViewChild('dateStart') dateStart;
  @ViewChild('dateEnd') dateEnd;

  minDateEnd;
  selected = {
    startDate: moment(new Date()),
    endDate: moment(new Date())
  };

  dropOffNotSelected: boolean;
  pickUpNotSelected: boolean;
  start: string;
  end: string;
  hour: string;
  hourEnd: string;
  dateHourStartText: string;
  dateHourEndText: string;
  totalNights: any;
  originZone: string;
  destinationZone: string;
  countryOfResidence: string;
  ageDriver: string;
  driverAges = [];
  validatorC: boolean;
  searchFormInvalid: boolean;
  requestInfo: CarAvailabilityRequest;
  minDate = moment(new Date());
  locale: any = {
    applyLabel: '',
    format: '',
    separator: ' - ',
  };
  selectedPickUpIata: string;
  selectedPickUpName: string;
  selectedDropOffIata: string;
  selectedDropOffName: string;
  allCities = [];
  carsSearchForm: FormGroup;
  placeholderPlace: string;
  placeholderSelectdate: string;
  textDay: string;
  textDays: string;
  notFoundText: string;
  textWithdrawalReturnIn: string;
  textWithdrawalIn: string;
  textReturnIn: string;
  textRental: string;
  textReturnDate: string;
  dateWeekdayFormat: string;
  refresh = true;
  searchSbs: Subscription;
  ageNotSelected: boolean;

  get qDays() {
    return this.carsSearchForm.value.dateSelect.endDate.diff(this.carsSearchForm.value.dateSelect.startDate, 'days');
  }

  constructor(
    private data: DataService,
    private router: Router,
    public dataStore: DataStoreService,
    private languageService: LanguageService,
    private translateService: TranslateService,
    private truncatePipe: TruncatePipe,
    private searchedService: SearchedService,
    private initService: InitService
  ) {
    this.carsSearchForm = this.createFormCarSearchGroup();
    this.languageService.systemLanguajeChange.subscribe((languaje) => {
      if (languaje) {
        this.getTranslations();
      }
    });
  }

  ngOnInit() {
    // this.trackingManager.setCurrentScreen('/cars', data.CARS.carsHomeScreen);
    this.pickUpNotSelected = false;
    this.searchFormInvalid = false;
    this.ageNotSelected = false;
    // Seteo de valores de edad para conductores
    for (let i = 18; i <= 99; i++) {
      this.driverAges.push(i);
    }
    this.carsSearchForm.get('dropOff').disable();
    this.initService.isInitializedBrand.subscribe((isInit) => {
      if (isInit) {
        GoogleAnalytics.setCurrentScreen('/cars');
      }
    });
  }

  private getTranslations() {
    this.translateService.get([
      'DATE.format_with_time',
      'DATE.format_weekday',
      'SEARCH_CARS.city_airport',
      'SEARCH_CARS.select_date',
      'SEARCH_CARS.apply',
      'SEARCH_CARS.text_day',
      'SEARCH_CARS.text_days',
      'SEARCH_CARS.not_found_text',
      'SEARCH_CARS.withdrawal_return_in',
      'SEARCH_CARS.withdrawal_in',
      'SEARCH_CARS.return_in',
      'SEARCH_CARS.rental',
      'SEARCH_CARS.return_date'
    ]).subscribe((translations) => {

      // local para los calendarios
      locale(this.languageService.systemLanguajeChange.value);
      this.locale.format = translations['DATE.format_with_time'];
      this.locale.daysOfWeek = moment.weekdaysMin();
      this.locale.monthNames = moment.monthsShort();

      // fix hack para actualizar los selectores de fecha
      this.refresh = !this.refresh;
      setTimeout(() => {
        this.refresh = !this.refresh;
      }, 100);

      this.dateWeekdayFormat = translations['DATE.format_weekday'];
      this.placeholderPlace = translations['SEARCH_CARS.city_airport'];
      this.placeholderSelectdate = translations['SEARCH_CARS.select_date'];
      this.locale.applyLabel = translations['SEARCH_CARS.apply'];
      this.textDay = translations['SEARCH_CARS.text_day'];
      this.textDays = translations['SEARCH_CARS.text_days'];
      this.notFoundText = translations['SEARCH_CARS.not_found_text'];
      this.textWithdrawalReturnIn = translations['SEARCH_CARS.withdrawal_return_in'];
      this.textWithdrawalIn = translations['SEARCH_CARS.withdrawal_in'];
      this.textReturnIn = translations['SEARCH_CARS.return_in'];
      this.textRental = translations['SEARCH_CARS.rental'];
      this.textReturnDate = translations['SEARCH_CARS.return_date'];
    });
  }
  updateAgeValidator() {
    this.ageNotSelected = false;
  }
  createFormCarSearchGroup() {
    return new FormGroup({
      pickUp: new FormControl(null, [Validators.required]),
      dropOff: new FormControl(null, [Validators.required]),
      dateSelect: new FormControl({
        startDate: moment(new Date()),
        endDate: moment(new Date())
      }, [Validators.required]),
      driverAge: new FormControl(null, [Validators.required]),
    });
  }

  @DebounceCall(250)
  onSearch($event) {
    if (this.searchSbs && !this.searchSbs.closed) {
      // si la busqueda anterior no terminó, la cancelamos
      this.searchSbs.unsubscribe();
    }

    this.allCities = [];
    const searched = $event.term;
    if (searched.length < 3) {
      return;
    }

    // si la busqueda es de al menos 3 letras
    this.searchSbs = this.data.getCities($event.term).subscribe(cities => {
      this.allCities = cities;
      this.searchSbs.unsubscribe();
    });
  }

  /**
   * hamemos que el ng-select retorne siempre true en el search
   * porque lo que filtramos es cambar el array de items (onSearch)
   * y el componente esta pensado para tener en el array todo cargado
   * y buscar mostrando y ocultando sobre esos items
   */
  returnTrue = () => true;

  onChangePickUp(event) {
    if (event && event.iata && event.name) {
      this.selectedPickUpIata = event.iata;
      this.selectedPickUpName = event.name;
      this.pickUpNotSelected = false;
    } else {
      this.selectedPickUpIata = '';
      this.selectedPickUpName = '';
      this.pickUpNotSelected = true;
    }
  }

  onChangeDropOff(event) {
    if (event && event.iata && event.name) {
      this.selectedDropOffIata = event.iata;
      this.selectedDropOffName = event.name;
      this.dropOffNotSelected = false;
    } else {
      this.selectedDropOffIata = '';
      this.selectedDropOffName = '';
      this.dropOffNotSelected = true;
    }
  }

  setValues() {
    this.start = formatDate(
      this.carsSearchForm.value.dateSelect.startDate,
      'yyyy-MM-dd',
      locale(this.translateService.getDefaultLang())
    ).toString();

    this.end = formatDate(
      this.carsSearchForm.value.dateSelect.endDate,
      'yyyy-MM-dd',
      locale(this.translateService.getDefaultLang())
    ).toString();

    this.hour = formatDate(
      this.carsSearchForm.value.dateSelect.startDate,
      'HH:mm:ss',
      locale(this.translateService.getDefaultLang())
    ).toString();

    this.hourEnd = formatDate(
      this.carsSearchForm.value.dateSelect.endDate,
      'HH:mm:ss',
      locale(this.translateService.getDefaultLang())
    ).toString();

    this.originZone = this.carsSearchForm.value.pickUp;
    this.destinationZone = this.carsSearchForm.getRawValue()['dropOff'];
    this.countryOfResidence =
      this.data.smartProfile && this.data.smartProfile.accountResultDto
        ? this.data.smartProfile.accountResultDto.accountSumaryDto.addtionalInformationDto.isoCountry
        : this.dataStore.countryOfResidence;
    this.ageDriver = this.carsSearchForm.value.driverAge;

    this.dateHourStartText = formatDate(
      this.carsSearchForm.value.dateSelect.startDate,
      'EEE dd/MM - hh:mm',
      locale('es')
    ).toLocaleString();

    this.dateHourEndText = formatDate(
      this.carsSearchForm.value.dateSelect.endDate,
      'EEE dd/MM - hh:mm',
      locale('es')
    ).toLocaleString();

    this.totalNights = this.qDays;
    if (this.totalNights > 1) {
      this.totalNights = this.totalNights + ' ' + this.textDays;
    } else {
      this.totalNights = this.totalNights + ' ' + this.textDay;
    }
  }

  searchC() {
    if (this.carsSearchForm.get('dropOff').disabled) {
      // si es devolucion en el mismo lugar de partida
      this.carsSearchForm.controls['dropOff'].setValue(this.carsSearchForm.controls['pickUp'].value);
      this.selectedDropOffIata = this.selectedPickUpIata;
      this.selectedDropOffName = this.selectedPickUpName;
    }
    if (this.carsSearchForm.valid) {
      this.searchFormInvalid = false;
      this.setValues();
      this.requestInfo = {
        'start': this.start,
        'end': this.end,
        'hour': this.hour,
        'hourEnd': this.hourEnd,
        'originZone': this.originZone,
        'destinationZone': this.destinationZone,
        'countryOfResidence': this.countryOfResidence,
        'ageDriver': this.ageDriver,
        'targetCurrency': this.dataStore.brandTargetCurrency
      };

      // valores que fueron buscados
      this.searchedService.setModel(<SearchedCar>{
        withdrawal: {
          id: this.carsSearchForm.get('pickUp').value,
          name: this.selectedPickUpName
        },
        return: {
          id: this.carsSearchForm.get('dropOff').enabled ?
            this.carsSearchForm.get('dropOff').value : this.carsSearchForm.get('pickUp').value,
          name: this.selectedDropOffName
        },
        date: {
          start: this.carsSearchForm.get('dateSelect').value.startDate,
          end: this.carsSearchForm.get('dateSelect').value.endDate
        },
        time: {
          start: this.hour,
          end: this.hourEnd
        },
        driverAge: this.carsSearchForm.get('driverAge').value
      });

      this.dataStore.journeyParamsInfo = {
        citiName: 'De ' + this.selectedPickUpName + ' a ' + this.selectedDropOffName
      };
      this.data.saveJourneyParamsInfo(this.dataStore.journeyParamsInfo);

      // información formateada para mostrar en el detalle
      const detalleDeBusqueda = <ICarInfo>{
        lugarRetiroDevolucionIguales: null,
        retiro: {
          iata: null,
          name: null,
          fecha: null,
          hora: null
        },
        devolucion: {
          iata: null,
          name: null,
          fecha: null,
          hora: null
        },
        edadConductor: null
      };

      // lugar de Retiro y Devolución
      let lugarRetiroDevolucion: string;
      if (this.originZone === this.destinationZone) {
        detalleDeBusqueda.lugarRetiroDevolucionIguales = true;
        const lugar = this.allCities.find(x => x.id === this.originZone || x.id === this.destinationZone);
        detalleDeBusqueda.retiro.iata = lugar.iata;
        detalleDeBusqueda.retiro.name = lugar.name;
        detalleDeBusqueda.devolucion.iata = lugar.iata;
        detalleDeBusqueda.devolucion.name = lugar.name;

        // retira y devuelve en el mismo lugar
        lugarRetiroDevolucion = this.textWithdrawalReturnIn + ' ' + lugar.iata;
      } else {
        detalleDeBusqueda.lugarRetiroDevolucionIguales = false;
        detalleDeBusqueda.retiro.iata = this.selectedPickUpIata;
        detalleDeBusqueda.retiro.name = this.selectedPickUpName;
        detalleDeBusqueda.devolucion.iata = this.selectedDropOffIata;
        detalleDeBusqueda.devolucion.name = this.selectedDropOffName;

        // retira en un lugar y devuelve en un lugar diferente
        lugarRetiroDevolucion = this.textWithdrawalIn + ' ' + this.selectedPickUpIata;
        lugarRetiroDevolucion += ' / ';
        lugarRetiroDevolucion += this.textReturnIn + ' ' + this.selectedDropOffIata;
      }

      detalleDeBusqueda.retiro.fecha = moment(this.start, ['YYYY-MM-DD']).format('DD/MM/YYYY');
      detalleDeBusqueda.retiro.hora = moment(this.hour, ['HH:mm:ss']).format('HH:mm');
      detalleDeBusqueda.devolucion.fecha = moment(this.end, ['YYYY-MM-DD']).format('DD/MM/YYYY');
      detalleDeBusqueda.devolucion.hora = moment(this.hourEnd, ['HH:mm:ss']).format('HH:mm');

      // FechaHora de alquiler y FechaHora de devolución
      let fechaAlquilerDevolucion: string;
      fechaAlquilerDevolucion = this.textRental;
      fechaAlquilerDevolucion += ' ' + formatDate(
        this.start,
        this.dateWeekdayFormat,
        locale(this.translateService.getDefaultLang())
      ).toLocaleString();
      fechaAlquilerDevolucion += ' ' + moment(this.hour, ['HH:mm:ss']).format('HH:mm') + ' hs';
      fechaAlquilerDevolucion += ' - ';
      fechaAlquilerDevolucion += this.textReturnDate;
      fechaAlquilerDevolucion += ' ' + formatDate(
        this.end,
        this.dateWeekdayFormat,
        locale(this.translateService.getDefaultLang())
      ).toLocaleString();
      fechaAlquilerDevolucion += ' ' + moment(this.hourEnd, ['HH:mm:ss']).format('HH:mm') + ' hs';

      // traducimos la información de la búsqueda a un IJourneyInfo
      // que es lo que acepta el app-main-form
      this.dataStore.carInfoParams = Object.assign({ detalle: detalleDeBusqueda }, {
        originDestinyIata: lugarRetiroDevolucion,
        departureArrivalDates: fechaAlquilerDevolucion
      });
      this.data.saveCarParamsInfo(this.dataStore.carInfoParams);
      this.data.saveRequestCars(this.requestInfo);
      this.dataStore.carDataSearch = this.requestInfo;
      this.router.navigate(['cars/results']);
    } else {
      this.searchFormInvalid = true;
      if (this.carsSearchForm.get('pickUp').value !== '' && this.carsSearchForm.get('pickUp') !== undefined) {
        this.pickUpNotSelected = true;
      }
      if (this.carsSearchForm.get('dropOff').enable &&
        this.carsSearchForm.get('dropOff').value !== '' &&
        this.carsSearchForm.get('dropOff') !== undefined) {
        this.dropOffNotSelected = true;
      }
      if (!this.carsSearchForm.get('driverAge').value) {
        this.ageNotSelected = true;
      }
    }
    // this.trackingManager.trackEventString(data.CARS.searchCarsButton, data.CARS.carsHomeScreen, '', JSON.stringify(this.dataStore.carInfoParams));
    GoogleAnalytics.sendEventFirebase('ga_event', data.CARS.carsHomeScreen, data.CARS.searchCarsButton, JSON.stringify(this.dataStore.carInfoParams));
  }

  /**
   * toggle disable checkbox return in other place
   */
  placeOfReturnToggle() {
    if (this.carsSearchForm.get('dropOff').enabled) {
      this.carsSearchForm.get('dropOff').disable();
    } else {
      this.carsSearchForm.get('dropOff').enable();
    }
  }

  openedChangeStart(opened: any) {
    // if (!opened) {
    this.selected = this.carsSearchForm.value.dateSelect;
    this.dateStart.nativeElement.value = this.carsSearchForm.value.dateSelect.startDate.format('MM/DD/YYYY');
    this.dateEnd.nativeElement.value = this.carsSearchForm.value.dateSelect.endDate.format('MM/DD/YYYY');
    this.minDateEnd = this.carsSearchForm.value.dateSelect.startDate;

  }

  openedChangeEnd(opened: any) {
    // if (!opened) {
    this.dateStart.nativeElement.value = this.carsSearchForm.value.dateSelect.startDate.format('MM/DD/YYYY');
    this.dateEnd.nativeElement.value = this.carsSearchForm.value.dateSelect.endDate.format('MM/DD/YYYY');
    this.selected = this.carsSearchForm.value.dateSelect;

    // }
  }

}
