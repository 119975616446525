import { Component, OnInit, AfterContentInit } from '@angular/core';
import { formatDate } from '@angular/common';
import { DataService } from '../../services/data.service';
import { DataStoreService } from '../../services/data-store.service';
import { SmartProfileService } from '../../services/smart-profile.service';
import { ActivatedRoute, Router } from '@angular/router';
import { LanguageService } from '../../component/language/language.service';
import { OcultadorService } from '../home/ocultador.service';
import { InitService } from '../../services/init.service';
import { MatDialog } from '@angular/material/dialog';
import { ActionEventService } from '../../services/action-event.service';
import { TranslateService } from '@ngx-translate/core';
import { OnePocketService, StoreServiceLibrary } from '@wearenovae/novae-core-services';
import { SearchedService } from '../../component/searched/searched.service';
import { LoginService } from '../../container/shared/login/login.service';
import { EProduct, IRoomPaxes } from '../../component/searched/models/searched.model';
import { SearchedFlight } from '../../component/searched/models/searched-flight.model';
import { SearchedHotel } from '../../component/searched/models/searched-hotel.model';
import { SearchedCar } from '../../component/searched/models/searched-car.model';
import { ICarInfo } from '../home/search/search-cars/search-cars.component';
import { IActivityRequestParameters } from '../../models/activities/response/activity-availability.model';
import { SearchedActivity } from '../../component/searched/models/searched-activity.model';

import data from '../../resources/screenAndEventsTracking.json';
import moment from 'moment';
import { locale } from 'moment';
import { GoogleAnalytics } from '../../resources/GoogleAnalytics.resource';
import { environment, typeApp } from 'src/environments/environment';

@Component({
  selector: 'app-fixed-rates',
  templateUrl: './fixed-rates.component.html',
  styleUrls: ['./fixed-rates.component.scss']
})
export class FixedRatesComponent implements OnInit, AfterContentInit {
  showForm: boolean;
  isAmex: boolean;
  translations = [];
  textWithdrawalReturnIn: string;
  textWithdrawalIn: any;
  textReturnIn: any;
  textRental: any;
  textReturnDate: any;
  paxes = [];
  validateScotiaLogin: boolean = false;

  showTableFixedRates: boolean = true;
  showIframeJuniper: boolean = false;
  urlToLoadJuniper = '';

  get language(): string {
    if (this.languageService.systemLanguajeChange.value) {
      return this.languageService.systemLanguajeChange.value.toLowerCase();
    }
    return '';
  }

  get brand() {
    return this.dataStoreService.brand;
  }

  constructor(
    private dataService: DataService,
    private dataStoreService: DataStoreService,
    private smartProfile: SmartProfileService,
    private route: ActivatedRoute,
    private languageService: LanguageService,
    public ocultadorService: OcultadorService,
    private initService: InitService,
    private actionEventService: ActionEventService,
    private dialog: MatDialog,
    private translate: TranslateService,
    private store: StoreServiceLibrary,
    private _storeLibrary: StoreServiceLibrary,
    private onePocketService: OnePocketService,
    private searchedService: SearchedService,
    private router: Router,
    private loginService: LoginService
  ) {
    this.getBrandRoute();
  }

  ngOnInit() {
    this.getTranslations();
    // this.trackingManager.setCurrentScreen('/home', data.WELCOME.welcomeScreen);
    this.aboutLoadNav();
    // this.dataService.productSelected = EProduct.FLIGHTS;
    // this.dataStoreService.preloaderImg = EProduct.FLIGHTS;
    this.initService.isInitializedBrand.subscribe((isInitialized) => {
      if (isInitialized) {
        const lang = this._storeLibrary.getItemFromStorage('lang') || 'en';
        this.translate.use(lang);
        this.translate.setDefaultLang(lang);
        this.languageService.setSystemLanguaje(lang);
        this.validateRegisterParams();

        if (this.dataStoreService.brand === 'scotia') {
          this.isAmex = true;
        } else {
          this.isAmex = false;
        }

        setTimeout(() => {
          // this.checkGetUserOnUrl();
          this.setInformationTravelFromApp();
        });
        if (this.dataService.token) {
          this.smartProfile.getAccountMethod(this.dataService.token);
        }
        this.dataService.productSelected = EProduct.FIXED_RATES;
        this.dataStoreService.preloaderImg = EProduct.FIXED_RATES;
        this.actionEventService.setItemActiveTab(EProduct.FIXED_RATES);
        GoogleAnalytics.setCurrentScreen('/fixed-rates');
      }
    });
    this.showForm = true;
  }

  ngAfterContentInit() {
  }

  private getTranslations() {
    const sbs = this.translate.get([
      'DATE',
      'SEARCH_HOTELS'
    ]).subscribe((trans) => {
      this.translations = trans;

      this.textWithdrawalReturnIn = trans['SEARCH_CARS.withdrawal_return_in'];
      this.textWithdrawalIn = trans['SEARCH_CARS.withdrawal_in'];
      this.textReturnIn = trans['SEARCH_CARS.return_in'];
      this.textRental = trans['SEARCH_CARS.rental'];
      this.textReturnDate = trans['SEARCH_CARS.return_date'];
      locale(this.languageService.systemLanguajeChange.value);
    });
  }

  /**
   * verificar si el usuario llegó en la url
   */
  private checkGetUserOnUrl() {
    this.route.queryParams.subscribe((params) => {
      if (params.tokenAuth) {
        this.actionEventService.sendCollapseApp(true);
        this.dataStoreService.isAlleMobile = true;
        this.ocultadorService.ocultar = true;
        const tokn = params.tokenAuth.replace(/ /g, '+');
        this.dataService.token = encodeURI(tokn);
        this.dataService.idApplication = params.idApplication;
        this.dataStoreService.channelID = params.idChannel;
        this.smartProfile.getAccountMethod(this.dataService.token);
        const brand = this.dataStoreService.brand;
        if (params.defaultCard !== '') {
          this.sendBrandLoad(Number(params.defaultCard));
        } else {
          this.actionEventService.sendBrandDynamic(brand);
        }

        this.languageService.setSystemLanguaje(params.language);
        this.definedFooter();

        // como abrieron bitacora, ya logueados
      }
    });
  }

  /**
   * metodo para buscar travel desde la app
   */
  setInformationTravelFromApp() {

    this.route.queryParams.subscribe((params) => {
      if (params.tokenAuth) {
        this.actionEventService.sendCollapseApp(true);
        this.dataStoreService.isAlleMobile = true;
        this.ocultadorService.ocultar = true;
        const tokn = params.tokenAuth.replace(/ /g, '+');
        this.dataService.token = encodeURI(tokn);
        this.dataService.idApplication = params.idApplication;
        this.dataStoreService.channelID = params.idChannel;
        this.smartProfile.getAccountMethod(this.dataService.token);
        const brand = this.dataStoreService.brand;
        if (params.defaultCard !== '') {
          this.sendBrandLoad(Number(params.defaultCard));
        } else {
          this.actionEventService.sendBrandDynamic(brand);
        }

        this.languageService.setSystemLanguaje(params.language);
        this.definedFooter();

        // como abrieron bitacora, ya logueados
      }
    });
  }

  definedFooter() {
    this.actionEventService.changeFooter.subscribe((isChange) => {
      if (this.dataStoreService.brand === 'scotia') {
        this.isAmex = isChange;
      } else {
        this.isAmex = false;
      }
    });
  }

  getCardsUser(token: string) {
    this.onePocketService.getCreditCards().subscribe((data: any) => {
      const listCards: any[][] = this.getSeparateListCreditCard(data);
      if (data.getCreditCardResponse.resultGetCreditCards
        && listCards['user'].length > 0) {

        for (const creditCardItem of listCards['user']) {
          if (creditCardItem && creditCardItem.creditCard
            && creditCardItem.creditCard.creditCardBase
            && creditCardItem.creditCard.creditCardBase.franquiseID
            && creditCardItem.creditCard.creditCardBase.defaultt) {
            // (creditCardItem.creditCard.creditCardBase.franquiseName);

            const franchice: number = Number(creditCardItem.creditCard.creditCardBase.franquiseID);
            this.sendBrandLoad(franchice);
          }
        }
      }
    }, (err) => {
    });
  }

  sendBrandLoad(franchiseId: number) {
    if (franchiseId === 3) {
      this.actionEventService.setSubBrand('amex');
      this.dataService.saveCreditCardDefult('amex');
      this.actionEventService.setChangeFooter(true);
    } else {
      this.actionEventService.setSubBrand('default');
      this.dataService.saveCreditCardDefult('');
      this.actionEventService.setChangeFooter(false);
    }
  }

  getSeparateListCreditCard(creditCardsResponse: any) {
    let creditCardsAll: any = [];
    const listCreditCardsSeparate: any[][] = [];
    const creditCardsStripe = [];
    const creditCards = [];

    if (creditCardsResponse.getCreditCardResponse.resultGetCreditCards.creditCards) {
      creditCardsAll = creditCardsResponse.getCreditCardResponse.resultGetCreditCards.creditCards;

      for (let i = 0; i < creditCardsAll.length; i++) {

        if (creditCardsAll[i].type && creditCardsAll[i].type === 'virtual') {
          creditCardsStripe.push(creditCardsAll[i]);
        } else {
          creditCards.push(creditCardsAll[i]);
        }
      }
    }

    listCreditCardsSeparate['virtual'] = creditCardsStripe;
    listCreditCardsSeparate['user'] = creditCards;

    return listCreditCardsSeparate;
  }

  getBrandRoute() {
    this.route.queryParams.subscribe((params: any) => {
      if (params.brand) {
        this.dataService.saveBrand(params.brand);
        this.actionEventService.sendBrandDynamic(params.brand);
        this.dataService.productSelected = EProduct.FLIGHTS;
        this.dataStoreService.preloaderImg = EProduct.FLIGHTS;
      } else {
        this.route.url.subscribe(resp => {
          if (resp[0].path === EProduct.TRAVEL) {
            this.dataService.productSelected = EProduct.FLIGHTS;
            this.dataStoreService.preloaderImg = EProduct.FLIGHTS;
          }
        });
      }
    });
  }

  aboutLoadNav() {
    this.actionEventService.isAboutLoad.subscribe((isAbout) => {
      this.dataService.productSelected = EProduct.FLIGHTS;
      this.dataStoreService.preloaderImg = EProduct.FLIGHTS;
    });
  }

  validateRegisterParams() {
    this.loginService.validateRegisterParams();
  }

  showComponents(event) {
    this.showTableFixedRates = event.showTable;
    this.showIframeJuniper = event.showIframe;
    this.urlToLoadJuniper = event.loadUrlJuniper;
  }
}
