<div class="body">
    <div class="main-img text-center">
        <img [src]="imgURL | srcSanitizer" alt="alert">
    </div>
    <div class="subtitle-container">
        <p *ngIf="options.subtitle !== null" class="subtitle"> {{ options.subtitle }} </p>
    </div>
    <div class="message-container">
        <p *ngIf="options.message !== null" class="message"> {{ options.message }} </p>
    </div>
</div>
<div class="footer">
    <div class="col" *ngIf="options.confirmText != null">
        <button type="button" class="btn-modal" (click)="successButton()">{{ options.confirmText }}</button>
    </div>
    <div class="col" *ngIf="options.cancelText != null">
        <button type="button" class="btn-modal" (click)="cancelButton($event)">{{ options.cancelText }}</button>
    </div>
</div>