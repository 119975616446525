import { CardLayoutThreeComponent } from './../card-layout-three.component';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-card-header',
  templateUrl: './card-header.component.html',
  styleUrls: ['./card-header.component.scss'],
  host: {'class': 'cmp-tag'}
})
export class CardHeaderComponent implements OnInit {

  constructor(
    private layout: CardLayoutThreeComponent
  ) { }

  get product() {
    return this.layout.product;
  }

  ngOnInit() {
  }

}
