import { Slider } from 'src/app/component/slider-op/model/slider';
import { CardPrice } from 'src/app/component/card/models/card-price.model';
import { CardInfo, CardImage } from 'src/app/component/card/models/card-layout-two.model';
import { CardLayoutThreeModel } from 'src/app/component/card/models/card-layout-three.model';
import { MeetingPoint } from './activity-availability-response.model';

export class ActivityOption implements CardLayoutThreeModel {
    constructor(
        public id: string | number,
        public name: string,
        // public address?: string,
        public description?: string,
        // public category?: string,
        // public info?: CardInfo[],
        public images?: CardImage[],
        public price?: CardPrice,

        public dates?: ActivityDates[],
        public meetingPoint?: MeetingPoint[],
        public selectedDate?: ActivityDates,
        public cancellationPolicy?: any, // ActivityCancellationPolicy[],

        /**
         * Datos necesarios para el booking
         */
        public bookingCode?: string,
        public serviceCode?: string,
        public selectedMeetingPoint?: MeetingPoint,

    ) {  }

    static toArray(values: any[], info: CardInfo[]): ActivityOption[] {
        values.forEach((v, i) => {
            values[i] = new ActivityOption(
                v.id,
                v.name,
                v.description,
                v.images,
                v.price,
                v.dates,
                // info,
                v.selectedDate,
                v.cancellationPolicy,
            );
            values[i].selectedDate = v.dates[0];
            values[i].price = v.dates[0].price;
        });
        return values;
    }

    nameSelected = () => ( this.name );
    subnameSelected = () => ( this.name );
    getMoneyAmount = () => (
        (!this.selectedDate.price.calculatedPrice && this.selectedDate.price.pointsPrice)
        || this.selectedDate.price.calculatedPrice
        ? this.selectedDate.price.calculatedPrice : this.selectedDate.price.totalPrice )
    getPointAmount = () => ( this.selectedDate.price.pointsPrice ? +this.selectedDate.price.pointsPrice : 0 );
    refundable = () => (
        !this.cancellationPolicy ? null : this.cancellationPolicy.refundable === 'Y' ? true : false );

    priceConversion = (conversionRate, percent, userPoints) => {
        Slider.getPriceConversion(this.dates, conversionRate, percent, userPoints);
    }

    get dropdownOptions() {
        return this.dates;
    }
    get radioOptions() {
        return this.meetingPoint;
    }
}

export interface ActivityDates {
    ratePlanCode: string;
    startDate: string;
    price?: CardPrice;
}

export interface ActivityCancellationPolicy {
    amount: number;
    to: string;
}

