import { Component, OnInit, Input, AfterContentInit, ElementRef } from '@angular/core';
import { DataStoreService } from '../../../services/data-store.service';
import { environment } from '../../../../environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { StoreServiceLibrary } from '@wearenovae/novae-core-services';
import { threadId } from 'worker_threads';

@Component({
  selector: 'app-section-one-mr',
  templateUrl: './section-one-mr.component.html',
  styleUrls: ['./section-one-mr.component.scss']
})
export class SectionOneMRComponent implements OnInit, AfterContentInit {
  urlsVideosFirstCitizens: any = {};
  video2
  uno
  @Input() brand: string;
  urlsVideos: any = environment.brands[this.dataStore.brand].videosLandingEN.sectionOne;

  imagesBgIphone1 = `./assets/images/${this.dataStore.brand}-miles/bg-mobiles.png`;

  objToLoadVideo = {
    one: false,
    two: false
  };

  constructor(private dataStore: DataStoreService,
    private translate: TranslateService,
    private store: StoreServiceLibrary,
    private el: ElementRef) {
  }

  ngOnInit() {

    this.changeLanguaje();
    if (this.brand === 'firstcitizens') {
      this.video2 = true
      if (this.video2 == true) {
        this.urlsVideosFirstCitizens = environment.brands[this.dataStore.brand].videosLanding.sectionOne;
        this.imagesBgIphone1 = `./assets/images/${this.dataStore.brand}-miles/bg-mobiles.png`;
        this.urlsVideos = this.urlsVideosFirstCitizens;
        this.objToLoadVideo.one = true;

      }

    }
  }

  ngAfterContentInit() {
    this.changeLanguaje();

  }

  changeLanguaje() {
    this.translate.onLangChange.subscribe((data) => {
      if (data.lang === 'es') {
        this.imagesBgIphone1 = `./assets/images/${this.dataStore.brand}-miles/es/bg-mobiles.png`;
        this.urlsVideos = environment.brands[this.dataStore.brand].videosLandingES.sectionOne;
        this.imagesBgIphone1 = `./assets/images/${this.dataStore.brand}-miles/es/bg-mobiles.png`;
      } else {
        this.imagesBgIphone1 = `./assets/images/${this.dataStore.brand}-miles/bg-mobiles.png`;
        this.urlsVideos = environment.brands[this.dataStore.brand].videosLandingEN.sectionOne;
        this.imagesBgIphone1 = `./assets/images/${this.dataStore.brand}-miles/bg-mobiles.png`;
      }
    });
  }

  openSection(number: any) {

    if (number == 1) {
      this.uno = true;
      this.objToLoadVideo.one = true;
      let tag = this.el.nativeElement.querySelector("section.first-learn");
      let tag2 = this.el.nativeElement.querySelector("section.second-learn");
      let tagBtn = this.el.nativeElement.querySelector(".row-shoponline .bl-item:first-child button");
      let tagBtn2 = this.el.nativeElement.querySelector(".row-shoponline .bl-item:last-child button");
      tag.classList.toggle('open');
      tag2.classList.remove('open');
      tagBtn.classList.toggle('active');
      tagBtn2.classList.remove('active');
    } else if (number == 2) {
      this.objToLoadVideo.two = true;
      let tag = this.el.nativeElement.querySelector("section.first-learn");
      let tag2 = this.el.nativeElement.querySelector("section.second-learn");
      let tagBtn = this.el.nativeElement.querySelector(".row-shoponline .bl-item:first-child button");
      let tagBtn2 = this.el.nativeElement.querySelector(".row-shoponline .bl-item:last-child button");
      tag2.classList.toggle('open');
      tag.classList.remove('open');
      tagBtn.classList.remove('active');
      tagBtn2.classList.toggle('active');
    }

  }

  closeSection(number: any) {
    if (number == 1) {
      let tag = this.el.nativeElement.querySelector("section.first-learn");
      let tagBtn = this.el.nativeElement.querySelector(".row-shoponline .bl-item:first-child button");
      tag.classList.remove('open');
      tagBtn.classList.remove('active');
    } else if (number == 2) {
      let tag2 = this.el.nativeElement.querySelector("section.second-learn");
      let tagBtn2 = this.el.nativeElement.querySelector(".row-shoponline .bl-item:last-child button");
      tag2.classList.remove('open');
      tagBtn2.classList.remove('active');
    }
  }

  selectedChangePowerMat(index) {
    const videos = this.el.nativeElement.querySelectorAll("section.section-uno video");
    videos.forEach((video, i) => {
      video.muted = true;
      video.loop = true;
      if (video != null) {
        video.pause();
      }

      if (index.selectedIndex == i) {
        if (video != null) {
          video.play();
        }
      }
    });
  }
}
