<section class="pro">
    <div class="container">
        <h2>Recibe compensación proactiva y al instante <span>por inconvenientes durante tu viaje.</span></h2>
        <div class="bl-row">
            <div class="bl-left">
                <swiper #bigSlider class="swiper-container" [config]="bigSliderConfig">
                    <div class="slide swiper-slide">
                        <div class="item">
                            <img src="../../../../assets/images/{{brand}}-miles/icon-late.svg" alt="">
                            <p><strong>US$ 100 </strong></p>
                            <p>de compensación proactiva por demora de vuelo de más de 6 horas.</p>
                        </div>
                        <div class="item">
                            <img src="../../../../assets/images/{{brand}}-miles/icon-watch.svg" alt="">
                            <p><strong>US$ 200 </strong></p>
                            <p>por retraso de equipaje.</p>
                        </div>
                        <div class="item">
                            <img src="../../../../assets/images/{{brand}}-miles/icon-bag.svg" alt="">
                            <p><strong>US$ 600 </strong></p>
                            <p>por pérdida de equipaje.</p>
                        </div>
                    </div>
                    <div class="slide swiper-slide">
                        <div class="item ticket">
                            <img src="../../../../assets/images/{{brand}}-miles/icon-tickets.svg" alt="">
                            <p>Si pierdes la conexión <br> internacional de tu vuelo. <br> <strong>Te reubicamos en otro <br> vuelo</strong> para que continúes <br> tu viaje.</p>
                        </div>
                        <div class="item">
                            <img src="../../../../assets/images/{{brand}}-miles/icon-phone.svg" alt="">
                            <p><strong>Hasta US$ 500 </strong></p>
                            <p>en compensación <br> instantánea por <br> dispositivos electrónicos <br> perdidos o dañados.</p>
                        </div>
                    </div>
                </swiper>
                <a (click)="goToTyC()">Ver Términos y Condiciones.</a>

            </div>
            <div class="bl-right">
                <img alt="" src="../../../../assets/images/{{brand}}-miles/pro.png">
            </div>
        </div>
        <button class="reserve" (click)="goToTravel()">Reserva ahora</button>
    </div>
</section>

<section class="gray attendance">
    <div class="container">
        <h2>Vuelve a viajar con tranquilidad.</h2>
        <p>Tus reservas incluyen <strong>asistencia médica y <br> protección COVID-19.</strong></p>
        <swiper #bigSlider class="swiper-container" [config]="bigSliderConfig">
            <div class="slide swiper-slide">
                <div class="bl-row">
                    <div class="bl-left">
                        <div class="item">
                            <div class="content">
                                <img src="../../../../assets/images/{{brand}}-miles/icon-protection.svg" alt="">
                                <p><strong><span class="especial">US$ 50.000</span></strong> por gastos médicos en <br> caso de enfermedad o <br> accidente. <strong>Incluido <br> COVID-19.</strong> </p>
                            </div>
                        </div>
                    </div>
                    <div class="bl-right">
                        <div class="item">
                            <div class="content">
                                <img src="../../../../assets/images/{{brand}}-miles/icon-med.svg" alt="">
                                <p><strong>US$ 250</strong> por medicamentos con prescripción médica. </p>
                            </div>

                        </div>
                        <div class="item">
                            <div class="content">
                                <img src="../../../../assets/images/{{brand}}-miles/icon-pregnant.svg" alt="">
                                <p><strong>US$ 100 por atención medica</strong> de emergencia por motivos del embarazo.</p>
                            </div>

                        </div>
                    </div>
                </div>
                <div class="certificate">
                    <img src="../../../../assets/images/{{brand}}-miles/union-europea.svg" alt="">
                    <p>Incluye el certificado de cobertura para la comunidad Schengen.</p>
                </div>
            </div>
            <div class="slide swiper-slide">
                <div class="bl-row">
                    <div class="bl-right">
                        <div class="item">
                            <div class="content">
                                <img src="../../../../assets/images/{{brand}}-miles/icon-nurse.svg" alt="">
                                <p><strong>US$ 100 por atención médica de emergencia</strong> a causa de una condición pre-existente. </p>
                            </div>

                        </div>
                        <div class="item">
                            <div class="content">
                                <img src="../../../../assets/images/{{brand}}-miles/icon-ambulance.svg" alt="">
                                <p><strong>Transporte de <br> emergencia sin costo</strong> en caso de evacuación médica.</p>
                            </div>

                        </div>
                    </div>
                    <div class="bl-right">
                        <div class="item">
                            <div class="content">
                                <img src="../../../../assets/images/{{brand}}-miles/icon-accident.svg" alt="">
                                <p><strong>Protección por accidente</strong> practicando deportes recreacionales.</p>
                            </div>

                        </div>
                        <div class="item">
                            <div class="content">
                                <img src="../../../../assets/images/{{brand}}-miles/icon-airplane.svg" alt="">
                                <p><strong>Retorno de menores a su país de origen</strong> por causa de hospitalización del adulto con quien viaja.</p>
                            </div>

                        </div>
                    </div>
                </div>
                <div class="item">
                    <p><strong>Alojamiento y gastos diarios</strong> por causa de <br> enfermedad o accidente, incluido un acompañante.</p>
                </div>
            </div>
        </swiper>
        <a (click)="goToTyC()">Ver Términos y Condiciones.</a>
        <button class="reserve" (click)="goToTravel()">Reserva ahora</button>
    </div>
</section>

<section class="cybernetic">
	<div class="container">
		<h2>Recibe protección contra <br> fraude y riesgo cibernético. <br> <span>Hasta US$ 5 millones.</span></h2>
		<p>La tecnología avanza rápidamente y los riesgos de ser víctima de un delito cibernético también. <br> <strong>Protegemos toda tu información ¡siempre!</strong></p>
		<img src="../../../../assets/images/{{brand}}-miles/cibernetico.png" alt="">
		<div class="win">
			<h2>¡Gana Más!</h2>
			<p><strong>Multiplica tus <span class="italic">Miles</span> con cada reserva que realices</strong>, incluso cuando pagas con <span class="italic">Miles.</span></p>
			<div class="entertainment">
				<div class="items">
					<div class="item">
						<img src="../../../../assets/images/{{brand}}-miles/reward1.png" alt="">
						<div>
							<img src="../../../../assets/images/{{brand}}-miles/1x.svg" alt="">
	                        <p>en <strong>vuelos.</strong></p>
						</div>
                    </div>
                    <div class="item">
                    	<img src="../../../../assets/images/{{brand}}-miles/reward2.png" alt="">
                    	<div>
                    		<img src="../../../../assets/images/{{brand}}-miles/2X.svg" alt="">
	                        <p>en alquiler de <strong>autos.</strong></p>
                    	</div>
                    </div>
                    <div class="item">
                    	<img src="../../../../assets/images/{{brand}}-miles/reward3.png" alt="">
                    	<div>
                    		<img src="../../../../assets/images/{{brand}}-miles/3X.svg" alt="">
	                        <p>en <strong>hoteles.</strong></p>
                    	</div>
                    </div>
				</div>
				<div class="footer">
					<p>Estas <span class="italic white">Miles</span> pueden usarse sin límites de <br> acumulación ni fecha de expiración.</p>

                    <a (click)="goToTyC()">Ver Términos y Condiciones.</a>

				</div>
			</div>
		</div>
	</div>
	<button class="reserve" (click)="goToTravel()">Reserva ahora</button>
    <div id="CombineYourMiles"></div>
</section>

