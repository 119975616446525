enum TrackEventCategoryType {
    PAGE = 'page_view',
    SCREEN = 'screen_view',
    SEARCH = 'search'
};

enum TrackEventActionType {
    NEW_TRACKING = 'NewTracking'
};

export { TrackEventCategoryType, TrackEventActionType };
