import { AlertSessionService } from './alert-session.service';
import { Component, OnInit, Input, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DataStoreService } from 'src/app/services/data-store.service';

@Component({
  selector: 'app-alert-session',
  templateUrl: './alert-session.component.html',
  styleUrls: ['./alert-session.component.scss']
})
export class AlertSessionComponent implements OnInit {


  @Input() message: any = { message: 'TOKEN_ERROR' };

  get brand() {
    return this.dataStoreService.brand;
  }

  constructor(
    private alertService: AlertSessionService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dataStoreService: DataStoreService
  ) { }

  ngOnInit() {
    this.message = (this.data.message) ? this.data.message : this.message;
    this.alertService.postMessage(this.message);
  }

}
