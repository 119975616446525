export enum EProduct {
    FLIGHTS = 'flights',
    HOTELS = 'hotels',
    CARS = 'cars',
    ACTIVITIES = 'activities',
    BENEFITS = 'benefits',
    ABOUT = 'about',
    HOME = 'home',
    TRAVEL = 'travel',
    GENERAL = 'general',
    REWARDS = 'rewards',
    FIXED_RATES = 'fixed-rates',
    NEWS = 'news',
    TUTORIALS = 'Tutorials',
    HOMEVACATIONS = 'homeVacations',
    EXAMPLE = 'example'

}

export interface IRange {
    start: string;
    end: string;
}

export interface IPlace {
    type: string;
    id: number;
    name: string;
    iata: string;
}

export interface IPaxe {
    id: number | string;
    age: number | string;
}

export interface IRoomPaxes {
    paxes: IPaxe[];
}
