import { MatDialog } from '@angular/material/dialog';
import { Component, OnInit, HostListener, Input } from '@angular/core';
import { EProduct } from '../searched/models/searched.model';
import { keys, StoreServiceLibrary } from '@wearenovae/novae-core-services';
import { keyJsonFixedRates, keysUniversal, keysPostMessage } from 'src/app/resources/keys.resources';
import { InitService } from '../../services/init.service';
import { DataStoreService } from '../../services/data-store.service';
import { DataService } from '../../services/data.service';
import { SmartProfileService } from '../../services/smart-profile.service';
import { OcultadorService } from '../../pages/home/ocultador.service';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { ActionEventService } from '../../services/action-event.service';
import { LoginComponent } from '../../container/shared/login/login.component';
import { environment } from '../../../environments/environment';
import { GoogleAnalytics } from '../../resources/GoogleAnalytics.resource';
import { MilesFormatPipe } from '../../pipes/miles-format.pipe';
import { BenefitsComponent } from '../benefits/benefits.component';

@Component({
  selector: 'app-fixed-rates-iframe-travel',
  templateUrl: './fixed-rates-iframe-travel.component.html',
  styleUrls: ['./fixed-rates-iframe-travel.component.scss']
})
export class FixedRatesIframeTravelComponent implements OnInit {

  @Input() urlIframeJuniperToLoad: string = '';

  EProduct = EProduct;
  iframeScrollHeight = 'calc(100vh - 155px)';
  iframeScrollWidth = '100%';
  classSearchContent = 'buscador-flotante';
  iframeJuniperDeep = '';
  urlSearchIframe = '';
  purchaceId = 0;
  selectedTaxes = 0;
  percentageUser: any;
  product = {};
  locationLoader: string;
  

  public hasBenefits = false;
  public hasActivities = true;
  public flagJuniperIframeIsLoad = false;

  languageSend: string;
  messageNeedToken: string;
  onePocketUrl: string;
  flagUseRedemtionBuyMiles = false;
  reedemtionPointsDefault: any;
  msjReservationInfo: string;

  bookingBenefitsTextBanner: {};
  htmlBenefits:string = '';

  constructor(private storeLibrary: StoreServiceLibrary,
    private initService: InitService,
    private dataService: DataService,
    private dataStore: DataStoreService,
    public ocultadorService: OcultadorService,
    private smartProfileService: SmartProfileService,
    private translate: TranslateService,
    private router: Router,
    private storeServiceLibrary: StoreServiceLibrary,
    private actionsEventService: ActionEventService,
    private dialog: MatDialog,
    private milesFormat: MilesFormatPipe) {
    this.initService.isInitializedBrand.subscribe((isInitialized) => {
      if (isInitialized) {
        // this.urlIframeJuniperToLoad = this.storeLibrary.getJustOneUniversalPropertie(keysUniversal.URL_TRAVEL_JUNIPER) || '';
        this.urlIframeJuniperToLoad = this.storeLibrary.getJustOneUniversalPropertie(keysUniversal.URL_TRAVEL_FIXED_RATES) || '';
        this.onePocketUrl = this.storeServiceLibrary.getJustOneUniversalPropertie(keysUniversal.onePocket_url) || '';
        // this.urlSearchIframe = 'http://localhost:8080/test_widget_onepocket_web/testPlugin1.html';

        this.languajeChange();
      }
    });
  }

  ngOnInit() {
    this.languageSend = this.translate.currentLang;
  }

  @HostListener('window:message', ['$event'])
  messageListener(event: MessageEvent) {
    const iframe = document.getElementById('iframe_fixed_rates');
    if (event.data) {
      let dataEvent: any;
      try {
        dataEvent = JSON.parse(event.data);
      } catch (error) {
        dataEvent = event.data;
      }

      if (typeof dataEvent === 'object' && dataEvent.type && dataEvent.type === 'ID_PURCHASE') {
        this.purchaceId = dataEvent.purchaseId;
        this.selectedTaxes = dataEvent.selectedTaxes;
        this.product = dataEvent.fixedRates;
        this.percentageUser = dataEvent.userPorcentage;

        this.goToOnepocketPay();

      } else if (dataEvent === 'getUrlWidgetPortal') {

        const message = {
          0: 'urlWidgetPortal',
          1: window.location.origin
        };
        this.dataService.sendPostMessage(message, '*', iframe);

      } else if (dataEvent === 'getUrlPortal') {

        const message = {
          0: 'urlPortal',
          1: window.location.origin
        };
        this.dataService.sendPostMessage(message, '*', iframe);

      } else if (dataEvent.type === 'UP_LOGIN_MODAL') {
        this.openLogin();
      } else if (typeof dataEvent === 'object' && dataEvent.typeMessage && dataEvent.typeMessage === 'JUNIPER_PAGE_IS_LOAD') {
        this.flagJuniperIframeIsLoad = true;
        this.sendPostMessageInitDataToJuniper();

        const location = dataEvent.location;
        let href = dataEvent.location.href;
        if (!href || href == undefined || href == 'undefined') {
          href = null;
        }
        const tmpLocation = GoogleAnalytics.clearLocationToAnalytics(location);
        GoogleAnalytics.sendEventFirebase('ga_event', 'TRAVEL_SEARCH', href, JSON.stringify(tmpLocation));

        if (this.dataService.token && this.dataService.token !== '') {
          this.sendPostMessageTokenUserToJuniper();
        }
      } else if (event.origin && dataEvent.type === 'ON_START') {
        const jsonJuniper = JSON.parse(sessionStorage.getItem(keyJsonFixedRates.json_fixed_rates));

        const message = {
          typeMessage: 'extraDataJson',
          extraDataJSON: jsonJuniper
        };

        this.dataService.sendPostMessage(message, '*', iframe);
      }

      // else if (dataEvent === 'getUrlAlleChannel') {
      // // TODO: toca sacar la url desde Universal para enviar aqui.
      // const message = {
      //   0: 'urlAlleChannel',
      //   1: 'https://purchasews.cibcfcibmiles.com/'
      // };
      // this.dataService.sendPostMessage(message, '*', iframe);
      // }
    }

  }

  sendPostMessageInitDataToJuniper() {
    const iframe = document.getElementById('iframe_fixed_rates');

    let fixedRatesData = sessionStorage.getItem(keyJsonFixedRates.json_fixed_rates);
    this.reedemtionPointsDefault = this.storeServiceLibrary.getJustOneUniversalPropertie(keysUniversal.DEFAULT_CONVERSION_FACTOR) || null;


    let milesGiftFormat = environment.brands[this.dataStore.brand].milesGiftDefault;

    if (milesGiftFormat) {
      milesGiftFormat = this.milesFormat.transform(milesGiftFormat);
    }

    this.messageNeedToken = this.getTranslate('SEARCH.needTokenPoints', { milesGift: milesGiftFormat });
    this.locationLoaderSlider();

    const cardSelected:any = this.storeServiceLibrary.getCardScotiaPointsSelected();
    let strCardSelected:string = '';

    if (cardSelected) {
      strCardSelected = JSON.stringify(cardSelected);
    }

    this.dataService.sendPostMessage(
      {
        "typeMessage": keysPostMessage.initData,
        "applicationId": environment.brands[this.dataStore.brand].applicationId,
        "iframeSliderUrl": this.dataStore.iframeSliderUrl,
        "language": this.languageSend,
        "messageNeedToken": this.messageNeedToken,
        "locationLoader": this.locationLoader,
        "fixedRates": true,
        "fixedRatesData": fixedRatesData,
        "htmlBenefits": btoa(this.htmlBenefits),
        "bookingBenefitsTextBanner" : this.bookingBenefitsTextBanner,
        "strCardSelected": btoa(strCardSelected)
      }, '*', iframe
    );
  }

  locationLoaderSlider() {
    let preload = `${this.onePocketUrl}assets/img/loaders`;
    if (this.dataStore.brand === 'credomatic') {
      preload += `/bac/tutorial-SlideOP_${this.languageSend.toUpperCase()}.gif`;
    } else {
      preload += `/tutorial-SlideOP_${this.languageSend.toUpperCase()}.gif`;
    }
    this.locationLoader = preload;
  }

  sendPostMessageTokenUserToJuniper() {
    const iframe = document.getElementById('iframe_fixed_rates');
    const universalProperties = localStorage.getItem(keys.universalProperties);
    let userData = this.storeServiceLibrary.getUserData();

    if (this.dataStore.brand === 'lifeMilesTravel') {
      this.flagUseRedemtionBuyMiles = true;
    } else {
      this.flagUseRedemtionBuyMiles = false;
    }

    this.msjReservationInfo = this.getTranslate('SEARCH.htmlReservationInfo', {});
    this.dataService.sendPostMessage(
      {
        "typeMessage": keysPostMessage.tokenUser,
        "userData": userData,
        "token": this.dataService.token,
        "flagRenderIframe": true,
        "applicationId": environment.brands[this.dataStore.brand].applicationId,
        "language": this.languageSend,
        "withoutToken": true,
        "flagUseRedemtionBuyMiles": this.flagUseRedemtionBuyMiles,
        "universalProperties": universalProperties,
        "htmlReservationInfo": this.msjReservationInfo
      }, '*', iframe
    );
  }

  goToOnepocketPay() {
    this.router.navigateByUrl('onepocket-pay', { state: { purchaceId: this.purchaceId, selectedTaxes: this.selectedTaxes, product: this.product, userPorcentage: this.percentageUser, flagFixedRates: true } });
  }

  getTranslate(tag, params) {
    let messageDataFind: string;
    this.translate.get(`${tag}`, params).subscribe((value) => messageDataFind = value);
    return messageDataFind;
  }

  openLogin() {
    const dialogRef = this.dialog.open(LoginComponent, {
      panelClass: 'login-dialog'
    });

    dialogRef.afterClosed().subscribe(successful => {
      if (successful) {
        this.sendPostMessageTokenUserToJuniper();
      }
    });
  }

  languajeChange() {
    this.translate.onLangChange.subscribe((data) => {
      if (this.urlIframeJuniperToLoad.includes('?idioma=en')) {
        const newSearch = this.urlIframeJuniperToLoad.replace('?idioma=en', '');
        this.urlIframeJuniperToLoad = `${newSearch}?idioma=${data['lang']}`;
      } else if (this.urlIframeJuniperToLoad.includes('?idioma=es')) {
        const newSearch = this.urlIframeJuniperToLoad.replace('?idioma=es', '');
        this.urlIframeJuniperToLoad = `${newSearch}?idioma=${data['lang']}`;
      } else if (this.urlIframeJuniperToLoad.includes('&idioma=en')) {
        const newSearch = this.urlIframeJuniperToLoad.replace('&idioma=en', '');
        this.urlIframeJuniperToLoad = `${newSearch}&idioma=${data['lang']}`;
      } else if (this.urlIframeJuniperToLoad.includes('&idioma=es')) {
        const newSearch = this.urlIframeJuniperToLoad.replace('&idioma=es', '');
        this.urlIframeJuniperToLoad = `${newSearch}&idioma=${data['lang']}`;
      }

      setTimeout(() => {
        this.translate.get([
          `LANDING.${this.dataStore.brand}.ONEPOCKETPAY.booking_benefits_title`,
          `LANDING.${this.dataStore.brand}.ONEPOCKETPAY.booking_benefits_desc`,
          `LANDING.${this.dataStore.brand}.ONEPOCKETPAY.booking_benefits_btn`
        ]).subscribe((translations) => {
          this.bookingBenefitsTextBanner = {
            'title': translations[`LANDING.${this.dataStore.brand}.ONEPOCKETPAY.booking_benefits_title`],
            'desc': translations[`LANDING.${this.dataStore.brand}.ONEPOCKETPAY.booking_benefits_desc`],
            'button': translations[`LANDING.${this.dataStore.brand}.ONEPOCKETPAY.booking_benefits_btn`]
          }
          this.htmlBenefits = undefined;
          const configOptionsTravel = this.dataService.getConfigPropertiesTravel();
          if (configOptionsTravel) {
            if (configOptionsTravel.showBenefitsInfo) {
              const benefits = document.getElementById('benefitsFixedRates');
              if (benefits) {
                this.htmlBenefits = benefits.outerHTML;
              }
            }
          }
        });
      });
    });
  }

  showBenefits() {
    const dialogRef = this.dialog.open(BenefitsComponent, {
      panelClass: 'modal-benefits',
      id: 'benefitsModal',
      disableClose: false,
      data: {
        showTermsAndConditions: false
      }
    });
  }
}
