import { CardLayoutThreeModel } from 'src/app/component/card/models/card-layout-three.model';
import { RequestHandlerService } from 'src/app/services/request-handler.service';
import { LanguageService } from 'src/app/component/language/language.service';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { IOptions } from './../../models/options.model';

@Component({
  selector: 'app-card-layout-three',
  templateUrl: './card-layout-three.component.html',
  styleUrls: ['./card-layout-three.component.scss']
})
export class CardLayoutThreeComponent implements OnInit {
  @Input() product: CardLayoutThreeModel;
  @Input() options: IOptions;
  @Output() dropDown = new EventEmitter();
  @Output() optionRadio = new EventEmitter();

  translationsDate = [];
  constructor(
    private languageService: LanguageService,
  ) { }

  get language() {
    return this.languageService.systemLanguajeChange.value;
  }

  ngOnInit() {
  }
}
