<div *ngIf="isInitializedBrand"  class="detail_millas onepocket {{dataStore.brand}}">
  <div class="result_detail_taxes gray" [ngClass]="{'active': purchaseID != undefined}">
    <ng-container *ngIf="price">
      <div class="result_text">
        <h4>Total:</h4>
        <h3>
          <span *ngIf="price.valuePoints">
            {{price.valuePoints | milesFormat}}&nbsp;{{'ONEPOCKET.miles'|translate}}
          </span>
          <span *ngIf="price.valuePoints && price.valueCreditCard">
            +
          </span>
          <span *ngIf="price.valueCreditCard">
            {{price.currency}}&nbsp;{{ dataService.getCurrencySymbol(price.currency,'narrow')}}{{price.valueCreditCard | milesFormat}}
          </span>
        </h3>
      </div>
      <ng-container *ngIf="price.tax">
        <div class="line"></div>
        <div class="result_text">
          <p>{{'ONEPOCKET.tax'|translate}}</p>
          <p>
            {{price.tax | milesFormatEn: false }}&nbsp;{{price.currency}}
          </p>
        </div>
      </ng-container>
    </ng-container>
  </div>
  <div class="content_iframe" id="content_iframe" *ngIf="iframeURL !== ''">
    <div class="loader-chat" *ngIf="!loaded" [ngClass]="dataStore.brand">
      <span class="texto-loader" innerHTML="{{'LOADING_SCREEN.creating_payment_experience' | translate}}">
        Creando tu<br> <strong> experiencia de pago...</strong>
      </span>
    </div>
    <iframe allow="clipboard-read; clipboard-write" [style.display]='!loaded ? "none" : "block"' id="iframe_onepocket"
      [src]="iframeURL | srcSanitizer" frameborder="0">
    </iframe>
  </div>
  <div class="branded_rules" [ngClass]="status ? 'show' : 'hide'">
    <div class="line_detail" (click)="clickEvent()">
      {{'BRAND_RULES.title'|translate}}
      <br>
      {{'BRAND_RULES.see_details'|translate}}
    </div>
    <div class="content-line">
      <div class="rules_detalles">
        <div class="interrupcion_icon"></div>
        <div class="text_detail">
          <h4>{{'BRAND_RULES.no_fees'|translate}}</h4>
          <p>{{'BRAND_RULES.for_cancellation'|translate}}</p>
        </div>
      </div>
      <div class="rules_detalles">
        <div class="conexiones_icon"></div>
        <div class="text_detail">
          <h4>{{'BRAND_RULES.no_fees'|translate}}</h4>
          <p>{{'BRAND_RULES.by_change'|translate}}</p>
        </div>
      </div>
      <div class="rules_detalles">
        <div class="equipaje_icon"></div>
        <div class="text_detail">
          <ng-container *ngIf="!dataStore.brand || dataStore.brand !== 'ABANCA'">
            <h4>{{'BRAND_RULES.up_to'|translate}} USD $500</h4>
          </ng-container>
          <ng-container *ngIf="dataStore.brand && dataStore.brand === 'ABANCA'">
            <h4>{{'BRAND_RULES.up_to'|translate}} EUR $452</h4>
          </ng-container>
          <p>{{'BRAND_RULES.per_flight'|translate}}</p>
        </div>
      </div>
      <div class="rules_detalles">
        <div class="retrasado_icon"></div>
        <div class="text_detail">
          <ng-container *ngIf="!dataStore.brand || dataStore.brand !== 'ABANCA'">
            <h4>{{'BRAND_RULES.up_to'|translate}} USD $50.000</h4>
          </ng-container>
          <ng-container *ngIf="dataStore.brand && dataStore.brand === 'ABANCA'">
            <h4>{{'BRAND_RULES.up_to'|translate}} EUR $45.161</h4>
          </ng-container>
          <p>{{'BRAND_RULES.per_flight'|translate}}</p>
        </div>
      </div>
      <div class="rules_detalles">
        <div class="personales_icon"></div>
        <div class="text_detail">
          <ng-container *ngIf="!dataStore.brand || dataStore.brand !== 'ABANCA'">
            <h4>{{'BRAND_RULES.up_to'|translate}} USD $500</h4>
          </ng-container>
          <ng-container *ngIf="dataStore.brand && dataStore.brand === 'ABANCA'">
            <h4>{{'BRAND_RULES.up_to'|translate}} EUR $452</h4>
          </ng-container>
          <p>{{'BRAND_RULES.for_your_personal'|translate}}</p>
        </div>
      </div>
      <div class="rules_detalles">
        <div class="garantizado_icon"></div>
        <div class="text_detail">
          <h4>{{'BRAND_RULES.price_guaranteed'|translate}}</h4>
          <p>{{'BRAND_RULES.and_more'|translate}}</p>
        </div>
      </div>
    </div>
  </div>
</div>
