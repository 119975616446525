import { SearchedHotel } from 'src/app/component/searched/models/searched-hotel.model';
import { HotelAvailabilityOptions } from 'src/app/models/new-hotel/hotel-availability-response.model';
import { CardInfo } from 'src/app/component/card/models/card-layout-two.model';

export class HotelFormatter {
    constructor(
        public hotelData: any,
        private searchedData: SearchedHotel
    ) {}

    formatData() {
        const hotelData = this.hotelData;

        if (hotelData) {
            const list = hotelData.filter((hotel, i) => {
                const totalNights = this.searchedData.qNights;
                const totalTravellers = this.searchedData.qGuest;
                const roomsQty = this.searchedData.qRooms;
                const info: CardInfo[] = [
                    {
                        type: 'refundable',
                        value: 'consulte las politicas de cancelación en el detalle'
                    },
                    {
                        type: 'night',
                        value: totalNights
                    },
                    {
                        type: 'traveller',
                        value: totalTravellers
                    },
                    {
                        type: 'breakfast',
                        value: true
                    },
                    {
                        type: 'wifi',
                        value: true
                    },
                    {
                        type: 'roomsQty',
                        value: roomsQty
                    }
                ];
                hotel.id = i;
                hotel.name = hotel.name;
                hotel.address = hotel.address;
                hotel.category = hotel.category;
                hotel.images = (hotel.images === null) ? [] : hotel.images;
                hotel.info = info;
                hotel.price = hotel.options[0].price;
                return hotel;
            });

            return HotelAvailabilityOptions.toArray(list);
        } else {
            return [];
        }
    }
}
