<div class="pointer-container" *ngIf="radioOptions && radioOptions.length > 0">
    <i class="icon-pointer"></i>
    <mat-radio-group [(ngModel)]="selected">
        <h6
            innerHTML="{{ 'ACTIVITY_DETAIL.meeting_point' | translate }}">
            Punto de encuentro
        </h6>
        <mat-radio-button class="point" *ngFor="let value of radioOptions; let i = index"
            (change)="selectMeetingPoint($event.source.value)" [value]="parseValue(value)">
            <p>{{ value.name }}</p>
            <p>{{ formatTime(value.time) }}</p>
        </mat-radio-button>
    </mat-radio-group>
</div>