import { DataStoreService } from 'src/app/services/data-store.service';
import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IBenefitResponse } from 'src/app/models/benefitResponce-model';

@Component({
  selector: 'app-pop-up-detail',
  templateUrl: './pop-up-detail.component.html',
  styleUrls: ['./pop-up-detail.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PopUpDetailComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<PopUpDetailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IBenefitResponse,
    public dataStore: DataStoreService) { }

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit() {
  }

}
