<div class="landing-fc" >
    <app-section-one-mr [brand]="brand"></app-section-one-mr>

    <app-section-two-mr [brand]="brand"></app-section-two-mr>

    <app-section-three-mr [brand]="brand"></app-section-three-mr>

    <app-section-four-mr [brand]="brand"></app-section-four-mr>

    <app-footer-fc-mr [brand]="brand"></app-footer-fc-mr>
</div>