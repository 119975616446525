import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-modal-load-video-tutorial',
  templateUrl: './modal-load-video-tutorial.component.html',
  styleUrls: ['./modal-load-video-tutorial.component.scss']
})
export class ModalLoadVideoTutorialComponent implements OnInit {

  tutorial: any;

  constructor(private dialogRef: MatDialogRef<ModalLoadVideoTutorialComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any) {
    this.tutorial = this.data['tutorial'];
  }

  ngOnInit(): void {
  }

  closeModal() {
    this.dialogRef.close();
  }

}
