import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalOptions } from '../../shared/modal/modal-options.model';
import { DataService } from '../../../services/data.service';
import { HotelBookingRulesRequest } from '../../../models/hotel/request/hotel-booking-rules-request.model';
import { HotelBookingRulesResponse } from '../../../models/hotel/response/hotel-booking-rules-response.model';
import { Card } from '../../shared/card/model/card';
import { DataStoreService } from '../../../services/data-store.service';
import { Router } from '@angular/router';
import { HotelAvailabilityResponseOptions } from '../../../models/hotel/response/hotel-availability-response-options.model';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-hotel-detail-modal',
  templateUrl: './hotel-detail-modal.component.html',
  styleUrls: ['./hotel-detail-modal.component.scss']
})
export class HotelDetailModalComponent implements OnInit, OnChanges {

  @ViewChild('content') content;
  @Input() openModal = false;
  @Input() options: ModalOptions;
  @Input() hotel: Card;
  @Input() porcentajeConversion: number;
  @Output() optionSelected = new EventEmitter();
  cardOptions = {
    type: 'room',
    style: 1,
  };
  openAlertModal = false;
  optionsModal: ModalOptions;

  constructor(
    public modalService: NgbModal,
    public dataService: DataService,
    public dataStore: DataStoreService,
    public router: Router,
  ) {
  }

  ngOnInit() { }

  ngOnChanges(changes: SimpleChanges) {
    if (this.hotel !== undefined) {
      this.open(this.content);
    }
  }
  open(content) {
    if (this.openModal && this.hotel !== undefined) {
      this.modalService.open(content, this.options.modalOptions);
      this.dataService.getPriceConversion(
        this.hotel.hotelObj.options,
        this.dataService.conversionRate,
        this.porcentajeConversion,
        this.dataService.userPointsSP);
    }
  }
  close() {
    this.modalService.dismissAll();
  }

  selectProductListener(ev) {
    this.hotel.hotelObj.selectedRoomOption = ev;
    this.dataService.saveSelectedHotel(this.hotel.hotelObj);
  }

  selectOption() {
    const req: any = {
      bookingRulesRequest: {
        hotelCode: {
          code: this.dataStore.selectedHotel.hotelCode
        },
        hotelOption: {
          ratePlancode: this.dataStore.selectedHotel.selectedRoomOption.ratePlanCode
        },
        hotelSearch: {
          checkIn: this.dataStore.hotelDataSearch.checkIn,
          checkOut: this.dataStore.hotelDataSearch.checkOut,
          destinationCode: this.dataStore.hotelDataSearch.destinationCode
        }
      }
    };

    this.dataService.sendBookingRulesRequest(req).subscribe((response: HotelBookingRulesResponse) => {
      this.openModal = false;
      this.close();

      // TODO: MANEJAR CAMBIO DE PRECIO
      this.hotel.hotelObj.bookingCode = response.results.bookingCode;
      // if (response.warnings && response.warnings.length > 0 && response.warnings[0].type === 'warnPriceChanged') {
      // } else {
      // }

      this.dataStore.selectedHotel.checkAvailabilityHotel = response;
      this.dataService.saveCheckAvailabilityHotel(response);
      this.dataService.saveSelectedHotel(this.hotel.hotelObj);
      this.router.navigate(['/hotels/registry']);
    }, error => {
      this.optionsModal = {
        type: 'error',
        subtitle: 'Lo sentimos...',
        mainImgUrl: 'https://www.freeiconspng.com/uploads/error-icon-4.png',
        message: 'Hubo un error chequeando la disponibilidad de esta habitación, por favor seleccione otra opción',
        cancelText: 'Volver',
        modalOptions: {
          size: 'md',
          ariaLabelledBy: 'modal-basic-title',
        }
      };
      this.openAlertModal = true;
    });
  }
  modalListener($event) {
    this.openAlertModal = false;
  }

  // getPriceConversion(conversionRate, percentage, puntosUsuarios) {
  //   this.hotel.hotelObj.options.forEach((item) => {
  //     const precioProducto = +item.price.totalPrice;
  //     const precioMillasProducto = (precioProducto / +conversionRate);
  //     const millasAdicionalNecesarias = precioMillasProducto - +puntosUsuarios;

  //     let puntosPagar = 0;
  //     let millasSobrantes = 0;
  //     if (millasAdicionalNecesarias >= 0) {
  //       puntosPagar = (millasAdicionalNecesarias === 0) ? (precioMillasProducto * percentage) / 100 : (puntosUsuarios * percentage) / 100;
  //     } else {
  //       // const maxDineroDisponible = puntosUsuarios / conversionRate;
  //       millasSobrantes = puntosUsuarios - precioMillasProducto;
  //       puntosPagar = (precioMillasProducto * percentage) / 100;
  //     }

  //     let dineroPagar = (precioProducto - Math.ceil(puntosPagar * conversionRate));
  //     if (dineroPagar < 0) {
  //       dineroPagar = 0;
  //     }
  //     item.price.pointsPrice = Math.ceil(puntosPagar).toString();
  //     item.price.calculatedPrice = dineroPagar.toFixed(2);
  //     item.price.restPointValue = Math.ceil(millasSobrantes).toString();

  //   });
  // }

  getUnique(options: HotelAvailabilityResponseOptions[]) {
    const unique = [];
    const compareArr = [];
    options.map((option: HotelAvailabilityResponseOptions) => {
      const optionComparerArr = [];
      const roomsName = option.rooms.map(item => {
        return item.name;
      }).join('-');
      optionComparerArr.push(roomsName);
      optionComparerArr.push(option.board);
      optionComparerArr.push(option.price.totalPrice);
      const optionComparer = optionComparerArr.join('-');
      if (compareArr.indexOf(optionComparer) === -1) {
        unique.push(option);
        compareArr.push(optionComparer);
      }
    });
    return unique;
  }

}
