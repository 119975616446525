import { SearchedActivity } from './../../../component/searched/models/searched-activity.model';
import { CardInfo } from 'src/app/component/card/models/card-layout-two.model';
import { ActivityResult } from 'src/app/models/new-activity/activity-availability-response.model';
export class ActivityFormatter {

    constructor(
        public data: any,
        private searchedData: SearchedActivity
    ) {}

    formatData() {
        const data = this.data;
        if (data) {
            const list = data.filter((item, i) => {
                const totalTravellers = this.searchedData.qPassenger;
                const info: CardInfo[] = [
                    {
                        type: 'traveller',
                        value: totalTravellers
                    }
                ];
                item.images = item.images.map(img => {
                    return { imgURL: img.url, label: img.type };
                });
                item.id = i;
                item.name = item.serviceName;
                item.description = item.serviceDescription;
                item.info = info;
                return item;
            });
            return ActivityResult.toArray(list);
        }
    }

}