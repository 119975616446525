/**
 * permite saber si el el element tiene foco mediante la proiedad "isFocused"
 */
import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appIsInputFocused]',
  exportAs: 'isFocused' // permite acceder a la propiedad isFocused desde afuera del componente
})
export class IsInputFocusedDirective {

  /**
   * value true cuando el componente esta activo
   */
  isFocused = false;

  private nativeElement: any;

  @HostListener('focus', ['$event.target'])
  focus = (target: any) => {
    if (target === this.nativeElement) {
      this.isFocused = true;
    }
  }

  @HostListener('blur', ['$event.target'])
  blur = (target: any) => {
    if (target === this.nativeElement) {
      this.isFocused = false;
    }
  }

  constructor(
    private el: ElementRef
  ) {
    this.nativeElement = this.el.nativeElement;
  }

}
