import { SearchedService } from './../../../../component/searched/searched.service';
import { Component, OnInit } from '@angular/core';
import { HotelDetailModalComponent } from '../hotel-detail-modal.component';
import { Card } from '../../../shared/card/model/card';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-selection-detail',
  templateUrl: './selection-detail.component.html',
  styleUrls: ['./selection-detail.component.scss']
})
export class SelectionDetailComponent implements OnInit {

  translations = {};
  nights: string;
  travellers: string;
  rooms: string;
  hotel: Card;
  wifi: boolean;
  breakfast: boolean;
  travellersQty: number;
  constructor(
    public hotelDetailModalComponent: HotelDetailModalComponent,
    private translateService: TranslateService,
    private searchedService: SearchedService) {
    this.getTranslations();
  }

  get getMoneyPrice() {
    return this.hotel.hotelObj.selectedRoomOption.price.calculatedPrice;
  }

  ngOnInit() {
    this.hotel = this.hotelDetailModalComponent.hotel;
    this.getNights();
    this.getTraveller();
    this.getBreakfast();
    this.getWifi();
  }
  getNights() {
    const nights = this.hotel.info.find(item => {
      return (item.type === 'night');
    });
    if (nights !== undefined) {
      const nightsQty = parseInt(nights.value);
      this.nights = (nightsQty === 1) ? nightsQty + ' ' + this.translations['CARD.night']
        : nightsQty + ' ' + this.translations['CARD.nights'];
    }
  }
  getTraveller() {
    const travellers = this.hotel.info.find(item => {
      return (item.type === 'traveller');
    });
    this.travellersQty = travellers.value;
    if (travellers !== undefined) {
      const travellersQty = parseInt(travellers.value);
      this.travellers = travellersQty + ' ' + this.translations['CARD.guests'];
    }
  }
  getRoomQty() {
    // const room = this.hotel.hotelObj.selectedRoomOption.rooms.length;
    const qRooms = this.searchedService.getSearchedHotel().qRooms;
    return this.rooms = (qRooms === 1) ? qRooms + ' ' + this.translations['CARD.room'] : qRooms + ' ' + this.translations['CARD.rooms'];
  }
  getBreakfast() {
    const breakfast = this.hotel.info.find(item => {
      return (item.type === 'breakfast');
    });
    this.breakfast = (breakfast !== undefined) ? true : false;
  }
  getWifi() {
    const wifi = this.hotel.info.find(item => {
      return (item.type === 'wifi');
    });
    this.wifi = (wifi !== undefined) ? true : false;
  }
  getPointsPrice() {
    return this.hotel.hotelObj.selectedRoomOption.price.pointsPrice;
  }
  getCurrency() {
    return this.hotel.hotelObj.selectedRoomOption.price.currency;
  }

  getSelectedBoard() {
    return this.hotel.hotelObj.selectedRoomOption.board.toLowerCase();
  }
  getSelectedRoom() {
    const rooms = this.hotel.hotelObj.selectedRoomOption.rooms;
    return rooms.map(item => {
      return item.name.toLowerCase();
    }).join(', ');
  }
  hasBreakfast() {
    return (this.hotel.hotelObj.selectedRoomOption.board.toLowerCase().includes('desayuno')) ? true : false;
  }


  getTranslations() {
    this.translateService.get([
      'CARD.night',
      'CARD.nights',
      'CARD.guest',
      'CARD.guests',
      'CARD.room',
      'CARD.rooms',
      'HOTEL_DETAIL.room',
      'HOTEL_DETAIL.rooms',
    ]).subscribe((trns) => {
      this.translations = trns;
    });
  }
}
