import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { GenericCard } from '../../../../models/genericCard/generic-card';
import { AbstractCardListComponent } from '../abstract-card-list.component';

@Component({
  selector: 'app-abstract-card',
  templateUrl: './abstract-card.component.html',
  styleUrls: ['./abstract-card.component.scss']
})
export class AbstractCardComponent implements OnInit {

  @Input() product: GenericCard;
  options: any;

  @Output() productDetailSelected = new EventEmitter();

  price = '';
  nights = 'X noches';
  travellers = 'X viajantes';
  description: string[];
  refundable: string;
  wifi: boolean;
  breakfast: boolean;

  constructor(
    public listCmp: AbstractCardListComponent
  ) { }

  ngOnInit() {
    this.options = this.listCmp.options;
  }
  isProductSelected() {
    return this.listCmp.selectedProductId === this.product.id;
  }

}
