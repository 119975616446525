<form [formGroup]="form">
  <div #inputAges class="input-ages">

    <div class="panel">
      <div class="container">
        <div #persons class="persons" formArrayName="persons">
          <div *ngFor="let person of getPersons().controls; let i = index;"
               [formGroupName]="i" class="person">
            <button class="remove-person"
                    (click)="removePersonByIndex(i, $event)"
                    [disabled]="this.getPersons().length === +min"
                    [title]="translations['remove']+'&nbsp;'+personText">
            </button>
            <span
              [class.age-selected]="person.get('age').value > 0">
                            {{personText + ' ' + (i+1)}}
                        </span>
            <select formControlName="age">
              <option value="" selected>
                {{translations['age']}}
              </option>
              <option *ngFor="let age of ages" [value]="age">
                {{age}}&nbsp;{{ age === 1 ? translations['year_old'] : translations['years_old']}}
              </option>
            </select>
          </div>
        </div>
        <div class="add-person-container">
          <button class="add-person"
                  (click)="addPerson();scrollToBottom(persons)">
            {{personText}}
          </button>
        </div>
      </div>

      <div class="apply-panel" (click)="onApply()">
        <div class="line">
        </div>
        <div class="button">
          <span>{{translations['apply']}}</span>
        </div>
      </div>
    </div>

  </div>
</form>
