import { Injectable } from '@angular/core';
import {EProduct} from "../searched/models/searched.model";
import {ISearchConfig} from "./home-search.component";

@Injectable({
  providedIn: 'root'
})
export class HomeSearchService {
  searchConfig: ISearchConfig;
  secondTarget: boolean;
  secondDatePicker: boolean;

  constructor() { }
  searchConfigFn(type: string) {
    switch (type) {
      case EProduct.FLIGHTS:
        this.searchConfig = {
          targetIn: 'Origen',
          targetOut: 'Destino',
          dateIn: '',
          dateOut: '',
          buyer: ''
        };
        this.secondTarget = true;
        this.secondDatePicker = true;
        break;
      case EProduct.HOTELS:
        this.searchConfig = {
          targetIn: 'Seleccione Destino',
          dateIn: '',
          dateOut: '',
          buyer: ''
        };
        this.secondTarget = false;
        this.secondDatePicker = false;
        break;
      case EProduct.CARS:
        this.searchConfig = {
          targetIn: 'Origen',
          targetOut: 'Destino',
          dateIn: '',
          dateOut: '',
          buyer: ''
        };
        this.secondTarget = true;
        this.secondDatePicker = true;
        break;
      case EProduct.BENEFITS:
        this.searchConfig = {
          targetIn: 'Seleccione Destino',
          dateIn: '',
          dateOut: '',
          buyer: ''
        };
        this.secondTarget = true;
        this.secondDatePicker = true;
        break;
      case EProduct.ACTIVITIES:
        this.searchConfig = {
          targetIn: 'Seleccione Destino',
          dateIn: '',
          dateOut: '',
          buyer: ''
        };
        this.secondTarget = false;
        this.secondDatePicker = false;
        break;
      default:
        this.searchConfig = {
          targetIn: 'Seleccione Destino',
          dateIn: '',
          dateOut: '',
          buyer: ''
        };
        this.secondTarget = false;
        this.secondDatePicker = false;
        break;
    }
  }

}
