import {Component, forwardRef, Input, OnInit} from '@angular/core';
import {ControlValueAccessor, FormControl, FormGroup, NG_VALUE_ACCESSOR, Validators} from '@angular/forms';
import {EProduct} from '../../../component/searched/models/searched.model';
import {DataService} from "../../../services/data.service";

@Component({
  selector: 'app-buyer',
  templateUrl: './buyer.component.html',
  styleUrls: ['./buyer.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => BuyerComponent),
      multi: true
    }
  ]
})
export class BuyerComponent implements OnInit, ControlValueAccessor {
  buyerForm: FormGroup;

  private _type;
  @Input() set type(v: any) {
    /*this.buyerForm = undefined;*/
    this._type = v;
  }

  get type() {
    return this._type;
  }

  buyerPlaceholder: string;
  value = '';
  show: boolean;
  preValues = {};
  EProduct = EProduct;
  driverAges = [];
  constructor(private data: DataService) {
  }

  ngOnInit() {
    for (let i = 18; i <= 99; i++) {
      this.driverAges.push(i);
    }
    this.show = false;
    this.buyerPlaceholder = 'Seleccione';
  }

  showFormFn() {
    if (this.preValues && this.preValues[this.data.productSelected]) {
    /*  this.buyerForm.setValue(this.preValues[this.data.productSelected]);*/
    }
    this.show = !this.show;
  }

  onChange = (_: any) => {
  };

  writeValue(value: any): void {
    if (value) {
      this.value = value || '';
    } else {
      this.value = '';
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
  }

  setDisabledState(isDisabled: boolean): void {
  }

  onApply(buyerForm) {
     this.preValues[this.data.productSelected] = buyerForm;
      this.showFormFn();
      this.onChange('hola');
  }
}
