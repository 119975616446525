export interface IOptions {
    type: string;
    hasDetail?: boolean;
    hasTitle?: boolean;
    hasRefundable?: boolean;
    showBottomInfo?: boolean;
    dropdownOptions?: any[];
    selectedRadioOption?: any;
    selectedDropdownOption?: any;
    isViewDetail?: boolean;
}

export enum ECardType {
    FLIGHTS = 'flights',
    HOTELS = 'hotels',
    CARS = 'cars',
    ACTIVITIES = 'activities',
    BENEFITS = 'benefits',
    ROOMS = 'rooms'
}

