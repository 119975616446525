/**
 * formateador para mostrar las millas
 */
import { Pipe, PipeTransform } from '@angular/core';
import { DecimalPipe } from '@angular/common';
import { LanguageService } from '../component/language/language.service';
import { environment } from '../../environments/environment';

@Pipe({
    name: 'milesFormatEn',
    pure: false
})
export class milesFormatEnPipe implements PipeTransform {

    transform(value: number | string, typePipe: boolean): any {
        if (typePipe) {
            return this.decimalPipe.transform(
                value,
                environment.pipeMilesFormat,
                'en'
            );
        } else {
            return this.decimalPipe.transform(
                value,
                environment.pipeCurrencyFormat,
                'en'
            );
        }
    }

    constructor(
        private languageService: LanguageService,
        private decimalPipe: DecimalPipe
    ) { }
}
