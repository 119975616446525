import { Router } from '@angular/router';
import { IContact } from 'src/app/component/contact-form/models/contact.model';
import { ITraveller } from 'src/app/component/traveller-form/models/traveller.model';
import { RegistrationService } from './registration.service';
import { Component, OnInit } from '@angular/core';
import { IPurchaseSummaryConfig } from 'src/app/component/purchase-summary/models/purchase-sumary-config.model';
import { EProduct } from 'src/app/component/searched/models/searched.model';
import { DatailTransaction } from './models/detail-transaction.model';
import { ConfirmationData } from 'src/app/component/confirmation/confirmation.model';
import { ActionEventService } from '../../services/action-event.service';
import { environment } from '../../../environments/environment';


@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss']
})
export class RegistrationComponent implements OnInit {

  public purchaseID: number;
  public onePocketToken: string;
  public onePocketURL: string;

  public dataTravellerForm: ITraveller[];
  public dataContactForm: IContact;

  public init = false;
  public confirmationData: ConfirmationData;

  public step = 1;

  constructor(
    private registrationService: RegistrationService,
    private router: Router,
    private actionEventService: ActionEventService
  ) {
  }

  get travellerFormConfig() {
    return this.registrationService.travellerFormConfig;
  }

  get productType() {
    return this.registrationService.typeProductSelected;
  }

  get purchaseSummaryConfig(): IPurchaseSummaryConfig {
    const config = {
      type: this.productType,
      paymentButtonClass: (this.purchaseID ) ? 'hidden' : (!this.dataTravellerForm) ? 'disabled' : '',
      modifyButtonClass: (this.purchaseID) ? 'hidden' : '',
      modifyButton: () => { this.router.navigate([this.productType + '/results']); },
      paymentButton: () => { this.onPay(); }
    };

    return config;
  }

  get isMobile() {
    return this.registrationService.isMobile;
  }

  get travellerTitle() {
    switch (this.productType) {
      case EProduct.HOTELS:
        return this.registrationService.translationsRegistration['guests'];
        break;
      case EProduct.CARS:
        return this.registrationService.translationsRegistration['driver'];
        break;
      case EProduct.BENEFITS:
        return this.registrationService.translationsRegistration['beneficiary'];
        break;
      default:
        return this.registrationService.translationsRegistration['passengers'];
        break;
    }
  }

  ngOnInit() {
    this.actionEventService.sendActiveItemMain('flys');
    this.registrationService.isInitialized.subscribe(async isInitialized => {
      if (isInitialized) {
        this.registrationService.setProductVariables();
        this.init = isInitialized;
        this.onePocketToken = this.registrationService.onePocketToken;
        this.onePocketURL = this.registrationService.onePocketURL;

        // luego de que tocan "seleccioanr", pero antes de completar los datos de registro
        // se hace un prebooking para obtener el "bookingId"
        this.registrationService.getBookingID();
      }
    });
  }

  /**
   * Procesar pre-booking
   * Continuar a proceso de pago
  */
  onPay() {

    this.actionEventService.sendActiveItemMain('pay');

    this.dataContactForm = {
      firstName: environment.dataContactObj.name,
      lastName: environment.dataContactObj.lastname,
      city: environment.dataContactObj.city,
      country: environment.dataContactObj.country,
      email: environment.dataContactObj.email,
      phone: environment.dataContactObj.phone
    };

    if (!this.dataTravellerForm) { return false; }
    this.registrationService.preBooking(this.dataTravellerForm, this.dataContactForm).then(res => {
      this.purchaseID = res.purchaseId;
    });
  }

  /**
   * Recibe datos del formulario de pasajeros
  */
  passengerFormListener(ev) {
    if (ev.length > 0) {
      this.dataTravellerForm = ev;
    } else {
      this.dataTravellerForm = null;
    }
  }

  /**
   * Recibe datos del formulario de contacto
  */
  contactFormListener(ev) {
    if (ev !== false) {
      this.dataContactForm = ev;
    } else {
      this.dataContactForm = null;
    }
  }

  /**
   * Recibe datos de OnePocket
  */
  onePocketListener(ev) {
    if (ev.typeMessage === 'PAYMENT_RESPONSE_ONEPOCKET' && ev.paymentSuccess) {
      const detailTransactionOP: DatailTransaction = ev.detailTransaction;
      this.confirmationData = this.registrationService.confirmationData(detailTransactionOP);
      this.registrationService.sendPaymentConfirmation();
    } else if (ev.typeMessage === 'GO_TO_RESUME_ONEPOCKET') {
      this.router.navigate(['home']);
    }
  }

  setStep(index: number) {
    this.step = this.step === index ? 0 : index;
  }

}
