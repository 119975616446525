import { Injectable } from '@angular/core';
import * as Rx from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ActionEventService {

  // Esto es para cambiar la clase dependiendo de la tarjeta
  changeClassAmexSubject: Rx.Subject<string> = new Rx.Subject<string>();
  activeItemMain: Rx.Subject<any> = new Rx.Subject();
  getDataUserRegisterParams: Rx.Subject<any> = new Rx.Subject();
  getDataUserHamburger: Rx.Subject<any> = new Rx.Subject();
  changeBrandDynamic: Rx.Subject<any> = new Rx.Subject();
  redirectHome: Rx.Subject<any> = new Rx.Subject();
  collapseAppMobile: Rx.Subject<any> = new Rx.Subject();
  isAboutLoad: Rx.Subject<any> = new Rx.Subject();
  getTrackingId: Rx.Subject<any> = new Rx.Subject();
  changeFooter: Rx.Subject<any> = new Rx.Subject();
  getOtherUrl: Rx.Subject<any> = new Rx.Subject<any>();
  ifChangeLogin: Rx.Subject<any> = new Rx.Subject<any>();

  isLoguedUserSsoId: Rx.Subject<any> = new Rx.Subject<any>();

  getInfoStorageUser: Rx.Subject<any> = new Rx.Subject<any>();
  setInfoCard: Rx.Subject<any> = new Rx.Subject<any>();

  needShowLoading: Rx.Subject<any> = new Rx.Subject<any>();
  countryCodeChange: Rx.Subject<any> = new Rx.Subject<any>();
  
  setInfoUserLifeMiles: Rx.Subject<any> = new Rx.Subject<any>();
  hideItemsPage: Rx.Subject<boolean> = new Rx.Subject<boolean>();
  changeItemMenu: Rx.Subject<string> = new Rx.Subject<string>();
  haveLoadUrlTravelIframe: Rx.Subject<boolean> = new Rx.Subject<boolean>();
  activateMenuItems: Rx.Subject<object> = new Rx.Subject<object>();
  changeItemsRouteTab: Rx.Subject<any> = new Rx.Subject<any>();
  getSelectorLanguaje: Rx.Subject<any> = new Rx.Subject<any>();

  changeInfoPromotion: Rx.Subject<any> = new Rx.Subject<any>();

  constructor() { }

  sendClassAmexSubject(type: string) {
    this.changeClassAmexSubject.next(type);
  }

  sendActiveItemMain(obj: any) {
    this.activeItemMain.next(obj);
  }

  sendParamsUserRegister(objUserRegister: any) {
    this.getDataUserRegisterParams.next(objUserRegister);
  }

  sendDataUserHamburger(objUser) {
    this.getDataUserHamburger.next(objUser);
  }

  sendBrandDynamic(brand) {
    this.changeBrandDynamic.next(brand);
  }

  sendRedirectTo(isRedirect) {
    this.redirectHome.next(isRedirect);
  }

  sendCollapseApp(isMobile: boolean) {
    this.collapseAppMobile.next(isMobile);
  }

  sendRestringedAbout(isAbout: boolean) {
    this.isAboutLoad.next(isAbout);
  }

  setTrackingId(trackId: string) {
    this.getTrackingId.next(trackId);
  }

  setSubBrand(subBrand: string) {
    this.changeClassAmexSubject.next(subBrand);
  }

  setChangeFooter(isChange: boolean) {
    this.changeFooter.next(isChange);
  }

  setChangeUrlJuniper(urlToChange: string, needScrollAnimation: boolean) {
    const iframe = document.getElementById('iframe_onepocket');
    if (needScrollAnimation) {
      if (iframe) {
        iframe.scrollIntoView({ behavior: 'smooth' });
      }
    }
    this.getOtherUrl.next(urlToChange);
  }

  changeLogin(succesfull: boolean) {
    this.ifChangeLogin.next(succesfull);
  }

  sendLoguedUserNotify(isLoguedUser: boolean) {
    this.isLoguedUserSsoId.next(isLoguedUser);
  }

  notifySaveInStorage(save: any) {
    this.getInfoStorageUser.next(save);
  }

  infoUserCard(cardInfo) {
    this.setInfoCard.next(cardInfo);
  }

  sendShowStartLoading(startLoading) {
    this.needShowLoading.next(startLoading);
  }

  sendCountryCode(country: object) {
    this.countryCodeChange.next(country);
  }

  sendInfoUserLifeMiles(user) {
    this.setInfoUserLifeMiles.next(user);
  }

  setHideItemsPage(show: boolean) {
    this.hideItemsPage.next(show);
  }

  setItemActiveTab(tabSelected: string) {
    this.changeItemMenu.next(tabSelected);
  }

  setLoadUrlTravelIframe(haveLoad: boolean) {
    this.haveLoadUrlTravelIframe.next(haveLoad);
  }

  setObjActivateMenu(objTabs: object) {
    this.activateMenuItems.next(objTabs);
  }

  setChangeItemsRouteTab(objRoutes: any) {
    this.changeItemsRouteTab.next(objRoutes);
  }

  changeSelectorLanguaje(languaje: string) {
    this.getSelectorLanguaje.next(languaje);
  }

  infoPromotion(infoPromotionObj: any) {
    this.changeInfoPromotion.next(infoPromotionObj);
  }
}
