import { Component, OnInit } from '@angular/core';
import { EProduct } from "../../searched/models/searched.model";
import { DataService } from "../../../services/data.service";
import { OcultadorService } from "../../../pages/home/ocultador.service";
import { HomeSearchService } from "../home-search.service";

@Component({
  selector: 'app-menu-search',
  templateUrl: './menu-search.component.html',
  styleUrls: ['./menu-search.component.scss']
})
export class MenuSearchComponent implements OnInit {
  EProduct = EProduct;
  productSelected;
  constructor(
    private dataService: DataService,
    public ocultadorService: OcultadorService,
    private homeSearchService: HomeSearchService
  ) { }

  ngOnInit() {
  }
  productSelect(type: EProduct) {
    this.dataService.productSelected = type;
    this.homeSearchService.searchConfigFn(type);
  }

}
