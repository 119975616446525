import { ProductHandlerService } from './../../services/product-handler.service';
import { TravellerFormService } from 'src/app/component/traveller-form/traveller-form.service';
import { IActivityPreBookRequest } from '../../models/activities/response/activity-availability.model';
import { EProduct } from './../../component/searched/models/searched.model';
import { PurchaseProductInfo } from './models/purchase-product-info';
import { OnePocketService, BodyAddPurchase, StoreServiceLibrary, UserData } from '@wearenovae/novae-core-services';
import { IContact } from 'src/app/component/contact-form/models/contact.model';
import { ITraveller } from 'src/app/component/traveller-form/models/traveller.model';
import { InitService } from 'src/app/services/init.service';
import { DataStoreService } from 'src/app/services/data-store.service';
import { DataService } from 'src/app/services/data.service';
import { environment } from 'src/environments/environment';
import { Injectable, OnInit } from '@angular/core';
import { FormConfig } from 'src/app/component/traveller-form/models/form-config.model';
import { BehaviorSubject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { FlightBookingRequest } from 'src/app/models/flights/flight-booking-request.model';
import { SearchedService } from 'src/app/component/searched/searched.service';
import { HotelBookingRequest } from 'src/app/models/hotel/request/hotel-booking-request.model';
import { IActivityResult } from 'src/app/models/activities/response/activity-availability.model';
import { IBenefitResponse } from 'src/app/models/benefitResponce-model';
import { ICarPreBookRequest } from 'src/app/models/car/request/carBookingRequest.model';
import { SearchedHotel } from 'src/app/component/searched/models/searched-hotel.model';
import { SearchedCar } from 'src/app/component/searched/models/searched-car.model';
import { SearchedFlight } from 'src/app/component/searched/models/searched-flight.model';
import { SearchedActivity } from 'src/app/component/searched/models/searched-activity.model';
import { SearchedBenefit } from 'src/app/component/searched/models/searched-benefit.model';
import { TravellerFormConfig } from './models/traveller-form-config';
import moment from 'moment';
import { formatDate } from '@angular/common';
import { DatailTransaction } from './models/detail-transaction.model';
import { LanguageService } from 'src/app/component/language/language.service';
import { ConfirmationData } from 'src/app/component/confirmation/confirmation.model';
import { Journey } from 'src/app/models/new-flight/journey.model';
import { HotelAvailabilityOptions } from 'src/app/models/new-hotel/hotel-availability-response.model';
import { RentacarAvailabilityOption } from 'src/app/models/new-rentacar/rentacar-availability-option.model';
import { IBenefitPreBookRequest, IBenefitPrice } from 'src/app/models/benefits/BenefitsBookingRequest.model';
import { ActionEventService } from '../../services/action-event.service';
import { keysUniversal, keysPostMessage } from 'src/app/resources/keys.resources';

@Injectable({
  providedIn: 'root'
})
export class RegistrationService {

  keyURL = sessionStorage.getItem('key');

  public isInitialized = new BehaviorSubject(false);

  public translationDate = [];
  public translationsForm = [];
  public translationsRegistration = [];

  private middlewareUrl: string;

  private dataTravellers: ITraveller[];
  private dataContact: IContact;

  private bookingId: string;
  private purchaseId: string;
  private preBookingStatus = false;

  public selectedProduct: Journey | HotelAvailabilityOptions | RentacarAvailabilityOption | IActivityResult | IBenefitResponse;
  public typeProductSelected: EProduct;
  public prebookingEndpoint: string;
  public bookingCodeEndpoint: string;
  public searchedData: SearchedHotel | SearchedCar | SearchedFlight | SearchedActivity | SearchedBenefit;
  public preBookingRequestSetter: () => {};

  get isMobile() {
    return this.dataStore.isMobileDevice;
  }

  iceValuePercentage: string;

  constructor(
    private dataService: DataService,
    private dataStore: DataStoreService,
    private initService: InitService,
    private translateService: TranslateService,
    private languageService: LanguageService,
    private httpClient: HttpClient,
    private storeLibrary: StoreServiceLibrary,
    private onePocketService: OnePocketService,
    private searchedService: SearchedService,
    private travellerFormService: TravellerFormService,
    private productHandlerService: ProductHandlerService
  ) {
    this.initService.isInitializedBrand.subscribe(async isInitialized => {
      if (isInitialized) {

        if (this.keyURL === 'localhost') {
          this.middlewareUrl = '/api/';
        } else {
          this.middlewareUrl = this.storeLibrary.getJustOneUniversalPropertie(keysUniversal.middleware_url);
        }

        this.languageService.systemLanguajeChange.subscribe((language) => {
          if (language) {
            this.getTranslations().then(() => {
              this.setProductVariables();
              this.isInitialized.next(true);
            });
          }
        });
      }
    });
  }

  get travellerFormConfig(): FormConfig {
    const travellerData = [this.travellerFormService.setTravellerData(this.dataService.smartProfileUserData)];
    const travellerFormConfig = new TravellerFormConfig(
      this.typeProductSelected,
      this.searchedData,
      travellerData,
      this.translationsForm
    );
    return travellerFormConfig.getConfig;
  }

  get onePocketToken() {
    return this.dataService.token;
  }

  get onePocketURL() {
    return this.dataStore.iframeUrl;
  }

  get channelId() {
    return this.dataStore.channelID;
  }

  get applicationId() {
    return environment.brands[this.brand].applicationId;
  }

  get brand() {
    return this.dataStore.brand;
  }


  confirmationData(datailTransaction: DatailTransaction): ConfirmationData {
    const autorizationCode = this.dataService.getOnePocketAuthorizationCode(datailTransaction);
    const currency = this.dataService.getOnePocketCurrency(datailTransaction);
    const pointsPaymentAmount = this.dataService.getOnePocketPointsPayment(datailTransaction);
    const moneyPaymentAmount = this.dataService.getOnePocketMoneyAmount(datailTransaction);
    const creditCardType = this.dataService.getOnePocketCardType(datailTransaction);
    const creditCardBin = this.dataService.getOnePocketCardNumber(datailTransaction);

    if (this.preBookingStatus) {
      return {
        type: this.typeProductSelected,
        product: this.selectedProduct,
        travellers: this.dataTravellers,
        bookingData: {
          purchaseId: +this.purchaseId
        },
        transactionData: {
          status: datailTransaction.estado,
          transactionNumber: +datailTransaction.numTransaction,
          transactionPoints: +pointsPaymentAmount,
          transactionMoney: +moneyPaymentAmount.toFixed(2),
          transactionCurrency: currency,
          transactionAutorizationCode: +autorizationCode,
          transactionCreditCardFranchise: creditCardType,
          transactionCreditCardProductNumbers: creditCardBin,
          date: formatDate(new Date(), 'yyyy-MM-dd', moment.locale()).toString(),
          time: formatDate(new Date(), 'hh:mm', moment.locale()).toString(),
        }
      };
    } else {
      return {};
    }
  }

  getTranslations() {
    return new Promise<any>((resolve) => {
      this.translateService.get([
        'DATE',
        'REGISTRATION',
        'TRAVELLER_FORM'
      ]).subscribe((trans) => {
        this.translationDate = trans['DATE'];
        this.translationsForm = trans['TRAVELLER_FORM'];
        this.translationsRegistration = trans['REGISTRATION'];
        resolve(true);
      });
    });
  }

  /**
   * Define los valores las variables de producto
   */
  setProductVariables() {
    const type = this.dataService.productSelected;
    this.bookingCodeEndpoint = 'booking/bookingcode/';
    switch (type) {
      case EProduct.FLIGHTS:
        this.selectedProduct = this.productHandlerService.selectedProduct as Journey;
        this.typeProductSelected = EProduct.FLIGHTS;
        this.prebookingEndpoint = 'flights/makePreBook';
        this.preBookingRequestSetter = this.setFlightRequest;
        this.searchedData = this.searchedService.getSearchedFlight();
        break;
      case EProduct.HOTELS:
        this.selectedProduct = this.productHandlerService.selectedProduct as HotelAvailabilityOptions;
        this.typeProductSelected = EProduct.HOTELS;
        this.prebookingEndpoint = 'hotels/makePreBook';
        this.preBookingRequestSetter = this.setHotelRequest;
        this.searchedData = this.searchedService.getSearchedHotel();
        break;
      case EProduct.CARS:
        this.selectedProduct = this.productHandlerService.selectedProduct as RentacarAvailabilityOption;
        this.typeProductSelected = EProduct.CARS;
        this.prebookingEndpoint = 'rentacar/makePreBook';
        this.preBookingRequestSetter = this.setCarRequest;
        this.searchedData = this.searchedService.getSearchedCar();
        break;
      case EProduct.ACTIVITIES:
        this.selectedProduct = this.dataStore.selectedActivity;
        this.typeProductSelected = EProduct.ACTIVITIES;
        this.prebookingEndpoint = 'activities/makePreBook';
        this.preBookingRequestSetter = this.setActivityRequest;
        this.searchedData = this.searchedService.getSearchedActivity();
        break;
      case EProduct.BENEFITS:
        this.selectedProduct = this.dataStore.selectedBenefit;
        this.typeProductSelected = EProduct.BENEFITS;
        this.prebookingEndpoint = 'benefits/makePreBook';
        this.preBookingRequestSetter = this.setBenefitRequest;
        this.searchedData = this.searchedService.getSearchedBenefit();
        break;
    }
  }

  /**
   * ejecuta el endpoint "makePreBook" para obtener el "bookingId",
   * el "bookingId" luego se utiliza en el "preBooking"
   */
  getBookingID() {
    const preBookingReq = this.preBookingRequestSetter();
    const endpointPreBooking = this.prebookingEndpoint;
    this.bookingId = null;

    if (preBookingReq) {
      this.sendPrebookingRequest(preBookingReq, endpointPreBooking).subscribe(res => {
        this.bookingId = res.result;
      }, err => {
      });
    }
  }

  /**
   * Setear y enviar request de PreBooking
   * @param dataTravellers Datos de TravellerFormComponent
   * @param dataContact Datos de ContactFormComponent
   */
  preBooking(dataTravellers: ITraveller[], dataContact: IContact) {
    this.dataTravellers = dataTravellers;
    this.dataContact = dataContact;
    return new Promise<any>((resolve, reject) => {
      this.getPurchaseId().then(() => {

        const preBookingReq = this.preBookingRequestSetter();
        const endpointPreBooking = this.prebookingEndpoint;

        // si los datos de prebooking estan seteados, hace prebooking
        if (preBookingReq) {

          this.sendPrebookingRequest(preBookingReq, endpointPreBooking).subscribe(res => {
            // set purchase
            this.setPostPurchase();
            resolve({ purchaseId: this.purchaseId });
          }, err => {
            reject();
          });

        } else {

          // set purchase
          this.setPostPurchase();
          resolve({ purchaseId: this.purchaseId });

        }

      }).catch(err => {
        reject();
      });
    });
  }

  setPostPurchase() {
    this.dataService.sendPostMessage(
      JSON.stringify({ type: keysPostMessage.idPurchase, purchaseId: this.purchaseId, idPurchase: this.purchaseId }));
    this.preBookingStatus = true;
  }

  getBookingCode(purchaseId) {
    return new Promise<any>((resolve, reject) => {
      this.sendBookingCodeRequest(this.bookingCodeEndpoint + purchaseId).toPromise().then(bookingCode => {
        resolve(bookingCode);
      }).catch(error => {
        reject();
      });
    });
  }

  /**
   * Llenar request de prebooking de vuelos
   */
  setFlightRequest(): any {
    const searched = this.searchedData as SearchedFlight;
    const product: Journey = this.selectedProduct as Journey;
    const purchaseId = this.purchaseId;

    const req: FlightBookingRequest = {
      // el "bookingId" viene el llamado a prebooking
      // luego de realizar la seleccion
      bookingId: this.bookingId,
      buyer: this.getBuyer(),
      passengers: this.dataTravellers,
      contact: this.dataContact,
      journeyID: product.journeyID,
      queryID: product.queryID,
      flightIDS: product.flightsIDS,
      price: product.price as any,
      purchaseId: purchaseId,
      channelId: this.channelId,
      flightData: []
    };

    const last = product.flights.length - 1;
    const origin = product.flights[0].origin.iata;
    const destination = product.flights[last].origin.iata;

    req.flightData.push({
      dateFrom: formatDate(searched.date.start, 'yyyy-MM-dd', moment.locale()).toString(),
      dateTo: formatDate(searched.date.end, 'yyyy-MM-dd', moment.locale()).toString(),
      origin: origin,
      destination: destination,
      flightClass: product.info.cabin,
      flightType: searched.journey
    });

    return req;
  }

  /**
   * Llenar request de prebooking de hotel
   */
  setHotelRequest(): any {
    const searched = this.searchedData as SearchedHotel;
    const product: HotelAvailabilityOptions = this.selectedProduct as HotelAvailabilityOptions;
    const purchaseId = this.purchaseId;
    const searchedRoomDistribution = (this.searchedData as SearchedHotel).roomsPaxes;

    const req: HotelBookingRequest = {
      // el "bookingId" viene el llamado a prebooking
      // luego de realizar la seleccion
      bookingId: this.bookingId,
      buyer: this.getBuyer(),
      contact: this.dataContact,
      purchaseId: purchaseId,
      channelId: this.channelId,
      hotels: [],
      hotelData: [],
      language: this.languageService.systemLanguajeChange.value
    };
    req.hotelData.push({
      dateFrom: formatDate(searched.date.start, 'yyyy-MM-dd', moment.locale()).toString(),
      dateTo: formatDate(searched.date.end, 'yyyy-MM-dd', moment.locale()).toString(),
      destination: searched.destination.iata,
      rooms: searched.qRooms,
      hotelName: product.name,
      hotelRooms: product.selectedOption.rooms.map(room => room.name).join(', '),
      hotelBoard: product.selectedOption.board
    });

    req.hotels.push({
      bookingCode: product.bookingRules.results.bookingCode,
      roomDistribution: searchedRoomDistribution as any,
      hotelBookingInfo: {
        checkIn: product.checkIn,
        checkOut: product.checkOut,
        hotelCode: product.hotelCode,
        price: {
          maxPrice: product.selectedOption.price.totalPrice,
          minPrice: product.selectedOption.price.totalPrice,
          currency: product.selectedOption.price.currency,
          providerTotalPrice: product.selectedOption.price.providerTotalPrice,
          providerCurrency: product.selectedOption.price.providerCurrency,
        }
      }
    });

    req.hotels[0].roomDistribution.forEach((item, i) => {
      if (this.dataTravellers && this.dataTravellers[i]) {
        item.paxes[0] = this.dataTravellers[i];
        item.paxes[0].name = this.dataTravellers[i].firstName;
        item.paxes[0].surname = this.dataTravellers[i].lastName;
      }
    });

    return req;
  }

  setCarRequest(): ICarPreBookRequest {
    const searched = this.searchedData as SearchedCar;
    const product: RentacarAvailabilityOption = this.selectedProduct as RentacarAvailabilityOption;

    const req: ICarPreBookRequest = {
      // el "bookingId" viene el llamado a prebooking
      // luego de realizar la seleccion
      bookingId: this.bookingId,
      buyer: this.getBuyer(),
      bookingCode: product.bookingRules.items[0].options[0].bookingCode,
      channelId: this.channelId,
      purchaseId: this.purchaseId,
      contact: this.dataContact ? this.dataContact : null,
      passengers: this.dataTravellers ? this.dataTravellers : [],
      price: {
        currency: product.price.currency,
        minPrice: +product.price.totalPrice,
        maxPrice: +product.price.totalPrice,
        providerCurrency: product.price.providerCurrency,
        providerTotalPrice: +product.price.providerTotalPrice,
      },
      rentacarData: [],
      language: this.languageService.systemLanguajeChange.value
    };

    req.rentacarData.push({
      carModel: product.name,
      dateFrom: formatDate(searched.date.start, 'yyyy-MM-dd', moment.locale()).toString(),
      dateTo: formatDate(searched.date.end, 'yyyy-MM-dd', moment.locale()).toString(),
      timeFrom: searched.time.start,
      timeTo: searched.time.end,
      pickUp: searched.withdrawal.name,
      dropOff: searched.return.name
    });

    req.passengers.forEach((item, i) => {
      if (this.dataTravellers && this.dataTravellers[i]) {
        item = this.dataTravellers[i];
        item.name = this.dataTravellers[i].firstName;
        item.surname = this.dataTravellers[i].lastName;
      }
    });

    return req;
  }

  setActivityRequest(): IActivityPreBookRequest {
    const searched = this.searchedData as SearchedActivity;
    const product: IActivityResult = this.selectedProduct as IActivityResult;
    const purchaseId = this.purchaseId;

    const req: IActivityPreBookRequest = {
      // el "bookingId" viene el llamado a prebooking
      // luego de realizar la seleccion
      bookingId: this.bookingId,
      buyer: this.getBuyer(),
      purchaseId: purchaseId,
      bookingCode: product.bookingRules.bookingCode,
      serviceCode: product.bookingRules.serviceCode,
      contact: this.dataContact,
      passengers: this.dataTravellers,
      meetingPoint: product.bookingRules.meetingPoints[0] as any,
      price: {
        currency: product.selectedOption.selectedDate.price.currency,
        minPrice: +product.selectedOption.selectedDate.price.totalPrice,
        maxPrice: +product.selectedOption.selectedDate.price.totalPrice,
        providerCurrency: product.selectedOption.selectedDate.price.providerCurrency,
        providerTotalPrice: +product.selectedOption.selectedDate.price.providerTotalPrice,
      },
      channelId: this.channelId,
      activityData: [],
      language: this.languageService.systemLanguajeChange.value
    };

    req.activityData.push({
      dateFrom: formatDate(searched.date.start, 'yyyy-MM-dd', moment.locale()).toString(),
      dateTo: formatDate(searched.date.end, 'yyyy-MM-dd', moment.locale()).toString(),
      destination: searched.destination.iata,
      activityName: product.name + '. ' + product.selectedOption.name,
      activityDate: formatDate(product.selectedOption.selectedDate.startDate, 'yyyy-MM-dd', moment.locale()).toString(),
      meetingPoint: product.selectedOption.selectedMeetingPoint ? product.selectedOption.selectedMeetingPoint.name : ''
    });

    return req;
  }

  /**
   * Llenar request de prebooking de vuelos
   */
  setBenefitRequest(): any {
    const purchaseId = this.purchaseId;
    const product: IBenefitResponse = this.selectedProduct as IBenefitResponse;
    // const searched = (this.searchedData as SearchedBenefit).obj;
    const req: IBenefitPreBookRequest = {
      purchaseId: purchaseId,
      // el "bookingId" viene el llamado a prebooking
      // luego de realizar la seleccion
      bookingId: this.bookingId,
      channelId: this.channelId,
      buyer: this.getBuyer(),
      passengers: this.dataTravellers || [],
      contact: this.dataContact || <IContact>{},
      token: this.dataStore.oauthTokenBenefits,
      // (el 'id' del selectedProduct se pone en el front, no es un id de backend)
      selectedProduct: {
        ...product,
        ...{
          flightData: this.dataStore.benefitsRequest.flightData
        }
      },
      price: <IBenefitPrice>{
        totalPrice: product.price.totalPrice,
        currency: product.productCurrency
      },
      // // valores del buscador
      // searchedProduct: searched,
      // // "benefitsRequest" contiene los parámetros del request que se hace al endpoint del backend
      // // en base a los datos de "searchedProduct" para consultar
      // // el availability de benefits
      // benefitsRequest: this.dataStore.benefitsRequest,
    };

    // Campos necesarios para el confirm-sale
    req.selectedProduct.currency = req.selectedProduct.productCurrency;
    req.selectedProduct.amount = req.selectedProduct.productRate;

    return req;
  }

  /**
   * Enviar http request
   * @param req JSON body
   * @param endpoint Endpoint
   */
  sendPrebookingRequest(req, endpoint) {
    // let headers = new HttpHeaders();
    // headers = headers.set('novaetoken', this.dataService.token);
    // return this.httpClient.post<any>(this.middlewareUrl + endpoint, req, {headers: headers});
    return this.httpClient.post<any>(this.middlewareUrl + endpoint, req);
  }

  sendBookingCodeRequest(req) {
    return this.httpClient.get<any>(this.middlewareUrl + req);
  }

  getBookingLocator(purchaseId?) {
    purchaseId = purchaseId ? purchaseId : this.purchaseId;
    return this.httpClient.get<any>(this.middlewareUrl + 'booking/bookingcode/' + purchaseId);
  }









  /**
   * Obtiene el purchaseId de novae
   */
  getPurchaseId() {
    // if (this.typeProductSelected === EProduct.BENEFITS) {
    //   return new Promise<any>((resolve, reject) => {
    //     // SE AGREGA EL PRODUCTO Y SE CREA EL PURCHASE PARA EL PAGO
    //     this.setBenefitToPurchase();
    //     const channelId = +this.channelId;
    //     const language = 'ES';

    //     this.onePocketService.addPurchaseAlle(channelId, language).subscribe((response: any) => {
    //       if (response.operationResult.code === 100) {
    //         this.purchaseId = response.purchaseID;
    //         resolve();
    //       } else {
    //         reject(response);
    //       }
    //     }, (error) => {
    //       reject(error);
    //     });
    //   });
    // }
    let request = this.getOnePocketPurchaseRequest();
    this.iceValuePercentage = this.storeLibrary.getItemFromStorage('percentage');
    request.addPurchaseRequest.purchase.iceValue = this.iceValuePercentage;
    return new Promise<any>((resolve, reject) => {
      this.onePocketService.addPurchase(request).subscribe((response: any) => {
        if (response.addPurchaseResponse.resultOperation.code === 100) {
          this.purchaseId = response.addPurchaseResponse.resultAddPurchase.purchase.purchaseID;
          resolve(true);
        } else {
          reject(response);
        }
      }, (error) => {
        reject(error);
      });
    });
  }

  /**
   * Setea el addPurchse request
   */
  getOnePocketPurchaseRequest(): BodyAddPurchase {
    let product: Journey | HotelAvailabilityOptions | RentacarAvailabilityOption | IActivityResult | IBenefitResponse;
    product = this.selectedProduct;
    const productType = this.typeProductSelected;
    const urlMiddleware = this.storeLibrary.getJustOneUniversalPropertie(keysUniversal.middleware_url);
    const purchaseProductInfo = new PurchaseProductInfo(
      product, productType,
      'ES', this.brand, this.channelId, this.applicationId,
      this.searchedData, urlMiddleware);
    return purchaseProductInfo.addPurchaseRequest;
  }

  setBenefitToPurchase() {
    const userData: UserData = this.storeLibrary.getUserData();
    userData.authenticationToken = this.dataService.token;
    this.storeLibrary.setUserData(userData);

    const product = (this.selectedProduct as IBenefitResponse);
    const benefit: any = {
      hierarchical: product.id,
      value: product.price.totalPrice,
      isoCurrency: product.productCurrency,
      name: product.productCode,
    };
    this.storeLibrary.setProductToPurchase(benefit);
  }

  sendPaymentConfirmation() {
    this.dataService.sendPaymentConfirmation(this.purchaseId);
  }

  private getBuyer(): ITraveller | null {
    // si hay un pasajero "cero", es el buyer
    if (this.dataTravellers && this.dataTravellers[0]) {
      return this.dataTravellers[0];
    } else if (this.dataService.smartProfileUserData) {
      // si no
      // se obtienen los datos del buyer del usuario logueado
      const smartProfileUserData = this.dataService.smartProfileUserData;
      return <ITraveller>{
        id: 1,
        number: 1,
        type: smartProfileUserData.identificationDto.typeId,
        firstName: smartProfileUserData.firstName,
        lastName: smartProfileUserData.lastName1,
        name: smartProfileUserData.firstName,
        surname: smartProfileUserData.lastName1,
        documentType: smartProfileUserData.identificationDto.typeId,
        documentNumber: smartProfileUserData.identificationDto.number,
        nationality: smartProfileUserData.addtionalInformationDto.isoCountry,
        city: smartProfileUserData.addtionalInformationDto.cityName,
        address: smartProfileUserData.streetAddress,
        gender: null,
        dateOfBirth: null,
        age: null,
        email: smartProfileUserData.emailaddress,
        telephone: smartProfileUserData.addtionalInformationDto.phoneNumber,
        telephoneCountry: smartProfileUserData.addtionalInformationDto.phoneNumber.substring(0, 1),
        telephoneArea: smartProfileUserData.indicativeCode,
        requiredInformations: []
      };
    } else {
      // si no, no hay buyer
      return null;
    }
  }

}
