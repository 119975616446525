<div class="">
    <div class="version-desktop">
        <div class="item airline">
            <img src="/assets/airlines/{{segment.company.iata}}.png" alt="logo" appFallBackImg="/assets/airlines/default.png" onerror="this.src='/assets/airlines/default.png';">
            <div class="bl-right">
                <small>{{'SEGMENT.DETAIL_ROUTE.flight_text'|translate}}: {{ segment.flightNumber }}</small>
                <p class="title"> {{ segment.departureHour }} - {{ segment.arrivalHour}} </p>
                <small> {{ segment.origin.cityName }} - {{ segment.destination.cityName }} </small>
                <small> {{ segment.company.shortName }} </small>
            </div>
        </div>
        <div class="line"></div>
        <div class="item cabin">
            <small class="mb-0"> {{ cabin }} </small>
        </div>
        <div class="line"></div>
        <div class="item duration">
            <p class="subtitle">{{ segment.flightDuration }}</p>
            <small class="">{{ segment.origin.iata }} - {{ segment.destination.iata }}  </small>
        </div>
        <div class="line"></div>
        <div class="item baggage">
            <i class="travel-icon icon-luggage"></i>
            <small class="small-alert">  {{ baggage }}  </small>
        </div>
    </div>

    <div class="version-mobile">
        <div class="top">
            <div class="content">
                <img src="/assets/airlines/{{segment.company.iata}}.png" alt="logo" appFallBackImg="/assets/airlines/default.png" onerror="this.src='/assets/airlines/default.png';">
                <div class="">
                    <p class="title">{{ segment.departureHour }}</p>
                    <small> {{ segment.origin.cityName }} </small>
                </div>
            </div>
            <div class="content">
                <small>{{'SEGMENT.DETAIL_ROUTE.flight_text'| translate}}: {{ segment.flightNumber }}</small>
                <p class="">{{ segment.origin.iata }} - {{ segment.destination.iata }} </p>
            </div>
            <div class="content">
                <div class="cabin">
                    <small class="mb-0"> {{ cabin }} </small>
                    <small class="subtitle">{{ segment.flightDuration }}</small>
                </div>
                <div class="">
                    <p class="title"> {{ segment.arrivalHour }} </p>
                    <small> {{ segment.destination.cityName }} </small>
                </div>
            </div>
        </div>
        <div class="baggage">
            <i class="travel-icon icon-luggage"></i>
            <small class="small-alert">  {{ baggage }}  </small>
        </div>
    </div>

    <app-detail-scale-info [ngClass]="'row'" *ngIf="!isLastSegment" [segment]="segment">
    </app-detail-scale-info>
</div>