import { IPlace, IRange, IPaxe } from './searched.model';
import { BehaviorSubject } from 'rxjs';
import { formatDate } from '@angular/common';
import { locale } from 'moment';

export class SearchedActivity {
    destination: IPlace;
    date: IRange;
    paxes: IPaxe[];

    private get translations() {
        return this.translations$.value['ISearched'] ? this.translations$.value['ISearched']['SearchedActivity'] : [];
    }

    private get translationsDate() {
        return this.translations$.value['DATE'] || [];
    }

    constructor(
        public obj: SearchedActivity,
        private translations$: BehaviorSubject<any>,
        private language$: BehaviorSubject<any>
    ) {
        this.destination = this.obj.destination;
        this.date = this.obj.date;
        this.paxes = this.obj.paxes;
    }

    /**
     * retorna las fechas en formato "humano legible"
     * resolviendo el formato para cada idioma
     */
    get dateToHumanReadable(): IRange {
        return {
            start: formatDate(
                this.date.start,
                this.translationsDate['format_weekday'],
                locale(this.language$.value)
            ).toString(),
            end: formatDate(
                this.date.end,
                this.translationsDate['format_weekday'],
                locale(this.language$.value)
            ).toString()
        };
    }

    get qPassenger(): number {
        return this.paxes.length;
    }

    get qPassengerToString(): string {
        return this.qPassenger + ' ' + (this.qPassenger === 1 ? this.translations['passenger'] : this.translations['passengers']);
    }
}
