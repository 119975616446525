import { CardLayoutTwoModel, CardInfo } from '../../component/card/models/card-layout-two.model';
import { CardPrice } from 'src/app/component/card/models/card-price.model';

export class HotelOptions implements CardLayoutTwoModel {

    static toArray(values: any[], info: CardInfo[]): HotelOptions[] {
        values.forEach((v, i) => {
            values[i] = new HotelOptions(
                v.id,
                v.name,
                v.board,
                v.ratePlanCode,
                v.rooms,
                v.price,
                info,
                v.fullPenaltyOnCancellationAfterBooking,
                v.cancellationPolicy,
            );
        });
        return values;
    }

    constructor(
        public id: string,
        public name: string,
        public board: string,
        public ratePlanCode: string,
        public rooms: HotelAvailabilityRoom[],
        public price: CardPrice,
        public info: CardInfo[],
        /**
         * Indicates if the combination is non refundable. If it’s true, the cancellation will have a 100%
         * penalty cost from the date in which the booking is confirmed.
         */
        public fullPenaltyOnCancellationAfterBooking: boolean,
        public cancellationPolicy: HotelBookingRulesCancellationPolicy,
    ) {}

    nameSelected = () => ( this.rooms[0].name );
    subnameSelected = () => ( this.board );
    getMoneyAmount = () => ( this.price.calculatedPrice ? +this.price.calculatedPrice : +this.price.totalPrice );
    getPointAmount = () => ( this.price.pointsPrice ? +this.price.pointsPrice : 0 );
    refundable = () => ( !this.cancellationPolicy ? null : this.cancellationPolicy.refundable === 'Y' ? true : false );
}

export interface HotelAvailabilityRoom {
    name: string;
    description: string;
    images?: Image;
    units: number;
}

export interface HotelBookingRulesCancellationPolicy {
    refundable: string;
    refundableInfo: {
        cancellationPolicy: HotelCancellationPolicy[];
    };
}

export interface HotelCancellationPolicy {
    amount: number;
    to: string;
}

export interface Image {
    imgURL: string;
    label: string;
}

export interface IPrice {
    basePrice: number;
    currency: string;
    providerCurrency?: string;
    providerTotalPrice?: string;
    taxes: number;
    totalPrice: number;
    charges?: number;
    discount?: number;
    type?: string;

    calculatedPrice?: string;
    pointsPrice?: string;
    restPointValue?: string;
}
