import { Component, Inject, Input, OnInit, Optional, Renderer2 } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { keysUniversal, StoreServiceLibrary } from '@wearenovae/novae-core-services';
import { DataStoreService } from 'src/app/services/data-store.service';
import { DataService } from 'src/app/services/data.service';
import { InitService } from 'src/app/services/init.service';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { DOCUMENT } from '@angular/common';


declare var window: any;

@Component({
  selector: 'app-benefits',
  templateUrl: './benefits.component.html',
  styleUrls: ['./benefits.component.scss']
})
export class BenefitsComponent implements OnInit {
  language: string;
  urlTermAndConditions: string = '';
  // adicionar dominio para encontrar la imagen tanto en bitacora como en alle2020
  //https://uat.scotia-rewards.com/assets/images/banner-benefits/es/banner-traveling-1.png

  benefitsImages = {
    en: {
      image1: `../assets/images/banner-benefits/en/banner-traveling-1.png`,
      image2: `../assets/images/banner-benefits/en/banner-traveling-2.png`,
      image3: `../assets/images/banner-benefits/en/banner-traveling-3.png`,
    },
    es: {
      image1: `../assets/images/banner-benefits/es/banner-traveling-1.png`,
      image2: `../assets/images/banner-benefits/es/banner-traveling-2.png`,
      image3: `../assets/images/banner-benefits/es/banner-traveling-3.png`,
    }
  }

  srcCommunity = `../assets/images/scotia/community.png`;
  srcPurchase = `../assets/images/icon-purchase.svg`;
  srcPersonal = `../assets/images/icon-personal.svg`;
  srcCyber = `../assets/images/icon-cyber.svg`;
  srcUpperMilesPerson = `../assets/images/uppermiles-person.png`;

  @Input()
  showTermsAndConditions: boolean = true;

  showBannerHeaderBenefits: boolean = false;

  get brand() {
    return this.dataStoreService.brand;
  }

  constructor(
    public dataStoreService: DataStoreService,
    private translateService: TranslateService,
    private storeServiceLibrary: StoreServiceLibrary,
    public dataService: DataService,
    private initService: InitService,
    private render: Renderer2,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    if (data) {
      this.showTermsAndConditions = data.showTermsAndConditions;
    }

    this.language = this.translateService.currentLang;
    const dominio = document.location.origin;
    this.benefitsImages = {
      en: {
        image1: dominio + `/assets/images/banner-benefits/${this.brand}/en/banner-traveling-1.png`,
        image2: dominio + `/assets/images/banner-benefits/${this.brand}/en/banner-traveling-2.png`,
        image3: dominio + `/assets/images/banner-benefits/${this.brand}/en/banner-traveling-3.png`,
      },
      es: {
        image1: dominio + `/assets/images/banner-benefits/${this.brand}/es/banner-traveling-1.png`,
        image2: dominio + `/assets/images/banner-benefits/${this.brand}/es/banner-traveling-2.png`,
        image3: dominio + `/assets/images/banner-benefits/${this.brand}/es/banner-traveling-3.png`,
      }
    }

    this.srcCommunity = dominio + `/assets/images/scotia/community.png`;
    this.srcPurchase = dominio + `/assets/images/icon-purchase.svg`;
    this.srcPersonal = dominio + `/assets/images/icon-personal.svg`;
    this.srcCyber = dominio + `/assets/images/icon-cyber.svg`;
    this.srcUpperMilesPerson = `${dominio}/assets/images/${this.brand}-miles/uppermiles-person.png`;
  }

  ngOnInit(): void {
    this.translateService.onLangChange.subscribe((data) => {
      this.language = data['lang'];
    });
    this.initService.isInitializedBrand.subscribe(isInitialized => {
      if (isInitialized) {
        this.changeLanguage();
        this.loadUrl();
        // this.countryCodeChange();

        // if (this.dataStoreService.brand == `credomatic`) {
        //   if (this.storeServiceLibrary.getItemFromStorage(`CountrySelected`)) {
        //     this.countryCredomatic = this.storeServiceLibrary.getItemFromStorage(`CountrySelected`);
        //   }
        // }
        // this.getPromo(TypePromotions.system);
        //TO-DO PROVISIONAL
        // let strBool = this.storeServiceLibrary.getJustOneUniversalPropertie(keysUniversal.web_show_code_promotion);
        // this.showCodePromotion = this.stringToBoolean(strBool);
        //TO-DO PROVISIONAL
      }
    });

    if (environment.brands[this.dataStoreService.brand].config['bannerHeaderBenefits']) {
      const elementRef = document.getElementById('bannerHeaderBenefits');
      this.render.setStyle(elementRef, 'display', 'block');
    }
  }

  changeLanguage() {
    this.translateService.onLangChange.subscribe((data) => {
      const iframe = document.getElementById('iframe_onepocket');
      const message = { typeMessage: 'LANGUAGE_CHANGE', language: data['lang'] };
      this.dataService.sendPostMessage(message, '*', iframe);
    });
  }

  // countryCodeChange() {
  //   this.eventService.countryCodeChange.subscribe((countryCodeChange) => {
  //     //
  //     if (this.dataStoreService.brand == `credomatic`) {
  //       if (countryCodeChange?.code) {
  //         this.countryCredomatic = countryCodeChange.code;
  //       }
  //     }
  //     //
  //     this.getPromo(TypePromotions.system);
  //     //
  //   });
  // }

  // getPromo(typePromo: TypePromotions) {
  //   this.typePromotion = typePromo;
  //   let userData: UserData = this.storeServiceLibrary.getUserData()
  //   //Llamar aservicio de obtener programas
  //   let requestGetPrograms = new GetProgramsRequest();
  //   requestGetPrograms.userToken = userData.authenticationToken;
  //   requestGetPrograms.quantityPoints = 0;
  //   this.codePromotionsService.getPrograms(requestGetPrograms).subscribe(
  //     (respGetPrograms: any) => {
  //       if (respGetPrograms?.operationResult?.code == 200 && respGetPrograms?.programsList) {
  //         let programIdList = [];
  //         for (const itr of respGetPrograms?.programsList) {
  //           programIdList.push(itr.programid);
  //         }
  //         programIdList = programIdList.sort(
  //           (a, b) => {
  //             return a - b
  //           }
  //         );
  //         /////////////////////////////////////////////
  //         let requestPromotion = new GetPromotionsByUserRequest();
  //         requestPromotion.lenguaje = this.translateService.currentLang;
  //         requestPromotion.programId = programIdList.join(",");
  //         requestPromotion.channelId = this.data.getChannel().toString();
  //         requestPromotion.country = this.countryCredomatic;
  //         requestPromotion.token = userData.authenticationToken;
  //         requestPromotion.type = typePromo;
  //         requestPromotion[`consult`] = true

  //         this.promotion = {};
  //         this.codePromotionsService.getPromotionsByUser(requestPromotion).subscribe((resp: GetPromotionsByUserResponse) => {
  //           if (resp?.transactionResult?.operationResult?.code == `200`) {
  //             this.promotion.code = resp?.transactionResult?.promotion?.code;
  //             this.promotion.type = resp?.transactionResult?.promotion?.type;
  //             this.promotion.fechafin = resp?.transactionResult?.promotion?.fechafin;
  //             this.promotion.percentage = resp?.transactionResult?.promotion?.percentage;
  //             this.promotion.currency = resp?.transactionResult?.promotion?.currency;
  //             this.promotion.namePromotion = resp?.transactionResult?.promotion?.namePromotion;
  //             this.promotion.typeDiscount = resp?.transactionResult?.promotion?.typeDiscount;
  //             this.promotion.valueDiscount = resp?.transactionResult?.promotion?.valueDiscount;
  //             if (typePromo == TypePromotions.code) {
  //               this.sendInfoPromotion(this.promotion);
  //             }
  //           } else {
  //             this.sendInfoPromotion(this.promotion);
  //             if (typePromo == TypePromotions.system) {
  //               this.getPromo(TypePromotions.code);
  //             }
  //           }
  //         }, (err) => {
  //         });
  //       } else {
  //       }
  //     },
  //     (err) => {
  //     }
  //   );

  // }

  // sendInfoPromotion(promotion: any) {
  //   let code = promotion?.code || ``;
  //   let infoPromotionObj = {
  //     code: code
  //   };
  //   this.eventService.infoPromotion(infoPromotionObj);
  // }

  openTyC() {
    const lang = this.storeServiceLibrary.getItemFromStorage('lang') || 'en';
    let urlTerms = this.storeServiceLibrary.getJustOneUniversalPropertie(keysUniversal.web_url_terms_and_conditions_en);
    if (lang) {
      if (lang == 'es') {
        urlTerms = this.storeServiceLibrary.getJustOneUniversalPropertie(keysUniversal.web_url_terms_and_conditions_es);
      }
    }
    window.open(urlTerms, '_blank');
  }

  openUrl(url) {
    window.open(url, '_target');
  }

  loadUrl() {
    const lang = this.storeServiceLibrary.getItemFromStorage('lang') || 'en';
    // const urlTerms = environment.brands[this.dataStore.brand].terminos_y_condiciones[lang.toUpperCase()];
    let urlTerms = this.storeServiceLibrary.getJustOneUniversalPropertie(keysUniversal.web_url_terms_and_conditions_en);

    if (lang) {
      if (lang == 'es') {
        urlTerms = this.storeServiceLibrary.getJustOneUniversalPropertie(keysUniversal.web_url_terms_and_conditions_es);
      }

    }
    if (!this.dataStoreService.isAlleMobile) {
      this.urlTermAndConditions = urlTerms;
    } else {
      this.urlTermAndConditions = `${urlTerms}`;
    }
    return this.urlTermAndConditions;
  }

}
