import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { TranslateService } from '@ngx-translate/core';
import { StoreServiceLibrary, keysUniversal, GetJWTTokenRequest, GetJWTTokenResponse } from '@wearenovae/novae-core-services';
import { Session } from 'protractor';

@Injectable({
  providedIn: 'root'
})
export class ActivationCardService {

  constructor(
    private http: HttpClient,
    private store: StoreServiceLibrary,
    private translate: TranslateService) {
  }

  getJWT() {
    // const url = 'https://devtransactionproxy-api.alle2020.com/v1/getJWTToken';
    const url = this.store.getJustOneUniversalPropertie(keysUniversal.url_jwt);
    const body: GetJWTTokenRequest = {
      username: 'FrontTransactionProxyApi',
      password: 'FrontTransactionProxyApi*#'
    };
    return this.http.post<any>(url, body);
  }

  activateCard(tokenJWT, emailUser, productCard) {
    const URL = this.store.getJustOneUniversalPropertie(keysUniversal.url_activate_credit_card);
    let body = {
      languageIso: this.translate.currentLang || this.translate.defaultLang,
      email: emailUser,
      productCard
    };
    const header = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${tokenJWT}`
    });

    return this.http.post(URL, body, { headers: header });
  }
}
