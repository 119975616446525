import { EventEmitter, Injectable } from '@angular/core';
import { FilterGroupType, ISlidersGroupIds } from './filterParams';

export const unique = (value, index, self) => {
  return self.indexOf(value) === index;
};

@Injectable({
  providedIn: 'root'
})
export class FilterService {
  smartProfilePreferenceSeted: boolean;
  preNgSelectArray: [];
  autocompleteOptions;
  autocompleteOptionsFull;
  slidersGroupIds: ISlidersGroupIds[];
  idsToEvaluate = [];
  n = 0;
  activateFilterGroup = [];
  idsToShow = [];
  fullIds = [];
  idsArrayToFilter: EventEmitter<any> = new EventEmitter();
  preferencesFilter: [{
    filterName: string,
    filterType: FilterGroupType,
    pushRemove: boolean,
    optionsIdsArray: any
  }];
  constructor() {
  }

  activateFilter(filterName: string, filterType: FilterGroupType, pushRemove: boolean, optionIdsArray: any[], multiOptions: boolean): void {
    const that = this;
    const activateFilterGroupExist = that.findValue(this.activateFilterGroup, filterName);
    // Si pushRemove = true, realiza un push en el array de Grupos de filtros activos
    if (pushRemove) {
      that.activateFilterGroup.push(filterName);
      // Si found = undefined, suma 1 a N, se utiliza para evaluar la cantidad de grupos de filtros diferentes activos
      if (activateFilterGroupExist === undefined) {
        that.n++;
      }
      that.idsToEvaluate.push(...optionIdsArray);
    } else {
      if (activateFilterGroupExist === filterName) {
        that.activateFilterGroup.splice(that.activateFilterGroup.indexOf(filterName), 1);
        const foundB = that.findValue(that.activateFilterGroup, filterName);
        if (foundB === undefined) {
          that.n--;
        }
      }
      for (let i = 0; i < optionIdsArray.length; i++) {
        that.idsToEvaluate.splice(that.idsToEvaluate.indexOf(optionIdsArray[i]), 1);
      }
    }

    that.idsToShow = [];
    const uniqueIds = new Set(that.idsToEvaluate);
    if (that.n > 0) {

      if (that.idsToEvaluate.length > 0) {

        uniqueIds.forEach(uniqueId => {
          if (that.countRepeatedValues(uniqueId, that.idsToEvaluate) >= that.n ) {
            that.idsToShow.push(uniqueId);
          }
        });
      } else {

        that.idsToShow = [];
      }
    } else {

      that.idsToShow = that.fullIds;
    }

    // const uniqueIdsToShow = that.idsToShow.filter(unique);
    that.idsArrayToFilter.emit({
      IDs: that.idsToShow,
      isFiltered: true
    });
  }
  findValue(groupToSearch, elementToFind): any {
    return groupToSearch.find(function (element) {
      if (element) {
        return element === elementToFind;
      } else {
        return undefined;
      }
    });
  }

  returnIntersectionValues(sourceA: any[], sourceB: any[]) {
    return sourceA.filter( a => sourceB.includes(a));
  }

  countRepeatedValues(uniqueValue: any, listOfValues: any[]): number {
    return listOfValues.filter( val => val === uniqueValue).length;
  }
}
