<div class="form-container">
    <div class="b-contacto">
        <h1>{{'CONTACT.title'|translate}}</h1>
        <p>{{'CONTACT.subtitle'|translate}}</p>
    </div>

    <div class="content-registro">
        <form [formGroup]="contactForm" (ngSubmit)="onSubmit()" *ngIf="contactForm">

            <div class="item-form">
                <div class="item" [ngClass]="{
                    'valid': isFormControlValid(contactForm.get('firstName')),
                    'invalid': isFormControlInvalid(contactForm.get('firstName')),
                    'focused': firstName.isFocused
                }">
                    <input formControlName="firstName" appIsInputFocused
                        #firstName="isFocused"
                        [placeholder]="'CONTACT.name'|translate"
                        class="form-control">
                    <small class="validation-error"
                        *ngIf="isFormControlInvalid(contactForm.get('firstName'))"
                        innerHTML="{{'CONTACT.name_enter'|translate}}">
                        Por favor ingrese nombre
                    </small>
                </div>

                <div class="item" [ngClass]="{
                    'valid': isFormControlValid(contactForm.get('lastName')),
                    'invalid': isFormControlInvalid(contactForm.get('lastName')),
                    'focused': lastName.isFocused
                }">
                    <input formControlName="lastName" appIsInputFocused
                        #lastName="isFocused"
                        [placeholder]="'CONTACT.last_name'|translate"
                        class="form-control">
                    <small class="validation-error"
                        *ngIf="isFormControlInvalid(contactForm.get('lastName'))"
                        innerHTML="{{'CONTACT.last_name_enter'|translate}}">
                        Por favor ingrese apellido
                    </small>
                </div>
            </div>

            <div class="item-form">
                <div class="item" [ngClass]="{
                    'valid': isFormControlValid(contactForm.get('email')),
                    'invalid': isFormControlInvalid(contactForm.get('email')),
                    'focused': email.isFocused
                }">
                    <input formControlName="email"
                        appIsInputFocused #email="isFocused"
                        [placeholder]="'CONTACT.mail'|translate"
                        class="form-control">
                    <small class="validation-error"
                        *ngIf="isFormControlInvalid(contactForm.get('email'))"
                        innerHTML="{{'CONTACT.mail_invalid'|translate}}">
                        Por favor ingrese email
                    </small>
                </div>

                <div class="item" [ngClass]="{
                    'valid': isFormControlValid(contactForm.get('phone')),
                    'invalid': isFormControlInvalid(contactForm.get('phone')),
                    'focused': phone.isFocused
                }">
                    <input formControlName="phone"
                        appIsInputFocused
                        #phone="isFocused"
                        class="form-control number"
                        [placeholder]="'CONTACT.phone_number'|translate">
                    <small class="validation-error"
                        *ngIf="isFormControlInvalid(contactForm.get('phone'))"
                        innerHTML="{{'CONTACT.phone_invalid'|translate}}">
                        Número de teléfono inválido
                    </small>
                </div>
            </div>

            <div class="item-form">
                <div class="item dropdown" [ngClass]="{
                    'valid': isFormControlValid(contactForm.get('country')),
                    'invalid': isFormControlInvalid(contactForm.get('country')),
                    'focused': country.isFocused
                }">
                    <select class="form-control"
                        formControlName="country" appIsInputFocused
                        #country="isFocused" (change)="fillCityList()">
                        <option value="0"
                            innerHTML="{{'CONTACT.select_your_country'|translate}}">
                            country
                        </option>
                        <option *ngFor="let country of countryList"
                            value="{{ country.iso }}">
                            {{ country.name }}
                        </option>
                    </select>
                    <small class="validation-error"
                        *ngIf="isFormControlInvalid(contactForm.get('country'))"
                        innerHTML="{{'CONTACT.select_your_country'|translate}}">
                        Por favor ingrese nacionalidad
                    </small>
                </div>

                <div class="item dropdown" [ngClass]="{
                    'valid': isFormControlValid(contactForm.get('city')),
                    'invalid': isFormControlInvalid(contactForm.get('city')),
                    'focused': city.isFocused
                }">
                    <select class="form-control selectpicker"
                        style="position: initial;"
                        formControlName="city" appIsInputFocused
                        #city="isFocused" placeholder="Ciudad">
                        <option value="0"
                            innerHTML="{{'CONTACT.select_your_city' | translate}}">
                            Ciudad
                        </option>
                        <option *ngFor="let city of cityList"
                            value="{{city.name}}">
                            {{ city.name }}
                        </option>
                    </select>
                    <small class="validation-error"
                        *ngIf="isFormControlInvalid(contactForm.get('city'))"
                        innerHTML="{{'CONTACT.select_your_city' | translate}}">
                        Por favor ingrese ciudad
                    </small>
                </div>
            </div>

        </form>
    </div>
</div>
