<div class="item hotel-row ps-0 mt-2" *ngIf="hotelList && hotelList.length > 0">
  <ng-container
    *ngFor="let Hotel of hotelList | filter:filteredID; let j = index; let f2 = first;">
    <app-card *ngIf="j < qtyResultShow && dataStore.selectedHotel"
      [product]="Hotel" [options]="cardOptions"
      [selectedProductId]="dataStore.selectedHotel.id"
      (productDetailSelected)="detailCardListener($event)"
      (selectProduct)="selectProductListener($event)"></app-card>
  </ng-container>
  <div class="clearfix"></div>
  <div class="button-container mt-3">
    <button class="btn-travel btn-hotel" *ngIf="(hotelList | filter:filteredID).length > qtyResultShow"
      (click)="AddResults()" innerHTML="{{ 'HOTEL_BODY.HOTEL_LIST.more_results' | translate }}">
      Mostrar más resultados
    </button>
  </div>
</div>