<div class="row">
    <div class="col-12">
        <h2>{{ activity.name }}</h2>
    </div>
</div>
<div class="row">
    <div class="col-12">{{ date }}</div>
</div>
<div class="row" *ngIf="hasMeetingPoint">
    <div class="col-12">
        <h2 innerHTML="{{'PURCHASE_SUMARY.ACTIVITY.meeting_point' | translate}}">Punto de encuentro</h2>
        <p>{{ meetingPoint }}</p>
    </div>
</div>
<!-- <div class="row" *ngIf="meetingPointHour">
    <div class="col-12 meeting-point">
        <h2 innerHTML="{{'PURCHASE_SUMARY.ACTIVITY.start_hour' | translate}}">Hora de inicio</h2>
        <svg width="39px" height="39px" viewBox="0 0 39 39" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
            <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g id="icon-clock" transform="translate(-172.000000, -283.000000)">
                    <g id="Group-3-Copy" transform="translate(174.000000, 285.000000)">
                        <circle id="Oval" cx="17.5" cy="17.5" r="17.5"></circle>
                        <g id="Group-2" transform="translate(15.000000, 7.500000)" stroke-linecap="round">
                            <path d="M2.5,12.5 L2.5,0.665439594" id="Line-2"></path>
                            <path d="M2.5,12.5 L9.71540087,16.5769542" id="Line-2"></path>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
        <p>{{ meetingPointHour }}</p>
    </div>
</div> -->

<div class="row cancellationPolicies">
    <div class="registro-persona">
        <h2 class="cancellation-title"
            innerHTML="{{'PURCHASE_SUMARY.CAR.cancellation_policies' | translate}}">
            Políticas de cancelación
        </h2>
        <span class="no-cancellationPolicies"
            *ngIf="!cancellationPolicies || !cancellationPolicies.length; else mostrarPoliticas">
            <i class="icon-reembolsable"></i>
            {{ translationsActivity['no_cancellation_policies'] }}
        </span>
        <ng-template #mostrarPoliticas>
            <div class="with-cancellationPolicies">
                <i class="icon-reembolsable"></i>
                <ul>
                    <li *ngFor="let value of cancellationPolicies; let l = last; let i = index;">
                        <!-- <ng-template *ngIf="value.to !== null; else noHasta">
                            <span>
                                {{ value.from | date: translationsDate['pipe_date'] }} - 
                                {{
                                    (value.to === null && !l && cancellationPolicies[i].to)
                                    ? (cancellationPolicies[i].to | date: translationsDate['pipe_date'])
                                    : (value.to | date: translationsDate['pipe_date'])
                                }}
                            </span>
                        </ng-template>
                        <ng-template #noHasta>
                            <span>{{value.from | date: translationsDate['pipe_date']}} - {{ translationsActivity['no_limit_date'] }}</span>
                        </ng-template>-->
                        <strong innerHTML="{{ 'PURCHASE_SUMARY.cancellation_from' | translate:'{date:\'' + (fromDate(value)) + '\'}' }}">{{ value.from | date: translationsDate['pipe_date'] }}</strong>
                        <br>
                        <small *ngIf="value.percent === '0.0'" innerHTML="{{ 'PURCHASE_SUMARY.penalty' | translate: '{amount:\'' + currency + (value.amount | milesFormat) + '\'}'}}"></small>
                        <small *ngIf="+value.amount === 0 && value.percent !== '0.0'" innerHTML="{{ 'PURCHASE_SUMARY.penalty_percent' | translate: '{amount:\'' + value.percent + '\'}'}}"></small>
                    </li>
                </ul>
            </div>
        </ng-template>
    </div>
</div>
