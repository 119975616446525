<section class="section press1 padding-first gray">
  <div class="container">
    <div class="tittle">
      <h2>Tutoriales</h2>
      <p [innerHTML]="titleTutorials"></p>
    </div>
  </div>
</section>

<div class="container">
  <div class="bl-colums-press">

    <div class="item" *ngFor="let tutorial of tutorialsDataFilter">
      <div>
        <span>{{ tutorial.title }}</span>
        <div class="iphone-prev">
          <div class="play" (click)="openModalVideo(tutorial)">
            <img src="../../../assets/images/credomatic-miles/icon-play.svg" alt="">
          </div>
          <img [src]="tutorial.image | domSecurity" alt="">
        </div>
        <p [innerHTML]="tutorial.description"></p>
      </div>
    </div>

  </div>
</div>

<app-footer-all [brand]="brand"></app-footer-all>
