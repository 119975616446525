<div class="container" *ngIf="translations">
    <div class="header" *ngIf="options.hasTitle">
        <app-card-header></app-card-header>
    </div>
    <div class="body">
        <div class="image" *ngIf="isHidden">
            <app-card-image></app-card-image>
        </div>
        <div class="description">
            <app-card-description></app-card-description>
        </div>
        <div>
            <app-card-price-modal class="price"></app-card-price-modal>
        </div>
    </div>
</div>