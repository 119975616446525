import { MainFormModule } from './../main-form/main-form.module';
import { TranslateModule } from '@ngx-translate/core';
import { SliderOpModule } from './../../component/slider-op/slider-op.module';
import { PipesModule } from 'src/app/pipes/pipes.module';
import { FiltersModule } from './../shared/filters/filters.module';
import { ResultsComponent } from './results.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CardModule } from 'src/app/component/card/card.module';
import { Routes, RouterModule } from '@angular/router';
import { DirectivesModule } from 'src/app/directives/directives.module';
import { ProductDetailModule } from 'src/app/component/product-detail/product-detail.module';
import { ProductDetailComponent } from 'src/app/component/product-detail/product-detail.component';
import { AlertErrorComponent } from '../../component/product-detail/alert-error/alert-error.component';

const routes: Routes = [{ path: '', component: ResultsComponent }];


@NgModule({
  declarations: [
    ResultsComponent,
    AlertErrorComponent
  ],
  exports: [
    ResultsComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    CardModule,
    ProductDetailModule,
    DirectivesModule,
    FiltersModule,
    PipesModule,
    SliderOpModule,
    TranslateModule,
    MainFormModule
  ],
  entryComponents: [
    ProductDetailComponent,
    AlertErrorComponent
  ]
})
export class ResultsModule { }
