<div class="container">


  <div class="content-flights">

    <div class="info-flights">
      <div class="top-info">
        <h1 innerHTML="{{'FIXED_RATES.tittle' | translate}}"></h1>
        <p innerHTML="{{'FIXED_RATES.message' | translate}}"></p>
      </div>
      <div class="bottom-info">
        <p innerHTML="{{'FIXED_RATES.footer_1' | translate}}"></p>
        <p innerHTML="{{'FIXED_RATES.footer_2' | translate}}"></p>
      </div>
    </div>

    <div class="table-container">
      <div class="table-flights" *ngIf="startYourAventure">
        <div class="bl-box-tittle">
          <div class="left">
            <span>{{ 'FIXED_RATES.tittle_table' | translate }}</span>
          </div>

          <div class="right">
            <p>{{ 'FIXED_RATES.tittle_table_colum' | translate }}</p>
            <div class="bl-two">
              <span>{{ 'FIXED_RATES.colum_1' | translate }}</span>
              <span>{{ 'FIXED_RATES.colum_2' | translate }}</span>
            </div>
          </div>
        </div>

        <div class="bl-box" [ngClass]="{'gray': i % 2 === 0 }"
          *ngFor="let item of fixedRates['TypeFixedRates']; let i=index">
          <div class="left">
            <p>{{ item['title'][languaje] }}</p>
          </div>

          <div class="right">
            <div class="item" [ngClass]="{ 'disabled': pointUser < item['economy']['value'] }">
              <label>
                <input [id]="item['economy']['id']" type="radio" name="fixedRates"
                  [disabled]="pointUser < item['economy']['value']"
                  (click)="verifyActivatedRadio(item, item['economy'], item['title'][languaje], typeSelectedClass['economy'][languaje])">
                <span class="custom-check"></span>
              </label>
              <span>{{ item['economy'].valueName | milesFormat }}</span>
            </div>

            <div class="item" [ngClass]="{ 'disabled': pointUser < item['business']['value']}">
              <label>
                <input [id]="item['business']['id']" type="radio" name="fixedRates"
                  [disabled]="pointUser < item['business']['value']"
                  (click)="verifyActivatedRadio(item, item['business'], item['title'][languaje],  typeSelectedClass['business'][languaje])">
                <span class="custom-check"></span>
              </label>
              <span>{{ item['business'].valueName | milesFormat }}</span>
            </div>
          </div>
        </div>

      </div>
      <div class="checkout-form" *ngIf="!startYourAventure">
        <div class="btn-back-fixed-rates" *ngIf="!startYourAventure" (click)="backToTableFixedRates()"></div>
        <h3>{{ 'FIXED_RATES.tittle_table' | translate }}</h3>
        <div class="info">
          <p>{{ fixedRatesSelected.description }}</p>
          <p><span>{{ 'FIXED_RATES.total_scotia_points' | translate }}</span> {{ fixedRatesSelected.value | milesFormat
            }}</p>
        </div>
        <app-search-flight-fixed-rates *ngIf="!startYourAventure" [typeSelect]="priceType"
          (notifyHaveParameters)="showIframeLoadData($event)" (notifyValueData)="changeValuePoints($event)">
        </app-search-flight-fixed-rates>
      </div>
      <div class="btn" [ngClass]="{ 'btn-disable': disabledButton, 'btn-active': activateButton }"
        *ngIf="startYourAventure" (click)="searchFlightsFixedRates()">{{ 'FIXED_RATES.button' | translate }}</div>
    </div>


  </div>
</div>