import { Component, OnInit } from '@angular/core';
import { keysUniversal, StoreServiceLibrary } from '@wearenovae/novae-core-services';
import { DataStoreService } from '../../../services/data-store.service';
import { environment } from '../../../../environments/environment';
import { Router } from '@angular/router';
import { ActionEventService } from '../../../services/action-event.service';

import data from '../../../resources/screenAndEventsTracking.json';
import { GoogleAnalytics } from '../../../resources/GoogleAnalytics.resource';

@Component({
  selector: 'app-section-second',
  templateUrl: './section-second.component.html',
  styleUrls: ['./section-second.component.scss']
})
export class SectionSecondComponent implements OnInit {

  brand = '';
  appName = '';

  constructor(private _storeLibrary: StoreServiceLibrary,
    private dataStoreService: DataStoreService,
    private router: Router,
    private actionEventService: ActionEventService) { }

  ngOnInit() {
    this.brand = this.dataStoreService.brand.toUpperCase();
    this.appName = environment.brands[this.dataStoreService.brand].appName;
  }

  redirect() {
    // this.trackingManager.trackEventString(data.WALKTHROUGH.bookNow, data.WALKTHROUGH.walkthroughScreen);
    GoogleAnalytics.sendEventFirebase('ga_event', data.WALKTHROUGH.walkthroughScreen, data.WALKTHROUGH.bookNow, '');
    this.actionEventService.sendRedirectTo(true);
    this.router.navigateByUrl('/travel');
  }

}
