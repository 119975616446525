import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { DataService } from 'src/app/services/data.service';
import { FormBuilder, Validators, AbstractControl, FormGroup } from '@angular/forms';
import { LoginService } from 'src/app/container/shared/login/login.service';
import { LanguageService } from '../language/language.service';
import { TranslateService } from '@ngx-translate/core';
import { IRegister } from 'src/app/container/shared/login/model/register.model';
import { ValidatePinResponse } from '@wearenovae/novae-core-services';

@Component({
  selector: 'app-registry-confirmation',
  templateUrl: './registry-confirmation.component.html',
  styleUrls: ['./registry-confirmation.component.scss']
})
export class RegistryConfirmationComponent implements OnInit {

  constructor(public router: Router,
    public dataService: DataService,
    public activatedRoute: ActivatedRoute,
    private fb: FormBuilder,
    private loginService: LoginService,
    private languageService: LanguageService,
    private translateService: TranslateService) { }

  ngOnInit() {
    this.getParametersFromURL();
    this.languageService.systemLanguajeChange.subscribe((language) => {
      if (language) {
        this.getTranslations();
      }
    });
  }

  public loading: boolean;
  private registry: IRegister;
  private idC: string; //id certificado
  private pin: string;
  private username: string;
  private channelid: string;
  private name: string = '';
  private lastName: string = '';
  private email: string;
  private accId: string = undefined;

  public hasFirstName: boolean = false;
  public hasLastName: boolean = false;
  public initialized: boolean = false;

  /**
   * textos para el usuario, traducidos a su idioma
   */
  translations = {};

  /**
   * formulario de confirmacion
   */
  formConfirmation: FormGroup;

  private getParametersFromURL(): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      let timeout;
      const subs = this.activatedRoute.queryParams.subscribe(params => {

        // Id Certificado
        if (params['idC'] !== undefined) {
          this.idC = params['idC'];
        }
        // Pin
        if (params['p'] !== undefined) {
          this.pin = params['p'];
        }
        // Nombre
        if (params['name'] !== undefined) {
          this.name = params['name'];
          this.hasFirstName = params['name'].length > 0;
        }
        // Apellido
        if (params['lname'] !== undefined) {
          this.lastName = params['lname'];
          this.hasLastName = params['lname'].length > 0;

        }
        if (params['aid'] !== undefined && params['aid'].length > 0 && params['aid'].toString() !== '0') {
          this.accId = params['aid'];
        }
        // Email
        if (params['email'] !== undefined) {
          this.email = params['email'];
        }
        // Channel
        if (params['channelid'] !== undefined) {
          this.channelid = params['channelid'];
        }
        this.loadFormGroupData();
        this.initialized = true;
        resolve(true);
      });
    });
  }

    /**
   * password min length
   */
  passwordMinLength(c: AbstractControl): { password_length: boolean } {
    if (c.value && c.value.length > 3 && (c.value.length < 8 || c.value.length > 16) && c.dirty) {
      return { password_length: true };
    }
  }

  /**
   * Password security check
   * @param c 
   */
  passwordSecurityCheck(c: AbstractControl): { password_safe: boolean } {
    const safeChecker = new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\\^@|!#$%&/=???~{}+*_.,:;\(\)\[\]\-\\<>])([A-Za-z\d\^@|!#$%&/=???~{}+*_.,:;\(\)\[\]\-\\<>]){8,20}$/);

    if (c.value && c.value.length > 7 && !safeChecker.test(c.value) && c.dirty) {
      return { password_safe: true };
    }
  }

  /**
   * validar contraseña confirmada
   */
  private passwordConfirming(c: AbstractControl): { password_not_match: boolean } {
    if (c.get('password').value !== c.get('password_confirm').value && c.get('password').touched) {
      return { password_not_match: true };
    }
  }

  /**
   * condiciones en las que no se puede hacer click en next
   */
  get nextButtonDisabled(): boolean {
    return this.formConfirmation.invalid;
  }

  next() {
    //Send registry
    this.loading = true;
    this.registry = { 
      firstName : this.formConfirmation.getRawValue().firstName,
      lastName : this.formConfirmation.getRawValue().lastName,
      email: this.email,
      language: this.translateService.getDefaultLang(),
      password: this.formConfirmation.getRawValue().password,
      address: 'Address',
      cityName: 'Kingston',
      countryIso: 'JM',
      docType: 'PAS',
      numberId: '',
      phone: '10000000000',
      certificateNumber: this.idC,
      register: true,
      accountId: this.accId
    };
    this.loginService.validateConfirmation(this.pin,this.idC,this.translateService.getDefaultLang()).then( (validation: ValidatePinResponse) => {
      this.loginService.register(
        this.registry
      ).then((data) => {
        // signup successful
        this.loading = false;
        this.username = data.username;
        this.stepSignupSuccessful();
      }).catch((message) => {
        // incorrect signup
        this.loading = false;
        alert(message || this.translations['signup_fail']);
      });
    }).catch( (message) => {
      this.loading = false;
      alert(message || this.translations['signup_fail']);
    })
  }

    /**
   * registro correcto
   */
  stepSignupSuccessful() {
    this.router.navigate(['/home']);
  }

  private getTranslations() {
    this.translateService.get(['LOGIN']).subscribe((trns) => {
      this.translations = trns['LOGIN'];
    });
  }

  loadFormGroupData() {
    this.formConfirmation = this.fb.group({
      password: ['', [
        Validators.required,
        this.passwordMinLength,
        this.passwordSecurityCheck
      ]],
      password_confirm: ['',
        Validators.required
      ],
      firstName: [this.name,
        Validators.required
      ],
      lastName: [this.lastName,
        Validators.required
      ],
    }, {
      validators: [this.passwordConfirming, this.passwordSecurityCheck]
    });
  }
}
