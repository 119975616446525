import { StoreServiceLibrary } from '@wearenovae/novae-core-services';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Language, LanguagePrefix } from './language.model';
import { BehaviorSubject } from 'rxjs';
import { environment } from '../../../environments/environment';
import { DataStoreService } from '../../services/data-store.service';
import { ActionEventService } from '../../services/action-event.service';

declare var window: any;
declare var navigator: any;

@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  // servicio de lenguaje inicializado
  isReady = new BehaviorSubject<boolean>(false);
  // emite el lenguaje del sistema
  systemLanguajeChange = new BehaviorSubject<LanguagePrefix>(null);

  // nombres de los lenguajes (cambian según el idioma seleccionado)
  languageNames$ = new BehaviorSubject<Language[]>([]);

  /**
   * lenagujes disponibles en el sistema
   */
  get languages(): LanguagePrefix[] {
    return Object.keys(LanguagePrefix) as LanguagePrefix[];
  }

  constructor(
    private trasnlateService: TranslateService,
    private dataStoreService: DataStoreService,
    private storeService: StoreServiceLibrary,
    private actionService: ActionEventService
  ) { }

  /**
   * Configura el sistema para que muestre un lenguaje inicial
   */
  init() {
    // cargar lenguajes disponibles de los archivos disponibles
    this.loadLanguajes(this.languages);

    // set lenguaje inicial
    this.initSystemLanguaje();

    // obtener los nobmres de los lenguajes, traducidos
    this.systemLanguajeChange.subscribe((language) => {
      if (language) {
        this.setLanguageNames();
      }
    });
  }

  /**
   * bindear lenguajes disponibles (los archivos json de lenguajes) con el servicio translate de ngx
   */
  private loadLanguajes(languages: LanguagePrefix[]) {
    this.trasnlateService.addLangs(languages);
  }

  /**
   * inicializa el lenguaje del sistema
   */
  private initSystemLanguaje() {
    // obtener el lenguaje del navegador
    let lang = environment.brands[this.dataStoreService.brand].defaultLanguaje;
    if (environment.brands[this.dataStoreService.brand].config.isLangNavigator) {

      let defaultLang;

      if (window.navigator.language) {
        defaultLang = window.navigator.language;
      } else if (navigator.language) {
        defaultLang = window.language;
      } else if (navigator.userLanguage) {
        defaultLang = window.userLanguage;
      }

      const splitLanguaje = defaultLang.split('-');
      if (splitLanguaje[0]) {
        defaultLang = splitLanguaje[0];
      }

      if (defaultLang === 'es' || defaultLang === 'en') {
        lang = defaultLang;
      }
    }

    this.trasnlateService.use(lang);
    this.trasnlateService.setDefaultLang(lang);
    this.storeService.setItemInStorage('lang', lang);

    // setear lenguaje del sistema igual al del navegador
    this.setSystemLanguaje(lang as LanguagePrefix).then(() => {
      this.isReady.next(true);
    });
  }

  /**
   * indicar el lenguaje en el que se mostrará el sistema
   */
  setSystemLanguaje(languaje: LanguagePrefix): Promise<any> {
    return new Promise((resolve) => {
      // this.trasnlateService.setDefaultLang(languaje);
      this.trasnlateService.use(languaje);
      this.trasnlateService.setDefaultLang(languaje);
      this.storeService.setItemInStorage('lang', languaje);
      setTimeout(() => {
        this.systemLanguajeChange.next(languaje);
        this.storeService.setItemInStorage('lang', languaje);
        this.actionService.changeSelectorLanguaje(languaje);
        resolve(true);
      }, 350);
    });
  }

  /**
   * nombres de los lenguajes disponibles en el sistema
   */
  private setLanguageNames() {
    this.trasnlateService.get([
      'LANGUAGES'
    ]).subscribe((trans) => {
      this.languageNames$.next(this.sortLanguages(trans['LANGUAGES']));
    });
  }

  /**
   * ordenar array con los lenguajes
   */
  sortLanguages(languages): Language[] {
    return (Object.keys(languages).map((key) => {
      return {
        name: languages[key],
        prefix: key
      };
    }) as Language[]).sort((a: Language, b: Language) => {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    });
  }

  getValidLanguage(language:string) {
    let languageReturn = environment.brands[this.dataStoreService.brand].defaultLanguaje;;
    
    this.languages.forEach((item) => {
      if (item == language) {
        languageReturn = language;
        return;
      }
    });
        
    return languageReturn;    
  }
}
