<div class="row" *ngIf="confirmationData !== undefined">
    <div class="col-12">
        <div class="content-confirmacion">

            <div class="content-head">
                <div class="cabecera-titulo col-md-12 text-center" innerHTML="{{ trans['CONFIRMATION']['approved'] }}">
                    {{ confirmationData.transactionData.status }}
                </div>
                <div class="miles-usd">

                    <span *ngIf="confirmationData.transactionData.transactionPoints > 0">
            <strong>{{confirmationData.transactionData.transactionPoints | milesFormat}}</strong>
            {{ 'CONFIRMATION.miles' | translate }}
          </span>

                    <span *ngIf="showPlus" class="icon-mas"></span>

                    <span *ngIf="confirmationData.transactionData.transactionMoney > 0">
            {{confirmationData.transactionData.transactionCurrency}}
            <strong>{{confirmationData.transactionData.transactionMoney}}</strong>
          </span>

                </div>
                <div class="cabecera-info row">
                    <div class="infoCol col-4">
                        <span class="">{{ confirmationData.transactionData.date | date: trans['DATE']['confirmation_date'] }}</span>
                    </div>
                    <div class="infoCol col-4">
                        <span class="destacado" *ngIf="confirmationData.type !== 'benefits'">
              <strong class="pr-2"
                innerHTML="{{ trans['CONFIRMATION']['reservation_code'] }}">
                Código de reserva:
              </strong>
              {{confirmationData.bookingData.purchaseId}}
            </span>
                    </div>
                    <div class="infoCol col-4">
                        <span>
              {{formatTime(confirmationData.transactionData.date + ' ' + confirmationData.transactionData.time) | date:'HH:mm'}}
            </span>
                    </div>
                </div>
            </div>

            <div class="title-info">
                <!--*ngIf="confirmationData.type === 'benefits'"-->
                {{ confirmationTitles.productTitle }}
            </div>



            <div class="content-datos" [ngSwitch]="confirmationData.type">
                <ng-container *ngSwitchCase="EProduct.FLIGHTS">
                    <div class="datos-col row">
                        <div class="col-6"><strong innerHTML="{{ trans['CONFIRMATION']['airline'] }}">Aerolínea</strong> {{ journey.flights[0].company.longName}}</div>
                        <div class="col-6"><strong>Purchase ID:</strong> # {{confirmationData.transactionData.transactionNumber}}
                        </div>
                    </div>
                    <ng-container *ngFor="let flight of journey.flights; index as flightIndex; last as l;">
                        <app-confirmation-flight [flight]="flight" [flightIndex]="flightIndex"></app-confirmation-flight>
                    </ng-container>
                </ng-container>

                <app-confirmation-hotel *ngSwitchCase="EProduct.HOTELS" [hotel]="hotel" [confirmationData]="confirmationData">
                </app-confirmation-hotel>

                <app-confirmation-benefits *ngSwitchCase="EProduct.BENEFITS" [confirmationData]="confirmationData"></app-confirmation-benefits>

                <app-confirmation-car *ngSwitchCase="EProduct.CARS" [confirmationData]="confirmationData"></app-confirmation-car>

                <app-confirmation-activity *ngSwitchCase="EProduct.ACTIVITIES" [activity]="activity" [transactionNumber]="confirmationData.transactionData.transactionNumber">
                </app-confirmation-activity>
            </div>

            <div class="content-datos" *ngFor="let traveller of confirmationData.travellers; let i = index">
                <div class="datos-col row">
                    <div class="col-12 title-info">
                        {{ trans['CONFIRMATION']['passenger'] }} ({{i+1}})
                    </div>
                </div>

                <div class="datos-col row" *ngIf="confirmationData.type !== 'benefits'">
                    <div class="col-6">
                        <strong innerHTML="{{ trans['CONFIRMATION']['name'] }}">Nombre:</strong> {{traveller.firstName}}
                    </div>
                    <div class="col-6">
                        <strong innerHTML="{{ trans['CONFIRMATION']['lastname'] }}">Apellido:</strong> {{traveller.lastName}}
                    </div>
                    <div class="col-6">
                        <strong innerHTML="{{ trans['CONFIRMATION']['birthday'] }}">Fecha de
              Nacimiento:</strong> {{traveller.dateOfBirth}}</div>
                    <div class="col-6">
                        <strong innerHTML="{{ trans['CONFIRMATION']['document_number'] }}">Número
              de Documento:</strong> {{traveller.documentNumber}}</div>
                    <div class="col-6">
                        <strong innerHTML="{{ trans['CONFIRMATION']['nationality'] }}">Nacionalidad:</strong> {{data.getCountryNameByID(traveller.nationality)}}
                    </div>
                    <div class="col-6">
                        <strong innerHTML="{{ trans['CONFIRMATION']['title'] }}">Titulo:</strong> {{data.getTitleNameByID(traveller.gender)}}
                    </div>
                </div>

                <div class="datos-col row" *ngIf="confirmationData.type === 'benefits'">
                    <div class="col-6">
                        <strong innerHTML="{{ trans['CONFIRMATION']['name'] }}">Nombre:</strong> {{traveller.firstName}}
                    </div>
                    <div class="col-6">
                        <strong innerHTML="{{ trans['CONFIRMATION']['lastname'] }}">Apellido:</strong> {{traveller.lastName}}
                    </div>
                    <div class="col-6">
                        <strong innerHTML="{{ trans['CONFIRMATION']['mail'] }}">Correo:</strong> {{traveller.email}}
                    </div>
                </div>

            </div>

            <div class="content-datos">
                <div class="datos-col row">
                    <div class="col-12 title-info" innerHTML="{{ trans['CONFIRMATION']['payment_information'] }}">
                        Payment Information</div>
                </div>
                <div class="datos-col row">
                    <div class="col-6" *ngIf="confirmationData.transactionData.transactionPoints > 0">
                        <strong innerHTML="{{ trans['CONFIRMATION']['miles_card'] }}">Points Card:</strong> {{confirmationData.transactionData.transactionPoints}} {{ trans['CONFIRMATION']['miles'] }}</div>

                    <!-- <div class="col-6"><strong>Tarjeta de crédito:</strong> <i class="icon-card"></i> •••• 9110 USD {{confirmationData.transactionData.transactionMoney}}</div> -->
                    <div class="col-6" *ngIf="confirmationData.transactionData.transactionMoney > 0">
                        <strong innerHTML="{{ trans['CONFIRMATION']['credit_card'] }}">Credit card:</strong>
                        <i [ngClass]="getCreditCardFranchise()"></i> •••• {{confirmationData.transactionData.transactionCreditCardProductNumbers}} {{confirmationData.transactionData.transactionCurrency}} {{confirmationData.transactionData.transactionMoney}}
                    </div>
                    <div class="col-6">
                        <strong innerHTML="{{ trans['CONFIRMATION']['authorization'] }}">Authorization:</strong> {{confirmationData.transactionData.transactionAutorizationCode}}
                    </div>
                </div>
            </div>

            <div class="content-datos beneficios">
                <!-- <div class="datos-col row">
              <div class="col-12 title-info">Beneficios</div>
          </div> -->
                <div class="datos-col container-items-beneficios">
                    <!-- <div class="item-beneficio">
                <i class="recompensas"></i>
                <small>Recompensas</small>
              </div> -->

                    <!-- <div class="vertical-divider"></div>

              <div class="item-beneficio">

                <i *ngIf="confirmationData.type == 'flight'" class="fly"></i>
                <i *ngIf="confirmationData.type == 'hotel'" class="icon-hotel-confirm"></i>
                <i *ngIf="confirmationData.type == 'car'" class="icon-car-confirm"></i>

                <small *ngIf="confirmationData.type == 'flight'">Cobertura Fly</small>
                <small *ngIf="confirmationData.type == 'hotel'">Cobertura Hotel</small>
                <small *ngIf="confirmationData.type == 'car'">Cobertura Car</small>
              </div> -->

                    <!-- <div class="vertical-divider"></div> -->

                    <!-- <div class="item-beneficio">
                <i class="stay"></i>
                <small>Cobertura Stay</small>
              </div> -->
                    <!--<button class="descargar-aprobacion">Descargar</button>-->
                </div>
            </div>

        </div>
        <!-- finaliza content-confirmacion -->

        <div class="button-container mb-2 mt-3">
            <button innerHTML="{{ trans['CONFIRMATION']['finalize'] }}" (click)="finalizar()" class="btn-travel">Finalize</button>
        </div>

    </div>
</div>