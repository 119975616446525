import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {

  transform(list: any, filteredID: any): any {
    if (filteredID.values.length === 0 && !filteredID.isFiltered) {
      return list;
    }
    return list.filter(item => {
      return filteredID.values.indexOf(item.id) !== -1;
    });
  }

}
