<ng-template [ngIf]="options.type === 'room'" [ngIfElse]="other">
    <div class="text-left mt-3">
        <p class="precio-paquete" innerHTML="{{ 'CARD.CARD_PRICE.total_price' | translate:'{value:\'' + card.travellers + '\'}' }}">
            Total price per
        </p>
        <p class="new-price">{{ card.priceMoneyPerTraveller | milesFormat }} <span innerHTML="{{ 'CARD.CARD_PRICE.miles' | translate }}"></span> </p>
        <p class="new-price">
            <span>{{currency}}</span>{{ card.pricePointsPerTraveller | milesFormat }}
        </p>
    </div>
</ng-template>

<ng-template #other>
    <div class="bl-price">
        <p class="precio-paquete" innerHTML="{{ 'CARD.CARD_PRICE.total_price' | translate:'{value:\'' + card.travellers + '\'}' }}">
            Total price per
        </p>
        <div class="bl-new">
            <p class="new-price">{{ price.pointsPrice | milesFormat }} <span innerHTML="{{ 'CARD.CARD_PRICE.miles' | translate }}"></span></p>
            <p class="new-price">
                <span>{{currency}} </span> {{ price.calculatedPrice | milesFormat }}
            </p>
        </div>
    </div>
    <div class="clearfix"></div>
    <div class="mb-2 button-container mt-1 ps-0">
        <button class="btn-travel hola" (click)="selectDetail()">
      {{ 'CARD.CARD_PRICE.details' | translate: '{product:\'' + card.productTranslate + '\'}' }}
    </button>
    </div>
    <div class="container">
        <div class="row">
            <p class="ps-0 col" *ngIf="card.nights !== undefined">
                <i class="travel-icon icon-calendar" aria-hidden="true"></i> {{ card.nights }}
            </p>
            <p class="ps-0 col" *ngIf="options.type !== 'activity' && card.travellers !== undefined">
                <i class="travel-icon icon-people" aria-hidden="true"></i> {{ card.travellers }}
            </p>
        </div>
    </div>
</ng-template>