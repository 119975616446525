<div class="card travel-box">
    <div class="card_info">
        <div
            [ngClass]="isSelected ? 'corner-selected selected' : 'corner-selected'">
            <div class="input-selector"
                (click)="selectOption(option)"></div>
        </div>
        <div class="description-container">
            <div *ngIf="option.images" class="image">
                <img src="" alt="">
            </div>
            <div class="text">
                <p class="title">{{ option.name }}</p>
                <p class="description"
                    innerHTML="{{ option.description }}"></p>
            </div>
        </div>
        <div
            class="d-flex align-items-center flex-column flex-sm-row selector-container">
            <div class="actions-container">
                <div class="select-container">
                    <i class="icon-calendario"></i>
                    <i class="icon-arrow-down"></i>
                    <h6
                        innerHTML="{{ 'ACTIVITY_DETAIL.date' | translate }}">
                        Fecha y Horario
                    </h6>
                    <select class="material_select"
                        (change)="selectOption(option, $event.target.value)">
                        <option *ngIf="!option.bookingCode">
                            {{'ACTIVITY_DETAIL.select_option'|translate}}
                        </option>
                        <option
                            *ngFor="let date of option.dates; let i = index"
                            [value]="i">
                            {{ formatTime(date.startDate) }}
                        </option>
                    </select>
                </div>
                <div class="pointer-container"
                    *ngIf="option.meetingPoints && option.meetingPoints.length > 0">
                    <i class="icon-pointer"></i>
                    <mat-radio-group [(ngModel)]="meetingPoint">
                        <h6
                            innerHTML="{{ 'ACTIVITY_DETAIL.meeting_point' | translate }}">
                            Punto de encuentro
                        </h6>
                        <mat-radio-button class="point" [value]="i"
                            (change)="selectMeetingPoint($event.source.value)"
                            *ngFor="let point of option.meetingPoints; let i = index">
                            <p>{{ point.name }}</p>
                            <p>{{ formatTime(point.time) }}</p>
                        </mat-radio-button>
                    </mat-radio-group>
                </div>
            </div>

            <div class="divider align-self-stretch"></div>
            <div class="price-container" *ngIf="option.selectedDate">
                <p class="strong"
                    innerHTML="{{ 'ACTIVITY_DETAIL.activity_price' | translate }}">
                    Precio por actividad</p>
                <p class="point-price">{{ pointsPrice | milesFormat }}
                    <span
                        innerHTML="{{ 'ACTIVITY_DETAIL.miles' | translate }}">points</span>
                </p>
                <p class="money-price">+ {{ moneyPrice }}</p>
            </div>
        </div>
    </div>
</div>