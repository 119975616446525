import { ProductDetailService } from './../product-detail.service';
import { Component, OnInit, Input } from '@angular/core';
import { HotelAvailabilityOptions } from 'src/app/models/new-hotel/hotel-availability-response.model';
import { getCurrencySymbol, formatDate } from '@angular/common';
import { locale } from 'moment';

@Component({
  selector: 'app-selection',
  templateUrl: './selection.component.html',
  styleUrls: ['./selection.component.scss']
})
export class SelectionComponent implements OnInit {

  @Input() product: any; // HotelAvailabilityOptions
  translations = {};
  travellersQty: number;
  constructor(
    private productDetailService: ProductDetailService
  ) {}

  get pointsPrice() {
    return this.product.getPointAmount();
  }

  get moneyPrice() {
    return this.product.getMoneyAmount();
  }

  get currency() {
    return this.product.price.currency + ' ' + getCurrencySymbol(this.product.price.currency, 'narrow');
  }

  get roomName() {
    return this.product.nameSelected();
  }

  get board() {
    return this.product.subnameSelected();
  }

  get nights() {
    const info = this.product.info.find(item => item.type === 'night');
    const nights = info ? info.value : 0;
    const translations = this.productDetailService.translations;
    switch (nights) {
      case 0:
        return false;
        break;
      case 1:
        return '1 ' + translations['night'];
        break;
      default:
        return nights + ' ' + translations['nights'];
        break;
    }
  }

  get travellers() {
    const info = this.product.info.find(item => item.type === 'traveller');
    const travellers = info ? info.value : 0;
    const translations = this.productDetailService.travellerType;
    switch (travellers) {
      case 0:
        return false;
        break;
      case 1:
        return '1 ' + translations[0];
        break;
      default:
        return travellers + ' ' + translations[1];
        break;
    }
  }

  get roomsQty() {
    const rooms = this.product.selectedOption.rooms ? this.product.selectedOption.rooms.length : 0;
    const translations = this.productDetailService.translations;
    switch (rooms) {
      case 0:
        return false;
        break;
      case 1:
        return '1 ' + translations['room'];
        break;
      default:
        return rooms + ' ' + translations['rooms'];
        break;
    }
  }

  get wifi() {
    const toReturn = this.product.info.find(item => item.type === 'wifi');
    return toReturn ? toReturn.value : null;
  }

  get breakfast() {
    const toReturn = this.product.info.find(item => item.type === 'breakfast');
    return toReturn ? toReturn.value : null;
  }

  get hasOptionMeetingPoint() {
    return this.product.selectedOption.selectedMeetingPoint ? true : false;
  }

  get meetingPoint() {
    return this.product.selectedOption.selectedMeetingPoint.name;
  }

  get dateMeetingPoint() {
    return formatDate(this.product.selectedOption.selectedDate.startDate, 'dd/MM', locale()).toString();
  }

  ngOnInit() {
  }

}
