<ng-template [ngIf]="options.type === 'car'">
  <div class="bl-price">
    <div class="precio-paquete"
      innerHTML="{{ 'CARD.CARD_PRICE.price_total' | translate }}">
      Total price
    </div>
    <div class="bl-new">
      <h6 class="new-price">
        {{ price.pointsPrice | milesFormat }} 
        <span innerHTML="{{ 'CARD.CARD_PRICE.miles' | translate }}"></span>
      </h6>
      <div class="new-price">
        <span>{{currency}}</span>
        {{ price.calculatedPrice }}
      </div>
    </div>
  </div>
</ng-template>