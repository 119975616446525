import {Component, OnInit, ViewChild, HostListener, Input, EventEmitter, Output} from '@angular/core';
import {ViewEncapsulation} from '@angular/core';
import {BuyerComponent} from '../buyer.component';
import {HomeSearchComponent} from "../../home-search.component";
import {DataStoreService} from '../../../../services/data-store.service';
import {LoginService} from "../../../../container/shared/login/login.service";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {maxPassangersValidator} from "../../customSearchValidators";

@Component({
  selector: 'app-passengers',
  templateUrl: './passengers.component.html',
  styleUrls: ['./passengers.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PassengersComponent implements OnInit {
  buyerForm: FormGroup;
  private readonly MAX_PASSENGERS = 8;

  get maxAdults() {
    return this.MAX_PASSENGERS - this.buyerForm.get('passangerConf').get('kids').value - this.buyerForm.get('passangerConf').get('infants').value;
  }

  get maxKids() {
    return this.MAX_PASSENGERS - this.buyerForm.get('passangerConf').get('adults').value - this.buyerForm.get('passangerConf').get('infants').value;
  }

  get maxInfants() {
    let maxInfantes;
    if (this.MAX_PASSENGERS - this.buyerForm.get('passangerConf').get('adults').value - this.buyerForm.get('passangerConf').get('kids').value < this.buyerForm.get('passangerConf').get('adults').value) {
      maxInfantes = this.MAX_PASSENGERS - this.buyerForm.get('passangerConf').get('adults').value - this.buyerForm.get('passangerConf').get('kids').value;
    } else {
      maxInfantes = this.buyerForm.get('passangerConf').get('adults').value
    }
    return maxInfantes;
  }

  constructor(
    private dataStore: DataStoreService,
    private loginService: LoginService,
    private buyer: BuyerComponent
  ) {
  }

  ngOnInit() {
    this.loginService.loginChange.subscribe(isLoged => {
      if (isLoged) {
        if (this.dataStore.smartProfilePreferences && this.dataStore.smartProfilePreferences.flightClass) {
          this.preferCabinFn(this.dataStore.smartProfilePreferences.flightClass);
        }
      }
    });
    this.buyerForm = this.createFormBuyerGroup();
  }

  createFormBuyerGroup() {
    return new FormGroup({
      passangerConf: new FormGroup({
        adults: new FormControl(1, [Validators.required]),
        kids: new FormControl(0, [Validators.required]),
        infants: new FormControl(0, [Validators.required])
      }, [Validators.required, maxPassangersValidator]),
      cabin: new FormGroup({
        economy: new FormControl(true, [Validators.required]),
        premiumEconomy: new FormControl(false, [Validators.required]),
        firstClass: new FormControl(false, [Validators.required]),
        bussiness: new FormControl(false, [Validators.required])
      })
    });
  }

  preferCabinFn(preferCabin) {
    switch (preferCabin) {
      case 'Y':
        this.buyerForm.get('cabin').get('economy').setValue(true);
        break;
      case 'F':
        this.buyerForm.get('cabin').get('firstClass').setValue(true);
        this.buyerForm.get('cabin').get('economy').setValue(false);
        break;
      case 'B':
        this.buyerForm.get('cabin').get('bussiness').setValue(true);
        this.buyerForm.get('cabin').get('economy').setValue(false);
        break;
    }
  }

  onApply() {
    this.buyer.onApply(this.buyerForm.value);
  }
}
