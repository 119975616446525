<div class="big-image ps-0 text-center mb-2">
    <div class="swiper-container gallery-top">
        <div class="swiper-wrapper">
        <img class="swiper-slide" [src]="mainImageSrc | srcSanitizer" alt="" (error)="onErrorFn()">
        </div>
    </div>
</div>
<div class=" miniaturas ps-0" *ngIf="images.length > 1">
    <app-activity-slider (clickedImgSrc)="clickedImgSrcListener($event)"></app-activity-slider>
</div>
