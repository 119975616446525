<section class="seccion-3">
    <div class="container">
        <img class="element-spiral" src="../assets/images/about/element-s2.png" alt="ncb-miles">
        <div class="row">
            <div class="col">
                <img class="phone" src="../assets/images/about/en/phone-s3.png" alt="">
            </div>
            <div class="col bl-left">
                <h1 innerHTML="{{'LANDING_ABOUT.SECTION_SECUND.SECTION1.title'| translate}}">.</h1>
                <h2 class="gradient" innerHTML="{{'LANDING_ABOUT.SECTION_SECUND.SECTION1.subtitle'| translate}}"></h2>
                <p innerHTML="{{'LANDING_ABOUT.SECTION_SECUND.SECTION1.parrafo_default1'| translate:'{appName:\'' + appName + '\', brand:\''+ brand + '\'}'}}"></p> 
                <p innerHTML="{{'LANDING_ABOUT.SECTION_SECUND.SECTION1.parrafo_default2'| translate}}"></p>
                <p innerHTML="{{'LANDING_ABOUT.SECTION_SECUND.SECTION1.parrafo_default3'| translate}}">.</p>
                <span class="text-download parrafo-logos" innerHTML="{{ 'LANDING_ABOUT.text_download'| translate:'{appName:\'' + appName + '\'}' }}"></span>
                <app-download-store [class]="'bl-stores'"></app-download-store>
            </div>
        </div>
    </div>
</section>

<section class="seccion-4 viajes-voyage">
    <div class="container">
                <h1 innerHTML="{{'LANDING_ABOUT.SECTION_SECUND.SECTION2.title'| translate}}"></h1>
                <h2 innerHTML="{{'LANDING_ABOUT.SECTION_SECUND.SECTION2.subtitle'| translate}}"></h2>
                <p class="especial" innerHTML="{{'LANDING_ABOUT.SECTION_SECUND.SECTION2.parrafo_default1'| translate : '{appName:\'' + appName + '\'}'}}"></p>

            <div class="bl-center">
                <ul class="logos-voyage">
                    <li><img src="./assets/images/myrewards-miles/voyage-expedia.png" alt="mr"></li>
                    <li><img src="./assets/images/myrewards-miles/voyage-priceline.png" alt="mr"></li>
                    <li><img src="./assets/images/myrewards-miles/voyage-viator.png" alt="mr"></li>
                    <li><img src="./assets/images/myrewards-miles/voyage-vrbo.png" alt="mr"></li>
                    <li><img src="./assets/images/myrewards-miles/voyage-hotels.png" alt="mr"></li>
                    <li><img src="./assets/images/myrewards-miles/voyage-american.png" alt="mr"></li>
                    <li><img src="./assets/images/myrewards-miles/voyage-avianca.png" alt="mr"></li>
                    <li><img src="./assets/images/myrewards-miles/voyage-qatar.png" alt="mr"></li>
                    <li><img src="./assets/images/myrewards-miles/voyage-copa.png" alt="mr"></li>
                    <li><img src="./assets/images/myrewards-miles/voyage-jet.png" alt="mr"></li>
                    <li><img src="./assets/images/myrewards-miles/voyage-airbnb.png" alt="mr"></li>
                    <li><img src="./assets/images/myrewards-miles/voyage-wyndham.png" alt="mr"></li>
                    <li><img src="./assets/images/myrewards-miles/voyage-hyatt.png" alt="mr"></li>
                    <li><img src="./assets/images/myrewards-miles/voyage-ihg.png" alt="mr"></li>
                    <li><img src="./assets/images/myrewards-miles/voyage-marriott.png" alt="mr"></li>
                    <li><img src="./assets/images/myrewards-miles/voyage-voyages.png" alt="mr"></li>
                    <li><img src="./assets/images/myrewards-miles/voyage-universal.png" alt="mr"></li>
                    <li><img src="./assets/images/myrewards-miles/voyage-disney.png" alt="mr"></li>
                    <li><img src="./assets/images/myrewards-miles/voyage-golf.png" alt="mr"></li>
                    <li><img src="./assets/images/myrewards-miles/voyage-car.png" alt="mr"></li>
                </ul>
            </div>

            <div class="bl-column">
                <span innerHTML="{{ 'LANDING_ABOUT.SECTION_SECUND.SECTION2.parrafo_default2'| translate }}"></span>
                <button (click)="redirect()">{{ 'LANDING_ABOUT.bookNow' | translate }}</button>
            </div>
    </div>
</section>


<!-- <section class="seccion-4">
    <div class="container">
        <div class="row">
            <div class="col">
                <h1 innerHTML="{{'LANDING_ABOUT.SECTION_SECUND.SECTION2.title'| translate}}"></h1>
                <h2 class="gradient" innerHTML="{{'LANDING_ABOUT.SECTION_SECUND.SECTION2.subtitle'| translate}}"></h2>
                <p class="especial" innerHTML="{{'LANDING_ABOUT.SECTION_SECUND.SECTION2.parrafo_default1'| translate : '{appName:\'' + appName + '\'}'}}"></p>
            </div>
            <div class="col">
                <img class="logos-bank" src="../assets/images/about/logos-bank.png" alt="">
            </div>
        </div>


        <div class="bl-column">
            <span class="gradient" innerHTML="{{ 'LANDING_ABOUT.SECTION_SECUND.SECTION2.parrafo_default2'| translate }}"></span>
            <button (click)="redirect()">{{ 'LANDING_ABOUT.bookNow' | translate }}</button>
        </div>
    </div>
</section> -->