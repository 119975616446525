import { Component, OnInit, Input, ElementRef, ViewChild } from '@angular/core';
import { DataStoreService } from '../../../services/data-store.service';
import { environment } from 'src/environments/environment';
import { StoreServiceLibrary, keysUniversal } from '@wearenovae/novae-core-services';
import { TranslateService } from '@ngx-translate/core';
import { SwiperComponent, SwiperDirective, SwiperModule, SwiperConfigInterface, SwiperPaginationInterface } from 'ngx-swiper-wrapper';
import { InitService } from 'src/app/services/init.service';

@Component({
  selector: 'app-section-four-mr',
  templateUrl: './section-four-mr.component.html',
  styleUrls: ['./section-four-mr.component.scss']
})
export class SectionFourMRComponent implements OnInit {

  @Input() brand: string;
  urlsVideos: any = environment.brands[this.dataStore.brand].videosLandingEN.sectionFour;
  iphoneTops = `./assets/images/${this.dataStore.brand}-miles/iphone-tops.png`;

  objToLoadVideo = {
    one: false,
    two: false
  };

  public bigSliderConfig: SwiperConfigInterface = {
    direction: 'horizontal',
    slidesPerView: 3,
    breakpoints: {
      991: {
        initialSlide: 1,
        slidesPerView: 3,
        freeMode: false,
        init: false,
        centeredSlides: true,
        allowSlidePrev: false,
        allowSlideNext: false
      },
      768: {
        initialSlide: 1,
        slidesPerView: 1.5,
        freeMode: false,
        init: true,
        centeredSlides: true,
        autoHeight: true,
        setWrapperSize: true,
        pagination: {
          el: '.swiper-pagination',
        },
      },
      540: {
        initialSlide: 1,
        slidesPerView: 1,
        freeMode: false,
        init: true,
        centeredSlides: true,
        autoHeight: true,
        setWrapperSize: true,
        pagination: {
          el: '.swiper-pagination',
        },
      },
    },
  };

  @ViewChild(SwiperComponent) swiper: SwiperComponent;

  contentCard2 = [
    {
      titulo: 'Miles Care Premium',
      precioUno: '99',
      precioDos: '1,000',
      precioTres: '600',
      precioCuatro: '75',
      precioCinco: '100,000',
      precioSeis: '500'
    },
    {
      titulo: 'Miles Care Exclusive',
      precioUno: '179',
      precioDos: '2,000',
      precioTres: '800',
      precioCuatro: '100',
      precioCinco: '250,000',
      precioSeis: '1,000'
    },
    {
      titulo: 'Miles Care Privilege',
      precioUno: '249',
      precioDos: '3,000',
      precioTres: '2,000',
      precioCuatro: '200',
      precioCinco: '2,000,000',
      precioSeis: '3,000'
    }
  ];
  video2
  urlsVideosFirstCitizens;

  urlTyC;
  
  constructor(
    private dataStore: DataStoreService,
    private store: StoreServiceLibrary,
    private translate: TranslateService,
    private el: ElementRef,
    private initService: InitService,
    ) { }

  ngOnInit() {
    this.initService.isInitializedBrand.subscribe(isInitialized => {
      if (isInitialized) {
        this.loadUrl();
        this.changeLanguaje();
      }
    });
    if (this.brand === 'firstcitizens') {
      this.video2 = true
      if (this.video2 == true) {
        this.urlsVideosFirstCitizens = environment.brands[this.dataStore.brand].videosLanding.sectionFour;
        this.urlsVideos = this.urlsVideosFirstCitizens;
        this.iphoneTops = `./assets/images/myrewards-miles/es/iphone-tops.png`;
        this.objToLoadVideo.one = true;

      }
    }
  }

  loadUrl() {
    const lang = this.store.getItemFromStorage('lang') || 'en';
    let urlTerms = this.store.getJustOneUniversalPropertie(keysUniversal.web_url_terms_and_conditions_en);
    if (lang) {
      if (lang == 'es') {
        urlTerms = this.store.getJustOneUniversalPropertie(keysUniversal.web_url_terms_and_conditions_es);
      }
    }
    this.urlTyC = urlTerms;
  }

  changeLanguaje() {
    this.translate.onLangChange.subscribe((data) => {
      if (data.lang === 'es') {
        this.iphoneTops = `./assets/images/${this.dataStore.brand}-miles/es/iphone-tops.png`;
        this.urlsVideos = environment.brands[this.dataStore.brand].videosLandingES.sectionFour;
      } else {
        this.iphoneTops = `./assets/images/${this.dataStore.brand}-miles/iphone-tops.png`;
        this.urlsVideos = environment.brands[this.dataStore.brand].videosLandingEN.sectionFour;
      }
      this.loadUrl();
    });
  }



  openSection(number: any) {
    if (number == 1) {
      const tag = this.el.nativeElement.querySelector('.benefits-voyage');
      const tag2 = this.el.nativeElement.querySelector('.bl-tablas');
      const tagBtn = this.el.nativeElement.querySelector('.bl-left .button');
      const tagBtn2 = this.el.nativeElement.querySelector('.bl-right .button');
      tag.classList.toggle('open');
      tag2.classList.remove('open');
      tagBtn.classList.toggle('active');
      tagBtn2.classList.remove('active');
    } else if (number == 2) {
      const tag = this.el.nativeElement.querySelector('.benefits-voyage');
      const tag2 = this.el.nativeElement.querySelector('.bl-tablas');
      const tagBtn = this.el.nativeElement.querySelector('.bl-left .button');
      const tagBtn2 = this.el.nativeElement.querySelector('.bl-right .button');
      tag2.classList.toggle('open');
      tag.classList.remove('open');
      tagBtn.classList.remove('active');
      tagBtn2.classList.toggle('active');
    } else {
      const tag = this.el.nativeElement.querySelector('.benefits-voyage');
      const tagBtn = this.el.nativeElement.querySelector('.button');
      tag.classList.toggle('open');
      tagBtn.classList.toggle('active');
    }

  }



  closeSection(number: any) {
    if (number == 1) {
      const tag = this.el.nativeElement.querySelector('.benefits-voyage');
      const tagBtn = this.el.nativeElement.querySelector('.bl-left .button');
      tag.classList.remove('open');
      tagBtn.classList.remove('active');
    } else if (number == 2) {
      let tag2 = this.el.nativeElement.querySelector(".bl-tablas");
      let tagBtn2 = this.el.nativeElement.querySelector(".bl-right .button");
      tag2.classList.remove('open');
      tagBtn2.classList.remove('active');
    } else {
      const tag = this.el.nativeElement.querySelector('.benefits-voyage');
      const tagBtn = this.el.nativeElement.querySelector('.button');
      tag.classList.remove('open');
      tagBtn.classList.remove('active');
    }
  }

}
