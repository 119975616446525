<div [ngClass]="brand + ' ejemplo'">
    <div class="popUpGeneralFontColor">
        <mat-accordion>
            <mat-expansion-panel  class="mb-3" *ngFor="let benefitGroup of benefit.typesAncestries">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        {{benefitGroup.name | titlecase }}
                    </mat-panel-title>
                </mat-expansion-panel-header>

                <div *ngFor="let benefit of benefitGroup.typeChildren">
                    <div class="benefitRow" *ngFor="let experience of benefit.experiences">
                        <div class="benefitDetails">
                            <div class="name-description">
                                <h6 class="tituloBenefits">{{experience.name}}</h6>
                                <span class="benefitDescriptions">{{experience.description}}</span>
                            </div>
                            <span class="benefitDescriptions limit">{{experience.config}}</span>
                        </div>
                    </div>
                </div>
            </mat-expansion-panel>
        </mat-accordion>
    </div>
</div>