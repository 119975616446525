<section id="help" class="help-center" *ngIf="showComponent">
    <div class="container">
        <div class="title">
            <h2>{{config?.title[this.translate.currentLang]}}</h2>
            <p>{{config?.description[this.translate.currentLang]}}</p>
        </div>
        <div class="row">
            <div *ngFor="let helpItem of config['videos'][this.translate.currentLang]" class="item"
                (click)="openModal(helpItem?.url)">
                <div class="image">
                    <img [src]="helpItem?.img | srcSanitizer" alt="">
                </div>
                <div [innerHTML]="helpItem.description"></div>
            </div>
        </div>
    </div>
</section>

<div class="bg" [ngClass]="{'open': open}">
    <div class="video">
        <!-- <span class="icon-play" (click)="playVideo()" *ngIf="!play"></span> -->
        <span class="icon-close" (click)="closeModal()"></span>
        <video class="elementVideo" [src]="video | srcSanitizer" controls #elementVideo poster="./assets/images/myrewards-miles/poster-help.png"></video>
    </div>

</div>