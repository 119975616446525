<!-- <ng-container *ngIf="section"> -->
<ng-container>
    <div class="container promociones">
        <div class="cover-container" *ngIf="brand !== 'credomatic'">
            <div class="title"><strong>{{ 'PROMOCIONES.destinations' | translate }}</strong></div>
            <div class="description">{{ 'PROMOCIONES.enjoy_flights_hotels' | translate }}</div>
        </div>

        
        <!-- <div *ngFor="let row of section.rows" class="row product-container"> -->
        <div class="row product-container">
            <div class="product">
                <div class="line"></div>
                <div class="text">
                    <strong>{{ 'EProduct.flights' | translate }}</strong>
                </div>
                <div class="line"></div>
            </div>
            <swiper class="slider-promociones" [config]="swiperConfig">
                <div class="img-container" *ngFor="let promo of flightPromo" [style.background-image]="'url(' + promo.src + ')'" (click)="search(EProduct.FLIGHTS, promo.search); $event.stopPropagation()">
                    <div class="promo-container">
                        <i></i>
                        <h3 class="destino">{{ promo.destino }}</h3>
                        <p class="detalle">
                            {{ 'PROMOCIONES.PROMOCION.product' | translate:{ product: ('EProduct.flights' | translate) } }}
                        </p>
                        <p class="precio">{{ promo.precio }}</p>
                        <p class="currency">{{ promo.currency }}</p>
                    </div>
                </div>
            </swiper>
        </div>


        <div class="row product-container">
            <div class="product">
                <div class="line"></div>
                <div class="text">
                    <strong>{{ 'EProduct.hotels' | translate }}</strong>
                </div>
                <div class="line"></div>
            </div>
            <swiper class="slider-promociones" [config]="swiperConfig">
                <div class="img-container" *ngFor="let promo of hotelPromo" [style.background-image]="'url(' + promo.src + ')'" (click)="search(EProduct.HOTELS, promo.search); $event.stopPropagation()">
                    <div class="promo-container">
                        <i></i>
                        <h3 class="destino">{{ promo.destino }}</h3>
                        <p class="detalle">
                            {{ 'PROMOCIONES.PROMOCION.product' | translate:{ product: ('EProduct.hotels' | translate) } }}
                        </p>
                        <p class="precio">{{ promo.precio }}</p>
                        <p class="currency">{{ promo.currency }}</p>
                    </div>
                </div>
            </swiper>
        </div>


    </div>
</ng-container>