import { FlightSummaryComponent } from './../flight-summary.component';
import { Flight } from '../../../../models/flights/flight.model';
import { Component, OnInit, Input } from '@angular/core';
import moment from 'moment';
import { LanguageService } from 'src/app/component/language/language.service';
import { TranslateService } from '@ngx-translate/core';
import { formatDate } from '@angular/common';
import { locale } from 'moment';

@Component({
  selector: 'app-flight-segment',
  templateUrl: './flight-segment.component.html',
  styleUrls: ['./flight-segment.component.scss']
})
export class FlightSegmentComponent implements OnInit {

  translations = [];

  @Input() flight: Flight;
  @Input() vuelta = false;

  collapsed = true;

  translationDate = [];
  translationFlight = [];
  translationDataService = [];

  get translationsDate(): string {
    return this.translations['DATE'] || [];
  }

  constructor(
    private flightSummaryComponent: FlightSummaryComponent,
    private languageService: LanguageService,
    private translateService: TranslateService
  ) { }

  get stops() {
    switch (this.flight.segments.length - 1) {
      case 0:
        return this.translationDataService['stops_direct'];
      case 1:
        return '1' + this.translationDataService['stops_scale'];
      case 2:
        return '2' + this.translationDataService['stops_scales'];
      default:
        return '+ 2' + this.translationDataService['stops_scales'];
    }
  }

  get baggage() {
    return this.flightSummaryComponent.getBaggage;
  }

  ngOnInit() {
    this.translationDate = this.flightSummaryComponent.translationDate;
    this.translationFlight = this.flightSummaryComponent.translationsFlight;
    this.translationDataService = this.flightSummaryComponent.translationDataService;

    this.languageService.systemLanguajeChange.subscribe((language) => {
      if (language) {
        this.getTranslations();
      }
    });
  }

  getTranslations() {
    this.translateService.get([
      'DATE'
    ]).subscribe((trans) => {
      this.translations = trans;
    });
  }

  SegmentDepartureDate(departureDate) {
    return formatDate(
      departureDate,
      this.translationsDate['format_weekday'],
      locale(this.languageService.systemLanguajeChange.value)
    ).toString();
  }
}
