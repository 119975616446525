/**
 * servicio responsable de conocer los parámetros que
 * se completaron para utilizar el buscador
 */
import { Injectable } from '@angular/core';
import { LanguageService } from 'src/app/component/language/language.service';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';
import { SearchedHotel } from './models/searched-hotel.model';
import { SearchedFlight } from './models/searched-flight.model';
import { SearchedCar } from './models/searched-car.model';
import { SearchedBenefit } from './models/searched-benefit.model';
import { SearchedActivity } from './models/searched-activity.model';

@Injectable({
  providedIn: 'root'
})
export class SearchedService {

  private readonly TAG = 'ISearched';

  /**
   * traducciones
   */
  translations$ = new BehaviorSubject<any>([]);

  constructor(
    private languageService: LanguageService,
    private translateService: TranslateService
  ) {
    this.languageService.systemLanguajeChange.subscribe((language) => {
      if (language) {
        this.translateService.get([
          this.TAG,
          'DATE'
        ]).subscribe((trans) => {
          this.translations$.next(trans);
        });
      }
    });
  }

  /**
   * Retorna un modelo con los datos que fueron buscados en el search
   * @param ISearched type del modelo que se buscaron datos
   */
  getModel<T extends SearchedFlight | SearchedHotel | SearchedCar | SearchedActivity | SearchedBenefit>(
    ISearched: { new(...args: any[]): T }
  ): T {
    // retorna el SearchedModel que recive por parámetro
    return new ISearched(
      JSON.parse(sessionStorage.getItem(this.TAG) || '{}'),
      this.translations$,
      this.languageService.systemLanguajeChange
    );
  }

  /**
   * modelo buscado
   */
  getSearchedFlight(): SearchedFlight {
    return new SearchedFlight(
      JSON.parse(sessionStorage.getItem(this.TAG) || '{}'),
      this.translations$,
      this.languageService.systemLanguajeChange
    );
  }

  /**
   * modelo buscado
   */
  getSearchedHotel(): SearchedHotel {
    return new SearchedHotel(
      JSON.parse(sessionStorage.getItem(this.TAG) || '{}'),
      this.translations$,
      this.languageService.systemLanguajeChange
    );
  }

  /**
   * modelo buscado
   */
  getSearchedCar(): SearchedCar {
    return new SearchedCar(
      JSON.parse(sessionStorage.getItem(this.TAG) || '{}'),
      this.translations$,
      this.languageService.systemLanguajeChange
    );
  }

  /**
   * modelo buscado
   */
  getSearchedActivity(): SearchedActivity {
    return new SearchedActivity(
      JSON.parse(sessionStorage.getItem(this.TAG) || '{}'),
      this.translations$,
      this.languageService.systemLanguajeChange
    );
  }

  /**
   * modelo buscado
   */
  getSearchedBenefit(): SearchedBenefit {
    return new SearchedBenefit(
      JSON.parse(sessionStorage.getItem(this.TAG) || '{}'),
      this.translations$,
      this.languageService.systemLanguajeChange
    );
  }

  /**
   * guardar los datos que fueron buscados usando el componente "Search"
   * @param v es un valor de modelo entre los posibles buscados
   */
  setModel(v: SearchedFlight | SearchedHotel | SearchedCar | SearchedActivity | SearchedBenefit) {
    sessionStorage.setItem(this.TAG, JSON.stringify(v));
  }
}
