<div class="bl-close" (click)="closeModal()"></div>

<div class="component-benefits">
  <div class="header-container" id="header-container">
    <div class="logo"></div>
    <h2 class="subtitle"></h2>
    <div class="producto">
      <h3 class="title">{{ benefitsGroup['productName'] }}</h3>
      <h2 class="subtitle">
        {{ benefitsGroup['productCurrency'] }}{{ benefitsGroup['productRate'] | currency:currencyUniversal.iso:'symbol':'1.0-2':this.currencyUniversal.locale }}&nbsp;{{ benefitsGroup['productCode'] }}
      </h2>
    </div>
  </div>
  <div class="container">
    <div id="benefitTitle" *ngFor="let item of benefitsGroup['benefitGroups']; let h = index;" [attr.data-index]="i">

      <div class="collapsible" [ngClass]="{'collapsible': true}" [id]="h+'collapsible'" *ngIf="item['benefits'].length !== 0">
        <span (click)="activateDeployItem(h)">{{ item['groupName'] }}</span><i class="arrow"
          (click)="activateDeployItem(h)">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22">
            <path class="arrow-color"
              d="M11 17.3c-.4 0-.8-.2-1.1-.5L.4 7.4c-.6-.6-.6-1.6 0-2.2.6-.6 1.6-.6 2.2 0l8.4 8.4 8.4-8.4c.6-.6 1.6-.6 2.2 0 .6.6.6 1.6 0 2.2l-9.5 9.5c-.3.2-.7.4-1.1.4z">
            </path>
          </svg></i></div>
      <div class="content" [id]="h+'item'" *ngIf="item['benefits'].length !== 0">
        <div class="item-container" *ngFor="let itemBenfits of item['benefits']">
          <div class="titleAndDesc">
            <strong>{{ itemBenfits['benefitName'] }}</strong>{{ itemBenfits['benefitDescription'] }}
          </div>
          <div class="limit ">{{ itemBenfits['limit'] }}</div>
        </div>
      </div>
    </div>

    <div class="t-and-c">
      <a id="terms" (click)="showTermsAndConditionsDialog()"  innerHTML="{{'LANDING.'+brand+'.CERTIFICATES_USER.see_t_y_c' | translate}}">See Terms and Conditions.</a>
    </div>
  </div>
  <div class="background"></div>
  <div class="element-1"></div>
  <div class="element-2"></div>

</div>
