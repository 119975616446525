<div class="slider-container">
  <div [class]="'result_detail_taxes ' + dataStore.brand">
    <div class="result_text">
      <h4>Total:</h4>
      <h3>
        <span class="millas"> {{ pointsAmount | milesFormat }} <span
            innerHTML="{{ 'SLIDER.miles' | translate }}">Millas</span></span>
      </h3>
      <span class="suma">+</span>
      <h3 class="dinero">
        <span>{{moneyCurrency}} {{ getCurrencySymbol(moneyCurrency,'narrow')}} {{ moneyAmount | milesFormat}}</span> <span></span>
      </h3>
    </div>

  </div>
  <div class="text-center" *ngIf="enabled">
    <div class="loader-chat" *ngIf="!loaded" [ngClass]="dataStore.brand">
      <span class="texto-loader"
        innerHTML="{{ 'SLIDER.payment_experience' | translate }}">
        Creando tu<br> <strong> experiencia de pago...</strong>
      </span>
    </div>
    <iframe [style.display]='!loaded ? "none" : "block"' class="iframeClass"
      [src]="iframeURL | srcSanitizer" frameborder="0" #onePocketIframe
      id="iframe_onepocket_slider" allow="clipboard-read; clipboard-write"></iframe>
  </div>
  <div class="text-center not-enabled" *ngIf="!enabled">
    <img src='../../../assets/images/slider-blur.png' loading="lazy" class="margin-top">
  </div>
</div>