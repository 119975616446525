import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray, FormControl } from '@angular/forms';
import { BuyerComponent } from '../buyer.component';
import { LanguageService } from 'src/app/component/language/language.service';
import { TranslateService } from '@ngx-translate/core';

export interface Iguests {
  id: number;
  age: number;
}

@Component({
  selector: 'app-guests',
  templateUrl: './guests.component.html',
  styleUrls: ['./guests.component.scss']
})
export class GuestsComponent implements OnInit {


  @ViewChild('guestCmp') guestCmp;
  ages = [];
  maxGuests = 8;
  lastGuest = -1;
  lastRoom = 0;
  paxes: Iguests[];
  rooms = [];
  qAdultos: number;
  qMenores = 0;
  qTotalGuests = 0;
  validatorH: boolean;
  qPax = 0;

  menorText: string;
  textPax: string;
  textRom: string;
  roomsForm: FormGroup;

  translationTextMinorsSingular: string;
  translationTextMinorsPlural: string;
  translationTextAdultsSingular: string;
  translationTextAdultsPlural: string;
  translationTextRoomsSingular: string;
  translationTextRoomsPlural: string;
  translationTextGuestsSingular: string;
  translationTextGuestsPlural: string;

  buyerForm: FormGroup;

  get roomsArray() {
    return <FormArray>this.buyerForm.get('rooms');
  }

  guestsArray(control: FormControl) {
    return <FormArray>control.get('guests');
  }

  constructor(
    private fb: FormBuilder,
    private buyer: BuyerComponent,
    private languageService: LanguageService,
    private translateService: TranslateService
  ) {
    this.languageService.systemLanguajeChange.subscribe((languaje) => {
      if (languaje) {
        this.getTranslations();
      }
    });
  }

  ngOnInit() {
    // Seteo de valores de edad para niños
    for (let i = 1; i <= 17; i++) {
      this.ages.push(i);
    }
    this.buyerForm = this.createFormBuyerGroup()
  }

  createFormBuyerGroup() {
    return this.fb.group({
      rooms: this.fb.array([this.createRooms()])
    });
  }

  // METODOS PARA HABITACIONES
  // Crear habitacion.
  createRooms() {
    // Por defecto por cada habitaion nueva se carga 1 adulto
    // Contabilizador de cantidad total de huespedes
    this.qTotalGuests++;
    return this.fb.group({
      guests: this.fb.array([this.createGuests()])

    });
  }

  // Nueva habitacion
  addNextRoom() {
    // Condicional para que no se puedan crear mas habitaciones (tomando en cuenta la cantidad maxima de huespedes establecida)
    if (this.qTotalGuests < this.maxGuests) {
      // Seteo de cantidad de menores en 0 para la nueva habitacion
      this.qMenores = 0;
      // Variable de control para posible eliminación de habitacion
      this.lastRoom++;
      // Adhecion de nueva habitacion al array de controles del reactiveForm
      (this.buyerForm.get('rooms') as FormArray).push(this.createRooms());
    }
  }

  // Eliminacion de ultima habitacion creada
  removeLastRoom() {
    // Condicional para por lo menos dejar una habitacion con un adulto seteada
    if (this.lastRoom > 0) {
      (this.buyerForm.get('rooms') as FormArray).removeAt(this.lastRoom);
      // Variable de control para posible eliminación de habitacion
      this.lastRoom--;
      // Modificacion del contabilizador de cantidad total de huespedes
      this.qTotalGuests--;
    }
  }

  // funcion para ingresar nuevo niño.
  createGuests() {
    this.qPax++;
    return this.fb.group({
      id: this.qPax,
      age: ['', [Validators.required]],
    });
  }

  addNextGuest(roomId) {
    if (this.qTotalGuests < this.maxGuests) {
      this.qTotalGuests++;
      this.qMenores++;
      this.lastGuest++;
      (this.buyerForm.get('rooms').get([roomId]).get(['guests']) as FormArray).push(this.createGuests());
    }
  }

  removeLastGuest(roomId) {
    if (this.lastGuest >= 0) {
      this.qTotalGuests--;
      this.qMenores--;
      (this.buyerForm.get('rooms').get([roomId]).get(['guests']) as FormArray).removeAt(this.lastGuest);
      this.lastGuest--;
    }
  }

  mash(hab) {
    if (this.qTotalGuests < 8) {
      this.qTotalGuests++;
      const preValue = (this.buyerForm.controls['rooms'].get([hab]) as FormArray).length;
      this.buyerForm.get('rooms').get([hab]).get[preValue].setValue(preValue + 1);
    }
  }

  menosh(hab) {
    const preValue = (this.buyerForm.controls['rooms'].get([hab]) as FormArray).length;
    if (preValue > 1) {
      this.buyerForm.get('rooms').get([hab]).get[preValue].setValue(preValue + 1);
      this.qTotalGuests--;
    }
  }

  qMenoresD(roomId) {
    let menorText;
    const qMenoresA = (this.buyerForm.get('rooms').get([roomId]).get(['guests']) as FormArray).length;
    if (qMenoresA !== 0) {
      if (qMenoresA < 2) {
        menorText = ', ' + qMenoresA + ' Menor';
      } else {
        menorText = ', ' + qMenoresA + ' Menores';
      }
      return menorText;
    }
    return 'no';
  }

  qAdultosText(q) {
    if (q < 2) {
      return (q + ' Adulto ');
    } else {
      return (q + ' Adultos ');
    }
  }

  submitG() {
    this.buyer.onApply(this.buyerForm.value);
    /* if (this.buyerForm.valid) {
      /!* this.guestDataToSearch.searchFormInvalid = false;*!/
       this.rooms = [];
       let qPax = 1;
       let qRooms = 0;
       const arrayRoom = this.buyerForm.get('rooms').value;
       for (let i = 0; i < arrayRoom.length; i++) {
         this.paxes = [];
         for (let j = 0; j < arrayRoom[i].qAdultos; j++) {
           this.paxes.push({id: qPax, age: 21});
           qPax++;
         }
         for (let h = 0; h < arrayRoom[i].guests.length; h++) {
           this.paxes.push({id: qPax, age: +arrayRoom[i].guests[h].age});
           qPax++;
         }
         qRooms++;
         this.rooms.push({paxes: this.paxes});
       }
      /!* this.guestDataToSearch.guests = this.rooms;
       this.guestDataToSearch.showGuestForm = true;
       this.guestDataToSearch.showGuestFormControl = false;*!/
       if (qRooms < 2) {
         this.textRom = qRooms + ' Habitación ';
       } else {
         this.textRom = qRooms + ' Habitaciones ';
       }
       if (qPax <= 2) {
         this.textPax = (qPax - 1) + ' Huésped';
       } else {
         this.textPax = (qPax - 1) + ' Huéspedes';
       }
      /!* this.guestDataToSearch.placeholderGuests = this.textRom + this.textPax;*!/
      /!* this.guestDataToSearch.hotelSearchForm.controls['passenger'].setValue(this.textRom + this.textPax);*!/
     } else {
       this.validatorH = true;
     }*/
  }

  private getTranslations() {
    this.translateService.get([
      'GUESTS.translation_text_minors_singular',
      'GUESTS.translation_text_adults_singular',
      'GUESTS.translation_text_rooms_singular',
      'GUESTS.translation_text_guests_singular',
      'GUESTS.translation_text_minors_plural',
      'GUESTS.translation_text_adults_plural',
      'GUESTS.translation_text_rooms_plural',
      'GUESTS.translation_text_guests_plural'
    ]).subscribe((translations) => {
      this.translationTextMinorsSingular = translations['GUESTS.translation_text_minors_singular'];
      this.translationTextAdultsSingular = translations['GUESTS.translation_text_adults_singular'];
      this.translationTextRoomsSingular = translations['GUESTS.translation_text_rooms_singular'];
      this.translationTextGuestsSingular = translations['GUESTS.translation_text_guests_singular'];
      this.translationTextMinorsPlural = translations['GUESTS.translation_text_minors_plural'];
      this.translationTextAdultsPlural = translations['GUESTS.translation_text_adults_plural'];
      this.translationTextRoomsPlural = translations['GUESTS.translation_text_rooms_plural'];
      this.translationTextGuestsPlural = translations['GUESTS.translation_text_guests_plural'];
    });
  }
}
