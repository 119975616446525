<footer>
    <div class="container">
        <div class="foot">
            <img src="./assets/images/{{brand}}-miles/logo-footer.png" alt="">
            <span class="learn-more">
                <span>
                    <div class="icon-miles-f"></div>
                    {{'LANDING.'+brand+'.FOOTER.questions' | translate}}
                    <a innerHTML="{{'LANDING.'+brand+'.FOOTER.click_here1' | translate}}" 
                        (click)="openUrl('https://www.wearenovae.com/')" target="_blank">{{'LANDING.'+brand+'.FOOTER.click_here1' |
                        translate}}</a>
                        <span innerHTML="{{'LANDING.'+brand+'.FOOTER.learn_more' | translate}}">{{'LANDING.'+brand+'.FOOTER.learn_more' | translate}}</span>


                </span>
                
            </span>

            <span>
                {{'LANDING.'+brand+'.FOOTER.more_info' | translate}}
                <a class="under" (click)="loadUrl()"><strong>{{'LANDING.'+brand+'.FOOTER.click_here'
                    | translate}}</strong>
                </a>
            </span>
            <span>
                <a href="">
                    {{'LANDING.'+brand+'.FOOTER.bank' | translate}}
                </a>
            </span>
            <span id="clickHereCountry" innerHTML="{{'LANDING.'+brand+'.FOOTER.terms'| translate: { urlProgram: urlProgram } }}">
                "{{'LANDING.'+brand+'.FOOTER.terms'| translate: { urlProgram: urlProgram } }}"
            </span>
        </div>
        <span innerHTML="{{'LANDING.'+brand+'.FOOTER.all_brand' | translate}}"></span>
    </div>
</footer>