<!-- IFRAME ADDCREDITCARD POINTS OR SELECTC CREDITCARDPOINTS -->
<div class="slider-container" [style.display]='!loaded ? "none" : "block"'>

  <div class="text-center">
    <iframe class="iframeClass" [src]="iframeURL | srcSanitizer" frameborder="0" #onePocketIframe
      id="iframe_onepocket_points_credomatic" allow="clipboard-read; clipboard-write"
      [ngStyle]="{height: iframeScrollHeight, width: iframeScrollWidth}"></iframe>
  </div>

  <div class="add-card-next" *ngIf="loadButtonContinue">
    <span>
      {{ 'LANDING.'+brand+'.ONEPOCKETPAY.add_card' | translate }}
    </span>
    <button mat-button (click)="close()">Continuar</button>
  </div>

</div>
