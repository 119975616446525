<div class="alert">
  <button class="close" (click)="close()" *ngIf="isShowIconClose">
    <i class="icon-close"></i>
  </button>
  <span class="cara-error"></span>
  <h1 mat-dialog-title class="title">
    {{tittle|translate}}
  </h1>
  <p>
    {{error|translate}}
  </p>


  <!-- Merge with development *** 19 Feb 2021
    <button class="what" (click)="goToExWhatsapp()" *ngIf="validateHaveWhatsApp"> <img
      src="../../../../assets/images/whatsapp-logo.png" alt="">
    {{'LOGIN.contactWhatsApp'|translate}}</button> 
  -->

  <!-- Init Merge with development *** 19 Feb 2021 -->
  <button class="what" (click)="goToExWhatsapp()" *ngIf="showButtonWhatsApp"> <img
      src="../../../../assets/images/whatsapp-logo.png" alt="" *ngIf="showButtonWhatsApp">
    {{'LOGIN.contactWhatsApp'|translate}}</button>

  <button class="what" (click)="goToLoginLM()"
    *ngIf="validateHaveGoToLogin">{{ 'ALERT.ERROR_BOOKING.goToLifeMiles' | translate }}</button>
</div>
