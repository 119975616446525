import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {ModalDismissReasons, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ModalOptions} from './modal-options.model';


@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})

export class ModalComponent implements OnInit, OnChanges {

  @ViewChild('content') content;
  @Output() output  = new EventEmitter();

  @Input() openModal = false;
  @Input() options: ModalOptions;

  closeResult: string;
  mainImgUrl: string;

  constructor(
    private modalService: NgbModal
  ) { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    this.mainImgUrl = '';
    if (this.openModal) {
      if ( this.options.type === null || this.options.type === 'error') {
        this.mainImgUrl = 'https://www.freeiconspng.com/uploads/error-icon-4.png';
      } else {
        this.mainImgUrl = 'https://cdn3.iconfinder.com/data/icons/flat-actions-icons-9/792/Tick_Mark_Dark-512.png';
      }
      this.open(this.content);
    }
  }
  open(content) {
    this.modalService.open(content, this.options.modalOptions);
    /*.result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });*/
  }
  successButton(ev) {
    if (this.options.redirectSuccessUrl !== undefined && this.options.redirectSuccessUrl !== null) {
      this.output.emit('redirectSuccessUrl');
    } else {
      this.output.emit(true);
    }
  }
  cancelButton(ev) {
    if (this.options.redirectCancelUrl !== undefined && this.options.redirectCancelUrl !== null) {
      this.output.emit('redirectCancelUrl');
    } else {
      this.output.emit(false);
    }
  }

  private getDismissReason(reason: any, ok = null) {
    if (reason === ModalDismissReasons.ESC) {
      return false;
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return false;
    } else {
      return  `with: ${reason}`;
    }
    if (ok !== null) {
      return true;
    }
  }

}
