<div class="segment" *ngIf="init">
    <div class="resume" [class.show]="show">
        <div class="row" (click)="show = !show">
            <div class="airline" id="logoImage">
                <img src="/assets/airlines/{{flight.company.iata}}.png" alt="logo" onerror="this.src='/assets/airlines/default.png';">
            </div>
            <div class="origin-destination">
                <div class="" id="origin">
                    <p class="title">{{flight.origin.cityName}}</p>
                </div>
                <div class="" id="destiny">
                    <p class="title">{{ flight.destination.cityName}}</p>
                    <small class="airline-name">{{ flight.company.longName }}</small>
                </div>
            </div>
            <div class="hours-cont">
                <div class="item hours">
                    <p>{{ flight.departureHour }} - {{ flight.arrivalHour }}</p>
                </div>
                <div class="item stops">
                    <p>{{ stops }}</p>
                </div>
                <div class="item">
                    <p>{{ flight.flightDuration }}</p>
                </div>
            </div>
            <small class="detail-button" [class.show]="show">{{ 'LAYOUT_ONE.details' | translate }}</small>
        </div>
    </div>
    <div id="detail-container" class="detail-container">
        <app-detail [flight]="flight" [index]="index" [show]="show">
        </app-detail>
    </div>
</div>