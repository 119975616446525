import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'numberToArr'
})
export class NumberToArrPipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    value = (!value || value === NaN) ? 0 : value;
    value = parseInt(value);
    return new Array(value);
  }

}
