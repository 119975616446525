import { Component, OnInit, Input, AfterViewInit } from '@angular/core';
import { DataStoreService } from '../../../services/data-store.service';
import { StoreServiceLibrary } from '@wearenovae/novae-core-services';
import { environment } from '../../../../environments/environment';
import { DataService } from '../../../services/data.service';
import { ActionEventService } from 'src/app/services/action-event.service';
import { keysUniversal } from 'src/app/resources/keys.resources';


declare var $: any;

@Component({
  selector: 'app-footer-landing-scotia',
  templateUrl: './footer-landing-scotia.component.html',
  styleUrls: ['./footer-landing-scotia.component.scss']
})
export class FooterLandingScotiaComponent implements OnInit, AfterViewInit {

  @Input() brand: string;

  urlData = '';
  dataDecode: any;
  language: any;
  urlProgram = '';

  constructor(private dataStore: DataStoreService,
    private data: DataService,
    private actionEventService: ActionEventService,
    private store: StoreServiceLibrary) { }

  ngOnInit() {
    this.actionEventService.countryCodeChange.subscribe((countryCodeChange) => {
      if (countryCodeChange && countryCodeChange.code) {
        this.getUrlProgramCredomatic(countryCodeChange)
      }
    });

  }

  ngAfterViewInit() {
  }

  loadUrl() {
    this.data.vaidateOnePocketStay().subscribe((observer) => {
      if (observer === 2) {
        this.continueFlow();
      } else if (observer === 1) {
        this.data.openModalQuestion().subscribe((isLoad) => {
          if (isLoad) {
            this.continueFlow();
          }
        });
      }
    });

  }

  continueFlow() {
    const lang = this.store.getItemFromStorage('lang') || 'en';
    // const urlTerms = environment.brands[this.dataStore.brand].terminos_y_condiciones[lang.toUpperCase()];
    let urlTerms = this.store.getJustOneUniversalPropertie(keysUniversal.web_url_terms_and_conditions_en);

    if (lang) {
      if (lang == 'es') {
        urlTerms = this.store.getJustOneUniversalPropertie(keysUniversal.web_url_terms_and_conditions_es);
      }
    }

    if (!this.dataStore.isAlleMobile) {
      this.urlData = urlTerms;
    } else {
      this.urlData = `${urlTerms}`;
    }
    window.open(this.urlData, '_blank');
  }

  openUrl(url) {
    window.open(url, '_target');
  }

  getUrlProgramCredomatic(countryCodeSelected) {
    let countryCode = 'CRI';
    if (countryCodeSelected) {
      countryCode = countryCodeSelected.code;
    }
    const url = keysUniversal.GET_URL_PROGRAM;
    const PROGRAM_DATA = JSON.parse(this.store.getJustOneUniversalPropertie(url));
    const PROGRAM_DATA_BRAND = PROGRAM_DATA[this.dataStore.brand];
    const PROGRAM = PROGRAM_DATA_BRAND[countryCode];
    this.urlProgram = PROGRAM.url;
  }

}

