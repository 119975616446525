import { ResultsModule } from './container/results/results.module';
import { ActivityModule } from './container/activity-body/activity.module';
import { BrowserModule, ɵDomSharedStylesHost } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './models/material';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AaDatepickerModule } from 'ngx-animating-datepicker';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavComponent } from './component/nav/nav.component';
import { RegionService } from './services/region.service';
import { HotelService } from './services/hotel.service';
import { HotelModule } from './container/hotel-body/hotel.module';
import { SharedModule } from './container/shared/shared.module';
import { FlightListComponentComponent } from './component/flight-list-component/flight-list-component.component';
import { LandingAlloComponent } from './pages/landing-allo/landing-allo.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HomeComponent } from './pages/home/home.component';
import { HomeBodyComponent } from './container/home-body/home-body.component';
import { FooterComponent } from './component/footer/footer.component';
import { FooterScotiaComponent } from './component/footer-scotia/footer-scotia.component';
import { SearchComponent } from './pages/home/search/search.component';
import { SearchFlightComponent } from './pages/home/search/search-flight/search-flight.component';
import { SliderHomeComponent } from './pages/home/slider-home/slider-home.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { PasajerosComponent } from './pages/home/search/search-flight/pasajeros/pasajeros.component';
import { LoadingScreenComponent } from './component/loading-screen/loading-screen.component';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { LoadingScreenInterceptor } from './interceptor/loading-screen.interceptor';
import { LoginInterceptor } from './interceptor/login.interceptor';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { registerLocaleData, DecimalPipe, DatePipe } from '@angular/common';
import localeEs from '@angular/common/locales/es';
import localeEn from '@angular/common/locales/en';
import { SearchHotelComponent } from './pages/home/search/search-hotel/search-hotel.component';
import { GuestsComponent } from './pages/home/search/search-hotel/guests/guests.component';
import { MatInputModule } from '@angular/material/input';
import { CarsComponent } from './pages/home/search/cars/cars.component';
registerLocaleData(localeEs, 'es');
registerLocaleData(localeEn, 'en');
import { MatExpansionModule } from '@angular/material/expansion';
import { SearchCarsComponent } from './pages/home/search/search-cars/search-cars.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { LandingMyProfileComponent } from './pages/landing-my-profile/landing-my-profile.component';
import { RentaCarModule } from './container/renta-car-body/renta-car.module';
import { SwiperModule } from 'ngx-swiper-wrapper';
import { BuscadorPresentacionComponent } from './pages/home/search/buscador-presentacion/buscador-presentacion.component';
import { BenefitModule } from './container/benefit-card/benefit.module';
import { SearchBenefitsComponent } from './pages/home/search/search-benefits/search-benefits.component';
import { FiltersModule } from './container/shared/filters/filters.module';
import { environment } from '../environments/environment';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { MatButtonModule } from '@angular/material/button';
import { MatRippleModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { LanguajeModule } from './component/language/language.module';
import { SearchActivitiesComponent } from './pages/home/search/search-activities/search-activities.component';
import { InputAgesComponent } from './component/input-ages/input-ages.component';
import { PipesModule } from './pipes/pipes.module';
import { SearchResumeBarModule } from './component/search-resume-bar/search-resume-bar.module';
import { NoResultsComponent } from './container/flight-body/no-results/no-results.component';
import { TravellerFormModule } from './component/traveller-form/traveller-form.module';
import { PurchaseSummaryModule } from './component/purchase-summary/purchase-summary.module';
import { HamburgerModule } from './component/hamburger/hamburger.module';
import { HomeSearchComponent } from './component/home-search/home-search.component';
import { HomeSearchModule } from './component/home-search/home-search.module';
import { BuyerModule } from './component/home-search/buyer/buyer.module';
import { PromocionesModule } from './component/promociones/promociones.module';
import { RegistrationModule } from './pages/registration/registration.module';
import { AuthComponent } from './authentication/auth/auth.component';
import { AuthService } from '@wearenovae/novae-core-services';
import { AuthGuard } from './authentication/auth.guard';
import { RegistryConfirmationComponent } from './component/registry-confirmation/registry-confirmation.component';
import { LandingAboutComponent } from './pages/landing-about/landing-about.component';
import { SectionFirstComponent } from './component/component-about/section-first/section-first.component';
import { SectionSecondComponent } from './component/component-about/section-second/section-second.component';
import { SectionThirdComponent } from './component/component-about/section-third/section-third.component';
import { DownloadStoreComponent } from './component/component-about/download-store/download-store.component';
import { CalendarSearchDirective } from './directives/calendar-search.directive';
import { ServiceWorkerModule } from '@angular/service-worker';
import { LandingTravelComponent } from './pages/landing-travel/landing-travel/landing-travel.component';
import { LandingFcComponent } from './pages/landing-fc/landing-fc.component';
import { SectionOneComponent } from './pages/landing-fc/section-one/section-one.component';
import { SectionTwoComponent } from './pages/landing-fc/section-two/section-two.component';
import { SectionThreeComponent } from './pages/landing-fc/section-three/section-three.component';
import { SectionFourComponent } from './pages/landing-fc/section-four/section-four.component';
import { FooterFcComponent } from './pages/landing-fc/footer-fc/footer-fc.component';
import { HttpLoaderFactory } from './resources/WebpackTranslateLoader';
import { LandingMrComponent } from './pages/landing-mr/landing-mr.component';
import { SectionOneMRComponent } from './pages/landing-mr/section-one-mr/section-one-mr.component';
import { SectionTwoMRComponent } from './pages/landing-mr/section-two-mr/section-two-mr.component';
import { SectionThreeMRComponent } from './pages/landing-mr/section-three-mr/section-three-mr.component';
import { SectionFourMRComponent } from './pages/landing-mr/section-four-mr/section-four-mr.component';
import { FooterFcMRComponent } from './pages/landing-mr/footer-fc-mr/footer-fc-mr.component';
import { OpFromIframeComponent } from './component/op-from-iframe/op-from-iframe.component';
import { LandingScotiaComponent } from './pages/landing-scotia/landing-scotia.component';
import { FooterLandingScotiaComponent } from './pages/landing-scotia/footer-landing-scotia/footer-landing-scotia.component';
import { SectionOneScotiaComponent } from './pages/landing-scotia/section-one-scotia/section-one-scotia.component';
import { SectionTwoScotiaComponent } from './pages/landing-scotia/section-two-scotia/section-two-scotia.component';
import { SectionThreeScotiaComponent } from './pages/landing-scotia/section-three-scotia/section-three-scotia.component';
import { SectionFourScotiaComponent } from './pages/landing-scotia/section-four-scotia/section-four-scotia.component';
import { BrandedRulesBenefitsComponent } from './component/branded-rules-benefits/branded-rules-benefits.component';
import { InfoFixedRatesComponent } from './component/info-fixed-rates/info-fixed-rates.component';
import { FlightRedemptionChartComponent } from './pages/flight-redemption-chart/flight-redemption-chart.component';
import { FixedRatesComponent } from './pages/fixed-rates/fixed-rates.component';
import { FixedRatesTableComponent } from './component/fixed-rates-table/fixed-rates-table.component';
import { SearchFlightFixedRatesComponent } from './component/search-flight-fixed-rates/search-flight-fixed-rates.component';
import { FixedRatesIframeTravelComponent } from './component/fixed-rates-iframe-travel/fixed-rates-iframe-travel.component';
import { PasajerosFixedRatesComponent } from './component/search-flight-fixed-rates/pasajeros/pasajeros-fixed-rates.component';
import { TravelAppComponent } from './pages/travel-app/travel-app.component';
import { MyrewardsSalesdeckComponent } from './pages/myrewards-salesdeck/myrewards-salesdeck.component';
import { NavSalesDeckComponent } from './component/nav-sales-deck/nav-sales-deck.component';
import { TabsComponent } from './component/tabs/tabs.component';
import { SWIPER_CONFIG } from 'ngx-swiper-wrapper';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { NgxPageScrollCoreModule } from 'ngx-page-scroll-core';
import { NgxPageScrollModule } from 'ngx-page-scroll';
import { LandingLifeMilesComponent } from './pages/landing-lifemiles/landing-lifemiles.component';
import { CertificatesUserComponent } from './component/certificates-user/certificates-user.component';
import { CertificateLearnMoreComponent } from './component/certificate-learn-more/certificate-learn-more.component';
import { RedirectComponent } from './component/redirect/redirect.component';
import { NewsComponent } from './pages/news/news.component';
import { ErrorPageComponent } from './component/errors/error-page/error-page.component';
import { FooterAllComponent } from './component/footer-all/footer-all.component';
import { NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule } from 'ngx-google-analytics';
import { PassengersLandingComponent } from './component/passengers-landing/passengers-landing.component';
import { ResetPasswordComponent } from './pages/reset-password/reset-password.component';
import { ActivatePhisicalCardComponent } from './pages/activate-phisical-card/activate-phisical-card.component';
import { BenefitsUpperclubComponent } from './component/benefits-upperclub/benefits-upperclub.component';
import { LandingUpperclubComponent } from './pages/landing-upperclub/landing-upperclub.component';
import { LandingBacComponent } from './pages/landing-bac/landing-bac.component';
import { SectionOneBacComponent } from './pages/landing-bac/section-one-bac/section-one-bac.component';
import { SectionTwoBacComponent } from './pages/landing-bac/section-two-bac/section-two-bac.component';
import { SectionThreeBacComponent } from './pages/landing-bac/section-three-bac/section-three-bac.component';
import { SectionFourBacComponent } from './pages/landing-bac/section-four-bac/section-four-bac.component';
import { FooterLandingBacComponent } from './pages/landing-bac/footer-landing-bac/footer-landing-bac.component';
import { ModalLoadVideoTutorialComponent } from './component/modal-load-video-tutorial/modal-load-video-tutorial.component';
///import { BlackFridayComponent } from './component/black-friday/black-friday.component';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { OwlModule } from 'ngx-owl-carousel';
import { ContentfulService } from './services/contentful/contentful.service';
import { CtfSliderComponent } from './component/ctf-slider/ctf-slider.component';
import { PromocionesCtfComponent } from './component/promociones-ctf/promociones-ctf.component';
import { BenefitsComponent } from './component/benefits/benefits.component';
import { CouponPromotionComponent } from './component/coupon-promotion/coupon-promotion.component';
import { HelpCenterComponent } from './component/help-center/help-center.component';
import { MeetMyrewardsComponent } from './component/meet-myrewards/meet-myrewards.component';
import { LandingHelpComponent } from './pages/landing-help/landing-help.component';
import { AppNotFoundComponent } from './component/errors/app-not-found/app-not-found.component';
import { AskedQuestionsComponent } from './component/asked-questions/asked-questions.component';
import { OnepocketBenefitsComponent } from './pages/onepocket-benefits/onepocket-benefits.component';
import { ExpireMilesComponent } from './component/expire-miles/expire-miles.component';
import { LandingTwoComponent } from './pages/landing-two/landing-two.component';
import { CustomDomSharedStylesHost } from './shared_styles_host';


@NgModule({
  declarations: [
    AppComponent,
    NavComponent,
    FlightListComponentComponent,
    LandingAlloComponent,
    HomeComponent,
    HomeBodyComponent,
    FooterComponent,
    FooterScotiaComponent,
    SearchComponent,
    SearchFlightComponent,
    SearchFlightFixedRatesComponent,
    FixedRatesTableComponent,
    SliderHomeComponent,
    AuthComponent,
    PasajerosComponent,
    LoadingScreenComponent,
    SearchHotelComponent,
    GuestsComponent,
    CarsComponent,
    SearchCarsComponent,
    LandingMyProfileComponent,
    BuscadorPresentacionComponent,
    SearchBenefitsComponent,
    SearchActivitiesComponent,
    InputAgesComponent,
    NoResultsComponent,
    HomeSearchComponent,
    RegistryConfirmationComponent,
    LandingAboutComponent,
    LandingTwoComponent,
    SectionFirstComponent,
    SectionSecondComponent,
    SectionThirdComponent,
    DownloadStoreComponent,
    CalendarSearchDirective,
    LandingTravelComponent,
    FixedRatesComponent,
    LandingFcComponent,
    SectionOneComponent,
    SectionTwoComponent,
    SectionThreeComponent,
    SectionFourComponent,
    FooterFcComponent,
    LandingMrComponent,
    SectionOneMRComponent,
    SectionTwoMRComponent,
    SectionThreeMRComponent,
    SectionFourMRComponent,
    FooterFcMRComponent,
    OpFromIframeComponent,
    LandingScotiaComponent,
    FooterLandingScotiaComponent,
    SectionOneScotiaComponent,
    SectionTwoScotiaComponent,
    SectionThreeScotiaComponent,
    SectionFourScotiaComponent,
    BrandedRulesBenefitsComponent,
    InfoFixedRatesComponent,
    FlightRedemptionChartComponent,
    FixedRatesIframeTravelComponent,
    PasajerosFixedRatesComponent,
    TravelAppComponent,
    OnepocketBenefitsComponent,
    MyrewardsSalesdeckComponent,
    NavSalesDeckComponent,
    TabsComponent,
    NavSalesDeckComponent,
    LandingLifeMilesComponent,
    CertificatesUserComponent,
    RedirectComponent,
    NewsComponent,
    ErrorPageComponent,
    FooterAllComponent,
    PassengersLandingComponent,
    ResetPasswordComponent,
    ActivatePhisicalCardComponent,
    BenefitsUpperclubComponent,
    LandingUpperclubComponent,
    LandingBacComponent,
    SectionOneBacComponent,
    SectionTwoBacComponent,
    SectionThreeBacComponent,
    SectionFourBacComponent,
    FooterLandingBacComponent,
    ModalLoadVideoTutorialComponent,
    // se quita por seguridad
    ///BlackFridayComponent,
    CtfSliderComponent,
    PromocionesCtfComponent,
    BenefitsComponent,
    CouponPromotionComponent,
    HelpCenterComponent,
    MeetMyrewardsComponent,
    LandingHelpComponent,
    AppNotFoundComponent,
    AskedQuestionsComponent,
    ExpireMilesComponent
  ],
  imports: [
    SwiperModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FormsModule,
    AaDatepickerModule,
    HttpClientModule,
    HotelModule,
    ActivityModule,
    SharedModule,
    NgbModule,
    NgSelectModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    NgxDaterangepickerMd.forRoot(),
    ReactiveFormsModule.withConfig({ warnOnNgModelWithFormControl: 'never' }),
    MatExpansionModule,
    MatCheckboxModule,
    RentaCarModule,
    BenefitModule,
    FiltersModule,
    MaterialModule,
    MatInputModule,
    MatFormFieldModule,
    MatButtonModule,
    MatRippleModule,
    LanguajeModule,
    PipesModule,
    SearchResumeBarModule,
    TravellerFormModule,
    PurchaseSummaryModule,
    HamburgerModule,
    HomeSearchModule,
    BuyerModule,
    PromocionesModule,
    RegistrationModule,
    ResultsModule,
    CarouselModule,
    NgxPageScrollCoreModule,
    NgxPageScrollModule,
    OwlModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoadingScreenInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoginInterceptor,
      multi: true
    },
    RegionService,
    HotelService,
    AuthService,
    AuthGuard,
    ContentfulService,
    DecimalPipe,
    DatePipe,
    { provide: 'cspMetaSelector', useValue: 'meta[name="CSP-NONCE"]' },
    { provide: ɵDomSharedStylesHost, useClass: CustomDomSharedStylesHost}
  ],
  bootstrap: [AppComponent],
  exports: [
    InputAgesComponent
  ],
  entryComponents: [PassengersLandingComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }

