import { IRegister } from './model/register.model';
import { LoginService } from './login.service';
import { Component, OnInit, OnDestroy, AfterViewInit, Inject } from '@angular/core';
import { FormBuilder, Validators, AbstractControl, FormControl } from '@angular/forms';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { DataStoreService } from 'src/app/services/data-store.service';
import { LanguageService } from '../../../component/language/language.service';
import { RegistryLocationService } from 'src/app/services/registry-location.service';
import { DataService } from '../../../services/data.service';
import { IRegisterCard } from './model/card.model';
import { IPreRegisterStep1 } from './model/pre-register-step1.model';
import { IPreRegisterStep2 } from './model/pre-register-step2.model';
import { IPreRegisterStep3 } from './model/pre-register-step3.model';
import { CountryPhoneISO } from 'src/assets/countryPhoneIso';
import { environment } from '../../../../environments/environment';
import {
  StoreServiceLibrary,
  AuthService, AccountSumaryDto, GeneralUtilService,
  OnePocketService, PropertyDto, keys, AuthGetAccount2Request, SecurityObject,
  AuthGetAccount2Response, UserData, keysUniversal
} from '@wearenovae/novae-core-services';
import { SliderComponent } from '../../../component/slider/slider.component';
import { ActionEventService } from '../../../services/action-event.service';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { CookieService } from 'ng2-cookies';
import * as CryptoJS from 'crypto-js';
import { GenerateService } from '../../../services/generate.service';
import { TravellerFormService } from '../../../component/traveller-form/traveller-form.service';
import data from '../../../resources/screenAndEventsTracking.json';
import { typeApp } from '../../../../environments/environment';
import { AlertErrorComponent } from '../../../component/product-detail/alert-error/alert-error.component';
import { CredomaticCardsComponent } from '../../../component/credomatic-cards/credomatic-cards.component';
import { LoadingScreenService } from '../../../services/loading-screen.service';
import { GoogleAnalytics } from '../../../resources/GoogleAnalytics.resource';
import { InitService } from '../../../services/init.service';
import { MilesFormatPipe } from 'src/app/pipes/miles-format.pipe';
import { LpCtmr } from 'src/app/models/lp.model';
import { LpService } from 'src/app/services/lp/lp.service';



/**
 * estados posibles del proceso log in / sign up
 */
enum LoginState {
  CHOOSE_OPTION = 'CHOOSE_OPTION',
  LOG_IN = 'LOG_IN',
  SIGN_UP = 'SIGN_UP',
  ERROR_LOGIN = 'ERROR_LOGIN',
  LOG_IN_SUCCESSFUL = 'LOG_IN_SUCCESSFUL',
  SIGN_UP_SUCCESSFUL = 'SIGN_UP_SUCCESSFUL',
  SIGN_UP_ERROR = 'SIGN_UP_ERROR',
  SIGN_UP_PRE_REGISTERED_STEP_1 = 'SIGN_UP_PRE_REGISTERED_STEP_1',
  SIGN_UP_PRE_REGISTERED_STEP_2 = 'SIGN_UP_PRE_REGISTERED_STEP_2',
  SIGN_UP_PRE_REGISTERED_STEP_3 = 'SIGN_UP_PRE_REGISTERED_STEP_3',
  SIGN_UP_PRE_REGISTERED_STEP_4 = 'SIGN_UP_PRE_REGISTERED_STEP_4',
  SIGN_UP_PRE_REGISTER_SUCCESSFUL = 'SIGN_UP_PRE_REGISTER_SUCCESSFUL',
  SIGN_UP_PRE_REGISTER_SET_PASSWORD = 'SIGN_UP_PRE_REGISTER_SET_PASSWORD',
  SIGN_UP_PRE_REGISTER_ERROR = 'SIGN_UP_PRE_REGISTER_ERROR',
  SIGN_UP_PRE_REGISTERED_SUCCESSFUL = 'SIGN_UP_PRE_REGISTERED_SUCCESSFUL',
  PRE_REGISTERED_CASE_4 = 'PRE_REGISTERED_CASE_4',
  ACTIVATE_NO_NAME_AND_LNAME = 'ACTIVATE_NO_NAME_AND_LNAME',
  ACTIVATE_TERMS_AND_CONDITIONS = 'ACTIVATE_TERMS_AND_CONDITIONS',
  ACTIVATE_SEND_PASSWORD = 'ACTIVATE_SEND_PASSWORD',
  ACTIVATE_CARD_CONFETI = 'ACTIVATE_CARD_CONFETI',
  RECOVERY_PASS = 'RECOVERY_PASS',
  MUST_CHANGE_PASS = 'MUST_CHANGE_PASS',
  LOAD_ADD_CARD = 'LOAD_ADD_CARD'
}

@Component({
  selector: 'app-login',
  providers: [CookieService],
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, AfterViewInit, OnDestroy {

  useJwtToken: boolean;
  expreg = new RegExp('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$');

  /**
   * todos los estados para usar en el .html
   */
  readonly LOGIN_STATES = LoginState;

  /**
   * estado actual del proceso
   */
  state = LoginState.LOG_IN;

  /**
   * formulario de ingreso al sistema
   */
  formLogIn = this.fb.group({
    email: ['', [
      Validators.required,
      Validators.email,
      Validators.pattern('([a-zA-Z0-9-]+[._]?)+@+[a-zA-Z0-9-]{2,}([.-]+[a-zA-Z0-9]{1,})+')
    ]],
    password: ['',
      Validators.required
    ],
    checkInformation: ['']
  });

  formMustChangePass = this.fb.group({
    newPwd: ['', [Validators.required,
    Validators.pattern('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$')]],
    confirmPwd: ['', [Validators.required,
    Validators.pattern('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$')]]
  });

  isErrorLogin = false;
  showModal = false;

  /**
   * formulario de registro
   */
  formSignUp = this.fb.group({
    firstName: ['',
      Validators.required
    ],
    lastName: ['',
      Validators.required
    ],
    email: ['', [
      Validators.required,
      Validators.email,
      Validators.pattern('([a-zA-Z0-9-]+[._]?)+@+[a-zA-Z0-9-]{2,}([.-]+[a-zA-Z0-9]{1,})+')
    ]],
    password: ['', [Validators.required, this.passwordMinLength,
    Validators.pattern('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$')

    ]],
    password_confirm: ['',
      [Validators.required,
      Validators.pattern('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$')]
    ],
    phone: ['',
      Validators.required
    ],
    docType: ['',
      Validators.required
    ],
    numberId: ['',
      Validators.required
    ],
    countryIso: ['',
      Validators.required
    ],
    cityName: [{
      value: '',
      disabled: true
    }, [
      // Validators.required
    ]],
    address: ['',
      Validators.required
    ],
    language: [this.translateService.currentLang,
      // Validators.required
    ],
    countryPhoneIso: ['',
    ],
    gender: ['',
    ],
    dateOfBirth: ['',
    ]
  }, {
    validators: this.passwordConfirming
  });

  /**
   * formulario de registro paso 1
   */
  formSignUpPreLoadedStep1 = this.fb.group({
    email: ['', [
      Validators.required,
      Validators.email,
      Validators.pattern('([a-zA-Z0-9-]+[._]?)+@+[a-zA-Z0-9-]{2,}([.-]+[a-zA-Z0-9]{1,})+')
    ]],
    language: [this.translateService.currentLang,
    Validators.required
    ]
  });

  /**
   * formulario de pre-registro paso 2
   */
  formSignUpPreLoadedStep2 = this.fb.group({
    bin: ['',
      [
        Validators.maxLength(6),
        Validators.minLength(6),
        Validators.required
      ]],
    product: ['',
      [
        Validators.maxLength(4),
        Validators.minLength(4),
        Validators.required
      ]]
  });

  /**
     * formulario de pre-registro paso 3
     */
  formSignUpPreLoadedStep3 = this.fb.group({
    firstName: ['',
      Validators.required
    ],
    lastName: ['',
      Validators.required
    ]
  });


  formRecoveryPass = this.fb.group({

    emailRecovery: ['', [
      Validators.required,
      Validators.email,
      Validators.pattern('([a-zA-Z0-9-]+[._]?)+@+[a-zA-Z0-9-]{2,}([.-]+[a-zA-Z0-9]{1,})+')
    ]],
  });

  formSignUpPreLoadedStep4 = this.fb.group({
    password: ['', [
      Validators.required,
      this.passwordMinLength,
      Validators.pattern('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$')
    ]],
    password_confirm: ['', [
      Validators.required,
      Validators.pattern('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$')
    ]]
  },
    {
      validators: this.passwordConfirming
    });

  /**
   * true cuando estamos haciendo un request
   */
  loading = false;

  /**
   * listado de paises
   */
  countries = [];
  countrysClass = 'country'


  /**
   * listado de ciudades
   */
  cities = [];
  passwordSent: boolean;
  passwordResent = '';
  inputEmail;

  /**
   * variable para las  cookies
   */
  cookies: Object;
  keys: Array<string>;
  cName: string;
  cValue: string;
  rName: string;
  checkName: string;

  remember = false;
  myModel = false;
  isNoRegistered = false;
  message: any;
  invalidPass: boolean;
  isblock = false;
  activateErrorEmail: boolean;
  activateFooterModal = false;
  milesGift = 0;
  showTextmilesGift = false;
  isOneIntent = false;
  /**
   * Listado de generos
   */
  get titleTypeList() {
    return this.translations_genders;
  }

  /**
   * listado de tipos de documento
   */
  get IDtypes() {
    return this.translations['IDtypes'];
  }

  /**
   * refrescar campos fecha cuando cambian el idioma
   */
  refresherDates = true;

  /**
   * condiciones en las que no se puede hacer click en next
   */
  get nextButtonDisabled(): boolean {
    switch (this.state) {
      case LoginState.CHOOSE_OPTION:
      case LoginState.LOG_IN_SUCCESSFUL:
      case LoginState.SIGN_UP_SUCCESSFUL:
        return true;
      case LoginState.LOG_IN:
        return this.formLogIn.invalid;
      case LoginState.SIGN_UP:
        return this.formSignUp.invalid;
      case LoginState.SIGN_UP_PRE_REGISTERED_STEP_1:
        return this.formSignUpPreLoadedStep1.invalid;
      case LoginState.SIGN_UP_PRE_REGISTERED_STEP_2:
        return this.formSignUpPreLoadedStep2.invalid;
      case LoginState.SIGN_UP_PRE_REGISTERED_STEP_3:
        return this.formSignUpPreLoadedStep3.invalid;
      case LoginState.SIGN_UP_PRE_REGISTERED_STEP_4:
        return this.formSignUpPreLoadedStep4.invalid;
      case LoginState.RECOVERY_PASS:
        return this.formRecoveryPass.invalid;
      case LoginState.MUST_CHANGE_PASS:
        return this.formMustChangePass.invalid;
      case LoginState.PRE_REGISTERED_CASE_4:
        return false;
    }
  }

  get brand() {
    return this.storeService.brand;
  }

  get appName() {
    return environment.brands[this.storeService.brand].appName;
  }

  /**
   * textos para el usuario, traducidos a su idioma
   */
  translations = {};

  /**
   * Titulos para el usuario
   */
  translations_genders = [];

  /**
   * Formato de fecha
  */
  translations_date = [];

  /**
   * preloaderImg aux, (se recuerda el que habia antes de abrir el dialog)
   * al cerrar el dialog se lo vuelve a configurar
   */
  preloaderImg: string;

  /**
   * como mostrar fechas
   */
  locale: any = {
    format: ''
  };

  /**
   * nombre del usuario logeado/registrado
   */
  username: string;
  terms = true;
  activateError = false;
  isValidPin: boolean;
  password: string;
  noNameEmpty = true;
  disabledPreRegister = true;
  disabledPasswordRegister = true;
  isChangePass = false;
  loadCardConfirm = false;
  isInOnepocketPage = false;
  private systemLanguage = 'EN';

  constructor(
    private fb: FormBuilder,
    private languageService: LanguageService,
    private translateService: TranslateService,
    private dialogRef: MatDialogRef<LoginComponent>,
    private storeService: DataStoreService,
    private registryLocationService: RegistryLocationService,
    public dataService: DataService,
    private _storeLibrary: StoreServiceLibrary,
    private bottomSheet: MatBottomSheet,
    private actionEventService: ActionEventService,
    private _authService: AuthService,
    public cookieService: CookieService,
    private _general: GenerateService,
    private generalUtilService: GeneralUtilService,
    private travellerFormService: TravellerFormService,
    private onePocketService: OnePocketService,
    private loadingScreenService: LoadingScreenService,
    private initService: InitService,
    public dialog: MatDialog,
    private milesFormat: MilesFormatPipe,
    private loginService: LoginService,
    private lpService:LpService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.update();
    this.getCookies();
    if (this.data) {
      if (this.data['isInOnepocket']) {
        this.isInOnepocketPage = true;
        this.data = undefined;
        return;
      }

      if (this.data['openRegister']) {
        this.loading = false;
        this.activateError = false;
        this.activateErrorEmail = false;
        this.state = LoginState.PRE_REGISTERED_CASE_4;
      } else {
        this.validatePin();
      }
    }
  }

  /**
   * Tipo de registracion
   */
  public registrationType = 0;

  ngOnInit() {
    this.useJwtToken = this.dataService.getJwtUse();
    this.registrationType = this.dataService.getRegistrationType();

    this.initService.isInitializedBrand.subscribe((isInit) => {
      if (isInit) {
        if (LoginState.CHOOSE_OPTION || LoginState.LOG_IN_SUCCESSFUL || LoginState.LOG_IN) {
          // this.trackingManager.setCurrentScreen('/login', data.LOGIN.loginPopUp);
          GoogleAnalytics.setCurrentScreen('/login');
        } else if (LoginState.SIGN_UP || LoginState.SIGN_UP_PRE_REGISTERED_STEP_1 || LoginState.SIGN_UP_PRE_REGISTERED_STEP_2
          || LoginState.SIGN_UP_PRE_REGISTERED_STEP_3 || LoginState.SIGN_UP_PRE_REGISTERED_STEP_4) {
          // this.trackingManager.setCurrentScreen('/register', data.REGISTER.registerPopUp);
          GoogleAnalytics.setCurrentScreen('/register');
        } else if (LoginState.RECOVERY_PASS) {
          // this.trackingManager.setCurrentScreen('/reset-pass', data.RESET_PASS.forgotPassPopUp);
          GoogleAnalytics.setCurrentScreen('/reset-pass');
        }
      }
    });


    // loader con el login
    this.preloaderImg = this.storeService.preloaderImg;
    this.storeService.preloaderImg = 'procesing';

    this.languageService.systemLanguajeChange.subscribe((language) => {
      if (language) {
        this.getTranslations().then(() => {
          this.dateInputsRefresh();
        });
        this.systemLanguage = language.toUpperCase();
      }
    });

    let milesGiftFormat: any = environment.brands[this.storeService.brand].milesGiftDefault;
    if (milesGiftFormat) {
      this.milesGift = this.milesFormat.transform(milesGiftFormat);
    }

    this.showTextmilesGift = this.storeService.brand === 'credomatic' || false

  }

  ngAfterViewInit() {

  }

  ngOnDestroy() {
    // setear el loaderImg que habia antes de abrir el dialog
    this.storeService.preloaderImg = this.preloaderImg;
    if (this.isChangePass) {
      this.loginService.logout();
    }
  }

  /**
   * password min length
   */
  passwordMinLength(c: AbstractControl): { password_length: boolean } {
    if (c.value && c.value.length > 3 && (c.value.length < 8 || c.value.length > 16) && c.dirty) {
      return { password_length: true };
    }
  }

  /**
   * validar contraseña confirmada
   */
  private passwordConfirming(c: AbstractControl): { password_not_match: boolean } {
    if (c.get('password').value !== c.get('password_confirm').value && c.get('password').touched) {
      return { password_not_match: true };
    }
  }

  /**
   * obtener traducciones para los textos de usuario
   */
  private getTranslations() {
    return new Promise<any>((resolve, reject) => {
      this.translateService.get(['LOGIN',
        'TRAVELLER_FORM',
        'DATE',
        'GENDERS',]).subscribe((trns) => {
          this.translations = trns['LOGIN'];
          this.translations_date = trns['DATE'];
          this.translations_genders = trns['GENDERS'];
          resolve(true);
        });
    });
  }

  /**
   * elijen opción Log In
   */
  chooseOptionLogIn() {
    this.state = LoginState.LOG_IN;
  }

  /**
   * Elijen opción Sign Up
   */
  chooseOptionSignUp() {
    if (this.registrationType === 2) {
      this.formSignUpPreLoadedStep1.get('language').setValue(this.translateService.getDefaultLang());
      this.state = LoginState.SIGN_UP_PRE_REGISTERED_STEP_1;
    } else {
      if (this.registrationType === 4) {
        this.state = LoginState.PRE_REGISTERED_CASE_4;
      } else {
        this.state = LoginState.SIGN_UP;
        // preparamos formulario de registro
        this.formSignUp.get('language').setValue(this.translateService.getDefaultLang());
        this.loadCountries();
        this.formSignUp.get('countryIso').valueChanges.subscribe((countryIso) => {
          if (countryIso) {
            this.loadCities();
          }
        });
      }
    }

  }

  /**
   * Obtener listado de paises
   */
  private loadCountries() {
    this.countries = [];
    this.registryLocationService.getCountries().then(() => {
      this.countries = this.registryLocationService.countries;
    });
  }

  /**
   * Obtener listado de ciudades
   */
  private loadCities() {
    this.cities = [];
    this.formSignUp.get('cityName').setValue('');
    this.formSignUp.get('cityName').disable();
    const countryId = this.countries.find(country => country.iso === this.formSignUp.get('countryIso').value).id;
    this.registryLocationService.getCities(countryId).then(() => {
      if (this.registryLocationService.cities) {
        this.cities = this.registryLocationService.cities;
      } else {
        this.cities = this.translations['city_other'];
      }
      this.formSignUp.get('cityName').enable();
    });
  }

  /**
   * salir del proceso
   */
  cancelar() {
    this.dialogRef.close();
  }

  /**
   * acción que se ejecuta para continuar para avanzar en el flujo normal del proceso
   */
  next() {
    switch (this.state) {
      case LoginState.LOG_IN:
        // this.stepLogIn();
        this.validateUserMR();
        break;
      case LoginState.SIGN_UP:
        this.stepSignUp();
        break;
      case LoginState.SIGN_UP_PRE_REGISTERED_STEP_1:
        // this.trackingManager.trackEventString(data.LOGIN.signUp, data.LOGIN.loginPopUp);
        GoogleAnalytics.sendEventFirebase('ga_event', data.LOGIN.loginPopUp, data.LOGIN.signUp, '');
        this.stepSignUpPreRegistered();
        break;
      case LoginState.SIGN_UP_PRE_REGISTERED_STEP_2:
        this.stepSignUpPreRegisteredStep2();
        // this.trackingManager.trackEventString(data.REGISTER.confirmBotonEmail, data.REGISTER.registerPopUp);
        GoogleAnalytics.sendEventFirebase('ga_event', data.REGISTER.registerPopUp, data.REGISTER.confirmBotonEmail, '');
        break;
      case LoginState.SIGN_UP_PRE_REGISTERED_STEP_3:
        this.stepSignUpPreRegisteredStep3();
        // this.trackingManager.trackEventString(data.REGISTER.confirmName, data.REGISTER.registerPopUp);
        GoogleAnalytics.sendEventFirebase('ga_event', data.REGISTER.registerPopUp, data.REGISTER.confirmName, '');
        break;
      case LoginState.SIGN_UP_PRE_REGISTERED_STEP_4:
        this.stepSignUpPreRegisteredStep4();
        // this.trackingManager.trackEventString(data.REGISTER.confirmPass, data.REGISTER.registerPopUp);
        GoogleAnalytics.sendEventFirebase('ga_event', data.REGISTER.registerPopUp, data.REGISTER.confirmPass, '');
        break;
      case LoginState.PRE_REGISTERED_CASE_4:
        // this.trackingManager.trackEventString(data.REGISTER.confirmBotonEmail, data.REGISTER.registerPopUp);
        GoogleAnalytics.sendEventFirebase('ga_event', data.REGISTER.registerPopUp, data.REGISTER.confirmBotonEmail, '');
        this.registerCaseFour();
        break;
      case LoginState.ACTIVATE_NO_NAME_AND_LNAME:
        break;
      case LoginState.ACTIVATE_TERMS_AND_CONDITIONS:
        break;
      case LoginState.ACTIVATE_SEND_PASSWORD:
        break;
      case LoginState.LOG_IN_SUCCESSFUL:
        this.stepLoginSuccessful();
        break;
      case LoginState.SIGN_UP_SUCCESSFUL:
        this.stepSignupSuccessful();
        break;
      case LoginState.RECOVERY_PASS:
        this.recoverPassword();
        // this.trackingManager.trackEventString(data.RESET_PASS.confirmEmailSend, data.RESET_PASS.forgotPassPopUp);
        GoogleAnalytics.sendEventFirebase('ga_event', data.RESET_PASS.forgotPassPopUp, data.RESET_PASS.confirmEmailSend, '');
        break;
      case LoginState.MUST_CHANGE_PASS:
        // this.trackingManager.trackEventString(data.RESET_PASS.confirmEmailSend, data.RESET_PASS.forgotPassPopUp);
        GoogleAnalytics.sendEventFirebase('ga_event', data.RESET_PASS.forgotPassPopUp, data.RESET_PASS.confirmEmailSend, '');
        this.serviceChangePassword();
    }
  }

  registerCaseFour() {
    // this.trackingManager.trackEventString(data.REGISTER.tryAgainBoton, data.REGISTER.registerPopUp);
    GoogleAnalytics.sendEventFirebase('ga_event', data.REGISTER.registerPopUp, data.REGISTER.tryAgainBoton, '');
    this.loading = true;
    this.loginService.validateEmail(
      this.formSignUpPreLoadedStep1.getRawValue() as IPreRegisterStep1
    ).then((data) => {
      // signup successful
      // Se envia email al usuario con url para confirmacion de registro
      this.getValidMailResponse(data);

    }).catch((err) => {
      // incorrect signup
      const arrayError = JSON.parse(this._storeLibrary.getJustOneUniversalPropertie('_APP_CODE_ERROR') || '[]');
      if (arrayError.some(a => { return a == err.code })) {
        this.openPopoverError(err.data);
      } else {
        this.loading = false;
        this.activateError = true;
        this.activateErrorEmail = true;
        this.state = LoginState.PRE_REGISTERED_CASE_4;
      }
      // Activated Error
      // alert(message || this.translations['signup_fail']);
    });
  }

  validateTermsAndConditions(event) {
    const paramsRegister = {
      'email': this.data.email,
      'firstName': this.data.name,
      'lastName': this.data.lname
    };
    // this.trackingManager.trackEventString(data.REGISTER.confirmTermsAndCond, data.REGISTER.registerPopUp, '', JSON.stringify(paramsRegister));
    GoogleAnalytics.sendEventFirebase('ga_event', data.REGISTER.registerPopUp, data.REGISTER.confirmTermsAndCond, '');
    if (this.terms && event) {
      if (this.isUserMR()) {
        this.registerSystem();
      } else {
        this.state = LoginState.ACTIVATE_SEND_PASSWORD;
        this.next();
      }
    } else {
    }
  }

  validateEmptyInputs(firstNameInput, lastNameInput) {
    const firstName = firstNameInput.value;
    const lastName = lastNameInput.value;
    if (firstName === '' || lastName === '') {
      this.noNameEmpty = true;
    } else {
      this.data.name = firstName;
      this.data.lname = lastName;
      this.noNameEmpty = false;
      this.state = LoginState.ACTIVATE_TERMS_AND_CONDITIONS;
      this.next();
    }
  }

  async validatePin() {
    if (this.data) {
      const user: UserData = new UserData();
      this._storeLibrary.setUserData(user);
      await this.loginService.validateConfirmation(this.data.pin, this.data.idC, this.translateService.getDefaultLang()).then((data) => {
        this.isValidPin = true;
        this.getDataUserRegister(this.isValidPin);
      }).catch((err) => {
        this.isValidPin = false;
        this.getDataUserRegister(this.isValidPin);
      });
    }
  }

  validatedChecked(event) {
    this.terms = !this.terms;
  }

  /**
   * realizar ingreso al sistema
   */
  stepLogIn() {
    this.loading = true;
    this.loginService.login(
      this.formLogIn.get('email').value,
      this.formLogIn.get('password').value,
      this.languageService.systemLanguajeChange.value
    ).then((data) => {
      // login successful
      const userData: UserData = this._storeLibrary.getUserData();
      if (this.formLogIn.get('checkInformation').value) {
        this.addCookie('email', CryptoJS.AES.encrypt(JSON.stringify(this.formLogIn.get('email').value), 'miclave').toString());
        this.addCookie('pw', CryptoJS.AES.encrypt(JSON.stringify(this.formLogIn.get('password').value), 'miclave').toString());
      } else {
        this.removeCookie();
      }
      
      const lpCtmr:LpCtmr = {
        info: {
          cstatus: 'active',
          customerId: userData.accountId,
          balance: Number(userData.accountResultDto.balance),
          userName: '', //this.userData.accountSumaryDto.emailaddress
          accountName: '' //this.userData.accountSumaryDto.emailaddress
        }
      }; 
      this.lpService.callLpVisitorInfo(lpCtmr);

      if (!data.mustChangePassword) {
        userData.authenticationToken = data.authenticationToken;
        userData.accountSumaryDto.firstName = data.firstName;
        userData.accountSumaryDto.lastName1 = data.lastName;
        this.loading = false;
        this.state = LoginState.LOG_IN_SUCCESSFUL;
        this.isErrorLogin = false;
        this.isChangePass = false;
        this._storeLibrary.setUserData(userData);
        this.next();

        if (this.storeService.brand === 'credomatic' && !this.isInOnepocketPage) {
          this.openGetCard();
        }
        GoogleAnalytics.sendEventFirebase('ga_event', data.LOGIN.loginPopUp, data.LOGIN.signInButton, 'Login_Success');
      } else {
        this.isChangePass = true;
        userData.authenticationToken = data.authenticationToken;
        userData.accountSumaryDto.firstName = data.firstName;
        userData.accountSumaryDto.lastName1 = data.lastName;
        this._storeLibrary.setUserData(userData);
        this.loading = false;
        this.state = LoginState.MUST_CHANGE_PASS;
      }
    }).catch((err: any) => {
      // incorrect system login
      this.loading = false;
      this.isErrorLogin = true;

      let attemptsToBlock;
      if (err && err.sessionAttemptsToBlock) {
        attemptsToBlock = Number(err.sessionAttemptsToBlock);
        if (attemptsToBlock === 1) {
          this.message = err.operationResult.message;
          this.isOneIntent = true;
        }
      }


      if (err && err.operationResult && err.operationResult.code && err.operationResult.code === '2002') {
        this.message = err.operationResult.message;
        this.isblock = true;
        this.isOneIntent = false;
      }

      GoogleAnalytics.sendEventFirebase('ga_event', data.LOGIN.loginPopUp, data.LOGIN.signInButton, 'Login_Error');
      // alert(message || this.translations['login_fail']);
    });
    const paramsLogin = {
      'email': this.formLogIn.get('email').value,
      'isRemember': this.formLogIn.get('checkInformation').value
    };
    // this.trackingManager.trackEventString(data.LOGIN.signInButton, data.LOGIN.loginPopUp, '', JSON.stringify(paramsLogin));
  }

  /**
   * Ingreso al sistema correcto
   */
  stepLoginSuccessful() {
    const haveSubBrand = environment.brands[this.storeService.brand].subBrand;

    if (haveSubBrand) {
      this.getCardsUser();
    }

    this.dialogRef.close(true);
    this.sendMonitoring();
    this.getCountryCode();
  }

  getCountryCode() {
    const smartProfile = JSON.parse(sessionStorage.getItem('smartProfile'));
    let objectcountry = {
      code: 'CRI',
      name: 'Costa Rica',
      classCountry: `${this.countrysClass}-CRI`
    };
    let countryCode = 'CRI';

    if (smartProfile && smartProfile.accountResultDto && smartProfile.accountResultDto.isoCountyCard) {
      countryCode = smartProfile.accountResultDto.isoCountyCard;
      objectcountry.code = countryCode;
      objectcountry.classCountry = `${this.countrysClass}-${countryCode}`
    }

    const validateUserCountry = this.validateCountryUser(countryCode);


    if (validateUserCountry.countryUserToPrint) {
      let countryUserConfigured = validateUserCountry.objectToPrint;
      this.actionEventService.sendCountryCode(countryUserConfigured);
    } else {
      const objectcountryNone = {
        code: 'CRI',
        name: 'Costa Rica',
        classCountry: `${this.countrysClass}-CRI`
      };
      this.actionEventService.sendCountryCode(objectcountryNone);
    }
  }

  validateCountryUser(countryUser) {
    let jsonCountries = this._storeLibrary.getJustOneUniversalPropertie('_JSON_COUNTRIES_ALLETRAVEL');
    if (jsonCountries) {
      let castCountries = JSON.parse(jsonCountries);


      let countries = castCountries['countries'];
      let countryUserToPrint: boolean = false;

      let objConfigurationsUser = {
        countryUserToPrint: countryUserToPrint,
        objectToPrint: {}
      };

      countries.map((country) => {
        if (country.code == countryUser) {
          let objectToPrint = country;
          countryUserToPrint = true;
          objConfigurationsUser.objectToPrint = objectToPrint;
          objConfigurationsUser.countryUserToPrint = countryUserToPrint;
          return true;
        }
      });
      if (countryUserToPrint) {
        return objConfigurationsUser;
      } else {
        return objConfigurationsUser;
      }
    }
  }

  getCardsUser() {
    const user: UserData = this._storeLibrary.getUserData();
    user.authenticationToken = `${this.dataService.token}`;
    this._storeLibrary.setUserData(user);
    this.onePocketService.getCreditCards().subscribe((data: any) => {
      const listCards: any[][] = this.getSeparateListCreditCard(data);
      if (data.getCreditCardResponse.resultGetCreditCards
        && listCards['user'].length > 0) {

        for (const creditCardItem of listCards['user']) {
          if (creditCardItem && creditCardItem.creditCard
            && creditCardItem.creditCard.creditCardBase
            && creditCardItem.creditCard.creditCardBase.franquiseID
            && creditCardItem.creditCard.creditCardBase.defaultt) {
            // (creditCardItem.creditCard.creditCardBase.franquiseName);            
            this.sendBrandLoad(creditCardItem.creditCard.creditCardBase.franquiseID);
          }
        }
      }
    }, (err) => {
    });
  }

  sendBrandLoad(franchiseId) {
    if (franchiseId === '3') {
      this.dataService.saveCreditCardDefult('amex');
      this.actionEventService.setSubBrand('amex');
      this.actionEventService.setChangeFooter(true);
    } else {
      this.dataService.saveCreditCardDefult('');
      this.actionEventService.setSubBrand('default');
      this.actionEventService.setChangeFooter(false);
    }
  }

  getSeparateListCreditCard(creditCardsResponse: any) {
    let creditCardsAll: any = [];
    const listCreditCardsSeparate: any[][] = [];
    const creditCardsStripe = [];
    const creditCards = [];

    if (creditCardsResponse.getCreditCardResponse.resultGetCreditCards.creditCards) {
      creditCardsAll = creditCardsResponse.getCreditCardResponse.resultGetCreditCards.creditCards;

      for (let i = 0; i < creditCardsAll.length; i++) {

        if (creditCardsAll[i].type && creditCardsAll[i].type === 'virtual') {
          creditCardsStripe.push(creditCardsAll[i]);
        } else {
          creditCards.push(creditCardsAll[i]);
        }
      }
    }

    listCreditCardsSeparate['virtual'] = creditCardsStripe;
    listCreditCardsSeparate['user'] = creditCards;

    return listCreditCardsSeparate;
  }

  showTermsAndConditionsDialog() {
    const lang = this._storeLibrary.getItemFromStorage('lang') || 'en';
    // const urlTerms = environment.brands[this.dataStore.brand].terminos_y_condiciones[lang.toUpperCase()];
    let urlTerms = this._storeLibrary.getJustOneUniversalPropertie(keysUniversal.web_url_terms_and_conditions_en);

    if (lang) {
      if (lang == 'es') {
        urlTerms = this._storeLibrary.getJustOneUniversalPropertie(keysUniversal.web_url_terms_and_conditions_es);
      }
    }
    // this.trackingManager.trackEventString(data.WELCOME.TermsConditionsBottom, data.WELCOME.welcomeScreen);
    GoogleAnalytics.sendEventFirebase('ga_event', data.WELCOME.welcomeScreen, data.WELCOME.TermsConditionsBottom, '');
    // this.dataService.openTermsAndConditionsDialog();
    window.open(urlTerms, '_blank');
  }

  /**
   * registro al sistema
   */
  stepSignUp() {
    this.loading = true;
    this.loginService.register(
      this.formSignUp.getRawValue() as IRegister
    ).then((data) => {
      this.loginService.updateProfile(this.formSignUp.getRawValue() as IRegister);
      // signup successful
      this.loading = false;
      this.username = data.username;
      this.state = LoginState.SIGN_UP_SUCCESSFUL;
      this.next();
    }).catch((message) => {
      // incorrect signup
      this.loading = false;
      // alert(message || this.translations['signup_fail']);
    });
  }

  registerSystem() {
    this.loading = true;
    if (this.isUserMR()) {
      this.state = LoginState.ACTIVATE_CARD_CONFETI;
      // activate footer modal
      // this.loadCardConfirm = true;
      this.stepLogIn();
    } else {

      const obj: IRegister = {
        email: this.data.email,
        firstName: this.data.name,
        lastName: this.data.lname,
        language: this.translateService.currentLang,
        password: this.password,
        certificateNumber: this.data.idC,
        pin: this.data.pin,
        register: this.isNoRegistered
      };

      this.storeService.channelID = Number(this.data.channelid);

      this.loginService.register(
        obj
      ).then((data) => {
        this.loginService.updateProfile(obj);
        // signup successful
        this.loading = false;
        this.username = data.username;
        this.state = LoginState.ACTIVATE_CARD_CONFETI;

        if (this.storeService.brand === 'credomatic') {
          this.loadCardConfirm = true;
        }
        this.next();
      }).catch((message) => {
        // incorrect signup
        this.loading = false;
        this.activateError = true;
        this.state = LoginState.PRE_REGISTERED_CASE_4;
        this.next();
        // alert(message || this.translations['signup_fail']);
      });
    }
  }

  validateInputsPassword(inputPass, inputPassConfirm, isKeyUp) {
    if (isKeyUp) {
      if (inputPass.value <= 0 || inputPassConfirm.value <= 0) {
        this.disabledPasswordRegister = true;
      } else {
        this.disabledPasswordRegister = false;
      }
    } else {
      if (inputPass.value === '' || inputPassConfirm.value === '') {
        this.disabledPasswordRegister = true;
      } else {
        this.disabledPasswordRegister = false;
        this.password = inputPass.value;
        this.validateBinWithRegister();
      }
    }
  }

  isValidPassword(password) {
    const valuePass = this.isCorrectPassword(password.value);
    if (!valuePass) {
      this.disabledPasswordRegister = true;
    }
  }


  isCorrectPassword(password: string) {
    const rePass = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$@$!%*?&#/=.$($)$-$_,])[A-Za-z\d$@$!%*?&#/=.$($)$-$_,]{8,15}$/;
    return rePass.test(String(password));
  }


  validateErrorSeems() {
    // this.trackingManager.trackEventString(data.REGISTER.errorSeems, data.REGISTER.registerPopUp);
    GoogleAnalytics.sendEventFirebase('ga_event', data.REGISTER.registerPopUp, data.REGISTER.errorSeems, '');
    this.state = LoginState.PRE_REGISTERED_CASE_4;
    this.activateError = false;
    this.next();
  }

  disabledKeyup(emailInput) {
    if (emailInput.value <= 0) {
      this.disabledPreRegister = true;
    } else {
      this.disabledPreRegister = false;
    }
  }

  disabledKeyUpName(inputName, inputLName) {
    if (inputName.value <= 0 || inputLName.value <= 0) {
      this.noNameEmpty = true;
    } else {
      this.noNameEmpty = false;
    }
  }


  /**
   * verificacion y registro al sistema por email
   */
  stepSignUpPreRegistered() {
    this.loading = true;
    this.loginService.validateEmail(
      this.formSignUpPreLoadedStep1.getRawValue() as IPreRegisterStep1
    ).then((data) => {
      // signup successful
      // Se envia email al usuario con url para confirmacion de registro
      this.getValidMailResponse(data);

      this.loading = false;
      this.username = data.username;
      this.state = LoginState.SIGN_UP_PRE_REGISTERED_SUCCESSFUL;
      this.next();
    }).catch((err) => {
      const arrayError = JSON.parse(this._storeLibrary.getJustOneUniversalPropertie('_APP_CODE_ERROR') || '[]');
      if (arrayError.some(a => { return a == err.code })) {
        this.openPopoverError(err.data);
      } else {
        this.loading = false;
        this.activateError = true;
        this.state = LoginState.SIGN_UP_PRE_REGISTERED_STEP_2;
      }
    });
  }

  /**
   * verificacion y registro al sistema por tarjeta y email
   */
  stepSignUpPreRegisteredStep2() {
    this.loading = true;
    const userData = this.formSignUpPreLoadedStep2.getRawValue() as IPreRegisterStep2;
    userData.email = (this.formSignUpPreLoadedStep1.getRawValue() as IPreRegisterStep1).email;
    userData.language = (this.formSignUpPreLoadedStep1.getRawValue() as IPreRegisterStep1).language;
    this.loginService.validateCard(userData).then((data) => {
      // Se envia email al usuario con url para confirmacion de registro
      this.loading = false;
      this.state = LoginState.SIGN_UP_PRE_REGISTERED_SUCCESSFUL;
    }).catch((err) => {
      this.loading = false;
      this.state = LoginState.SIGN_UP_PRE_REGISTERED_STEP_3;
    });
  }

  stepSignUpPreRegisteredStep3() {
    this.loading = true;
    const userDataStep1 = this.formSignUpPreLoadedStep1.getRawValue() as IPreRegisterStep1;
    const userDataStep2 = this.formSignUpPreLoadedStep2.getRawValue() as IPreRegisterStep2;
    const userDataStep3 = this.formSignUpPreLoadedStep3.getRawValue() as IPreRegisterStep3;
    userDataStep3.bin = userDataStep2.bin;
    userDataStep3.product = userDataStep2.product;
    userDataStep3.email = userDataStep1.email;
    userDataStep3.language = userDataStep1.language;
    this.loginService.validateCardAndPerson(userDataStep3).then((data) => {
      this.loading = false;
      this.state = LoginState.SIGN_UP_PRE_REGISTERED_SUCCESSFUL;
      this.next();
    }).catch((err) => {
      this.loading = false;
      this.state = LoginState.SIGN_UP_PRE_REGISTERED_STEP_4;
    })
  }

  stepSignUpPreRegisteredStep4() {
    this.loading = true;
    setTimeout(() => {
      this.loading = false;
      alert(this.translations['signup_fail']);
      this.cancelar();
    }, 3000);
  }

  getCountryPhoneCodeByISO(isoCountryCode: FormControl) {
    if (isoCountryCode && isoCountryCode.value) {
      this.formSignUp.get('countryPhoneIso').setValue(CountryPhoneISO[isoCountryCode.value]);
      return CountryPhoneISO[isoCountryCode.value];
    } else {
      this.formSignUp.get('countryPhoneIso').setValue('');
      return '';
    }
  }


  /**
   * refrescar los inputs de fechas para que muestren bien la fecha
   */
  private dateInputsRefresh() {
    this.refresherDates = false;
    this.locale.format = this.translations_date['format'];
    setTimeout(() => {
      this.refresherDates = true;
    }, 10);
  }

  /**
   * registro correcto
   */
  stepSignupSuccessful() {
  }

  getDataUserRegister(isValidPin) {
    if (this.data) {
      if (isValidPin) {
        if (this.data.name === '' || this.data.lname === '' || !(/\S/.test(this.data.name)) || !(/\S/.test(this.data.lname))) {
          this.state = LoginState.ACTIVATE_NO_NAME_AND_LNAME;
          this.isNoRegistered = false;
          this.next();
        } else {
          this.state = LoginState.ACTIVATE_TERMS_AND_CONDITIONS;
          this.isNoRegistered = true;
          this.next();
        }
      } else {
        this.state = LoginState.PRE_REGISTERED_CASE_4;
        this.activateError = true;
        this.next();
      }
    }
  }


  validateBinWithRegister() {
    this.loginService.validateChannelIdAndEmail(this.data.email, this.data.channelid).then((response: AuthGetAccount2Response) => {
      const userData = this._storeLibrary.getUserData();
      if (response && response.accountResultDto) {
        userData.accountId = response.accountResultDto.accountId;
        if (response.accountResultDto.accountSumaryDto) {
          userData.accountResultDto = response.accountResultDto;
          userData.accountSumaryDto = response.accountResultDto.accountSumaryDto;
        }

        if (response.accountResultDto.bin) {
          userData.bin = response.accountResultDto.bin;
        }

      }
      this._storeLibrary.setUserData(userData);
      this.registerSystem();
    }, (err) => {
      this.state = LoginState.PRE_REGISTERED_CASE_4;
      this.activateError = true;
      this.next();
    });

  }

  goToExWhatsapp() {
    // if (this.platform.is('cordova')) {
    // setTimeout(() => {
    const urlWhatsappWelcome = this._storeLibrary.getJustOneUniversalPropertie(keysUniversal.app_url_whatsapp_welcome);
    window.open(urlWhatsappWelcome, '_system', 'location=yes');
    // this.trackingManager.trackEventString(data.LOGIN.contactWhatsapp, data.LOGIN.loginPopUp, '', urlWhatsappWelcome);
    GoogleAnalytics.sendEventFirebase('ga_event', data.LOGIN.loginPopUp, data.LOGIN.contactWhatsapp, urlWhatsappWelcome);
    // }, 2000);
    // }
  }

  showModalRecoveryPass() {
    // this.trackingManager.trackEventString(data.LOGIN.resetPassword, data.LOGIN.loginPopUp);
    GoogleAnalytics.sendEventFirebase('ga_event', data.LOGIN.loginPopUp, data.LOGIN.resetPassword, '');
    this.state = LoginState.RECOVERY_PASS;
  }

  async recoverPassword() {
    this.inputEmail = this.formRecoveryPass.get('emailRecovery').value;
    const channelId = this._storeLibrary.getJustOneUniversalPropertie(keysUniversal.app_channel_id);
    this._authService.forgotPassword(this.inputEmail, channelId, this.useJwtToken).subscribe(
      (resp) => {
        // Siempre mostramos que se envio el correo por seguridad
        this.passwordSent = true;

        // if (String(resp.code) === '200') {
        //   this.passwordSent = true;
        // } else {
        //   this.passwordSent = false;
        // }
      },
      (err) => {
        // Siempre mostramos que se envio el correo por seguridad
        this.passwordSent = true;
      }
    );

  }

  isRememberInformation() {
    this.myModel = this.formLogIn.get('checkInformation').value;
    if (!this.myModel) {
      this.removeCookie();
    }
  }

  update() {
    this.cookies = this.cookieService.getAll();
    this.keys = Object.keys(this.cookies);
  }
  addCookie(cName: string, cValue: string) {
    this.cookieService.set(cName, cValue);
    this.update();
  }
  removeCookie() {
    this.cookieService.delete('email');
    this.cookieService.delete('pw');
    this.update();
  }

  getCookies() {
    if (this.cookieService.check('email') && this.cookieService.check('pw')) {
      this.formLogIn.get('email').setValue(this._general.decrypt(this.cookieService.get('email')));
      this.formLogIn.get('password').setValue(this._general.decrypt(this.cookieService.get('pw')));
      this.formLogIn.get('checkInformation').setValue(true);
    }
  }

  sendMonitoring() {
    const lpBrandName = environment.brands[this.storeService.brand].lpBrandName;

    const travellerData = [this.travellerFormService.setTravellerData(this.dataService.smartProfileUserData)];

    if (!travellerData[0]) {
      return;
    }

    this.generalUtilService.LPEngagementAttribute(
      travellerData[0].firstname, travellerData[0].lastname, travellerData[0].email,
      `${travellerData[0].phoneArea}${travellerData[0].phoneNumber}`,
      lpBrandName);
  }

  validateUserMR() {
    const validateVLSMigrate = this._storeLibrary.getJustOneUniversalPropertie(keysUniversal.app_validateVLSMigrate) || 'false';
    const dataValidate: IPreRegisterStep1 = {
      email: this.formLogIn.value.email,
      language: this.languageService.systemLanguajeChange.value
    };


    this.loginService.validateEmail(
      dataValidate
    ).then((data) => {
      // signup successful
      // Se envia email al usuario con url para confirmacion de registro
      const channelIDMR: PropertyDto = new PropertyDto();
      const mapProperties = this._storeLibrary.getItemFromStorage(keys.universalProperties) as PropertyDto[];
      if (this._storeLibrary.getJustOneUniversalPropertie(keysUniversal.app_channel_id_mr) !== '') {
        mapProperties.map(dato => {
          if (dato.key === this.dataService.idApplication + keysUniversal.app_channel_id_mr) {
            dato.value = '' + data.accountResultDto.channelId;
          }
          return dato;
        });

      } else {
        channelIDMR.key = this.dataService.idApplication + keysUniversal.app_channel_id_mr;
        channelIDMR.value = '' + data.accountResultDto.channelId;
        channelIDMR.state = true;
        mapProperties.push(channelIDMR);
      }
      this._storeLibrary.setUniversalProperties(mapProperties);
      this.storeService.channelID = this._storeLibrary.getJustOneUniversalPropertie(keysUniversal.app_channel_id_mr) !== '' ? +this._storeLibrary.getJustOneUniversalPropertie(keysUniversal.app_channel_id_mr) : +this._storeLibrary.getJustOneUniversalPropertie(keysUniversal.app_channel_id);

      this.loading = false;
      const userData: UserData = new UserData;
      userData.accountSumaryDto = new AccountSumaryDto();
      userData.accountSumaryDto = data.accountResultDto.accountSumaryDto;
      userData.accountId = data.accountResultDto.accountId;
      userData.fl = data.accountResultDto.fl as boolean;
      this._storeLibrary.setUserData(userData);
      if (data.accountResultDto.fl && JSON.parse(data.accountResultDto.fl.toString()) && JSON.parse(validateVLSMigrate)) {

        this.data = {
          name: data.accountResultDto.accountSumaryDto.firstName,
          lname: data.accountResultDto.accountSumaryDto.lastName
        };
        this.noNameEmpty = false;
        this.state = LoginState.ACTIVATE_TERMS_AND_CONDITIONS;
        this.next();
      } else {
        this.stepLogIn();
      }
    }).catch((message) => {
      // incorrect signup
      this.stepLogIn();
      // Activated Error
      // alert(message || this.translations['signup_fail']);
    });
  }

  isUserMR(): boolean {
    const userData = this._storeLibrary.getUserData();
    const validateVLSMigrate = this._storeLibrary.getJustOneUniversalPropertie(keysUniversal.app_validateVLSMigrate) || 'false';
    return (userData.fl && JSON.parse(userData.fl.toString()) && JSON.parse(validateVLSMigrate));
  }


  getValidMailResponse(response: AuthGetAccount2Response) {
    const mapProperties = this._storeLibrary.getItemFromStorage(keys.universalProperties) as PropertyDto[];
    // TODO se pone solo para pruebas de diseño

    if (response.operationResult.code === '200') {
      const channelIDMR: PropertyDto = new PropertyDto();
      if (this._storeLibrary.getJustOneUniversalPropertie(keysUniversal.app_channel_id_mr) !== '') {
        mapProperties.map(dato => {
          if (dato.key === this.dataService.idApplication + keysUniversal.app_channel_id_mr) {
            dato.value = '' + response.accountResultDto.channelId;
          }
          return dato;
        });

      } else {
        channelIDMR.key = this.dataService.idApplication + keysUniversal.app_channel_id_mr;
        channelIDMR.value = '' + response.accountResultDto.channelId;
        channelIDMR.state = true;
        mapProperties.push(channelIDMR);
      }
      this._storeLibrary.setUniversalProperties(mapProperties);
      this.storeService.channelID = this._storeLibrary.getJustOneUniversalPropertie(keysUniversal.app_channel_id_mr) !== '' ? +this._storeLibrary.getJustOneUniversalPropertie(keysUniversal.app_channel_id_mr) : +this._storeLibrary.getJustOneUniversalPropertie(keysUniversal.app_channel_id);
      // this._storeLibrary.setItemInStorage(keys.universalProperties, mapProperties);

      if (response.accountResultDto.accountId) {
        // SI EXISTE VALIDO SI EN QUE ESTADO ESTA0
        if (response.accountResultDto.accountSumaryDto.status === '1') {

          const userData: UserData = new UserData;
          userData.accountSumaryDto = new AccountSumaryDto();
          userData.accountSumaryDto = response.accountResultDto.accountSumaryDto;
          userData.accountId = response.accountResultDto.accountId;

          const universalHaveRegister = this._storeLibrary.getJustOneUniversalPropertie(keysUniversal.app_have_register_with_credit_card);
          // const haveRegisterWithCreditCard = Boolean(JSON.parse(universalHaveRegister));

          this._storeLibrary.setUserData(userData);

          // Se vuelve a llamar el getAccount para que envie el correo
          const authGetAccount2Request = new AuthGetAccount2Request();
          authGetAccount2Request.channelId = this._storeLibrary.getJustOneUniversalPropertie(keysUniversal.app_channel_id_mr) !== '' ? +this._storeLibrary.getJustOneUniversalPropertie(keysUniversal.app_channel_id_mr) : +this._storeLibrary.getJustOneUniversalPropertie(keysUniversal.app_channel_id);
          authGetAccount2Request.language = this.translateService.currentLang;
          authGetAccount2Request.email = this.formSignUpPreLoadedStep1.controls['email'].value;
          authGetAccount2Request.authenticationToken = '';
          authGetAccount2Request.certificateNumber = '';
          authGetAccount2Request.validateUser = true;
          authGetAccount2Request.security = SecurityObject.getSecurityObject();

          this._authService.getAccount2V2(authGetAccount2Request, null, this.useJwtToken).subscribe(
            (res) => {
              this.loading = false;
              this.state = LoginState.SIGN_UP_PRE_REGISTERED_SUCCESSFUL;
              this.next();
            },
            (err) => {
              this.loading = false;
              this.activateError = true;
              this.formLogIn.controls['email'].setValue(this.formSignUpPreLoadedStep1.controls['email'].value);
              this.state = LoginState.LOG_IN;
            });

          // this._injector.addDynamicComponent(ValidateBinProductComponent);

        } else if (response.accountResultDto.accountSumaryDto.status === '7' && this.data.idApplication === typeApp.LIFEMILES) {
          this.loading = false;
          this.state = LoginState.SIGN_UP_PRE_REGISTERED_SUCCESSFUL;
          this.next();
        } else {
          this.loading = false;
          const userData: UserData = new UserData;
          userData.accountSumaryDto = new AccountSumaryDto();
          userData.accountSumaryDto = response.accountResultDto.accountSumaryDto;
          userData.accountId = response.accountResultDto.accountId;
          userData.fl = response.accountResultDto.fl as boolean;
          this._storeLibrary.setUserData(userData);
          this.formLogIn.controls['email'].setValue(this.formSignUpPreLoadedStep1.controls['email'].value);
          this.state = LoginState.LOG_IN;
        }

      } else {
        // SI ES UN USUARIO NUEVO

        const universalCanAccept = this._storeLibrary.getJustOneUniversalPropertie(keysUniversal.app_can_accept_new_register);
        // const canAcceptNewRegister = Boolean(JSON.parse(universalCanAccept));
        const canAcceptNewRegister = this._general.stringToBoolean(universalCanAccept);

        if (canAcceptNewRegister) {
          const authGetAccount2Request = new AuthGetAccount2Request();
          authGetAccount2Request.channelId = this._storeLibrary.getJustOneUniversalPropertie(keysUniversal.app_channel_id_mr) !== '' ? +this._storeLibrary.getJustOneUniversalPropertie(keysUniversal.app_channel_id_mr) : +this._storeLibrary.getJustOneUniversalPropertie(keysUniversal.app_channel_id);
          authGetAccount2Request.language = this.translateService.currentLang;
          authGetAccount2Request.email = this.formSignUpPreLoadedStep1.controls['email'].value;
          authGetAccount2Request.authenticationToken = '';
          authGetAccount2Request.certificateNumber = '';
          authGetAccount2Request.validateUser = true;
          authGetAccount2Request.security = SecurityObject.getSecurityObject();
          this._authService.getAccount2V2(authGetAccount2Request, null, this.useJwtToken).subscribe(
            (res) => {
              this.loading = false;
              this.state = LoginState.SIGN_UP_PRE_REGISTERED_SUCCESSFUL;
              this.next();
            },
            (err) => {
              this.loading = false;
              this.activateError = true;
              this.formLogIn.controls['email'].setValue(this.formSignUpPreLoadedStep1.controls['email'].value);
              this.state = LoginState.LOG_IN;

            });
        } else {
          this.loading = false;
          this.formLogIn.controls['email'].setValue(this.formSignUpPreLoadedStep1.controls['email'].value);
          this.state = LoginState.LOG_IN;

        }
      }
    } else {
      this.loading = false;
      this.activateError = true;
      this.state = LoginState.PRE_REGISTERED_CASE_4;
    }
  }


  serviceChangePassword() {
    const userData: UserData = this._storeLibrary.getUserData();
    userData.password = this.formLogIn.get('password').value;
    userData.newPassword = this.formMustChangePass.get('newPwd').value;
    userData.confirmPassword = this.formMustChangePass.get('confirmPwd').value;
    this._storeLibrary.setUserData(userData);
    const that = this;
    this._authService.changePassword().subscribe((res) => {
      that.handleSuccessChangePassword();
    },
      (err) => {
        if (err) {
          if (err.code === '200') {
            that.handleSuccessChangePassword();
          } else {
            const strCodesBacked = that._storeLibrary.getJustOneUniversalPropertie(keysUniversal.app_codes_to_show_of_backend);
            if (strCodesBacked) {
              const codes: any = JSON.parse(strCodesBacked);
              for (const codeToShow of codes.codesToShowAuth) {
                if (err.code === codeToShow.code) {
                  that.message = err.message;
                  that.invalidPass = true;
                  break;
                }
              }
            }
          }
        }

      });
  }

  openGetCard() {
    this.dialogRef.close(true);
    const userData = this._storeLibrary.getUserData();
    this.loadCardConfirm = false;
    // this.data.token = userData.authenticationToken;
    this.dataService.token = userData.authenticationToken;
    const dialogRef = this.dialog.open(CredomaticCardsComponent, {
      panelClass: 'onepocket-credomatic',
      id: 'credomatic-cards'
    });

    this.loadingScreenService.startLoading();

    dialogRef.afterClosed().subscribe(() => {
    });

  }

  handleSuccessChangePassword() {
    this.isChangePass = false;
    const userData: UserData = this._storeLibrary.getUserData();
    userData.password = this.password;
    userData.newPassword = '';
    userData.confirmPassword = '';
    this._storeLibrary.setUserData(userData);
    this.loading = false;
    this.state = LoginState.LOG_IN_SUCCESSFUL;
    this.isErrorLogin = false;
    this.next();
  }

  async openPopoverError(dataResponse: any) {

    const heightPadding = this.storeService.isMobileDevice ? '0px' : '40px';
    const widthPadding = this.storeService.isMobileDevice ? '0px' : '16vw';

    this.dialog.open(AlertErrorComponent, {
      maxWidth: '50vw',
      width: 'calc(50vw - ' + widthPadding + ')',
      height: 'calc(50vh - ' + heightPadding + ')',
      minHeight: '59vh',
      data: {
        tittle: dataResponse.titulo,
        error: dataResponse.message,
        label_button_app: dataResponse.label_button_app,
        action_button_web: dataResponse.action_button_web
      }
    });
  }

}
