<div class="">
  <p>{{ room.Name }}. {{ room.Name }}</p>
  <div class="clearfix"></div>
  <div class="button-container mt-1 ps-1" *ngIf="style == 2">
    <button class="btn-travel">
      Ver Detalle del Hotel
    </button>
  </div>
  <div class="tripadvisor-rating text-center col-md-6 col-sm-6 ps-0 mt-1">
    <small class="">Muy Bueno</small>
    <img class="tripadvisor-img" src="http://www.tripadvisor.com/img/cdsi/img2/ratings/traveler/3.5-39958-4.png" alt="">
  </div>
  <div class="clearfix"></div>

  <div class="col-md-12 col-sm-12 hidden-xs services ps-0 mt-1">
    <div class="alert-text-container">
      <i [ngClass]="'travel-icon ' + iconClass" aria-hidden="true" style="font-size: 12px!important;"></i>
      <p style="font-size: 12px;">  {{ refundable }}</p>
    </div>
  </div>

  <div class="clearfix">
    <p class="amenity" title="Desayuno incluido"><i class="travel-icon icon-breakfast" aria-hidden="true"></i></p>
    <p class="amenity" title="Wifi"><i class="travel-icon icon-wifi" aria-hidden="true"></i></p>
  </div>
</div>
