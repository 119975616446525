import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';

@Component({
  selector: 'app-hotel-card',
  templateUrl: './hotel-card.component.html',
  styleUrls: ['./hotel-card.component.scss']
})
export class HotelCardComponent implements OnInit, OnChanges {

  @Input() hotel: any;
  @Input() style = 1;

  infoContainerClass = (this.style === 1) ? 'col-md-4 col-sm-4' : 'col-md-6 col-sm-6';
  constructor() {
  }

  ngOnInit() {
  }
  ngOnChanges(changes: SimpleChanges) {
    this.infoContainerClass = (this.style === 1) ? 'col-md-4 col-sm-4' : 'col-md-6 col-sm-6';
  }
}
