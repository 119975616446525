import { CardLayoutThreeComponent } from './../card-layout-three.component';
import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { formatDate } from '@angular/common';
import { locale } from 'moment';

@Component({
  selector: 'app-radio-option',
  templateUrl: './radio-option.component.html',
  styleUrls: ['./radio-option.component.scss']
})
export class RadioOptionComponent implements OnInit {

  translationsDate = [];

  get selected() {
    return JSON.stringify(this.options.selectedRadioOption);
  }

  get radioOptions() {
    return this.layout.product.radioOptions;
  }

  get options() {
    return this.layout.options;
  }

  constructor(
    private layout: CardLayoutThreeComponent
  ) { }

  ngOnInit() {
    this.translationsDate = this.layout.translationsDate;
  }

  selectMeetingPoint(ev) {
    ev = JSON.parse(ev);
    this.layout.optionRadio.emit({
      product: this.layout.product,
      option: ev
    });
  }

  formatTime(time) {
    try {
      return formatDate(
        time,
        this.translationsDate['DATE']['format_weekday'],
        locale(this.layout.language)
      ).toString();
    } catch (e) {
      return time;
    }
  }

  parseValue(value) {
    return JSON.stringify(value);
  }
}
