import { IPlace, IRange, IRoomPaxes } from './searched.model';
import { BehaviorSubject } from 'rxjs';
import moment from 'moment';
import { formatDate } from '@angular/common';
import { locale } from 'moment';

export class SearchedHotel {
    destination: IPlace;
    date: IRange;
    roomsPaxes: IRoomPaxes[];

    private get translations() {
        return this.translations$.value['ISearched'] ? this.translations$.value['ISearched']['SearchedHotel'] : [];
    }

    private get translationsDate() {
        return this.translations$.value['DATE'] || [];
    }

    constructor(
        public obj: SearchedHotel,
        private translations$: BehaviorSubject<any>,
        private language$: BehaviorSubject<any>
    ) {
        this.destination = this.obj.destination;
        this.date = this.obj.date;
        this.roomsPaxes = this.obj.roomsPaxes;
    }

    /**
     * retorna cantidad de noches
     */
    get qNights(): number {
        return moment(this.date.end).diff(this.date.start, 'days');
    }

    /**
     * retorna cantidad noches más texto "noches" traducido
     */
    get qNightsToString(): string {
        return this.qNights + ' ' + (this.qNights === 1 ? this.translations['night'] : this.translations['nights']);
    }

    /**
     * retorna cantidad de huéspedes en la reserva
     */
    get qGuest(): number {
        let cantPaxes = 0;
        this.roomsPaxes.forEach(room => {
            cantPaxes += room.paxes.length;
        });
        return cantPaxes;
    }

    /**
     * retorna cantidad huéspedes más texto "noches" traducido
     */
    get qGuestToString(): string {
        return this.qGuest + ' ' + (this.qGuest === 1 ? this.translations['guest'] : this.translations['guests']);
    }

    /**
     * retorna las fechas en formato "humano legible"
     * resolviendo el formato para cada idioma
     */
    get dateToHumanReadable(): IRange {
        return {
            start: formatDate(
                this.date.start,
                this.translationsDate['format_weekday'],
                locale(this.language$.value)
            ).toString(),
            end: formatDate(
                this.date.end,
                this.translationsDate['format_weekday'],
                locale(this.language$.value)
            ).toString()
        };
    }

    get qRooms(): number {
        return this.roomsPaxes.length;
    }

    get qRoomsToString(): string {
        return this.qRooms + ' ' + (this.qRooms === 1 ? this.translations['room'] : this.translations['rooms']);
    }
}
