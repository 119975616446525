import { Slider } from './../../component/slider-op/model/slider';
import { HotelOptions } from './hotel-options.model';
import { CardLayoutTwoModel, CardInfo, CardImage } from 'src/app/component/card/models/card-layout-two.model';
import { CardPrice } from 'src/app/component/card/models/card-price.model';

export class HotelAvailabilityResponse  {
    hotelOptions: HotelAvailabilityOptions[];
    token: string;
}
export class HotelAvailabilityOptions implements CardLayoutTwoModel {

    constructor(
        public id: string | number,
        public name: string,
        public hotelCode: string,
        public latitude: string,
        public longitude: string,
        public checkIn: string,
        public checkOut: string,
        public options: HotelOptions[],
        public preferredRoomArrayPos?: number,
        public amenities?: HotelAmenity[],
        public attributes?: string[],
        public address?: string,
        public description?: string,
        public category?: string,
        public info?: CardInfo[],
        public images?: CardImage[],
        public price?: CardPrice,
        public selectedOption?: HotelOptions,
        public bookingRules?: any
    ) {  }

    static toArray(values: HotelAvailabilityOptions[]): HotelAvailabilityOptions[] {
        values.forEach((v, i) => {
            // Formatear data de habitaciones para mostrar en CardLayout
            v.options = HotelOptions.toArray(v.options, v.info);

            // Setear option[0] como preseleccionada
            v.selectedOption = v.options[0];

            values[i] = new HotelAvailabilityOptions(
                v.id,
                v.name,
                v.hotelCode,
                v.latitude,
                v.longitude,
                v.checkIn,
                v.checkOut,
                v.options,
                v.preferredRoomArrayPos,
                v.amenities,
                v.attributes,
                v.address,
                v.description,
                v.category,
                v.info,
                v.images,
                v.price,
                v.selectedOption
            );
        });
        return values;
    }

    nameSelected = () => ( this.selectedOption.rooms[0].name );
    subnameSelected = () => ( this.selectedOption.board );
    getMoneyAmount = () => (
        (!this.selectedOption.price.calculatedPrice && this.selectedOption.price.pointsPrice)
        || this.selectedOption.price.calculatedPrice
        ? this.selectedOption.price.calculatedPrice : this.selectedOption.price.totalPrice )
    getPointAmount = () => ( this.selectedOption.price.pointsPrice ? +this.selectedOption.price.pointsPrice : 0 );
    refundable = () => ( (this.selectedOption.cancellationPolicy.refundable === 'Y') ? true : false );

    priceConversion = (conversionRate, percent, userPoints) => {
        this.options = Slider.getPriceConversion(this.options, conversionRate, percent, userPoints);
    }
}

export interface HotelAmenity {
    type: string;
    value: string;
}

