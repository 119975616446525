<div [ngClass]="dataStore.brand + ' ejemplo'">
    <h1>Detalle de su protección de viaje</h1>
    <h2 id="titulo">{{data.productName}}</h2>
    <div class="closeBtn" (click)="onNoClick()" style="cursor: pointer; text-align: end">
      <img src="../../../../assets/images/icon-close.svg">
    </div>
  <div mat-dialog-content class="popUpGeneralFontColor">
    <mat-accordion>
      <mat-expansion-panel  class="mb-3" *ngFor="let benefitGroup of data.benefitGroups">
        <mat-expansion-panel-header>
          <mat-panel-title>
            {{benefitGroup.groupName | titlecase }}
          </mat-panel-title>
        </mat-expansion-panel-header>

        <div *ngFor="let benefit of benefitGroup.benefits">
          <div class="benefitRow">
            <div class="benefitDetails">
              <h6 class="tituloBenefits">{{benefit.benefitName}}</h6>
              <span class="benefitDescriptions">{{benefit.benefitDescription}}<br>
              {{benefit.limit}}</span>
            </div>
            <!-- <div class="benefitValue">
              {{benefit.config}}
            </div> -->
          </div>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</div>