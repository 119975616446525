import {Component, Input, OnInit} from '@angular/core';
import { HotelCardComponent } from '../hotel-card.component';

@Component({
  selector: 'app-hotel-price',
  templateUrl: './hotel-price.component.html',
  styleUrls: ['./hotel-price.component.scss']
})
export class HotelPriceComponent implements OnInit {

  price = '';
  nights = 0;
  travellers = 0;
  constructor(private cardComponent: HotelCardComponent) { }

  ngOnInit() {
    this.price = this.cardComponent.hotel.Rooms[0].Board[0].TotalPrice;
    this.nights = 4;
    this.travellers = 2;
  }

}
