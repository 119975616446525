<div id="top"></div>
<div class="d-app display-none" *ngIf="hasHeaderDownload && !isAlleMobile &&
!router.url.includes('/searcher') &&
!router.url.includes('flights-mobile') &&
!router.url.includes('/hotels-mobile') &&
!ocultadorService.ocultar && !this.router.url.includes('/travel-app')">
  <a class="d-app-close" (click)="hiddenHeaderDownload()">X</a>
  <div class="wp-icon"><img class="icon-app" src="../assets/images/{{brand}}-miles/{{logo}}"></div>
  <div class="text-app">
    <h3>{{appName}}</h3><span innerHTML="{{'NAV.downloadApp'|translate}}">Download app</span>
  </div>
  <div class="wp-btn">
    <button class="btn" id="btn-install-app" type="button" name="button" (click)="downloadapp()"
      innerHTML="{{'NAV.install'|translate}}">Install</button>
  </div>

</div>

<!-- <header *ngIf="!isAlleMobile &&
  !router.url.includes('/searcher') &&
  !router.url.includes('flights-mobile') &&
  !router.url.includes('/hotels-mobile') &&
  !ocultadorService.ocultar &&
  !this.router.url.includes('/travel-app') &&
  !this.router.url.includes('/onepocket-benefits') &&
  !this.router.url.includes('/myrewards-salesdeck') &&
  brand !== 'lifeMilesTravel'" class="container-fluid" [ngClass]="{'fixed': (fixed || router.url.includes('/myProfile'))}"> -->
<header *ngIf="showHeader" class="container-fluid" [ngClass]="{'fixed': (fixed || router.url.includes('/myProfile'))}">
  <div class="container">

    <div class="bg-logo" (click)="redirectFligths()">
      <a class="logo"></a>
    </div>
    <div class="barra-top">
      <app-hamburger></app-hamburger>
    </div>

    <nav class="menu-principal" [ngClass]="{'about-nav': tabSelect === EProduct.ABOUT}" *ngIf="!isNewMenu">
      <ul class="navbar">
        <li class="nav-item vuelos" [ngClass]="{active: tabSelect === EProduct.FLIGHTS}">
          <a class="nav-link" (click)="tabSelected(EProduct.FLIGHTS)" routerLink="/home"
            innerHTML="{{'NAV.flights'|translate}}">
            Flights
          </a>
        </li>
        <li class=" nav-item hoteles" [ngClass]="{active: tabSelect === EProduct.HOTELS}">
          <a class="nav-link" routerLink="/home" (click)="tabSelected(EProduct.HOTELS)"
            innerHTML="{{'NAV.hotels'|translate}}">
            Hotels
          </a>
        </li>
        <li class=" nav-item autos" [ngClass]="{active: tabSelect === EProduct.CARS}">
          <a class="nav-link" (click)="tabSelected(EProduct.CARS)" routerLink="/home"
            innerHTML="{{'NAV.cars'|translate}}">
            Cars
          </a>
        </li>
        <li class=" nav-item actividades" [ngClass]="{active: tabSelect === EProduct.ACTIVITIES}">
          <a class="nav-link" *ngIf="hasActivities" (click)="tabSelected(EProduct.ACTIVITIES)" routerLink="/home"
            innerHTML="{{'NAV.activities'|translate}}">
            Activities
          </a>
        </li>
        <li class=" nav-item beneficios" [ngClass]="{active: tabSelect === EProduct.BENEFITS}">
          <a class="nav-link" *ngIf="hasBenefits" (click)="tabSelected(EProduct.BENEFITS)" routerLink="/home"
            innerHTML="{{'NAV.benefits'|translate}}">
            Benefits
          </a>
        </li>
        <li class=" nav-item about" [ngClass]="{active: tabSelect === EProduct.ABOUT}" routerLinkActive="active">
          <a class="nav-link about-ncb" *ngIf="hasAbout" (click)="tabSelected(EProduct.ABOUT)" routerLink="/about"
            innerHTML="{{'NAV.about'|translate}}">
            About Miles
          </a>
        </li>
      </ul>
    </nav>

    <nav class="menu-principal" [ngClass]="{'about-nav': tabSelect === EProduct.ABOUT}" *ngIf="isNewMenu">
      <ul class="navbar">

        <li pageScroll href="#top" class="nav-item about-fc" *ngFor="let item of optionMenu"
          [ngClass]="{active: item.tabSelect == tabSelectData }">
          <a class="nav-link" id="buttons_header" (click)="tabSelected(item.tabSelect, item.router)"
            innerHTML="{{item.label|translate: '{appName:\'' + appName +'\'}'}}">
            Home
          </a>
        </li>
      </ul>
    </nav>

  </div>
</header>

<header *ngIf="!isAlleMobile &&
!router.url.includes('/searcher') &&
!router.url.includes('flights-mobile') &&
!router.url.includes('/hotels-mobile') &&
!ocultadorService.ocultar &&
!this.router.url.includes('/travel-app') &&
brand === 'lifeMilesTravel'">
  <div class="container">
    <div class="bg-logo" (click)="redirectToHomeWithParam()">
      <a class="logo"></a>
      <div class="bl-lm" *ngIf="loadInfoLM">
        <div class="btn-lm" (click)="goToLoginLM()"
          innerHTML="{{'LANDING.'+brand+'.CERTIFICATES_USER.new_search' | translate}}">Nueva búsqueda</div>
        <div class="points-lm">
          <p>{{ userLifeMiles.balance | milesFormatEn: true }}</p>
          <p>{{ userLifeMiles.accountSumaryDto.firstName }}</p>
        </div>
      </div>
    </div>
  </div>
</header>