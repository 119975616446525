
<section class="s-flex morepower" id="power">
    <div class="container">
        <div class="bl-row">
            <div class="bl-left">
                <h2 class="text-gradient" innerHTML="{{'LANDING.'+brand+'.SECTION_THREE.SECTION_MPOWER.power_wallet_title' | translate}}"></h2>
                <p innerHTML="{{'LANDING.'+brand+'.SECTION_THREE.SECTION_MPOWER.text_intro_1' | translate}}"></p>
                <button pageScroll href="#bl-open" class="btn button" (click)="openSection()">{{'LANDING.'+brand+'.SECTION_THREE.SECTION_MPOWER.btnLeranMore' | translate}}</button>
            </div>

            <div class="bl-right">
                <img class="imagen-video" alt="" [src]="imgPower"> 
            </div>
        </div>

        <ul class="logos-coming logos-fc" *ngIf="brand ==='firstcitizens' || brand ==='myrewards' || brand === 'cibc' || brand === 'apap' || brand === 'bancoindustrial' || brand ==='ncb'" >
            <li><img src="./assets/images/{{brand}}-miles/logo-paypal.png" alt="mr"></li>
            <li><img src="./assets/images/{{brand}}-miles/logo-pay.png" alt="mr"></li>
            <li><img src="./assets/images/{{brand}}-miles/logo-samsung.png" alt="mr"></li>
            <li><img src="./assets/images/{{brand}}-miles/logo-g-pay.png" alt="mr"></li>
            <li><img src="./assets/images/{{brand}}-miles/logo-amazonpay.png" alt="mr"></li>
            <li><img src="./assets/images/{{brand}}-miles/logo-visapay.png" alt="mr"></li>
        </ul>

    </div>
</section>

<!--END NUEVA SECCION-->


<div class="bl-open" id="bl-open">
    <section class="s-more-power">
        <div pageScroll href="#power" class="arrow-up" (click)="closeSection()"></div>
        <div class="container">
            <h1 *ngIf="brand ==='ncb' || brand ==='firstcitizens' " class="general-three text-gradient" innerHTML="{{'LANDING.'+brand+'.SECTION_THREE.SECTION_MPOWER.power_wallet_title' | translate}}"></h1>
    
            <h3 *ngIf="brand ==='ncb' || brand ==='firstcitizens' " class="text-intro" innerHTML="{{'LANDING.'+brand+'.SECTION_THREE.SECTION_MPOWER.text_intro_1' | translate}}"></h3>

            <mat-tab-group (selectedTabChange)="selectedChangePowerMat(powermatgroup)" #powermatgroup class="paypal" mat-align-tabs="center">
                <mat-tab label="{{'LANDING.'+brand+'.SECTION_THREE.SECTION_MPOWER.tap1' | translate}}">
                    <div class="bl-row">
                        <div class="bl-left">
                            <ul class="items-number">
                                <li><span>{{'LANDING.'+brand+'.SECTION_FIRST.SECTION1.1' | translate}}</span>
                                    <p innerHTML="{{'LANDING.'+brand+'.SECTION_THREE.SECTION_MPOWER.paypal_list_1' | translate}}"></p>
                                </li>
                                <li><span>{{'LANDING.'+brand+'.SECTION_FIRST.SECTION1.2' | translate}}</span>
                                    <p innerHTML="{{'LANDING.'+brand+'.SECTION_THREE.SECTION_MPOWER.paypal_list_2' | translate}}"></p>
                                </li>
                                <li><span>{{'LANDING.'+brand+'.SECTION_FIRST.SECTION1.3' | translate}}</span>
                                    <p innerHTML="{{'LANDING.'+brand+'.SECTION_THREE.SECTION_MPOWER.paypal_list_3' | translate}}"></p>
                                </li>
                                <li><span>{{'LANDING.'+brand+'.SECTION_FIRST.SECTION1.4' | translate}}</span>
                                    <p innerHTML="{{'LANDING.'+brand+'.SECTION_THREE.SECTION_MPOWER.paypal_list_4' | translate}}"></p>
                                </li>
                                <li><span>{{'LANDING.'+brand+'.SECTION_FIRST.SECTION1.5' | translate}}</span>
                                    <p innerHTML="{{'LANDING.'+brand+'.SECTION_THREE.SECTION_MPOWER.paypal_list_5' | translate}}"></p>
                                </li>
                            </ul>
                        </div>
                        <div class="bl-right">
                            <div class="bloque-video">
                                <div class="iphone-video">
                                    <video class="video-vimeo" loop muted playsinline autoplay oncanplay="this.play()" onloadedmetadata="this.muted=true"> 
                                        <source *ngIf="objToLoadVideo.one" [src]="urlsVideos.one.url | srcSanitizer"  type="video/mp4">
                                    </video>
                                </div>
                                <img class="imagen-video" src="./assets/images/{{brand}}-miles/bg-iphone-miles.png" alt="">
                            </div>
                        </div>
                    </div>
                </mat-tab>

                <mat-tab label="{{'LANDING.'+brand+'.SECTION_THREE.SECTION_MPOWER.tap2' | translate}}">
                    <div class="bl-row">
                        <div class="bl-left">
                            <ul class="items-number">
                                <li><span>{{'LANDING.'+brand+'.SECTION_FIRST.SECTION1.1' | translate}}</span>
                                    <p innerHTML="{{'LANDING.'+brand+'.SECTION_THREE.SECTION_MPOWER.paypal_list_1' | translate}}"></p>
                                </li>
                                <li><span>{{'LANDING.'+brand+'.SECTION_FIRST.SECTION1.2' | translate}}</span>
                                    <p innerHTML="{{'LANDING.'+brand+'.SECTION_TWO.SECTION_GSHOPPING.list_shopping_1' | translate}}"></p>
                                </li>
                                <li><span>{{'LANDING.'+brand+'.SECTION_FIRST.SECTION1.3' | translate}}</span>
                                    <p innerHTML="{{'LANDING.'+brand+'.SECTION_THREE.SECTION_MPOWER.paypal_list_3' | translate}}"></p>
                                </li>
                                <li><span>{{'LANDING.'+brand+'.SECTION_FIRST.SECTION1.4' | translate}}</span>
                                    <p innerHTML="{{'LANDING.'+brand+'.SECTION_THREE.SECTION_MPOWER.paypal_list_4' | translate}}"></p>
                                </li>
                                <li><span>{{'LANDING.'+brand+'.SECTION_FIRST.SECTION1.5' | translate}}</span>
                                    <p innerHTML="{{'LANDING.'+brand+'.SECTION_THREE.SECTION_MPOWER.paypal_list_5' | translate}}"></p>
                                </li>
                            </ul>
                        </div>
                        <div class="bl-right">
                            <div class="bloque-video">
                                <div class="iphone-video">
                                    <video class="video-vimeo" loop muted playsinline autoplay oncanplay="this.play()" onloadedmetadata="this.muted=true"> 
                                        <source *ngIf="objToLoadVideo.two" [src]="urlsVideos.two.url | srcSanitizer"  type="video/mp4">
                                    </video>
                                </div>
                                <img class="imagen-video" src="./assets/images/{{brand}}-miles/bg-iphone-miles.png" alt="">
                            </div>
                        </div>
                    </div>
                </mat-tab>

                <mat-tab label="{{'LANDING.'+brand+'.SECTION_THREE.SECTION_MPOWER.tap3' | translate}}">
                    <div class="bl-row">
                        <div class="bl-left">
                            <ul class="items-number">

                                <li><span>{{'LANDING.'+brand+'.SECTION_FIRST.SECTION1.1' | translate}}</span>
                                    <p innerHTML="{{'LANDING.'+brand+'.SECTION_THREE.SECTION_MPOWER.samsung_list_1' | translate}}"></p>

                                </li>
                                <li><span>{{'LANDING.'+brand+'.SECTION_FIRST.SECTION1.2' | translate}}</span>
                                    <p innerHTML="{{'LANDING.'+brand+'.SECTION_TWO.SECTION_GSHOPPING.list_shopping_1' | translate}}"></p>
                                </li>
                                <li><span>{{'LANDING.'+brand+'.SECTION_FIRST.SECTION1.3' | translate}}</span>
                                    <p innerHTML="{{'LANDING.'+brand+'.SECTION_THREE.SECTION_MPOWER.paypal_list_3' | translate}}"></p>
                                </li>
                                <li><span>{{'LANDING.'+brand+'.SECTION_FIRST.SECTION1.4' | translate}}</span>
                                    <p innerHTML="{{'LANDING.'+brand+'.SECTION_THREE.SECTION_MPOWER.paypal_list_4' | translate}}"></p>
                                </li>
                                <li><span>{{'LANDING.'+brand+'.SECTION_FIRST.SECTION1.5' | translate}}</span>
                                    <p innerHTML="{{'LANDING.'+brand+'.SECTION_THREE.SECTION_MPOWER.paypal_list_5' | translate}}"></p>
                                </li>
                            </ul>
                        </div>
                        <div class="bl-right">
                            <div class="bloque-video samsung-bloque">
                                <div class="iphone-video">
                                    <video class="video-vimeo" loop muted playsinline autoplay oncanplay="this.play()" onloadedmetadata="this.muted=true"> 
                                        <source *ngIf="objToLoadVideo.three" [src]="urlsVideos.three.url | srcSanitizer"  type="video/mp4">
                                    </video>
                                </div>
                                <img class="imagen-video" src="./assets/images/bg-samsung.png" alt="">
                            </div>
                        </div>
                    </div>
                </mat-tab>
            </mat-tab-group>
        </div>
    </section>

    <section class="available">
        <div class="container">
            <div class="bl-row">
                <div class="bl-left">
                    <img class="img-watch" src="./assets/images/{{brand}}-miles/bg-watch.png" alt="mr">
                </div>

                <div class="bl-right">
                    <h3>{{'LANDING.'+brand+'.SECTION_THREE.SECTION_AVAILABLE.available' | translate}}</h3>
                    <img class="logos" src="./assets/images/{{brand}}-miles/logo-gpay.png" alt="mr">
                </div>
            </div>
        </div>
    </section>

</div>


<section class="section-unlimited" *ngIf="brand ==='firstcitizens'">
    <div class="container">
        <div class="bl-row">
            <div class="bl-left animated animation-left delaytwo">
                <h2 class="text-gradient"><strong>{{'LANDING.'+brand+'.SECTION_TWO.SECTION_UNLIMITED.get_physical_card' | translate}}</strong></h2>
                <ul class="icons">
                    <li class="icon-first">
                        <p innerHTML="{{'LANDING.'+brand+'.SECTION_TWO.SECTION_UNLIMITED.desc_1' | translate}}"></p>
                    </li>
                    <li class="icon-wtp">
                        <p><strong>{{'LANDING.'+brand+'.SECTION_TWO.SECTION_UNLIMITED.icon_whats_desc1' | translate}}</strong>{{'LANDING.'+brand+'.SECTION_TWO.SECTION_UNLIMITED.icon_whats_desc2' | translate}}</p>
                    </li>
                    <li class="icon-cards">
                        <p><strong>{{'LANDING.'+brand+'.SECTION_TWO.SECTION_UNLIMITED.icon_card_desc' | translate}}</strong></p>
                        <div class="store">
                            <app-download-store [class]="'logo'"></app-download-store>
                        </div>
                    </li>
                </ul>
            </div>
            <div class="bl-right relative">
                <img class="card-first" src="./assets/images/{{brand}}-miles/img-card.png" alt="">
            </div>
        </div>
    </div>
</section>