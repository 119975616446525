import { Component, OnInit, Input, ElementRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { StoreServiceLibrary } from '@wearenovae/novae-core-services';
import { environment } from '../../../../environments/environment';
import { DataStoreService } from '../../../services/data-store.service';


@Component({
  selector: 'app-section-two',
  templateUrl: './section-two.component.html',
  styleUrls: ['./section-two.component.scss']
})
export class SectionTwoComponent implements OnInit {
  @Input() brand: string;
  urlsVideos: any = environment.brands[this.dataStore.brand].videosLandingEN.sectionTwo;
  urlsVideosFirstCitizens: any = {};
  video2
  imgPower = `./assets/images/${this.dataStore.brand}-miles/iphone-power.png`;

  objToLoadVideo = {
    one: false,
    two: false,
    three: false
  }

  constructor(private dataStore: DataStoreService,
    private store: StoreServiceLibrary,
    private translate: TranslateService,
    private el: ElementRef) { }

  ngOnInit() {
    this.changeLanguaje();
    if(this.brand==='firstcitizens'){
      this.video2= true
      if(this.video2==true){
        this.urlsVideosFirstCitizens= environment.brands[this.dataStore.brand].videosLanding.sectionTwo;
        this.urlsVideos= this.urlsVideosFirstCitizens;
        this.objToLoadVideo.one = true;
      }
           
     }
  }

  ngAfterContentInit() {
    this.changeLanguaje();

  }

  changeLanguaje() {
    this.translate.onLangChange.subscribe((data) => {
      if (data.lang === 'es') {
        this.imgPower = `./assets/images/${this.dataStore.brand}-miles/es/iphone-power.png`;
        this.urlsVideos = environment.brands[this.dataStore.brand].videosLandingES.sectionTwo;
      } else {
        this.imgPower = `./assets/images/${this.dataStore.brand}-miles/iphone-power.png`;
        this.urlsVideos = environment.brands[this.dataStore.brand].videosLandingEN.sectionTwo;
      }
    });
  }




  openSection() {
    this.objToLoadVideo.one = true;
    this.objToLoadVideo.two = true;
    this.objToLoadVideo.three = true;
    const tag = this.el.nativeElement.querySelector('.bl-open');
    const tagBtn = this.el.nativeElement.querySelector('.button');
    tag.classList.toggle('open');
    tagBtn.classList.toggle('active');
  }


  closeSection() {
    const tag = this.el.nativeElement.querySelector('.bl-open');
    const tagBtn = this.el.nativeElement.querySelector('.button');
    tag.classList.remove('open');
    tagBtn.classList.remove('active');
  }

}
