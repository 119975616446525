import { Component, OnInit, ElementRef } from '@angular/core';
import { keysUniversal, StoreServiceLibrary, AuthGetAccount2Response } from '@wearenovae/novae-core-services';
import { environment } from 'src/environments/environment';
import { DataStoreService } from '../../../services/data-store.service';
import { ActionEventService } from '../../../services/action-event.service';
import { Router } from '@angular/router';
import { LoginComponent } from '../../../container/shared/login/login.component';
import { MatDialog } from '@angular/material/dialog';
import { DataService } from '../../../services/data.service';

import data from '../../../resources/screenAndEventsTracking.json';
import { GoogleAnalytics } from '../../../resources/GoogleAnalytics.resource';

@Component({
  selector: 'app-section-third',
  templateUrl: './section-third.component.html',
  styleUrls: ['./section-third.component.scss']
})
export class SectionThirdComponent implements OnInit {
  brand = '';
  appName = '';

  profile: AuthGetAccount2Response;
  dataUser: any;

  constructor(private _storeLibrary: StoreServiceLibrary,
    private dataStoreService: DataStoreService,
    private router: Router,
    private actionEventService: ActionEventService,
    private dialog: MatDialog,
    private dataService: DataService,
    private el: ElementRef) { }

  ngOnInit() {

    this.brand = this.dataStoreService.brand.toUpperCase();
    this.appName = environment.brands[this.dataStoreService.brand].appName;
  }

  redirect() {
    // this.trackingManager.trackEventString(data.WALKTHROUGH.bookNow, data.WALKTHROUGH.walkthroughScreen);
    GoogleAnalytics.sendEventFirebase('ga_event', data.WALKTHROUGH.walkthroughScreen, data.WALKTHROUGH.bookNow, '');
    this.actionEventService.sendRedirectTo(true);
    this.router.navigateByUrl('/travel');
  }

  /**
 * menu para ingresar
 */
  openLogin() {
    // this.trackingManager.trackEventString(data.WALKTHROUGH.signInWalk, data.WALKTHROUGH.walkthroughScreen);
    GoogleAnalytics.sendEventFirebase('ga_event', data.WALKTHROUGH.walkthroughScreen, data.WALKTHROUGH.signInWalk, '');
    const dialogRef = this.dialog.open(LoginComponent, {
      panelClass: 'login-dialog'
    });

    dialogRef.afterClosed().subscribe(successful => {
      if (successful) {
        // si ingreso y/o se registró correctamente
        this.profile = this.dataService.smartProfile;
        this.actionEventService.sendRedirectTo(true);
        this.router.navigateByUrl('/home');
      }
    });
  }

  openSection() {
    const tag = this.el.nativeElement.querySelector('.benefits');
    tag.classList.add('open');
      
  }



  closeSection() {
    const tag = this.el.nativeElement.querySelector('.benefits');
    tag.classList.remove('open');
  }

}
