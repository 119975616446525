import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContactFormComponent } from './contact-form.component';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DirectivesModule } from 'src/app/directives/directives.module';


@NgModule({
  declarations: [
    ContactFormComponent,
  ],
  exports: [
    ContactFormComponent
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    TranslateModule.forChild(),
    DirectivesModule,
    CommonModule
  ]
})
export class ContactFormModule { }
