import { TranslateService } from '@ngx-translate/core';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CardLayoutOneService {

  public translations = [];
  constructor(
    private translateService: TranslateService
  ) { }

  public getTranslations() {
    return new Promise<any>((resolve, reject) => {
      this.translateService.get([
        'LAYOUT_ONE',
      ]).subscribe((trans) => {
        this.translations = trans;
        resolve(true);
      });
    });
  }

}
