import { LanguageService } from './../../language/language.service';
import { PurchaseSummaryService } from './../purchase-summary.service';
import { Component, OnInit, Input } from '@angular/core';
import { locale } from 'moment';
import { formatDate, getCurrencySymbol } from '@angular/common';
import { IActivityResult } from 'src/app/models/activities/response/activity-availability.model';

@Component({
  selector: 'app-activity-summary',
  templateUrl: './activity-summary.component.html',
  styleUrls: ['./activity-summary.component.scss']
})
export class ActivitySummaryComponent implements OnInit {

  @Input() activity: IActivityResult;

  translationsDate = [];
  translationsActivity = [];

  constructor(
    private purchaseSummaryService: PurchaseSummaryService,
    private languageService: LanguageService
  ) {
    this.translationsDate = this.purchaseSummaryService.translationDate;
    this.translationsActivity = this.purchaseSummaryService.translationsActivity;
  }

  get date() {
    return formatDate(
      this.activity.selectedOption.selectedDate.startDate, this.translationsDate['format_weekday'],
      locale(this.purchaseSummaryService.locale)).toString();
  }

  get travellers() {
    return '1 pasajero';
  }

  get address() {
    return this.activity.address;
  }

  get activityName() {
    return this.activity.selectedOption.name;
  }

  get hasMeetingPoint() {
    return (this.activity.selectedOption.selectedMeetingPoint);
  }
  get meetingPoint() {
    return this.activity.selectedOption.selectedMeetingPoint.name;
  }

  get meetingPointHour() {
    try {
      return formatDate(
        this.activity.selectedOption.selectedMeetingPoint.time, 'hh:mm',
        locale(this.purchaseSummaryService.locale)).toString();
    } catch (e) {
      return false;
    }
  }

  get cancellationPolicies() {
    if (this.activity.selectedOption.cancellationPolicy) {
      return this.activity.selectedOption.cancellationPolicy;
    } else {
      return [];
    }
  }

  get currency() {
    const currency = this.purchaseSummaryService.activity.selectedOption.selectedDate.price.currency;
    return currency + ' ' + getCurrencySymbol(currency, 'narrow');
  }

  fromDate(value) {
    return formatDate(
      value.from,
      this.translationsDate['pipe_date'],
      locale(this.languageService.systemLanguajeChange.value));
  }

  ngOnInit() {
  }

}
