<div class="no-results">
    <div class="travel_available">
        <div class="not_available">
            <div class="alert_icon"></div>
            <div class="text_ul">
                <h3>{{searchErrorType.titulo}}</h3>
                <p>{{searchErrorType.subTitulo}}</p>
                <ul>
                    <li *ngFor="let item of searchErrorType.body">
                        {{item}}
                    </li>
                </ul>
            </div>
        </div>
        <button class="search" (click)="goBack()" innerHTML="{{'NO_RESULTS.another_search'|translate}}">
            Perform another search
        </button>
    </div>
</div>