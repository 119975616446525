import { Component, OnInit, ViewChild, Input, EventEmitter, AfterContentChecked, Output, HostListener, 
  AfterContentInit, OnDestroy, AfterViewInit } from '@angular/core';
import { getCurrencySymbol, Location } from '@angular/common';
import { DataService } from '../../services/data.service';
import { InitService } from '../../services/init.service';
import { DataStoreService } from '../../services/data-store.service';
import { TranslateService } from '@ngx-translate/core';
import { StoreServiceLibrary } from '@wearenovae/novae-core-services';
import { MatDialog } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import { LoginService } from '../../container/shared/login/login.service';
import { DebounceCall } from '../../decorators/debounce.decorator';
import { LoginComponent } from '../../container/shared/login/login.component';
import { ActionEventService } from '../../services/action-event.service';
import { SmartProfileService } from '../../services/smart-profile.service';
import { keysUniversal } from 'src/app/resources/keys.resources';
import { keysPostMessage } from 'src/app/resources/keys.resources';
import { AlertErrorComponent } from '../product-detail/alert-error/alert-error.component';
import { GoogleAnalytics } from '../../resources/GoogleAnalytics.resource';
import { UtilsResource } from 'src/app/resources/UtilsResource.resource';


declare var androidProxy: any;
declare var window: any;

/**
 * objeto precio que llega por postmessage
 */
interface ITotalCost {
  valuePoints: number;
  valueCreditCard: number;
  currency: string;
  tax: string;
  insuranceValue: string;
  promotion?: {
    code?,
    miles?: {
      initValue?,
      finalValue?,
      discount?,
      percentage?
    },
    cash?: {
      initValue?,
      finalValue?,
      discount?,
      percentage?
    }
  }
}

@Component({
  selector: 'app-op-from-iframe',
  templateUrl: './op-from-iframe.component.html',
  styleUrls: ['./op-from-iframe.component.scss']
})
export class OpFromIframeComponent implements OnInit, AfterViewInit, AfterContentChecked, AfterContentInit, OnDestroy {

  @ViewChild('onePocketIframe') iframe;
  @ViewChild('loadingVideo') loadingVideo;

  @Input() iframeURL = '';
  @Input() token: string;
  @Input() enabled = false;

  @Output() outputEmiter = new EventEmitter();
  isLifeMilesTravel = false;

  showBenefits = false;
  haveBenfitsModal = false;
  voyageBenefits = false;
  loadCertificates = false;
  showLoadOP = true;
  verifyOnePurchase = true;
  useJwtToken: any;
  confirmSale: any;
  flagFixedRates = false;

  get brand() {
    return this.dataStore.brand;
  }

  contador: number;
  public loaderSlider: string;

  // @Input()
  public loaded = false;
  public moneyAmount = 0;
  public pointsAmount = 0;
  public getCurrencySymbol = getCurrencySymbol;

  private initiated = false;
  private totalCostValue = 0;
  status = false;
  purchaseStatus = false;
  public paymentStatus: boolean;
  isInitializedBrand: boolean;
  price: ITotalCost;
  typeCreditCard = '';
  currencyUniversal: any;

  @Input()
  public moneyCurrency = '';

  @Output()
  public getPorcentaje = new EventEmitter(true);

  private iframeBaseUrl: string;

  purchaceId: number = 0;
  selectedTaxes: number;
  percentageUser: any;

  openModal = false;
  product = '';

  modalOptions: {
    size: 'lg',
    ariaLabelledBy: 'modal-basic-title',
    backdrop: 'static',
    keyboard: false
  };

  clickEvent() {
    this.status = !this.status;
  }

  promotion: {
    code?,
    fechafin?,
    percentage?,
    currency?
  } = {};

  countryCredomatic;

  language: string;

  benefitsImages = {
    en: {
      image1: '../assets/images/banner-benefits/en/banner-traveling-1.png',
      image2: '../assets/images/banner-benefits/en/banner-traveling-2.png',
      image3: '../assets/images/banner-benefits/en/banner-traveling-3.png',
    },
    es: {
      image1: '../assets/images/banner-benefits/es/banner-traveling-1.png',
      image2: '../assets/images/banner-benefits/es/banner-traveling-2.png',
      image3: '../assets/images/banner-benefits/es/banner-traveling-3.png',
    }
  }

  showBenefitsComponent: boolean = false;


  constructor(
    public data: DataService,
    private initService: InitService,
    public dataStore: DataStoreService,
    private translate: TranslateService,
    private router: Router,
    private loginService: LoginService,
    private dialog: MatDialog,
    private storeServiceLibrary: StoreServiceLibrary,
    private eventService: ActionEventService,
    private location: Location,
    private smartProfile: SmartProfileService,
    private translateService: TranslateService,
    private activateRouter: ActivatedRoute
  ) {
    this.language = this.translate.currentLang;
    const objStateParams = this.location.getState();
    this.purchaceId = objStateParams['purchaceId'];
    // this.purchaceId = 138444;
    this.selectedTaxes = objStateParams['selectedTaxes'];
    this.percentageUser = objStateParams['userPorcentage'];

    if (this.dataStore.brand === 'scotia') {
      this.product = objStateParams['product'];
      this.flagFixedRates = objStateParams['flagFixedRates'];
    }

    if (this.brand === 'lifeMilesTravel') {
      this.loadCertificates = true;
      this.showLoadOP = false;
      this.storeServiceLibrary.setItemInStorage('purchaceId', this.purchaceId);
      this.storeServiceLibrary.setItemInStorage('globalTaxes', this.selectedTaxes);
    }

    this.eventService.ifChangeLogin.subscribe((successfull) => {
      if (successfull) {
        this.token = this.data.token;
        if (this.purchaceId !== undefined) {
          // si ingreso y/o se registró correctamente
          this.enabled = true;
          this.loaded = false;
        } else {
          this.enabled = false;
          this.router.navigateByUrl('travel');
        }
      } else {
        this.enabled = false;
      }
    });

  }

  ngOnInit() {
    this.initService.isInitializedBrand.subscribe(isInitialized => {
      if (isInitialized) {
        this.initiated = true;
        this.loaded = false;
        this.enabled = true;
        this.iframeURL = this.dataStore.iframeUrl;
        // this.iframeURL = 'http://localhost:8100/#/onepocket?channelID=16&applicationID=31&language=en"';
        const pathArray = this.iframeURL.split('/');
        const protocol = pathArray[0];
        const host = pathArray[2];
        this.iframeBaseUrl = protocol + '//' + host;
        this.currencyUniversal = JSON.parse(
          this.storeServiceLibrary.getJustOneUniversalPropertie(
            keysUniversal.app_default_currency
          )
        );
        this.changeLanguage();
        this.countryCodeChange();
        this.codePromotionChange();

        if (this.dataStore.brand == 'credomatic') {
          if (this.storeServiceLibrary.getItemFromStorage('CountrySelected')) {
            this.countryCredomatic = this.storeServiceLibrary.getItemFromStorage('CountrySelected');
          }
        }

        let configOptionsTravel = this.data.getConfigPropertiesTravel();
        if (configOptionsTravel) {
          this.showBenefitsComponent = configOptionsTravel.showBenefitsInfo;
        }

/*     if (this.data.isShowBenefitsComponent()) {
          this.showBenefitsComponent = true;
        }  */
        // this.getPromo(TypePromotions.code);
        //TO-DO PROVISIONAL
        // let strBool = this.storeServiceLibrary.getJustOneUniversalPropertie(keysUniversal.web_show_code_promotion);
        // this.showCodePromotion = this.stringToBoolean(strBool);
        //TO-DO PROVISIONAL
      }
    });

    if (this.dataStore.brand == 'credomatic') {
      this.haveBenfitsModal = true;
    }

    this.isLifeMilesTravel = this.dataStore.brand === 'lifeMilesTravel' || false;
    this.useJwtToken = this.data.getJwtUse();
  }

  ngAfterContentInit() {
    this.validateUserToken();
  }

  ngAfterContentChecked() {
    this.definedLangLoader();
  }

  codePromotionChange() {
    this.eventService.changeInfoPromotion.subscribe((infoPromotionObj) => {
      if (this.dataStore.brand == 'credomatic') {
        let message = {
          typeMessage: keysPostMessage.changeInfoPromotion,
          infoPromotion: infoPromotionObj
        };
        const iframe = document.getElementById('iframe_onepocket');
        if (iframe) {
          this.sendPostMessage(iframe, message, this.iframeBaseUrl);
        }
      }
    });
  }

  countryCodeChange() {
    this.eventService.countryCodeChange.subscribe((countryCodeChange) => {
      //
      let message = {
        'typeMessage': keysPostMessage.changeCredomaticCity,
      };
      if (this.dataStore.brand == 'credomatic') {
        if (countryCodeChange?.code) {
          this.countryCredomatic = countryCodeChange.code;
          message['countryCredomatic'] = countryCodeChange.code;
        }
      }
      const iframe = document.getElementById('iframe_onepocket');
      if (iframe) {
        this.sendPostMessage(iframe, message, this.iframeBaseUrl);
      }
      //
      // this.getPromo(TypePromotions.code);
      //
    });
  }

  // getPromo(typePromo: TypePromotions) {
  //   let userData: UserData = this.storeServiceLibrary.getUserData()
  //   //Llamar aservicio de obtener programas
  //   let requestGetPrograms = new GetProgramsRequest();
  //   requestGetPrograms.userToken = userData.authenticationToken;
  //   requestGetPrograms.quantityPoints = 0;
  //   this.codePromotionsService.getPrograms(requestGetPrograms).subscribe(
  //     (respGetPrograms: any) => {

  //       if (respGetPrograms?.operationResult?.code == 200 && respGetPrograms?.programsList) {
  //         let programIdList = [];
  //         for (const itr of respGetPrograms?.programsList) {
  //           programIdList.push(itr.programid);
  //         }
  //         /////////////////////////////////////////////
  //         let requestPromotion = new GetPromotionsByUserRequest();
  //         requestPromotion.lenguaje = this.translateService.currentLang;
  //         requestPromotion.programId = programIdList.join(",");
  //         requestPromotion.channelId = this.data.getChannel().toString();
  //         requestPromotion.country = this.countryCredomatic;
  //         requestPromotion.token = userData.authenticationToken;
  //         requestPromotion.type = typePromo;
  //         requestPromotion['consult'] = true

  //         this.promotion = {};
  //         this.codePromotionsService.getPromotionsByUser(requestPromotion).subscribe((resp: GetPromotionsByUserResponse) => {

  //           if (resp?.transactionResult?.operationResult?.code == '200') {
  //             this.promotion.code = resp?.transactionResult?.promotion?.code;
  //             this.promotion.fechafin = resp?.transactionResult?.promotion?.fechafin;
  //             this.promotion.percentage = resp?.transactionResult?.promotion?.percentage;
  //             this.promotion.currency = resp?.transactionResult?.promotion?.currency;
  //           } else {
  //           }
  //         }, (err) => {
  //         });
  //       } else {
  //       }
  //     },
  //     (err) => {
  //     }
  //   );
  // }

  definedLangLoader() {
    if (this.translate.currentLang.valueOf() === 'en') {
      if (this.dataStore.brand === 'scotia') {
        if (this.dataStore.creditCardDefult === 'amex') {
          this.loaderSlider = '../../../assets/images/scotia/gif-loader-scotia-amex.gif';
        } else {
          this.loaderSlider = '../../../assets/images/scotia/gif-loader-scotia-non-amex.gif';
        }
      } else if (this.dataStore.brand === 'myrewards') {
        this.loaderSlider = '../../../assets/images/myrewards-miles/loaders/loader-slide-en.gif';
      } else if (this.dataStore.brand === 'cibc') {
        this.loaderSlider = '../../../assets/loader/videoLoaderOP-CIBC-MR_EN.gif';
      } else if (this.dataStore.brand === 'lifeMilesTravel') {
        this.loaderSlider = '../../../assets/loader/Loader_LifeMiles_en.gif';
      } else {
        this.loaderSlider = '../../../assets/loader/Loader_LifeMiles_en.gif';
      }
    } else {
      if (this.dataStore.brand === 'myrewards') {
        this.loaderSlider = '../../../assets/images/myrewards-miles/loaders/loader-slide-es.gif';
      } else if (this.dataStore.brand === 'cibc') {
        this.loaderSlider = '../../../assets/loader/videoLoaderOP-CIBC-MR_EN.gif';
      } else if (this.dataStore.brand === 'lifeMilesTravel') {
        this.loaderSlider = '../../../assets/loader/Loader_LifeMiles_es.gif';
      } else {
        this.loaderSlider = '../../../assets/loader/Loader_LifeMiles_es.gif';
      }
    }
  }

  async playVideo() {
    await this.loadingVideo.nativeElement.play();
  }

  ngAfterViewInit() {
    /*     if (this.showLoadOP) {
          this.scrollIframe();
        } */
  }

  scrollIframe() {
    const elementSearch = document.getElementById('container-before-op');
    elementSearch.scrollIntoView({ behavior: 'smooth' });
  }

  @HostListener('window:message', ['$event'])
  receiveMessage(event: any) {
    if (event.data.typeMessage === 'INIT_ONEPOCKET_PAGE') {
      /* POSTMESSAGGE INICIAL DE ONEPOCKET */
      this.sendToken();
    } else if (event.data.typeMessage === 'SUCCESS_TOKEN_AUTH') {
      this.sendPurchaceID();
      this.loaded = true;
    } else if (event.data.typeMessage === 'TERM_AND_CONDITION_ONEPOCKET') {
      /* POSTMESSAGGE AL PULSAR 'VER TERMINOS Y CONDICIONES' DE ONEPOCKET */
      this.onePocketTerms(event.data);
    } else if (event.data.typeMessage === 'PAYMENT_RESPONSE_ONEPOCKET') {
      /* POSTMESSAGGE AL COMPLETAR PROCESO DE PAGO DE ONEPOCKET */
      this.onePocketPaymentResponse(event.data);
      if (this.dataStore.brand === 'scotia') {
        this.data.getInfoCard();
      }
    } else if (event.data.typeMessage === 'GO_TO_RESUME_ONEPOCKET') {
      if (this.dataStore.brand === 'scotia') {
        this.data.getInfoCard();
      }
      /* POSTMESSAGGE AL VOLVER AL HOME DE ONEPOCKET */
      if (this.dataStore.brand === 'lifeMilesTravel') {
        this.goToLifeMilesPage();
      } else {
        this.router.navigate(['home']);
      }
    } else if (event.data.typeMessage === 'CANCEL_PAYMENT_ONEPOCKET') {
      if (this.dataStore.brand === 'lifeMilesTravel') {
        this.goToLifeMilesPage();
      } else {
        this.router.navigateByUrl('travel');
      }
    } else if (event.data.typeMessage === 'DETAIL_PAYMENT_ONEPOCKET') {
      if (this.dataStore.brand === 'scotia') {
        this.data.getInfoCard();
      }
      this.price = event.data;
    } else if (event.data.typeMessage === 'SLIDER_DRAGEND_PAYMENT_PAGE') {
      this.price.valuePoints = event.data.valuePoints;
      this.price.valueCreditCard = event.data.valuePayment;
    } else if (event.data.typeMessage === 'UPDATE_PROMOTION_CODE') {
      if (event?.data?.promotion) {
        this.price.promotion = event.data.promotion;
      }
    } else if (event.data.typeMessage === 'GO_TO_HOME') {
      if (this.dataStore.brand == 'credomatic') {
        this.router.navigate(['/travel']);
      } else {
        this.router.navigate(['/home']);
      }
    } else if (event.data.typeMessage === 'TYPE_CREDIT_CARD_DEFUALT') {
      if (event.data.franquice === 3) {
        this.eventService.setSubBrand('amex');
      } else {
        this.eventService.setSubBrand('default');
      }
    } else if (event.data.typeMessage === 'SUCCESS_PAYMENT_LANDING_MILES') {
      if (this.dataStore.brand === 'lifeMilesTravel') {
        this.goToLifeMilesPage();
      }
    } else if (event.data.typeMessage === 'TOKEN_SESSION_USER_ERROR') {
      if (this.dataStore.brand === 'lifeMilesTravel' || this.dataStore.brand === 'scotia') {
        this.errorModal('ALERT.ERROR_BOOKING.boddy');
      } else {
        this.router.navigateByUrl('home');
        this.loginService.logout();
        this.openLogin();
      }
    } else if (event.data.typeMessage === 'OPEN_EXTERNAL_URL') {
      this.data.openUrlExternal(event.data.url, event.data.target, event.data.features);
    } else if (event.data.typeMessage === 'TRACKING_DATA') {
      let objTrackingId = {
        category: '',
        action: '',
        label: '',
        params: ''
      }
      if (event.data.objectTracking) {
        objTrackingId = event.data.objectTracking;
      }
      let params = {};
      if (objTrackingId.params) {
        try {
          params = JSON.parse(objTrackingId.params);
        } catch (error) {
          UtilsResource.ShowLog('*** OpFromIframeComponent - error', error);          
        }
      }

      GoogleAnalytics.sendEventFirebase('ga_event', objTrackingId.category, objTrackingId.action, objTrackingId.label, params);
    }
  }

  goToLifeMilesPage() {
    const urlLifeMiles = this.storeServiceLibrary.getJustOneUniversalPropertie(keysUniversal.URL_REDIRECT_LIFEMILES_PAGE);
    window.location.href = urlLifeMiles;
    //window.open(urlLifeMiles, '_blank');
  }

  validateUserToken() {
    if (this.data.token) {
      this.token = this.data.token;
      if (this.purchaceId !== undefined) {
        this.enabled = true;
        this.loaded = false;
      } else {
        this.enabled = false;
        if (this.brand !== 'lifeMilesTravel') {
          this.router.navigateByUrl('travel');
        } else {
          this.router.navigateByUrl('lifemiles-travel');
        }
      }
    } else {
      this.enabled = false;

      if (this.dataStore.brand !== 'scotia' && this.dataStore.brand !== 'lifeMilesTravel') {

        const dialogRef = this.dialog.open(LoginComponent, {
          panelClass: 'login-dialog',
          data: {
            isInOnepocket: true
          }
        });

        dialogRef.afterClosed().subscribe(succesful => {
          if (succesful) {
            this.token = this.data.token;
            if (this.purchaceId !== undefined) {
              // si ingreso y/o se registró correctamente
              this.enabled = true;
              this.loaded = false;
              this.scrollIframe();
            } else {
              this.enabled = false;
              this.router.navigateByUrl('travel');
            }
          } else {
            this.enabled = false;
            this.scrollIframe();
          }
        });
      } else {
        if (this.brand === 'scotia') {
          // TODO: Mensaje pendiente.
          this.errorModal('');
        }
      }


    }
  }

  /*
* ENVIAR RESPUESTA DEL PAGO
*/
  onePocketPaymentResponse(data: any) {
    this.paymentStatus = data.paymentSuccess;
    this.smartProfile.getAccountMethod(this.data.token);
    this.outputEmiter.emit(data);
  }

  sendToken() {
    if (this.token !== undefined) {
      const iframe = document.getElementById('iframe_onepocket');
      const message = { typeMessage: keysPostMessage.tokenOnePocket, 'token': this.token };
      this.sendPostMessage(iframe, message, this.iframeBaseUrl);
    }
  }

  sendPurchaceID() {
    if (this.purchaceId !== undefined) {
      if (this.dataStore.mobile) {
        if (typeof androidProxy !== 'undefined') {
          androidProxy.postMessage(JSON.stringify({ purchaseId: this.purchaceId }));
        } else if (window.webkit !== undefined && window.webkit !== 'undefined' && window.webkit.messageHandlers.native !== undefined && window.webkit.messageHandlers.native !== 'undefined') { // Si es iOS
          window.webkit.messageHandlers.native.postMessage(JSON.stringify({ purchaseId: this.purchaceId }));
        }
      } else {
        const iframe = document.getElementById('iframe_onepocket');



        let message = {
          'typeMessage': keysPostMessage.idPurchaseOnePocket,
          'purchaseID': this.purchaceId,
          'flagVerifyProxyTravel': this.verifyOnePurchase,
          // 'flagVerifyProxyTravel': false,
          'selectedTaxes': this.selectedTaxes,
          'userPorcentage': this.percentageUser
        };

        if (this.dataStore.brand == 'credomatic') {
          if (this.storeServiceLibrary.getItemFromStorage('CountrySelected')) {
            message['countryCredomatic'] = this.storeServiceLibrary.getItemFromStorage('CountrySelected');
          }
        }

        if (this.confirmSale) {
          message['confirmSale'] = btoa(JSON.stringify(this.confirmSale));
        }

        if (this.product) {
          // 'fixedRatesData': JSON.parse(this.product),
          message['fixedRatesData'] = JSON.parse(this.product);
        }


        this.sendPostMessage(iframe, message, this.iframeBaseUrl);
        this.purchaseStatus = !this.purchaseStatus;
      }
    }
  }

  getParams() {
    this.activateRouter.queryParams.subscribe((params) => {
      if (params['p']) {
        const purchaseBase = btoa(params['p']);
        this.purchaceId = Number(purchaseBase);
        this.validateUserToken();
      }
    });
  }

  /*
* ENVIAR MOSTRAR TERMINOS Y CONDICIONES
*/
  onePocketTerms(data: any) {
    this.data.openTermsAndConditionsDialog();
  }

  openLogin() {
    const dialogRef = this.dialog.open(LoginComponent, {
      panelClass: 'login-dialog'
    });

    dialogRef.afterClosed().subscribe(successful => {
      if (successful) {
        this.router.navigateByUrl('home');
      }
    });
  }

  refreshValuePurchase() {
    if (!this.initiated || !this.loaded) {
      return;
    }
    const iframe = document.getElementById('iframe_onepocket_slider');
    const message = { typeMessage: keysPostMessage.updateInfoPreferencePayment, infoPayment: { valuePurchase: this.purchaceId } };
    this.sendPostMessage(iframe, message, this.iframeBaseUrl);
  }

  sendPostMessage(iframe: any, message: any, targetOrigin: string) {
    if (iframe === null) {
      return;
    }
    const iWindow = (<HTMLIFrameElement>iframe).contentWindow;
    iWindow.postMessage(message, targetOrigin);
  }

  @DebounceCall(50)
  private onePocketSliderResponse(data: any) {
    this.pointsAmount = +data.valuePoints;
    this.moneyAmount = +data.valuePayment;
    const percentage = data.porcentagePayment;
    this.storeServiceLibrary.setItemInStorage('percentage', percentage);
    this.getPorcentaje.emit(data.porcentagePayment);
  }

  showBenefeitsModal() {
    this.showBenefits = !this.showBenefits;
  }

  ngOnDestroy(): void {
    window.removeEventListener('message', event => this.receiveMessage(event), false);
  }

  showVoyageBenefits() {
    this.voyageBenefits = !this.voyageBenefits;
  }

  receivedFlag(event) {
    this.showLoadOP = event.send;
    this.loadCertificates = !event.send;
    this.purchaceId = this.storeServiceLibrary.getItemFromStorage('purchaceId');
    this.verifyOnePurchase = false;

    if (event.confirmSale) {
      this.confirmSale = event.confirmSale;
    }
  }

  changeLanguage() {
    this.translateService.onLangChange.subscribe((data) => {
      const iframe = document.getElementById('iframe_onepocket');
      const message = { typeMessage: 'LANGUAGE_CHANGE', language: data['lang'] };
      this.data.sendPostMessage(message, '*', iframe);
    });
  }

  errorModal(message) {
    let validateButtonGoToLogin = false;

    if (this.dataStore.brand === 'lifeMilesTravel') {
      validateButtonGoToLogin = true;
    }

    const heightPadding = this.dataStore.isMobileDevice ? '0px' : '40px';
    const widthPadding = this.dataStore.isMobileDevice ? '0px' : '16vw';
    const dialogRef = this.dialog.open(AlertErrorComponent, {
      maxWidth: '50vw',
      width: 'calc(50vw - ' + widthPadding + ')',
      height: 'calc(50vh - ' + heightPadding + ')',
      minHeight: '59vh',
      disableClose: true,
      data: {
        tittle: 'ALERT.ERROR_BOOKING.title',
        error: `${message}`,
        validateHaveGoToLogin: validateButtonGoToLogin,
        showIconClose: false
      }
    });



    dialogRef.afterClosed().subscribe(() => {
      /**
       * Aca se haria la logica para desplguear
       */
      this.data.token = '';
      this.purchaceId = null;
      this.router.navigateByUrl('lifemiles-travel');
    });
  }

  stringToBoolean(value: string) {
    switch (value) {
      case "true":
      case "1":
      case "on":
      case "yes":
        return true;
      default:
        return false;
    }
  }

  openTyC() {
    const lang = this.storeServiceLibrary.getItemFromStorage('lang') || 'en';
    // const urlTerms = environment.brands[this.dataStore.brand].terminos_y_condiciones[lang.toUpperCase()];
    let urlTerms = this.storeServiceLibrary.getJustOneUniversalPropertie(keysUniversal.web_url_terms_and_conditions_en);
    if (lang) {
      if (lang == 'es') {
        urlTerms = this.storeServiceLibrary.getJustOneUniversalPropertie(keysUniversal.web_url_terms_and_conditions_es);
      }
    }
    window.open(urlTerms, '_blank');
  }

}
