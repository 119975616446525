import { Injectable, Inject } from '@angular/core';
import { CanActivate, Router, ActivatedRoute } from '@angular/router';

import { DOCUMENT, Location } from '@angular/common';
import { DataStoreService } from '../../services/data-store.service';
import { EProduct } from '../../component/searched/models/searched.model';
import { DataService } from '../../services/data.service';
import { keys } from 'src/app/resources/keys.resources';
import { StoreServiceLibrary } from '@wearenovae/novae-core-services';
import { InitService } from '../../services/init.service';

@Injectable({
    providedIn: 'root'
})
export class RouterRedirectGuard implements CanActivate {
    domain = '';

    constructor(
        @Inject(DOCUMENT) private _DOCUMENT,
        private router: Router,
        private dataStore: DataStoreService,
        private dataService: DataService,
        private storeServiceLibrary: StoreServiceLibrary,
        private initService: InitService) {
    }

    canActivate() {
        let route = window.location.hash;
        let hostname = window.location.hostname;
        const params = window.location.search;
        if (this.dataStore.brand == 'lifeMilesTravel') {
            this.router.navigateByUrl(`lifemiles-travel${params}`);
            return false;
        } else if (route == '#/travel-app') {
            this.router.navigateByUrl(`travel-app${params}`);
            return false;
        } else if (hostname == 'mr2.sale') {
            this.router.navigateByUrl(`myrewards-salesdeck`);
            return false;
        } else {
            this.initService.isInitializedBrand.subscribe((isInit) => {
                if (isInit) {
                    const redirectDefault = this.storeServiceLibrary.getJustOneUniversalPropertie(keys.DEFAULT_REDIRECT_WEB) || 'travel';
                    this.router.navigateByUrl(redirectDefault + params);
                    /*
                    switch (this.dataStore.brand) {
                        case 'myrewards':
                            this.router.navigateByUrl(redirectDefault);
                            break;
                        case 'cibc':
                            this.router.navigateByUrl(redirectDefault);
                            break;
                        case 'ncb':
                            this.router.navigateByUrl(redirectDefault);
                            break;
                        case 'firstcitizens':
                            this.router.navigateByUrl(redirectDefault);
                            break;
                        case 'credomatic':
                            this.router.navigateByUrl(redirectDefault);
                            break;
                        default:
                            this.router.navigateByUrl(redirectDefault);
                            break;
                    }
                    //*/
                }
            });
            return false;
        }
    }

}
