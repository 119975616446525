<div class="card travel-box">
  <div class="card_info">
    <div [ngClass]="(isProductSelected()) ? 'corner-selected selected' : 'corner-selected'" >
      <div class="input-selector" (click)="listCmp.selectProduct.emit(product)"></div>
    </div>

    <app-abstract-card-header
      *ngIf="options.type !== 'car'"
    ></app-abstract-card-header>
    <app-abstract-card-body></app-abstract-card-body>
    <!--<app-card-body></app-card-body>-->

  </div>
</div>
