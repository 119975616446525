import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { DataStoreService } from '../../../services/data-store.service';
import { keysUniversal, StoreServiceLibrary } from '@wearenovae/novae-core-services';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { SwiperComponent, SwiperDirective, SwiperModule, SwiperConfigInterface, SwiperPaginationInterface } from 'ngx-swiper-wrapper';
import { Router } from '@angular/router';

@Component({
  selector: 'app-section-three-bac',
  templateUrl: './section-three-bac.component.html',
  styleUrls: ['./section-three-bac.component.scss']
})
export class SectionThreeBacComponent implements OnInit {

  @Input() brand: string;
  urlsVideos: any = environment.brands[this.dataStore.brand].videosLandingEN.sectionThree;

  imgSwitch = `./assets/images/${this.dataStore.brand}-miles/iphone-switch.png`;
  imgPayPint = `./assets/images/${this.dataStore.brand}-miles/pay-points.png`;
  urlData: string;

  public bigSliderConfig: SwiperConfigInterface = {
    direction: 'horizontal',
    slidesPerView: 1,
    // pagination: true,
    pagination: {
      el: '.swiper-pagination',
      clickable: true
    }
    // breakpoints: {
    //   991: {
    //     initialSlide: 1,
    //     slidesPerView: 3,
    //     freeMode: false,
    //     init: false,
    //     centeredSlides: true,
    //     allowSlidePrev: false,
    //     allowSlideNext: false 
    //   },
    //   768: {
    //     initialSlide: 1,
    //     slidesPerView: 1.5,
    //     freeMode: false,
    //     init: true,
    //     centeredSlides: true,
    //     autoHeight: true,
    //     setWrapperSize: true,
    //     pagination: {
    //       el: '.swiper-pagination',
    //     },
    //   },
    //   540: {
    //     initialSlide: 1,
    //     slidesPerView: 1,
    //     freeMode: false,
    //     init: true,
    //     centeredSlides: true,
    //     autoHeight: true,
    //     setWrapperSize: true,
    //     pagination: {
    //       el: '.swiper-pagination',
    //     },
    //   },
    // },
  };

  @ViewChild(SwiperComponent) swiper: SwiperComponent;

  constructor(private dataStore: DataStoreService,
    private store: StoreServiceLibrary,
    private translate: TranslateService,
    private router: Router) { }

  ngOnInit() {
    this.changeLanguaje();
  }

  changeLanguaje() {
    this.translate.onLangChange.subscribe((data) => {
      if (data.lang === 'es') {
        this.imgSwitch = `./assets/images/${this.dataStore.brand}-miles/es/iphone-switch.png`;
        this.imgPayPint = `./assets/images/${this.dataStore.brand}-miles/es/pay-points.png`;
        this.urlsVideos = environment.brands[this.dataStore.brand].videosLandingES.sectionThree;
      } else {
        this.imgSwitch = `./assets/images/${this.dataStore.brand}-miles/iphone-switch.png`;
        this.imgPayPint = `./assets/images/${this.dataStore.brand}-miles/pay-points.png`;
        this.urlsVideos = environment.brands[this.dataStore.brand].videosLandingEN.sectionThree;
      }
    });
  }

  goToTravel() {
    this.router.navigateByUrl('travel');
    document.body.scroll({
      top: 0,
      behavior: 'smooth'
    });
  }

  goToTyC() {
    const lang = this.store.getItemFromStorage('lang') || 'en';
    // const urlTerms = environment.brands[this.dataStore.brand].terminos_y_condiciones[lang.toUpperCase()];
    let urlTerms = this.store.getJustOneUniversalPropertie(keysUniversal.web_url_terms_and_conditions_en);

    if (lang) {
      if (lang == 'es') {
        urlTerms = this.store.getJustOneUniversalPropertie(keysUniversal.web_url_terms_and_conditions_es);
      }
    }
    if (!this.dataStore.isAlleMobile) {
      this.urlData = urlTerms;
    } else {
      this.urlData = `${urlTerms}`;
    }
    window.open(this.urlData, '_blank');
  }
}
