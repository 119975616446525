<p class="title" innerHTML="{{ 'ACTIVITY_DETAIL.reservation' | translate }}">Tu
    reserva</p>
<small *ngIf="detail.travellersQty > 1 else oneGuest"
    innerHTML="{{ 'ACTIVITY_DETAIL.total_price_person' | translate:'{value:\'' + detail.travellersQty + '\'}' }}">
    Total price
</small>
<ng-template #oneGuest>
    <small innerHTML="{{ 'ACTIVITY_DETAIL.total_price' | translate }}">Total
        price </small>
</ng-template>

<div class="prices">
    <p class="new_price">
        {{ detail.selectionMilesPrice | milesFormat}} <span
            innerHTML="{{ 'ACTIVITY_DETAIL.miles' | translate }}"></span>
    </p>
    <p class="money_price">+ {{ detail.selectionMoneyPrice }}</p>
</div>

<p class="selection">{{ detail.selectionName }}
    {{ (!detail.hasOptionMeetingPoint) ? '(' + detail.selectionDate + ')' : '' }}.
</p>

<div class="meetingpoint" *ngIf="detail.hasOptionMeetingPoint">
    <h6 innerHTML="{{ 'ACTIVITY_DETAIL.meeting_point' | translate }}">Punto de
        encuentro: </h6>
    <p>{{ detail.selectionPlaceMeetingPoint }}</p>
    <h6 innerHTML="{{ 'ACTIVITY_DETAIL.meeting_date' | translate }}">Fecha y
        hora de encuentro: </h6>
    <p>{{ detail.selectionDateMeetingPoint }}</p>
</div>