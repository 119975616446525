import { Injectable } from '@angular/core';
import {
  CanActivate,
  CanActivateChild,
  CanLoad,
  Route,
  UrlSegment,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router, ActivatedRoute
} from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';
import { DataService } from '../services/data.service';
import { LoginComponent } from '../container/shared/login/login.component';
import { MatDialog } from '@angular/material/dialog';
import { DataStoreService } from '../services/data-store.service';
import { AlertErrorComponent } from '../component/product-detail/alert-error/alert-error.component';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild, CanLoad {

  /*static canActivate(path) {
    
    debugger;
    return undefined;
  }*/

  constructor(private auth: AuthService,
    private router: Router,
    private data: DataService,
    private dataStore: DataStoreService,
    private dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    private translate: TranslateService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const token = this.data.token;
    // TODO: Descomentar cuando tengamos el token, se comenta para poder visualizar las paginsd
    if (token && token !== null && token.trim() !== '') {
      return true;
    } else {

      if (this.dataStore.brand === 'scotia') {
        let message;
        this.translate.get('ALERT.ERROR_BOOKING.boddy').subscribe(translate => message = translate);
        this.errorModal(message);
      } else {
        const dialogRef = this.dialog.open(LoginComponent, {
          panelClass: 'login-dialog'
        });

        dialogRef.afterClosed().subscribe(successful => {
          if (successful) {
            // si ingreso y/o se registró correctamente
            this.router.navigate([state.url]);
          }
        });

        return false;
      }
    }
    // return true;
  }

  errorModal(message) {
    const heightPadding = this.dataStore.isMobileDevice ? '0px' : '40px';
    const widthPadding = this.dataStore.isMobileDevice ? '0px' : '16vw';
    this.dialog.open(AlertErrorComponent, {
      maxWidth: '50vw',
      width: 'calc(50vw - ' + widthPadding + ')',
      height: 'calc(50vh - ' + heightPadding + ')',
      minHeight: '59vh',
      data: {
        tittle: 'ALERT.ERROR_BOOKING.title',
        error: `${message}`
      }
    });
  }

  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return true;
  }

  canLoad(
    route: Route,
    segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
    return true;
  }
}
