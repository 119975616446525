import { Component, OnInit } from '@angular/core';
import { TypePromotions } from 'src/app/resources/keys.resources';
import { DataStoreService } from 'src/app/services/data-store.service';
import { TranslateService } from '@ngx-translate/core';
import { StoreServiceLibrary, CodePromotionsService, UserData, GetProgramsRequest, GetPromotionsByUserRequest, GetPromotionsByUserResponse } from '@wearenovae/novae-core-services';
import { DataService } from 'src/app/services/data.service';
import { InitService } from 'src/app/services/init.service';
import { ActionEventService } from 'src/app/services/action-event.service';

@Component({
  selector: 'app-coupon-promotion',
  templateUrl: './coupon-promotion.component.html',
  styleUrls: ['./coupon-promotion.component.scss']
})
export class CouponPromotionComponent implements OnInit {

  language: string;

  promotion: {
    code?,
    type?,
    fechafin?,
    percentage?,
    currency?
    namePromotion?
    typeDiscount?
    valueDiscount?
  };
  countryCredomatic;
  typePromotion: TypePromotions;

  get brand() {
    return this.dataStoreService.brand;
  }

  constructor(
    public dataStoreService: DataStoreService,
    private translateService: TranslateService,
    private storeServiceLibrary: StoreServiceLibrary,
    private codePromotionsService: CodePromotionsService,
    public data: DataService,
    private initService: InitService,
    private eventService: ActionEventService,
  ) { }

  ngOnInit(): void {
    this.translateService.onLangChange.subscribe((data) => {
      this.language = data['lang'];
    });
    this.initService.isInitializedBrand.subscribe(isInitialized => {
      if (isInitialized) {
        this.changeLanguage();
        this.countryCodeChange();

        if (this.dataStoreService.brand == 'credomatic') {
          if (this.storeServiceLibrary.getItemFromStorage('CountrySelected')) {
            this.countryCredomatic = this.storeServiceLibrary.getItemFromStorage('CountrySelected');
          }
        }
        this.getPromo(TypePromotions.system);
        //TO-DO PROVISIONAL
        // let strBool = this.storeServiceLibrary.getJustOneUniversalPropertie(keysUniversal.web_show_code_promotion);
        // this.showCodePromotion = this.stringToBoolean(strBool);
        //TO-DO PROVISIONAL
      }
    });
  }

  changeLanguage() {
    this.translateService.onLangChange.subscribe((data) => {
      const iframe = document.getElementById('iframe_onepocket');
      const message = { typeMessage: 'LANGUAGE_CHANGE', language: data['lang'] };
      this.data.sendPostMessage(message, '*', iframe);
    });
  }

  countryCodeChange() {
    this.eventService.countryCodeChange.subscribe((countryCodeChange) => {
      if (this.dataStoreService.brand == 'credomatic') {
        if (countryCodeChange?.code) {
          this.countryCredomatic = countryCodeChange.code;
        }
      }
      this.getPromo(TypePromotions.system);
    });
  }

  getPromo(typePromo: TypePromotions) {
    this.typePromotion = typePromo;
    let userData: UserData = this.storeServiceLibrary.getUserData()
    //Llamar aservicio de obtener programas
    let requestGetPrograms = new GetProgramsRequest();
    requestGetPrograms.userToken = userData.authenticationToken;
    requestGetPrograms.quantityPoints = 0;
    this.codePromotionsService.getPrograms(requestGetPrograms).subscribe(
      (respGetPrograms: any) => {
        if (respGetPrograms?.operationResult?.code == 200 && respGetPrograms?.programsList) {
          let programIdList = [];
          for (const itr of respGetPrograms?.programsList) {
            programIdList.push(itr.programid);
          }
          programIdList = programIdList.sort(
            (a, b) => {
              return a - b
            }
          );
          /////////////////////////////////////////////
          let requestPromotion = new GetPromotionsByUserRequest();
          requestPromotion.lenguaje = this.translateService.currentLang;
          requestPromotion.programId = programIdList.join(",");
          requestPromotion.channelId = this.data.getChannel().toString();
          requestPromotion.country = this.countryCredomatic;
          requestPromotion.token = userData.authenticationToken;
          requestPromotion.type = typePromo;
          requestPromotion['consult'] = true

          this.codePromotionsService.getPromotionsByUser(requestPromotion).subscribe((resp: GetPromotionsByUserResponse) => {
            if (resp?.transactionResult?.operationResult?.code == '200') {
              this.promotion = {};
              this.promotion.code = resp?.transactionResult?.promotion?.code;
              this.promotion.type = resp?.transactionResult?.promotion?.type;
              this.promotion.fechafin = resp?.transactionResult?.promotion?.fechafin;
              this.promotion.percentage = resp?.transactionResult?.promotion?.percentage;
              this.promotion.currency = resp?.transactionResult?.promotion?.currency;
              // this.promotion.namePromotion = resp?.transactionResult?.promotion?.namePromotion;
              // this.promotion.typeDiscount = resp?.transactionResult?.promotion?.typeDiscount;
              // this.promotion.valueDiscount = resp?.transactionResult?.promotion?.valueDiscount;
              if (typePromo == TypePromotions.code) {
                this.sendInfoPromotion(this.promotion);
              }
            } else {
              this.sendInfoPromotion(this.promotion);
              if (typePromo == TypePromotions.system) {
                this.getPromo(TypePromotions.code);
              }
            }
          }, (err) => {
          });
        } else {
        }
      },
      (err) => {
      }
    );

  }

  sendInfoPromotion(promotion: any) {
    let code = promotion?.code || '';
    let infoPromotionObj = {
      code: code
    };
    this.eventService.infoPromotion(infoPromotionObj);
  }
}
