<div class="search-benefits filtro-flotante tab-panel">

    <div class="uno">

        <form [formGroup]="benefitsSearchForm" novalidate style="display: flex;">

            <div class="input origen-destino">
                <div class="origenes-in bl-salida">
                    <ng-select formControlName="origen" class="autocompleteSearch" id="origen" [items]="allCities" bindLabel="name" groupBy="type" [searchFn]="returnTrue" dropdownPosition="bottom" placeholder="{{'SEARCH_BENEFITS.placeholder_origin'|translate}}" [virtualScroll]="true"
                        (search)="onSearch($event)" [notFoundText]="'SEARCH_BENEFITS.not_found_text'|translate" #origen (change)="origen.blur()">
                        <ng-template ng-option-tmp let-item="item" let-search="searchTerm">
                            <div>
                                <span>{{item.name}}</span>
                            </div>
                        </ng-template>
                    </ng-select>
                    <div class="alert_red" *ngIf="originNotSelected" innerHTML="{{'SEARCH_BENEFITS.please_indicate_origin'|translate}}">
                        Please indicate the origin of your trip
                    </div>
                    <div class="alert_red" *ngIf="validatorOriginDestiniDif" innerHTML="{{'SEARCH_BENEFITS.must_be_different'|translate}}">
                        Origin and destination must be different
                    </div>
                </div>
                <div class="ciudades icon-invert" (click)="invertFlightSelection()">
                    <span class="icono-invertir"></span>
                </div>
                <div class="origenes-in bl-llegada">
                    <ng-select formControlName="destino" class="autocompleteSearch" id="destino" [items]="allCities" bindLabel="name" groupBy="type" [searchFn]="returnTrue" dropdownPosition="bottom" placeholder="{{'SEARCH_BENEFITS.placeholder_destination'|translate}}" [virtualScroll]="true"
                        (search)="onSearch($event)" [notFoundText]="'SEARCH_BENEFITS.not_found_text'|translate" #destino (change)="destino.blur()">
                        <ng-template ng-option-tmp let-item="item" let-search="searchTerm">
                            <div>
                                <span>{{item.name}}</span>
                            </div>
                        </ng-template>
                    </ng-select>
                    <div class="alert_red" *ngIf="destinationNotSelected" innerHTML="{{'SEARCH_BENEFITS.please_indicate_destination'|translate}}">
                        Please indicate the destination of your trip
                    </div>
                    <div class="alert_red" *ngIf="validatorOriginDestiniDif" innerHTML="{{'SEARCH_BENEFITS.must_be_different'|translate}}">
                        Origin and destination must be different
                    </div>
                </div>
            </div>

            <div class="input fecha-ida">
                <label [innerHTML]="'SEARCH_BENEFITS.date'|translate">
          Date
        </label>
                <!-- <div class="fecha-inputs" *ngIf="fixChangeLanguageInputDate === false">
          <input class="dateSelect form-control" type="text"
            ngxDaterangepickerMd formControlName="dateSelect"
            [linkedCalendars]="true" [showDropdowns]="true" [autoApply]="true"
            [locale]="locale" [singleDatePicker]="false" [minDate]="minDate" />
        </div> -->


                <div class="fecha-inputs" *ngIf="fixChangeLanguageInputDate === false">
                    <div class="bl-input-icon">
                        <input #dateStart name="dateStart" [(ngModel)]="selected" class="dateSelect form-control" (openedChange)="openedChangeStart($event)" type="text" appCalendarSearch readonly formControlName="dateSelect" emptyWeekRowClass="empty-week" placeholder="Select Date"
                            [linkedCalendars]="true" [showDropdowns]="true" [autoApply]="true" [locale]="locale" [singleDatePicker]="true" [minDate]="minDate" [dateShowTogether]="false" [isStart]="true" />
                    </div>
                    <div class="bl-input-icon icon-last">
                        <input #dateEnd name="dateEnd" [(ngModel)]="selected" class="dateSelect form-control" (openedChange)="openedChangeEnd($event)" type="text" appCalendarSearch readonly formControlName="dateSelect" emptyWeekRowClass="empty-week" placeholder="Select Date"
                            [linkedCalendars]="true" [showDropdowns]="true" [autoApply]="true" [locale]="locale" [singleDatePicker]="true" [minDate]="minDate" [dateShowTogether]="false" [isEnd]="true" />
                    </div>
                </div>


                <div class="alert_red" *ngIf="dayOfDifference" innerHTML="{{'SEARCH_BENEFITS.day_of_difference'|translate}}">
                    At least one day apart
                </div>
            </div>

            <div class="input passengers">
                <label>{{'SEARCH_BENEFITS.passengers'|translate}}</label>
                <app-input-ages min="1" (apply)="onApplyAgesPassengers($event)" [personText]="'SEARCH_BENEFITS.passenger'|translate">
                </app-input-ages>
                <div class="alert_red" *ngIf="passengerNotSelected" innerHTML="{{'SEARCH_BENEFITS.travelers'|translate}}">
                </div>
            </div>

        </form>
        <div class="buscar" (click)="search()">
            <span innerHTML="{{'SEARCH_BENEFITS.search'|translate}}">
        Search
      </span>
        </div>
    </div>
</div>