import {Component, Input, OnInit, ViewChildren, QueryList, Output, EventEmitter} from '@angular/core';
import {LabelType, Options} from 'ng5-slider';
import {FilterGroupType} from '../filterParams';
import {FilterService, unique} from '../filter.service';
import {formatCurrency, getCurrencySymbol} from '@angular/common';
import { MatCheckbox } from '@angular/material/checkbox';
import {TranslateService} from '@ngx-translate/core';
import {LanguageService} from '../../../../component/language/language.service';
import {DataStoreService} from '../../../../services/data-store.service';

@Component({
  selector: 'app-filter-group',
  templateUrl: './filter-group.component.html',
  styleUrls: ['./filter-group.component.scss'],
})
export class FilterGroupComponent implements OnInit {

  // checkbox de opciones de los filtros tipo ícono
  @ViewChildren('optionsIcon') public optionsIcon: QueryList<MatCheckbox>;

  filterGroupDataOptions = [];
  minValue: any;
  maxValue: any;
  options: Options;
  optionIdsArray: any[];
  preNgSelectArray: any[];
  flagProvisorio: boolean;
  placeholderBuscar: string;
  smartChequed: boolean;
  availableOptions: [];
  autocompleteOptions;
  @Input() filterGroupData;

  @Input() set availableOptionsF(val) {
    this.availableOptions = val;
  }

  @Output() selectedOption = new EventEmitter<any>();
  language: string;

  constructor(
    private filterService: FilterService,
    private languageService: LanguageService,
    private translateService: TranslateService,
    private dataStore: DataStoreService
  ) {
  }

  ngOnInit() {
    this.filterService.slidersGroupIds = undefined;
    this.getTranslations();
    if (!this.filterService.smartProfilePreferenceSeted) {
    this.filterService.activateFilterGroup = [];
    this.filterService.idsToEvaluate = [];
    this.filterService.n = 0;
    }
    this.filterGroupData.options.sort((a, b) => (a.optionValue > b.optionValue) ? 1 : -1);
    for (let i = 0; i < this.filterGroupData.options.length; i++) {
      this.filterGroupData.options[i].optionsId = this.filterGroupData.options[i].optionsId.filter(unique);
    }
    this.filterGroupDataOptions = this.filterGroupData.options;
    if (this.filterGroupData.optionType === 4) {

      this.filterService.preNgSelectArray = [];
      this.filterService.autocompleteOptions = this.filterGroupDataOptions;
      this.filterService.autocompleteOptionsFull = this.filterGroupDataOptions;
    }
    // fecha
    if (this.filterGroupData.optionType === 1) {
      this.minValue = this.filterGroupData.sliderMinValue;
      this.maxValue = this.filterGroupData.sliderMaxValue;
      this.options = {
        floor: this.filterGroupData.sliderMinValue,
        ceil: this.filterGroupData.sliderMaxValue,
        step: 1,
        pushRange: true,
        animate: false,
        disabled: this.minValue === this.maxValue,
        translate: (value: number, label: LabelType): string => {
          const hourMinute = Math.floor(value / 60).toString().padStart(2, '0') + ':' + Math.round(value % 60).toString().padStart(2, '0');
          switch (label) {
            case LabelType.Floor:
              return hourMinute;
            case LabelType.Ceil:
              return hourMinute;
            default:
              return hourMinute;
          }
        }
      };

      // precio
    } else if (this.filterGroupData.optionType === 2) {
      this.minValue = this.filterGroupData.sliderMinValue;
      this.maxValue = this.filterGroupData.sliderMaxValue;
      this.options = {
        floor: this.filterGroupData.sliderMinValue,
        ceil: this.filterGroupData.sliderMaxValue,
        step: 1,
        pushRange: true,
        animate: false,
        disabled: this.minValue === this.maxValue,
        translate: (value: number, label: LabelType): string => {
          switch (label) {
            case LabelType.Low:
              return formatCurrency(Math.floor(value), this.language, getCurrencySymbol(this.filterGroupData.currency, 'narrow'),
                this.filterGroupData.currency, '1.0-0');
            case LabelType.High:
              return formatCurrency(Math.floor(value), this.language, getCurrencySymbol(this.filterGroupData.currency, 'narrow'),
                this.filterGroupData.currency, '1.0-0');
            case LabelType.Floor:
              return formatCurrency(Math.floor(value), this.language, getCurrencySymbol(this.filterGroupData.currency, 'narrow'),
                this.filterGroupData.currency, '1.0-0');
            case LabelType.Ceil:
              return formatCurrency(Math.floor(value), this.language, getCurrencySymbol(this.filterGroupData.currency, 'narrow'),
                this.filterGroupData.currency, '1.0-0');
            default:
              return this.filterGroupData.currency + value;
          }
        }
      };

    }
  }

  private getTranslations() {
    this.languageService.systemLanguajeChange.subscribe((language) => {
      if (language) {
        this.language = language;
        this.translateService.get([
          'FILTERS.search'
        ]).subscribe((translations) => {
          this.placeholderBuscar = translations['FILTERS.search'];
        });
      }
    });
  }

  doFiltering(filterName: string, filterType: FilterGroupType, pushRemove: boolean, optionIdsArray, ev, optionValue?: string) {
    switch (filterType) {
      case 1:
      case 2:
        this.flagProvisorio = true;
        optionIdsArray = ev.source.value;
        break;
      case 3:
        this.flagProvisorio = true;
        this.optionIdsArray = [];
        const maxVal = ev.highValue;
        const minVal = ev.value;
        if (maxVal === minVal) {
          break;
        }
        this.filterGroupData.options.forEach(value => {
          if (value.optionValue >= minVal && value.optionValue <= maxVal) {
            this.optionIdsArray.push(...value.optionsId);
          }
        });
        if (this.filterService.slidersGroupIds === undefined) {
          this.filterService.slidersGroupIds = [{
            filterName: filterName,
            prevSliderIdsArray: this.optionIdsArray
          }];
        } else {
        const filterNamePos = this.filterService.slidersGroupIds.findIndex(i => i.filterName === filterName);
        if (filterNamePos === -1) {
          this.filterService.slidersGroupIds.push({
            filterName: filterName,
            prevSliderIdsArray: this.optionIdsArray
          });
        } else {
          this.filterService.activateFilter(
            filterName,
            filterType,
            false,
            this.filterService.slidersGroupIds[filterNamePos].prevSliderIdsArray,
            this.filterGroupData.multipleOptions
          );
          this.filterService.slidersGroupIds[filterNamePos].prevSliderIdsArray = this.optionIdsArray;
        }
        }
        optionIdsArray = this.optionIdsArray;
        pushRemove = true;
        break;
      case 4:
        this.flagProvisorio = true;
        const optionIdsArrayPrev = this.filterService.preNgSelectArray;
        if (optionIdsArrayPrev !== undefined  && optionIdsArrayPrev.length > 0) {
          this.filterService.activateFilter(filterName, filterType, false, optionIdsArrayPrev, this.filterGroupData.multipleOptions);
        }
        if (ev !== undefined) {
          this.filterService.preNgSelectArray = ev.optionsId;
          optionIdsArray = ev.optionsId;
          pushRemove = true;
        } else {
          this.filterService.preNgSelectArray = [];
          this.flagProvisorio = false;
        }
        break;
    }
    if (this.flagProvisorio) {
      this.filterService.activateFilter(filterName, filterType, pushRemove, optionIdsArray, this.filterGroupData.multipleOptions);
    }
    if (optionValue) {
      this.selectedOption.emit([filterName, optionValue, pushRemove, this.filterGroupData.multipleOptions]);
    }
    this.autocompleteOptionsFn(this.filterService.idsToShow);
  }

  autocompleteOptionsFn(idsToShow) {

    this.filterService.autocompleteOptions = [];
    if (this.filterService.preNgSelectArray !== undefined && this.filterService.preNgSelectArray.length > 0) {
      this.filterService.autocompleteOptions.push(this.filterService.preNgSelectArray);
    }

    this.filterService.autocompleteOptionsFull.forEach((option, i) => {
      this.filterService.autocompleteOptionsFull[i].optionsId.forEach((optionId, j) => {
        if (idsToShow.includes(optionId)) {
          this.filterService.autocompleteOptions.push(this.filterService.autocompleteOptionsFull[i]);
        }
      });
    });
  }

  isAvailableOption(groupName, value): boolean {
    if (this.filterGroupData.multipleOptions) {
      return this.filterGroupData.multipleOptions;
    }
    return this.availableOptions.findIndex(element => {
      return element[groupName] === value;
    }) > -1;
  }

}
