<section class="paying">
    <div class="container">
        <div class="bl-row">
            <div class="bl-left">
                <a href="#/travel"><img class="imagen-video" [src]="imgPayPint | srcSanitizer" alt=""></a>
            </div>

            <div class="bl-right">
                <h2 class="text-gradient" innerHTML="{{'LANDING.'+brand+'.SECTION_THREE.SECTION_FTRAVEL.flex_travel_title' | translate}}"></h2>
                <p innerHTML="{{'LANDING.'+brand+'.SECTION_THREE.SECTION_FTRAVEL.ftravel_desc_1' | translate}}"></p>
                <!-- <p *ngIf="brand === 'credomatic'" innerHTML="{{'LANDING.'+brand+'.SECTION_THREE.SECTION_FTRAVEL.ftravel_desc_2' | translate}}"></p> -->
            </div>
        </div>
    </div>
</section>



<section class="s-enough">
    <div class="container">
        <h2 class="text-gradient" innerHTML="{{'LANDING.'+brand+'.SECTION_THREE.SECTION_EMILES.enough_miles_title' | translate}}"></h2>
        <p innerHTML="{{'LANDING.'+brand+'.SECTION_THREE.SECTION_EMILES.emiles_desc_1' | translate}}"></p>
        <div class="bl-row">
            <div class="bl-left">
                <div class="bloque-video">
                    <div class="iphone-video">
                        <video class="video-vimeo" loop muted playsinline autoplay oncanplay="this.play()" onloadedmetadata="this.muted=true">
                                <source [src]="urlsVideos.three.url | srcSanitizer" type="video/mp4">
                            </video>
                    </div>
                    <img class="imagen-video" src="./assets/images/{{brand}}-miles/bg-iphone-miles.png" alt="">
                </div>
            </div>

            <div class="bl-right">

                <ul class="items-number">
                    <li><span>{{'LANDING.'+brand+'.SECTION_FIRST.SECTION1.1' | translate}}</span>
                        <p innerHTML="{{'LANDING.'+brand+'.SECTION_THREE.SECTION_EMILES.list_paying_1' | translate}}"></p>
                    </li>
                    <li><span>{{'LANDING.'+brand+'.SECTION_FIRST.SECTION1.2' | translate}}</span>
                        <p innerHTML="{{'LANDING.'+brand+'.SECTION_THREE.SECTION_EMILES.list_paying_2' | translate}}"></p>
                    </li>
                    <li><span>{{'LANDING.'+brand+'.SECTION_FIRST.SECTION1.3' | translate}}</span>
                        <p innerHTML="{{'LANDING.'+brand+'.SECTION_THREE.SECTION_EMILES.list_paying_3' | translate}}"></p>
                    </li>
                    <li *ngIf="brand === 'credomatic'"><span>{{'LANDING.'+brand+'.SECTION_FIRST.SECTION1.4' | translate}}</span>
                        <p innerHTML="{{'LANDING.'+brand+'.SECTION_THREE.SECTION_EMILES.list_paying_4' | translate}}"></p>
                    </li>
                    <li *ngIf="brand === 'credomatic'">
                        <p innerHTML="{{'LANDING.'+brand+'.SECTION_THREE.SECTION_EMILES.list_paying_5' | translate}}"></p>
                    </li>
                </ul>
                <div *ngIf="brand === 'scotia'" class="store">
                    <span>{{'LANDING.'+brand+'.SECTION_THREE.SECTION_EMILES.download_app' | translate}}</span>
                    <app-download-store [class]="'logo'"></app-download-store>
                </div>
            </div>
        </div>
    </div>
</section>