import { Component } from '@angular/core';


@Component({
  selector: 'app-onepocket-benefits',
  templateUrl: './onepocket-benefits.component.html',
  styleUrls: ['./onepocket-benefits.component.css']
})
export class OnepocketBenefitsComponent {

  constructor() { }
}
