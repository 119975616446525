import { Component, OnInit, Input, AfterContentInit, ElementRef } from '@angular/core';
import { DataStoreService } from '../../../services/data-store.service';
import { environment } from '../../../../environments/environment';

import { StoreServiceLibrary } from '@wearenovae/novae-core-services';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-section-two-mr',
  templateUrl: './section-two-mr.component.html',
  styleUrls: ['./section-two-mr.component.scss']
})
export class SectionTwoMRComponent implements OnInit, AfterContentInit {

  @Input() brand: string;
  urlsVideos: any = environment.brands[this.dataStore.brand].videosLandingEN.sectionTwo;
  urlsVideosFirstCitizens: any = {};
  video2
  imgPower = `./assets/images/${this.dataStore.brand}-miles/iphone-power.png`;

  objToLoadVideo = {
    one: false,
    two: false,
    three: false
  }

  constructor(private dataStore: DataStoreService,
    private store: StoreServiceLibrary,
    private translate: TranslateService,
    private el: ElementRef) { }

  ngOnInit() {
    this.changeLanguaje();
    if (this.brand === 'firstcitizens') {
      this.video2 = true
      if (this.video2 == true) {
        this.urlsVideosFirstCitizens = environment.brands[this.dataStore.brand].videosLanding.sectionTwo;
        this.urlsVideos = this.urlsVideosFirstCitizens;
        this.objToLoadVideo.one = true;
        this.imgPower = `./assets/images/${this.dataStore.brand}-miles/iphone-power.png`
      }

    }
  }

  ngAfterContentInit() {
    this.changeLanguaje();

  }

  changeLanguaje() {
    this.translate.onLangChange.subscribe((data) => {
      if (data.lang === 'es') {
        this.imgPower = `./assets/images/${this.dataStore.brand}-miles/es/iphone-power.png`;
        this.urlsVideos = environment.brands[this.dataStore.brand].videosLandingES.sectionTwo;
      } else {
        this.imgPower = `./assets/images/${this.dataStore.brand}-miles/iphone-power.png`;
        this.urlsVideos = environment.brands[this.dataStore.brand].videosLandingEN.sectionTwo;
      }
    });
  }




  openSection() {
    this.objToLoadVideo.one = true;
    this.objToLoadVideo.two = true;
    this.objToLoadVideo.three = true;
    const tag = this.el.nativeElement.querySelector('.bl-open');
    const tagBtn = this.el.nativeElement.querySelector('.button');
    tag.classList.toggle('open');
    tagBtn.classList.toggle('active');
  }


  closeSection() {
    const tag = this.el.nativeElement.querySelector('.bl-open');
    const tagBtn = this.el.nativeElement.querySelector('.button');
    tag.classList.remove('open');
    tagBtn.classList.remove('active');
  }

  selectedChangePowerMat(index) {
    // const videos = this.el.nativeElement.querySelectorAll("section.s-more-power video");
    const videos = index._elementRef.nativeElement.querySelectorAll("video");
    videos.forEach((video, i) => {
      video.muted = true;
      video.loop = true;
      if (video != null) {
        video.pause();
      }
    });

    const videosActives = index._elementRef.nativeElement.querySelectorAll("mat-tab-group mat-tab-body video");
    videosActives.forEach((video, i) => {
      video.muted = true;
      video.loop = true;
      if (video != null) {
        video.play();
      }
    });
  }
}
