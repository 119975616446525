import { Component, HostListener, OnInit } from '@angular/core';
import { EProduct } from '../../component/searched/models/searched.model';
import { AccountSumaryDto, keys, LifeMilesTravelService, StoreServiceLibrary, UserData } from '@wearenovae/novae-core-services';
import { keysUniversal, keysPostMessage } from 'src/app/resources/keys.resources';
import { InitService } from '../../services/init.service';
import { TranslateService } from '@ngx-translate/core';
import { ActionEventService } from '../../services/action-event.service';
import { environment } from '../../../environments/environment';
import { DataStoreService } from '../../services/data-store.service';
import { DataService } from '../../services/data.service';
import { SmartProfileService } from '../../services/smart-profile.service';
import { ActivatedRoute, Router } from '@angular/router';
import { LoadingScreenService } from '../../services/loading-screen.service';
import { JuniperService } from '../../services/juniper.service';
import { MatDialog } from '@angular/material/dialog';
import { AlertErrorComponent } from '../../component/product-detail/alert-error/alert-error.component';
import { LanguageService } from '../../component/language/language.service';
import { LoginComponent } from '../../container/shared/login/login.component';
import { DatePipe } from '@angular/common';
import { GoogleAnalytics } from '../../resources/GoogleAnalytics.resource';
import { PassengersLandingComponent } from 'src/app/component/passengers-landing/passengers-landing.component';
import { MilesFormatPipe } from '../../pipes/miles-format.pipe';
import { UtilsResource } from 'src/app/resources/UtilsResource.resource';


@Component({
  selector: 'app-landing-lifemiles',
  templateUrl: './landing-lifemiles.component.html',
  styleUrls: ['./landing-lifemiles.component.css'],
  providers: [DatePipe]
})
export class LandingLifeMilesComponent implements OnInit {
  EProduct = EProduct;
  iframeScrollHeight = 'calc(100vh - 175px)';
  iframeScrollWidth = '100%';
  border = '0';
  classSearchContent = 'buscador-flotante';
  flagUseRedemtionBuyMiles = false;
  user: UserData;
  tokenAuth: string;
  memNum: string;

  objSearch = {
    destinationIATA: '',
    originIATA: '',
    startDate: '',
    endDate: '',
    adt: '',
    chd: '',
    inf: '',
    ages: '',
    urlMultiDestino: '',
    itineraryType: '',
    flightClass: '',
    appendHashParams: ''
  };

  useSearchIframe: any;
  purchaceId = 0;
  selectedTaxes = 0;
  percentageUser: any;

  /**
   * Para hacer pruebas
   */
  // urlSearchIframe = environment.brands[this.dataStore.brand].urlSearchIframe;
  urlSearchIframe: any;

  urlTravelDefault: any;

  languageSend: string;
  messageNeedToken: string;
  locationLoader: string;
  onePocketUrl: string;
  reedemtionPointsDefault: any;
  haveSearchData: boolean = true;
  activateInfoCabin: boolean = false;
  cabinTypeValidate: string = '';

  public hasBenefits = false;
  public hasActivities = true;
  public flagJuniperIframeIsLoad = false;

  activateCard = false;
  msjReservationInfo: any;

  get tabSelect(): string {
    return this.dataService.productSelected;
  }

  get brand() {
    return this.dataStore.brand;
  }

  set tabSelect(value: string) {
    this.dataService.productSelected = value as EProduct;
    this.dataStore.preloaderImg = value;
  }

  constructor(
    private storeServiceLibrary: StoreServiceLibrary,
    private initService: InitService,
    private translate: TranslateService,
    private actionsEventService: ActionEventService,
    private dataStore: DataStoreService,
    private dataService: DataService,
    private smartProfileService: SmartProfileService,
    private loadingScreenService: LoadingScreenService,
    private activateRouter: ActivatedRoute,
    private lifeMilesService: LifeMilesTravelService,
    private router: Router,
    private juniperService: JuniperService,
    private dialog: MatDialog,
    private languajeService: LanguageService,
    private datePipe: DatePipe,
    private milesFormat: MilesFormatPipe
  ) {
    this.initService.isInitializedBrand.subscribe((isInitialized) => {
      if (isInitialized) {
        this.languageSend = this.translate.currentLang;
        this.onePocketUrl = this.storeServiceLibrary.getJustOneUniversalPropertie(keysUniversal.onePocket_url) || '';
        this.getRouterParams();
      }
    });
  }

  ngOnInit() {
    if (this.useSearchIframe && this.urlSearchIframe) {
      this.useSearchIframe = true;
      this.classSearchContent = 'buscador-iframe';
      this.urlSearchIframe = `${this.urlSearchIframe}`;
      this.translate.onLangChange.subscribe(dataService => {
        this.urlSearchIframe = `${this.urlSearchIframe}`;
      });
      this.actionsEventService.getOtherUrl.subscribe(url => {
        this.urlSearchIframe = url;
      });
    } else {
      if (this.useSearchIframe) {
        this.classSearchContent = 'buscador-iframe';
        this.urlSearchIframe = `${this.urlTravelDefault}`;
        this.translate.onLangChange.subscribe(dataService => {
          this.urlSearchIframe = `${this.urlTravelDefault}`;
        });
        this.actionsEventService.getOtherUrl.subscribe(url => {
          this.urlSearchIframe = url;
        });
      }
    }

    this.initService.isInitializedBrand.subscribe(isInitialized => {
      if (isInitialized) {
        this.hasBenefits = environment.brands[this.dataStore.brand].config.hasBenefits;
        if (environment.brands[this.dataStore.brand].config.hasActivities) {
          this.hasActivities = environment.brands[this.dataStore.brand].config.hasActivities;
        }
      }
    });
    this.tabSelect = this.dataService.productSelected;
    if (this.dataService.token && this.dataService.token !== '') {
      this.smartProfileService.setSmartProfilePreferences();
    }
  }

  getRouterParams() {
    this.activateRouter.queryParams.subscribe((data: any) => {
      if (data['PARAM']) {
        this.starLoading();
        const param = data['PARAM'];
        this.validateSession(param, 'PARAM');
      }

      if (data['payload']) {
        const payload = data['payload'];
        this.validateSession(payload, 'payload');
      }
      const valueToValidate = this.validateObjectKey(data);

      if (valueToValidate) {
        this.errorModal('ALERT.ERROR_BOOKING.boddy');
      }

    });
  }

  validateObjectKey(data) {
    let flagOpenWarningModal: boolean;
    if (Object.entries(data).length > 0) {
      const keysToValue = Object.keys(data);
      keysToValue.map((items) => {
        if (items == 'PARAM' || items == 'payload') {
          flagOpenWarningModal = false;
        } else {
          flagOpenWarningModal = true;
        }
      });
      return flagOpenWarningModal;
    } else {
      return true;
    }

  }

  validateSession(data, type) {
    if (type === 'PARAM') {
      this.lifeMilesService.validateSession(encodeURIComponent(data)).subscribe((resp: any) => {
        if (resp && resp['infoUser']) {
          let userData = this.storeServiceLibrary.getUserData();
          this.dataService.token = resp['infoUser'].authenticationToken;
          this.tokenAuth = resp['infoUser'].authenticationToken;
          if (userData) {
            userData.authenticationToken = resp['infoUser'].authenticationToken;
            userData.balance = resp['infoUser'].balance;
            if (userData.accountSumaryDto) {
              userData.accountSumaryDto.firstName = resp['infoUser'].name;
              userData.accountSumaryDto.lastName1 = resp['infoUser'].lastName;
            } else {
              userData.accountSumaryDto = new AccountSumaryDto();
              userData.accountSumaryDto.firstName = resp['infoUser'].name;
              userData.accountSumaryDto.lastName1 = resp['infoUser'].lastName;
            }
          } else {
            userData = new UserData();
            userData.accountSumaryDto = new AccountSumaryDto();
            userData.authenticationToken = resp['infoUser'].authenticationToken;
            userData.accountSumaryDto.firstName = resp['infoUser'].name;
            userData.accountSumaryDto.lastName1 = resp['infoUser'].lastName;
            userData.balance = resp['infoUser'].balance;
          }
          this.storeServiceLibrary.setUserData(userData);
          this.user = userData;
          this.actionsEventService.sendInfoUserLifeMiles(this.user);
          this.activateCard = true;
          if (resp['infoTravel']) {
            this.validateInfoTravel(resp);
          } else {
            this.validateInfoTravel(null);
          }
        }
        this.stopLoading();
      }, (err) => {
        this.errorModal('ALERT.ERROR_BOOKING.boddy');
      });
    } else if (type === 'payload') {
      this.lifeMilesService.validateTravel(encodeURIComponent(data)).subscribe((resp: any) => {
        /*
        if(resp.adt && resp.adt == '1') {
          this.validateInfoTravel(resp);
        } else {
          this.validPassengers(resp);
        }
        //*/
        this.validateInfoTravel(resp);
      }, (err) => {
        this.errorModal('ALERT.ERROR_BOOKING.boddy');
      });
    }
  }

  starLoading() {
    this.loadingScreenService.startLoading();
  }

  stopLoading() {
    this.loadingScreenService.stopLoading();
  }

  validPassengers(travelResponse: any) {
    const heightPadding = this.dataStore.isMobileDevice ? '0px' : '40px';
    const widthPadding = this.dataStore.isMobileDevice ? '0px' : '16vw';

    const passengers = this.dialog.open(PassengersLandingComponent, {
      maxWidth: '50vw',
      width: 'calc(5ovw - ' + widthPadding + ')',
      height: 'calc(50vh - ' + heightPadding + ')',
      minHeight: '59vh',
      disableClose: true,
      panelClass: 'modal-passengers',
      data: {
        totalPassenger: travelResponse.adt
      }
    });

    passengers.afterClosed().subscribe((responsePassengers: any) => {
      travelResponse.adt = responsePassengers.adults;
      travelResponse.inf = responsePassengers.infants;
      travelResponse.infAges = responsePassengers.infantsAges;
      travelResponse.chd = responsePassengers.children;
      travelResponse.chdAges = responsePassengers.childrenAges;
      this.validateInfoTravel(travelResponse);
    });
  }

  errorModal(message) {
    const heightPadding = this.dataStore.isMobileDevice ? '0px' : '40px';
    const widthPadding = this.dataStore.isMobileDevice ? '0px' : '16vw';

    const dialogRef = this.dialog.open(AlertErrorComponent, {
      maxWidth: '50vw',
      width: 'calc(50vw - ' + widthPadding + ')',
      height: 'calc(50vh - ' + heightPadding + ')',
      minHeight: '59vh',
      disableClose: true,
      data: {
        tittle: 'ALERT.ERROR_BOOKING.title',
        error: `${message}`,
        validateHaveGoToLogin: true,
        showIconClose: false
      }
    });

    dialogRef.afterClosed().subscribe(() => {
      /**
       * Aca se haria la logica para desplguear
       */
      this.dataService.token = '';
      this.purchaceId = null;
      this.router.navigateByUrl('lifemiles-travel');
    });
  }

  @HostListener('window:message', ['$event'])
  receiveMessage(event: MessageEvent) {
    const iframe = document.getElementById('iframe_juniper_lm');

    UtilsResource.ShowLog('*** LandingLifeMilesComponent - receiveMessage - event', event);

    if (event.data) {
      let dataEvent: any;
      try {
        dataEvent = JSON.parse(event.data);
      } catch (error) {
        dataEvent = event.data;
      }

      if (typeof dataEvent === 'object' && dataEvent.scrollHeight) {
        /*
        if (this.haveSearchData) {
          this.iframeScrollHeight = dataEvent.scrollHeight + 'px';
        } 
        */
        //this.iframeScrollHeight = dataEvent.scrollHeight + 'px';
      } else if (dataEvent === 'getUrlWidgetPortal') {

        const message = {
          0: 'urlWidgetPortal',
          1: window.location.origin
        };
        this.dataService.sendPostMessage(message, '*', iframe);

      } else if (dataEvent.type === 'UP_LOGIN_MODAL') {
        this.openLogin();

      } else if (typeof dataEvent === 'object' && dataEvent.type && dataEvent.type === 'ID_PURCHASE') {
        this.purchaceId = dataEvent.purchaseId;
        this.selectedTaxes = dataEvent.selectedTaxes;
        this.percentageUser = dataEvent.userPorcentage;
        this.goToOnepocketPay();

      } else if (typeof dataEvent === 'object' && dataEvent.typeMessage === 'OPEN_EXTERNAL_URL') {
        this.dataService.openUrlExternal(dataEvent.url, dataEvent.target, dataEvent.features);

      } else if (typeof dataEvent === 'object' && dataEvent.typeMessage && dataEvent.typeMessage === 'JUNIPER_PAGE_IS_LOAD') {
        this.flagJuniperIframeIsLoad = true;
        this.sendPostMessageInitDataToJuniper();

        const location = dataEvent.location;
        let href = dataEvent.location.href;
        if (!href || href == undefined || href == 'undefined') {
          href = null;
        }
        const tmpLocation = GoogleAnalytics.clearLocationToAnalytics(location);
        GoogleAnalytics.sendEventFirebase('ga_event', 'TRAVEL_SEARCH', href, JSON.stringify(tmpLocation));

        if (this.tokenAuth) {
          this.getAccount(this.tokenAuth);
        }
      } else if (dataEvent.typeMessage === 'TRACKING_DATA') {
        let objTrackingId = {
          category: '',
          action: '',
          label: '',
          params: ''
        }
        if (dataEvent.objectTracking) {
          objTrackingId = dataEvent.objectTracking;
        }
        
        let params = {};
        if (objTrackingId.params) {
          try {
            params = JSON.parse(objTrackingId.params);
          } catch (error) {
            UtilsResource.ShowLog('*** LandingLifemilesComponent - receiveMessage - error', error);          
          }
        }
  
        GoogleAnalytics.sendEventFirebase('ga_event', objTrackingId.category, objTrackingId.action, objTrackingId.label, params);
      }
    }
  }

  goToOnepocketPay() {
    this.router.navigateByUrl('onepocket-pay', { state: { purchaceId: this.purchaceId, selectedTaxes: this.selectedTaxes, userPorcentage: this.percentageUser } });
  }

  validateInfoTravel(info) {
    if (info && info['authenticationToken']) {
      let userData = this.storeServiceLibrary.getUserData();
      this.dataService.token = info['authenticationToken'];
      this.tokenAuth = info['authenticationToken'];
      if (userData) {
        userData.authenticationToken = info['authenticationToken'];
        userData.balance = info['blnc'];
        userData['memNum'] = info['memNum'];
        if (userData.accountSumaryDto) {
          userData.accountSumaryDto.firstName = info['prfNam'];
        } else {
          userData.accountSumaryDto = new AccountSumaryDto();
          userData.accountSumaryDto.firstName = info['prfNam'];
        }
      } else {
        userData = new UserData();
        userData.accountSumaryDto = new AccountSumaryDto();
        userData.authenticationToken = info['authenticationToken'];
        userData.accountSumaryDto.firstName = info['prfNam'];
        userData.balance = info['blnc'];
        userData['memNum'] = info['memNum'];
      }

      if (info && info['ods']) {
        const ods = info['ods'];
        const itineraryType = info['itinType'];
        
        switch (itineraryType) {
          case 'OW': {
            this.objSearch.itineraryType = 'oneway';
            this.objSearch.originIATA = ods[0].org;
            this.objSearch.destinationIATA = ods[0].dest;
            this.objSearch.startDate = ods[0].depDate;
            this.objSearch.adt = info['adt'];
            this.objSearch.chd = info['chd'];
            this.objSearch.inf = info['inf'];

            if (info['inf']) {
              this.objSearch.chd = `${Number(info['chd']) + Number(info['inf'])}`;
            }
            this.proccesAges(info);
            this.validateCabin(info['cabin']);
            
            const urlToChangeFligths = this.juniperService.searchFlightsJuniperLifeMiles('searchflights', this.objSearch);
            this.storeServiceLibrary.setUserData(userData);
            this.user = userData;
            this.actionsEventService.sendInfoUserLifeMiles(this.user);
            this.activateCard = true;
            let languajeService = info['lng'];
            this.translate.use(languajeService);
            this.translate.setDefaultLang(languajeService);
            this.languajeService.setSystemLanguaje(languajeService);
            this.urlSearchIframe = `${urlToChangeFligths}&idioma=${languajeService}`;
          }
            break;
          case 'RT': {
            this.objSearch.itineraryType = 'roundtrip';
            this.objSearch.originIATA = ods[0].org;
            this.objSearch.destinationIATA = ods[0].dest;
            this.objSearch.startDate = ods[0].depDate;
            this.objSearch.endDate = ods[1].depDate;
            this.objSearch.adt = info['adt'];
            this.objSearch.chd = info['chd'];
            this.objSearch.inf = info['inf'];

            if (info['inf']) {
              this.objSearch.chd = `${Number(info['chd']) + Number(info['inf'])}`;
            }
            this.proccesAges(info);
            this.validateCabin(info['cabin']);

            const urlToChangeFligths = this.juniperService.searchFlightsJuniperLifeMiles('searchflights', this.objSearch);
            this.storeServiceLibrary.setUserData(userData);
            this.user = userData;
            this.actionsEventService.sendInfoUserLifeMiles(this.user);
            this.activateCard = true;
            let languajeService = info['lng'];
            this.translate.use(languajeService);
            this.translate.setDefaultLang(languajeService);
            this.languajeService.setSystemLanguaje(languajeService);
            this.urlSearchIframe = `${urlToChangeFligths}&idioma=${languajeService}`;
          }
            break;
          case 'MC': {
            const urlTempMulti = this.validateMultiDest(ods);
            this.objSearch.itineraryType = 'multi';
            this.objSearch.adt = info['adt'];
            this.objSearch.chd = info['chd'];
            this.objSearch.inf = info['inf'];
            this.objSearch.urlMultiDestino = urlTempMulti;

            if (info['inf']) {
              this.objSearch.chd = `${Number(info['chd']) + Number(info['inf'])}`;
            }
            this.proccesAges(info);
            this.validateCabin(info['cabin']);
            
            const urlToChangeFligths = this.juniperService.searchFlightsJuniperMultiDestino('searchflights', this.objSearch);
            this.storeServiceLibrary.setUserData(userData);
            this.user = userData;
            this.actionsEventService.sendInfoUserLifeMiles(this.user);
            this.activateCard = true;
            let languajeService = info['lng'];
            this.translate.use(languajeService);
            this.translate.setDefaultLang(languajeService);
            this.languajeService.setSystemLanguaje(languajeService);
            this.urlSearchIframe = `${urlToChangeFligths}&idioma=${languajeService}`;
          }
            break;
          default:
            break;
        }
        this.activateInfoCabin = true;
      } else {
        this.activateInfoCabin = false;
        this.urlSearchIframe = this.storeServiceLibrary.getJustOneUniversalPropertie(keysUniversal.URL_TRAVEL_JUNIPER_LM) || '';
      }
      this.sendPostMessageTokenUserToJuniper();
    } else {
      this.activateInfoCabin = false;
      this.errorModal('ALERT.ERROR_BOOKING.boddy');
      this.urlSearchIframe = `${this.storeServiceLibrary.getJustOneUniversalPropertie(keysUniversal.URL_TRAVEL_JUNIPER_LM)}?idioma=${this.translate.currentLang}` || '';
    }
    this.useSearchIframe = this.storeServiceLibrary.getJustOneUniversalPropertie(keysUniversal.USE_SEARCH_IFRAME) || false;
  }

  validateMultiDest(ods) {
    let tempUrljuniper = ``;
    ods.map((item) => {
      tempUrljuniper += `${item.org},${item.dest},${this.datePipe.transform(item.depDate, 'yyyy-MM-dd')}|`
    });
    tempUrljuniper = tempUrljuniper.substring(0, tempUrljuniper.length - 1);
    return tempUrljuniper;
  }

  proccesAges(info) {
    if (info['chd']) {
      if (!info['chdAges']) {
        info['chdAges'] = ['08'];
      }
      for (let index = 0; index < info['chd']; index++) {
        this.objSearch.ages += info['chdAges'][index];
      }
    }

    if (info['inf']) {
      if (!info['infAges']) {
        info['infAges'] = '01';
      }
      for (let index = 0; index < info['inf']; index++) {
        this.objSearch.ages += info['infAges'][index];
      }
    }
  }

  sendPostMessageShowExtraElements() {
    const iframe = document.getElementById('iframe_juniper_lm');
    this.dataService.sendPostMessage(
      {
        "typeMessage": keysPostMessage.showJuniperExtraElements,
      }, '*', iframe
    );
  }

  sendPostMessageInitDataToJuniper() {
    const iframe = document.getElementById('iframe_juniper_lm');

    this.reedemtionPointsDefault = this.storeServiceLibrary.getJustOneUniversalPropertie(keysUniversal.DEFAULT_CONVERSION_FACTOR);
    this.locationLoaderSlider();

    let milesGiftFormat = environment.brands[this.dataStore.brand].milesGiftDefault;
    let messageDescSlider: string = '';

    if (milesGiftFormat) {
      milesGiftFormat = this.milesFormat.transform(milesGiftFormat);
    }

    this.messageNeedToken = this.getTranslate('SEARCH.needTokenPoints', { milesGift: milesGiftFormat });

    this.locationLoaderSlider();

    const userData = this.storeServiceLibrary.getUserData();
    const universalProperties = localStorage.getItem(keys.universalProperties);

    if (this.dataStore.brand === 'lifeMilesTravel') {
      this.flagUseRedemtionBuyMiles = true;
    } else {
      this.flagUseRedemtionBuyMiles = false;
    }

    let objPostMessage = {
      "typeMessage": keysPostMessage.initData,
      "applicationId": environment.brands[this.dataStore.brand].applicationId,
      "iframeSliderUrl": this.dataStore.iframeSliderUrl,
      "language": this.languageSend,
      "messageNeedToken": this.messageNeedToken,
      "locationLoader": this.locationLoader,
      "defaultConversionFactor": this.reedemtionPointsDefault,
      "userData": userData,
      "token": userData.authenticationToken,
      "flagRenderIframe": true,
      "withoutToken": true,
      "flagUseRedemtionBuyMiles": this.flagUseRedemtionBuyMiles,
      "universalProperties": universalProperties,
    };

    if (this.brand === 'credomatic') {
      messageDescSlider = this.getTranslate('SEARCH.htmlDescSlider', {});
      objPostMessage['messageDescSlider'] = messageDescSlider;
    }


    this.dataService.sendPostMessage(objPostMessage, '*', iframe);
  }

  locationLoaderSlider() {
    let preload = `${this.onePocketUrl}assets/img/loaders`;
    if (this.dataStore.brand === 'credomatic') {
      preload += `/bac/tutorial-SlideOP_${this.languageSend.toUpperCase()}.gif`;
    } else {
      preload += `/tutorial-SlideOP_${this.languageSend.toUpperCase()}.gif`;
    }
    this.locationLoader = preload;
  }

  sendPostMessageTokenUserToJuniper() {
    const iframe = document.getElementById('iframe_juniper_lm');

    const userData = this.storeServiceLibrary.getUserData();
    const universalProperties = localStorage.getItem(keys.universalProperties);

    if (this.dataStore.brand === 'lifeMilesTravel') {
      this.flagUseRedemtionBuyMiles = true;
    } else {
      this.flagUseRedemtionBuyMiles = false;
    }

    let objPostMessageToken = {
      "typeMessage": keysPostMessage.tokenUser,
      "userData": userData,
      "token": userData.authenticationToken,
      "flagRenderIframe": true,
      "applicationId": environment.brands[this.dataStore.brand].applicationId,
      "language": this.languageSend,
      "withoutToken": true,
      "flagUseRedemtionBuyMiles": this.flagUseRedemtionBuyMiles,
      "universalProperties": universalProperties,
      "htmlReservationInfo": this.msjReservationInfo
    };

    if (this.brand === 'credomatic') {
      this.msjReservationInfo = this.getTranslate('SEARCH.htmlReservationInfo', {});
      objPostMessageToken['htmlReservationInfo'] = this.msjReservationInfo;
    }

    this.dataService.sendPostMessage(objPostMessageToken, '*', iframe);
  }

  getAccount(token: string) {
    let userData = this.storeServiceLibrary.getUserData();
    userData.authenticationToken = token;
    this.storeServiceLibrary.setUserData(userData);
    this.smartProfileService.getAccountMethod(token).then((resp) => {
      this.sendPostMessageTokenUserToJuniper();
    }, (err) => {
      UtilsResource.NSMarkLogError('GET ACCOUNT ERROR', err);
    });
  }

  getTranslate(tag, params) {
    let messageDataFind: string;
    this.translate.get(`${tag}`, params).subscribe((value) => messageDataFind = value);
    return messageDataFind;
  }

  openLogin() {
    const dialogRef = this.dialog.open(LoginComponent, {
      panelClass: 'login-dialog'
    });

    dialogRef.afterClosed().subscribe(successful => {
      if (successful) {
        this.sendPostMessageTokenUserToJuniper();
      }
    });
  }

  validateCabin(cabinType) {
    switch (cabinType) {
      case '1':
        // Economica
        this.objSearch.flightClass = '';
        this.cabinTypeValidate = this.getTranslate('LANDING.lifeMilesTravel.RESULTS.economic', {});
        break;
      case '2':
        // Business
        this.objSearch.flightClass = 'C';
        this.cabinTypeValidate = this.getTranslate('LANDING.lifeMilesTravel.RESULTS.business', {});
        break;
      default:
        break;
    }    
  }
}
