import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Card } from './model/card';
import { Info } from './model/info';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from '../../../component/language/language.service';
import { DataStoreService } from '../../../services/data-store.service';

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss']
})
export class CardComponent implements OnInit {

  @Input() options: any;
  @Input() product: Card;
  @Input() info: Info[];
  @Input() selectedProductId: any;
  @Output() productDetailSelected = new EventEmitter();
  @Output() selectProduct = new EventEmitter();
  class = '';

  get productTranslate() {
    return this.translations['CARD']['CARD_PRICE'][this.options.type];
  }

  translations = {};

  price = '';
  nights: string;
  travellers: string;
  firstDetail: string;
  secondDetail: string;
  description: string;
  refundable: string;
  wifi: boolean;
  breakfast: boolean;

  constructor(
    private translateService: TranslateService,
    private languageService: LanguageService,
    private dataStore: DataStoreService,
  ) {
  }

  get priceMoneyPerTraveller() {
    return this.product.price.pointsPrice;
    return ((+this.product.price.pointsPrice / +this.travellers).toFixed(0)).toString();
  }
  get pricePointsPerTraveller() {
    return this.product.price.calculatedPrice;
    return ((+this.product.price.calculatedPrice / +this.travellers).toFixed(2)).toString();
  }

  get brand() {
    return this.dataStore.brand;
  }

  ngOnInit() {
    this.getTranslations().then(() => {
      if (this.info !== undefined) {
        this.product.info = this.info;
      }
      if (this.product.info !== undefined) {
        this.getNights();
        this.getTraveller();
        this.getBreakfast();
        this.getWifi();
        this.getRefundable();
        this.getDescription();
      }
      if (this.options.type === 'room') {
        this.getOptionRefundable();
      }
    });
  }
  isProductSelected() {
    return this.selectedProductId === this.product.id;
  }


  getNights() {
    const nights = this.product.info.find(item => {
      return (item.type === 'night');
    });
    if (nights !== undefined) {
      const nightsQty = parseInt(nights.value);
      this.nights = (nightsQty === 1) ? nightsQty + ' ' + this.translations['CARD']['night']
        : nightsQty + ' ' + this.translations['CARD']['nights'];
    }
  }

  getTraveller() {
    const travellers = this.product.info.find(item => {
      return (item.type === 'traveller');
    });
    if (travellers !== undefined) {
      const travellersQty = parseInt(travellers.value);
      if (this.options.type === 'hotel' && travellersQty === 1) {
        this.travellers = '1 ' + this.translations['CARD']['guest'];
      } else if (this.options.type === 'hotel' && travellersQty > 1) {
        this.travellers = travellersQty + ' ' + this.translations['CARD']['guests'];
      } else {
        this.travellers = travellersQty + ' ' + this.translations['CARD']['traveller'];
      }
    }

  }

  getRefundable() {
    const refundable = this.product.info.find(item => {
      return (item.type === 'refundable');
    });
    this.refundable = (refundable !== undefined) ? refundable.value : this.translations['CARD']['no_refundable'];
  }

  getOptionRefundable() {
    const refundable = !this.product.fullPenaltyOnCancellationAfterBooking;
    this.refundable = (refundable) ? this.translations['CARD']['refundable'] : this.translations['CARD']['no_refundable'];
  }

  getFirstDetail() {
    if (this.options.type === 'hotel' && this.product.hotelObj.options[0]) {
      const detail = this.product.hotelObj.selectedRoomOption.rooms.map(room => {
        return room.name;
      });
      return this.firstDetail = detail.join(', ').toLowerCase();
    } else if (this.options.type === 'room') {
      const firstDetail = this.product.rooms.map(item => item.name);
      return this.firstDetail = firstDetail.join(', ');
    }
  }
  getSecondDetail() {
    if (this.options.type === 'hotel' && this.product.hotelObj.options[0]) {
      return this.secondDetail = this.product.hotelObj.selectedRoomOption.board.toLowerCase();
    } else if (this.options.type === 'room') {
      return this.secondDetail = this.product.board.toLowerCase();
    }
  }

  getDescription() {
    this.description = this.product.description;
  }

  getBreakfast() {
    const breakfast = this.product.info.find(item => {
      return (item.type === 'breakfast');
    });
    this.breakfast = (breakfast !== undefined) ? true : false;
  }

  getWifi() {
    const wifi = this.product.info.find(item => {
      return (item.type === 'wifi');
    });
    this.wifi = (wifi !== undefined) ? true : false;
  }

  getCalculatedPrice() {
    if (this.options.type === 'hotel' && this.product.hotelObj.options[0]) {
      return this.product.hotelObj.selectedRoomOption.price.calculatedPrice;
    } else {
      return this.product.price.calculatedPrice;
    }
  }

  getTotalPrice() {
    if (this.options.type === 'hotel' && this.product.hotelObj.options[0]) {
      return this.product.hotelObj.selectedRoomOption.price.totalPrice;
    } else {
      return this.product.price.totalPrice;
    }
  }

  getPointsPrice() {
    if (this.options.type === 'hotel' && this.product.hotelObj.options[0]) {
      return this.product.hotelObj.selectedRoomOption.price.pointsPrice;
    } else {
      return this.product.price.pointsPrice;
    }
  }

  getCurrency() {
    if (this.product.price.currency) {
      return this.product.price.currency;
    } else {
      return '$';
    }
  }

  getTranslations() {
    return new Promise<any>((resolve, reject) => {
      this.languageService.systemLanguajeChange.subscribe(language => {
        if (language) {
          this.translateService.get([
            'CARD',
            'CARD.CARD_PRICE',
          ]).subscribe((trns) => {
            this.translations = trns;
            resolve(true);
          });
        }
      });
    });
  }

}
