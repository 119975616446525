import { IPlace, IRange } from './searched.model';
import { BehaviorSubject } from 'rxjs';
import { formatDate } from '@angular/common';
import { locale } from 'moment';
import moment from 'moment';

export class SearchedCar {
    withdrawal: IPlace;
    return?: IPlace;
    date: IRange;
    time: IRange;
    driverAge: number;

    private get translations() {
        return this.translations$.value['ISearched'] ? this.translations$.value['ISearched']['SearchedCar'] : [];
    }

    private get translationsDate() {
        return this.translations$.value['DATE'] || [];
    }

    constructor(
        public obj: SearchedCar,
        private translations$: BehaviorSubject<any>,
        private language$: BehaviorSubject<any>
    ) {
        this.withdrawal = this.obj.withdrawal;
        this.return = this.obj.return;
        this.date = this.obj.date;
        this.time = this.obj.time;
        this.driverAge = this.obj.driverAge;
    }

    /**
     * retorna true si el lugar de retiro y devolución son iguales
     */
    get isSamePlaceWithdrawalAndReturn(): boolean {
        return this.withdrawal.id === this.return.id;
    }

    /**
     * tiempo en formato "humano legible"
     */
    get timeToHumanReadable(): IRange {
        return {
            start: this.time.start ? this.time.start.substring(0, 5) : '',
            end: this.time.end ? this.time.end.substring(0, 5) : ''
        };
    }

    /**
     * retorna las fechas en formato "humano legible"
     * resolviendo el formato para cada idioma
     */
    get dateToHumanReadable(): IRange {
        return {
            start: formatDate(
                this.date.start,
                this.translationsDate['format_weekday'],
                locale(this.language$.value)
            ).toString(),
            end: formatDate(
                this.date.end,
                this.translationsDate['format_weekday'],
                locale(this.language$.value)
            ).toString()
        };
    }

    get qDays(): number {
        return moment(this.date.end).diff(this.date.start, 'days') + 1;
    }

    get qDaysToString(): string {
        return this.qDays + ' ' + (this.qDays === 1 ? this.translations['day'] : this.translations['days']);
    }
}
