import { Injectable } from '@angular/core';
import { IJourneyInfo } from '../models/flights/journeyParamsInfo';
import { HotelAvailabilityRequest } from '../models/hotel/request/hotel-availability-request.model';
import { HotelBookingRulesResponse } from '../models/hotel/response/hotel-booking-rules-response.model';
import { HotelAvailabilityOptions } from '../models/hotel/response/hotel-availability-options.model';
import { MainFormDinamicTexts } from '../models/main-form-dinamic-texts.model';
import { RentacarAvailabilityOption } from '../models/rentacar/response/rentacar-availability-option';
import { CarAvailabilityRequest } from '../pages/home/search/search-cars/search-cars.component';
import { JourneySearch } from '../models/flights/journeySearch.model';
import { ISmartProfilePreferences } from '../models/flights/smartProfilePreferences';
import { CountryDto, StoreServiceLibrary } from '@wearenovae/novae-core-services';
import { BenefitsRequest } from '../models/benefits/benefits-request.model';
import { SearchBenefits } from '../models/benefits/SearchBenefits.model';
import { ProductRequest } from '../models/benefit-old/product-request.model';
import { IBenefitResponse } from '../models/benefitResponce-model';
import { IActivityResult, IActivityBookingRequest } from '../models/activities/response/activity-availability.model';
import { IActivityBookingRules } from '../models/activities/response/activity-booking-rules.model';
import { keysUniversal } from 'src/app/resources/keys.resources';

@Injectable({
  providedIn: 'root'
})
export class DataStoreService {
  qRooms = 1;
  qPax = 1;
  qAdultos = 1;
  qNinos = 0;
  qBebes = 0;
  tabSelectActivate = 'flights';
  brandTargetCurrency: string;
  searchBenefits: SearchBenefits;
  creditCardDefult;

  mobile = false;
  countryList: CountryDto[];
  citiesList: any[];

  hotelInfoParams: IJourneyInfo = {};
  journeyParamsInfo: IJourneyInfo = {};
  carInfoParams: IJourneyInfo = {};

  selectedHotel: HotelAvailabilityOptions;

  checkAvailabilityHotel: HotelBookingRulesResponse;
  hotelDataSearch: HotelAvailabilityRequest;

  carDataSearch: CarAvailabilityRequest;
  mainFormDinamicTexts: MainFormDinamicTexts;

  selectedRentaCar: RentacarAvailabilityOption;

  journeySearch: JourneySearch;
  preloaderImg = 'general';
  mainFormTab = 'results';

  smartProfilePreferences: ISmartProfilePreferences;

  iframeUrl = '';
  iframeSliderUrl = '';
  iframeAlleLoginUrl = '';
  iframeAlleProfileUrl = '';
  countryOfResidence = 'US';

  isAlleMobile = false;

  // true si es mobile
  isMobileDevice = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

  brand: string;
  channelID: number;

  selectedBenefit: IBenefitResponse; // ProductDto;
  benefitsRequest: BenefitsRequest;
  set oauthTokenBenefits(token) {
    sessionStorage['oauthTokenBenefits'] = token;
  }
  get oauthTokenBenefits() {
    return sessionStorage['oauthTokenBenefits'];
  }

  benefitProductRequest: ProductRequest;
  activityBookingRequest: IActivityBookingRequest;

  latestPrice : {valuePoints: any, valuePayment: any, currency: any} = {currency:'',valuePayment:'',valuePoints:''};

  selectedDataBenefits: any = {
    // TODO: poner el uuid final
    queryUUID: 'ed5b9a5a-6d49-4b32-be77-1d2cbe898997',
    productId: '1',
    productName: 'Basic',
    productCurrency: 'EUR',
    productRate: 26,
    productConditions: '',
    subtitle: 'Producto exclusivo para LifeMiles',
    benefits: [
      {
        groupId: '1',
        benefitId: '1',
        benefitDesc: 'Retraso de equipaje',
        limit: '200',
        sort: 1
      },
      {
        groupId: '1',
        benefitId: '2',
        benefitDesc: 'Pérdida de conexión internacional',
        limit: '100',
        sort: 2
      },
      {
        groupId: '1',
        benefitId: '3',
        benefitDesc: 'Vuelo demorado',
        limit: '100',
        sort: 3
      }
    ],
    price: {
      totalPrice: '150',
      currency: 'EUR',
      calculatedPrice: '0',
      pointsPrice: '150',
      restPointValue: '0'
    }
  };

  // parametros para crear y validar campos edad en formulario: flight, hotel, car, benefits
  registryFormParam = {
    pageType: '',
    userTypesList: [],
    dateToValidate: ''
  };


  get selectedActivity(): IActivityResult {
    try {
      return (sessionStorage['selectedActivity'] !== undefined) ? JSON.parse(sessionStorage['selectedActivity']) : null;
    } catch (e) {
      return null;
    }
  }
  set selectedActivity(v: IActivityResult) {
    sessionStorage['selectedActivity'] = JSON.stringify(v);
  }

  get activityBookingRules(): IActivityBookingRules {
    try {
      return (sessionStorage['activityBookingRules'] !== undefined) ? JSON.parse(sessionStorage['activityBookingRules']) : null;
    } catch (e) {
      return null;
    }
  }
  set activityBookingRules(v: IActivityBookingRules) {
    sessionStorage['activityBookingRules'] = JSON.stringify(v);
  }

  porcentajeConversion = 100;

  constructor(private storeLibrary: StoreServiceLibrary) {
  }

}
