import { CardLayoutTwoModel, CardInfo, CardImage } from 'src/app/component/card/models/card-layout-two.model';
import { CardPrice } from 'src/app/component/card/models/card-price.model';
import { Slider } from 'src/app/component/slider-op/model/slider';
import { formatDate } from '@angular/common';
import { locale } from 'moment';
import moment from 'moment';
import { BehaviorSubject } from 'rxjs';

export class RentacarAvailabilityOption implements CardLayoutTwoModel {

    constructor(
        public id: string | number,
        public name: string,

        public ratePlanCode: string,
        public pickUpCode: string,
        public originZone: string,
        public dropOffCode: string,
        public destinationZone: string,
        public contractInfo: string,

        public address?: string,
        public description?: string,
        public category?: string,
        public info?: CardInfo[],
        public images?: CardImage[],
        public price?: CardPrice,
        public supplier?: string,
        public puckUp?: string,
        public dropOff?: string,
        public pickUpDate?: string,
        public dropOffDate?: string,
        public pickUpHour?: string,
        public dropOffHour?: string,

        public bookingRules?: {
            errors: any[],
            items: BookingRulesItem[],
            locationDetails: any,
            supplier: any,
            originZoneCode: any,
            destinationZoneCode: any,
            code: any
            cancellationPolicy?: any[]
        },

    ) { }



    static toArray(values: any): RentacarAvailabilityOption[] {
        const carDataSearch = (sessionStorage['carDataSearch'] !== undefined) ? JSON.parse(sessionStorage['carDataSearch']) : new Date();
        const translations$ = new BehaviorSubject<any>('');
        const language$ = new BehaviorSubject<any>('');
        const translationsDate = translations$.value['DATE'] || [];
        values.forEach((v, i) => {
            values[i] = new RentacarAvailabilityOption(
                v.id,
                v.name,

                v.ratePlanCode,
                v.pickUpCode,
                v.originZone,
                v.dropOffCode,
                v.destinationZone,
                v.contractInfo,

                v.address,
                v.description,
                v.category,
                v.info,
                v.images,
                v.price,
                v.supplier.supplierName,
                v.originZoneName,
                v.destinationZoneName,
                carDataSearch.start,
                carDataSearch.end,
                moment(carDataSearch.hour, ['HH:mm:ss']).format('HH:mm'),
                moment(carDataSearch.hourEnd, ['HH:mm:ss']).format('HH:mm')

            );
        });
        return values;
    }

    nameSelected = () => (this.name);
    subnameSelected = () => (null);
    getMoneyAmount = () => (
        (!this.price.calculatedPrice && this.price.pointsPrice)
            || this.price.calculatedPrice
            ? this.price.calculatedPrice : this.price.totalPrice)
    getPointAmount = () => (this.price.pointsPrice ? +this.price.pointsPrice : 0);
    refundable = () => (null);

    priceConversion = (conversionRate, percent, userPoints) => {
        Slider.getPriceConversion([{ price: this.price }], conversionRate, percent, userPoints);
    }

}


export interface BookingRulesItem {
    name: string;
    description: string;
    image: string;
    supplements: any;
    options: BookingRulesItemOption[]
}

export interface BookingRulesItemOption {
    paxes: any[];
    bookingCode: string;
    ratePlanCode: string;
    prices: any;
    cancellationPolicy: any;
}