import { Component, Input, OnInit, Output, ViewChildren, QueryList } from '@angular/core';
import moment from 'moment';
import { FilterDataType, FilterGroupType } from './filterParams';
import { FilterService, unique } from './filter.service';
import { FilterGroupComponent } from './filter-group/filter-group.component';
import { FormGroup } from '@angular/forms';
import { EventEmitter } from 'events';
import { invalid } from '@angular/compiler/src/render3/view/util';

@Component({
  selector: 'app-filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.scss']
})
export class FiltersComponent implements OnInit {
  optionId: any[] = [];
  counter = 0;
  fullIds = [];

  // Lista de posibles valores y sus combinaciones para los filtros
  filterOptionValuesArray = [];
  // Lista de posibles valores filtrada por valores elegidos
  filteredOptionValuesArray = [];
  // Listado de valores elegidos para usarlos de filtro
  selectedOptions: { key: string, value: any, multiOption?: boolean }[] = [];
  // Mapa de diccionarios con la clase como key
  dictionaryLists: { key: string, values: [] }[] = [];

  n = 0;
  x = 0;
  filtersForm: FormGroup;
  filterMatchCool: {};

  @Output() idsArrayToFilter = this.filterService.idsArrayToFilter;
  /**
   * listado resultado de la búsqueda de Productos (Vuelos, Hoteles, etc)
   */
  @Input() fullData;

  /**
   * Listado de objetos con los parametros necesarios para los filtros.
   * ( groupName: string;
   *   groupType: FilterGroupType;
   *   optionValuePath: string;
   *   optionsIdsPath: string;)
   */
  @Input() filtersParamsList;

  /**
   * Listado de Objetos para armado de filtros en donde se ordena el fulldata en cada filtro,
   * asignando un listado de opciones para el grupo.
   * Cada una de las opciones contendra un optionValue (resultado del optionValuePath)
   * y un listado de optionsId (resultado del optionsIdsPath)
   */
  filtersData: [{
    groupTitle?: string;
    groupName?: string; // Nombre del filtro
    groupType?: FilterGroupType; // Tipo del filtro
    groupOrder?: number;
    options?: [{
      optionValue?: any; // valor o nombre de la opcion a filtrar
      optionValueTranslate?: any; // valor o nombre de la opción a filtrar, pero que se traduce con "translate"
      optionCode?: any; // valor o nombre de la opcion sin traducir
      optionsId?: any[] // lista de id correspondiente a las opciones que tiene que mostrar al aplicar el filtro
      optionChecked?: boolean // valor de checkbox pre seteado
      filterMatch: {}
    }],
    // Para los casos en que el tipo de filtro sea slider necesita tener seteado:
    iconImg?: string,
    currency?: string,
    sliderMinValue?: any; // valor minimo
    sliderMaxValue?: any; // valor maximo
    optionType?: FilterDataType;
    dictionary?: any[];
    chequedValues?: any[],
    multipleOptions?: false
  }];

  constructor(private filterService: FilterService) {
  }

  ngOnInit() {
    this.filterService.preferencesFilter = undefined;
    this.setFiltersData(); // llamada al metodo que setea el listado de objetos con los atributos anteriormente descriptos

  }

  /**
   *  Por cada posicion del listado de resultados (fullData) se extraen los datos necesarios para cargar cada uno de
   *  los valores de los atributos de cada filtro (filterData) solicitado.
   */
  setFiltersData() {
    const that = this;
    this.fullData.forEach(data => {
      const filterMatches = {};
      that.filterMatchCool = {};
      that.filtersParamsList.forEach((filterGroup, i) => {

        // Por cada posision de filterParam list se setean las siguientes variables
        const dataId = eval(filterGroup.optionsIdsPath); // id resultante de la evaluacion del path especifico
        const optValue = eval(filterGroup.optionValuePath); // valor de la opcion resultante de la evaluacion de path especifico

        if (!this.filterMatchCool[filterGroup.groupName]) {
          this.filterMatchCool[filterGroup.groupName] = optValue;
        }

        let multipleOptions = false;
        if (filterGroup.multipleOptions) {
          multipleOptions = filterGroup.multipleOptions;
        }

        let currency = '';

        if (filterGroup.currencyPath !== undefined && filterGroup.currencyPath !== '') {
          currency = eval(filterGroup.currencyPath);
        }
        const dataType = filterGroup.optionType; // tipo de dato del grupo de filtros
        const includeText = filterGroup.includeText; // textos que debe evaluar para incluir id y valor en el grupo
        const excludeText = filterGroup.excludeText; // textos que debe evaluar para excluir id y valor en el grupo
        that.fullIds === undefined ? that.fullIds = [dataId] : that.fullIds.push(dataId);
        // fullId corresponde a todos los ids de todos los grupos de filtros. Se inicia o se llena el array segun corresponda
        const includeBool = includeText instanceof RegExp; // Evalua si la variable es una expresion regular
        const excludeBool = excludeText instanceof RegExp; // Evalua si la variable es una expresion regular
        // si include o exclude tiene valor pero el resultado del test es false, saltear todo
        if ((includeBool && !includeText.test(optValue)) || (excludeBool && excludeText.test(optValue))) {
          return;
        }

        // Cargamos el mapa de diccionarios utilizado
        if (that.dictionaryLists.findIndex(value => {
          return String(value.key) === String(filterGroup.groupName);
        }) === -1) {
          if (filterGroup.dictionary) {
            that.dictionaryLists.push({ key: filterGroup.groupName, values: filterGroup.dictionary });
          }
        }

        // Inicializar o Cargar filtersData
        that.fillFiltersData(
          filterGroup.groupName,
          filterGroup.groupType,
          filterGroup.optionType,
          filterGroup.dictionary,
          optValue,
          dataId,
          dataType,
          filterGroup.iconImg,
          currency,
          filterGroup.groupTitle,
          filterGroup.groupOrder,
          filterGroup.chequedValues,
          filterMatches,
          multipleOptions
        );
      });

      if (that.filterOptionValuesArray.findIndex(arrayFilter =>
        JSON.stringify(arrayFilter) === JSON.stringify(that.filterMatchCool)) === -1) {
        that.filterOptionValuesArray.push(that.filterMatchCool);
      }
    });

    this.fullIds = this.fullIds.filter(unique);
    this.filterService.fullIds = this.fullIds;
    for (let i = 0; i < this.filtersData.length; i++) {
      if (this.filtersData[i].chequedValues !== undefined || this.filtersData[i].dictionary !== undefined) {
        for (let j = 0; j < this.filtersData[i].options.length; j++) {
          if (this.filtersData[i].chequedValues !== undefined) {
            for (let k = 0; k < this.filtersData[i].chequedValues.length; k++) {
              if (this.filtersData[i].options[j].optionValue === this.filtersData[i].chequedValues[k]) {
                this.filtersData[i].options[j].optionChecked = true;
              }
            }
          }
          if (this.filtersData[i].dictionary !== undefined) {
            for (let k = 0; k < this.filtersData[i].dictionary.length; k++) {
              if (this.filtersData[i].options[j].optionValue === this.filtersData[i].dictionary[k].inValue) {
                this.filtersData[i].options[j].optionValue = this.filtersData[i].dictionary[k].outValue;
                this.filtersData[i].options[j].optionValueTranslate = this.filtersData[i].dictionary[k].outValueTranslate;
                this.filtersData[i].options[j].optionCode = this.filtersData[i].dictionary[k].inValue;
              }
            }
          }
          if (this.filtersData[i].options[j].optionChecked === true) {
            if (!this.filterService.preferencesFilter) {
              this.filterService.preferencesFilter = [{
                filterName: this.filtersData[i].groupName,
                filterType: this.filtersData[i].groupType,
                pushRemove: true,
                optionsIdsArray: this.filtersData[i].options[j].optionsId
              }];
            } else {
              this.filterService.preferencesFilter.push(
                {
                  filterName: this.filtersData[i].groupName,
                  filterType: this.filtersData[i].groupType,
                  pushRemove: true,
                  optionsIdsArray: this.filtersData[i].options[j].optionsId
                }
              );
            }
          }
        }
      }
    }

    this.filtersData.sort((a, b) => a.groupOrder - b.groupOrder);
    if (this.filterService.preferencesFilter !== undefined) {
      this.filterService.smartProfilePreferenceSeted =  true;
      this.filterService.preferencesFilter.forEach(activateFilter => {
        this.filterService.activateFilter(activateFilter.filterName,
          activateFilter.filterType, true, activateFilter.optionsIdsArray, false);
      });
    } else {
      this.filterService.smartProfilePreferenceSeted =  false;
    }
    this.filteredOptionValuesArray = this.filterOptionValuesArray.slice();
  }

  fillFiltersData(
    filterGroupName,
    filterGroupType,
    filterGroupOptionType,
    filterGroupDicctionary,
    optValue,
    dataId,
    dataType,
    iconImg,
    currency,
    filterGroupTitle,
    groupOrder,
    chequedValues,
    filterMatches,
    multipleOptions) {
    const that = this;
    // Si filtersData aun no esta inicializado
    if (this.filtersData === undefined) {
      this.filtersData = [{
        groupTitle: filterGroupTitle,
        groupName: filterGroupName,
        groupType: filterGroupType,
        options: [{ optionsId: [], optionValue: '', optionCode: '', filterMatch: {} }],
        optionType: filterGroupOptionType,
        dictionary: filterGroupDicctionary,
        currency: currency,
        groupOrder: groupOrder,
        chequedValues: chequedValues,
        multipleOptions: multipleOptions
      }];
    }

    // Luego de inicializarlo (o si ya esta inicializado) se cargan los siguientes grupos mediante push
    let groupNamePos = this.filtersData.findIndex(i => i.groupName === filterGroupName);
    if (groupNamePos === -1) {
      this.filtersData.push({
        groupTitle: filterGroupTitle,
        groupName: filterGroupName,
        groupType: filterGroupType,
        options: [{ optionsId: [], optionValue: '', optionCode: '', filterMatch: {} }],
        optionType: filterGroupOptionType,
        dictionary: filterGroupDicctionary,
        currency: currency,
        groupOrder: groupOrder,
        chequedValues: chequedValues,
        multipleOptions: multipleOptions
      });
    }
    groupNamePos = this.filtersData.findIndex(i => i.groupName === filterGroupName);

    if (dataType === 3) {
      this.filtersData[groupNamePos].iconImg = iconImg;
      optValue = parseInt(optValue);
      this.filterMatchCool[filterGroupName] = optValue;
    }
    this.fillOptionsOfFilterGroup(groupNamePos, optValue, filterGroupType, dataId, dataType, iconImg, filterMatches, filterGroupName);
  }

  fillOptionsOfFilterGroup(groupNamePos, optValue, filterGroupType, dataId, dataType, iconImg, filterMatches, filterGroupName) {
    const optionValuePos = this.filtersData[groupNamePos].options.findIndex(i => i.optionValue === optValue);
    switch (filterGroupType) {
      case 1:
      case 2:
        this.setFiltersDataForCheckbox(optionValuePos, groupNamePos, optValue, dataId, dataType, filterMatches, filterGroupName);
        break;
      case 3:
        this.setFiltersDataForSlider(optionValuePos, groupNamePos, optValue, dataId, dataType, filterMatches, filterGroupName);
        break;
      case 4:
      case 5:
        this.setFiltersDataForText(optionValuePos, groupNamePos, optValue, dataId, dataType, filterMatches, filterGroupName);
        break;
    }
  }

  /*fillFilterMatch(groupNamePos, optionValuePos, filterGroupName, optValue) {
    this.filtersParamsList.forEach((item, i) => {
      if (!this.filtersData[groupNamePos].options[optionValuePos].filterMatch[item.groupName]) {
        this.filtersData[groupNamePos].options[optionValuePos].filterMatch[item.groupName] = [];
      }

      if (this.filtersData[groupNamePos].options[optionValuePos].filterMatch[item.groupName].indexOf(optValue) === -1 && item.groupName === filterGroupName) {
        this.filtersData[groupNamePos].options[optionValuePos].filterMatch[filterGroupName].push(optValue)
      }

    });
  }
*/

  setFiltersDataForCheckbox(optionValuePos, groupNamePos, optValue, dataId, dataType, filterMatches, filterGroupName) {

    if (this.filtersData[groupNamePos].options[0].optionValue === '') {
      this.filtersData[groupNamePos].options = [
        {
          optionValue: optValue,
          optionCode: optValue,
          optionsId: [dataId],
          filterMatch: {}
        }
      ];
      optionValuePos = 0;
    } else {
      if (optionValuePos === -1) {
        this.filtersData[groupNamePos].options.push(
          {
            optionValue: optValue,
            optionCode: optValue,
            optionsId: [dataId],
            filterMatch: {}
          }
        );
        optionValuePos = this.filtersData[groupNamePos].options.length - 1;
      } else {
        this.filtersData[groupNamePos].options[optionValuePos].optionsId.push(dataId);
      }
    }
    /*this.fillFilterMatch(groupNamePos, optionValuePos, filterGroupName, optValue);*/

  }

  setFiltersDataForSlider(optionValuePos, groupNamePos, optValue, dataId, dataType, filterMatches, filterGroupName) {
    let optValueNumb;
    let minValue;
    let maxValue;
    switch (dataType) {
      case 1:
        const optValueDateControl = optValue.split('h');
        if (optValueDateControl[1]) {
          let dias;
          let extraMinutes;
          let minutes;
          if (optValueDateControl[0] > 23) {
            dias = Math.floor(optValueDateControl[0] / 24);
            extraMinutes = dias * 24 * 60;
            minutes = (optValueDateControl[0] - (dias * 24)) * 60;
          } else {
            minutes = optValueDateControl[0] * 60;
            extraMinutes = 0;
            dias = 0;
          }
          optValueNumb = minutes + extraMinutes + parseInt(optValueDateControl[1]);
        } else {
          optValueNumb = moment.duration(moment(optValue, ['H mm', 'HH mm']).format('HH:mm')).asMinutes();
        }
        minValue = this.filtersData[groupNamePos].sliderMinValue;
        maxValue = this.filtersData[groupNamePos].sliderMaxValue;
        break;
      case 2:
        optValueNumb = +optValue;
        minValue = +this.filtersData[groupNamePos].sliderMinValue;
        maxValue = +this.filtersData[groupNamePos].sliderMaxValue;
        break;
      case 3:
        break;
      case 4:
        break;
    }
    if (this.filtersData[groupNamePos].sliderMinValue === undefined) {
      this.filtersData[groupNamePos].sliderMinValue = optValueNumb;
      this.filtersData[groupNamePos].sliderMaxValue = optValueNumb;
    } else {
      if (optValueNumb < minValue) {
        this.filtersData[groupNamePos].sliderMinValue = optValueNumb;
      }
      if (optValueNumb > maxValue) {
        this.filtersData[groupNamePos].sliderMaxValue = optValueNumb;
      }
    }
    if (this.filtersData[groupNamePos].options[0].optionValue === '') {
      this.filtersData[groupNamePos].options = [
        {
          optionValue: optValueNumb,
          optionsId: [dataId],
          filterMatch: {}
        }
      ];
      optionValuePos = 0;
    } else {
      this.filtersData[groupNamePos].options.push(
        {
          optionValue: optValueNumb,
          optionsId: [dataId],
          filterMatch: {}
        }
      );
      optionValuePos = this.filtersData[groupNamePos].options.length - 1;
    }
    /* this.fillFilterMatch(groupNamePos, optionValuePos, filterGroupName, optValue)*/
  }

  setFiltersDataForText(optionValuePos, groupNamePos, optValue, dataId, dataType, filterMatches, filterGroupName) {
    if (this.filtersData[groupNamePos].options[0].optionValue === '') {
      this.filtersData[groupNamePos].options = [
        {
          optionValue: optValue,
          optionCode: optValue,
          optionsId: [dataId],
          filterMatch: {}
        }
      ];
      optionValuePos = 0;
    } else {
      if (optionValuePos === -1) {

        this.filtersData[groupNamePos].options.push(
          {
            optionValue: optValue,
            optionCode: optValue,
            optionsId: [dataId],
            filterMatch: {}
          }
        );
        optionValuePos = this.filtersData[groupNamePos].options.length - 1;
      } else {
        this.filtersData[groupNamePos].options[optionValuePos].optionsId.push(dataId);
      }
    }
    /* this.fillFilterMatch(groupNamePos, optionValuePos, filterGroupName, optValue)*/
  }

  /*  Recibimos el valor de la opcion seleccionada
      Mejorar: Recibir tambien de que listado viene el valor
      para evitar filtrar valores de otros filtros */
  addOptionToOptionsFilter(event: EventEmitter) {
    const dictionary = this.dictionaryLists.find(dictionary => String(dictionary.key) === String(event[0]));
    const translatedValue = this.findValueInDictionary(dictionary, event[1]);
    if (event[3]) {
      return; // Si es seleccion multiple, no debe quitar las otras opciones
    }
    const index = this.selectedOptions.findIndex(element => element.value === translatedValue);
    // Si ya estaba en la lista de seleccionados, entonces debemos quitarlo del filtro
    if (index > -1 && !event[2]) { // event[2] true = push // false = remove
      this.selectedOptions.splice(index, 1);
    } else if (index === -1 && event[2]) {
      // Caso contrario hay que agregarlo
      this.selectedOptions.push({ key: event[0], value: translatedValue, multiOption: event[3] });
    }
    this.filteredOptionValuesArray = this.filterFilteringOptions();
  }

  /*  Mejorar: Filtrar por nombre filtro y valor */
  filterFilteringOptions(): any[] {
    let tempFilterArray = this.filterOptionValuesArray.slice(0);
    this.selectedOptions.forEach(selectedOption => {
      tempFilterArray = tempFilterArray.filter(element => {
        return element[selectedOption.key] === selectedOption.value;
      });
      // if (selectedOption.multiOption) {
      //   tempFilterArray.push(tempFilterArray.filter( element => {
      //     return element[selectedOption.key] === selectedOption.value
      //   }));
      // }
    });
    return tempFilterArray;
  }

  //Devolvemos el valor si existe en el diccionario
  findValueInDictionary(dictionary: { key: string, values: any[] }, value: any): any {
    if (dictionary) {
      const dictionaryValues = dictionary.values.find(dictionaryValue => {
        return String(dictionaryValue.outValue) === String(value);
      });
      if (dictionaryValues) {
        return dictionaryValues.inValue;
      }
    }
    return value;
  }
}
