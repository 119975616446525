import { Component, OnDestroy, OnInit } from '@angular/core';
import { DataService } from '../../services/data.service';
import { RentacarAvailabilityResponse } from '../../models/rentacar/response/rentacar-availability-response';
import { RentacarAvailabilityOption } from '../../models/rentacar/response/rentacar-availability-option';
import { DataStoreService } from '../../services/data-store.service';
import { ModalOptions } from '../shared/modal/modal-options.model';
import { Router, ActivatedRoute } from '@angular/router';
import { SearchErrorType } from '../../models/search-error-type.model';
import { Subscription } from 'rxjs';
import { FilterDataType, FilterGroupType, IFilterParams } from '../shared/filters/filterParams';
import { SmartProfileService } from 'src/app/services/smart-profile.service';
import { CarAvailabilityRequest, ICarInfo } from 'src/app/pages/home/search/search-cars/search-cars.component';
import { formatDate } from '@angular/common';
import { locale } from 'moment';
import moment from 'moment';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from 'src/app/component/language/language.service';

@Component({
  selector: 'app-renta-car',
  templateUrl: './renta-car.component.html',
  styleUrls: ['./renta-car.component.scss']
})
export class RentaCarBodyComponent implements OnInit, OnDestroy {

  translations = {};
  showPriceSlider = false;
  cityName: string;
  carList: RentacarAvailabilityOption[];
  porcentajeConversion = 100;
  availabilitySubscription: Subscription;
  filtersParamsList: IFilterParams[];
  optionsCard = {
    type: 'car',
    style: 1
  };
  filteredID: any = {
    type: 'car',
    values: []
  };
  optionsModal: ModalOptions;
  openAlertModal = false;
  isMobile = false;
  showFilters = false;
  textJUNIP2: any;

  constructor(
    public dataService: DataService,
    public dataStore: DataStoreService,
    public router: Router,
    private activatedRoute: ActivatedRoute,
    private smartProfile: SmartProfileService,
    private languageService: LanguageService,
    private translateService: TranslateService
  ) {
  }

  filterListener(filteredIDs) {
    this.filteredID = {
      type: 'car',
      values: filteredIDs.IDs,
      isFiltered: filteredIDs.isFiltered
    };
    if (this.filteredID.values.length > 0) {
      const car = this.carList.find(item => {
        return item.id === this.filteredID.values[0];
      });
      this.dataService.saveSelectedRentaCar(car);
    }
  }

  ngOnInit() {
    this.getTranslations().then(async () => {
      this.dataStore.mainFormTab = 'results';
      if (this.activatedRoute.snapshot.routeConfig.path === 'cars-mobile') {
        await this.fillCarRequestFromUrl();
        await this.smartProfile.getAccountMethod(this.dataService.token);
        this.dataService.saveRequestCars(this.dataStore.carDataSearch);
        this.fillCarInfoParams();
        this.getCarAvailability();

        this.dataStore.isAlleMobile = true;
        this.dataService.saveMobile(true);
      } else {
        this.getCarAvailability();
        this.dataService.saveMobile(false);
      }
    }).catch(() => {});
  }
  fillCarInfoParams() {
    // información formateada para mostrar en el detalle
    const detalleDeBusqueda = <ICarInfo>{
      lugarRetiroDevolucionIguales: null,
      retiro: {
        iata: null,
        name: null,
        fecha: null,
        hora: null
      },
      devolucion: {
        iata: null,
        name: null,
        fecha: null,
        hora: null
      },
      edadConductor: null
    };

    // lugar de Retiro y Devolución
    let lugarRetiroDevolucion: string;
    detalleDeBusqueda.lugarRetiroDevolucionIguales = true;
    detalleDeBusqueda.retiro.iata = 'IATA';
    detalleDeBusqueda.retiro.name = 'Ciudad de retiro';
    detalleDeBusqueda.devolucion.iata = 'IATA';
    detalleDeBusqueda.devolucion.name = 'Ciudad de devolución';

    // retira y devuelve en el mismo lugar
    lugarRetiroDevolucion = ''; // 'Retiro y Devolución en ' + lugar.iata;
    detalleDeBusqueda.retiro.fecha = moment(this.dataStore.carDataSearch.start, ['YYYY-MM-DD']).format('DD/MM/YYYY');
    detalleDeBusqueda.retiro.hora = moment(this.dataStore.carDataSearch.hour, ['HH:mm:ss']).format('HH:mm');
    detalleDeBusqueda.devolucion.fecha = moment(this.dataStore.carDataSearch.end, ['YYYY-MM-DD']).format('DD/MM/YYYY');
    detalleDeBusqueda.devolucion.hora = moment(this.dataStore.carDataSearch.hourEnd, ['HH:mm:ss']).format('HH:mm');

    // FechaHora de alquiler y FechaHora de devolución
    let fechaAlquilerDevolucion: string;
    fechaAlquilerDevolucion = 'Alquiler ' +
      detalleDeBusqueda.retiro.fecha + ' ' +
      detalleDeBusqueda.retiro.hora + ' hs';
    fechaAlquilerDevolucion += ' - ';
    fechaAlquilerDevolucion += 'Devolución ' +
      detalleDeBusqueda.devolucion.fecha + ' ' +
      detalleDeBusqueda.devolucion.hora + ' hs';

    // traducimos la información de la búsqueda a un IJourneyInfo
    // que es lo que acepta el app-main-form
    this.dataStore.carInfoParams = Object.assign({ detalle: detalleDeBusqueda }, {
      originDestinyIata: lugarRetiroDevolucion,
      departureArrivalDates: fechaAlquilerDevolucion
    });
  }

  fillCarRequestFromUrl() {
    this.activatedRoute.queryParams.subscribe(params => {
      if (params['token'] !== undefined) {
        this.dataService.saveToken(decodeURIComponent(params['token'].replace(/ /g, '+')));
        const req: CarAvailabilityRequest = {
          ageDriver: params['agedriver'].trim(),
          start: formatDate(params['start'], 'yyyy-MM-dd', locale()).toString(),
          end: formatDate(params['end'], 'yyyy-MM-dd', locale()).toString(),
          hour: params['hourstart'].trim(),
          hourEnd: params['hourend'].trim(),
          originZone: params['originzone'].trim(),
          destinationZone: params['destinationzone'].trim(),
          countryOfResidence: params['countryofresidence'].trim(),
          targetCurrency: (params['targetCurrency']) ? params['targetCurrency'].trim() : 'USD'
        };
        this.dataStore.carDataSearch = req;
      }
    });
  }
  getCarAvailability() {
    this.availabilitySubscription = this.dataService.sendRequestAvailabilityCar().subscribe((res: RentacarAvailabilityResponse) => {
      if ((res.errors === null || res.errors.length === 0) && res.options.length > 0) {
        this.setFilterParamList();
        this.carList = res.options;
        this.carList = this.formatData(this.carList);
        this.dataService.saveSelectedRentaCar(this.carList[0]);
        this.actualizarPorcentaje(100);
      } else {
        this.dataService.flightSearchError = this.textJUNIP2;
        this.router.navigate(['cars/noResults']);
      }
    }, error => {
      this.dataService.flightSearchError = this.textJUNIP2;
      this.router.navigate(['cars/noResults']);
    });
  }

  setFilterParamList() {
    this.filtersParamsList = [
      {
        groupTitle: this.translations['RENTACAR_BODY.model'],
        groupName: 'Marca-Modelo',
        groupType: FilterGroupType.textAutocompleteFG,
        optionValuePath: 'data.name',
        optionsIdsPath: 'data.id',
        optionType: FilterDataType.autocompleteFilter,
        //Deben ser expresiones regulares
        includeText: undefined,
        excludeText: undefined,
        multipleOptions: false
      },
      {
        groupTitle: this.translations['RENTACAR_BODY.price'],
        groupName: 'price',
        groupType: FilterGroupType.sliderFG,
        optionValuePath: 'data.price.totalPrice',
        optionsIdsPath: 'data.id',
        optionType: FilterDataType.priceFilter,
        //Deben ser expresiones regulares
        includeText: undefined,
        excludeText: undefined,
        currencyPath: 'data.price.currency',
        multipleOptions: false
      },
    ];
  }

  selectProductListener(product: RentacarAvailabilityOption) {
    this.dataService.saveSelectedRentaCar(product);
  }

  formatData(list: RentacarAvailabilityOption[]) {
    list.forEach((item: RentacarAvailabilityOption, i) => {
      /* TODO: ELIMINAR CUANDO LOS DATOS VENGAN OK DESDE BACKEND */
      item.id = i.toString();
      // description separada por comas, para maquetado
      const descriptionMock = item.description ? item.description.split(',') : [];
      if (descriptionMock.length) {
        descriptionMock.forEach((element, index) => {
          descriptionMock[index] = element ? '<li>' + element.toString().trim() + '</li>' : '';
        });
      }
      item.description = '<ul>' + descriptionMock.join('') + '</ul>';
    });
    return list;
  }

  actualizarPorcentaje(evt) {
    const conversionRate = this.dataService.conversionRate;
    const userPointsSP = this.dataService.userPointsSP;
    this.dataService.getPriceConversion(this.carList, conversionRate, evt, userPointsSP);
    this.porcentajeConversion = evt;
  }

  selectOption() {

    const ratePlanCodeToken = this.dataStore.selectedRentaCar.ratePlanCode;

    this.dataService.sendCarBookingRulesRequest(
      {
        ratePlanCode: ratePlanCodeToken,
        providerCurrency: this.dataStore.selectedRentaCar.price.providerCurrency,
        providerTotalPrice: this.dataStore.selectedRentaCar.price.providerTotalPrice
      }).subscribe(
        (response: any) => {
          if (response.errors !== null && response.errors.length > 0) {
            this.bookingRulesError();
          } else {
            this.dataStore.selectedRentaCar.bookingRules = response;

            this.dataService.saveSelectedRentaCar(this.dataStore.selectedRentaCar);
            this.router.navigate(['/cars/registry']);
          }
        },
        error => {
          this.bookingRulesError();
        });
  }

  bookingRulesError() {
    this.optionsModal = {
      type: 'error',
      subtitle: 'Lo sentimos...',
      mainImgUrl: 'https://www.freeiconspng.com/uploads/error-icon-4.png',
      message: 'Hubo un error chequeando la disponibilidad de este auto, por favor intente nuevamente',
      cancelText: 'Volver',
      modalOptions: {
        size: 'md',
        ariaLabelledBy: 'modal-basic-title',
      }
    };
    this.openAlertModal = true;
  }

  modalListener($event) {
    this.openAlertModal = false;
  }

  ngOnDestroy(): void {
    if (this.availabilitySubscription && !this.availabilitySubscription.closed) {
      this.availabilitySubscription.unsubscribe();
    }
  }
  getTranslations() {
    return new Promise<any>((resolve, reject) => {
      this.languageService.systemLanguajeChange.subscribe(language => {
        if (language) {
          this.translateService.get([
            'RENTACAR_BODY.price',
            'RENTACAR_BODY.model',
            'NO_RESULTS.JUNIP2'
          ]).subscribe((trns) => {
            this.translations = trns;
            this.textJUNIP2 = trns['NO_RESULTS.JUNIP2'] as SearchErrorType;

            resolve(true);
          });
        }
      });
    });
  }
}
