/**
 * cambia el lenguaje del sistema mediante el select
 */
import { Component, AfterContentInit, OnDestroy, OnInit, Input, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { LanguagePrefix } from './language.model';
import { LanguageService } from './language.service';
import { FormBuilder, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { environment } from '../../../environments/environment';
import { DataStoreService } from '../../services/data-store.service';
import { StoreServiceLibrary } from '@wearenovae/novae-core-services';
import { TranslateService } from '@ngx-translate/core';
import { ActionEventService } from 'src/app/services/action-event.service';
import { DataService } from '../../services/data.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-languaje',
  templateUrl: './language.component.html',
  styleUrls: ['./language.component.scss']
})
export class LanguajeComponent implements OnInit, AfterContentInit, OnDestroy {

  @ViewChild('language') languageElement: ElementRef;

  @Input() ocultedLanguage: boolean;

  hasTwoLanguages = environment.brands[this.dataStoreService.brand].config.hasTwoLanguages;
  languaje: string;

  get languages() {
    return this.languageService.languageNames$.value;
  }

  form = this.fb.group({
    language: ['', Validators.required]
  });

  languajeReadySubscription: Subscription;

  constructor(
    public languageService: LanguageService,
    private fb: FormBuilder,
    private dataStoreService: DataStoreService,
    private storeServiceLibrary: StoreServiceLibrary,
    private translate: TranslateService,
    private actionsEvent: ActionEventService,
    private dataService: DataService,
    private route: Router
  ) {
  }

  ngOnInit() {
    this.actionsEvent.getSelectorLanguaje.subscribe((lang) => {
      this.form.get('language').setValue(lang, { emitEvent: false });
    });
  }

  ngAfterContentInit() {
    // inicializar selector basados en el lenguaje defualt del sistema
    this.languajeReadySubscription = this.languageService.isReady.subscribe((ready) => {
      if (ready) {
        this.form.get('language').setValue(this.storeServiceLibrary.getItemFromStorage('lang'), {
          emitEvent: false
        });
      }
    });
    // cuando cambia el lenguaje del selector
    this.form.get('language').valueChanges.subscribe((language: LanguagePrefix) => {
      this.dataService.vaidateOnePocketStayLanguaje().subscribe(observer => {
        if (observer === 2) {

          if (this.form.get('language')) {
            this.form.get('language').disable({ emitEvent: false });
          }

          // cambiar el lenguaje del sistema
          this.languageService.setSystemLanguaje(language).then(() => {
            this.storeServiceLibrary.setItemInStorage('lang', language);
            this.translate.use(language);
            this.form.get('language').enable({ emitEvent: false });
          });

        } else if (observer === 1) {
          this.dataService.openModalQuestion().subscribe((isLoad) => {
            if (isLoad) {
              if (this.form.get('language')) {
                this.form.get('language').disable({ emitEvent: false });
              }
              // cambiar el lenguaje del sistema
              this.languageService.setSystemLanguaje(language).then(() => {
                this.storeServiceLibrary.setItemInStorage('lang', language);
                this.translate.use(language);
                this.form.get('language').enable({ emitEvent: false });
              });

              this.route.navigateByUrl('/travel');
            } else {
              this.form.get('language').setValue(this.storeServiceLibrary.getItemFromStorage('lang'), {
                emitEvent: false
              });
            }
          });
        }
      });
    });
  }

  ngOnDestroy(): void {
    if (this.languajeReadySubscription && !this.languajeReadySubscription.closed) {
      this.languajeReadySubscription.unsubscribe();
    }
  }
}
