<div class="bl-precio">
  <p class="precio-paquete">Precio del paquete por persona</p>
  <p class="new-price">{{ price | milesFormat }} <span>Millas</span></p>
  <div class="clearfix"></div>
  <div class="mb-2 button-container mt-1 ps-1">
    <button class="btn-travel">
      Ver Detalle del Hotel
    </button>
  </div>

  <p class="ps-0 col-md-6 col-sm-6 col-xs-6" *ngIf="nights > 0">
    <i class="travel-icon icon-calendar" aria-hidden="true"></i>
    {{ nights }} noches
  </p>
  <p class="ps-0 col-md-6 col-sm-6 col-xs-6" *ngIf="travellers > 0">
    <i class="travel-icon icon-people" aria-hidden="true"></i>
    {{ travellers }} pasajeros
  </p>
</div>