import { Component, OnInit, ElementRef, Input } from '@angular/core';
import { DataStoreService } from '../../../services/data-store.service';
import { StoreServiceLibrary } from '@wearenovae/novae-core-services';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-section-four-scotia',
  templateUrl: './section-four-scotia.component.html',
  styleUrls: ['./section-four-scotia.component.scss']
})
export class SectionFourScotiaComponent implements OnInit {

  @Input() brand: string;
  //urlsVideos: any = environment.brands[this.dataStore.brand].videosLandingEN.sectionFour;
  iphoneTops = `./assets/images/${this.dataStore.brand}-miles/iphone-tops.png`;

  constructor(private dataStore: DataStoreService,
    private store: StoreServiceLibrary,
    private translate: TranslateService,
    private el: ElementRef) { }

  ngOnInit() {
    this.validateLanguaje();
    this.changeLanguaje();
  }

  changeLanguaje() {
    this.translate.onLangChange.subscribe((data) => {
      if (data.lang === 'es') {
        this.iphoneTops = `./assets/images/${this.dataStore.brand}-miles/es/iphone-tops.png`;
        //this.urlsVideos = environment.brands[this.dataStore.brand].videosLandingES.sectionFour;
      } else {
        this.iphoneTops = `./assets/images/${this.dataStore.brand}-miles/iphone-tops.png`;
        //this.urlsVideos = environment.brands[this.dataStore.brand].videosLandingEN.sectionFour;
      }
    });
  }



  openSection() {
    const tag = this.el.nativeElement.querySelector('.benefits-voyage');
    const tagBtn = this.el.nativeElement.querySelector('.button');
    tag.classList.toggle('open');
    tagBtn.classList.toggle('active');
  }



  closeSection() {
    const tag = this.el.nativeElement.querySelector('.benefits-voyage');
    const tagBtn = this.el.nativeElement.querySelector('.button');
    tag.classList.remove('open');
    tagBtn.classList.remove('active');
  }

  validateLanguaje() {
    let lang = this.translate.currentLang;
    if (lang === 'es') {
      this.iphoneTops = `./assets/images/${this.dataStore.brand}-miles/es/iphone-tops.png`;
      //this.urlsVideos = environment.brands[this.dataStore.brand].videosLandingES.sectionFour;
    } else {
      this.iphoneTops = `./assets/images/${this.dataStore.brand}-miles/iphone-tops.png`;
      //this.urlsVideos = environment.brands[this.dataStore.brand].videosLandingEN.sectionFour;
    }
  }

}
