import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor() { }

  hasToken(token = null) {
    if (token !== null) {
      return this.getAccountInfo(token);
    } else if (sessionStorage['token'] !== undefined) {
      token = sessionStorage['token'];
      return this.getAccountInfo(token);
    } else {
      return false;
    }
  }

  getAccountInfo(token: string) {
    return true;
  }
}
