import { Component, OnInit } from '@angular/core';
import {HotelDetailModalComponent} from '../hotel-detail-modal.component';

@Component({
  selector: 'app-hotel-detail-header',
  templateUrl: './hotel-detail-header.component.html',
  styleUrls: ['./hotel-detail-header.component.scss']
})
export class HotelDetailHeaderComponent implements OnInit {

  hotel: any;
  starsArr = [];
  constructor(public parentCmp: HotelDetailModalComponent) { }

  ngOnInit() {
    this.hotel = this.parentCmp.hotel;
    this.starsArr = (this.hotel.stars !== undefined && this.hotel.stars !== null)
      ? Array(parseInt(this.hotel.stars)).fill(0) : [];
  }

}
