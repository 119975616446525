import { Component, OnInit, Input, ElementRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { StoreServiceLibrary } from '@wearenovae/novae-core-services';
import { environment } from '../../../../environments/environment';
import { DataStoreService } from '../../../services/data-store.service';

@Component({
  selector: 'app-section-one',
  templateUrl: './section-one.component.html',
  styleUrls: ['./section-one.component.scss']
})
export class SectionOneComponent implements OnInit {
  imagesBgIphone1 = `./assets/images/${this.dataStore.brand}-miles/bg-mobiles.png`;
  @Input() brand: string;
  urlsVideos: any = {};
  objToLoadVideo = {
    one: false,
    two: false
  };
  constructor(private dataStore: DataStoreService,
    private translate: TranslateService,
    private store: StoreServiceLibrary,
    private el: ElementRef) { }

  ngOnInit() {
    this.urlsVideos = environment.brands[this.dataStore.brand].videosLanding.sectionOne;
  }

  
  openSection(number: any) {

    if (number == 1) {
      this.objToLoadVideo.one = true;
      let tag = this.el.nativeElement.querySelector("section.first-learn");
      let tag2 = this.el.nativeElement.querySelector("section.second-learn");
      let tagBtn = this.el.nativeElement.querySelector(".row-shoponline .bl-item:first-child button");
      let tagBtn2 = this.el.nativeElement.querySelector(".row-shoponline .bl-item:last-child button");
      tag.classList.toggle('open');
      tag2.classList.remove('open');
      tagBtn.classList.toggle('active');
      tagBtn2.classList.remove('active');
    } else if (number == 2) {
      this.objToLoadVideo.two = true;
      let tag = this.el.nativeElement.querySelector("section.first-learn");
      let tag2 = this.el.nativeElement.querySelector("section.second-learn");
      let tagBtn = this.el.nativeElement.querySelector(".row-shoponline .bl-item:first-child button");
      let tagBtn2 = this.el.nativeElement.querySelector(".row-shoponline .bl-item:last-child button");
      tag2.classList.toggle('open');
      tag.classList.remove('open');
      tagBtn.classList.remove('active');
      tagBtn2.classList.toggle('active');
    }

  }

  closeSection(number: any) {
    if (number == 1) {
      let tag = this.el.nativeElement.querySelector("section.first-learn");
      let tagBtn = this.el.nativeElement.querySelector(".row-shoponline .bl-item:first-child button");
      tag.classList.remove('open');
      tagBtn.classList.remove('active');
    } else if (number == 2) {
      let tag2 = this.el.nativeElement.querySelector("section.second-learn");
      let tagBtn2 = this.el.nativeElement.querySelector(".row-shoponline .bl-item:last-child button");
      tag2.classList.remove('open');
      tagBtn2.classList.remove('active');
    }
  }
}
