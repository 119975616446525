import { Component, Input, OnInit } from '@angular/core';
import { Confirmation } from '../confirmation.model';
import { DataStoreService } from 'src/app/services/data-store.service';
import { ConfirmationComponent } from '../confirmation.component';
import { SearchedService } from 'src/app/component/searched/searched.service';
import { HotelAvailabilityOptions } from 'src/app/models/hotel/response/hotel-availability-options.model';

@Component({
  selector: 'app-confirmation-hotel',
  templateUrl: './confirmation-hotel.component.html',
  styleUrls: ['./confirmation-hotel.component.scss']
})
export class ConfirmationHotelComponent implements OnInit {
  @Input() confirmationData: Confirmation;
  @Input() hotel: HotelAvailabilityOptions;


  translationDate = [];
  roomName: string;

  refundable: string;
  constructor(
    public dataStore: DataStoreService,
    private searchedService: SearchedService,
    public confirmationComponent: ConfirmationComponent
  ) {
  }

  get travellers() {
    return this.searchedService.getSearchedHotel().qGuestToString;
  }

  get qrooms() {
    return this.searchedService.getSearchedHotel().qRooms;
  }

  get checkIn() {
    return this.searchedService.getSearchedHotel().dateToHumanReadable.start;
  }

  get checkOut() {
    return this.searchedService.getSearchedHotel().dateToHumanReadable.end;
  }

  ngOnInit() {
    this.isRefundable();
    this.getRoomName();
    this.translationDate = this.confirmationComponent.trans['DATE'];
  }

  getNights(info: any[]) {
    if (info !== undefined) {
      const nights = info.find(item => {
        return (item.type === 'night');
      });
      if (nights !== undefined) {
        const nightsQty = parseInt(nights.value);
        return (nightsQty === 1) ? nightsQty + ' Noche'
          : nightsQty + ' Noches';
      }
    } else {
      return '';
    }
  }
  isRefundable() {
    const refundable = this.hotel.selectedOption.cancellationPolicy.refundable;
    
    // TODO: Agosto 27 2020 => Validar que estos textos esten localizados para que puedan cambiar entre idiomas, se identifico que se encontraban quemados en español.
    this.refundable = (refundable === 'Y')
      ? 'Refundable' // 'Reembolsable'
      : (refundable === 'N') ? 'Non Refundable' // 'No Reembolsable'
        : '';
  }

  getRoomName() {
    const firstDetail = this.hotel.selectedOption.rooms.map(item => item.name);
    this.roomName = firstDetail.join(', ');
  }

  get nights() {
    return this.searchedService.getSearchedHotel().qNightsToString;
  }
}
