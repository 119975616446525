import { Injectable } from '@angular/core';
import { createClient, Entry, ContentfulClientApi, EntryCollection } from 'contentful';
import { keysUniversal, StoreServiceLibrary } from '@wearenovae/novae-core-services';
import { InitService } from '../init.service';


@Injectable({
  providedIn: 'root'
})
export class ContentfulService {
  contentful = {
    space: 'space',
    accessToken: 'accestToken',
    slidersCode: {
    }
  };

  private cdaClient: ContentfulClientApi;

  constructor(
    private initService: InitService,
    private _storeLibrary: StoreServiceLibrary
  ) {
    this.initService.isInitializedBrand.subscribe((isInit) => {
      if (isInit) {
        let contentful = this._storeLibrary.getJustOneUniversalPropertie(keysUniversal.contentful);
        if (contentful != '') {
          this.contentful = JSON.parse(contentful);
          this.cdaClient = createClient({
            space: this.contentful.space,
            accessToken: this.contentful.accessToken
          });
        }
      }
    });
  }

  getContentful(query?: object): Promise<Entry<any>[]> {
    return this.cdaClient.getEntries(Object.assign({}, query))
      .then((res) => {
        return res.items;
      });
  }

  getContentfulV2(query?: object): Promise<EntryCollection<any>> {
    return this.cdaClient.getEntries(Object.assign({}, query));
  }
}
