import { keysPostMessage } from 'src/app/resources/keys.resources';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  OnDestroy
} from '@angular/core';
import { Router } from '@angular/router';
import { SmartProfileService } from 'src/app/services/smart-profile.service';
import { DataService } from 'src/app/services/data.service';
import { DataStoreService } from 'src/app/services/data-store.service';
import { InitService } from 'src/app/services/init.service';
import { ActionEventService } from '../../services/action-event.service';
import { ScotiaImplementationService } from '@wearenovae/novae-core-services';

declare var androidProxy: any;
declare var window: any;

/**
 * objeto precio que llega por postmessage
 */
interface ITotalCost {
  valuePoints: number;
  valueCreditCard: number;
  currency: string;
  tax: string;
}

@Component({
  selector: 'app-onepocket',
  templateUrl: './onepocket.component.html',
  styleUrls: ['./onepocket.component.scss']
})
export class OnepocketComponent implements OnInit, OnChanges, OnDestroy {

  @ViewChild('onePocketIframe') iframe;
  @Input() iframeURL: string;
  @Input() purchaseID: string;
  @Input() token: string;
  @Output() outputEmiter = new EventEmitter();
  status = false;
  purchaseStatus = false;
  public loaded = false;
  public iframeBaseUrl = '';
  public paymentStatus: boolean;
  isInitializedBrand: boolean;
  price: ITotalCost;
  typeCreditCard = '';

  openModal = false;
  modalOptions: {
    size: 'lg',
    ariaLabelledBy: 'modal-basic-title',
    backdrop: 'static',
    keyboard: false
  };

  private messageListenerFn = (event) => this.messageListener(event);
  useJwtToken: any;

  clickEvent() {
    this.status = !this.status;
  }

  constructor(
    private router: Router,
    private smartProfile: SmartProfileService,
    public dataService: DataService,
    public dataStore: DataStoreService,
    private initService: InitService,
    private _eventsService: ActionEventService,
    private scotiaServiceImplementation: ScotiaImplementationService,
  ) {
    this.initService.isInitializedBrand.subscribe((isInitializedBrand) => {
      if (isInitializedBrand) {
        setTimeout(() => {
          const pathArray = this.iframeURL.split('/');
          const protocol = pathArray[0];
          const host = pathArray[2];
          this.iframeBaseUrl = protocol + '//' + host;
          this.isInitializedBrand = isInitializedBrand;
          this.setPostMessageListener();
        }, 1000);
      }
    });
  }

  ngOnInit() {
    this.loaded = false;
    this.price = {
      valuePoints: this.dataStore.latestPrice.valuePoints, currency: this.dataStore.latestPrice.currency,
      valueCreditCard: this.dataStore.latestPrice.valuePayment, tax: ''
    };
    this.useJwtToken = this.dataService.getJwtUse();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.purchaseID !== undefined && !this.purchaseStatus) {
      if (this.dataStore.mobile) {
        if (typeof androidProxy !== 'undefined') {
          androidProxy.postMessage(JSON.stringify({ purchaseId: this.purchaseID }));
        } else if (window.webkit !== undefined && window.webkit !== 'undefined' && window.webkit.messageHandlers.native !== undefined && window.webkit.messageHandlers.native !== 'undefined') { // Si es iOS
          window.webkit.messageHandlers.native.postMessage(JSON.stringify({ purchaseId: this.purchaseID }));
        }
      } else {
        const iframe = document.getElementById('iframe_onepocket');
        const message = { typeMessage: keysPostMessage.idPurchaseOnePocket, purchaseID: this.purchaseID };
        this.sendPostMessage(iframe, message, this.iframeBaseUrl);
        this.purchaseStatus = !this.purchaseStatus;
      }
    }
  }

  setPostMessageListener() {
    if (window.addEventListener) {
      window.addEventListener('message', this.messageListenerFn, false);
    }
  }

  /*
  * ENVIAR EL TOKEN DE USUARIO A ONEPOCKET
  */
  onePocketInit(data: any) {
    this.paymentStatus = false;
    const iframe = document.getElementById('iframe_onepocket');
    const message = { typeMessage: keysPostMessage.tokenOnePocket, token: this.token };
    this.sendPostMessage(iframe, message, this.iframeBaseUrl);
  }

  /*
  * ENVIAR RESPUESTA DEL PAGO
  */
  onePocketPaymentResponse(data: any) {
    this.paymentStatus = data.paymentSuccess;
    this.smartProfile.getAccountMethod(this.dataService.token);
    this.dataService.getInfoCard();
    this.outputEmiter.emit(data);
    
  }

  /*
  * ENVIAR MOSTRAR TERMINOS Y CONDICIONES
  */
  onePocketTerms(data: any) {
    this.dataService.openTermsAndConditionsDialog();
  }

  sendPostMessage(iframe: any, message: any, targetOrigin: string) {
    if (iframe === null) {
      return;
    }
    const iWindow = (<HTMLIFrameElement>iframe).contentWindow;
    iWindow.postMessage(message, targetOrigin);
  }

  async messageListener(event: MessageEvent): Promise<any> {
    if (event.origin === this.iframeBaseUrl && event.data.typeMessage === 'INIT_ONEPOCKET_PAGE') {
      /* POSTMESSAGGE INICIAL DE ONEPOCKET */
      this.onePocketInit(event.data);
      this.loaded = true;
    } else if (event.origin === this.iframeBaseUrl && event.data.typeMessage === 'TERM_AND_CONDITION_ONEPOCKET') {
      /* POSTMESSAGGE AL PULSAR 'VER TERMINOS Y CONDICIONES' DE ONEPOCKET */
      this.onePocketTerms(event.data);
    } else if (event.origin === this.iframeBaseUrl && event.data.typeMessage === 'PAYMENT_RESPONSE_ONEPOCKET') {

      /* POSTMESSAGGE AL COMPLETAR PROCESO DE PAGO DE ONEPOCKET */
      this.onePocketPaymentResponse(event.data);
    } else if (event.origin === this.iframeBaseUrl && event.data.typeMessage === 'GO_TO_RESUME_ONEPOCKET') {
      /* POSTMESSAGGE AL VOLVER AL HOME DE ONEPOCKET */
      this.dataService.getInfoCard();
      this.router.navigate(['home']);
    } else if (event.origin === this.iframeBaseUrl && event.data.typeMessage === 'DETAIL_PAYMENT_ONEPOCKET') {
      this.price = event.data;
    } else if (event.data.typeMessage === 'REFRESH_USER_POINT') {
      this.dataService.getInfoCard();
    } 
    if (event.origin === this.iframeBaseUrl && event.data.typeMessage === 'SLIDER_DRAGEND_PAYMENT_PAGE') {
      this.price.valuePoints = event.data.valuePoints;
      this.price.valueCreditCard = event.data.valuePayment;
    }
    if (event.origin === this.iframeBaseUrl && event.data.typeMessage === 'TYPE_CREDIT_CARD_DEFUALT') {
      if (event.data.franquice === 3) {
        this._eventsService.setSubBrand('amex');
      } else {
        this._eventsService.setSubBrand('default');
      }
    }
  }

  ngOnDestroy(): void {
    window.removeEventListener('message', this.messageListenerFn, false);
  }

}
