import { Component, OnInit, Input } from '@angular/core';

import { DataStoreService } from '../../../services/data-store.service';
import { keysUniversal, StoreServiceLibrary } from '@wearenovae/novae-core-services';


@Component({
  selector: 'app-footer-fc',
  templateUrl: './footer-fc.component.html',
  styleUrls: ['./footer-fc.component.scss']
})
export class FooterFcComponent implements OnInit {
  @Input() brand: string;

  urlData: string = '';

  constructor(private dataStore: DataStoreService,
              private store: StoreServiceLibrary) { }

  ngOnInit() {
  }

  loadUrl() {
    const lang = this.store.getItemFromStorage('lang') || 'en';
    // const urlTerms = environment.brands[this.dataStore.brand].terminos_y_condiciones[lang.toUpperCase()];
    let urlTerms = this.store.getJustOneUniversalPropertie(keysUniversal.web_url_terms_and_conditions_en);

    if (lang) {
      if (lang == 'es') {
        urlTerms = this.store.getJustOneUniversalPropertie(keysUniversal.web_url_terms_and_conditions_es);
      }
    }
    if (!this.dataStore.isAlleMobile) {
      this.urlData = urlTerms;
    } else {
      this.urlData = `${urlTerms}`;
    }

    return this.urlData;
  }
  
  openUrl(url) {
    window.open(url, '_target');
  }

}
