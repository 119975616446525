import { Component, OnInit, ElementRef, Input, AfterContentInit } from '@angular/core';
import { DataStoreService } from '../../../services/data-store.service';
import { TranslateService } from '@ngx-translate/core';
import { StoreServiceLibrary } from '@wearenovae/novae-core-services';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-section-one-bac',
  templateUrl: './section-one-bac.component.html',
  styleUrls: ['./section-one-bac.component.scss']
})
export class SectionOneBacComponent implements OnInit, AfterContentInit {

  @Input() brand: string;
  urlsVideos: any = environment.brands[this.dataStore.brand].videosLandingEN.sectionOne;

  imagesBgIphone1 = `./assets/images/${this.dataStore.brand}-miles/bg-mobiles.png`;

  constructor(private dataStore: DataStoreService,
    private translate: TranslateService,
    private store: StoreServiceLibrary,
    private el: ElementRef) {
    this.brand = this.dataStore.brand;
  }

  ngOnInit() {
    this.changeLanguaje();
  }

  ngAfterContentInit() {
    this.changeLanguaje();
  }

  changeLanguaje() {
    this.translate.onLangChange.subscribe((data) => {
      if (data.lang === 'es') {
        this.imagesBgIphone1 = `./assets/images/${this.dataStore.brand}-miles/es/bg-mobiles.png`;
        this.urlsVideos = environment.brands[this.dataStore.brand].videosLandingES.sectionOne;
        this.imagesBgIphone1 = `./assets/images/${this.dataStore.brand}-miles/es/bg-mobiles.png`;
      } else {
        this.imagesBgIphone1 = `./assets/images/${this.dataStore.brand}-miles/bg-mobiles.png`;
        this.urlsVideos = environment.brands[this.dataStore.brand].videosLandingEN.sectionOne;
        this.imagesBgIphone1 = `./assets/images/${this.dataStore.brand}-miles/bg-mobiles.png`;
      }
    });
  }

}
