<div class="alert">
  <button class="close" (click)="close()" *ngIf="isShowIconClose">
    <i class="icon-close"></i>
  </button>
  <span class="cara-error"></span>
  <h1>{{ 'MODAL_QUESTION.atention' | translate }}
  </h1>
  <p [innerHTML]="'MODAL_QUESTION.really_change_window' | translate"></p>

<div class="bl-button">
  <button (click)="yes()" [innerHTML]="'MODAL_QUESTION.yes' | translate">Yes</button>
  <button (click)="close()" [innerHTML]="'MODAL_QUESTION.no' | translate">No</button>
</div>
</div>
