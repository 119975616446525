import { Component, OnInit, Input } from '@angular/core';
import { Flight } from 'src/app/models/flights/flight.model';
import { DataService } from 'src/app/services/data.service';
import moment from 'moment';
import { ConfirmationComponent } from '../confirmation.component';

@Component({
  selector: 'app-confirmation-flight',
  templateUrl: './confirmation-flight.component.html',
  styleUrls: ['./confirmation-flight.component.scss']
})
export class ConfirmationFlightComponent implements OnInit {


  @Input() flight: Flight;
  @Input() flightIndex: number;

  public isCollapsed = false;
  public translationDate = [];

  get flightType() {
    return (this.flightIndex === 0) ? 'CONFIRMATION.FLIGHT.outbound_flight' : 'CONFIRMATION.FLIGHT.return_flight';
  }

  constructor(
    public data: DataService,
    public confirmationComponent: ConfirmationComponent
  ) { }

  ngOnInit() {
    this.translationDate = this.confirmationComponent.trans['DATE'];
  }

  SegmentDepartureDate(departureDate) {
    return moment(departureDate).locale('es').format('ddd DD/MM');
  }

}
