import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators, FormArray } from '@angular/forms';
import { DebounceCall } from 'src/app/decorators/debounce.decorator';
import { Subscription } from 'rxjs';
import { DataService } from 'src/app/services/data.service';
import { LanguageService } from 'src/app/component/language/language.service';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';
import { locale } from 'moment';
import { formatDate } from '@angular/common';
import { Router } from '@angular/router';
import { BenefitsRequest } from 'src/app/models/benefits/benefits-request.model';
import { SearchedBenefit } from 'src/app/component/searched/models/searched-benefit.model';
import { SearchedService } from 'src/app/component/searched/searched.service';

import data from 'src/app/resources/screenAndEventsTracking.json';
import { GoogleAnalytics } from '../../../../resources/GoogleAnalytics.resource';
import { InitService } from '../../../../services/init.service';

@Component({
  selector: 'app-search-benefits',
  templateUrl: './search-benefits.component.html',
  styleUrls: ['./search-benefits.component.scss']
})
export class SearchBenefitsComponent implements OnInit {

  @ViewChild('dateStart') dateStart;
  @ViewChild('dateEnd') dateEnd;


  benefitsSearchForm = this.fb.group({
    origen: [null, Validators.required],
    destino: [null, Validators.required],
    dateSelect: [{
      startDate: moment(new Date()).add('1', 'day'),
      endDate: moment(new Date()).add('7', 'day')
    }, [Validators.required]],
    paxes: [null, Validators.required]
  });
  allCities = [];
  searchSbs: Subscription;
  originNotSelected = false;
  destinationNotSelected = false;
  validatorOriginDestiniDif = false;
  dayOfDifference = false;
  translations = [];
  minDate = moment(new Date()).add('1', 'day');
  locale: any = {
    format: '',
    autoclose: true
  };
  dateNotSelected = false;
  showPassengerFormControl = true;
  showPassengerForm = true;
  passengerNotSelected = false;

  /*
  * cuando cambia el idioma de la página, se oculta y muestra el input
  * para que se refleje el cambio de idioma
  */
  fixChangeLanguageInputDate: boolean;

  minDateEnd;
  selected = {
    startDate: moment(new Date()),
    endDate: moment(new Date())
  };

  constructor(
    private data: DataService,
    private languageService: LanguageService,
    private translateService: TranslateService,
    private fb: FormBuilder,
    private router: Router,
    private searchedService: SearchedService,
    private initService: InitService
  ) { }

  ngOnInit() {
    // this.trackingManager.setCurrentScreen('/benefits', data.BENEFITS.benefitsHomeScreen);
    this.getTranslations();
    this.initService.isInitializedBrand.subscribe((isInit) => {
      if (isInit) {
        GoogleAnalytics.setCurrentScreen('/benefits');
      }
    });
  }

  private getTranslations() {
    this.languageService.systemLanguajeChange.subscribe((language) => {
      if (language) {
        this.translateService.get([
          'DATE',
          'SEARCH_BENEFITS'
        ]).subscribe((translations) => {
          this.translations = translations;

          // local para los calendarios
          locale(language);
          this.locale.format = translations['DATE']['format'];
          this.locale.daysOfWeek = moment.weekdaysMin();
          this.locale.monthNames = moment.monthsShort();

          // fix hack para actualizar los selectores de fecha
          this.fixChangeLanguageInputDate = !this.fixChangeLanguageInputDate;
          setTimeout(() => {
            this.fixChangeLanguageInputDate = !this.fixChangeLanguageInputDate;
          }, 100);
        });
      }
    });
  }

  @DebounceCall(250)
  onSearch($event) {
    if (this.searchSbs && !this.searchSbs.closed) {
      // si la busqueda anterior no terminó, la cancelamos
      this.searchSbs.unsubscribe();
    }

    this.allCities = [];
    this.originNotSelected = false;
    this.destinationNotSelected = false;
    const searched = $event.term;
    if (searched.length < 3) {
      return;
    }

    // si la busqueda es de al menos 3 letras
    this.searchSbs = this.data.getCities($event.term).subscribe(cities => {
      // en vuelos aeropuertos siempre primero (por eso el sort)
      this.allCities = cities.sort((a, b) => {
        if (a && b && a.type && b.type) {
          return (<string>a.type).localeCompare(b.type);
        }

        return 0;
      });
      this.searchSbs.unsubscribe();
    });
  }

  /**
   * hamemos que el ng-select retorne siempre true en el search
   * porque lo que filtramos es cambar el array de items (onSearch)
   * y el componente esta pensado para tener en el array todo cargado
   * y buscar mostrando y ocutlando sobre esos items
   */
  returnTrue = () => true;

  invertFlightSelection() {
    if (this.benefitsSearchForm.controls['origen'].value !== undefined && this.benefitsSearchForm.controls['destino'].value !== undefined) {
      const nuevoDestino = this.benefitsSearchForm.controls['destino'].value;
      const nuevoOrigen = this.benefitsSearchForm.controls['origen'].value;
      this.benefitsSearchForm.controls.origen.setValue(nuevoDestino);
      this.benefitsSearchForm.controls.destino.setValue(nuevoOrigen);
    }
  }

  // validaciones del buscador
  busquedaValida(): boolean {
    let alMenosUnError = false;

    this.originNotSelected = false;
    this.destinationNotSelected = false;
    this.validatorOriginDestiniDif = false;
    this.dayOfDifference = false;
    this.passengerNotSelected = false;

    // seleccionar origen
    if (!this.benefitsSearchForm.get('origen').value) {
      this.originNotSelected = true;
    }

    // seleccionar destino
    if (!this.benefitsSearchForm.get('destino').value) {
      this.destinationNotSelected = true;
    }

    // origen y destino distintos
    if (this.benefitsSearchForm.get('origen').value &&
      this.benefitsSearchForm.get('destino').value &&
      this.benefitsSearchForm.get('origen').value.id === this.benefitsSearchForm.get('destino').value.id) {
      this.validatorOriginDestiniDif = true;
    }

    // duración del viaje (mínimo 1 día)
    if (moment(this.benefitsSearchForm.get('dateSelect').value.endDate)
      .diff(this.benefitsSearchForm.get('dateSelect').value.startDate, 'days') < 1
    ) {
      this.dayOfDifference = true;
    }

    if (this.benefitsSearchForm.get('paxes').value === null ||
      this.benefitsSearchForm.get('paxes').value.length === 0) {
      this.passengerNotSelected = true;
    }

    // si hay al menos un error, poner en true "alMenosUnError"
    if (this.originNotSelected ||
      this.destinationNotSelected ||
      this.validatorOriginDestiniDif ||
      this.dayOfDifference ||
      this.passengerNotSelected) {
      alMenosUnError = true;
    }

    // la búsqueda es válida si no hay ningún error
    const busquedaValida = !alMenosUnError;
    return busquedaValida;
  }

  search() {
    if (!this.busquedaValida()) {
      // la bùsqueda no se hace si hay algún error
      return;
    }

    // parámetros para buscar beneficios
    const requestParams: BenefitsRequest = {
      language: this.languageService.systemLanguajeChange.value.toUpperCase(),
      precisionCurrency: 2,
      scaleCurrency: 2,
      exchangeRate: 1.1,
      descExchangeRate: '$',
      nPassengers: this.benefitsSearchForm.get('paxes').value ? this.benefitsSearchForm.get('paxes').value.length : 0,
      flightData: [
        {
          dateFrom: formatDate(
            this.benefitsSearchForm.get('dateSelect').value.startDate,
            'yyyy-MM-dd',
            locale(this.languageService.systemLanguajeChange.value)
          ).toString(),
          dateTo: formatDate(
            this.benefitsSearchForm.get('dateSelect').value.endDate,
            'yyyy-MM-dd',
            locale(this.languageService.systemLanguajeChange.value)
          ).toString(),
          origin: this.benefitsSearchForm.get('origen').value.iata,
          destination: this.benefitsSearchForm.get('destino').value.iata,
          flightClass: 'Y'
        }
      ]
    };

    // valores que fueron buscados
    this.searchedService.setModel(<SearchedBenefit>{
      origin: this.benefitsSearchForm.get('origen').value,
      destination: this.benefitsSearchForm.get('destino').value,
      date: {
        start: this.benefitsSearchForm.get('dateSelect').value.startDate,
        end: this.benefitsSearchForm.get('dateSelect').value.endDate
      },
      paxes: this.benefitsSearchForm.get('paxes').value
    });

    this.data.saveBenefitRequest(requestParams);
    this.router.navigate(['/benefits/results']);

    // this.trackingManager.trackEventString(data.BENEFITS.searchBenefitsButton, data.BENEFITS.benefitsHomeScreen, '', JSON.stringify(requestParams));
    GoogleAnalytics.sendEventFirebase('ga_event', data.BENEFITS.benefitsHomeScreen, data.BENEFITS.searchBenefitsButton, JSON.stringify(requestParams));
  }

  showPassengerFormFn() {
    if (this.showPassengerFormControl) {
      this.showPassengerForm = false;
    } else {
      this.showPassengerFormControl = true;
    }
  }

  /**
  * cambia el valor del input de paxes
  */
  onApplyAgesPassengers(event: FormArray) {
    if (event) {
      // actualiza valor del form array de paxes
      this.benefitsSearchForm.get('paxes').setValue(event.value);
    }
    this.passengerNotSelected = false;
  }

  openedChangeStart(opened: any) {
    // if (!opened) {
      this.selected = this.benefitsSearchForm.value.dateSelect;
      this.dateStart.nativeElement.value = this.benefitsSearchForm.value.dateSelect.startDate.format('MM/DD/YYYY');
      this.dateEnd.nativeElement.value = this.benefitsSearchForm.value.dateSelect.endDate.format('MM/DD/YYYY');
      this.minDateEnd = this.benefitsSearchForm.value.dateSelect.startDate;
     
  }

  openedChangeEnd(opened: any) {
    // if (!opened) {
      this.dateStart.nativeElement.value = this.benefitsSearchForm.value.dateSelect.startDate.format('MM/DD/YYYY');
      this.dateEnd.nativeElement.value = this.benefitsSearchForm.value.dateSelect.endDate.format('MM/DD/YYYY');
      this.selected = this.benefitsSearchForm.value.dateSelect;
      
    // }
  }
}
