<p class="title" innerHTML="{{ 'HOTEL_DETAIL.reservation' | translate }}">Tu reserva</p>
<div class="info">
    <small *ngIf="travellersQty > 1; else elseBlock" innerHTML="{{ 'HOTEL_DETAIL.total_price_person' | translate:'{value:\'' +travellersQty + '\'}' }}">
        Total price
    </small>
    <ng-template #elseBlock>
        <small innerHTML="{{ 'HOTEL_DETAIL.total_price' | translate }}">Total price</small>
    </ng-template>
    <div class="prices">
        <p class="new_price">
            {{pointsPrice | milesFormat}} <span innerHTML="{{ 'HOTEL_DETAIL.miles' | translate }}"></span>
        </p>
        <p class="money_price">+ {{currency}}{{moneyPrice | milesFormat}}</p>
    </div>
    <p class="selection">{{ roomName }}</p>
    <p class="selection board">{{ board }}</p>

    <div class="meetingpoint" *ngIf="hasOptionMeetingPoint">
        <h6 innerHTML="{{ 'ACTIVITY_DETAIL.meeting_point' | translate }}">Punto de
            encuentro: </h6>
        <p>{{ meetingPoint }}</p>
        <h6 innerHTML="{{ 'ACTIVITY_DETAIL.meeting_date' | translate }}">Fecha y
            hora de encuentro: </h6>
        <p>{{ dateMeetingPoint }}</p>
    </div>

    <div class="info">
        <p *ngIf="nights"><i class="travel-icon icon-calendar"></i>{{nights}}</p>
        <p *ngIf="travellers"><i class="travel-icon icon-people"></i>{{travellers}}</p>
        <p *ngIf="roomsQty"><i class="travel-icon icon-stepper-acommodation"></i>{{roomsQty}}</p>
        <p *ngIf="wifi"><i class="travel-icon icon-wifi"></i>
            <span innerHTML="{{ 'HOTEL_DETAIL.wifi' | translate }}">Wifi</span>
        </p>
        <p *ngIf="breakfast"><i class="travel-icon icon-breakfast"></i>
            <span innerHTML="{{ 'HOTEL_DETAIL.breakfast' | translate }}">Desayuno incluido</span>
        </p>
    </div>
</div>