<div class="rules-benefits">
	<div class="column-left">
		<h2>Branded Rules Benefits</h2>
		<p>Branded Rules apply only when using Points to book flights and/or hotels.</p>
	</div>
	<div class="column-right">
		<div class="item">
			<span class="icon"></span>
			<div class="text">
				<h3>No fees</h3>
				<p>for rebooking, cancellations, interruptions or lost connections</p>
			</div>
		</div>
		<div class="item">
			<span class="icon"></span>
			<div class="text">
				<h3>Protection</h3>
				<p>for lost or damaged personal travel devices</p>
			</div>
		</div>
		<div class="item">
			<span class="icon"></span>
			<div class="text">
				<h3>Compensation of up to $500</h3>
				<p>for flight and baggage delays</p>
			</div>
		</div>
		<div class="item">
			<span class="icon"></span>
			<div class="text">
				<h3>$50,000</h3>
				<p>in emergency medical assistance during your trip</p>
			</div>
		</div>
		<div class="item">
			<span class="icon"></span>
			<div class="text">
				<h3>Best-price guarantee</h3>
				<p>and more!</p>
			</div>
		</div>
	</div>
</div>