<div class="landing-fc">
    <section class="section-one gray">
        <h1  innerHTML="{{ 'LANDING_TWO.title' | translate:'{appName:\'' + appName + '\'}' }}"></h1>
        <div class="container">
            <div class="bl-row">
                <div class="bl-left">
                    <img src="./assets/images/firstcitizens-miles/new-miles-card.webp" alt="fc">
                </div>
                <div class="bl-right">
                    <ul class="items-number">
                        <li>
                            <span></span>
                            <p innerHTML="{{ 'LANDING_TWO.item1' | translate:'{appName:\'' + appName + '\'}' }}"></p>
                        </li>
                        <li>
                            <span></span>
                            <p innerHTML="{{ 'LANDING_TWO.item2' | translate:'{appName:\'' + appName + '\'}' }}"></p>
                        </li>
                        <li>
                            <span></span>
                            <p innerHTML="{{ 'LANDING_TWO.item3' | translate:'{appName:\'' + appName + '\'}' }}"></p>
                        </li>
                    </ul>

                    <ul class="logos-coming logos-fc">
                        <li><img src="./assets/images/firstcitizens-miles/logo-paypal.png" alt="mr"></li>
                        <li><img src="./assets/images/firstcitizens-miles/logo-pay.png" alt="mr"></li>
                        <li><img src="./assets/images/firstcitizens-miles/logo-samsung.png" alt="mr"></li>
                        <li><img src="./assets/images/firstcitizens-miles/logo-g-pay.png" alt="mr"></li>
                        <li><img src="./assets/images/firstcitizens-miles/logo-amazonpay.png" alt="mr"></li>
                        <li><img src="./assets/images/firstcitizens-miles/logo-visapay.png" alt="mr"></li>
                    </ul>

                    <button class="btn button" innerHTML="{{ 'LANDING_TWO.button1' | translate:'{appName:\'' + appName + '\'}' }}"></button>
                  
                </div>
            </div>
        </div>
    </section>
    
    <section class="section-two">
        <div class="container">
            <div class="bl-row">
                <div class="bl-left">
                    <h3 innerHTML="{{ 'LANDING_TWO.title2' | translate:'{appName:\'' + appName + '\'}' }}"></h3>
                    <p innerHTML="{{ 'LANDING_TWO.item4' | translate:'{appName:\'' + appName + '\'}' }}"></p>
                    <button class="btn button"innerHTML="{{ 'LANDING_TWO.button2' | translate:'{appName:\'' + appName + '\'}' }}"></button>
                </div>
                <div class="bl-right">
                    <img src="./assets/images/firstcitizens-miles/update-card.webp" alt="fc">
                </div>
            </div>

            <div class="bl-row">
                <div class="bl-left">
                    <img src="./assets/images/firstcitizens-miles/icon-purchase.svg" alt="">
                    <p innerHTML="{{ 'LANDING_TWO.item5' | translate:'{appName:\'' + appName + '\'}' }}"></p>
                </div>
                <div class="bl-right">
                    <img src="./assets/images/firstcitizens-miles/icon-secure.webp" alt="">
                    <p innerHTML="{{ 'LANDING_TWO.item6' | translate:'{appName:\'' + appName + '\'}' }}"></p>
                </div>
            </div>
        </div>
    </section>

    
</div>