import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-benefit-detail',
  templateUrl: './benefit-detail.component.html',
  styleUrls: ['./benefit-detail.component.scss']
})
export class BenefitDetailComponent implements OnInit {

  @Input() benefit: any;
  @Input() brand: any;
  constructor() { }

  ngOnInit() {
    
  }

}
