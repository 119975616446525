<div class="bl-general" [class.vuelta]="vuelta"
    [ngClass]="collapsed ? 'collapsed' : 'expanded'">
    <h2 matTooltip="{{flight.destination.longName}}"
        innerHTML="{{'PURCHASE_SUMARY.FLIGHT.flight_to' | translate: '{value:\'' + flight.destination.longName.substr(0,15) + '\'}'}}">
        Vuelo a
    </h2>
    <div class="b-vuelo">
        <div class="b-left">
            <div class="icon-detalles"></div>
            <h6> {{flight.company.longName}}</h6>
        </div>

        <div class="b-right">
            <h6 matTooltip="{{flight.origin.longName+' - '+flight.destination.longName}}">{{ flight.origin.longName.substr(0,15) }} - {{ flight.destination.longName.substr(0,15) }}
            </h6>
            <div class="detail-date">
                <small
                    class="fecha">{{ SegmentDepartureDate(flight.departureDate) }}</small>
                <small>{{ flight.departureHour }} - {{ flight.arrivalHour }}
                </small>
                <small class="detail">{{ stops }} - {{ baggage }}</small>
            </div>
        </div>
    </div>

    <div class="text-right">
        <button class="detail-button" (click)="collapsed = !collapsed">
            {{ translationFlight['details'] }} <span class="arrow"></span>
        </button>
    </div>

    <div id="detail-container" class="col-12 detail-container"
        [class.expanded]="!collapsed">
        <div class="segment"
            *ngFor="let segment of flight.segments; let l = last">
            <app-flight-segment-detail [segment]="segment">
            </app-flight-segment-detail>
            <!-- <app-flight-resume-segment-bottom *ngIf="!l" [segment]="segment"></app-flight-resume-segment-bottom> -->
        </div>
    </div>
</div>