import { Component, OnInit, AfterViewInit, AfterContentInit, OnDestroy } from '@angular/core';
import { IPromotions, IPromotionRow } from './promociones.model';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { PROMOCIONES_MOCK } from '../../mocks/promociones.mock';
import { InitService } from 'src/app/services/init.service';
import { DataStoreService } from 'src/app/services/data-store.service';
import { LanguageService } from 'src/app/component/language/language.service';
import { EProduct, IPaxe, IRoomPaxes } from '../searched/models/searched.model';
import { DataService } from 'src/app/services/data.service';
import { SearchedFlight } from '../searched/models/searched-flight.model';
import { SearchedService } from '../searched/searched.service';
import { Router } from '@angular/router';
import { formatDate } from '@angular/common';
import { locale } from 'moment';
import { SearchedHotel } from '../searched/models/searched-hotel.model';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { GenerateService } from '../../services/generate.service';


import { StoreServiceLibrary, UserData } from '@wearenovae/novae-core-services';
import data from '../../resources/screenAndEventsTracking.json';
import { HttpClient } from '@angular/common/http';
import { JuniperService } from '../../services/juniper.service';
import { ActionEventService } from '../../services/action-event.service';
import { keysUniversal } from '../../resources/keys.resources';
import { GoogleAnalytics } from '../../resources/GoogleAnalytics.resource';


@Component({
  selector: 'app-promociones',
  templateUrl: './promociones.component.html',
  styleUrls: ['./promociones.component.scss']
})
export class PromocionesComponent implements OnInit, AfterContentInit, OnDestroy {

  get brand() {
    return this.dataStore.brand;
  }

  section = <IPromotions>{
    title: 'PROMOCIONES.destinations',
    description: 'PROMOCIONES.enjoy_flights_hotels',
    rows: <IPromotionRow[]>[]
  };

  swiperConfig = <SwiperConfigInterface>{
    slidesPerView: 3,
    slidesPerGroup: 3,
    slidesPerColumn: 1,
    slidesPerColumnFill: 'row',
    spaceBetween: 30,
    centeredSlidesBounds: true,
    autoHeight: true,
    autoplay: true,
    loop: true,
    navigation: true,
    breakpoints: {
      960: {
        slidesPerView: 3,
      },
      880: {
        slidesPerView: 2.3,
        slidesPerGroup: 2.3,
      },
      740: {
        slidesPerView: 2.1,
        slidesPerGroup: 2.1,
      },
      640: {
        slidesPerView: 1.5,
        slidesPerGroup: 1.5,
      },
      540: {
        slidesPerView: 1.3,
        slidesPerGroup: 1.3,
      },
      440: {
        slidesPerView: 1.15,
        slidesPerGroup: 1.15,
      },
      360: {
        slidesPerView: 1.1,
        slidesPerGroup: 1.1,
      },
      240: {
        slidesPerView: 1.1,
        slidesPerGroup: 1.1,
      },
    }
  };

  uri = environment.uriPromociones;
  sbs: any;

  get language(): string {
    if (this.languageService.systemLanguajeChange.value) {
      return this.languageService.systemLanguajeChange.value.toLowerCase();
    }
    return '';
  }

  /**
   * traducciones
   */
  translations = [];
  showDetail:boolean = true;

  constructor(
    private initService: InitService,
    private dataStore: DataStoreService,
    private dataService: DataService,
    private languageService: LanguageService,
    private router: Router,
    private searchedService: SearchedService,
    private translateService: TranslateService,
    private _general: GenerateService,
    private juniperService: JuniperService,
    private actionEventService: ActionEventService,
    private storeLibrary: StoreServiceLibrary
  ) {

    this.actionEventService.countryCodeChange.subscribe((countryCodeChange) => {
      if (countryCodeChange && countryCodeChange.code) {
        let loadPromosDB = environment.brands[this.dataStore.brand].config['loadPromosFromDB'] || false;
        if (loadPromosDB) {
          this.uri = keysUniversal.GET_PROMOS_DATABASE;
          this.readData('DATABASE_DATA', this.uri, countryCodeChange.code);
        } else {
          this.readData('FILE_DATA', this.uri);
        }

      }
    });

  }

  ngOnInit() {
    this.initService.isInitializedBrand.subscribe((isInitializedBrand) => {
      if (isInitializedBrand) {
        this.languageService.systemLanguajeChange.subscribe((language) => {
          if (language) {
            // mock de promociones
            //const promoBrand = PROMOCIONES_MOCK[this.dataStore.brand];
            let loadPromosDB = environment.brands[this.dataStore.brand].config['loadPromosFromDB'] || false;
            if (loadPromosDB) {
              this.uri = keysUniversal.GET_PROMOS_DATABASE;
              const countryCodeSelected =  this.storeLibrary.getItemFromStorage('CountrySelected');
              let countryCode = 'CRI';
              if (countryCodeSelected) {
                this.section.rows = [];
                countryCode = countryCodeSelected;
              }
              this.readData('DATABASE_DATA', this.uri, countryCode);
            } else {
              this.readData('FILE_DATA', this.uri);
            }
          }
        });
      }
    });
  }

  ngAfterContentInit() {
  }

  readData(typeSearch: string, url?: string, codeCountry?: string) {
    if (typeSearch === 'FILE_DATA') {
      this._general.readJson(url).subscribe(data => {
        const promJson = data;
        const promoBrand = promJson.PROMOCIONES_MOCK[this.dataStore.brand] || '';
        if (promoBrand) {
          this.section.rows = promoBrand[this.languageService.systemLanguajeChange.value.toUpperCase()];
          this.showDetail = promoBrand['showDetail'] ? promoBrand.showDetail : false
          this.getTranslations();
        }
      });
    } else if (typeSearch === 'DATABASE_DATA') {
      const PROMO_DATA = JSON.parse(this.storeLibrary.getJustOneUniversalPropertie(url));
      const PROMO_DATA_BRAND = PROMO_DATA[this.dataStore.brand];

      const specialLoadPromo = environment.brands[this.dataStore.brand].config['specialLoadPromos'] || false;
      if (specialLoadPromo) {
        try {
          let countryCode = codeCountry;

          this.translateService.use('es');
          this.section.rows = PROMO_DATA_BRAND[countryCode][this.translateService.currentLang.valueOf().toUpperCase()];
          this.showDetail = PROMO_DATA_BRAND[countryCode]['showDetail'] ? PROMO_DATA_BRAND[countryCode].showDetail : false
        } catch (error) {
          this.section.rows = [];
        }
      } else {
        this.section.rows = PROMO_DATA_BRAND[this.translateService.currentLang.valueOf().toUpperCase()];
        this.showDetail = PROMO_DATA_BRAND['showDetail'] ? PROMO_DATA_BRAND.showDetail : false
      }
      this.getTranslations();
    }
  }

  private getTranslations() {
    this.sbs = this.translateService.get([
      'DATE',
      'SEARCH_HOTELS'
    ]).subscribe((trans) => {
      this.translations = trans;
    });
  }

  /**
   * realizar búsqueda
   */
  search(product: EProduct, search: Object) {
    let paramsPromo = search;
    let useSerachIframe = this.storeLibrary.getJustOneUniversalPropertie(keysUniversal.USE_SEARCH_IFRAME) || false;
    switch (product) {
      case EProduct.HOTELS:
        // this.trackingManager.trackEventString(data.WELCOME.promoHotels, data.WELCOME.welcomeScreen, '', JSON.stringify(paramsPromo));
        GoogleAnalytics.sendEventFirebase('ga_event', data.WELCOME.welcomeScreen, data.WELCOME.promoHotels, JSON.stringify(paramsPromo));

        if (useSerachIframe) {
          // GET PARAMS OF SEARCH
          const urlToChangeHotels = this.juniperService.searchHotelsJuniper('searchhotels', search);
          this.actionEventService.setChangeUrlJuniper(urlToChangeHotels, true);
        } else {
          this.searchHotels(search);
        }
        break;
      case EProduct.FLIGHTS:
        // this.trackingManager.trackEventString(data.WELCOME.promoFlights, data.WELCOME.welcomeScreen, '', JSON.stringify(paramsPromo));
        GoogleAnalytics.sendEventFirebase('ga_event', data.WELCOME.welcomeScreen, data.WELCOME.promoFlights, JSON.stringify(paramsPromo));
        if (useSerachIframe) {
          const urlToChangeFligths = this.juniperService.searchFlightsJuniper('searchflights', search);
          this.actionEventService.setChangeUrlJuniper(urlToChangeFligths, true);
        } else {
          this.searchFlight(search);
        }
        break;
    }
  }


  private searchFlight(search: Object) {
    search['startDate'] = formatDate(
      search['startDate'],
      'yyyy-MM-dd',
      locale(this.language)
    ).toString();

    search['endDate'] = formatDate(
      search['endDate'],
      'yyyy-MM-dd',
      locale(this.language)
    ).toString();

    this.dataService.flightAvailabilityRequest = {
      journey: [{
        departureAirportCity: search['departureAirportCity'],
        arrivalAirportCity: search['arrivalAirportCity'],
        flightDate: search['startDate']
      },
      {
        departureAirportCity: search['arrivalAirportCity'],
        arrivalAirportCity: search['departureAirportCity'],
        flightDate: search['endDate']
      }],
      passengers: [{ type: 'ADT', count: search['qAdultos'] },
      { type: 'CHD', count: 0 },
      { type: 'INF', count: 0 }],
      cabinClasses: ['Y', 'B', 'F', 'P', 'M'],
      targetCurrency: this.dataStore.brandTargetCurrency,
      language: this.languageService.systemLanguajeChange.value.toUpperCase()
    };

    // valores que fueron buscados
    this.searchedService.setModel(<SearchedFlight>{
      origin: {
        iata: search['departureAirportCity'],
        id: undefined,
        name: search['departureAirportCityText'],
        type: undefined
      },
      destination: {
        iata: search['arrivalAirportCity'],
        id: undefined,
        name: search['arrivalAirportCityText'],
        type: undefined
      },
      journey: ((this.dataService.flightAvailabilityRequest.journey.length === 1) ? 'oneWay' : 'idaVuelta'),
      date: {
        start: search['startDate'],
        end: search['endDate']
      },
      quantity: {
        adults: +search['qAdultos'],
        ninos: +search['qNinos'],
        babes: +search['qBebes']
      },
      cabinClass: {
        economy: true,
        economyPremium: true,
        executive: true,
        first: true,
        combinada: true
      }
    });

    this.dataService.productSelected = EProduct.FLIGHTS;
    this.dataStore.preloaderImg = this.dataService.productSelected;
    this.dataService.saveFlightRequest(this.dataService.flightAvailabilityRequest);
    this.router.navigate(['flights/results']);
  }

  private searchHotels(search: Object) {
    const checkInText = formatDate(
      search['checkIn'],
      this.translations['DATE']['format_weekday'],
      locale(this.languageService.systemLanguajeChange.value)
    ).toLocaleString();

    const checkOutText = formatDate(
      search['checkOut'],
      this.translations['DATE']['format_weekday'],
      locale(this.languageService.systemLanguajeChange.value)
    ).toLocaleString();

    const countryResidance = this.dataService.smartProfile && this.dataService.smartProfile.accountResultDto
      ? this.dataService.smartProfile.accountResultDto.accountSumaryDto.addtionalInformationDto.isoCountry : 'AR';

    const requestInfo = {
      'checkIn': search['checkIn'],
      'checkOut': search['checkOut'],
      'destinationCode': search['destinyCode'],
      'countryOfResidence': countryResidance,
      'roomDistribution': [
        {
          'paxes': [
            {
              'id': 1,
              'age': 21
            },
            {
              'id': 2,
              'age': 21
            }
          ]
        }
      ],
      'targetCurrency': this.dataStore.brandTargetCurrency,
      'language': this.languageService.systemLanguajeChange.value.toUpperCase()
    };

    // valores que fueron buscados
    this.searchedService.setModel(<SearchedHotel>{
      destination: {
        iata: search['destinyIata'],
        id: search['destinyCode'],
        name: search['destinyName'],
        type: undefined
      },
      date: {
        start: search['checkIn'],
        end: search['checkOut']
      },
      roomsPaxes: <IRoomPaxes[]>[<IRoomPaxes>{
        paxes: [<IPaxe>{
          id: 1,
          age: 21
        }, <IPaxe>{
          id: 2,
          age: 21
        }]
      }]
    });

    this.dataService.saveRequestHotel(requestInfo);
    this.dataStore.hotelDataSearch = requestInfo;

    let searchParams_totalNights = '';
    if (+search['totalNights'] === 1) {
      searchParams_totalNights = search['totalNights'] + ' ' + this.translations['SEARCH_HOTELS']['text_day'];
    } else {
      searchParams_totalNights = search['totalNights'] + ' ' + this.translations['SEARCH_HOTELS']['text_days'];
    }

    this.dataStore.hotelInfoParams = {
      originDestinyIata: search['destinyName'],
      departureArrivalDates: 'Check in ' + checkInText + ' - ' + ' Check out ' + checkOutText,
      qPasangers: search['qPasangersB'],
      qNights: searchParams_totalNights,
      citiName: search['destinyName']
    };

    this.dataService.productSelected = EProduct.HOTELS;
    this.dataStore.preloaderImg = this.dataService.productSelected;
    this.dataService.saveHotelParamsInfo(this.dataStore.hotelInfoParams);
    this.router.navigate(['hotels/results']);
  }

  ngOnDestroy() {
    // this.sbs.unsubscribe();
  }
}
