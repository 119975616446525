
<section class="paying" *ngIf="brand == 'apap' || brand == 'cibc'">
    <div class="container">
        <div class="bl-row">
            <div class="bl-left">
                <div class="bloque-video">
                    <div class="iphone-video">
                        <video class="video-vimeo" loop muted playsinline autoplay oncanplay="this.play()" onloadedmetadata="this.muted=true">
                                <source [src]="urlsVideos.one.url | srcSanitizer" type="video/mp4">
                            </video>
                    </div>
                    <img class="imagen-video" src="./assets/images/{{brand}}-miles/bg-iphone-miles.png" alt="">
                </div>
            </div>

            <div class="bl-right">
                <h2 class="text-gradient"innerHTML="{{'LANDING.'+brand+'.SECTION_THREE.SECTION_FTRAVEL.flex_travel_title' | translate}}"></h2>
                <p innerHTML="{{'LANDING.'+brand+'.SECTION_THREE.SECTION_FTRAVEL.ftravel_desc_1' | translate}}"></p>
                <p *ngIf="brand === 'cibc'" innerHTML="{{'LANDING.'+brand+'.SECTION_THREE.SECTION_FTRAVEL.ftravel_desc_2' | translate}}"></p>
            </div>
        </div>
    </div>
</section>


<!-- <section class="s-flex" *ngIf="brand != 'apap' && brand != 'ncb'">
    <div class="container">
        <div class="bl-row">
            <div class="bl-left">
                <h2 class="text-gradient"innerHTML="{{'LANDING.'+brand+'.SECTION_THREE.SECTION_SWITCHTOMILES.flex_switch_title' | translate}}"></h2>
                <p innerHTML="{{'LANDING.'+brand+'.SECTION_THREE.SECTION_SWITCHTOMILES.switch__desc_1' | translate}}"></p>
                <p *ngIf="brand === 'cibc'" innerHTML="{{'LANDING.'+brand+'.SECTION_THREE.SECTION_SWITCHTOMILES.switch__desc_2' | translate}}"></p>
            </div>

            <div class="bl-right">
                <div class="bloque-video" *ngIf="brand !== 'apap' && brand !== 'bancoindustrial'">
                    <div class="iphone-video">
                        <video class="video-vimeo" loop muted playsinline autoplay oncanplay="this.play()" onloadedmetadata="this.muted=true">
                                <source [src]="urlsVideos.two.url | srcSanitizer" type="video/mp4">
                            </video>
                    </div>
                    <img class="imagen-video" src="./assets/images/{{brand}}-miles/bg-iphone-miles.png" alt="">
                </div>
                <img *ngIf="brand === 'apap' || brand === 'bancoindustrial'" [src]="imgSwitch" alt="">
            </div>

        </div>
    </div>
</section> -->


<section class="s-enough" *ngIf="brand != 'apap' && brand != 'firstcitizens' && brand != 'cibc'">
    <div class="container">
        <h2 class="text-gradient" innerHTML="{{'LANDING.'+brand+'.SECTION_THREE.SECTION_EMILES.enough_miles_title' | translate}}"></h2>

        <div class="bl-row">


            <div class="bl-left">
                <div class="bloque-video">
                    <div class="iphone-video">
                        <video class="video-vimeo" loop muted playsinline autoplay oncanplay="this.play()" onloadedmetadata="this.muted=true">
                                <source [src]="urlsVideos.three.url | srcSanitizer" type="video/mp4">
                            </video>
                    </div>
                    <img class="imagen-video" src="./assets/images/{{brand}}-miles/bg-iphone-miles.png" alt="">
                </div>
            </div>

            <div class="bl-right">
                <!-- <p innerHTML="{{'LANDING.'+brand+'.SECTION_THREE.SECTION_EMILES.emiles_desc_1' | translate}}"></p> -->
                <ul class="items-number">
                    <li><span>{{'LANDING.'+brand+'.SECTION_FIRST.SECTION1.1' | translate}}</span>
                        <p innerHTML="{{'LANDING.'+brand+'.SECTION_THREE.SECTION_EMILES.list_paying_1' | translate}}"></p>
                    </li>
                    <li><span>{{'LANDING.'+brand+'.SECTION_FIRST.SECTION1.2' | translate}}</span>
                        <p innerHTML="{{'LANDING.'+brand+'.SECTION_THREE.SECTION_EMILES.list_paying_2' | translate}}"></p>
                    </li>
                    <li><span>{{'LANDING.'+brand+'.SECTION_FIRST.SECTION1.3' | translate}}</span>
                        <p innerHTML="{{'LANDING.'+brand+'.SECTION_THREE.SECTION_EMILES.list_paying_3' | translate}}"></p>
                    </li>
                    <li><span>{{'LANDING.'+brand+'.SECTION_FIRST.SECTION1.4' | translate}}</span>
                        <p innerHTML="{{'LANDING.'+brand+'.SECTION_THREE.SECTION_EMILES.list_paying_4' | translate}}"></p>
                    </li>
                    <li *ngIf="brand !== 'cibc'"><span>{{'LANDING.'+brand+'.SECTION_FIRST.SECTION1.5' | translate}}</span>
                        <p innerHTML="{{'LANDING.'+brand+'.SECTION_THREE.SECTION_EMILES.list_paying_5' | translate}}"></p>
                    </li>
                </ul>
                <div class="store" *ngIf="brand != 'cibc'">
                    <span>{{'LANDING.'+brand+'.SECTION_FIRST.SECTION0.download_app' | translate}}</span>
                    <app-download-store [class]="'logo'"></app-download-store>
                </div>
            </div>
        </div>

        <div class="store" *ngIf="brand == 'cibc'">
            <span>{{'LANDING.'+brand+'.SECTION_FIRST.SECTION0.download_app' | translate}}</span>
            <app-download-store [class]="'logo'"></app-download-store>
        </div>
    </div>
</section>

<section class="s-enough" *ngIf="brand === 'firstcitizens' ">
    <div class="container">
        

        <div class="bl-row">
            <div class="bl-left">
                <div class="bloque-video">
                    <div class="iphone-video">
                        <video class="video-vimeo" loop muted playsinline autoplay oncanplay="this.play()" onloadedmetadata="this.muted=true">
                                <source [src]="urlsVideos.three.url | srcSanitizer" type="video/mp4">
                            </video>
                    </div>
                    <img class="imagen-video" src="./assets/images/{{brand}}-miles/bg-iphone-miles.png" alt="">
                </div>
            </div>

            <div class="bl-right" >
                <h2 class="text-gradient" innerHTML="{{'LANDING.'+brand+'.SECTION_THREE.SECTION_EMILES.enough_miles_title' | translate}}"></h2>
                  <p innerHTML="{{'LANDING.'+brand+'.SECTION_FIRST.SECTION1.always' | translate}}"></p>
              
            </div>
        </div>
        <!-- <div class="store store--apap">
            <span>{{'LANDING.'+brand+'.SECTION_FIRST.SECTION0.download_app' | translate}}</span>
            <app-download-store [class]="'logo'"></app-download-store>
        </div> -->
    </div>
</section>


