import { Injectable } from '@angular/core';
import { keysUniversal, StoreServiceLibrary } from '@wearenovae/novae-core-services';
import { LpCartUpdate, LpCtmr, LpError, LpMrk, LpTransaction } from 'src/app/models/lp.model';
import { generalKeys } from 'src/app/resources/keys.resources';
import { LpChannel } from 'src/app/resources/lp.resources';
import { UtilsResource } from 'src/app/resources/UtilsResource.resource';
import { InitService } from '../init.service';

//declare var LPMonitiring: any;
declare var lpTag;

@Injectable({
  providedIn: 'root'
})
export class LpService {
  lpPushInformation:boolean = false;

  constructor(
    private initService:InitService,
    private storeServiceLibrary:StoreServiceLibrary
  ) {
    this.initService.isInitializedBrand.subscribe((isInitialized) => {
      if (isInitialized) {
        /*
        const livePersonAccount = this.storeServiceLibrary.getJustOneUniversalPropertie(keysUniversal.live_person_account);
        UtilsResource.ShowLog('*** LpService - Constructor - livePersonAccount', livePersonAccount);
        if (livePersonAccount != null && livePersonAccount != '') {
          LPMonitiring.init(livePersonAccount);
        }
        //*/
        const lpPushInformation = this.storeServiceLibrary.getJustOneUniversalPropertie(generalKeys.lpPushInformation);
        UtilsResource.ShowLog('*** LpService - Constructor - lpPushInformation', lpPushInformation);
        if (lpPushInformation && lpPushInformation == 'true') {
          this.lpPushInformation = true;
        }
      }
    });
  }

  private sendPush(data:any) {
    if (this.lpPushInformation) {
      if (lpTag && lpTag.sdes != undefined) {
        lpTag.sdes.push(data);
      }
    }
  }
        
  mrktInfo (lpChannel:LpChannel, affiliate:string, campaignId:string) {
    const mrkt:LpMrk = {
      type: "mrktInfo",
      info: {
        channel: lpChannel,
        affiliate: affiliate,
        campaignId: campaignId
      }
    };

    this.sendPush(mrkt);
  }
  
  callLpCartUpdate(lpCartUpdate:LpCartUpdate) {
    try {
      this.sendPush(lpCartUpdate);
    } catch (error: any) {
      UtilsResource.ShowLog('*** LpService - callLpCartUpdate - error', error);
    }
  }

  callLpVisitorError(lpError:LpError) {
    try {
      this.sendPush(lpError);
    } catch (error: any) {
      UtilsResource.ShowLog('*** LpService - callLpVisitorError - error', error);
    }
  }
  
  callLpTransaction(lpTransaction: LpTransaction) {
    try {      
      this.sendPush(lpTransaction);
    } catch (error) {
      UtilsResource.ShowLog('*** LpService - callLpTransaction - error', error);
    }
  }

  callLpVisitorInfo(lpCtmr: LpCtmr) {
    try {
      this.sendPush(lpCtmr);
    } catch (error: any) {
      UtilsResource.ShowLog('*** LpService - callLpVisitorInfo - error', error);
    }
  }
}
