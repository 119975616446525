import { Component, OnInit } from '@angular/core';
import { StoreServiceLibrary } from '@wearenovae/novae-core-services';
import { MatDialogRef } from '@angular/material/dialog';
import { DataStoreService } from 'src/app/services/data-store.service';

@Component({
  selector: 'app-expire-miles',
  templateUrl: './expire-miles.component.html',
  styleUrls: ['./expire-miles.component.scss']
})
export class ExpireMilesComponent implements OnInit {

  pipePointFormat;
  userData;
  balance;
  expireBalance;
  dateExpire;
  get brand() {
    return this.dataStoreService.brand;
  }
  constructor(
    private dataStoreService: DataStoreService,
    private _storeLibrary: StoreServiceLibrary,
    private dialogRef: MatDialogRef<ExpireMilesComponent>,
  ) { }

  ngOnInit() {
    this.pipePointFormat = '1.0-0'
    this.userData = this._storeLibrary.getUserData();
    this.balance = Number(this.userData?.accountResultDto?.balance);
    // this.balance = 200000;
    this.expireBalance = this.userData?.accountResultDto?.detailBalanceDto?.expireBalance || 0;
    // this.expireBalance = 200000;
    this.dateExpire = this.userData?.accountResultDto?.detailBalanceDto?.dateExpire || null;
    // this.dateExpire = "2023-01-10T14:23:57.863+0000";
  }

  onCancel() {
    this.dialogRef.close();
  }
}
