import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { locale, Moment } from 'moment';
import { formatDate } from '@angular/common';
import { Router } from '@angular/router';
import { DataService } from '../../../../services/data.service';
import moment from 'moment';
import { DataStoreService } from '../../../../services/data-store.service';
import { HotelAvailabilityRequest } from 'src/app/models/hotel/request/hotel-availability-request.model';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from '../../../../component/language/language.service';
import { DebounceCall } from 'src/app/decorators/debounce.decorator';
import { Subscription } from 'rxjs';
import { SearchedService } from 'src/app/component/searched/searched.service';
import { SearchedHotel } from 'src/app/component/searched/models/searched-hotel.model';

import data from 'src/app/resources/screenAndEventsTracking.json';
import { GoogleAnalytics } from '../../../../resources/GoogleAnalytics.resource';
import { InitService } from '../../../../services/init.service';


@Component({
  selector: 'app-search-hotel',
  templateUrl: './search-hotel.component.html',
  styleUrls: ['./search-hotel.component.scss']
})

export class SearchHotelComponent implements OnInit {

  @ViewChild('dateStart') dateStart;
  @ViewChild('dateEnd') dateEnd;

  minDateEnd;
  selected = {
    startDate: moment(new Date()),
    endDate: moment(new Date())
  };

  get placeholderGuests() {
    let placeHolHost;
    if (this.dataStore.qRooms > 0) {
      if (this.dataStore.qRooms === 1) {
        placeHolHost = this.dataStore.qRooms + ' ' + this.translation_guest['translation_text_rooms_singular'];
      } else {
        placeHolHost = this.dataStore.qRooms + ' ' + this.translation_guest['translation_text_rooms_plural'];
      }

      if (this.dataStore.qPax === 1) {
        placeHolHost = placeHolHost + ' ' + this.dataStore.qPax + ' ' + this.translation_guest['translation_text_guests_singular'];
      } else {
        placeHolHost = placeHolHost + ' ' + this.dataStore.qPax + ' ' + this.translation_guest['translation_text_guests_plural'];
      }
      this.hotelSearchForm.get('passenger').setValue(placeHolHost);
    }
    return placeHolHost;
  }

  get passenger() {
    return this.hotelSearchForm.get('passenger');
  }

  get destino() {
    return this.hotelSearchForm.get('destino');
  }

  get qNights() {
    return this.hotelSearchForm.value.dateSelect.endDate.diff(this.hotelSearchForm.value.dateSelect.startDate, 'days');
  }

  searchFormInvalid: boolean;
  requestInfo: HotelAvailabilityRequest;
  guests: any;
  showGuestForm: boolean;
  showGuestFormControl: boolean;
  minDate = moment(new Date());
  hotelDestiny: string;
  checkIn: string;
  checkOut: string;
  checkInText: string;
  checkOutText: string;
  totalNights: any;
  locale: any = {
    format: '',
    separator: ' - ',
  };
  allCities = [];
  hotelSearchForm: FormGroup;
  selectedName: string;
  selectedIata: string;
  notFoundText: string;
  palceholderSelectDate: string;
  textDay: string;
  textDays: string;
  diaDeLaSemanaFormat: string;
  refresh = true;
  translations_search_hotel = [];
  translation_guest = [];
  searchSbs: Subscription;

  constructor(
    private data: DataService,
    private router: Router,
    private dataStore: DataStoreService,
    private translateService: TranslateService,
    private languageService: LanguageService,
    private searchedService: SearchedService,
    private initService: InitService
  ) {
    this.hotelSearchForm = this.createFormSearchGroup();
  }

  ngOnInit() {
    // this.trackingManager.setCurrentScreen('/hotels', data.HOTELS.hotelsHomeScreen);
    this.showGuestForm = true;
    this.showGuestFormControl = true;
    this.searchFormInvalid = false;
    if (this.dataStore.smartProfilePreferences && this.dataStore.smartProfilePreferences.hotelDestinyId) {
      this.hotelSearchForm.controls['destino'].setValue({
        id: this.dataStore.smartProfilePreferences.hotelDestinyId,
        name: this.dataStore.smartProfilePreferences.hotelDestiny,
      });
    }
    this.getTranslations();
    this.initService.isInitializedBrand.subscribe((isInit) => {
      if (isInit) {
        GoogleAnalytics.setCurrentScreen('/hotels');
      }
    });
  }

  getTranslations() {
    this.languageService.systemLanguajeChange.subscribe((language) => {
      if (language) {
        this.translateService.get([
          'DATE.format',
          'DATE.format_weekday',
          'SEARCH_HOTELS.not_found_text',
          'SEARCH_HOTELS.select_Date',
          'SEARCH_HOTELS.text_day',
          'SEARCH_HOTELS.text_days',
          'SEARCH_HOTELS',
          'GUESTS'
        ]).subscribe(
          (translations) => {

            // local para los calendarios
            locale(language);
            this.locale.format = translations['DATE.format'];
            this.locale.daysOfWeek = moment.weekdaysMin();
            this.locale.monthNames = moment.monthsShort();

            // fix hack para actualizar los selectores de fecha
            this.refresh = !this.refresh;
            setTimeout(() => {
              this.refresh = !this.refresh;
            }, 100);


            this.diaDeLaSemanaFormat = translations['DATE.format_weekday'];
            this.notFoundText = translations['SEARCH_HOTELS.not_found_text'];
            this.palceholderSelectDate = translations['SEARCH_HOTELS.select_Date'];
            this.textDay = translations['SEARCH_HOTELS.text_day'];
            this.textDays = translations['SEARCH_HOTELS.text_days'];

            this.translations_search_hotel = translations['SEARCH_HOTELS'];
            this.translation_guest = translations['GUESTS'];
          });
      }
    });
  }

  @DebounceCall(250)
  onSearch($event) {
    if (this.searchSbs && !this.searchSbs.closed) {
      // si la busqueda anterior no terminó, la cancelamos
      this.searchSbs.unsubscribe();
    }

    this.allCities = [];
    const searched = $event.term;
    if (searched.length < 3) {
      return;
    }

    // si la busqueda es de al menos 3 letras
    this.searchSbs = this.data.getCities($event.term).subscribe(cities => {
      /*this.allCities = cities.filter(city => this.isCity(city.type));*/
      this.allCities = cities;
      this.searchSbs.unsubscribe();
    });
  }

  /**
   * hamemos que el ng-select retorne siempre true en el search
   * porque lo que filtramos es cambar el array de items (onSearch)
   * y el componente esta pensado para tener en el array todo cargado
   * y buscar mostrando y ocutlando sobre esos items
   */
  returnTrue = () => true;

  /**
   * remover de la búsqueda los aeropuertos, para retornar solo ciudades
   */
  isCity(type) {
    return !(<string>type).toLowerCase().match(this.translations_search_hotel['airports'].toLowerCase());
  }

  showGuestFormFn() {
    if (this.showGuestFormControl) {
      this.showGuestForm = false;
    } else {
      this.showGuestFormControl = true;
    }
  }

  createFormSearchGroup() {
    return new FormGroup({
      destino: new FormControl(null, [Validators.required]),
      dateSelect: new FormControl({
        startDate: moment(new Date()),
        endDate: moment(new Date())
      }, [Validators.required]),
      passenger: new FormControl(null, [Validators.required]),
    });
  }


  setValues() {
    this.hotelDestiny = this.hotelSearchForm.value.destino.id;

    this.checkIn = formatDate(
      this.hotelSearchForm.value.dateSelect.startDate,
      'yyyy-MM-dd',
      locale(this.translateService.getDefaultLang())
    ).toString();

    this.checkOut = formatDate(
      this.hotelSearchForm.value.dateSelect.endDate,
      'yyyy-MM-dd',
      locale(this.translateService.getDefaultLang())
    ).toString();

    this.checkInText = formatDate(
      this.hotelSearchForm.value.dateSelect.startDate,
      this.diaDeLaSemanaFormat,
      locale(this.translateService.getDefaultLang())
    ).toLocaleString();

    this.checkOutText = formatDate(
      this.hotelSearchForm.value.dateSelect.endDate,
      this.diaDeLaSemanaFormat,
      locale(this.translateService.getDefaultLang())
    ).toLocaleString();

    this.totalNights = +this.qNights;
    if (this.totalNights > 1) {
      this.totalNights = this.totalNights + ' ' + this.textDays;
    } else {
      this.totalNights = this.totalNights + ' ' + this.textDay;
    }
  }

  onChangeH(ev) {
  }

  searchH() {
    if (this.hotelSearchForm.valid) {
      this.searchFormInvalid = false;
      this.setValues();
      if (!this.guests) {
        this.guests = [{ paxes: [{ id: 1, age: 21 }] }];
      }
      const countryResidance = this.data.smartProfile && this.data.smartProfile.accountResultDto
        ? this.data.smartProfile.accountResultDto.accountSumaryDto.addtionalInformationDto.isoCountry : 'AR';

      this.requestInfo = {
        'checkIn': this.checkIn,
        'checkOut': this.checkOut,
        'destinationCode': this.hotelDestiny,
        'countryOfResidence': countryResidance,
        'roomDistribution': this.guests,
        'targetCurrency': this.dataStore.brandTargetCurrency,
        'language': this.languageService.systemLanguajeChange.value.toUpperCase()
      };

      this.data.saveRequestHotel(this.requestInfo);
      this.dataStore.hotelDataSearch = this.requestInfo;

      this.dataStore.journeyParamsInfo = {
        citiName: this.hotelSearchForm.value.destino.name
      };
      this.data.saveJourneyParamsInfo(this.dataStore.journeyParamsInfo);

      this.dataStore.hotelInfoParams = {
        originDestinyIata: this.hotelSearchForm.value.destino.name,
        departureArrivalDates: 'Check in ' + this.checkInText + ' - ' + ' Check out ' + this.checkOutText,
        qPasangers: this.placeholderGuests,
        qNights: this.totalNights,
        citiName: this.hotelSearchForm.value.destino.name
      };

      // valores que fueron buscados
      this.searchedService.setModel(<SearchedHotel>{
        destination: this.hotelSearchForm.get('destino').value,
        date: {
          start: this.hotelSearchForm.get('dateSelect').value.startDate,
          end: this.hotelSearchForm.get('dateSelect').value.endDate
        },
        roomsPaxes: this.guests
      });

      this.data.saveHotelParamsInfo(this.dataStore.hotelInfoParams);
      this.router.navigate(['hotels/results']);

      // this.trackingManager.trackEventString(data.HOTELS.searchHotelsButton, data.HOTELS.hotelsHomeScreen, '', JSON.stringify(this.dataStore.hotelInfoParams));
      GoogleAnalytics.sendEventFirebase('ga_event', data.HOTELS.hotelsHomeScreen, data.HOTELS.searchHotelsButton, JSON.stringify(this.dataStore.hotelInfoParams));
    } else {
      this.searchFormInvalid = true;
    }
  }

  openedChangeStart(opened: any) {
    // if (!opened) {
    this.selected = this.hotelSearchForm.value.dateSelect;
    this.dateStart.nativeElement.value = this.hotelSearchForm.value.dateSelect.startDate.format('MM/DD/YYYY');
    this.dateEnd.nativeElement.value = this.hotelSearchForm.value.dateSelect.endDate.format('MM/DD/YYYY');
    this.minDateEnd = this.hotelSearchForm.value.dateSelect.startDate;

  }

  openedChangeEnd(opened: any) {
    // if (!opened) {
    this.dateStart.nativeElement.value = this.hotelSearchForm.value.dateSelect.startDate.format('MM/DD/YYYY');
    this.dateEnd.nativeElement.value = this.hotelSearchForm.value.dateSelect.endDate.format('MM/DD/YYYY');
    this.selected = this.hotelSearchForm.value.dateSelect;

    // }
  }
}
