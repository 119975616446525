import { RadioOptionComponent } from './radio-option/radio-option.component';
import { DropdownOptionComponent } from './dropdown-option/dropdown-option.component';
import { PipesModule } from './../../../../pipes/pipes.module';
import { FormsModule } from '@angular/forms';
import { MatRadioModule } from '@angular/material/radio';
import { TranslateModule } from '@ngx-translate/core';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CardLayoutThreeComponent } from './card-layout-three.component';
import { CardHeaderComponent } from './card-header/card-header.component';

@NgModule({
  declarations: [
    CardLayoutThreeComponent,
    CardHeaderComponent,
    DropdownOptionComponent,
    RadioOptionComponent,
  ],
  exports: [
    CardLayoutThreeComponent
  ],
  imports: [
    CommonModule,
    TranslateModule,
    MatRadioModule,
    FormsModule,
    PipesModule
  ]
})
export class CardLayoutThreeModule { }
