<section class="searchBox">
  <section class="buscador-flotante ">
    <div class="menu-buscardor container tabset">
      <app-menu-search></app-menu-search>
    </div>

  <!--  <div class="searchBody">
      <form [formGroup]="searchForm" novalidate class="searchForm">
        <app-target-input
          [ngClass]="{oneTargetInput: !secondTarget, twoTargetInput: secondTarget }"
          formControlName="targetIn"
          [targetPlaceholder]="searchConfig.targetIn">
        </app-target-input>
        <div *ngIf="productSelected === EProduct.FLIGHTS || productSelected === EProduct.BENEFITS" class="ciudades icon-invert"><span class="icono-invertir" (click)="invertFlightSelection()"></span></div>
        <app-target-input
          *ngIf="secondTarget"
          [ngClass]="{twoTargetInput: secondTarget }"
          formControlName="targetOut"
          [targetPlaceholder]="searchConfig.targetOut">
        </app-target-input>
        <ng-select
                   *ngIf="productSelected === EProduct.FLIGHTS"
                   class="tramoClass"
                   [searchable]="false"
                   [clearable]="false"
                   labelForId="Ida y vuelta"
                   (change)="tramoFn($event)">
          <ng-option value="oneWay">Solo Ida</ng-option>
          <ng-option value="idaVuelta">Ida y Vuelta</ng-option>
        </ng-select>
        <app-date-input
          formControlName="dateSelect"
          [secondDatePicker]=secondDatePicker>
        </app-date-input>
        <app-buyer formControlName="buyer"
          [type]="productSelected">
        </app-buyer>

        <button (click)="doSearch()" class="buscar"></button>
      </form>
    </div>-->
  </section>
</section>

