<div class="benefits-protegido bl-mobile">
  <h1>¡Estás a punto de viajar con los mejores beneficios!</h1>

  <div class="bl-bene">

    <app-coupon-promotion></app-coupon-promotion>

    <div class="bloque">
      <div class="bl-img">
        <img src="assets/images/credomatic-miles/icon-1.svg" alt="">
        <span>Haz que tu viaje sea flexible: Viaja <strong>flex</strong>.</span>
      </div>

      <div class="bl-text">
        <ul>
          <li><strong>Sin costo adicional por cambios o cancelaciones.</strong> Todas las reservas son 100%
            modificables.
          </li>
          <li><strong>Compensación instantánea en tu tarjeta digital Miles:</strong>
            <ul class="internas">
              <li><strong>US$ 200</strong> por demora de equipaje.</li>
              <li><strong>US$ 600</strong> por pérdida de equipaje.</li>
              <li><strong>US$ 100</strong> por retraso de vuelo por más de 6 horas.</li>
              <li><strong>US$ 500</strong> por pérdida, robo o daño de artículos comprados durante el viaje.</li>
            </ul>
          </li>
        </ul>
      </div>


    </div>

    <div class="bloque">
      <div class="bl-img">
        <img src="assets/images/credomatic-miles/icon-2.svg" alt="">
        <span>Viaja tranquilo con <strong>protección COVID-19</strong></span>
      </div>
      <div class="bl-text">
        <ul>
          <li><strong>US$ 50.000 por asistencia médica</strong> y dental de emergencia incluyendo protección COVID-19.
          </li>
          <li><strong>Transporte de emergencia sin costo</strong> en caso de evacuación médica.</li>
          <li><strong>Alojamiento y gastos diarios incluidos</strong> por causa de enfermedad o accidente.</li>
        </ul>
      </div>

    </div>

    <div class="bloque">
      <div class="bl-img">
        <img src="assets/images/credomatic-miles/icon-3.svg" alt="">
        <span>Gana más beneficios. ¡Gana <strong>upperMiles!</strong></span>
      </div>
      <div class="bl-text">
        <ul>
          <li><strong>Acumula 3X más millas</strong> cuando reservas con puntos, MillasPlus y demás recompensas.</li>
          <li><strong>Combina tus puntos y recompensas</strong> con tus tarjetas BAC Credomatic.</li>
          <li><strong>Unifica todos tus programas de lealtad en uno solo.</strong></li>
          <li><strong>Paga en cuotas sin interés*.</strong></li>
        </ul>
      </div>

    </div>
  </div>
</div>
