import { Component, OnInit, Output, EventEmitter, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-details-news',
  templateUrl: './details-news.component.html',
  styleUrls: ['./details-news.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class DetailsNewsComponent implements OnInit {


  @Input() newDetail: any;
  @Output() backToNews = new EventEmitter<boolean>();

  public actived:boolean;
  public tabs_state;
  public tabs_state_mob;
  actived1: boolean;
  actived2: boolean;

  constructor() {
    this.tabs_state = {
      item1: true,
      item2: false
    }
    this.tabs_state_mob = {
      item1: false,
      item2: false
    }
  }

  ngOnInit(): void {
  }

  reset(){
    this.tabs_state = {
      item1: false,
      item2: false
    }
  }

  resetMob(){
    this.tabs_state_mob = {
      item1: false,
      item2: false
    }
  }

  active(number:any){
    this.tabs_state_mob = { 
      item1: false,
      item2: false
    }
    this["actived" + number] = !this["actived" + number];
    
  }

  back() {
    this.backToNews.emit(false);
  }

}
