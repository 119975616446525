import { environment } from 'src/environments/environment';

class UtilsResource {

    constructor() { }

    static validateString(stringVal: string) {
        return stringVal != null && stringVal !== '';
    }

    static validateEmail(email: string) {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }


    static getCurrentMilliseconds() {
        const now = new Date();
        const milli = now.getTime();

        return milli;
    }

    static getDiffCurrentMilliseconds(lastMilli) {
        const currentMilli = UtilsResource.getCurrentMilliseconds();
        return currentMilli - lastMilli;
    }

    /**
     * Print log in a console only when the app isn't in production
     *
     * @param args: any[] - console to print
     */
    static ShowLog(...args: any[]) {
        if (!environment.production) {
            for (let i = 0; i < args.length; i++) {
                args[i] = JSON.stringify(args[i]);
            }
            console.log(args);
        }
    }

    /**
     * Print log in a console with especial mark only when the app isn't in production
     *
     * @param title: String - console title
     * @param description?: String - console description
     * @param strColorBg?: String - console backgraund color - Default 'red'
     * @param strColorFont?: String - console text color - Default 'white'
     */
    static NSMarkLog(title: any, description?: any, strColorBg?: String, strColorFont?: String) {
        if (!description) {
            description = '';
        }
        if (!strColorBg) {
            strColorBg = 'yellow';
        }
        if (!strColorFont) {
            strColorFont = 'blue';
        }
        const printTitle = `%c ${JSON.stringify(title)}`;
        const printConfigure = `background:${strColorBg}; color:${strColorFont}`;

        UtilsResource.ShowLog(printTitle, printConfigure, JSON.stringify(description));
    }

    /**
     * Print log in a console with especial mark only when the app isn't in production
     *
     * @param description?: String - console description
     */
    static NSMarkLogError(title: any, description: any) {
        if (!description) {
            description = '';
        }
        const printTitle = `%c ERROR - ${JSON.stringify(title)}`;
        const printConfigure = `background:red; color:yellow`;

        UtilsResource.ShowLog(printTitle, printConfigure, JSON.stringify(description));
    }

    static ciEquals(a, b) {
        return typeof a === 'string' && typeof b === 'string'
            ? a.localeCompare(b, undefined, { sensitivity: 'accent' }) === 0
            : a === b;
    }
}

export { UtilsResource };
