import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';

import { DataStoreService } from '../../services/data-store.service';

@Injectable({
  providedIn: 'root'
})
export class TravelGuard implements CanActivate {

  constructor(private dataStore: DataStoreService,
    private router: Router) {

  }

  canActivate() {
    let hostname = window.location.hostname;
    if (this.dataStore.brand === 'lifeMilesTravel') {
      const param = window.location.search;
      this.router.navigateByUrl(`lifemiles-travel${param}`);
      return false;
    } else if (hostname == 'mr2.sale') {
      this.router.navigateByUrl(`myrewards-salesdeck`);
      return false;
    } else {
      return true;
    }
  }

}
