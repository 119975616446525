import { Component, OnInit, Inject, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { keysUniversal, StoreServiceLibrary } from '@wearenovae/novae-core-services';
import { DataStoreService } from '../../../services/data-store.service';
import { environment } from '../../../../environments/environment';
import { keys } from 'src/app/resources/keys.resources';

declare var androidProxy: any;
declare var window: any;

@Component({
  selector: 'app-alert-error',
  templateUrl: './alert-error.component.html',
  styleUrls: ['./alert-error.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AlertErrorComponent implements OnInit {
  error = '';
  tittle = '';

  showButtonWhatsApp: boolean;
  label_button_app: string;
  action_button_ios: string;
  action_button_android: string;
  action_button_web: string;
  validateHaveGoToLogin = false;
  isShowIconClose = true;


  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _storeLibrary: StoreServiceLibrary,
    private dataStore: DataStoreService,
    private dialogRef: MatDialogRef<AlertErrorComponent>) {
    this.error = data.error;
    this.tittle = data.tittle;
    
    this.validateHaveGoToLogin = data.validateHaveGoToLogin;
    this.isShowIconClose = data.showIconClose;
    this.label_button_app = data.label_button_app;
    this.action_button_web = data.action_button_web;
  }

  ngOnInit() {
    if (environment.brands[this.dataStore.brand].config.showButtonWhatsApp !== undefined) {
      if (environment.brands[this.dataStore.brand].config.showButtonWhatsApp) {
        this.showButtonWhatsApp = true;
      } else {
        this.showButtonWhatsApp = environment.brands[this.dataStore.brand].config.showButtonWhatsApp;
      }
    } else {
      this.showButtonWhatsApp = true;
    }
  }

  close() {
    this.dialogRef.close();
  }

  goToExWhatsapp() {
    const urlWhatsappWelcome = this._storeLibrary.getJustOneUniversalPropertie(keysUniversal.app_url_whatsapp_welcome);
    if (typeof androidProxy !== 'undefined') {
      androidProxy.postMessage(JSON.stringify({
        typeMessage: 'OPEN_EXTERNAL_URL',
        URL: urlWhatsappWelcome
      }));
    } else if (window.webkit !== undefined && window.webkit !== 'undefined' && window.webkit.messageHandlers.native !== undefined && window.webkit.messageHandlers.native !== 'undefined') { // Si es iOS
      window.webkit.messageHandlers.native.postMessage(JSON.stringify({
        typeMessage: 'OPEN_EXTERNAL_URL',
        URL: urlWhatsappWelcome
      }));
    } else {
      window.open(urlWhatsappWelcome, '_system', 'location=yes');
    }
  }

  goToExApp() {
    if (typeof androidProxy !== 'undefined') {
      androidProxy.postMessage(JSON.stringify({
        typeMessage: 'OPEN_EXTERNAL_URL',
        URL: this.action_button_android
      }));
    } else if (window.webkit !== undefined && window.webkit !== 'undefined' && window.webkit.messageHandlers.native !== undefined && window.webkit.messageHandlers.native !== 'undefined') { // Si es iOS
      window.webkit.messageHandlers.native.postMessage(JSON.stringify({
        typeMessage: 'OPEN_EXTERNAL_URL',
        URL: this.action_button_ios
      }));
    } else {
      window.open(this.action_button_web, '_system', 'location=yes');
    }
  }

  goToLoginLM() {
    const urlLoginLifeMiles = this._storeLibrary.getJustOneUniversalPropertie(keys.URL_LOGIN_LIFEMILES);
    window.location.href = urlLoginLifeMiles;
  }


}
