import { MainFormComponent } from './main-form.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { SearchResumeBarModule } from 'src/app/component/search-resume-bar/search-resume-bar.module';



@NgModule({
  declarations: [
    MainFormComponent
  ],
  exports: [
    MainFormComponent
  ],
  imports: [
    TranslateModule.forChild(),
    RouterModule,
    SearchResumeBarModule,
    CommonModule
  ]
})
export class MainFormModule { }
