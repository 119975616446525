import { Component, OnInit } from '@angular/core';
import { CardLayoutTwoComponent } from '../card-layout-two.component';

@Component({
  selector: 'app-card-header',
  templateUrl: './card-header.component.html',
  styleUrls: ['./card-header.component.scss'],
  host: {'class': 'cmp-tag'}
})
export class CardHeaderComponent implements OnInit {

  product: any;
  constructor(private parentCmp: CardLayoutTwoComponent) {}

  ngOnInit() {
    this.product = this.parentCmp.product;
    this.product.category = parseInt(this.product.category);
  }

}
