import { Injectable, Renderer2 } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import * as CryptoJS from 'crypto-js';
import { MatDialog } from '@angular/material/dialog';
import { WarningModalComponent } from '../component/warning-modal/warning-modal.component';
import { UtilsResource } from '../resources/UtilsResource.resource';
import { StoreServiceLibrary } from '@wearenovae/novae-core-services';
import { keysUniversal } from 'src/app/resources/keys.resources';
import { environment } from 'src/environments/environment';
import { DataStoreService } from './data-store.service';

@Injectable({
  providedIn: 'root'
})
export class GenerateService {

  constructor(
    private httpService: HttpClient,
    private dialog: MatDialog,
    private storeServiceLibrary: StoreServiceLibrary,
    private dataStore: DataStoreService,
  ) {
  }

  readJson(uri): Observable<any> {
    return new Observable((observer) => {
      this.httpService.get(uri).subscribe(data => {
        observer.next(data);
        observer.complete();
      }, (err: HttpErrorResponse) => {
        observer.error('error');
      });
    });
  }

  decrypt(object) {
    let dataFromStorage: any;
    try {
      const bytes = CryptoJS.AES.decrypt(object, 'miclave');
      dataFromStorage = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    } catch (ex) {
    }
    return dataFromStorage;
  }

  stringToBoolean(value: string) {
    switch (value) {
      case 'true':
      case '1':
      case 'on':
      case 'yes':
        return true;
      default:
        return false;
    }
  }

  warningModalShow(message, widthPadding, heightPadding) {
    this.dialog.open(WarningModalComponent, {
      data: {
        maxWidth: '50vw',
        width: 'calc(50vw - ' + widthPadding + ')',
        height: 'calc(50vh - ' + heightPadding + ')',
        minHeight: '59vh',
        tittle: 'Warning',
        error: `${message}`
      }
    });
  }

  /** aca - Test ScotiaBank */
  getScotiaBankAuthKey() {
    let authKey = '';

    const options: RequestInit = {
      method: 'HEAD',
      redirect: 'manual', // follow - manual - error
      // mode: 'no-cors',
      credentials: 'omit',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': 'Content-Type',
        'Access-Control-Allow-Credentials': 'false',
        'Content-Type': 'application/json',
        'Access-Control-Expose-Headers': 'Location'
      }
    };

    fetch('https://passport-u.scointnet.net/oauth2/v1/authorize?response_type=code&client_id=488ca212-39b8-4a1c-9913-50686f2201e9', options
    ).then(response => {
      UtilsResource.ShowLog('*** getAuthKey - response', response);
    }).catch(error => {
      UtilsResource.NSMarkLog('*** getAuthKey - error');
      UtilsResource.NSMarkLog(error);
    })
      ;

    return authKey;
  }

  static validateEmail(email: string) {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  redirect(url) {

    // const url = 'https://facebook.com/';
    if (!url) {
      return;
    }
    window.location.href = url;
  }

  generateNewElement(nameElem, text, ref: any, type?, src?) {
    const element = document.createElement(nameElem);
    element.text = text;

    if (type !== null && type === 'img') {
      const img = document.createElement('img');
      img.setAttribute('src', src);
      img.setAttribute('height', '1');
      img.setAttribute('width', '1');
      element.appendChild(img);
    } else if (type === 'iframe') {

      const iframe = document.createElement('iframe');
      iframe.setAttribute('src', src);
      iframe.setAttribute('style', 'display:none;visibility:hidden');
      iframe.setAttribute('height', '0');
      iframe.setAttribute('width', '0');
      element.appendChild(iframe);
    }
    try {
      ref.appendChild(element);
    } catch (err) {
      UtilsResource.ShowLog('*** GenerateService - generateNewElement - err', err);
    }
  }

  appendHashParams(clase: string, passengerNumbers: number): string {
    let filter = 'appendHashParams=discount%3d0';
    const filterToLimitPriceStr: any = this.storeServiceLibrary.getJustOneUniversalPropertie(keysUniversal.filterToLimitPrice) || '';
    if (filterToLimitPriceStr == '') {
      return filter;
    }
    const filterToLimitPrice = JSON.parse(filterToLimitPriceStr);
    if (clase == '') {
      clase = 'A';   // Any class
    }
    if (filterToLimitPrice) {
      if (filterToLimitPrice.class[clase]) {
        filter += '%26minPrice%3d' + filterToLimitPrice.filtro[filterToLimitPrice.class[clase]].min.value * (filterToLimitPrice.filtro[filterToLimitPrice.class[clase]].min.xpass ? passengerNumbers : 1);
        filter += '%26maxPrice%3d' + filterToLimitPrice.filtro[filterToLimitPrice.class[clase]].max.value * (filterToLimitPrice.filtro[filterToLimitPrice.class[clase]].max.xpass ? passengerNumbers : 1);
      }
    }
    return filter;
  }

  appendHashParamsToFixedRates(clase: string, passengerNumbers: number, subtotal: number): string {
    let filter = 'appendHashParams=discount%3d0';
    const filterToLimitPriceToFixedRatesStr: any = this.storeServiceLibrary.getJustOneUniversalPropertie(keysUniversal.filterToLimitPriceToFixedRates) || '';
    if (filterToLimitPriceToFixedRatesStr == '') {
      return filter;
    }
    const filterToLimitPriceToFixedRates = JSON.parse(filterToLimitPriceToFixedRatesStr);
    if (clase == '') {
      clase = 'E';   // Economy
    }
    if (filterToLimitPriceToFixedRates) {
      const option = filterToLimitPriceToFixedRates.class[clase.toUpperCase()].find((item) => subtotal >= item.Inicio && subtotal < item.Fin);
      if (option != undefined) {
        filter += '%26minPrice%3d' + filterToLimitPriceToFixedRates.filtro[option.filtro].min.value * (filterToLimitPriceToFixedRates.filtro[option.filtro].min.xpass ? passengerNumbers : 1);
        filter += '%26maxPrice%3d' + filterToLimitPriceToFixedRates.filtro[option.filtro].max.value * (filterToLimitPriceToFixedRates.filtro[option.filtro].max.xpass ? passengerNumbers : 1);
      }
    }
    return filter;
  }

  getCallGetCountry() {
    let callGetCountry = true;
    let envConf = environment.brands[this.dataStore.brand];
    if (
      envConf
      && envConf.callGetCountry != null
    ) {
      callGetCountry = envConf.callGetCountry;
    }
    UtilsResource.NSMarkLog("callGetCountry ---->", callGetCountry);
    return callGetCountry;
  }
}