import { PipesModule } from './../../../../pipes/pipes.module';
import { SharedModule } from './../../../../container/shared/shared.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CardLayoutTwoComponent } from './card-layout-two.component';
import { CardImageComponent } from './card-image/card-image.component';
import { CardDescriptionComponent } from './card-description/card-description.component';
import { CardPriceComponent } from './card-price/card-price.component';
import { CardHeaderComponent } from './card-header/card-header.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule.forChild(),
    PipesModule
  ],
  declarations: [
    CardLayoutTwoComponent,
    CardImageComponent,
    CardDescriptionComponent,
    CardPriceComponent,
    CardHeaderComponent,
  ],
  exports: [
    CardLayoutTwoComponent
  ]
})
export class CardLayoutTwoModule { }
