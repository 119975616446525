<div class="alert">
  <button class="close" (click)="close()">
    <i class="icon-close"></i>
  </button>
  <span class="cara-error"></span>
  <h1 mat-dialog-title class="title">
    {{tittle | translate}}
  </h1>
  <p>
    {{error | translate}}
  </p>

</div>
