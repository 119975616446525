import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { StoreServiceLibrary } from '@wearenovae/novae-core-services';
import { LanguageService } from 'src/app/component/language/language.service';
import { EProduct } from 'src/app/component/searched/models/searched.model';
import { ActionEventService } from 'src/app/services/action-event.service';
import { DataStoreService } from 'src/app/services/data-store.service';
import { DataService } from 'src/app/services/data.service';
import { InitService } from 'src/app/services/init.service';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-landing-two',
  templateUrl: './landing-two.component.html',
  styleUrls: ['./landing-two.component.scss']
})
export class LandingTwoComponent  {
  public translations = {};
  public isNewMenu = false;
  loadInfoLM = false;


  sbs: any;
  EProduct = EProduct;
  public hasBenefits = false;
  public hasActivities = true;
  public hasAbout = false;
  appName = '';
  logo = '';
  optionMenu = [];
  brand = '';
  public fixed: boolean;
  public id = 'header';


  get isAlleMobile(): boolean {
    return this.dataStoreService.isAlleMobile;
  }

  hasHeaderDownload = false;


  get tabSelect(): EProduct {
    return this.dataService.productSelected;
  }

  set tabSelect(value: EProduct) {
    this.dataService.productSelected = value;
  }

  paramPayLoad: string;
  tabSelectData: string;

  showHeader : boolean = true;
  

  constructor(
    private actionEventService: ActionEventService,
    private dataStoreService: DataStoreService,
    private dataService: DataService,
    private initService: InitService,
    private translate: TranslateService,
    private languageService: LanguageService,
    private _storeLibrary: StoreServiceLibrary,

  ) {
      this.dataService.verifyNeedToken();
      this.optionMenu 
   
  }
  ngOnInit() {

    this.translate.use(environment.brands[this.dataStoreService.brand].defaultLanguaje);
    this.translate.setDefaultLang(environment.brands[this.dataStoreService.brand].defaultLanguaje);
    this.languageService.setSystemLanguaje(environment.brands[this.dataStoreService.brand].defaultLanguaje);
    this._storeLibrary.setItemInStorage('lang', environment.brands[this.dataStoreService.brand].defaultLanguaje);

    this.brand = this.dataStoreService.brand.toUpperCase();
    this.appName = environment.brands[this.dataStoreService.brand].appName;
    this.logo = environment.brands[this.dataStoreService.brand].logo;
    this.initService.isInitializedBrand.subscribe(isInitialized => {
      if (isInitialized) {
       if (environment.brands[this.dataStoreService.brand].config.isNewHome) {
          this.isNewMenu = environment.brands[this.dataStoreService.brand].config.isNewHome;
          this.optionMenu = environment.brands[this.dataStoreService.brand].optionmenu;

          var URLactual = window.location;
          console.log(URLactual.hash)
          if(URLactual.hash=='#/example'){
            this.optionMenu[2]={ label: 'NAV.example', router: '/example', tabSelect: [EProduct.EXAMPLE] }
            this.actionEventService.setItemActiveTab(EProduct.EXAMPLE);
            this.dataService.productSelected = EProduct.EXAMPLE;
            this.dataStoreService.preloaderImg = EProduct.EXAMPLE;
          }else{
            this.optionMenu
          }
          
        }
       
      }
    });

    this.actionEventService.setItemActiveTab(EProduct.EXAMPLE);
    this.dataService.productSelected = EProduct.EXAMPLE;
    this.dataStoreService.preloaderImg = EProduct.EXAMPLE;
  }



 


}





  

