import { HttpClient } from '@angular/common/http';

import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { environment } from 'src/environments/environment';

export function HttpLoaderFactory(http: HttpClient) {


    let uri = '';
    const brand = sessionStorage.getItem('brand');
    if (brand !== null) {
        if (brand === 'scotia' || brand === 'myrewards' || brand === 'cibc' || brand === 'apap'
            || brand === 'credomatic' || brand === 'bancoindustrial' || brand === 'ncb' 
            || brand === 'firstcitizens' || brand === 'cryptmi') {
            const isSpecialTranslate = environment.brands[brand]['speciali18n'];
            if (isSpecialTranslate) {
                uri = '';
                uri = brand + '/';
            }
        }
    } else {
        window.location.reload();
    }
    return new TranslateHttpLoader(http, './assets/i18n/' + uri, '.json');
}
