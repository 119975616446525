<!--TODO: Componente para pintar los cards-->
<main class="main" *ngIf="!activateDetail">
  <div class="main-wrapper">
    <section class="section press1 padding-first gray">
      <div class="container">
        <div class="tittle">
          <h2>Noticias</h2>
          <p [innerHTML]="titleNews"></p>
        </div>

        <div class="bl-colums-press">


          <div class="item" *ngFor="let new of newsDataFilter">
            <div>
              <img [src]="new.image | domSecurity" alt="">
              <div class="bl-text">
                <span class="date">{{ new.date }}</span>
                <span>{{ new.titleShort }}</span>
                <p>{{ new.descriptionShort }}</p>
                <a class="more" (click)="goToDetail(new)">Leer más</a>
              </div>
            </div>
          </div>

          
        </div>

        <div class="row">
          <div class="side">
            <ul>
              <li class="active">Lorem ipsum dolor</li>
              <li>Lorem ipsum dolor sit amet</li>
              <li>Lorem ipsum dolor</li>
              <li>Lorem ipsum dolor sit</li>
              <li>Lorem ipsum dolor</li>
              <li>Lorem ipsum dolor sit amet consectetur</li>
              <li>Lorem ipsum dolor sit amet</li>
              <li>Lorem ipsum dolor</li>
              <li>Lorem ipsum dolor</li>
            </ul>
          </div>
          <div class="content">
            <h2>Lorem, ipsum dolor sit amet</h2>
            <div class="links">
              <a href="">
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit</p>
                <span class="icon-arrow"></span>
              </a>
              <a href="">
                <p>Lorem ipsum dolor sit amet consectetur</p>
                <span class="icon-arrow"></span>
              </a>
              <a href="">
                <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Est molestias</p>
                <span class="icon-arrow"></span>
              </a>
              <a href="">
                <p>Lorem ipsum dolor sit amet</p>
                <span class="icon-arrow"></span>
              </a>
              <a href="">
                <p>Lorem ipsum dolor sit amet consectetur adipisicing</p>
                <span class="icon-arrow"></span>
              </a>
            </div>
          </div>
        </div>

        <br>
        <br>

        <div class="row">
          <div class="side">
            <a href="">
              <span class="icon-arrow"></span>
              Lorem ipsum dolor sit amet
            </a>
            <ul>
              <li>Lorem ipsum dolor</li>
              <li>Lorem ipsum dolor sit amet</li>
              <li class="active">Lorem ipsum dolor</li>
              <li>Lorem ipsum dolor sit</li>
              <li>Lorem ipsum dolor</li>
              <li>Lorem ipsum dolor sit amet consectetur</li>
              <li>Lorem ipsum dolor sit amet</li>
              <li>Lorem ipsum dolor</li>
              <li>Lorem ipsum dolor</li>
            </ul>
          </div>
          <div class="content">
            <h2>Lorem, ipsum dolor sit amet</h2>
            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Incidunt officiis dicta eum ipsam minima maiores repudiandae tempore enim, praesentium molestias, quae ab doloribus fugiat, et necessitatibus voluptatem voluptatum. Inventore, nihil?</p>
            <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Voluptatem rerum ipsa at mollitia sapiente? Magnam, <strong>dolorum totam non voluptatem doloremque numquam vitae. Molestiae architecto</strong> voluptate at eius quibusdam consequatur necessitatibus. Lorem ipsum dolor sit amet consectetur adipisicing elit. Expedita a ut maiores cum maxime eaque amet soluta iusto neque recusandae voluptates, eligendi quos rem illum, saepe magni, alias sequi blanditiis.</p>
            <ul>
              <li>Lorem ipsum, dolor sit amet consectetur adipisicing elit.</li>
              <li>Lorem ipsum, dolor sit amet consectetur.</li>
              <li>Lorem ipsum, dolor sit amet consectetur adipisicing.</li>
              <li>Lorem ipsum, dolor sit amet.</li>
            </ul>
            <img src="/assets/images/credomatic-miles/reward1.png" alt="">
            <hr>
            <ol>
              <li>Lorem ipsum, dolor sit amet consectetur adipisicing elit.</li>
              <li>Lorem ipsum, dolor sit amet consectetur.</li>
              <li>Lorem ipsum, dolor sit amet consectetur adipisicing.</li>
            </ol>
            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Laborum incidunt aperiam recusandae ex, <a href="">minus obcaecati</a> quam facere eaque eveniet quasi commodi in repudiandae officia laboriosam. Dolor aperiam odit voluptatibus voluptate.</p>
          </div>
        </div>

        <br>
        <br>

        <div class="row">
          <div class="side">
            <a href="">
              <span class="icon-arrow"></span>
              Lorem ipsum dolor sit amet
            </a>
            <ul>
              <li>Lorem ipsum dolor</li>
              <li>Lorem ipsum dolor sit amet</li>
              <li>Lorem ipsum dolor</li>
              <li>Lorem ipsum dolor sit</li>
              <li class="active">Lorem ipsum dolor</li>
              <li>Lorem ipsum dolor sit amet consectetur</li>
              <li>Lorem ipsum dolor sit amet</li>
              <li>Lorem ipsum dolor</li>
              <li>Lorem ipsum dolor</li>
            </ul>
          </div>
          <div class="content">
            <h2>Lorem, ipsum dolor sit amet</h2>
            <div class="accordion">
              <div class="item">
                <h3>
                  <span class="icon-arrow"></span>
                  Lorem ipsum dolor sit amet consectetur
                </h3>
                <div class="info">
                  <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequatur at hic molestias quod recusandae quasi magni? Doloremque voluptate amet eum explicabo, a ipsam beatae ex, numquam enim commodi repellat! Deserunt?</p>
                  <ul>
                    <li>Lorem ipsum, dolor sit amet consectetur adipisicing elit.</li>
                    <li>Lorem ipsum, dolor sit amet.</li>
                    <li>Lorem ipsum, dolor sit amet consectetur.</li>
                  </ul>
                </div>
              </div>
              <div class="item open">
                <h3>
                  <span class="icon-arrow"></span>
                  Lorem ipsum dolor sit amet consectetur
                </h3>
                <div class="info">
                  <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequatur at hic molestias quod recusandae quasi magni? Doloremque voluptate amet eum explicabo, a ipsam beatae ex, numquam enim commodi repellat! Deserunt?</p>
                  <ul>
                    <li>Lorem ipsum, dolor sit amet consectetur adipisicing elit.</li>
                    <li>Lorem ipsum, <a href="">dolor sit amet</a>.</li>
                    <li>Lorem ipsum, dolor sit amet consectetur.</li>
                  </ul>
                </div>
              </div>
              <div class="item">
                <h3>
                  <span class="icon-arrow"></span>
                  Lorem ipsum dolor sit amet consectetur
                </h3>
                <div class="info">
                  <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequatur at hic molestias quod recusandae quasi magni? Doloremque voluptate amet eum explicabo, a ipsam beatae ex, numquam enim commodi repellat! Deserunt?</p>
                  <ul>
                    <li>Lorem ipsum, dolor sit amet consectetur adipisicing elit.</li>
                    <li>Lorem ipsum, dolor sit amet.</li>
                    <li>Lorem ipsum, dolor sit amet consectetur.</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </section>
  </div>
</main>


<!--TODO: Componente para pintar el detalle del card-->
<app-details-news *ngIf="activateDetail" [(newDetail)]="newDetail" (backToNews)="showNews($event)"></app-details-news>
