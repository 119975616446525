import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { DataStoreService } from '../../services/data-store.service';
import { DataService } from 'src/app/services/data.service';
import { LanguageService } from '../../component/language/language.service';
import { ActionEventService } from '../../services/action-event.service';


@Component({
  selector: 'app-main-form',
  templateUrl: './main-form.component.html',
  styleUrls: ['./main-form.component.scss']
})
export class MainFormComponent implements OnInit {

  @ViewChild('MainSteps') mainSteps: ElementRef;

  class = '';

  activeFlys: boolean = false;
  activeData: boolean = false;
  activePay: boolean = false;

  get mainFormTab(): string {
    return this.dataStore.mainFormTab;
  }

  set mainFormTab(value: string) {
    this.dataStore.mainFormTab = value;
  }

  public mainFormDinamicText = this.data.mainFormDinamicTexts;

  get brand() {
    return this.dataStore.brand;
  }

  constructor(
    private data: DataStoreService,
    private dataService: DataService,
    private dataStore: DataStoreService,
    private languageService: LanguageService,
    private actionEventService: ActionEventService
  ) {
    this.languageService.systemLanguajeChange.subscribe(async (language) => {
      if (language) {
        await this.dataService.updateMainFormTexts();
        this.mainFormDinamicText = this.data.mainFormDinamicTexts;
      }
    });
    this.activeStep();
  }

  async ngOnInit() {
    await this.dataService.updateMainFormTexts();
    this.mainFormDinamicText = this.data.mainFormDinamicTexts;
    if (this.brand === 'scotia' && this.dataStore.creditCardDefult === 'amex') {
      this.actionEventService.setSubBrand('amex');
      this.class = 'scotia-amex';
    } else {
      this.class = 'scotia-default';
    }
  }

  activeStep() {
    this.actionEventService.activeItemMain.subscribe(data => {
      switch (data) {
        case 'flys':
          this.activeFlys = true;
          this.activeData = false;
          this.activePay = false;
          break;
        case 'data':
          this.activeFlys = true;
          this.activeData = true;
          this.activePay = false;
          break;
        case 'pay':
          this.activeFlys = true;
          this.activeData = true;
          this.activePay = true;
          break;
        default:
          break;
      }

    });
  }
}
