import { DOCUMENT, ɵgetDOM as getDOM } from "@angular/common";
import { Inject, Injectable, OnDestroy } from "@angular/core";
import { ɵSharedStylesHost } from "@angular/platform-browser";


declare var window: any;

@Injectable()
export class CustomDomSharedStylesHost extends ɵSharedStylesHost implements OnDestroy{
  // Maps all registered host nodes to a list of style nodes that have been added to the host node.
  private _hostNodes = new Map<Node, Node[]>();
  private _nonce: string | null | undefined = null;

  constructor(
    @Inject(DOCUMENT) private _doc: any,
    @Inject('cspMetaSelector') private _metaCSPTag: string
  ) {
    super();    
    this._hostNodes.set(_doc.head, []);
    this._setCSPNonce();
    this.applyNonceToLivePersonTags();
  }

  private _addStylesToHost(
    styles: Set<string>,
    host: Node,
    styleNodes: Node[]
  ): void {
    styles.forEach((style: string) => {
      const styleEl = this._doc.createElement('style');
      styleEl.textContent = style;

      if (!style.includes('without-nonce') && this._nonce) {
        styleEl.setAttribute('nonce', this._nonce);
      }

      styleNodes.push(host.appendChild(styleEl));
    });

    if (this._nonce ) {
      this._removeCSPNonceHeader();
    }
  }

  private _setCSPNonce(): void {    
    this._nonce = document
      .querySelector(this._metaCSPTag)
      ?.getAttribute('content');
        
    if (this._nonce) {
      document.querySelectorAll('[nonce]')
        .forEach((item) => {
          item.setAttribute('nonce', this._nonce);
        });
    }
  }

  private _removeCSPNonceHeader(): void {
    document.querySelector(this._metaCSPTag)?.remove();
  }

  addHost(hostNode: Node): void {
    const styleNodes: Node[] = [];
    this._hostNodes.set(hostNode, styleNodes);
  }

  removeHost(hostNode: Node): void {
    const styleNodes = this._hostNodes.get(hostNode);
    if (styleNodes) {
      styleNodes.forEach(removeStyle);
    }
    this._hostNodes.delete(hostNode);
  }

  override onStylesAdded(additions: Set<string>): void {
    this._hostNodes.forEach((styleNodes, hostNode) => {
      this._addStylesToHost(additions, hostNode, styleNodes);
    });
  }

  ngOnDestroy(): void {
    this._hostNodes.forEach((styleNodes) => styleNodes.forEach(removeStyle));
  }

  applyNonceToLivePersonTags(): void {
    const headTag = document.head;
    const config = { attributes: true, childList: true, subtree: true };

    const callback = (mutationList, observer) => {
      for (const mutation of mutationList) {
        if (mutation.type === "childList") {
          if ("childList" === mutation.type 
            && mutation.addedNodes.length > 0 
            && "STYLE" == mutation.addedNodes[0].tagName 
            && null == mutation.addedNodes[0].getAttribute("nonce")) {              
            const newTag = mutation.addedNodes[0];
              newTag.remove(),
              newTag.setAttribute("nonce", this._nonce),
              headTag.append(newTag)
              if ("lpChatStyle" == newTag.getAttribute("id")) {
                setTimeout(() => {
                  observer.disconnect();                  
                });
              }
          }
        /*
        } else if (mutation.type === "attributes") {
          console.log(`The ${mutation.attributeName} attribute was modified.`);
        //*/
        }
      }
    };
    // Create an observer instance linked to the callback function
    const observer = new MutationObserver(callback);
    // Start observing the target node for configured mutations
    observer.observe(headTag, config);    
  }
}

function removeStyle(styleNode: Node): void {
  getDOM().remove(styleNode);
}
