<span class="icon-close" (click)="onCancel()"></span>
<h3 innerHTML="{{'LANDING.'+brand+'.SECTION_TWO.SECTION_EXPIREDATA.desc_1' | translate}}">
</h3>
<!-- <h2>21,537</h2> -->
<h2>{{ balance | number: pipePointFormat }}</h2>
<div class="expire-message">
    <!-- <p><strong>1,284 </strong> will expire on: Jan 30, 2023</p> -->
    <p *ngIf="dateExpire">
        <strong>
            {{ expireBalance | number: pipePointFormat }}
        </strong>
        {{'LANDING.'+brand+'.SECTION_TWO.SECTION_EXPIREDATA.desc_2' | translate}} {{ dateExpire | date:'MMM d, y'}}
    </p>
</div>