<div>
  <ng-template [ngIf]="options.type === 'car'">
    <h6 class="product-name car-name mt-2">{{ product.name }}</h6>
    <div class="clearfix"></div>
    <div class="col-12 services ps-0 mt-1 description" *ngIf="product.description">
      <!-- <i class="travel-icon icon-alert-rounded" aria-hidden="true"></i> -->
      <div class="refundable" [innerHtml]="product.description"></div>
    </div>
    <div>
      <ul>
        <ng-container *ngFor="let acrissdetail of getACRISSValues()">
          <li class="acriss">
            {{acrissdetail}}
          </li>
        </ng-container>
      </ul>
    </div>
  </ng-template>
</div>