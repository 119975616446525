import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-detail-gallery',
  templateUrl: './detail-gallery.component.html',
  styleUrls: ['./detail-gallery.component.scss']
})
export class DetailGalleryComponent implements OnInit {
  @Input() images = [];
  mainImageSrc: string;
  constructor() { }

  ngOnInit() {
    if (this.images === null || this.images.length === 0) {
      this.images = [
        {
          imgURL: './assets/images/empty_room.png',
          label: ''
        }
      ];
    }
    this.mainImageSrc = this.images[0].imgURL;
  }
  clickedImgSrcListener(src) {
    this.mainImageSrc = src;
  }

  onErrorFn() {
    this.mainImageSrc = this.images[0].imgURL;
  }

}
