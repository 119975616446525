import { PurchaseSummaryService } from './../purchase-summary.service';
import { Component, OnInit, Input } from '@angular/core';
import { IBenefitResponse } from 'src/app/models/benefitResponce-model';
import { SearchedService } from '../../searched/searched.service';
import { SearchedBenefit } from '../../searched/models/searched-benefit.model';

@Component({
  selector: 'app-benefit-summary',
  templateUrl: './benefit-summary.component.html',
  styleUrls: ['./benefit-summary.component.scss']
})
export class BenefitSummaryComponent implements OnInit {

  /**
   * modelo con los parámetros de búsqueda
   */
  searchedBenefit: SearchedBenefit;

  @Input() benefit: IBenefitResponse;

  translationsBenefit = [];

  get beneficiaries() {
    return this.searchedBenefit.qBeneficiariesToString;
  }

  get benefitInitDate() {
    return this.searchedBenefit.dateToHumanReadable.start;
  }

  get benefitEndDate() {
    return this.searchedBenefit.dateToHumanReadable.end;
  }

  constructor(
    private purchaseSummaryService: PurchaseSummaryService,
    private searchedService: SearchedService
  ) {
    this.searchedBenefit = this.searchedService.getModel(SearchedBenefit);
  }

  ngOnInit() {
    this.translationsBenefit = this.purchaseSummaryService.translationsBenefit;
  }

}
