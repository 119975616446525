import { Component, OnInit, ViewChild, Input, ElementRef, AfterViewInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { environment } from '../../../environments/environment';
import { ActionEventService } from '../../services/action-event.service';
import { InitService } from '../../services/init.service';
import { StoreServiceLibrary } from '@wearenovae/novae-core-services';
import { keysUniversal } from 'src/app/resources/keys.resources';
import { GoogleAnalytics } from '../../resources/GoogleAnalytics.resource';

@Component({
  selector: 'app-selector-country',
  templateUrl: './selector-country.component.html',
  styleUrls: ['./selector-country.component.scss']
})
export class SelectorCountryComponent implements OnInit, AfterViewInit {

  @ViewChild('countries') countriesElement: ElementRef;

  @Input() ocultedCountries: boolean;

  countrysClass = 'country'

  countries = [];

  countryDefault = 'CRI';

  get countriesArray() {
    return this.countries;
  }

  form = this.fb.group({
    countries: ['', Validators.required]
  });


  constructor(
    private fb: FormBuilder,
    private actionsEventService: ActionEventService,
    private initService: InitService,
    private storeServiceLibrary: StoreServiceLibrary

  ) {
  }

  ngOnInit() {
    this.initService.isInitializedBrand.subscribe((isInitialized) => {
      if (isInitialized) {
        let jsonCountries = this.storeServiceLibrary.getJustOneUniversalPropertie(keysUniversal.JSON_COUNTRIES_ALLETRAVEL);
        if (jsonCountries) {
          let castCountries = JSON.parse(jsonCountries);
          this.countries = castCountries['countries'];
          this.getCountryCode();
        }
      }
    });
  }

  ngAfterViewInit() {
    this.actionsEventService.countryCodeChange.subscribe((countryCodeChange) => {

      if (countryCodeChange && countryCodeChange.code) {
        for (const country of this.countries) {
          if (country && country.code == countryCodeChange.code) {

            this.storeServiceLibrary.setItemInStorage('CountrySelected', country.code);
            GoogleAnalytics.setCountryUser(country.name);

            this.form.get('countries').setValue(countryCodeChange.code, {
              emitEvent: false
            });
            break;
          }
        }
      }
    });
    // cuando cambia el country del selector
    // inicializar selector basados en el lenguaje defualt del sistema
    this.form.get('countries').setValue(this.countryDefault, {
      emitEvent: false
    });
    // cuando cambia el country del selector
    this.form.get('countries').valueChanges.subscribe((countryCode) => {
      if (this.form.get('countries')) {
        for (const country of this.countries) {
          if (country && country.code == countryCode) {
            this.actionsEventService.sendCountryCode(country);
            this.storeServiceLibrary.setItemInStorage('CountrySelected', country.code);
            GoogleAnalytics.setCountryUser(country.name);
            break;
          }
        }
      }
    });
  }


  getCountryCode() {
    const smartProfile = JSON.parse(sessionStorage.getItem('smartProfile'));
    let countryCode = 'CRI';
    let countryCodeStore = this.storeServiceLibrary.getItemFromStorage('CountrySelected');
    if (countryCodeStore) {
      countryCode = countryCodeStore;
    } else if (smartProfile && smartProfile.accountResultDto && smartProfile.accountResultDto.isoCountyCard) {
      countryCode = smartProfile.accountResultDto.isoCountyCard;
    }
    for (const country of this.countries) {
      if (country && country.code == countryCode) {
        this.actionsEventService.sendCountryCode(country);
        this.storeServiceLibrary.setItemInStorage('CountrySelected', country.code);
        GoogleAnalytics.setCountryUser(country.name);
        break;
      }
    }
  }
}
