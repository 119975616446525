import { EProduct } from './../searched/models/searched.model';
import { PurchaseSummaryService } from './purchase-summary.service';
import { Component, OnInit, Input } from '@angular/core';
import { IPurchaseSummaryConfig } from './models/purchase-sumary-config.model';
import { DataService } from '../../services/data.service';
import { DataStoreService } from '../../services/data-store.service';
import { RegistrationService } from '../../pages/registration/registration.service';
import data from 'src/app/resources/screenAndEventsTracking.json';
import { GoogleAnalytics } from '../../resources/GoogleAnalytics.resource';
import { InitService } from '../../services/init.service';

@Component({
  selector: 'app-purchase-summary',
  templateUrl: './purchase-summary.component.html',
  styleUrls: ['./purchase-summary.component.scss']
})
export class PurchaseSummaryComponent implements OnInit {

  EProduct = EProduct;

  @Input() config: IPurchaseSummaryConfig;

  get isTranslationsLoaded() {
    return this.purchaseSummaryService.isTranslationsLoaded;
  }

  set tabSelect(value: string) {
    this.data.productSelected = value as EProduct;
    this.dataStore.preloaderImg = value;
  }

  constructor(
    public purchaseSummaryService: PurchaseSummaryService,
    private data: DataService,
    public registrationService: RegistrationService,
    private dataStore: DataStoreService,
    private initService: InitService
  ) { }

  ngOnInit() {
    let tipoUpperCase = this.data.productSelected.replace(/\b\w/g, (l) => l.toUpperCase());;
    // this.trackingManager.setCurrentScreen("/data-pay", data.DATA_PRODUCT_USER.dataScreen + tipoUpperCase);
    this.config.type = this.config.type as EProduct;
    this.initService.isInitializedBrand.subscribe((isInit) => {
      GoogleAnalytics.setCurrentScreen('/data-pay');
    });
  }

  paymentButton() {
    if (this.config.paymentButton) {
      this.config.paymentButton();
      let productInfo;
      if (this.data.productSelected === EProduct.FLIGHTS) {
        productInfo = this.data.flightAvailabilityRequest;
      } else if (this.data.productSelected === EProduct.HOTELS) {
        productInfo = this.data.hotelAvailabilityRequest;
      } else if (this.data.productSelected === EProduct.ACTIVITIES) {
        productInfo = this.data.activityRequestParameters;
      } else if (this.data.productSelected === EProduct.CARS) {
        productInfo = this.dataStore.carDataSearch;
      }
      let paramsDataPay = {
        'userInfo': this.data.smartProfileUserData,
        'productInfo': productInfo
      }
      let tipoUpperCase = this.data.productSelected.replace(/\b\w/g, (l) => l.toUpperCase());
      // this.trackingManager.trackEventString(data.DATA_PRODUCT_USER.payDataButton + tipoUpperCase, data.DATA_PRODUCT_USER.dataScreen + tipoUpperCase, '', JSON.stringify(paramsDataPay));
      GoogleAnalytics.sendEventFirebase('ga_event', data.DATA_PRODUCT_USER.dataScreen + tipoUpperCase, data.DATA_PRODUCT_USER.payDataButton + tipoUpperCase, JSON.stringify(paramsDataPay));
    }
  }

  modifyButton() {
    if (this.config.modifyButton) {
      this.config.modifyButton();
      this.dataStore.preloaderImg = this.config.type;
    }
    let tipoUpperCase = this.data.productSelected.replace(/\b\w/g, (l) => l.toUpperCase());
    // this.trackingManager.trackEventString(data.DATA_PRODUCT_USER.modifyDataProduct + tipoUpperCase, data.DATA_PRODUCT_USER.dataScreen + tipoUpperCase);
    GoogleAnalytics.sendEventFirebase('ga_event', data.DATA_PRODUCT_USER.dataScreen + tipoUpperCase, data.DATA_PRODUCT_USER.modifyDataProduct + tipoUpperCase, '');
  }
}
