<!-- <div *ngIf="showComponent"> -->
<div [style.display]='showComponent ? "block" : "none"'>

    <div class="title-ask">
        <div class="container">
            <h2 [innerHTML]="config?.title[this.translate.currentLang]">
                Frequently Asked Questions
            </h2>
            <p [innerHTML]="config?.description[this.translate.currentLang]">
                <strong>Got a question?</strong>
                <br>
                Reach out to alle and get answers 24/7.
                <a href="">Just click here.</a>
            </p>
        </div>
    </div>

    <section class="ask">
        <div class="container">
            <mat-tab-group mat-align-tabs="center" (selectedTabChange)="onTabChanged($event)">
                <mat-tab *ngFor="let tabItem of config?.tabs" [label]="tabItem?.title[this.translate.currentLang]">
                    <mat-accordion>
                        <mat-expansion-panel *ngFor="let questPanel of tabItem?.questionsPanel">
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    <p [innerHTML]="questPanel?.question[this.translate.currentLang]">What is <strong> My Rewards</strong>?</p>
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <div class="content">
                                <p *ngFor="let paragraph of questPanel?.answer?.paragraphs" [innerHTML]="paragraph[this.translate.currentLang]">
                                </p>


                                <!-- PLANTILLA -->
                                <ng-template #recursiveList let-list>
                                    <ul>
                                        <li *ngFor="let step of list; let i = index">
                                            <!-- ICON -->
                                            <span *ngIf="!step?.iconVignette" class="number">{{ i + 1 }}</span>
                                            <span *ngIf="step?.iconVignette" [ngClass]="step?.iconVignette"></span>
                                            <!-- ICON -->
                                            <span *ngIf="step?.text && step?.text[this.translate.currentLang]" [innerHTML]="step?.text[this.translate.currentLang]"></span>
                                            <ng-container *ngIf="step?.steps">
                                                <ng-container 
                                                    *ngTemplateOutlet="recursiveList; context: { $implicit: step.steps }">
                                                </ng-container>
                                            </ng-container>
                                        </li>
                                    </ul>
                                </ng-template>
                                <!-- PLANTILLA -->
                                <!-- RENDER DE LA PLANTILLA -->
                                <ng-container *ngTemplateOutlet="recursiveList; context: { $implicit: questPanel?.answer?.steps }">
                                </ng-container>
                                <!-- RENDER DE LA PLANTILLA -->
                                
                                <p *ngFor="let paragraphF of questPanel?.answer?.paragraphFooter" [innerHTML]="paragraphF[this.translate.currentLang]">
                                    <strong>
                                        All set! Better pack your bags!
                                    </strong>
                                </p>
                                <div class="content-beneficios" >
                                    <div class="card-beneficios bg-blanco " *ngFor="let membership of questPanel?.answer?.membershipList">

                                        <span class="titulo-card" [innerHTML]="membership.title[translate.currentLang]"></span>
                                        <div class="precio-card">
                                            <strong>
                                                {{ membership.price[translate.currentLang] }}
                                                <span>
                                                    /{{ membership.frecuencyTime[translate.currentLang] }}
                                                </span>
                                            </strong>
                                        </div>
                                        <div class="contenido-card">

                                            <div class="parrafo-card" *ngFor="let benefit of membership?.benefitList">
                                                <span class="titulo-parrafo-card" [innerHTML]="benefit.title[translate.currentLang]">FLYCARE</span>
                                                <span class="titulo-parrafo-card" [innerHTML]="benefit.title2[translate.currentLang]">(Durante tu vuelo)</span>
                                                <div *ngFor="let benefitDetail of benefit?.benefitDetailList">
                                                    <span [innerHTML]="benefitDetail.detail[translate.currentLang]"></span>
                                                    <span>
                                                        <strong>{{ benefitDetail.simbolCurrency[translate.currentLang] }} {{ benefitDetail.price[translate.currentLang] }}</strong>
                                                    </span> 
                                                </div>
                                                
                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                            <div class="video" *ngIf="questPanel?.answer?.videos?.length > 0">
                                <div *ngFor="let video of questPanel?.answer?.videos">
                                    <div class="image" (click)="openModal(video?.url[this.translate.currentLang])">
                                        <img alt="" [src]="video?.img[this.translate.currentLang]">
                                    </div>
                                    <span [innerHTML]="video?.description[this.translate.currentLang]">
                                        To learn more, check out this video
                                    </span>
                                    <p [innerHTML]="video?.title[this.translate.currentLang]">
                                        <strong>Booking travel</strong>
                                    </p>
                                </div>
                            </div>

                        </mat-expansion-panel>
                    </mat-accordion>
                </mat-tab>
            </mat-tab-group>
        </div>
    </section>

    <div class="bg" [ngClass]="{'open': open}">
        <div class="video">
            <!-- <span class="icon-play" (click)="playVideo()" *ngIf="!play"></span> -->
            <span class="icon-close" (click)="closeModal()"></span>
            <!-- <video [src]="video" controls autoplay #elementVideo></video> -->

            <div class="bloque-video">
                <div class="iphone-video">
                    <video [src]="video" controls autoplay #elementVideo poster="./assets/images/myrewards-miles/poster-ask.webp"></video>
                </div>
                <img class="imagen-video" src="./assets/images/myrewards-miles/bg-iphone-ask.png" alt="">
            </div>
        </div>
        
    </div>

    

</div>