import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-booking-alert',
  templateUrl: './booking-alert.component.html',
  styleUrls: ['./booking-alert.component.scss']
})
export class BookingAlertComponent implements OnInit {

  options = {
    subtitle: null,
    message: null,
    confirmText: null,
    cancelText: null,
    imgURL: '',
    successAction: () => {},
    cancelAction: () => {},
  };

  get imgURL() {
    return (this.options.imgURL) ? this.options.imgURL : 'https://www.freeiconspng.com/uploads/error-icon-4.png';
  }
  constructor(
    public dialogRef: MatDialogRef<BookingAlertComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit() {
    this.options = this.data;
  }

  successButton() {
    this.options.successAction();
  }
  cancelButton(ev) {
    this.options.cancelAction();
  }

}
