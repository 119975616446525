import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {PassengersComponent} from "./passengers/passengers.component";
import {GuestsComponent} from "./guests/guests.component";
import {BuyerComponent} from "./buyer.component";
import {MatFormFieldModule} from "@angular/material/form-field";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {MatExpansionModule} from "@angular/material/expansion";
import {MatInputModule} from "@angular/material/input";
import {MatSelectModule} from "@angular/material/select";
import { DriverComponent } from './driver/driver.component';
import { BeneficiaryComponent } from './beneficiary/beneficiary.component';
import { AcitvityPassengerComponent } from './acitvity-passenger/acitvity-passenger.component';
import {AppModule} from "../../../app.module";

@NgModule({
  declarations: [

  DriverComponent,

  BeneficiaryComponent,

  AcitvityPassengerComponent],
  exports: [
    DriverComponent,
    BeneficiaryComponent,
    AcitvityPassengerComponent

  ],
  imports: [
    CommonModule,
    MatFormFieldModule,
    FormsModule,
    ReactiveFormsModule,

    // Angular Material Modules
    MatCheckboxModule,
    MatExpansionModule,
    MatInputModule,
    MatSelectModule,
  ]
})
export class BuyerModule { }
