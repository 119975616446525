/**
 * archivos "/assets/i18n/'languaje'.json"
 */
export enum LanguagePrefix {
    es = 'es',
    en = 'en'
}

export interface Language {
    prefix: LanguagePrefix; // archivo json con las traducciones
    name: string; // nombre del lenguaje
}
