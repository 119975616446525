import { Component, OnInit, Input } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { DataStoreService } from '../../../services/data-store.service';
import { StoreServiceLibrary } from '@wearenovae/novae-core-services';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-section-three-mr',
  templateUrl: './section-three-mr.component.html',
  styleUrls: ['./section-three-mr.component.scss']
})
export class SectionThreeMRComponent implements OnInit {

  @Input() brand: string;
  urlsVideosFirstCitizens: any = {};
  video2
  urlsVideos: any = environment.brands[this.dataStore.brand].videosLandingEN.sectionThree;

  imgSwitch = `./assets/images/${this.dataStore.brand}-miles/iphone-switch.png`;

  objToLoadVideo = {
    one: false,
    two: false
  };

  constructor(private dataStore: DataStoreService,
    private store: StoreServiceLibrary,
    private translate: TranslateService) { }

  ngOnInit() {
    this.changeLanguaje();
    if(this.brand==='firstcitizens'){
      this.video2= true
      if(this.video2==true){
        this.urlsVideosFirstCitizens= environment.brands[this.dataStore.brand].videosLanding.sectionThree;
        this.urlsVideos= this.urlsVideosFirstCitizens;
        this.objToLoadVideo.one = true;
      
      }
           
           }
  }

  changeLanguaje() {
    this.translate.onLangChange.subscribe((data) => {
      if (data.lang === 'es') {
        this.imgSwitch = `./assets/images/${this.dataStore.brand}-miles/es/iphone-switch.png`;
        this.urlsVideos = environment.brands[this.dataStore.brand].videosLandingES.sectionThree;
      } else {
        this.imgSwitch = `./assets/images/${this.dataStore.brand}-miles/iphone-switch.png`;
        this.urlsVideos = environment.brands[this.dataStore.brand].videosLandingEN.sectionThree;
      }
    });
  }
}
