import { Injectable, AfterViewInit } from '@angular/core';
import { FlightAvailabilityRequest } from '../models/flights/flightavailabilityrequest.model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { JourneySearch } from '../models/flights/journeySearch.model';
import { Journey } from '../models/flights/journey.model';
import { Router, NavigationEnd } from '@angular/router';
import { SelectedJourney } from '../models/flights/selected-journey.model';
import { FlightBookingRequest } from '../models/flights/flight-booking-request.model';
import { FlightBookingResponse } from '../models/flights/flight-booking-response.model';
import { environment, typeApp } from './../../environments/environment';
import moment from 'moment';
import { AuthGetAccount2Response, UniversalService, StoreServiceLibrary, AccountResultDto, AccountSumaryDto, UserData, GetInfoCardResponse, OnePocketService, ScotiaImplementationService, GeneralUtilService } from '@wearenovae/novae-core-services';
import { IJourneyInfo } from '../models/flights/journeyParamsInfo';
import { DataStoreService } from './data-store.service';
import { SearchErrorType } from '../models/search-error-type.model';
import { formatDate } from '@angular/common';
import { locale } from 'moment';
import { HotelBookingRequest } from '../models/hotel/request/hotel-booking-request.model';
import { HotelBookingResponse } from '../models/hotel/response/hotel-booking-response.model';
import { HotelAvailabilityRequest } from '../models/hotel/request/hotel-availability-request.model';
import { HotelAvailabilityResponse } from '../models/hotel/response/hotel-availability-response.model';
import { HotelBookingRulesResponse } from '../models/hotel/response/hotel-booking-rules-response.model';
import { HotelAvailabilityOptions } from '../models/hotel/response/hotel-availability-options.model';
import { MainFormDinamicTexts } from '../models/main-form-dinamic-texts.model';
import { CarBookingRequest } from '../models/car/request/carBookingRequest.model';
import { CarBookingResponse } from '../models/car/response/carBookingResponse.model';
import { CarBookingRulesRequest } from '../models/car/request/carBookingRulesRequest.model';
import { CarBookingRulesResponse } from '../models/car/response/carBookingRulesResponse.model';
import { HotelContentResponse } from '../models/hotel/response/hotel-content-response.model';
import { RentacarAvailabilityResponse } from '../models/rentacar/response/rentacar-availability-response';
import { RentacarAvailabilityOption } from '../models/rentacar/response/rentacar-availability-option';
import { CarAvailabilityRequest } from '../pages/home/search/search-cars/search-cars.component';
import { ISmartProfilePreferences } from '../models/flights/smartProfilePreferences';
import { FormGroup } from '@angular/forms';
import { BenefitsRequest } from '../models/benefits/benefits-request.model';
import { TermsAndConditionsComponent } from '../component/terms-and-conditions/terms-and-conditions.component';
import { MatDialog } from '@angular/material/dialog';
import { BenefitsBookingRequest } from '../models/benefits/BenefitsBookingRequest.model';
import { ProductResponse } from '../models/benefit-old/product-response.model';
import { LanguageService } from '../component/language/language.service';
import { TranslateService } from '@ngx-translate/core';
import { IBenefitResponse } from '../models/benefitResponce-model';
import * as uuid from 'uuid';
import { IActivityRequestParameters } from '../models/activities/response/activity-availability.model';
import { EProduct } from '../component/searched/models/searched.model';
import { getCurrencySymbol } from '@angular/common';
import { Observable, Subscriber } from 'rxjs';
import { BenefitsRequestMock } from '../mocks/benefits-request.mock';
import { RentacarBookingRulesRequest } from '../models/new-rentacar/rentacar-booking-rules-request.model';
import { ActionEventService } from './action-event.service';
import data from '../resources/screenAndEventsTracking.json';
import { keysUniversal } from 'src/app/resources/keys.resources';
import { ModalQuestionComponent } from '../component/modal/modal-question/modal-question.component';
import { GoogleAnalytics } from '../resources/GoogleAnalytics.resource';
import { UtilsResource } from '../resources/UtilsResource.resource';
import { LoginComponent } from '../container/shared/login/login.component';

declare var window: any;
declare var document: any;
declare var global: any;
declare var androidProxy: any;


@Injectable({
  providedIn: 'root'
})
export class DataService {

  keyURL = sessionStorage.getItem('key');
  public token = '';
  public translations = [];
  public translations_gender = [];
  public smartProfile: AuthGetAccount2Response | any;
  public flightAvailabilityRequest: FlightAvailabilityRequest;
  public hotelResponseBooking: HotelBookingResponse;
  public getCurrencySymbol = getCurrencySymbol;
  public dataCard: any;
  // environment.registrationType[this.dataStore.brand];
  public registrationType = ''; // environment.brands[this.dataStore.brand].registrationType;

  get productSelected(): EProduct {
    return (sessionStorage['EProductSelected'] as EProduct) || EProduct.FLIGHTS;
  }

  set productSelected(v: EProduct) {
    sessionStorage['EProductSelected'] = v;
  }

  get activityRequestParameters(): IActivityRequestParameters {
    return JSON.parse(sessionStorage['activityRequestParameters']);
  }

  set activityRequestParameters(v: IActivityRequestParameters) {
    sessionStorage['activityRequestParameters'] = JSON.stringify(v);
  }

  get conversionRate() {
    return this.smartProfile && this.smartProfile.accountResultDto
      ? this.smartProfile.accountResultDto.redemptionValuePerPoint
      : 1;
  }

  get userPointsSP() {
    return this.smartProfile && this.smartProfile.accountResultDto
      ? this.smartProfile.accountResultDto.balance
      : 0;
  }

  get smartProfileUserData() {
    return this.smartProfile && this.smartProfile.accountResultDto && this.smartProfile.accountResultDto.accountSumaryDto
      ? this.smartProfile.accountResultDto.accountSumaryDto
      : false;
  }

  public flightSearchError: SearchErrorType;
  public hotelAvailabilityRequest: HotelAvailabilityRequest;
  private middlewareUrl: string;
  private consolidlUrl = this.storeServiceLibrary.getJustOneUniversalPropertie(keysUniversal.consolid_url);
  private middlewareBenefitsURL: string;
  private urlOnePocket = '';
  public idApplication = ''; // environment.brands[this.dataStore.brand].applicationId;
  private rutaActual = '';
  private systemLanguage = 'EN';
  public selectedJourney: SelectedJourney = {
    journey: {}
  };
  public benefitsFormGroup: FormGroup;

  constructor(
    private httpClient: HttpClient,
    private router: Router,
    private dataStore: DataStoreService,
    public dialog: MatDialog,
    private languageService: LanguageService,
    private translateService: TranslateService,
    private universalService: UniversalService,
    private actionEventService: ActionEventService,
    private storeServiceLibrary: StoreServiceLibrary,
    private _onePocketService: OnePocketService,
    private scotiaServiceImplementation: ScotiaImplementationService,
    private generalUtil: GeneralUtilService
  ) {

    if (this.keyURL === 'localhost') {
      this.middlewareUrl = '/api/';
      this.middlewareBenefitsURL = '/apib/';
    } else {
      this.middlewareUrl = this.storeServiceLibrary.getJustOneUniversalPropertie(keysUniversal.middleware_url);
      this.middlewareBenefitsURL = this.storeServiceLibrary.getJustOneUniversalPropertie(keysUniversal.middleware_benefits_url);
    }
    // cuando cambia el idioma del sistema
    this.languageService.systemLanguajeChange.subscribe(language => {
      if (language) {
        this.getTranslations().then(() => {
          this.updateMainFormTexts();
        });
        this.systemLanguage = language;
        this.setIframeUrl();
      }
    });

    /* SETTEO DE VARIABLES DESDE EL SESSIONSTORAGE */
    this.token = (sessionStorage['novae_token'] !== undefined) ? sessionStorage['novae_token'] : this.token;

    this.flightAvailabilityRequest = (sessionStorage['flight_availability_request'] !== undefined)
      ? JSON.parse(sessionStorage['flight_availability_request'])
      : this.flightAvailabilityRequest;

    this.dataStore.carDataSearch = (sessionStorage['carDataSearch'] !== undefined)
      ? JSON.parse(sessionStorage['carDataSearch'])
      : this.dataStore.carDataSearch;

    this.selectedJourney = (sessionStorage['selected_jounrney'] !== undefined)
      ? JSON.parse(sessionStorage['selected_jounrney'])
      : this.selectedJourney;

    this.dataStore.selectedBenefit = (sessionStorage['selectedBenefit'] === undefined || sessionStorage['selectedBenefit'] === 'undefined')
      ? this.dataStore.selectedBenefit
      : JSON.parse(sessionStorage['selectedBenefit']);

    this.dataStore.benefitsRequest = (sessionStorage['benefitsRequest'] !== undefined)
      ? JSON.parse(sessionStorage['benefitsRequest'])
      : this.dataStore.benefitsRequest;

    this.dataStore.selectedHotel = (sessionStorage['selectedHotel'] === undefined || sessionStorage['selectedHotel'] === 'undefined')
      ? this.dataStore.selectedHotel
      : JSON.parse(sessionStorage['selectedHotel']);

    this.dataStore.selectedBenefit = (sessionStorage['selectedBenefit'] === undefined || sessionStorage['selectedBenefit'] === 'undefined')
      ? this.dataStore.selectedBenefit
      : JSON.parse(sessionStorage['selectedBenefit']);

    this.dataStore.selectedRentaCar = (sessionStorage['selectedRentaCar'] === undefined ||
      sessionStorage['selectedRentaCar'] === 'undefined')
      ? this.dataStore.selectedRentaCar
      : JSON.parse(sessionStorage['selectedRentaCar']);

    this.smartProfile = (sessionStorage['smartProfile'] !== undefined && sessionStorage['smartProfile'] !== 'undefined')
      ? JSON.parse(sessionStorage['smartProfile'])
      : this.smartProfile;

    if (sessionStorage['mainFormDinamicTexts'] !== undefined) {
      this.dataStore.mainFormDinamicTexts = JSON.parse(sessionStorage['mainFormDinamicTexts']);
    } else {
      this.updateMainFormTexts();
    }

    this.dataStore.searchBenefits = (sessionStorage['searchBenefits'] !== undefined)
      ? JSON.parse(sessionStorage['searchBenefits'])
      : this.dataStore.searchBenefits;

    this.dataStore.journeyParamsInfo = (sessionStorage['journeyParamsInfo'] !== undefined)
      ? JSON.parse(sessionStorage['journeyParamsInfo'])
      : this.dataStore.journeyParamsInfo;

    this.dataStore.carInfoParams = (sessionStorage['carInfoParams'] !== undefined)
      ? JSON.parse(sessionStorage['carInfoParams'])
      : this.dataStore.carInfoParams;

    this.dataStore.hotelInfoParams = (sessionStorage['hotelInfoParams'] !== undefined)
      ? JSON.parse(sessionStorage['hotelInfoParams'])
      : this.dataStore.hotelInfoParams;

    this.dataStore.hotelDataSearch = (sessionStorage['hotelDataSearch'] !== undefined)
      ? JSON.parse(sessionStorage['hotelDataSearch'])
      : this.dataStore.hotelDataSearch;

    this.router.events.subscribe((evt) => {
      if (evt instanceof NavigationEnd) {
        this.rutaActual = evt.urlAfterRedirects;
      }
    });

    this.dataStore.brand = (sessionStorage['brand'] !== undefined)
      ? sessionStorage['brand']
      : this.dataStore.brand;

    this.dataStore.brandTargetCurrency = (sessionStorage['brandTargetCurrency'] !== undefined)
      ? sessionStorage['brandTargetCurrency']
      : this.dataStore.brandTargetCurrency;

    this.dataStore.latestPrice = (sessionStorage['latestPrice'] !== undefined)
      ? sessionStorage['latestPrice']
      : this.dataStore.latestPrice;

    this.dataStore.creditCardDefult = (sessionStorage['creditCardDefult'] !== undefined)
      ? sessionStorage['creditCardDefult']
      : this.dataStore.creditCardDefult;
  }

  getTranslations() {
    return new Promise<any>((resolve, reject) => {
      this.translateService.get([
        'DATA_SERVICE.economy_cabin',
        'DATA_SERVICE.bussines_cabin',
        'DATA_SERVICE.first_class_cabin',
        'DATA_SERVICE.premium_economy_cabin',
        'DATA_SERVICE.combined_cabin',
        'DATA_SERVICE.baggage_pieces',
        'DATA_SERVICE.baggage_kilograms',
        'DATA_SERVICE.baggage_one_piece',
        'DATA_SERVICE.baggage_hand_baggage',
        'DATA_SERVICE.refundable_refundable',
        'DATA_SERVICE.refundable_norefundable',
        'DATA_SERVICE.stops_direct',
        'DATA_SERVICE.stops_scale',
        'DATA_SERVICE.stops_scales',
        'DATA_SERVICE.stops_scale_at',
        'DATA_SERVICE.stops_scales_at',
        'MAIN_FORM.flight',
        'MAIN_FORM.hotel',
        'MAIN_FORM.activity',
        'MAIN_FORM.benefit',
        'MAIN_FORM.car',
        'GENDERS'
      ]).subscribe((trns) => {
        this.translations = trns;
        this.translations_gender = trns['GENDERS'];
        resolve(true);
      });
    });
  }

  /* FUNCIONES PARA FORMATEAR DATOS A MOSTRAR */
  getCabinType(cabin) {
    switch (cabin) {
      case 'Y':
        return this.translations['DATA_SERVICE.economy_cabin'];
      case 'B':
        return this.translations['DATA_SERVICE.bussines_cabin'];
      case 'F':
        return this.translations['DATA_SERVICE.first_class_cabin'];
      case 'P':
        return this.translations['DATA_SERVICE.premium_economy_cabin'];
      case 'M':
        return this.translations['DATA_SERVICE.combined_cabin'];
      default:
        break;
    }
  }

  getBaggageType(baggage) {
    let label = '';
    if (baggage.indexOf('PC') > -1) {
      label = ' ' + this.translations['DATA_SERVICE.baggage_pieces'];
    } else if (baggage.indexOf('K') > -1) {
      label = this.translations['DATA_SERVICE.baggage_kilograms'];
    }
    const qty = parseInt(baggage);
    if (qty === 1) {
      return this.translations['DATA_SERVICE.baggage_one_piece'];
    } else if (qty > 1) {
      return qty + label;
    } else {
      return this.translations['DATA_SERVICE.baggage_hand_baggage'];
    }
  }

  getRefundableType(refundable) {
    // TODO: FALTAN RESPUESTA EN REFUNDABLE
    switch (refundable) {
      case 'Y':
        return this.translations['DATA_SERVICE.refundable_refundable'];
      default:
        return this.translations['DATA_SERVICE.refundable_norefundable'];
    }
  }

  getStopIata(iataArr) {
    iataArr = iataArr.slice(1, (iataArr.length - 1));
    return iataArr.join(' - ');
  }

  getStopIataLongNames(flight) {
    const iataArr = flight.segments.slice(0, flight.segments.length - 1);
    let scalesText: string = "";
    if (iataArr.length === 1) {
      scalesText = this.translations['DATA_SERVICE.stops_scale_at'];
      scalesText = scalesText + ' ' + iataArr[0].destination.longName;
    } else if (iataArr.length > 1) {
      scalesText = this.translations['DATA_SERVICE.stops_scales_at'];
      iataArr.forEach((scale, idx) => {
        if (idx > 0) {
          scalesText = scalesText + ' -';
        }
        scalesText = scalesText + ' ' + scale.destination.longName;
      });
    }
    // iataArr = iataArr.slice(1, (iataArr.length - 1));
    // iataArr.join(' - ')
    return scalesText;
  }

  getStopQty(stops) {
    switch (stops) {
      case 0:
        return this.translations['DATA_SERVICE.stops_direct'];
      case 1:
        return '1' + this.translations['DATA_SERVICE.stops_scale'];
      case 2:
        return '2' + this.translations['DATA_SERVICE.stops_scales'];
      default:
        return '+ 2' + this.translations['DATA_SERVICE.stops_scales'];
    }
  }

  // TODO: pasar a una lista global
  getTitleNameByID(id) {
    const title = this.translations_gender.find(item => item.id === id);
    return title.value;
  }

  /**
   * FUNCIONES PARA OBTENER EL NOMBRE DEL PAIS Y CIUDAD A PARTIR DEL CODIGO
   */
  getCountryNameByID(id) {
    if (!this.dataStore.countryList) {
      this.universalService.getCountries(environment.brands[this.dataStore.brand].novaeUniversalUrlBase).subscribe(res => {
        this.dataStore.countryList = res.countryDtoList;
      });
      return '';
    } else {
      return this.dataStore.countryList.find(
        country => country.iso === id
      ).name;
    }
  }

  sendRequestAvailabilityFlight() {
    this.flightAvailabilityRequest.channelId = this.dataStore.channelID;
    return this.httpClient.post<JourneySearch>(this.middlewareUrl + 'flights/availability', this.flightAvailabilityRequest);
  }

  sendRequestPurchaseConfirmation(req) {
    return this.httpClient.patch<any>(this.middlewareUrl + 'booking/set-purchase-ID', req);
  }

  sendRequestBenefitConfirmation(req) {
    return this.httpClient.post<any>(this.middlewareUrl + 'benefits/confirm-sale', req);
  }

  sendRequestBooking(flightBookingRequest: FlightBookingRequest) {
    return this.httpClient.post<FlightBookingResponse>(this.middlewareUrl + 'flights/book', flightBookingRequest);
  }

  sendRequestAvailabilityHotel() {
    return this.httpClient.post<HotelAvailabilityResponse>(this.middlewareUrl + 'hotels/availability', this.dataStore.hotelDataSearch);
  }

  sendRequestAvailabilityActivities(req: any) {
    // req.destinationZone = 15011;
    return this.httpClient.post<any>(this.middlewareUrl + 'activities/availability', req);
  }

  sendBookingRulesActivities(req: any) {
    return this.httpClient.post<any>(this.middlewareUrl + 'activities/getBookingRules', req);
  }

  sendRequestBookingActivities(req: any) {
    return this.httpClient.post<any>(this.middlewareUrl + 'activities/makeBooking', req);
  }

  sendBookingRulesRequest(hotelBookingRulesRequest: any) {
    return this.httpClient.post<HotelBookingRulesResponse>(this.middlewareUrl + 'hotels/booking-rules', hotelBookingRulesRequest);
  }

  sendContentHotelRequest(req: string) {
    return this.httpClient.post<HotelContentResponse[]>(this.middlewareUrl + 'hotels/content?ids=' + req, {});
  }

  sendHotelRequestBooking(hotelRegistryBookingRequest: HotelBookingRequest) {
    return this.httpClient.post<HotelBookingResponse>(this.middlewareUrl + 'hotels/book', hotelRegistryBookingRequest);
  }

  sendPaymentConfirmation(purchase: string) {
    return this.httpClient.post<any>(this.middlewareUrl + 'booking/payment/' + purchase, {});
  }

  sendRequestAvailabilityCar() {
    this.dataStore.carDataSearch.language = this.languageService.systemLanguajeChange.value.toUpperCase();
    return this.httpClient.post<RentacarAvailabilityResponse>(
      this.middlewareUrl + 'rentacar/getAvailability',
      this.dataStore.carDataSearch);
  }

  // TODO: response de middle
  sendCarRequestBooking(carRegistryBookingRequest: CarBookingRequest) {
    return this.httpClient.post<CarBookingResponse>(this.middlewareUrl + 'rentacar/makeBooking', carRegistryBookingRequest);
  }

  sendCarBookingRulesRequest(carBookingRulesRequest: CarBookingRulesRequest) {
    return this.httpClient.post<CarBookingRulesResponse>(this.middlewareUrl + 'rentacar/bookingRules', carBookingRulesRequest);
  }

  // TODO: consultar el Endpoint final
  // sendHotelAvailability(hotelRegistryBookingRequest: HotelAvailabilityRequest) {
  sendHotelAvailability() {
    return this.httpClient.post<HotelAvailabilityResponse>(this.middlewareUrl + 'hotels/availability', {});
  }

  getCities(term: any) {
    return this.httpClient.get<any[]>(
      this.middlewareUrl +
      'autocomplete/zone?zone=' + term + '&lang=' + this.systemLanguage.toUpperCase(), {});
  }

  getCitiesConsolid(term: any): Observable<any> {

    const header = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    const uri = this.storeServiceLibrary.getJustOneUniversalPropertie(keysUniversal.getLocations_uri);
    const request: any = {};
    request.LocationSearch = term;
    return this.httpClient.post(uri, request, { headers: header });
  }

  /* GUARDAR DATA DE SESION */
  async saveToken(token: string) {
    this.token = token;
    sessionStorage['novae_token'] = token;
  }

  async saveFlightRequest(req: FlightAvailabilityRequest) {
    req.channelId = this.dataStore.channelID;
    this.flightAvailabilityRequest = req;
    sessionStorage['flight_availability_request'] = JSON.stringify(req);
  }

  async saveSelectedJourney(journey: Journey) {
    this.selectedJourney.journey = journey;
    sessionStorage['selected_jounrney'] = JSON.stringify(this.selectedJourney);
  }

  saveSelectedHotel(hotel: HotelAvailabilityOptions) {
    // TODO: NO GUARDAR LOS OPTIONS EN SESSIONSTORAGE
    this.dataStore.selectedHotel = hotel;
    sessionStorage['selectedHotel'] = JSON.stringify(this.dataStore.selectedHotel);
  }

  saveSelectedBenefit(benefit: IBenefitResponse) {
    this.dataStore.selectedBenefit = benefit;
    sessionStorage['selectedBenefit'] = JSON.stringify(this.dataStore.selectedBenefit);
  }

  async saveSmartProfile(_smartProfile: AuthGetAccount2Response) {
    let userData: UserData = this.storeServiceLibrary.getUserData();
    if (_smartProfile) {
      if (userData && Object.keys(_smartProfile).length > 0) {

        if (userData.accountResultDto) {
          userData.accountResultDto = _smartProfile.accountResultDto;
          if (userData.accountResultDto.accountSumaryDto) {
            userData.accountSumaryDto = _smartProfile.accountResultDto.accountSumaryDto;
          } else {
            userData.accountSumaryDto = new AccountSumaryDto();
            userData.accountSumaryDto = _smartProfile.accountResultDto.accountSumaryDto;
          }
        } else {
          userData.accountResultDto = new AccountResultDto();
          userData.accountResultDto = _smartProfile.accountResultDto;
          userData.accountSumaryDto = _smartProfile.accountResultDto.accountSumaryDto;
        }

      }
    } else {
      _smartProfile = new AuthGetAccount2Response();
    }
    this.smartProfile = _smartProfile;
    sessionStorage['smartProfile'] = JSON.stringify(this.smartProfile);
    this.storeServiceLibrary.setUserData(userData);
  }

  async saveSmartProfilePreferences(_smartProfilePreferences: ISmartProfilePreferences) {
    this.dataStore.smartProfilePreferences = _smartProfilePreferences;
    sessionStorage['saveSmartProfilePreferences'] = JSON.stringify(this.dataStore.smartProfilePreferences);
  }

  saveJourneyParamsInfo(journeyParamsInfo: IJourneyInfo) {
    this.dataStore.journeyParamsInfo = journeyParamsInfo;
    sessionStorage['journeyParamsInfo'] = JSON.stringify(this.dataStore.journeyParamsInfo);
  }

  saveMobile(_mobile: boolean) {
    this.dataStore.mobile = _mobile;
    sessionStorage['mobile'] = JSON.stringify(this.dataStore.mobile);
  }

  saveHotelParamsInfo(hotelInfoParams: IJourneyInfo) {
    this.dataStore.hotelInfoParams = hotelInfoParams;
    sessionStorage['hotelInfoParams'] = JSON.stringify(this.dataStore.hotelInfoParams);
  }

  saveCarParamsInfo(carInfoParams: IJourneyInfo) {
    this.dataStore.carInfoParams = carInfoParams;
    sessionStorage['carInfoParams'] = JSON.stringify(this.dataStore.carInfoParams);
  }

  saveRequestHotel(req: HotelAvailabilityRequest) {
    this.dataStore.hotelDataSearch = req;
    sessionStorage['hotelDataSearch'] = JSON.stringify(req);
  }

  saveRequestCars(req: CarAvailabilityRequest) {
    this.dataStore.carDataSearch = req;
    sessionStorage['carDataSearch'] = JSON.stringify(req);
  }

  saveJourneySearch(req: JourneySearch) {
    this.dataStore.journeySearch = req;
    sessionStorage['journeySearch'] = JSON.stringify(req);
  }

  saveSearchBenefits(searchBenefits) {
    this.dataStore.searchBenefits = searchBenefits;
    sessionStorage['searchBenefits'] = JSON.stringify(searchBenefits);
  }

  saveBenefitRequest(benefitsRequest: BenefitsRequest) {
    this.dataStore.benefitsRequest = benefitsRequest;
    sessionStorage['benefitsRequest'] = JSON.stringify(benefitsRequest);
  }

  sendRequestBenefitList() {
    // // Mock: responde un listado de beneficios
    // return new Observable<any>((subscriber) => {
    //   subscriber.next(BenefitsRequestMock);
    //   subscriber.complete();
    // });

    return new Observable<any>(subscriber => {

      // paso 1 - obtener token (oauth) de seguridad
      const formData = new FormData();
      formData.append('grant_type', 'client_credentials');
      let headers = new HttpHeaders();
      headers = headers.append(
        'Authorization',
        'Basic ' + btoa('lifemiles:238651ce-7962-c9bf-67a4-1ec0573d1058')
      );
      this.httpClient.post(this.middlewareBenefitsURL + 'oauth/token',
        formData,
        {
          headers: headers,
          params: {
            grant_type: 'client_credentials'
          }
        }
      ).subscribe((response: any) => { // una vez que tenemos el token de seguridad

        // guardar token en datastore
        this.dataStore.oauthTokenBenefits = response.access_token;

        // paso 2 - consultar listado de beneficios
        const headersConToken = new HttpHeaders().append('Authorization', 'Bearer ' + response.access_token);

        // token harcodeado
        // const headersConToken = new HttpHeaders().append('Authorization', 'Bearer ' + 'eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJzY29wZSI6WyJyZWFkIiwid3JpdGUiXSwiZXhwIjoxNTg1NTk0MjA3LCJhdXRob3JpdGllcyI6WyJST0xFX0NMSUVOVCJdLCJqdGkiOiJhNTM1ZWJkZC01Mjc4LTRlYmEtYTNmZC03NjVjZWM1Mzg3MTYiLCJjbGllbnRfaWQiOiJsaWZlbWlsZXMifQ.GR0Q2UoO6qZLCWLaeG_Yyi1KUQ_9XaWLkkULPrOC6zM9ZnsT7Z5-cKQSfSKaZqd83-Az-u3SSW78fGqWdlUVR3BTv9lfCYbfhxjHdCmhg0mtyhvHjUuMm-niU4iPsCTugfCuwkKknkXzptgdtd0Ak9ojblU0ub1Q1_vl0wxwq0SxtH4WiGjRsnIHVA-ab30h8QDCKEwaGdBr40TeAieLMXIaKHmGbERCCzSwP8SpqVpmAMtEXM_em2jjwQ-9-Ol9DEhXmyu1xaj1RGPPsAuqYU67N7vxdLwiWzV-9m0y6eU0fl1--qC9SgJ1FDyhv5VHHfeyjuyTrefAPAecdu6I9A');

        this.httpClient.post<any>(this.middlewareBenefitsURL + 'api/benefits',
          this.dataStore.benefitsRequest,
          {
            headers: headersConToken
          }
        ).subscribe(benefits => {
          // responder listado de beneficios
          subscriber.next(benefits);
          subscriber.complete();
        });

      });

    });
  }

  sendRequestProductByUser() {
    const httpHeaders = new HttpHeaders().append('Authorization', this.token);
    return this.httpClient.get<IBenefitResponse[]>(this.middlewareBenefitsURL + 'benefits/product-by-user?lang=' + this.languageService.systemLanguajeChange.value.toUpperCase(), { headers: httpHeaders });
  }

  sendBenefitConfirmSalesRequest(benefitsBookingRequest: BenefitsBookingRequest) {
    return this.httpClient.post<[]>(this.middlewareBenefitsURL + 'benefits/confirm-sale', benefitsBookingRequest);
  }

  async saveBrand(req: any) {
    this.dataStore.brand = req;
    sessionStorage.setItem('brand', req);
    sessionStorage['brand'] = req;
    this.actionEventService.sendBrandDynamic(req);
  }

  async saveBrandTargetCurrency(req) {
    this.dataStore.brandTargetCurrency = req;
    sessionStorage['brandTargetCurrency'] = req;
  }

  async saveLatestPrice(price) {
    this.dataStore.latestPrice = price;
    sessionStorage['latestPrice'] = price;
  }

  async saveJourneyParamsInfoFromFlightRequest(req: FlightAvailabilityRequest) {
    if (this.dataStore.journeyParamsInfo) {
      this.dataStore.journeyParamsInfo.originDestinyIata = req.journey.map(journey => {
        return journey.departureAirportCity + '-' + journey.arrivalAirportCity;
      }).join(' / ');
      if (req.journey.length > 0) {
        this.dataStore.journeyParamsInfo.departureArrivalDates = formatDate(req.journey[req.journey.length - 1].flightDate,
          'EEE dd/MM', locale('es'))
          .toLocaleString();
      }
      let cont = 0;
      req.passengers.forEach(item => {
        cont += item.count;
      });
      this.dataStore.journeyParamsInfo.qPasangers = cont + ' pasajeros';
      sessionStorage['journeyParamsInfo'] = JSON.stringify(this.dataStore.journeyParamsInfo);
    }
  }

  saveCheckAvailabilityHotel(obj: HotelBookingRulesResponse) {
    this.dataStore.checkAvailabilityHotel = obj;
    sessionStorage['checkAvailabilityHotel'] = JSON.stringify(obj);
  }

  deleteToken() {
    delete sessionStorage['novae_token'];
    this.token = undefined;
  }

  checkTokenExist() {
    if (this.token === undefined) {
      alert('Su sesion ha expirado, por favor ingrese nuevamente');
      this.router.navigate(['landing']);
    }
  }

  async updateMainFormTexts() {
    const mainFormDinamicTexts = new MainFormDinamicTexts();
    if (this.rutaActual && this.rutaActual.includes('flights')) {
      mainFormDinamicTexts.dinamicRoute = 'flights';
      mainFormDinamicTexts.dinamicIcon = 'icon-stepper-plane-outline';
      mainFormDinamicTexts.dinamicText = this.translations['MAIN_FORM.flight'];
      mainFormDinamicTexts.searchDetailsTexts = this.dataStore.journeyParamsInfo;
    } else if (this.rutaActual && this.rutaActual.includes('hotels')) {
      mainFormDinamicTexts.dinamicRoute = 'hotels';
      mainFormDinamicTexts.dinamicIcon = 'icon-building';
      mainFormDinamicTexts.dinamicText = this.translations['MAIN_FORM.hotel'];
      mainFormDinamicTexts.searchDetailsTexts = this.dataStore.hotelInfoParams;
    } else if (this.rutaActual && this.rutaActual.includes('car')) {
      // parámetros de búsqueda traducidos al app-main-form
      mainFormDinamicTexts.dinamicRoute = 'car';
      mainFormDinamicTexts.dinamicIcon = 'icon-drive';
      mainFormDinamicTexts.dinamicText = this.translations['MAIN_FORM.car'];
      mainFormDinamicTexts.searchDetailsTexts = this.dataStore.carInfoParams;
    } else if (this.rutaActual && this.rutaActual.includes('flights-mobile')) {
      mainFormDinamicTexts.dinamicRoute = 'flights';
      mainFormDinamicTexts.dinamicIcon = 'icon-stepper-plane-outline';
      mainFormDinamicTexts.dinamicText = this.translations['MAIN_FORM.flight'];
      mainFormDinamicTexts.searchDetailsTexts = this.dataStore.journeyParamsInfo;
    } else if (this.rutaActual && this.rutaActual.includes('benefits')) {
      mainFormDinamicTexts.dinamicRoute = 'benefits';
      mainFormDinamicTexts.dinamicIcon = 'icon-check';
      mainFormDinamicTexts.dinamicText = this.translations['MAIN_FORM.benefit'];
      mainFormDinamicTexts.searchDetailsTexts = this.dataStore.journeyParamsInfo;
    } else if (this.rutaActual && this.rutaActual.includes('activities')) {
      mainFormDinamicTexts.dinamicText = this.translations['MAIN_FORM.activity'];
      mainFormDinamicTexts.dinamicRoute = 'activities';
      mainFormDinamicTexts.dinamicIcon = 'icon-building';
      mainFormDinamicTexts.searchDetailsTexts = this.dataStore.journeyParamsInfo;
    }

    this.dataStore.mainFormDinamicTexts = mainFormDinamicTexts;
    sessionStorage['mainFormDinamicTexts'] = JSON.stringify(mainFormDinamicTexts);
  }

  saveSelectedRentaCar(rentaCar: RentacarAvailabilityOption) {
    this.dataStore.selectedRentaCar = rentaCar;
    sessionStorage['selectedRentaCar'] = JSON.stringify(this.dataStore.selectedRentaCar);
  }

  /* POSTMESSAGES */
  sendPostMessage(message: any, targetOrigin: string = '*', iframe: any = null) {
    let iWindow = window.parent;

    if (iframe !== null) {
      iWindow = (<HTMLIFrameElement>iframe).contentWindow;
      iWindow.postMessage(message, targetOrigin);
    } else {
      if (typeof androidProxy !== 'undefined') {
        androidProxy.postMessage(message);
      } else if (window.webkit !== undefined && window.webkit !== 'undefined' && window.webkit.messageHandlers.native !== undefined && window.webkit.messageHandlers.native !== 'undefined') { // Si es iOS
        window.webkit.messageHandlers.native.postMessage(message);
      } else {
        iWindow.postMessage(message, targetOrigin);
      }
    }
  }

  getRegistrationType(): number {
    // return environment.registrationType[this.dataStore.brand];
    return environment.brands[this.dataStore.brand].registrationType;
  }

  setIframeUrl() {
    if (this.dataStore.brand !== undefined) {
      this.urlOnePocket = this.storeServiceLibrary.getJustOneUniversalPropertie(keysUniversal.onePocket_url);
      // this.urlOnePocket = 'http://localhost:8100/';
      // this.urlOnePocket = 'https://devifupperclub.allegra.tech/';
      this.dataStore.channelID = environment.brands[this.dataStore.brand].channelId;
      this.dataStore.iframeUrl = `${this.urlOnePocket}` + '#/onepocket?' +
        'channelID=' + this.dataStore.channelID +
        '&applicationID=' + environment.brands[this.dataStore.brand].applicationId +
        '&language=' + this.systemLanguage;

        
      this.dataStore.iframeSliderUrl = `${this.urlOnePocket}` + '#/onepocket';
      if (this.dataStore.brand == 'cryptmi') {
        const urlOnePocketBenefits = this.storeServiceLibrary.getJustOneUniversalPropertie(keysUniversal.onePocketBenefits_url);
        this.dataStore.iframeSliderUrl = urlOnePocketBenefits;
      }
      this.dataStore.iframeSliderUrl += '?channelID=' + this.dataStore.channelID +
        '&applicationID=' + environment.brands[this.dataStore.brand].applicationId +
        '&language=' + this.systemLanguage + '&preferencePayment=true&chargeUniversal=false';

      this.dataStore.iframeAlleLoginUrl = `${this.urlOnePocket}` + '#/sign-up?' +
        'channelID=' + this.dataStore.channelID +
        '&applicationID=' + environment.brands[this.dataStore.brand].applicationId +
        '&language=' + this.systemLanguage;

      this.dataStore.iframeAlleProfileUrl = `${this.urlOnePocket}` + '#/my-account?' +
        'channelID=' + this.dataStore.channelID +
        '&applicationID=' + environment.brands[this.dataStore.brand].applicationId +
        '&language=' + this.systemLanguage;

      this.dataStore.countryOfResidence = (this.dataStore.brand === 'credomatic') ? 'CR'
        : (this.dataStore.brand === 'abanca') ? 'ES' : 'US';
    }
  }

  /* FUNCIONES GLOBALES HOTELES */
  getHotelGuests() {
    let totalPaxes = 0;
    this.dataStore.hotelDataSearch.roomDistribution.forEach(room => {
      room.paxes.forEach(pax => {
        totalPaxes++;
      });
    });
    return totalPaxes;
  }

  getHotelRooms() {
    return this.dataStore.hotelDataSearch.roomDistribution.length;
  }

  hasBreakfast(hotel: HotelAvailabilityOptions) { }

  getDaysDurationDiff(departureDate, arrivalDate) {
    const startDate = moment(departureDate, 'YYYY-MM-DD');
    const endDate = moment(arrivalDate, 'YYYY-MM-DD');
    const duration = moment.duration(endDate.diff(startDate));
    return duration.asDays();
  }

  openTermsAndConditionsDialog(): void {
    // en mobile el dialog no debe tener padding en el alto
    const heightPadding = this.dataStore.isMobileDevice ? '0px' : '40px';
    const widthPadding = this.dataStore.isMobileDevice ? '0px' : '16vw';
    const lang = this.storeServiceLibrary.getItemFromStorage('lang') || 'en';
    // const urlTerms = environment.brands[this.dataStore.brand].terminos_y_condiciones[lang.toUpperCase()];
    let urlTerms = this.storeServiceLibrary.getJustOneUniversalPropertie(keysUniversal.web_url_terms_and_conditions_en);

    if (lang) {
      if (lang == 'es') {
        urlTerms = this.storeServiceLibrary.getJustOneUniversalPropertie(keysUniversal.web_url_terms_and_conditions_es);
      }
    }
    // this.trackingManager.trackEventString(data.REGISTER.openTerms, data.REGISTER.registerPopUp, '', urlTerm);
    GoogleAnalytics.sendEventFirebase('ga_event', data.REGISTER.registerPopUp, data.REGISTER.openTerms, urlTerms);

    if (!urlTerms) {
      urlTerms = '';
    }
    this.dialog.open(TermsAndConditionsComponent, {
      maxWidth: '74vw',
      panelClass: 'bl-terminoscondiciones',
      width: 'calc(100vw - ' + widthPadding + ')',
      height: 'calc(100vh - ' + heightPadding + ')',

      data: {
        iframeURL: urlTerms
      }
    });
  }

  getPriceConversion(arrayData: any[], conversionRate, percentage, puntosUsuarios) {
    arrayData.forEach((item, i) => {
      const precioProducto = +item.price.totalPrice;

      const precioMillasProducto = (precioProducto * percentage) / 100;
      const millasAdicionalNecesarias = precioMillasProducto - +puntosUsuarios;

      let puntosPagar = 0;
      let millasSobrantes = 0;
      if (millasAdicionalNecesarias >= 0) {
        puntosPagar = (millasAdicionalNecesarias === 0) ? (precioMillasProducto * percentage) / 100 : (puntosUsuarios * percentage) / 100;
      } else {
        millasSobrantes = puntosUsuarios - precioMillasProducto;
        puntosPagar = (precioMillasProducto * percentage) / 100;
        puntosPagar = Math.ceil(precioMillasProducto / conversionRate);
      }

      let dineroPagar = Number((precioProducto - (puntosPagar * conversionRate)).toFixed(2));
      if (dineroPagar < 0) {
        dineroPagar = 0;
      }

      // TODO: LOS MODELOS DE PRECIOS ESTAN DIFERENTES
      // POR ESO SE SETEAN VARIABLES DIFERENTES EN ESTA FUNCION
      // CORREGIR EN REESTRUCTURACION D: !
      item.price.pointsValue = puntosPagar;
      item.price.restMoneyValue = dineroPagar;
      item.price.restPointValue = millasSobrantes;

      item.price.pointsPrice = puntosPagar;
      item.price.calculatedPrice = dineroPagar;
      item.price.restPointValue = millasSobrantes;
    });
    return arrayData;
  }

  getUUID(): string {
    return uuid.v4();
  }

  getOnePocketAuthorizationCode(OPData) {
    try {
      if (OPData.uniqueTransactionResponse.transactionResult.suscriptionPaymentResponse) {
        return OPData.uniqueTransactionResponse.transactionResult.suscriptionPaymentResponse.transaction.authorizationCode;
      } else if (OPData.uniqueTransactionResponse.transactionResult.pointsPaymentResponse) {
        return OPData.uniqueTransactionResponse.transactionResult.pointsPaymentResponse.transaction.authorizationCode;
      }
    } catch (e) {
      return 'NotFound';
    }
  }

  getOnePocketCurrency(OPData) {
    try {
      if (OPData.uniqueTransactionResponse.transactionResult.suscriptionPaymentResponse) {
        return OPData.uniqueTransactionResponse.transactionResult.suscriptionPaymentResponse.purchase.currencyIso;
      } else if (OPData.uniqueTransactionResponse.transactionResult.pointsPaymentResponse) {
        return OPData.uniqueTransactionResponse.transactionResult.pointsPaymentResponse.purchase.currencyIso;
      }
    } catch (e) {
      return 'N/A';
    }
  }

  getOnePocketPointsPayment(OPData) {
    try {
      return OPData.uniqueTransactionResponse.transactionResult.pointsPaymentResponse.points.redeemedPoints;
    } catch (e) {
      return 0;
    }
  }

  getOnePocketTransactionId(OPData) {
    try {
      return OPData.uniqueTransactionResponse.transactionResult.pointsPaymentResponse.transaction.transactionId;
    } catch (e) {
      return 'NotFound';
    }
  }

  getOnePocketTransactionDate(OPData) {
    try {
      return OPData.uniqueTransactionResponse.transactionResult.pointsPaymentResponse.transaction.transactionDate;
    } catch (e) {
      return 'NotFound';
    }
  }

  getOnePocketMoneyAmount(OPData) {
    try {
      return OPData.uniqueTransactionResponse.transactionResult.suscriptionPaymentResponse.purchase.value;
    } catch (e) {
      return 0;
    }
  }

  getOnePocketCardType(OPData) {
    try {
      return OPData.uniqueTransactionResponse.transactionResult.suscriptionPaymentResponse.creditCard.franchise.name;
    } catch (e) {
      return '';
    }
  }

  getOnePocketCardBin(OPData) {
    try {
      return OPData.uniqueTransactionResponse.transactionResult.suscriptionPaymentResponse.creditCard.binNumber;
    } catch (e) {
      return 'N/A';
    }
  }

  getOnePocketCardNumber(OPData) {
    try {
      return OPData.uniqueTransactionResponse.transactionResult.suscriptionPaymentResponse.creditCard.productNumber;
    } catch (e) {
      return 'N/A';
    }
  }

  async saveCreditCardDefult(req) {
    this.dataStore.creditCardDefult = req;
    sessionStorage['creditCardDefult'] = req;
  }

  getJwtUse() {
    const useJwtAuth = environment.brands[this.dataStore.brand].useJwtToken || false;
    return useJwtAuth;
  }

  definedLangLoader() {
    let loaderSlider: string = '';
    if (this.translateService.currentLang.valueOf() === 'en') {
      if (this.dataStore.brand === 'scotia') {
        if (this.dataStore.creditCardDefult === 'amex') {
          loaderSlider = '../../../assets/images/scotia/gif-loader-scotia-amex.gif';
        } else {
          loaderSlider = '../../../assets/images/scotia/gif-loader-scotia-non-amex.gif';
        }
      } else if (this.dataStore.brand === 'myrewards') {
        loaderSlider = '../../../assets/images/myrewards-miles/loaders/loader-slide-en.gif';
      } else {
        loaderSlider = '../../../assets/loader/loader-slide-en.gif';
      }
    } else {
      if (this.dataStore.brand === 'myrewards') {
        loaderSlider = '../../../assets/images/myrewards-miles/loaders/loader-slide-es.gif';
      } else {
        loaderSlider = '../../../assets/loader/loader-slide-es.gif';
      }
    }
    return loaderSlider;
  }

  openModalQuestion() {

    return new Observable((observer) => {
      const heightPadding = this.dataStore.isMobileDevice ? '0px' : '40px';
      const widthPadding = this.dataStore.isMobileDevice ? '0px' : '16vw';
      const dialogRef = this.dialog.open(ModalQuestionComponent, {
        maxWidth: '50vw',
        width: 'calc(50vw - ' + widthPadding + ')',
        height: 'calc(50vh - ' + heightPadding + ')',
        minHeight: '59vh',
        panelClass: 'modal-questions',
        data: {
          tittle: 'Seguro que quieres salir?',
          error: `texto more text`
        }
      });

      dialogRef.afterClosed().subscribe((load) => {
        if (load) {
          observer.next(true);
        } else {
          observer.next(false);
        }
      });
    });
  }

  vaidateOnePocketStay() {
    const urlActive = this.router.url
    return new Observable((observer) => {
      if (urlActive === '/onepocket-pay') {
        GoogleAnalytics.sendEventFirebase('ga_event', 'ONEPOCKETPAY', 'OPEN', 'OPEN_MODAL_QUESTION_ONEPOCKET');
        observer.next(1);
      } else {
        observer.next(2);
      }
    });
  }

  vaidateOnePocketStayLanguaje() {
    const urlActive = this.router.url
    return new Observable((observer) => {
      if (this.dataStore.brand == 'scotia') {
        if (urlActive === '/onepocket-pay') {
          observer.next(1);
        } else {
          observer.next(2);
        }
      } else {
        observer.next(2);
      }
    });
  }

  getDataCard() {
    return this.dataCard;
  }

  setDataCard(dataCard) {
    this.dataCard = dataCard;
  }
  getInfoCard() {
    if (this.dataCard && this.dataCard.creditCard) {
      this._onePocketService.getInfoCard(this.dataCard.creditCard.creditCardID, this.getJwtUse()).subscribe(
        (resp: GetInfoCardResponse) => {
          this.dataCard.quantityPoints = resp.quantityPoints;
          this.dataCard.token = resp.token;
          this.scotiaServiceImplementation.setCardScotiaPointsSelected(this.dataCard, this.getJwtUse()).subscribe((resp) => {
            this.actionEventService.infoUserCard(this.dataCard);
            this.actionEventService.notifySaveInStorage(true);
            // this.loadingScreenService.stopLoading();
          }, (err) => {
            UtilsResource.NSMarkLogError('GET INFO CARD ERROR', err);
          });
        });
    }
  }

  openUrlExternal(url, target, features = '') {
    try {
      if (typeof window != 'undefined') {
        window.open(url, target, features);
      } else if (typeof document != 'undefined') {
        document.open(url, '', 'noopener=true');
      } else {
        global.open(url, target, features);
      }
    } catch (error) {
      window.location = url;
    }

  }


  verifyNeedToken() {
    if (this.token == null || typeof this.token == 'undefined' || this.token == '' || this.token.trim() == '') {
      const dialogRef = this.dialog.open(LoginComponent, {
        panelClass: 'login-dialog',
        disableClose: true,
        data: {
          isInOnepocket: true
        }
      });

      dialogRef.afterClosed().subscribe(succesful => {
        if (succesful) {
        } else {
        }
      });
    }
  }

  /**
   * Metodo que retorna el channelId, el valor se toma del usuario del getAccount si no
   * existe valor, se debe poner el channelId que esta guardado en la Base de datos
   */
  getChannel(): string {
    let channelIdFinal = "";
    const userData: UserData = this.storeServiceLibrary.getUserData();
    if (
      userData &&
      userData.accountResultDto &&
      userData.accountResultDto.channelId
    ) {
      channelIdFinal = String(userData.accountResultDto.channelId);
    } else {
      channelIdFinal = this.storeServiceLibrary.getJustOneUniversalPropertie(
        keysUniversal.app_channel_id
      );
    }
    return channelIdFinal;
  }

  /*
  Se cambia por el metodo getConfigPropertiesTravel()
  */
  /*
   isShowBenefitsComponent() {
     let isShowBenefitsComponent = false;
     // let isShowBenefitsComponent = true;
     let strIsShowBenefitsComponent = this.storeServiceLibrary.getJustOneUniversalPropertie(
       keysUniversal.app_web_show_benefits_component
     );
     if (strIsShowBenefitsComponent) {
       isShowBenefitsComponent = this.stringToBoolean(strIsShowBenefitsComponent);
     }
     return isShowBenefitsComponent;
   }
   //*/

  getConfigPropertiesTravel() {
    let configOptionsTravel = JSON.parse(this.storeServiceLibrary.getJustOneUniversalPropertie('_CONFIG_OPTIONS_TRAVEL') || 'false');
    UtilsResource.NSMarkLog('CONFIGURATIONS TO SHOW: ', configOptionsTravel);
    if (configOptionsTravel) {
      return configOptionsTravel;
    } else {
      return false;
    }
  }

  stringToBoolean(value: string) {
    switch (value) {
      case 'true':
      case '1':
      case 'on':
      case 'yes':
        return true;
      default:
        return false;
    }
  }

  validateLocalDate(localDate, dateService) {
    if (localDate == null || DataService == null) {
      return false;
    }
    let languajeTimeDefault = atob(environment.brands[this.dataStore.brand].languajeTimeDefault || '');
    const day = new Date().toLocaleString(languajeTimeDefault, {
      timeZone: localDate
    });

    const dayTemp = new Date(day);
    let date = moment(dayTemp).format('DD/MM/YYYY').toString();

    if (date == dateService) {
      this.generalUtil.setLocalDate(dayTemp);
      return true;
    } else {
      return false;
    }
  }
}
