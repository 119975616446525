<div id="contenido-landing" class="contenido-landing" [ngClass]="{'padding-fixed': fixed}">
    <!-- <div class="modal-miles-expire"> BLOQUE MODAL MILES EXPIRE
        <span class="title">Miles to Expire</span>
        <h2><span></span>1,284</h2>
        <p> Will expire on: Apr 30, 2023</p>
    </div> -->
    <section class="intro upperclub">
        <div class="container">
            <div class="bl-intro">
                <div class="bl-in-left">
                    <h1 innerHTML="{{'MY_PROFILE.the_reward'|translate}}">
                        The reward is <strong> discover the world </strong>
                    </h1>
                    <div class="line"></div>
                    <p innerHTML="{{'MY_PROFILE.welcome'|translate:'{appName:\'' + appName + '\'}'}}">
                        Welcome to <strong>{{brand}}</strong>, now you have thousands of options to redeem on flights, hotels, entertainment, car rental and more, around the world.
                    </p>
                    <div class="download">
                        <p innerHTML="{{'MY_PROFILE.download'|translate:'{appName:\'' + appName + '\'}'}}">
                            Download now our <strong> {{brand}}:</strong>
                        </p>
                        <app-download-store [class]="'bl-stores'"></app-download-store>
                    </div>
                </div>

                <div class="bl-in-right">
                    <div class="bl-tab-experiencias">
                        <span innerHTML="{{'MY_PROFILE.live_the_experience'|translate}}">
              Live the experience here!
            </span>
                        <div class="btn"></div>
                    </div>
                    <div class="phone-structure bl-drop">
                        <div class="btn-drop">
                            <span innerHTML="{{'MY_PROFILE.live_the_experience'|translate}}">
                Live the experience here!
              </span>
                        </div>
                        <div class="b-iframe iframe-landing">
                            <div class="loader-chat" *ngIf="!loaded"></div>
                            <iframe allow="clipboard-read; clipboard-write" [style.display]='!loaded ? "none" : "block"' [src]="dataStore.iframeAlleProfileUrl | srcSanitizer" #alleIframe id="alleIframe"></iframe>
                        </div>
                    </div>
                    <div class="bl-tab-beneficios"></div>
                </div>
            </div>
        </div>
    </section>
</div>
<!-- <app-footer-all *ngIf="brandEnvironment != 'ncb' && brandEnvironment != 'cibc'" [brand]="brandEnvironment"></app-footer-all> -->