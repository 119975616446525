import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../auth.service';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss']
})
export class AuthComponent implements OnInit {

  constructor(private activatedRoute: ActivatedRoute,
    private router: Router,
    private auth: AuthService
  ) { }

  ngOnInit() {
    const token = this.activatedRoute.snapshot.paramMap.get('token');
    const validToken = this.auth.hasToken(token);
    if (validToken) {
      this.router.navigate(['/flights/results'], { queryParams: { page: 1 } });
    } else {
      this.router.navigate(['/landing']);
    }
  }

}
