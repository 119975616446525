import { ShowProductDirective } from 'src/app/directives/show-product.directive';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IsInputFocusedDirective } from './is-input-focused.directive';
import { FallBackImgDirective } from './fall-back-img.directive';

const directives = [
  IsInputFocusedDirective,
  FallBackImgDirective,
  IsInputFocusedDirective,
  FallBackImgDirective,
  ShowProductDirective
];

@NgModule({
  declarations: directives,
  exports: directives,
  imports: [
    CommonModule
  ]
})
export class DirectivesModule { }
