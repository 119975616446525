<div class="container benefitsContainer">
  <div class="row" *ngIf="benefitsResponse">
    <div class="col-12 d-flex justify-content-between my-4">
      <div class="title-container">
        <h1 class="title"
          innerHTML="{{'BENEFIT_CARD.my_benefits' | translate}}">
          Mis Beneficios
        </h1>
        <div class="tabs">
          <button [class.fill]="tab === 1" (click)="tab = 1"
            innerHTML="{{'BENEFIT_CARD.see_benefits' | translate}}">
            Ver beneficios
          </button>
          <button [class.fill]="tab === 2" (click)="seeCertificates()"
            innerHTML="{{'BENEFIT_CARD.check_my_benefits' | translate}}">
            Ver mis certificados
          </button>
          <button (click)="dataService.openTermsAndConditionsDialog()"
            innerHTML="{{'BENEFIT_CARD.terms_and_conditions' | translate}}">
            Términos y condiciones
          </button>
        </div>
      </div>
    </div>

    <!-- CONTENEDOR DE BENEFICIO DEL USUARIO -->
    <ng-container *ngIf="tab == 2">
      <div class="row benefit-user">
        <app-active-benefit [user]="userInfo">
        </app-active-benefit>
      </div>
    </ng-container>

    <!-- CONTENEDOR DE LISTADO DE BENEFICIOS -->
    <ng-container *ngIf="tab == 1">
      <div class="col-md-2 col-12">
        <div class="content-btn-filter">
          <button class="btn-travel" (click)="showFilters = !showFilters"
            innerHTML="{{'BENEFIT_CARD.filter_result' | translate}}">
            Filtrar resultado
          </button>
          <button class="btn-travel"
            (click)="showPriceSlider = !showPriceSlider"
            innerHTML="{{'BENEFIT_CARD.adjust_price' | translate}}">
            Ajustar precio
          </button>
        </div>
        <div class="filtros-responsive" [class.show]="showFilters">
          <div class="backdrop" (click)="showFilters = false"></div>
          <div class="body">
            <app-filters [fullData]="benefitsResponse"
              [filtersParamsList]="filtersParamsList"
              (idsArrayToFilter)="filterListener($event)">
            </app-filters>
            <br>
            <div class="btn-aceptar">
              <button class="btn-travel" (click)="showFilters = false"
                innerHTML="{{'BENEFIT_CARD.close' | translate}}">
                Cerrar
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="content-flight col-lg-6 ">
        <mat-radio-group>
          <mat-radio-button appClickCatcher class="benefitContainer"
            *ngFor="let item of benefitsResponse | filter: filteredID; let i = index"
            [ngClass]="{'filterShowClass': filterShow < item.productRate}"
            [checked]="i === 0" [value]="item"
            (change)="onSelect($event.value)">
            <app-benefit-item class="fullWidth d-flex align-items-stretch"
              [item]=item></app-benefit-item>
            <div *ngIf="dataStore.selectedBenefit"
              [ngClass]="(dataStore.selectedBenefit.id === item.id) ? 'corner-selected selected' : 'corner-selected'">
              <div class="input-selector"></div>
            </div>
          </mat-radio-button>
        </mat-radio-group>
      </div>
      <div class="col-lg-4 col-12" id="one-pocket">
        <div class="sticky-top" [class.slider-mobile]="(showPriceSlider)">
          <div class="backdrop" (click)="showPriceSlider = false"></div>
          <div class="body">
            <app-slider (getPorcentaje)="actualizarPorcentaje($event)"
              [moneyCurrency]="dataStore.selectedBenefit.productCurrency"
              [totalCost]="dataStore.selectedBenefit.productRate"
              [token]="dataService.token"
              [enabled]="dataStore.selectedBenefit && (dataService.token !== '' && dataService.token !== undefined)">
            </app-slider>
            <div class="btn-aceptar">
              <button class="btn-travel" (click)="showPriceSlider = false"
                innerHTML="{{ 'RENTACAR_BODY.accept' | translate }}">
                Accept
              </button>
            </div>
          </div>
        </div>
        <div class="mb-lg-2 button-container mt-1 ps-0 btn-continuar">
          <button class="btn-travel" (click)="selectOption()"
            innerHTML="{{ 'RENTACAR_BODY.select' | translate }}">
            Select
          </button>
        </div>
      </div>
    </ng-container>



  </div>
</div>