<ng-container *ngIf="formConfig">
    <h1 class="pasajeros">
        {{ formConfig.title }}
    </h1>

    <div class="row content-registro mb-5">
        <div class="selectores mobile-select">
            <div *ngFor="let hostel of formArray.controls; let i = index;" (click)="selectedFormIndex=i; validateCamps()" class="pasajero hostel-selector-{{i}}" [ngClass]="{'select': selectedFormIndex === i}">
                <span class="icono-pasajero"><span>{{i+1}}</span></span>
            </div>
        </div>

        <form class="formulario-registro" [formGroup]="travellerForm">
            <div formArrayName="formArray">
                <div class="traveller traveller-{{ i }}" [class.d-none]="selectedFormIndex !== i" *ngFor="let row of formArray.controls; let i = index" [formGroupName]="i">

                    <div class="title-passenger-form">
                        <span class="title-passenger" *ngIf="!travellerAge">
              {{translations['passenger']}}:&nbsp;{{row.get('userTypeName').value | translate}}
            </span>
                        <span class="title-passenger" *ngIf="travellerAge">
              {{translations['passenger']}}:&nbsp;{{travellerAge[i]}}&nbsp;{{ travellerAge[i] === 1 ? translations['year_old'] : translations['years_old']}}
            </span>
                    </div>

                    <div class="item-form">
                        <div class="item nombre" [ngClass]="{
                            'valid': isFormControlValid(row.get('firstName')),
                            'invalid': isFormControlInvalid(row.get('firstName')),
                            'focused': firstName.isFocused
                        }">
                            <input formControlName="firstName" appIsInputFocused #firstName="isFocused" [placeholder]="translations['name']" class="form-control">
                            <small class="validation-error" *ngIf="isFormControlInvalid(row.get('firstName'))" innerHTML="{{'TRAVELLER_FORM.name_enter' | translate}}">
                Por favor ingrese nombre
              </small>
                        </div>

                        <div class="item apellido" [ngClass]="{
                            'valid': isFormControlValid(row.get('lastName')),
                            'invalid': isFormControlInvalid(row.get('lastName')),
                            'focused': lastName.isFocused
                        }">
                            <input formControlName="lastName" appIsInputFocused #lastName="isFocused" [placeholder]="translations['last_name']" class="form-control">
                            <small class="validation-error" *ngIf="isFormControlInvalid(row.get('lastName'))" innerHTML="{{'TRAVELLER_FORM.last_name_enter' | translate}}">
                Por favor ingrese apellido
              </small>
                        </div>
                    </div>

                    <div class="item-form">
                        <div class="item genero dropdown" [ngClass]="{
                            'valid': isFormControlValid(row.get('gender')),
                            'invalid': isFormControlInvalid(row.get('gender')),
                            'focused': gender.isFocused
                        }">
                            <select formControlName="gender" appIsInputFocused #gender="isFocused" class="form-control" placeholder="translations['title']">
                <option value="0" innerHTML="{{'TRAVELLER_FORM.title' | translate}}">
                  Título
                </option>
                <option *ngFor="let titleType of titleTypeList" [value]="titleType.id">
                  {{titleType.value}}
                </option>
              </select>
                            <small class="validation-error" *ngIf="isFormControlInvalid(row.get('gender'))" innerHTML="{{'TRAVELLER_FORM.gender_enter' | translate}}">
                Por favor ingrese título
              </small>
                        </div>

                        <div *ngIf="refresherDates" class="item fecha-nacimiento" [ngClass]="{
                            'valid': isFormControlValid(row.get('dateOfBirth')),
                            'invalid': isFormControlInvalid(row.get('dateOfBirth')),
                            'focused': dateOfBirth.isFocused
                        }">
                            <div class="icono-fecha">
                                {{travellerForm.value.dateOfBirth}}
                                <input #dateOfBirth="isFocused" class="dateSelect form-control" style="padding-left: 40px;" type="text" readonly formControlName="dateOfBirth" appIsInputFocused [placeholder]="translations['date_of_birth']" ngxDaterangepickerMd [locale]="locale" [autoApply]="true"
                                    [closeOnAutoApply]="true" [showDropdowns]="true" [singleDatePicker]="true" [minDate]="setMinDate(i)" [maxDate]="setMaxDate(i)" />
                                <small class="validation-error" *ngIf="isFormControlInvalid(row.get('dateOfBirth'))">
                  {{'TRAVELLER_FORM.date_of_birth_enter' | translate}}
                </small>
                            </div>
                        </div>
                    </div>
                    <div class="item-form">
                        <div class="item identificacion dropdown" [ngClass]="{
                            'valid': isFormControlValid(row.get('documentType')),
                            'invalid': isFormControlInvalid(row.get('documentType')),
                            'focused': documentType.isFocused
                        }">
                            <select formControlName="documentType" appIsInputFocused #documentType="isFocused" class="form-control" placeholder="translations['document_type']">
                <option value="0">
                  {{'TRAVELLER_FORM.document_type' | translate}}
                </option>
                <option *ngFor="let docType of documentTypes" [value]="docType.id">
                  {{docType.value}}
                </option>
              </select>


                            <small class="validation-error" *ngIf="isFormControlInvalid(row.get('documentType'))" innerHTML="{{'TRAVELLER_FORM.plase_identification_number' | translate}}">
                Por favor ingrese título
              </small>
                        </div>

                        <div class="item identificacion" [ngClass]="{
                            'valid': isFormControlValid(row.get('documentNumber')),
                            'invalid': isFormControlInvalid(row.get('documentNumber')),
                            'focused': documentNumber.isFocused
                        }">
                            <input formControlName="documentNumber" appIsInputFocused #documentNumber="isFocused" [placeholder]="translations['identification_number']" class="form-control">
                            <small class="validation-error" *ngIf="isFormControlInvalid(row.get('documentNumber'))" innerHTML="{{'TRAVELLER_FORM.plase_identification_number' | translate}}">
                Por favor ingrese documento
              </small>
                        </div>
                    </div>

                    <div class="item-form">
                        <div class="item pais" [ngClass]="{
                            'valid': isFormControlValid(row.get('country')),
                            'invalid': isFormControlInvalid(row.get('country')),
                            'focused': country.isFocused
                        }">
                            <select class="form-control selectpicker" style="position: initial;" formControlName="country" appIsInputFocused #country="isFocused" placeholder="translations['country']" (change)="fillCitiesList(i)">
                <option value="0" innerHTML="{{'TRAVELLER_FORM.nationality' | translate}}">
                  País
                </option>
                <option *ngFor="let country of countryList" value="{{ country.iso }}">
                  {{ country.name }}
                </option>
              </select>
                            <small class="validation-error" *ngIf="isFormControlInvalid(row.get('country'))" innerHTML="{{'TRAVELLER_FORM.nationality_enter' | translate}}">
                Por favor ingrese nacionalidad
              </small>
                        </div>

                        <div class="item ciudad" [ngClass]="{
                            'valid': isFormControlValid(row.get('city')),
                            'invalid': isFormControlInvalid(row.get('city')),
                            'focused': city.isFocused
                        }">
                            <select class="form-control selectpicker" style="position: initial;" formControlName="city" appIsInputFocused #city="isFocused" placeholder="translations['city']">
                <option value="0" innerHTML="{{'TRAVELLER_FORM.city' | translate}}">
                  Ciudad
                </option>
                <option *ngFor="let city of cityList[i]" value="{{city.name}}">
                  {{ city.name }}
                </option>
              </select>
                            <small class="validation-error" *ngIf="isFormControlInvalid(row.get('city'))" innerHTML="{{'TRAVELLER_FORM.city_enter' | translate}}">
                Por favor ingrese ciudad
              </small>
                        </div>
                    </div>
                    <div class="item-form">
                        <div class="item direccion" [ngClass]="{
                            'valid': isFormControlValid(row.get('address')),
                            'invalid': isFormControlInvalid(row.get('address')),
                            'focused': address.isFocused
                        }">
                            <input formControlName="address" appIsInputFocused #address="isFocused" [placeholder]="translations['address']" class="form-control">
                            <small class="validation-error" *ngIf="isFormControlInvalid(row.get('address'))" innerHTML="{{'TRAVELLER_FORM.address_enter' | translate}}">
                Por favor ingrese su direccion
              </small>
                        </div>

                        <div class="item phone" [ngClass]="{
                            'valid': isFormControlValid(row.get('phone')),
                            'invalid': isFormControlInvalid(row.get('phone')),
                            'focused': phone.isFocused
                        }">
                            <mat-select #phoneArea formControlName="phoneArea" appIsInputFocused (openedChange)="openedChange($event)" [ngStyle]="style">
                                <mat-option *ngFor="let code of codeCountry" value="{{ code.areaCode }}">
                                    <span> {{ code.country }} +{{ code.areaCode }}</span>
                                </mat-option>
                            </mat-select>

                            <button class="icon-phone" (click)="setStep(phoneArea)" *ngIf="!isOpen">
                <span>+{{ selected }}</span>
              </button>

                            <input formControlName="phone" appIsInputFocused #phone="isFocused" class="form-control number" [placeholder]="translations['phone_number']">
                            <small class="validation-error" *ngIf="isFormControlInvalid(row.get('phone'))" innerHTML="{{'TRAVELLER_FORM.phone_invalid'|translate}}">
                Telefono invalido
              </small>
                        </div>




                    </div>
                    <div class="item-form">
                        <div class="item correo" [ngClass]="{
                            'valid': isFormControlValid(row.get('email')),
                            'invalid': isFormControlInvalid(row.get('email')),
                            'focused': email.isFocused
                        }">
                            <input formControlName="email" appIsInputFocused #email="isFocused" [placeholder]="translations['enter_email']" class="form-control">
                            <small class="validation-error" *ngIf="isFormControlInvalid(row.get('email'))" innerHTML="{{'TRAVELLER_FORM.email_invalid'|translate}}">
                Por favor ingrese email
              </small>
                        </div>
                        <div class="item"></div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</ng-container>