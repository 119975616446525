<div class="container">
  <div class="b-registro-pasajeros">
    <div class="row">
      <div class="col-xs-12" id="no-worries" [ngClass]="{'col-lg-8': !loadCertificates, 'col-lg-12': loadCertificates}">
        <app-certificates-user *ngIf="brand === 'lifeMilesTravel' && loadCertificates"
          (sendBenefits)="receivedFlag($event)">
        </app-certificates-user>

        <div class="bl-no-worries" *ngIf="showLoadOP && brand !== 'credomatic' && brand !== 'scotia' && brand !== 'apap' && brand !== 'ncb' && brand !== 'myrewards' && brand !== 'cibc'">
          <div class="bl-text">
            <h2 *ngIf="!flagFixedRates" innerHTML="{{'LANDING.'+brand+'.ONEPOCKETPAY.worries' | translate}}">Not enough
              miles?<br> No worries.
            </h2>
            <p *ngIf="!flagFixedRates" innerHTML="{{'LANDING.'+brand+'.ONEPOCKETPAY.description_worrie' | translate}}">
              Even if you don't have
              enough miles, you can always <strong>split your payment.</strong></p>
            <h2 innerHTML="{{'LANDING.'+brand+'.ONEPOCKETPAY.never' | translate}}" *ngIf="!isLifeMilesTravel">It's never
              too late to <br> switch to miles
            </h2>
            <p innerHTML="{{'LANDING.'+brand+'.ONEPOCKETPAY.pay_milles' | translate}}" *ngIf="!isLifeMilesTravel">Want
              to use your miles instead? You can always <strong>switch to miles</strong> later.</p>
            <h2 innerHTML="{{'LANDING.'+brand+'.ONEPOCKETPAY.received_milles' | translate}}"
              *ngIf="!isLifeMilesTravel && brand !== 'scotia'">
              Earn miles when <br> spending and redeeming</h2>
            <p innerHTML="{{'LANDING.'+brand+'.ONEPOCKETPAY.received_three_more' | translate}}"
              *ngIf="!isLifeMilesTravel && brand !== 'scotia'">We offer <strong>up to
                3X rewards</strong> on all spending and redemptions, even when paying with rewards. There’s no limit to
              how much you can earn. There’s no expiration date. No fine print. No gimmicks.
            </p>
          </div>
          <div class="bl-image col-lg-4 col-xs-12 mb-5" *ngIf="showLoadOP"></div>
        </div>

        <app-benefits [ngStyle]="{display: showBenefitsComponent ? 'inline' : 'none'}"></app-benefits>
      </div>
      <div class="col-lg-4 col-xs-12 mb-5" *ngIf="showLoadOP">
        <div class="slider-container">
          <div class="result_detail_taxes gray" [ngClass]="{'active': purchaceId != undefined}">
            <ng-container *ngIf="price">
              <ng-container *ngIf="price?.promotion">
                <div class="discount" *ngIf="price?.promotion?.miles">
                  <div class="code">
                    <p>{{ price.promotion.code }}</p>
                  </div>
                  <div class="value">
                    -
                    <span class="m">{{ price.promotion.miles.discount }}</span>
                  </div>
                </div>

                <div class="discount" *ngIf="price?.promotion?.cash">
                  <div class="code">
                    <p>{{ price.promotion.code }}</p>
                  </div>
                  <div class="value">
                    -
                    <span>{{ price.currency }} {{ data.getCurrencySymbol(price.currency,'narrow')}} {{
                      price.promotion.cash.discount | milesFormat }}</span>
                  </div>
                </div>
              </ng-container>
              <div class="result_text">
                <h4>Total:</h4>
                <h3>
                  <span *ngIf="price.valuePoints && brand !== 'lifeMilesTravel'">
                    <span class="sig-mw"></span>{{price.valuePoints | milesFormat}}
                    <!-- &nbsp;{{'ONEPOCKET.miles'|translate}} -->
                  </span>
                  <span *ngIf="price.valuePoints && brand === 'lifeMilesTravel'">
                    <span class="sig-mw"></span>{{price.valuePoints | milesFormatEn: true }}
                  </span>
                  <span *ngIf="price.valuePoints && price.valueCreditCard">
                    +
                  </span>
                  <span *ngIf="price.valueCreditCard && brand !== 'lifeMilesTravel'">
                    {{ price.currency }}
                    {{ data.getCurrencySymbol(price.currency,'narrow')}}{{price.valueCreditCard | milesFormat}}
                  </span>
                  <span *ngIf="price.valueCreditCard && brand === 'lifeMilesTravel'">
                    {{ currencyUniversal.displayName }}
                    {{ data.getCurrencySymbol(price.currency,'narrow')}}&nbsp;{{price.valueCreditCard | milesFormatEn:true}}
                  </span>
                </h3>
              </div>
              <ng-container *ngIf="price.tax">
                <div class="line"></div>
                <div class="result_text">
                  <p>{{'ONEPOCKET.tax'|translate}}</p>
                  <p>
                    {{currencyUniversal.displayName}}{{
                    data.getCurrencySymbol(price.currency,'narrow')}}&nbsp;{{price.tax | milesFormatEn: false }}
                  </p>
                </div>
              </ng-container>
              <ng-container *ngIf="price.insuranceValue">
                <div class="line" *ngIf="!price.tax"></div>
                <div class="result_text">
                  <p>{{'ONEPOCKET.travel_protecion'|translate}}</p>
                  <p>
                    {{currencyUniversal.displayName}}{{
                    data.getCurrencySymbol(price.currency,'narrow')}}&nbsp;{{price.insuranceValue | milesFormatEn:false}}
                  </p>
                </div>
              </ng-container>
            </ng-container>
          </div>
          <div id="container-before-op"></div>
          <div class="text-center" *ngIf="enabled && iframeURL">

            <div class="loader-chat" [ngClass]="dataStore.brand" *ngIf="!loaded">
              <img [src]="loaderSlider | srcSanitizer">
              <span class="texto-loader" innerHTML="{{ 'SLIDER.payment_experience' | translate }}">
                Creando tu<br> <strong> experiencia de pago...</strong>
              </span>

            </div>
            <iframe [style.display]='!loaded ? "none" : "block"' class="iframeClass" [src]="iframeURL | srcSanitizer"
              frameborder="0" #onePocketIframe id="iframe_onepocket" allow="clipboard-read; clipboard-write"></iframe>
          </div>

          <div class="text-center not-enabled" *ngIf="!enabled">
            <img [src]="loaderSlider | srcSanitizer" loading="lazy" class="margin-top">
          </div>

          <div class="tab" *ngIf="!showBenefits && haveBenfitsModal" (click)="showBenefeitsModal()">Beneficios Viajes
            BAC | upperClub</div>
        </div>
        <div class="benefits-travel" *ngIf="showBenefits">
          <div class="content-benefits">

            <div *ngIf="brand !== 'credomatic'">
              <h2>Beneficios de viajes</h2>

              <div class="bl-items">
                <span class="icon-beneficios"></span>
                <div class="bl-text">
                  <h2>Beneficios BAC Credomatic</h2>
                  <p>Viaja de forma flexible y con total tranquilidad. Disfruta todos los beneficios y deja de
                    preocuparte
                    por los inconvenientes que aparecen en tu viaje. Sin cargos extra por cambios de reserva,
                    cancelaciones, interrupciones
                    o pérdida de conexiones</p>
                </div>
              </div>

              <div class="bl-items">
                <span class="icon-ofertas"></span>
                <div class="bl-text">
                  <h2>Recibe las mejores ofertas para viajes</h2>
                  <p>Reserva con los mejores precios de Expedia en vuelos, hoteles, actividades, renta de autos, y más.
                    Disfruta viajar por el mundo reservando sin restricciones en asientos o fechas.</p>
                </div>
              </div>

              <div class="bl-items">
                <span class="icon-fondos"></span>
                <div class="bl-text">
                  <h2>Fondos al Instante</h2>
                  <p>No tendrás que usar dinero de tu bolsillo en caso de situaciones como vuelo retrasado, interrupción
                    o
                    cancelación de viaje y mucho más.</p>
                </div>
              </div>

              <div class="bl-items">
                <span class="icon-atencion"></span>
                <div class="bl-text">
                  <h2>Hasta USD $ 50,000</h2>
                  <p>En gastos médicos para atención de emergencia u hospitalización, incluido Protección por Covid-19
                  </p>
                </div>
              </div>

              <div class="bl-items">
                <span class="icon-equipaje"></span>
                <div class="bl-text">
                  <h2>Hasta USD $ 600</h2>
                  <p>Para que compres todo lo que necesites en caso de que tu equipaje se extravíe o retrase durante un
                    viaje.
                  </p>
                </div>
              </div>

              <div class="bl-items">
                <span class="icon-articulos"></span>
                <div class="bl-text">
                  <h2>Hasta USD $ 500</h2>
                  <p>Para reponer tus artículos electrónicos personales en caso de perdida o robo mientras viajas.</p>
                </div>
              </div>
            </div>
            <app-benefits-upperclub *ngIf="brand === 'credomatic'"></app-benefits-upperclub>
            <div class="tab-close" *ngIf="showBenefits" (click)="showBenefeitsModal()"></div>
          </div>
        </div>

        <!-- Branded Rules Benefits -->
        <!-- <div class="benefits-travel-ncb-fc hide-benefits" [ngClass]="{'hide-benefits': !voyageBenefits, 'show-benefits': voyageBenefits}">
                    <div class="header" (click)="showVoyageBenefits()">
                        Voyage benefits
                    </div>
                    <div class="content-benefits">
                        <h2>BRANDED RULES BENEFITS</h2>
                        <div class="bl-items">
                            <div class="bl">
                                <span class="icon-fondos"></span>
                                <div class="bl-text">
                                    <h2>No Fees</h2>
                                    <p>for cancellation or interruption of flights or hotels</p>
                                </div>
                            </div>

                            <div class="bl">
                                <span class="icon-equipaje"></span>
                                <div class="bl-text">
                                    <h2>No Fees</h2>
                                    <p>for cancellation or interruption of flights or hotels</p>
                                </div>
                            </div>

                            <div class="bl">
                                <span class="icon-ofertas"></span>
                                <div class="bl-text">
                                    <h2>Up to US$ 100</h2>
                                    <p>in case your international flight connection is delayed for more than six (6) hours</p>
                                </div> 
                            </div>
                        </div>

                        <div class="bl-items">

                            <div class="bl">
                                <span class="icon-atencion"></span>
                                <div class="bl-text">
                                    <h2>Up to US$ 600</h2>
                                    <p>in case you lose your luggage and do not get it back (the airline must confirm the loss)</p>
                                </div>
                            </div>

                            <div class="bl">
                                <span class="icon-articulos"></span>
                                <div class="bl-text">
                                    <h2>Up to US$ 500</h2>
                                    <p>for your personal travel devices</p>
                                </div>
                            </div>

                            <div class="bl">
                                <span class="icon-beneficios"></span>
                                <div class="bl-text">
                                    <h2>The Best-Price Guarantee</h2>
                                    <p>and more.</p>
                                </div>
                            </div>
                        </div>
                        <p>Branded Rules apply only when using miles to book flights and/or hotels.</p>
                    </div>
                </div> -->
      </div>
    </div>
  </div>
</div>
<app-footer-all [brand]="brand"></app-footer-all>