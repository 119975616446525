import { Component, OnInit } from '@angular/core';
import { InitService } from '../../services/init.service';
import { DataStoreService } from '../../services/data-store.service';
import { StoreServiceLibrary } from '@wearenovae/novae-core-services';
import { DataService } from '../../services/data.service';

@Component({
  selector: 'app-landing-help',
  templateUrl: './landing-help.component.html',
  styleUrls: ['./landing-help.component.scss']
})
export class LandingHelpComponent implements OnInit {

  brand: string;
  confLandingHelp: any;

  constructor(private initService: InitService,
    private dataStore: DataStoreService,
    private dataService: DataService,
    private storeService: StoreServiceLibrary) { }

  ngOnInit(): void {
    this.initService.isInitializedBrand.subscribe((init) => {
      if (init) {
        this.brand = this.dataStore.brand;
        let configOptionsTravel = this.dataService.getConfigPropertiesTravel();
        if (configOptionsTravel?.configHelp) {
          this.storeService.setItemInStorage('CONFIG-HELP', configOptionsTravel?.configHelp);
        }
      }
    });
  }

}
