<div class="">
  <!--DESCRIPCION DE CANCELACION  Y AMENITIES DE HOTEL-->
  <ng-template [ngIf]="options.type === 'hotel'">
    <p class="product-name">
      <span>{{ card.getFirstDetail() }}</span>
      <span class="name2"><i class="travel-icon icon-stepper-acommodation"
          aria-hidden="true"></i>{{ card.getSecondDetail() }}</span>
    </p>

    <div class="col-12 services ps-0 mt-1 description"
      *ngIf="card.refundable != undefined">
      <i class="travel-icon icon-information" aria-hidden="true"></i>
      <p class="refundable"
        innerHTML="{{ 'CARD.check_refundable' | translate }}">
        Check cancellatio policy
      </p>
    </div>

    <div class="clearfix">
      <p *ngIf="card.breakfast !== undefined && card.breakfast" class="amenity"
        title="Desayuno incluido">
        <i class="travel-icon icon-breakfast" aria-hidden="true"></i>
      </p>
      <p *ngIf="card.wifi !== undefined && card.wifi" class="amenity"
        title="Wifi">
        <i class="travel-icon icon-wifi" aria-hidden="true"></i>
      </p>
    </div>
  </ng-template>
  <!--DESCRIPCION DE CANCELACION  Y AMENITIES  DE HOTEL-->

  <!--DESCRIPCIONES DE ACTIVIDADES-->
  <ng-template #activities [ngIf]="options.type === 'activity'">
    <p class="product-name">
      <span> {{ card.description  | truncate: 130 }}</span>
    </p>
  </ng-template>
  <!--DESCRIPCIONES DE ACTIVIDADES-->

  <!--DESCRIPCIONES DE HABITACIONES-->
  <ng-template #room [ngIf]="options.type === 'room'">
    <p class="product-name">
      <span>{{ card.getFirstDetail() }}</span>
      <span class="name2 mt-1">{{ card.getSecondDetail() }}</span>
    </p>

    <div class="col-12 services ps-0 mt-1 description"
      *ngIf="card.refundable != undefined">
      <i class="travel-icon icon-alert-rounded" aria-hidden="true"></i>
      <p class="refundable"> {{ card.refundable }}</p>
    </div>
    
    <p class="ps-0 col item-room" *ngIf="card.nights !== undefined">
      <i class="travel-icon icon-calendar" aria-hidden="true"></i>
      {{ card.nights }}
      <p class="ps-0 col item-room" *ngIf="card.travellers !== undefined">
        <i class="travel-icon icon-people" aria-hidden="true"></i>
        {{ card.travellers }}
      </p>
  </ng-template>
  <!--DESCRIPCIONES DE HABITACIONES-->

</div>