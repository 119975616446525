<div *ngIf="languaje === 'en'">
  <li class="pill" [ngClass]="{'active': is_active}" (click)="showContent()">
    <a class="icon-splash_abanca">Website-App Terms of Use</a>
  </li>
  <div class="tab-pane active" *ngIf="show_content || show_mobile" [ngClass]="{'show': show_mobile}" id="item-0">


    <h2 class="titulo-intro" (click)="toggleClass('1')" [ngClass]="{'open': show_description1}">Website-App Terms of use
    </h2>

    <div class="container" [ngClass]="{'show': show_description1}">
      <p>
        The use of the website and APP (the "Portal") is subject to the following Terms and Conditions (the "Terms and
        Conditions applicable to the use of the Portal"). Please read the Terms and Conditions applicable to the use of
        the Portal carefully before using it. These may be modified and/or updated at any time and will be published for
        your knowledge.
      </p>
      <p>
        The Portal allows the knowledge and access to the purchase of the different products,
        services and benefits offered by <strong>Miles / Care by Novae</strong> and/or strategic allies, using as
        a means the different forms of payment made available to the user and especially debit
        and/or credit cards. The transactional process of buying and selling the aforementioned
        products or services will correspond to that offered by each of the suppliers integrated
        into the portal. <strong>Miles / Care by Novae</strong> will be responsible for the products and/or
        services offered and sold on the Portal, however, in some cases, the services or
        benefits are provided by third-party providers, so any complaint and claim should be
        addressed directly to them, where <strong>Miles / Care by Novae</strong> will act as facilitator of said
        services, claims and will do its best to manage and provide a solution. The portal then
        operates as an access channel for the offer of products and services made available to
        users but not as a transactional channel. <strong>Miles / Care by Novae</strong> will be responsible for
        the handling and security of the information provided by the cardholders. This is due to
        products being sold directly to the suppliers, who are responsible for complying with the
        commercial conditions and service levels offered.
      </p>
      <p>
        The Portal User agrees to comply with the Terms and Conditions, applicable to the use
        of the Portal and any modification and/or update that may be made in the future will be
        valid during the period in which the services offered on the Portal are provided. The
        Portal Users are responsible for reading, understanding and complying with the
        information contained in the Terms and Conditions applicable to the use of the Portal,
        who state that they have read it, understood and accepted them.
      </p>

      <p>
        For all intents and purposes, the Portal Administrator and Owner of its contents and/or
        applications is <strong>Miles / Care by Novae</strong>, except for what is related to the brands, reviews
        and commercial names of the suppliers or financial entities that are part of the
        programs. Within their applications, participating companies and/or participating <strong>Miles / Care by
          Novae</strong> suppliers may offer a service and/or product on the Portal. For these
        purposes, any travel user, traveler, passenger, customer or any person or company that
        contracts the services of a supplier and/or participant through the Portal, will be called
        User. The services and/or products offered by the Providers on the Portal under the
        administration and supervision of the Portal Administrator, including, without limitation to
        those stated herein, the tourism content and/or promotions of vacation packages and/or
        flights and/or hotels and/or cars and/or cruise ships and/or tourist services and/or the
        insurances and added values that are published, ARE REGULATED AND WILL BE
        SUBJECT TO THE TERMS, CONDITIONS, RESTRICTIONS AND/OR LIMITATIONS
        SPECIFIC AND APPLICABLE TO EACH SERVICE AND/OR PRODUCT. SUCH
        TERMS, CONDITIONS, RESTRICTIONS AND/OR LIMITATIONS WILL BE
        UNDERSTOOD AND AGREED BETWEEN THE SUPPLIERS AND USERS AND
        NOTHING COMMITTED TO THE PORTAL ADMINISTRATOR. Therefore, the Terms
        and Conditions of use of the Portal are not applicable, nor do they modify, interpret
        and/or restrict in any way the Terms and Conditions applicable to the services and/or
        products offered by the suppliers in the Portal.
      </p>

      <p>
        The Portal Administrator is not responsible for the acts or omissions of the suppliers or
        for any loss, damage or expense that the User incurred as a result of the acts and/or
        omissions of the suppliers. The User accepts the Terms and Conditions applicable to
        the use of the Portal published and updated by the Portal Administrator on the portal
        and declares that he/she has read, understood and accepted it in their entirety. The
        supplier accepts the Terms and Conditions applicable to the use of the Portal published
        and updated by the Portal Administrator in the Portal and declares that he/she has read,
        understood and accepted it in their entirety and that he/she is fully responsible for the
        sale he/she makes of his/her services and products to Users. Likewise, the Portal
        Administrator is not responsible for discrepancies between verbal quotes and written
        quotes that may arise from suppliers. Once the payment has been received, all of the
        information contained in the <strong>Miles / Care by Novae</strong> service itinerary will be considered as
        correct and fully accepted by the User.
      </p>
    </div>


    <h2 (click)="toggleClass('2')" [ngClass]="{'open': show_description2}">POLICY OF USE OF THE SITE, ITS CONTENT AND
      NAVIGATION</h2>

    <div class="container" [ngClass]="{'show': show_description2}">
      <p>
        By using the Portal, Users and/or Suppliers accept the following conditions of use.
        Disclaimer: To the maximum extent permitted by applicable laws, the materials and/or

        applications contained in the Portal are provided as they are exposed and without
        warranties of any kind, either express or implied. The Portal Administrator disclaims all
        warranties, express or implied, including, without limitation, the implied warranties of
        merchantability and/or fitness for a particular purpose, and the Suppliers, subsidiaries
        and/or affiliates are solely responsible for the guarantee of the products or services
        offered and sold on the Portal with the exception of the guarantees or services directly
        provided by <strong>Miles / Care by Novae</strong> where he/she is responsible for their compliance.
        The Portal Administrator will do his/her best so that the components of the Portal,
        system, equipment, and programs, are free of viruses or any unauthorized programs,
        and agrees to timely correct interruptions or errors of the Portal that are attributable to
        him/her. The information and descriptions contained in the Terms and Conditions
        applicable to the use of the Portal are not intended to be the terms, exclusions, and
        conditions applicable to the products and services offered on the Portal, please refer to
        the policy or agreement applicable to each product or service in particular. The Portal
        may be linked to other websites that are not maintained by the Portal Administrator, so it
        will not be responsible for the content, use, product or service sold on such websites.
        The inclusion of a link to the Portal does not imply approval or endorsement by the
        Portal Administrator and/or the Providers of said linked web page and/or its content.
        The Portal Administrator agrees to include accurate and updated information on the
        Portal, it will not be responsible for any errors or omissions that may occur that are
        attributable to third parties.
      </p>
    </div>



    <h2 (click)="toggleClass('3')" [ngClass]="{'open': show_description3}">RESTRICTIONS ON THE USE OF MATERIALS AND/OR
      APPLICATIONS CONTAINED IN THE PORTAL</h2>

    <div class="container" [ngClass]="{'show': show_description3}">
      <p>
        The Portal and the applications contained therein are owned and operated by the Portal
        Administrator and/or by the providers, as applicable. Except as expressly permitted, no
        material and/or application and/or website and/or request for property operated,
        licensed or controlled by the Portal Administrator and/or the providers, as applicable,
        may be copied, reproduced, republished, uploaded, sent, transmitted or distributed in
        any way. The User may download materials and/or applications displayed on the Portal
        for their exclusive use, provided that they maintain all copyright and other proprietary
        notices contained in the materials and/or applications. The User may not distribute,
        modify, transmit, reuse, forward or use the content and/or applications of the Portal for
        public or commercial purposes, including the texts, images, audio and videos contained
        therein.
      </p>
    </div>



    <h2 (click)="toggleClass('4')" [ngClass]="{'open': show_description4}">COMPETITION QUESTIONS</h2>

    <div class="container" [ngClass]="{'show': show_description4}">
      <p>
        Users who access the Portal and/or its applications do so on their own initiative and are
        responsible for compliance with applicable laws. The offers and/or products and/or
        services will be effective in any jurisdiction in which said offer and/or request, purchase
        or sale is legal under the laws of said jurisdiction. Some products and services may not
        be available in all jurisdictions.
      </p>
    </div>



    <h2 (click)="toggleClass('5')" [ngClass]="{'open': show_description5}">PROPERTY OF THE INFORMATION</h2>

    <div class="container" [ngClass]="{'show': show_description5}">
      <p>
        Any information, other than personal data or information that the User transmits to the
        Portal Administrator and/or the Providers through the Portal, by email or other means,
        including data, questions, comments or suggestions, will be treated as confidential and
        will become the property of the Portal Administrator and/or suppliers, as appropriate.
        Such information, other than personal data, may be used for any purpose, including but
        not limited to the reproduction, requests, disclosure, transmission, publication, and
        dissemination. Both the Portal Administrator and the suppliers may use any ideas,
        concepts, know-how or techniques contained in any communication that either party
        sends through the Portal and/or by any other means for any purpose, including but not
        limited to developing and marketing products using such information.
      </p>
    </div>



    <h2 (click)="toggleClass('6')" [ngClass]="{'open': show_description6}">MATERIAL AND IMMATERIAL PROPERTY</h2>

    <div class="container" [ngClass]="{'show': show_description6}">
      <p>
        <strong>Miles / Care by Novae</strong> retains all rights, titles, intellectual property and interests of all
        parts of the products, services, associated software and developments of technological
        platforms, their modifications, extensions or any work resulting therefrom that are part of
        the integrated platform made available to banks and/or allies, as well as the strategies,
        business models and information that as a product of the development of customer
        usage statistics established in the programs maintains and will maintain Miles / Care by
        Novae with Banks and/or allies.
      </p>
    </div>



    <h2 (click)="toggleClass('7')" [ngClass]="{'open': show_description7}">SOFTWARE LICENSES</h2>

    <div class="container" [ngClass]="{'show': show_description7}">
      <p>
        The User acknowledges that any software that may be offered on the Portal and/or in its
        applications may contain technology subject to strict controls in accordance with the
        applicable control regulations and regulations. By accepting the Terms and Conditions
        Applicable to the Use of the Portal, the User undertakes not to transfer or export
        software, in violation of such laws and regulations applicable to the export. The Portal
        Administrator prohibits the download or export of any software or technical data of the
        Portal and/or its applications to any jurisdiction prohibited by export controls, such laws
        and regulations.
      </p>
    </div>



    <h2 (click)="toggleClass('8')" [ngClass]="{'open': show_description8}">TOURIST OPERATOR</h2>

    <div class="container" [ngClass]="{'show': show_description8}">
      <p>
        The tour operator who makes the reservation will inform you of the values to be paid for
        taxes, fees, contributions, charges, penalties, restrictions, limits of liability and other
        information related to the services offered. Subject to the liability regime established by
        Law 300/96 and D.R. 1075/97.
      </p>
    </div>



    <h2 (click)="toggleClass('9')" [ngClass]="{'open': show_description9}">GENERAL</h2>

    <div class="container" [ngClass]="{'show': show_description9}">
      <p>
        The sexual exploitation and abuse of minors is punishable by imprisonment, in
        accordance with the provisions of Law 679 of 2001. All rights reserved. The User is
        obligated to strictly observe the instructions that the Portal Administrator indicates
        regarding the use of the service and security measures, either through written
        documentation or published on any of the Portal pages, or through any other means

        that <strong>Miles / Care by Novae</strong> consider relevant. The User assumes, from now on, the responsibility
        for the operations and financial transactions that will be carried out through the technological or
        transactional means indicated in the Portal.
      </p>

      <p>
        It is agreed that <strong>Miles / Care by Novae</strong> will not be responsible for inquiries,
        transactions, operations and/or instructions made by persons other than the User when
        he/she loses, discloses or compromises the security of the elements required for
        carrying out transactions and/or operations. It is agreed that <strong>Miles / Care by Novae</strong>
        may suspend or cancel totally or partially at any time, without prior notice, the service to
        the User, and consequently, restrict access to the use of the Portal and the conduction
        of the transactions, operations and/or instructions offered therein. The User agrees not
        to use the Portal and the services offered by <strong>Miles / Care by Novae</strong> and/or strategic
        allies, as appropriate for illegal or contrary to morality or generally accepted good
        customs, likewise refrain from using the Portal and the services available through the
        Internet to: a) create or attempt to create a false identity with the purpose of deceiving
        <strong>Miles / Care by Novae</strong> or third parties regarding the identity of the sender or the origin
        of their messages, inquiries or transactions; b) publish, distribute or disseminate any
        inappropriate, defamatory, illicit, obscene, indecent, illegal or infringing information or
        material that violates the rights of third parties; c) transmit or upload files containing
        viruses, "Trojan horses", ";worms", "time bombs", "news cancellation robots" or any
        other harmful programs or data; d) transmit, upload or copy files that contain software or
        other materials protected by legislation related to intellectual property, the right to
        privacy or the image itself, or by any other applicable legislation or right, unless the User
        is the owner or controls the aforementioned rights or has received all necessary
        authorizations for it; e) send or channel the sending of unauthorized, aggressive or
        unsolicited advertising messages, massively ("spamming") or randomly; f) create
        hyperlinks ("links") to or from any of the pages, files, applications or elements of the
        Portal without the express written authorization of the Portal Administrator for such
        purposes; g) interfere with or interrupt the normal operation of the networks connected
        to the service, and violate the rules, directives or procedures of said networks; h)
        attempt to obtain unauthorized access to the services provided by <strong>Miles / Care by Novae</strong> through
        electronic means, as well as any computer system or network
        connected to the services offered by <strong>Miles / Care by Novae</strong>, through automatic
        password search, data overload or by any other means; i) interfere with the operation,
        use or dispose of in any way of the files, applications, programs and/or systems that
        <strong>Miles / Care by Novae</strong> uses or possesses for the provision of services of any kind
        through the Internet, the Portal or any other computer network; j) access, use or dispose
        of without express authorization of the Portal Administrator of the information contained
        in any of its information, communication, processing and/or data storage systems; k)
        interfere with the use or enjoyment of the Portal and/or any other service by other
        clients, persons or entities; l) obtain information from third parties, being understood that
        if the User access it in any way he/she must keep the case reservation and respond for
        the damages caused by the violation of the case; m) perform any other act or omission
        that may directly or indirectly cause damage to <strong>Miles / Care by Novae</strong> or to third
        parties. In case of knowing the occurrence of any of the aforementioned activities by

        any person, the User agrees to give immediate notice to the Portal Administrator and be
        responsible for the damages caused by the delay in said notice.
      </p>

      <p>
        The User expressly authorizes <strong>Miles / Care by Novae</strong> to provide information, when the
        latter considers it necessary, related to the Portal, its management or products, services
        and benefits developed by <strong>Miles / Care by Novae</strong>, through any means either without
        but limited to cellular, text messages, email or other means that the User declares are
        for their exclusive use and that will restrict access by third parties. In any case, the User
        now refuses to submit any claim against <strong>Miles / Care by Novae</strong> for sending the
        information or for the content thereof. The sending of the information is purely
        informative, so the parties agree that <strong>Miles / Care by Novae</strong> will not be responsible for
        inaccuracies in it or for its content, except for information related to the sending of a
        username and password. The User is obligated to keep his/her information updated, as
        well as his/her cell phone number and secure email, responding to the breach of this
        obligation. The User agrees to maintain and apply in his/her transactions and
        businesses the procedures, tools, systems, and effective methods to prevent them from
        being subject to money laundering, in addition to keeping <strong>Miles / Care by Novae</strong>
        harmless from any damage caused by such a situation. The User agrees to notify <strong>Miles / Care by
          Novae</strong> in writing, immediately, of any act or suspicion of corruption, bribery,
        money laundering or terrorist financing that is presented or may be presented in the
        negotiation, celebration or execution of this contract. It is agreed that <strong>Miles / Care by Novae</strong>
        is not responsible for the lack of provision of services in case of force majeure,
        fortuitous event, failures in the electrical fluid or telephone lines, and other events that
        prevent or hinder the provision of the service. The User expressly authorizes <strong>Miles / Care by
          Novae</strong> to record the conversations related to their business, and to keep them,
        listen to them and, if necessary, use them as evidence.
      </p>
    </div>

  </div>
</div>

<!-- ESPAÑOL -->
<!-- ESPAÑOL -->



<div *ngIf="languaje === 'es'">
  <li class="pill" [ngClass]="{'active': is_active}" (click)="showContent()">
    <a class="icon-splash_abanca">Términos de Uso del Sitio Web-App</a>
  </li>

  <a class="icon-splash_abanca pill-mobile" [ngClass]="{'active': show_mobile}" (click)="showMobileContent()">Términos
    de Uso del Sitio Web-App</a>
  <div class="tab-pane active" *ngIf="show_content || show_mobile" [ngClass]="{'show': show_mobile}" id="item-0">



    <h2 class="titulo-intro" (click)="toggleClass('1')" [ngClass]="{'open': show_description1}">Términos de Uso del
      Sitio Web-App</h2>

    <div class="container" [ngClass]="{'show': show_description1}">
      <p>
        El uso del sitio web y de la APP (el "Portal") está sujeto a los siguientes Términos y Condiciones (los
        "Términos y Condiciones aplicables al uso del Portal"). Por favor, lea atentamente los Términos y Condiciones
        aplicables al uso del Portal antes de utilizarlo. Éstos pueden ser modificados y/o actualizados en cualquier
        momento y serán publicados para su conocimiento.
      </p>
      <p>El Portal permite el conocimiento y acceso a la compra de los diferentes productos, servicios y beneficios
        ofrecidos por <strong>Miles / Care by Novae</strong> y/o sus aliados estratégicos, utilizando como medio las
        diferentes formas de pago puestas a disposición del usuario y especialmente las tarjetas de débito y/o crédito.
        El proceso transaccional de compra y venta de los productos o servicios mencionados corresponderá con el
        ofrecido por cada uno de los proveedores integrados en el portal. <strong>Miles / Care by Novae</strong> será
        responsable de los productos y/o servicios ofrecidos y vendidos en el Portal, no obstante, en algunos casos, los
        servicios o prestaciones son proporcionados por terceros, por lo que cualquier queja o reclamación deberá
        dirigirse directamente a ellos, donde <strong>Miles / Care by Novae</strong> actuará como facilitador de dichos
        servicios, reclamaciones y hará todo lo posible por gestionar y dar solución. El portal funciona entonces como
        un canal de acceso para la oferta de productos y servicios puestos a disposición de los usuarios, pero no como
        un canal transaccional. <strong>Miles / Care by Novae</strong> se encargará de la gestión y la seguridad de la
        información proporcionada por los titulares de las tarjetas. Esto se debe a que los productos se venden
        directamente a los proveedores, quienes son responsables de cumplir con las condiciones comerciales y los
        niveles de servicio ofrecidos.</p>
      <p>El Usuario del Portal se compromete a cumplir con los T&eacute;rminos y Condiciones, aplicables al uso del
        Portal y cualquier modificaci&oacute;n y/o actualizaci&oacute;n que sea realizada en el futuro ser&aacute;
        v&aacute;lida durante el per&iacute;odo en que se presten los servicios ofrecidos en el Portal. Los Usuarios del
        Portal son responsables de leer, comprender y cumplir con la informaci&oacute;n contenida en los T&eacute;rminos
        y Condiciones aplicables a la utilizaci&oacute;n del Portal, quienes declaran haberla le&iacute;do, comprendido
        y aceptado.</p>
      <p>Para todos los efectos, el Administrador del Portal y Propietario de sus contenidos y/o aplicaciones es
        <strong>Miles / Care by Novae</strong>, excepto en lo relacionado con las marcas, rese&ntilde;as y nombres
        comerciales de los proveedores o entidades financieras que forman parte de los programas. Dentro de sus
        aplicaciones, las empresas y/o los proveedores de <strong>Miles / Care by Novae</strong> participantes pueden
        ofrecer un servicio y/o producto en el Portal. Para estos efectos, se denominar&aacute; Usuario a todo usuario
        de viajes, viajero, pasajero, cliente o cualquier persona o empresa que contrate los servicios de un proveedor
        y/o participante a trav&eacute;s del Portal. Los servicios y/o productos ofrecidos por los Proveedores en el
        portal bajo la supervisi&oacute;n del Administrador del Portal, incluyendo, sin limitaci&oacute;n a los
        aqu&iacute; expuestos, los contenidos tur&iacute;sticos y/o promociones de paquetes vacacionales y/o vuelos y/o
        hoteles y/o autom&oacute;viles y/o cruceros y/o servicios tur&iacute;sticos y/o seguros y valores
        a&ntilde;adidos que se publiquen, EST&Aacute;N REGULADOS Y ESTAR&Aacute;N SUJETOS A LOS T&Eacute;RMINOS,
        CONDICIONES, RESTRICCIONES Y/O LIMITACIONES ESPEC&Iacute;FICAS Y APLICABLES A CADA SERVICIO Y/O PRODUCTO. DICHOS
        T&Eacute;RMINOS, CONDICIONES, RESTRICCIONES Y/O LIMITACIONES SER&Aacute;N ENTENDIDOS Y ACORDADOS ENTRE LOS
        PROVEEDORES Y LOS USUARIOS Y NADA CON EL ADMINISTRADOR DEL PORTAL. Por lo tanto, los T&eacute;rminos y
        Condiciones de uso del Portal no son aplicables, ni modifican, interpretan y/o restringen en modo alguno los
        T&eacute;rminos y Condiciones aplicables a los servicios y/o productos ofrecidos por los proveedores en el
        Portal.</p>
      <p>El Administrador del Portal no se hace responsable de los actos u omisiones de los proveedores o de cualquier
        p&eacute;rdida, da&ntilde;o o gasto en que el Usuario haya incurrido como resultado de los actos y/u omisiones
        de los proveedores. El Usuario acepta los T&eacute;rminos y Condiciones aplicables al uso del Portal publicados
        y actualizados por el Administrador en el mismo y declara que lo ha le&iacute;do, entendido y aceptado en su
        totalidad. El proveedor acepta los T&eacute;rminos y Condiciones aplicables al uso del Portal publicados y
        actualizados por el Administrador en el Portal y declara que lo ha le&iacute;do, entendido y aceptado en su
        totalidad y que es plenamente responsable de la venta que realice de sus servicios y productos a los Usuarios.
        Asimismo, el Administrador del Portal no se hace responsable de las discrepancias entre las cotizaciones
        verbales y las cotizaciones escritas que puedan surgir de los proveedores. Una vez recibido el pago, toda la
        informaci&oacute;n contenida en el itinerario del servicio de <strong>Miles / Care by Novae</strong> se
        considerar&aacute; correcta y plenamente aceptada por el Usuario.</p>
    </div>


    <h2 (click)="toggleClass('2')" [ngClass]="{'open': show_description2}">POLÍTICA DE USO DEL SITIO, SU CONTENIDO Y
      NAVEGACIÓN</h2>

    <div class="container" [ngClass]="{'show': show_description2}">
      <p>Al utilizar el Portal, los Usuarios y/o Proveedores aceptan las siguientes condiciones de uso. Descargo de
        responsabilidad: En la medida m&aacute;xima permitida por las leyes aplicables, los materiales y/o aplicaciones
        contenidos en el Portal se proporcionan tal y como est&aacute;n expuestos y sin garant&iacute;as de
        ning&uacute;n tipo, ya sean expresas o impl&iacute;citas. El Administrador del Portal renuncia a todas las
        garant&iacute;as, incluyendo, sin limitaci&oacute;n, las garant&iacute;as impl&iacute;citas de comerciabilidad
        y/o idoneidad para un prop&oacute;sito particular, y los Proveedores, subsidiarias y/o afiliados son los
        &uacute;nicos responsables de la garant&iacute;a de los productos o servicios ofrecidos y vendidos en el Portal,
        con la excepci&oacute;n de las garant&iacute;as o servicios proporcionados directamente por <strong>Miles / Care
          by Novae</strong> donde &eacute;l es responsable de su cumplimiento. El Administrador del Portal har&aacute;
        todo lo posible para que los componentes del Portal, el sistema, el equipo y los programas, est&eacute;n libres
        de virus o de cualquier programa no autorizado, y se compromete a corregir oportunamente las interrupciones o
        errores del Portal que le sean imputables. La informaci&oacute;n y descripciones contenidas en los
        T&eacute;rminos y Condiciones aplicables al uso del Portal no pretenden ser los t&eacute;rminos, exclusiones y
        condiciones aplicables a los productos y servicios ofrecidos en el Portal, por favor, consulte la
        pol&iacute;tica o acuerdo aplicable a cada producto o servicio en particular. El Portal puede estar vinculado a
        otros sitios web que no son mantenidos por el Administrador del Portal, por lo que no ser&aacute; responsable
        por el contenido, uso, producto o servicio vendido en dichos sitios web. La inclusi&oacute;n de un enlace al
        Portal no implica la aprobaci&oacute;n o el respaldo del Administrador del Portal y/o de los Proveedores de
        dicha p&aacute;gina web enlazada y/o su contenido. El Administrador del Portal se compromete a incluir
        informaci&oacute;n precisa y actualizada en el Portal, no se har&aacute; responsable de los errores u omisiones
        que puedan producirse y que sean atribuibles a terceros.</p>
    </div>



    <h2 (click)="toggleClass('3')" [ngClass]="{'open': show_description3}">RESTRICCIONES AL USO DE MATERIALES Y/O
      APLICACIONES CONTENIDAS EN EL PORTALL</h2>

    <div class="container" [ngClass]="{'show': show_description3}">
      <p>El Portal y las aplicaciones contenidas en &eacute;l son propiedad y est&aacute;n operados por el Administrador
        del Portal y/o por los proveedores, seg&uacute;n corresponda. Excepto en los casos expresamente permitidos,
        ning&uacute;n material y/o aplicaci&oacute;n y/o sitio web y/o solicitud de propiedad operada, licenciada o
        controlada por el Administrador del Portal y/o los proveedores, seg&uacute;n corresponda, puede ser copiado,
        reproducido, publicado, cargado, enviado, transmitido o distribuido de ninguna manera. El Usuario puede
        descargar los materiales y/o aplicaciones mostrados en el Portal para su uso exclusivo, siempre y cuando
        mantenga todos los avisos de derechos de autor y otros avisos de propiedad contenidos en los materiales y/o
        aplicaciones. El Usuario no podr&aacute; distribuir, modificar, transmitir, reutilizar, reenviar o utilizar el
        contenido y/o aplicaciones del Portal para fines p&uacute;blicos o comerciales, incluyendo los textos,
        im&aacute;genes, audio y videos contenidos en el mismo.</p>
    </div>



    <h2 (click)="toggleClass('4')" [ngClass]="{'open': show_description4}">ASUNTOS DE COMPETENCIA</h2>

    <div class="container" [ngClass]="{'show': show_description4}">
      <p>
        Los usuarios que acceden al Portal y/o a sus aplicaciones lo hacen por iniciativa propia y son responsables del
        cumplimiento de las leyes aplicables. Las ofertas y/o productos y/o servicios tendrán efecto en cualquier
        jurisdicción en la que dicha oferta y/o solicitud, compra o venta sea legal bajo las leyes de dicha
        jurisdicción. Es posible que algunos productos y servicios no estén disponibles en todas las jurisdicciones.
      </p>
    </div>



    <h2 (click)="toggleClass('5')" [ngClass]="{'open': show_description5}">PROPIEDAD DE LA INFORMACIÓN</h2>

    <div class="container" [ngClass]="{'show': show_description5}">
      <p>
        Cualquier información, distinta de los datos personales o información que el Usuario transmita al Administrador
        del Portal y/o a los Proveedores a través del Portal, por correo electrónico u otros medios, incluyendo datos,
        preguntas, comentarios o sugerencias, será tratada como confidencial y pasará a ser propiedad del Administrador
        del Portal y/o de los proveedores, según corresponda. Dicha información, distinta de los datos personales, podrá
        ser utilizada para cualquier propósito, incluyendo pero no limitándose a la reproducción, solicitud,
        divulgación, transmisión, publicación y difusión. Tanto el Administrador del Portal como los proveedores pueden
        utilizar cualquier idea, concepto, know-how o técnica contenida en cualquier comunicación que cualquiera de las
        partes envíe a través del Portal y/o por cualquier otro medio para cualquier propósito, incluyendo pero no
        limitándose a desarrollar y comercializar productos utilizando dicha información.
      </p>
    </div>



    <h2 (click)="toggleClass('6')" [ngClass]="{'open': show_description6}">PROPIEDAD MATERIAL E INMATERIAL</h2>

    <div class="container" [ngClass]="{'show': show_description6}">
      <p><strong>Miles / Care by Novae</strong> retiene todos los derechos, t&iacute;tulos, propiedad intelectual e
        intereses de todas las partes de los productos, servicios, software asociado y desarrollos de plataformas
        tecnol&oacute;gicas, sus modificaciones, ampliaciones o cualquier trabajo resultante de los mismos que formen
        parte de la plataforma integrada puesta a disposici&oacute;n de los bancos y/o aliados, as&iacute; como las
        estrategias, modelos de negocio e informaci&oacute;n que como producto del desarrollo de las estad&iacute;sticas
        de uso de los clientes establecidas en los programas, mantenga y mantendr&aacute; <strong>Miles / Care by
          Novae</strong> con los bancos y/o aliados.</p>
    </div>



    <h2 (click)="toggleClass('7')" [ngClass]="{'open': show_description7}">LICENCIAS DE SOFTWARE</h2>

    <div class="container" [ngClass]="{'show': show_description7}">
      <p>
        El Usuario reconoce que cualquier software que pueda ofrecerse en el Portal y/o en sus aplicaciones puede
        contener tecnología sujeta a estrictos controles de acuerdo con las normas y reglamentos de control aplicables.
        Al aceptar los Términos y Condiciones Aplicables al Uso del Portal, el Usuario se compromete a no transferir o
        exportar software, en violación de las leyes y reglamentos aplicables a la exportación. El Administrador del
        Portal prohíbe la descarga o exportación de cualquier software o datos técnicos del Portal y/o sus aplicaciones
        a cualquier jurisdicción prohibida por los controles de exportación, tales leyes y reglamentos.
      </p>
    </div>



    <h2 (click)="toggleClass('8')" [ngClass]="{'open': show_description8}">OPERADOR TURÍSTICO</h2>

    <div class="container" [ngClass]="{'show': show_description8}">
      <p>
        El operador turístico que haga la reserva le informará de los valores a pagar por impuestos, tasas,
        contribuciones, cargos, penalizaciones, restricciones, límites de responsabilidad y otras informaciones
        relacionadas con los servicios ofrecidos. Sujeto al régimen de responsabilidad establecido por la Ley 300/96 y
        el D.R. 1075/97.
      </p>
    </div>



    <h2 (click)="toggleClass('9')" [ngClass]="{'open': show_description9}">GENERALIDADES</h2>

    <div class="container" [ngClass]="{'show': show_description9}">
      <p>La explotaci&oacute;n y el abuso sexual de menores se castiga con penas de prisi&oacute;n, de conformidad con
        las disposiciones de la Ley 679 de 2001. Todos los derechos reservados. El Usuario se obliga a observar
        estrictamente las instrucciones que el Administrador del Portal le indique sobre el uso del servicio y las
        medidas de seguridad, ya sea a trav&eacute;s de documentaci&oacute;n escrita o publicada en cualquiera de las
        p&aacute;ginas del Portal, o a trav&eacute;s de cualquier otro medio que <strong>Miles / Care by Novae</strong>
        considere pertinente. El Usuario asume, en adelante, la responsabilidad de las operaciones y transacciones
        financieras que se realicen a trav&eacute;s de los medios tecnol&oacute;gicos o transaccionales indicados en el
        Portal.</p>
      <p>Se acuerda que <strong>Miles / Care by Novae</strong> no ser&aacute; responsable de las consultas,
        transacciones, operaciones y/o instrucciones realizadas por personas ajenas al usuario cuando &eacute;ste
        pierda, divulgue o comprometa la seguridad de los elementos necesarios para la realizaci&oacute;n de las
        transacciones y/o operaciones. Se acuerda que <strong>Miles / Care by Novae</strong> podr&aacute; suspender o
        cancelar total o parcialmente en cualquier momento, sin previo aviso, el servicio al Usuario, y en consecuencia,
        restringir el acceso al uso del Portal y la realizaci&oacute;n de las transacciones, operaciones y/o
        instrucciones ofrecidas en el mismo. El Usuario se compromete a no utilizar el Portal y los servicios ofrecidos
        por <strong>Miles / Care by Novae</strong> y/o aliados estrat&eacute;gicos, seg&uacute;n sea el caso, con fines
        il&iacute;citos o contrarios a la moral y a las buenas costumbres generalmente aceptadas, as&iacute; como a
        abstenerse de utilizar el Portal y los servicios disponibles a trav&eacute;s de Internet a: a) crear o intentar
        crear una falsa identidad con el fin de enga&ntilde;ar a <strong>Miles / Care by Novae</strong> o a terceros
        sobre la identidad del remitente o el origen de sus mensajes, consultas o transacciones; b) publicar, distribuir
        o difundir cualquier informaci&oacute;n o material inapropiado, difamatorio, il&iacute;cito, obsceno, indecente,
        ilegal o que infrinja los derechos de terceros; c) transmitir o subir archivos que contengan virus, "troyanos",
        "gusanos", "bombas de tiempo", "robots de cancelaci&oacute;n de noticias" o cualquier otro programa o dato
        da&ntilde;ino; d) transmitir, cargar o copiar archivos que contengan software u otros materiales protegidos por
        la legislaci&oacute;n relativa a la propiedad intelectual, el derecho a la intimidad o la propia imagen, o por
        cualquier otra legislaci&oacute;n o derecho aplicable, a menos que el Usuario sea el propietario o controle los
        derechos mencionados o haya recibido todas las autorizaciones necesarias para ello; e) enviar o canalizar el
        env&iacute;o de mensajes publicitarios no autorizados, agresivos o no solicitados, de forma masiva ("spamming")
        o aleatoria; f) crear hiperv&iacute;nculos ("links") hacia o desde cualquiera de las p&aacute;ginas, archivos,
        aplicaciones o elementos del Portal sin la autorizaci&oacute;n expresa y por escrito del Administrador del
        Portal para tales fines; g) interferir o interrumpir el normal funcionamiento de las redes conectadas al
        servicio, as&iacute; como violar las normas, directivas o procedimientos de dichas redes; h) intentar obtener
        acceso no autorizado a los servicios prestados por <strong>Miles / Care by Novae</strong> a trav&eacute;s de
        medios electr&oacute;nicos, as&iacute; como a cualquier sistema o red inform&aacute;tica conectada a los
        servicios ofrecidos por <strong>Miles / Care by Novae</strong>, mediante la b&uacute;squeda autom&aacute;tica de
        contrase&ntilde;as, la sobrecarga de datos o por cualquier otro medio; i) interferir en el funcionamiento, uso o
        disposici&oacute;n en cualquier forma de los archivos, aplicaciones, programas y/o sistemas <strong>Miles / Care
          by Novae</strong> utilice o posea para la prestaci&oacute;n de servicios de cualquier tipo a trav&eacute;s de
        Internet, del Portal o de cualquier otra red inform&aacute;tica; j) acceder, utilizar o disponer sin
        autorizaci&oacute;n expresa del Administrador del Portal de la informaci&oacute;n contenida en cualquiera de sus
        sistemas de informaci&oacute;n, comunicaci&oacute;n, procesamiento y/o almacenamiento de datos; k) interferir en
        el uso o disfrute del Portal y/o de cualquier otro servicio de otros clientes, personas o entidades; l) obtener
        informaci&oacute;n de terceros, entendi&eacute;ndose que si el Usuario accede a ella de cualquier forma
        deber&aacute; mantener la confidencialidad y responder por los da&ntilde;os y perjuicios ocasionados por la
        violaci&oacute;n del mismo; m) realizar cualquier otro acto u omisi&oacute;n que pueda causar directa o
        indirectamente un da&ntilde;o a <strong>Miles / Care by Novae</strong> o a terceros. En caso de tener
        conocimiento de la realizaci&oacute;n de alguna de las actividades mencionadas por parte de alguna persona, el
        Usuario se compromete a dar aviso inmediato al Administrador del Portal y a responder por los da&ntilde;os y
        perjuicios causados por la demora en dicho aviso.</p>
      <p>El Usuario autoriza expresamente a <strong>Miles / Care by Novae</strong> a facilitar informaci&oacute;n,
        cuando &eacute;sta lo considere necesario, relacionada con el Portal, su gesti&oacute;n o los productos,
        servicios y prestaciones desarrollados por <strong>Miles / Care by Novae</strong>, a trav&eacute;s de cualquier
        medio, ya sea sin &aacute;nimo de lucro, mensajes de texto, correo electr&oacute;nico u otros medios que el
        Usuario declare son de uso exclusivo y que restringir&aacute;n el acceso a terceros. En cualquier caso, el
        Usuario se niega ahora a presentar cualquier reclamaci&oacute;n contra <strong>Miles / Care by Novae</strong>
        por el env&iacute;o de la informaci&oacute;n o por el contenido de la misma. El env&iacute;o de la
        informaci&oacute;n tiene car&aacute;cter meramente informativo, por lo que las partes acuerdan que <strong>Miles
          / Care by Novae</strong> no ser&aacute; responsable de las inexactitudes en la misma ni de su contenido, salvo
        la informaci&oacute;n relativa al env&iacute;o de un nombre de usuario y una contrase&ntilde;a. El usuario
        est&aacute; obligado a mantener su informaci&oacute;n actualizada, as&iacute; como su n&uacute;mero de
        m&oacute;vil y correo electr&oacute;nico seguro, respondiendo al incumplimiento de esta obligaci&oacute;n. El
        Usuario se compromete a mantener y aplicar en sus transacciones y negocios los procedimientos, herramientas,
        sistemas y m&eacute;todos eficaces para evitar que sean objeto de blanqueo de dinero, adem&aacute;s de mantener
        a <strong>Miles / Care by Novae</strong> libre de cualquier da&ntilde;o causado por tal situaci&oacute;n. El
        Usuario se compromete a notificar por escrito a <strong>Miles / Care by Novae</strong>, de forma inmediata,
        cualquier acto o sospecha de corrupci&oacute;n, soborno, blanqueo de dinero o financiaci&oacute;n del terrorismo
        que se presente o pueda presentarse en la negociaci&oacute;n, celebraci&oacute;n o ejecuci&oacute;n del presente
        contrato. Se acuerda que <strong>Miles / Care by Novae</strong> no se hace responsable de la falta de
        prestaci&oacute;n de servicios en caso de fuerza mayor, caso fortuito, fallas en el fluido el&eacute;ctrico o en
        las l&iacute;neas telef&oacute;nicas, y otros eventos que impidan o dificulten la prestaci&oacute;n del
        servicio. El Usuario autoriza expresamente a <strong>Miles / Care by Novae </strong>a grabar las conversaciones
        relacionadas con su negocio, y a conservarlas, escucharlas y, en su caso, utilizarlas como prueba</p>
    </div>

  </div>
</div>
