import { Component, OnInit, AfterViewInit, HostListener } from '@angular/core';
import { DataService } from '../../services/data.service';
import { Router } from '@angular/router';
import { DataStoreService } from '../../services/data-store.service';
import { OcultadorService } from '../../pages/home/ocultador.service';
import { EProduct } from '../searched/models/searched.model';
import { InitService } from '../../services/init.service';
import { environment } from 'src/environments/environment';
import { ActionEventService } from '../../services/action-event.service';
import { StoreServiceLibrary, keysUniversal, keysFixedRates, UserData } from '@wearenovae/novae-core-services';
import data from '../../resources/screenAndEventsTracking.json';
import { keys } from 'src/app/resources/keys.resources';
import { GoogleAnalytics } from '../../resources/GoogleAnalytics.resource';
import { timeout } from 'rxjs/operators';


declare var window: any;

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss']
})
export class NavComponent implements OnInit, AfterViewInit {

  public translations = {};
  public isNewMenu = false;
  loadInfoLM = false;

  userLifeMiles: UserData;

  EProduct = EProduct;
  public hasBenefits = false;
  public hasActivities = true;
  public hasAbout = false;
  appName = '';
  logo = '';
  optionMenu = [];
  public fixed: boolean;
  public id = 'header';

  get brand() {
    return this.dataStore.brand;
  }

  get isAlleMobile(): boolean {
    return this.dataStore.isAlleMobile;
  }

  hasHeaderDownload = false;


  get tabSelect(): EProduct {
    return this.data.productSelected;
  }

  set tabSelect(value: EProduct) {
    this.data.productSelected = value;
  }

  paramPayLoad: string;
  tabSelectData: string;

  showHeader : boolean = true;

  constructor(
    private data: DataService,
    public router: Router,
    private dataStore: DataStoreService,
    public ocultadorService: OcultadorService,
    private initService: InitService,
    private actionEventService: ActionEventService,
    private storeLibrary: StoreServiceLibrary
  ) {
  }

  ngOnInit() {
    this.appName = environment.brands[this.dataStore.brand].appName;
    this.logo = environment.brands[this.dataStore.brand].logo;
    this.initService.isInitializedBrand.subscribe(isInitialized => {
      if (isInitialized) {

        this.actionEventService.changeItemMenu.subscribe((tabSelect) => {
          this.tabSelectData = tabSelect;
        });

  
        this.actionEventService.changeItemsRouteTab.subscribe((optionMenuTab) => {
          this.optionMenu = optionMenuTab;
         
        });

        this.hasBenefits = environment.brands[this.dataStore.brand].config.hasBenefits;

        if (environment.brands[this.dataStore.brand].config.hasActivities) {
          this.hasActivities = environment.brands[this.dataStore.brand].config.hasActivities;
        }
        if (environment.brands[this.dataStore.brand].config.hasAbout) {
          this.hasAbout = environment.brands[this.dataStore.brand].config.hasAbout;
        }

        if (environment.brands[this.dataStore.brand].config.isNewHome) {
          this.isNewMenu = environment.brands[this.dataStore.brand].config.isNewHome;
          this.optionMenu = environment.brands[this.dataStore.brand].optionmenu;

          var URLactual = window.location;
          console.log(URLactual.hash)
          if(URLactual.hash=='#/example'){
            this.optionMenu[2]={ label: 'NAV.example', router: '/example', tabSelect: [EProduct.EXAMPLE] }
            this.actionEventService.setItemActiveTab(EProduct.EXAMPLE);
            this.data.productSelected = EProduct.EXAMPLE;
            this.dataStore.preloaderImg = EProduct.EXAMPLE;
          }else{
            this.optionMenu
          }
          
        }



        // if (this.dataStore.brand !== 'miles'
        //   && this.dataStore.brand !== 'firstcitizens'
        //   && this.dataStore.brand !== 'bancopopular'
        //   && this.dataStore.brand !== 'bancoindustrial'
        //   && this.dataStore.brand !== 'ncb'
        // ) {
        //   this.hasBenefits = true;
        // }
        this.getRedirect();
        setTimeout(() => {
          this.showHeader = !this.isAlleMobile &&
            !this.router.url.includes('/searcher') &&
            !this.router.url.includes('flights-mobile') &&
            !this.router.url.includes('/hotels-mobile') &&
            !this.ocultadorService.ocultar &&
            !this.router.url.includes('/travel-app') &&
            !this.router.url.includes('/onepocket-benefits') &&
            !this.router.url.includes('/myrewards-salesdeck') &&
            this.brand !== 'lifeMilesTravel' &&
            !this.router.url.includes('/onepocket-benefit');
        });
        //*/
      }
    });

    this.hasHeaderDownload = environment.brands[this.dataStore.brand].config.hasHeaderDownload;
  }

  ngAfterViewInit() {
    this.actionEventService.getInfoStorageUser.subscribe((save) => {
      if (save) {
        this.validateTypeUser(this.optionMenu);
      }
    });
    this.actionEventService.setInfoUserLifeMiles.subscribe((user: any) => {
      if (user) {
        this.userLifeMiles = user;
        this.loadInfoLM = true;
      }
    });
  }

  @HostListener('body:scroll', ['$event'])
  onScrollingEvent($event): void {
    let scrollOffset = $event.target.scrollTop;
    if (scrollOffset > 0) {
      this.fixed = true;
      this.id = '';
    } else {
      this.fixed = false;
      this.id = 'header';
    }
  }

  redirectToHomeWithParam() {
    const param = window.location.href;
    const data = param.split('?');
    if (data[1]) {
      this.router.navigateByUrl(`lifemiles-travel?${data[1]}`);
    } else {
      this.router.navigateByUrl('lifemiles-travel');
    }
  }

  getRedirect() {
    this.actionEventService.redirectHome.subscribe((isHome) => {
      if (environment.brands[this.dataStore.brand].config['isNewHome']) {
        this.data.productSelected = EProduct.HOME;
        this.dataStore.preloaderImg = EProduct.HOME;
      } else {
        this.data.productSelected = EProduct.FLIGHTS;
        this.dataStore.preloaderImg = EProduct.FLIGHTS;
      }
    });
  }

  async tabSelected(tipo: string, route: string) {
    this.data.vaidateOnePocketStay().subscribe((observer) => {
      if (observer === 2) {
        this.continueFlow(tipo, route);
      } else if (observer === 1) {
        this.data.openModalQuestion().subscribe((continueFlow) => {
          if (continueFlow) {
            this.continueFlow(tipo, route);
          }
        });
      }
    });
  }

  continueFlow(tipo: string, route: string) {
    switch (route) {
      case '/home':
        let appNameUpper = this.appName.replace(/\b\w/g, (l) => l.toUpperCase());
        let appNameReplace = appNameUpper.replace(/ /g, "_");
        let finishNameEvent = data.WELCOME.aboutRewards + appNameReplace;
        // this.trackingManager.trackEventString(finishNameEvent, data.WELCOME.welcomeScreen);
        GoogleAnalytics.sendEventFirebase('ga_event', data.WELCOME.welcomeScreen, finishNameEvent, '');
        this.router.navigateByUrl(route);
        break;
      
      case '/tutorials':
        this.router.navigateByUrl(route);
        break;
      case '/myRewards':
        // this.trackingManager.trackEventString(data.WELCOME.myRewards, data.WELCOME.welcomeScreen);
        GoogleAnalytics.sendEventFirebase('ga_event', data.WELCOME.welcomeScreen, data.WELCOME.myRewards, '');
        this.router.navigateByUrl(route);
        break;
      case '/travel':
        // this.trackingManager.trackEventString(data.WELCOME.voyage, data.WELCOME.welcomeScreen);
        GoogleAnalytics.sendEventFirebase('ga_event', data.WELCOME.welcomeScreen, data.WELCOME.voyage, '');
        this.router.navigateByUrl(route);
        break;
      case '/flights': {

        this.tabSelect = EProduct.FLIGHTS;
        this.tabSelectData = EProduct.FLIGHTS;
        this.dataStore.preloaderImg = EProduct.FLIGHTS;

        this.router.navigateByUrl('travel/flights');

        let objActivatesTabs = {
          activateFligths: true,
          activateHotels: false,
          activateCars: false,
          activateHouseVacations: false
        };

        this.actionEventService.setObjActivateMenu(objActivatesTabs);

      }
        break;
      case '/hotels': {

        this.tabSelect = EProduct.HOTELS;
        this.tabSelectData = EProduct.HOTELS;
        this.dataStore.preloaderImg = EProduct.HOTELS;

        this.router.navigateByUrl('travel/hotels');

        let objActivatesTabs = {
          activateFligths: false,
          activateHotels: true,
          activateCars: false,
          activateHouseVacations: false
        };

        this.actionEventService.setObjActivateMenu(objActivatesTabs);

      }
        break;
      case '/cars': {

        this.tabSelect = EProduct.CARS;
        this.tabSelectData = EProduct.CARS;
        this.dataStore.preloaderImg = EProduct.CARS;

        this.router.navigateByUrl('travel/cars');

        let objActivatesTabs = {
          activateFligths: false,
          activateHotels: false,
          activateCars: true,
          activateHouseVacations: false
        };

        this.actionEventService.setObjActivateMenu(objActivatesTabs);
      }
        break;
      case '/homeVacations': {

        this.tabSelect = EProduct.HOMEVACATIONS;
        this.tabSelectData = EProduct.HOMEVACATIONS;
        this.dataStore.preloaderImg = EProduct.HOMEVACATIONS;

        this.router.navigateByUrl('travel/homeVacations');

        let objActivatesTabs = {
          activateFligths: false,
          activateHotels: false,
          activateCars: false,
          activateHouseVacations: true
        };

        this.actionEventService.setObjActivateMenu(objActivatesTabs);
      }
        break;
      case '/news':
        GoogleAnalytics.sendEventFirebase('ga_event', data.WELCOME.welcomeScreen, data.WELCOME.news, '');
        this.router.navigateByUrl(route);
        break;
      // case '/fixed-rates':
      //   GoogleAnalytics.sendEventFirebase('ga_event', data.WELCOME.welcomeScreen, data.WELCOME.fixedRates, '');
      //   this.router.navigateByUrl(route);
      //   break;

      case '/example':
       this.router.navigateByUrl(route);
        break;
    }

    if (this.dataStore.brand === 'scotia') {
      if (this.data.token) {
        this.data.getInfoCard();
      }
    }

    this.data.productSelected = tipo as EProduct;
    this.dataStore.preloaderImg = tipo;
  }

  validateTypeUser(optionMenu) {
    let typeZone;
    const strCradSelected = this.storeLibrary.getCardScotiaPointsSelected();
    const cardSelected = JSON.parse(strCradSelected);

    if (cardSelected && cardSelected.typeZone) {
      typeZone = cardSelected.typeZone;
    } else {
      typeZone = keysFixedRates.DR;
    }

    this.optionMenu.forEach((item, index) => {
     // if (item['router'] == '/fixed-rates') {
       // this.optionMenu.splice(index, 1);
     // }
    });

    switch (typeZone) {
      case keysFixedRates.ECR_AMEX:
        this.actionEventService.setSubBrand('amex');
        this.data.saveCreditCardDefult('amex');
        this.actionEventService.setChangeFooter(true);
        break;
      case keysFixedRates.DR_AMEX:
        this.actionEventService.setSubBrand('amex');
        this.data.saveCreditCardDefult('amex');
        this.actionEventService.setChangeFooter(true);
        break;
      case keysFixedRates.ECR:
        this.actionEventService.setSubBrand('default');
        this.data.saveCreditCardDefult('');
        this.actionEventService.setChangeFooter(false);
       // const fixedRates = { label: 'NAV.fixed-rates', router: '/fixed-rates', tabSelect: [EProduct.FIXED_RATES] };
        //this.optionMenu.push(fixedRates);
        break;
      case keysFixedRates.DR:
        this.actionEventService.setSubBrand('default');
        this.data.saveCreditCardDefult('');
        this.actionEventService.setChangeFooter(false);
        break;
      default:
        break;
    }
  }

  downloadapp() {
    // this.trackingManager.trackEventString(data.WELCOME.downloadApp, data.WELCOME.welcomeScreen);
    GoogleAnalytics.sendEventFirebase('ga_event', data.WELCOME.welcomeScreen, data.WELCOME.downloadApp, '');
    let urlWhatsappWelcome = '';

    /*
  *Detection mobile browser
  */

    const isMobile = {
      Android: function () {
        return navigator.userAgent.match(/Android/i);
      },
      iOS: function () {
        return navigator.userAgent.match(/iPhone|iPad|iPod|Macintosh/i);
      }
    };

    if (isMobile.Android()) {
      urlWhatsappWelcome = this.storeLibrary.getJustOneUniversalPropertie(keysUniversal.android_url_store);
      window.location.assign(urlWhatsappWelcome);
    } else if (isMobile.iOS()) {
      urlWhatsappWelcome = this.storeLibrary.getJustOneUniversalPropertie(keysUniversal.ios_url_store);
      window.location.assign(urlWhatsappWelcome);
    } else {
      urlWhatsappWelcome = this.storeLibrary.getJustOneUniversalPropertie(keysUniversal.android_url_store);
      window.open(urlWhatsappWelcome, '_system', 'location=yes');
    }


  }

  goToLoginLM() {
    const urlLoginLifeMiles = this.storeLibrary.getJustOneUniversalPropertie(keys.URL_LOGIN_LIFEMILES);
    window.location = urlLoginLifeMiles;
  }

  hiddenHeaderDownload() {
    // this.trackingManager.trackEventString(data.WELCOME.closeDownloadApp, data.WELCOME.welcomeScreen);
    GoogleAnalytics.sendEventFirebase('ga_event', data.WELCOME.welcomeScreen, data.WELCOME.closeDownloadApp, '');
    this.hasHeaderDownload = false;
  }

  redirectFligths() {
    const route = window.location.hash;
    let routeLink = '';

    if (!environment.production) {
      if (this.brand !== 'credomatic') {
        if (route === '#/travel') {
          routeLink = 'travel/flights';
        } else {
          routeLink = 'travel';
        }
      }
    }

    this.data.vaidateOnePocketStay().subscribe((observer) => {
      if (observer === 2) {
        this.router.navigateByUrl(routeLink);

        document.body.scroll({
          top: 0,
          behavior: 'smooth'
        });

        let objActivatesTabs = {
          activateFligths: true,
          activateHotels: false,
          activateCars: false
        };

        this.actionEventService.setObjActivateMenu(objActivatesTabs);

      } else if (observer === 1) {
        this.data.openModalQuestion().subscribe((continueFlow) => {
          if (continueFlow) {

            let objActivatesTabs = {
              activateFligths: true,
              activateHotels: false,
              activateCars: false
            };

            this.router.navigateByUrl(routeLink);

            document.body.scroll({
              top: 0,
              behavior: 'smooth'
            });

            this.actionEventService.setObjActivateMenu(objActivatesTabs);
          }
        });
      }
    });
  }

}
