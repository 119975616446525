import { ProductHandlerService } from './../../services/product-handler.service';
import { DataService } from 'src/app/services/data.service';
import { ResultsService } from './results.service';
import { ProductDetailComponent } from './../../component/product-detail/product-detail.component';
import { IOptions, ECardType } from './../../component/card/models/options.model';
import { Component, OnInit, OnDestroy, Inject, Renderer2, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { HotelAvailabilityOptions } from 'src/app/models/new-hotel/hotel-availability-response.model';
import { MatDialog } from '@angular/material/dialog';
import { Journey } from 'src/app/models/new-flight/journey.model';
import { Slider } from 'src/app/component/slider-op/model/slider';
import { EProduct } from 'src/app/component/searched/models/searched.model';
import { RentacarAvailabilityOption } from 'src/app/models/new-rentacar/rentacar-availability-option.model';
import { ActivityResult } from 'src/app/models/new-activity/activity-availability-response.model';
import { DataStoreService } from '../../services/data-store.service';
import { AlertErrorComponent } from '../../component/product-detail/alert-error/alert-error.component';
import { DOCUMENT } from '@angular/common';
import { OcultadorService } from '../../pages/home/ocultador.service';
import { ActionEventService } from '../../services/action-event.service';

import data from 'src/app/resources/screenAndEventsTracking.json';
import { GoogleAnalytics } from '../../resources/GoogleAnalytics.resource';

@Component({
  selector: 'app-results',
  templateUrl: './results.component.html',
  styleUrls: ['./results.component.scss']
})

export class ResultsComponent implements OnInit {

  get cardOptions(): IOptions {
    const productType = this.productType;
    const cardOptions = {
      type: productType as any,
      hasDetail: (productType !== EProduct.CARS) ? true : false,
      hasTitle: (productType !== EProduct.CARS) ? true : false,
      hasRefundable: (productType !== EProduct.CARS && productType !== EProduct.ACTIVITIES) ? true : false,
      showBottomInfo: (productType !== EProduct.ACTIVITIES) ? true : false,
    };
    return cardOptions;
  }

  public filtersParamsList: any;
  public filteredID: any = {
    values: []
  };

  public translations = [];

  public showAdjustPriceMobile = false;
  public showFiltersMobile = false;

  public qtyResultShow = 5;

  public resultServiceSubscription;
  // tslint:disable-next-line:member-ordering
  private stateClassRef = {
    normal: 'visible',
    collapsed: 'hidden'
  };
  // tslint:disable-next-line:member-ordering
  private state = 'normal'; // normal or collapsed

  isCollapse: boolean = false;

  constructor(
    private el: ElementRef,
    private rd: Renderer2,
    @Inject(DOCUMENT) private document: Document,
    public dialog: MatDialog,
    private resultsService: ResultsService,
    private dataService: DataService,
    private router: Router,
    private dataStore: DataStoreService,
    private productHandlerService: ProductHandlerService,
    private ocultadorService: OcultadorService,
    private actionEventService: ActionEventService
  ) {
    this.productHandlerService.clearSelectedProduct();
  }

  get showSelectButton() {
    return this.cardOptions.type !== ECardType.HOTELS;
  }

  get selectedProduct(): HotelAvailabilityOptions | Journey | RentacarAvailabilityOption | ActivityResult {
    return this.productHandlerService.selectedProduct;
  }

  get token() {
    return this.resultsService.token;
  }

  get productList(): HotelAvailabilityOptions[] | Journey[] | RentacarAvailabilityOption[] | ActivityResult[] {
    return this.resultsService.productList ? this.resultsService.productList : [];
  }

  get productType(): EProduct {
    return this.resultsService.productType;
  }


  ngOnInit() {
    this.resultServiceSubscription = this.resultsService.isInitialized.subscribe(init => {
      if (init) {
        this.resultsService.getAvailability().then(res => {
          this.initMobileCollapse();
          this.filtersParamsList = this.resultsService.getFiltersParamsList();
          this.getContent();
        }).catch(redirect => {
          this.router.navigate(['results/noResults']);
        });
      }
    });
  }

  // ngOnDestroy() {
  //   this.resultServiceSubscription.unsubscribe();
  // }

  selectProduct(product) {
    this.productHandlerService.selectedProduct = product;
  }

  getProductDetail(ev) {
    this.selectProduct(ev);
    const dialogRef = this.dialog.open(ProductDetailComponent, {
      panelClass: 'detail-dialog',
      data: ev
    });
  }
  changeMobile() {
    this.showFiltersMobile = !this.showFiltersMobile;
    this.state = this.state === 'normal' ? 'collapsed' : 'normal';
    this.el.nativeElement.closest('body').className = this.stateClassRef[this.state];
  }
  isProductSelected(product) {
    return (this.selectedProduct) ? this.selectedProduct.id === product.id : false;
  }

  filterListener(filteredIDs) {
    this.filteredID = {
      values: filteredIDs.IDs,
      isFiltered: filteredIDs.isFiltered
    };
    this.selectProduct(this.productList[filteredIDs.IDs[0]]);
    this.getContent();
  }

  priceConversion(percent) {
    const conversionRate = this.dataService.conversionRate;
    const userPoints = this.dataService.userPointsSP;
    this.productList.forEach(product => product.priceConversion(conversionRate, percent, userPoints));
  }

  /**
   * Mostrar mas resultados
   */
  showMore() {
    let tipoUpperCase = this.productType.replace(/\b\w/g, (l) => l.toUpperCase());
    // this.trackingManager.trackEventString(data.RESULTS.showMoreResults + tipoUpperCase, data.RESULTS.searchResultScreen + tipoUpperCase);
    GoogleAnalytics.sendEventFirebase('ga_event', data.RESULTS.searchResultScreen + tipoUpperCase, data.RESULTS.showMoreResults + tipoUpperCase, '');
    this.qtyResultShow += 5;
    this.getContent();
  }

  /**
   * Obtener data adicional de los productos mostrados
   */
  getContent() {
    const filteredIDs = this.filteredID.values;
    let getContentOf
      = (this.productList as any[]).filter(item => !filteredIDs || filteredIDs.length === 0 || filteredIDs.indexOf(item.id) > -1);
    getContentOf = getContentOf.filter((item, i) => i < this.qtyResultShow && (!item.images || item.images.length <= 1));
    this.resultsService.getContentData(getContentOf);
  }

  initMobileCollapse() {
    this.actionEventService.collapseAppMobile.subscribe(isTrue => {
      if (isTrue || this.ocultadorService.ocultar) {
        this.isCollapse = true;
      }
    });

    if (this.ocultadorService.ocultar) {
      return this.isCollapse = true;
    } else {
      return this.isCollapse = false;
    }
  }

  next() {
    this.resultsService.getBookingRules().then(res => {
      const selectedProduct = (this.productHandlerService.selectedProduct as any);
      selectedProduct.bookingRules = res;
      this.productHandlerService.selectedProduct = selectedProduct;
      this.router.navigate([this.productType + '/registry']);
      
      let tipoUpperCase = this.productType.replace(/\b\w/g, (l) => l.toUpperCase());
      // this.trackingManager.trackEventString(data.RESULTS.selectResult + tipoUpperCase, data.RESULTS.searchResultScreen + tipoUpperCase);
      GoogleAnalytics.sendEventFirebase('ga_event', data.RESULTS.searchResultScreen + tipoUpperCase, data.RESULTS.selectResult + tipoUpperCase, '');
    }).catch(err => {
      const heightPadding = this.dataStore.isMobileDevice ? '0px' : '40px';
      const widthPadding = this.dataStore.isMobileDevice ? '0px' : '16vw';
      const EProductSelected = this.dataService.productSelected;
      this.dialog.open(AlertErrorComponent, {
        maxWidth: '50vw',
        width: 'calc(50vw - ' + widthPadding + ')',
        height: 'calc(50vh - ' + heightPadding + ')',
        minHeight: '59vh',
        data: {
          tittle: 'ALERT.ERROR_BOOKING.title',
          error: 'ALERT.ERROR_BOOKING.boddy'
        }
      });
      let tipoUpperCase = this.productType.replace(/\b\w/g, (l) => l.toUpperCase());
      // this.trackingManager.trackEventString(data.RESULTS.selectResult + tipoUpperCase, data.RESULTS.searchResultScreen + tipoUpperCase, '', JSON.stringify(err));
      GoogleAnalytics.sendEventFirebase('ga_event', data.RESULTS.searchResultScreen + tipoUpperCase, data.RESULTS.selectResult + tipoUpperCase, JSON.stringify(err));
    });
  }
}
