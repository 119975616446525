<div>
    <div class="coupon" *ngIf="promotion?.code && typePromotion == 'CODE' && brand == 'credomatic'">
        <div class="block-description">
          <div class="description">
            <h2>¡ Tenemos un cupón de descuento para ti !</h2>
            <!-- <h3>Hasta <span>30</span>% Dto.</h3> -->
            <h3 *ngIf="promotion.typeDiscount == 'PORCENTAGE'">Hasta <span>{{ promotion.percentage }}</span>% Dto.</h3>
            <h3 *ngIf="promotion.typeDiscount == 'VALUEDISCOUNT'">Hasta <span>US$ {{ promotion.valueDiscount }}</span></h3>

            <h2 class="promocioncompra" *ngIf="promotion?.namePromotion">En tu compra con {{ promotion.namePromotion }}</h2>
          </div>
          <div class="block-code">
            <p>
              Agregalo después de seleccionar tu forma de pago
              <span class="altern" *ngIf="promotion?.currency == 'MONEY'">en dinero</span>
              <span class="altern" *ngIf="promotion?.currency == 'MILES'">en puntos</span>
            </p>
            <div class="code">
              <!-- DESC0128C -->
              {{ promotion?.code }}
            </div>
            <span>Válido hasta {{ promotion?.fechafin | date:"dd/MM/yyyy" }}</span>
          </div>
        </div>

      </div>

      <div class="discount" *ngIf="promotion && typePromotion == 'SYSTEM' && brand == 'credomatic'">
        <div class="description">
          <div class="bl-left">
            <h2 *ngIf="promotion.typeDiscount == 'PORCENTAGE'">Descuentos <br> hasta {{ promotion.percentage }}%</h2>
            <h2 *ngIf="promotion.typeDiscount == 'VALUEDISCOUNT'">Descuentos <br> hasta US$ {{ promotion.valueDiscount }}</h2>
            <h2 class="compra-promotion" *ngIf="promotion?.namePromotion">En tu compra con {{ promotion.namePromotion }}</h2>
          </div>
          <div class="bl-right">
            <img src="assets/images/credomatic-miles/discount.svg" alt="">
          </div>
        </div>
        <div class="date">
          <span>Válido hasta {{ promotion?.fechafin | date:"dd/MM/yyyy" }}</span>
        </div>
      </div>

</div>