import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-buscador-presentacion',
  templateUrl: './buscador-presentacion.component.html',
  styleUrls: ['./buscador-presentacion.component.scss']
})
export class BuscadorPresentacionComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
