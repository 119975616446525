import {Component, OnInit} from '@angular/core';
import {CardComponent} from '../card.component';

@Component({
  selector: 'app-card-body',
  templateUrl: './card-body.component.html',
  styleUrls: ['./card-body.component.scss']
})
export class CardBodyComponent implements OnInit {

  options: any;
  constructor(public cardComponent: CardComponent) {
  }

  ngOnInit() {
    this.options = this.cardComponent.options;
  }

}
