<div class="contenido-landing">

  <section [class]="'intro upperclub ' + dataStore.brand">
    <!--HEADER-->
    <header>
      <div class="container">
        <div class="bg-logo landingLogo" routerLink="home">
          <a class="logo logoHolder"></a>
        </div>
      </div>
    </header>

    <div>
      <div class="bl-intro">

        <swiper
          *ngIf="type === 'component' && show && link_IOS_URL_STORE && link_ANDROID_URL_STORE"
          class="swiper-container" fxFlex="auto" [config]="config"
          [disabled]="disabled">
          <div class="bl-in-left swiper-slide slide-uno">
            <div class="padding-landing">
              <h1>
                <strong innerHTML="{{'LANDING_ALLO.get_active'|translate}}">
                  Get active in the digital rewards platform that rewards your
                  loyalty
                </strong>
              </h1>
              <p innerHTML="{{'LANDING_ALLO.enjoy_your_benefits'|translate}}">
                Enjoy your benefits, activating your account with the email you
                have registered with your bank.
              </p>
              <div class="download">
                <p
                  innerHTML="{{'LANDING_ALLO.download_now'|translate:'{brand:\'' + brand + '\'}'}}">
                  Download now our brand app
                </p>
                <div class="buttons">
                  <a [href]="link_IOS_URL_STORE" target="_blank">
                    <img class="btn-app" src="assets/images/btn-playstore.svg"
                      alt="btn appstore">
                  </a>
                  <a [href]="link_ANDROID_URL_STORE" target="_blank">
                    <img class="btn-app" src="assets/images/btn-googleplay.svg"
                      alt="btn googleplay">
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div class="bl-in-left swiper-slide slide-dos">
            <div class="padding-landing">
              <h1 class="slide-branded">
                <strong innerHTML="{{'LANDING_ALLO.branded_rules'|translate}}">
                  Branded Rules
                </strong>
                <br>
                <span innerHTML="{{'LANDING_ALLO.turn_your_miles'|translate}}">
                  Turn your Miles into extraordinary experiences
                </span>
              </h1>
              <ul class="lista-branded">
                <li class="interrupcion_icon">
                  <strong innerHTML="{{'LANDING_ALLO.no_fees'|translate}}">
                    No fees
                  </strong>
                  <br>
                  <span
                    innerHTML="{{'LANDING_ALLO.for_cancellation'|translate}}">
                    for cancellation or interruption of flights or hotel
                  </span>
                </li>
                <li class="conexiones_icon">
                  <ng-container *ngIf="!brand || brand !== 'ABANCA'">
                    <strong
                      innerHTML="{{'LANDING_ALLO.up_to'|translate: '{value:\'USD $500\'}'}}">
                      Up to USD $500
                    </strong>
                    <br>
                  </ng-container>
                  <ng-container *ngIf="brand && brand === 'ABANCA'">
                    <strong
                      innerHTML="{{'LANDING_ALLO.up_to'|translate: '{value:\'EUR $452\'}'}}">
                      Up to EUR $452
                    </strong>
                    <br>
                  </ng-container>
                  <span innerHTML="{{'LANDING_ALLO.per_flight'|translate}}">
                    per flight and delayed luggage
                  </span>
                </li>
                <li class="equipaje_icon">
                  <strong innerHTML="{{'LANDING_ALLO.no_fees'|translate}}">
                    No fees
                  </strong>
                  <br>
                  <span innerHTML="{{'LANDING_ALLO.by_reservation'|translate}}">
                    by reservation change or lost international connections
                  </span>
                </li>
                <li class="retrasado_icon">
                  <ng-container *ngIf="!brand || brand !== 'ABANCA'">
                    <strong
                      innerHTML="{{'LANDING_ALLO.up_to'|translate: '{value:\'USD $500\'}'}}">
                      Up to USD $500
                    </strong>
                    <br>
                  </ng-container>
                  <ng-container *ngIf="brand && brand === 'ABANCA'">
                    <strong
                      innerHTML="{{'LANDING_ALLO.up_to'|translate: '{value:\'EUR $452\'}'}}">
                      Up to EUR $452
                    </strong>
                    <br>
                  </ng-container>
                  <span innerHTML="{{'LANDING_ALLO.loss_or_theft'|translate}}">
                    for your personal electronic travel devices for loss or
                    theft
                  </span>
                </li>
                <li class="personales_icon">
                  <ng-container *ngIf="!brand || brand !== 'ABANCA'">
                    <strong
                      innerHTML="{{'LANDING_ALLO.up_to'|translate: '{value:\'USD $50.000\'}'}}">
                      Up to USD $50.000
                    </strong>
                    <br>
                  </ng-container>
                  <ng-container *ngIf="brand && brand === 'ABANCA'">
                    <strong
                      innerHTML="{{'LANDING_ALLO.up_to'|translate: '{value:\'EUR $45.161\'}'}}">
                      Up to EUR $45.161
                    </strong>
                    <br>
                  </ng-container>
                  <span innerHTML="{{'LANDING_ALLO.per_delayed'|translate}}">
                    per flight and/or delayed luggage
                  </span>
                </li>
                <li class="garantizado_icon">
                  <strong
                    innerHTML="{{'LANDING_ALLO.best_price_guaranteed'|translate}}">
                    The best price guaranteed*
                  </strong>
                  <br>
                  <span innerHTML="{{'LANDING_ALLO.and_more'|translate}}">
                    and more.
                  </span>
                </li>
              </ul>
            </div>
          </div>

          <div class="bl-in-left swiper-slide slide-tres">
            <div class="padding-landing">
              <h1 innerHTML="{{'LANDING_ALLO.discover'|translate}}">
                Discover <b>more reasons to sign up</b>
              </h1>
              <ul class="lista-descubre">
                <li class="viaja_icon">
                  <strong
                    innerHTML="{{'LANDING_ALLO.travel_with_peace_of_mind'|translate}}">
                    Travel with peace of mind
                  </strong>
                  <br>
                  <span innerHTML="{{'LANDING_ALLO.from_app'|translate}}">
                    From app or web book the trip of your dreams anytime,
                    anywhere.
                  </span>
                </li>
                <li class="personalizar_icon">
                  <strong
                    innerHTML="{{'LANDING_ALLO.personalization'|translate}}">
                    Personalization 100%
                  </strong>
                  <br>
                  <span
                    innerHTML="{{'LANDING_ALLO.indicate_and_save_preferences'|translate}}">
                    Indicate and save your flight preferences, hotel, travel
                    companions and much more.
                  </span>
                </li>
                <li class="combinar_icon">
                  <strong innerHTML="{{'LANDING_ALLO.combine'|translate}}">
                    Combine miles and card
                  </strong>
                  <br>
                  <span
                    innerHTML="{{'LANDING_ALLO.redeem_what_you_want'|translate}}">
                    Redeem what you want, when you want, combining your miles
                    with your credit card.
                  </span>
                </li>
                <li class="precio_icon">
                  <strong
                    innerHTML="{{'LANDING_ALLO.best_price_insured'|translate}}">
                    Best price insured*
                  </strong>
                  <br>
                  <span innerHTML="{{'LANDING_ALLO.guarantee'|translate}}">
                    We guarantee the best price for your flights and hotels.
                    Forever!
                  </span>
                </li>
              </ul>
            </div>
          </div>

          <div class="bl-in-left swiper-slide slide-cuatro">
            <div class="padding-landing">
              <h1 innerHTML="{{'LANDING_ALLO.sign_up_steps'|translate}}">
                Sing up now
                <br>
                <b>It's only 3 steps</b>
              </h1>
              <ul class="pasos">
                <li innerHTML="{{'LANDING_ALLO.download_the_app'|translate}}">
                  <span>1</span>
                  <strong>
                    Download
                  </strong>
                  <br>
                  the app on your mobile.
                </li>
                <li innerHTML="{{'LANDING_ALLO.enter_the_email'|translate}}">
                  <span>2</span>
                  <strong>
                    Enter the email
                  </strong>
                  <br>
                  email registered in your bank.
                </li>
                <li
                  innerHTML="{{'LANDING_ALLO.indicate_the_information'|translate}}">
                  <span>3</span>
                  <strong>
                    Indicate the information
                  </strong>
                  <br>
                  of your credit card.
                </li>
              </ul>
              <div class="celular-landing"></div>
            </div>
          </div>

          <div class="bl-in-left swiper-slide slide-cinco">
            <div class="padding-landing">
              <h1 innerHTML="{{'LANDING_ALLO.more_options'|translate}}">
                <strong>
                  More options, more benefits,
                </strong>
                more ways to know the world
              </h1>
              <ul class="opciones">
                <li class="vuelos_icon"
                  innerHTML="{{'LANDING_ALLO.more_of_flights'|translate}}">
                  <strong>
                    More of 70.000 flights
                  </strong>
                  <br>
                  daily to more of 1.700 destinations.
                </li>
                <li class="hoteles_icon"
                  innerHTML="{{'LANDING_ALLO.more_of_cities'|translate}}">
                  <strong>
                    More of 400.000 hotels
                  </strong>
                  <br>
                  in more of 250.000 cities.
                </li>
                <li class="empresas_icon"
                  innerHTML="{{'LANDING_ALLO.more_of_companies'|translate}}">
                  <strong>
                    More of 29.000 companies
                  </strong>
                  <br>
                  car rental in 200 countries.
                </li>
                <li class="actividades_icon"
                  innerHTML="{{'LANDING_ALLO.more_of_activities'|translate}}">
                  <strong>
                    More of 50.000 activities
                  </strong>
                  <br>
                  and tourist attractions.
                </li>
                <span class="text-garantia"
                  innerHTML="{{'LANDING_ALLO.all_best_price_guaranteed'|translate}}">
                  All with the best price guaranteed*.
                </span>
              </ul>
            </div>
          </div>

          <div class="bl-in-left swiper-slide slide-seis">
            <div class="padding-landing">
              <h1
                innerHTML="{{'LANDING_ALLO.with_brand_you_have_the_best'|translate:'{brand:\'' + brand + '\'}'}}">
                With&nbsp;<b>{{brand}}</b>&nbsp;you have the best at all times
              </h1>
              <ul class="lista-imagenes">
                <li>
                  <span class="img_1"></span>
                  <p>
                    <strong
                      innerHTML="{{'LANDING_ALLO.all_your_purchases'|translate}}">
                      All your purchases earn miles
                    </strong>
                    <br>
                    <span
                      innerHTML="{{'LANDING_ALLO.with_each_purchase'|translate}}">
                      With each purchase made with your Miles cards, you will
                      receive miles that you can then redeem.
                    </span>
                  </p>
                </li>
                <li>
                  <span class="img_2"></span>
                  <p>
                    <strong
                      innerHTML="{{'LANDING_ALLO.live_support'|translate}}">
                      Live support
                    </strong>
                    <br>
                    <span
                      innerHTML="{{'LANDING_ALLO.contact_our_experts'|translate}}">
                      From the app or the web, contact our experts 24/7 via
                      chat, phone or video call.
                    </span>
                  </p>
                </li>
                <li>
                  <span class="img_3"></span>
                  <p>
                    <strong
                      innerHTML="{{'LANDING_ALLO.safety_first'|translate}}">
                      Safety first
                    </strong>
                    <br>
                    <span
                      innerHTML="{{'LANDING_ALLO.your_data_will_be_protected'|translate}}">
                      Your data will be protected with the most advanced
                      encryption protocols to avoid fraud.
                    </span>
                  </p>
                </li>
              </ul>
            </div>
          </div>

          <div class="bl-in-left swiper-slide slide-siete">
            <div class="padding-landing">
              <div class="dudas">
                <h1>
                  <strong innerHTML="{{'LANDING_ALLO.doubts'|translate}}">
                    You have doubts?
                  </strong>
                </h1>
                <span innerHTML="{{'LANDING_ALLO.contact_us'|translate}}">
                  Contact us and we will solve your questions.
                </span>
                <span class="chat"
                  innerHTML="{{'LANDING_ALLO.online_chat'|translate}}">
                  Online chat.
                </span>
                <div class="content-flex-dudas">
                  <div class="content-dummy"></div>
                  <div class="download">
                    <p
                      innerHTML="{{'LANDING_ALLO.download_now_our_app'|translate:'{brand:\'' + brand + '\'}'}}">
                      Download now our <b>{{brand}} app:</b>
                    </p>
                    <div class="buttons">
                      <a [href]="link_IOS_URL_STORE" target="_blank">
                        <img class="btn-app"
                          src="assets/images/btn-playstore.svg"
                          alt="btn appstore">
                      </a>
                      <a [href]="link_ANDROID_URL_STORE" target="_blank">
                        <img class="btn-app"
                          src="assets/images/btn-googleplay.svg"
                          alt="btn googleplay">
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </swiper>

        <div [ngClass]="status ? 'show' : 'hide'" (click)="clickEvent()"
          class="bl-in-right bl-drop">
          <div class="bl-tab-experiencias">
            <span innerHTML="{{'LANDING_ALLO.live_the_experience'|translate}}">
              Live <b>here</b> the experience
            </span>
            <div class="btn"></div>
          </div>
          <div class="phone-structure">
            <div class="btn-drop">
              <span
                innerHTML="{{'LANDING_ALLO.live_the_experience'|translate}}">
                Live <b>here</b> the experience
              </span>
            </div>
            <div class="b-iframe iframe-landing">
              <div class="loader-chat" *ngIf="!loaded"
                [ngClass]="dataStore.brand"></div>
              <iframe [style.display]='!loaded ? "none" : "block"'
                [src]="dataStore.iframeAlleLoginUrl | srcSanitizer"
                #alle allow="clipboard-read; clipboard-write"></iframe>
            </div>
          </div>
          <div class="bl-tab-beneficios"></div>
        </div>
      </div>
    </div>
  </section>
</div>