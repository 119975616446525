<ng-template #content let-modal>
  <div [class]="dataStore.brand">
    <app-modal
      [options]="optionsModal"
      [openModal]="openAlertModal"
      (output)="modalListener($event)"
    ></app-modal>
    <div class="modal-header">
      <app-hotel-detail-header></app-hotel-detail-header>
      <button type="button" class="close" aria-label="Close" (click)="close()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body b-hoteles-modales">
      <div class="container">
        <div class="row bl-seccion">
          <div class="col-12 col-md-7">
            <app-hotel-gallery [images]="hotel.images"></app-hotel-gallery>
          </div>
          <div class="col-12 col-md-5">
            <app-selection-detail></app-selection-detail>
          </div>
        </div>

        <div class="row" *ngIf="hotel.hotelObj !== undefined && hotel.hotelObj.options !== undefined">
          <div class="col-12 col-md-7 cards-container">
            <ng-container *ngFor="let option of hotel.hotelObj.options; let j = index; let f2 = first;">
              <app-card
                [product]="option"
                [options]="cardOptions"
                [info]="hotel.info"
                [selectedProductId]="hotel.hotelObj.selectedRoomOption.id"
                (selectProduct)="selectProductListener($event)"
              ></app-card>
            </ng-container>
          </div>
          <div class="col-12 col-md-5">
            <div class="mb-2 button-container mt-1 ps-0">
              <app-hotel-detail-footer
                *ngIf="hotel.hotelObj.amenities && hotel.hotelObj.amenities !== null && hotel.hotelObj.amenities.length > 0"
              ></app-hotel-detail-footer>
              <div class="continue-button">
                <button class="btn-travel" innerHTML="{{ 'HOTEL_DETAIL.continue' | translate }}"
                (click)="selectOption()">Continue to register</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="clearfix"></div>
    <div class="modal-footer mt-2">

    </div>
  </div>
</ng-template>

