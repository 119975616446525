import { Component, OnInit, Input } from '@angular/core';
import { DataStoreService } from '../../../services/data-store.service';
import { environment } from '../../../../environments/environment';
import { keysUniversal, StoreServiceLibrary } from '@wearenovae/novae-core-services';
import { InitService } from '../../../services/init.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-footer-fc-mr',
  templateUrl: './footer-fc-mr.component.html',
  styleUrls: ['./footer-fc-mr.component.scss']
})
export class FooterFcMRComponent implements OnInit {

  @Input() brand: string;

  urlData: string = '';
  dataDecode: any;
  language: any;
  fullYear;


  constructor(
    private dataStore: DataStoreService,
    private store: StoreServiceLibrary,
    private initService: InitService,
    private router: Router,
    ) { }

  ngOnInit() {
    this.fullYear = new Date().getFullYear();
    this.initService.isInitializedBrand.subscribe((isInit) => {
      if (isInit) {
        this.loadUrl();
      }
    });
  }

  loadUrl() {
    const lang = this.store.getItemFromStorage('lang') || 'en';
    // const urlTerms = environment.brands[this.dataStore.brand].terminos_y_condiciones[lang.toUpperCase()];
    let urlTerms = this.store.getJustOneUniversalPropertie(keysUniversal.web_url_terms_and_conditions_en);

    if (lang) {
      if (lang == 'es') {
        urlTerms = this.store.getJustOneUniversalPropertie(keysUniversal.web_url_terms_and_conditions_es);
      }

    }
    if (!this.dataStore.isAlleMobile) {
      this.urlData = urlTerms;
    } else {
      this.urlData = `${urlTerms}`;
    }
    return this.urlData;
  }

  openUrl(url) {
    window.open(url, '_target');
  }

  goToHelp(){
    this.router.navigate(['/help']);
    document.body.scroll({
      top: 0,
      behavior: 'smooth'
    });
  }
}
