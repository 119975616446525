import { CardLayoutOneService } from './../card-layout-one.service';
import {Component, Input, OnInit} from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { Info } from 'src/app/models/flights/info.model';
import { getCurrencySymbol } from '@angular/common';
import { CardPrice } from '../../../models/card-price.model';

@Component({
  selector: 'app-info',
  templateUrl: './info.component.html',
  styleUrls: ['./info.component.scss']
})
export class InfoComponent implements OnInit {

  @Input() info: Info;
  @Input() price: CardPrice;

  translations = [];

  get baggageType() {
    const baggage = this.info.baggage;
    let label = '';
    if (baggage.indexOf('PC') > -1) {
      label = ' ' + this.translations['baggage_pieces'];
    } else if (baggage.indexOf('K') > -1) {
      label = this.translations['baggage_kilograms'];
    }

    const qty = parseInt(baggage);
    if (qty === 1) {
      return this.translations['one_baggage_piece_included'];
    } else if (qty > 1) {
      return qty + label;
    } else {
      return this.translations['hand_baggage_only'];
    }
  }

  get currency() {
    return this.price.currency + ' ' + getCurrencySymbol(this.price.currency, 'narrow');
  }

  get totalMilesPrice() {
    return (this.price.pointsPrice) ? this.price.pointsPrice : 0;
  }

  get totalMoneyPrice() {
    return (!this.price.calculatedPrice && this.price.pointsPrice)
            || this.price.calculatedPrice
            ? this.price.calculatedPrice : this.price.totalPrice;
  }

  constructor(
    public data: DataService,
    private cardLayoutOneService: CardLayoutOneService
  ) { }

  ngOnInit() {
    this.cardLayoutOneService.getTranslations().then(() => {
      this.translations = this.cardLayoutOneService.translations['LAYOUT_ONE'];
    });
  }

}
