<div class="container">
    <!-- Stepper -->
    <app-main-form class="mb-5">
    </app-main-form>


    <div id="car_container" *ngIf="carList !== undefined">

        <!-- CONTENEDOR TITULO -->
        <!-- <div class="row header-filters" attr.id="filter-0">
      <div class="items col-lg-8 offset-lg-2 col-12">
        <p class="title"
          innerHTML="{{ 'RENTACAR_BODY.car_reservation' | translate }}">
          Car reservation
        </p>
      </div>
    </div> -->

        <div class="row">
            <!-- CONTENEDOR PARA FILTROS -->
            <div class="col-lg-2 col-12 mt-lg-3" id="filters">
                <div class="button-filter-container">
                    <button (click)="showFilters = !showFilters" class="btn-click" innerHTML="{{ 'RENTACAR_BODY.filter_result' | translate }}">
            Filter results
          </button>
                    <button mt-raised-button class="btn-click" (click)="showPriceSlider = !showPriceSlider" innerHTML="{{ 'RENTACAR_BODY.adjust_price' | translate }}">
            Adjust price
          </button>
                </div>
                <div [ngClass]="{'show':showFilters}" class="filtros-responsive" id="filters">
                    <app-filters [fullData]="carList" [filtersParamsList]="filtersParamsList" (idsArrayToFilter)="filterListener($event)"></app-filters>
                    <div class="bl-button-fl">
                        <button (click)="showFilters = false" class="btn-click2" innerHTML="{{ 'RENTACAR_BODY.close_filters' | translate }}">
            Close filters
          </button>
                    </div>
                </div>
            </div>


            <!-- CONTENEDOR LISTADO DE RESULTADOS -->
            <div class="col-lg-6 col-12">
                <div class="list-container">
                    <app-card-abstract-list #carListCmp [data]="carList" *ngIf="dataStore.selectedRentaCar" [options]="optionsCard" [selectedProductId]="dataStore.selectedRentaCar.id" (selectProduct)="selectProductListener($event)">
                    </app-card-abstract-list>
                </div>
            </div>

            <!-- CONTENEDOR ONEPOCKET -->
            <div class="col-lg-4 col-12" id="one-pocket">
                <div class="sticky-top" [class.slider-mobile]="(showPriceSlider)">
                    <div class="backdrop" (click)="showPriceSlider = false"></div>
                    <div class="body">
                        <app-slider *ngIf="dataStore.selectedRentaCar" (getPorcentaje)="actualizarPorcentaje($event)" [moneyCurrency]="dataStore.selectedRentaCar.price.currency" [totalCost]="dataStore.selectedRentaCar.price.totalPrice" [token]="dataService.token" [enabled]="dataService.selectedJourney && (dataService.token !== '' && dataService.token !== undefined)">
                        </app-slider>
                        <div class="btn-aceptar">
                            <button class="btn-travel" (click)="showPriceSlider = false" innerHTML="{{ 'RENTACAR_BODY.accept' | translate }}">
                Accept
              </button>
                        </div>
                    </div>
                </div>
                <div class="mb-lg-2 button-container mt-1 ps-0 btn-continuar">
                    <button class="btn-travel" (click)="selectOption()" innerHTML="{{ 'RENTACAR_BODY.select' | translate }}">
            Select
          </button>
                </div>
            </div>

        </div>
    </div>
    <app-modal [options]="optionsModal" [openModal]="openAlertModal" (output)="modalListener($event)"></app-modal>
</div>