<!--START NUEVA SECCION-->

<section class="s-flex morepower">
    <div class="container">
        <h2 *ngIf="brand === 'credomatic'" class="text-gradient" innerHTML="{{'LANDING.'+brand+'.SECTION_THREE.SECTION_MPOWER.title' | translate}}"></h2>
        <p class="text-center" *ngIf="brand === 'credomatic'" innerHTML="{{'LANDING.'+brand+'.SECTION_THREE.SECTION_MPOWER.desc_1' | translate}}"></p>
        <!-- <p class="text-center" *ngIf="brand === 'credomatic'" innerHTML="{{'LANDING.'+brand+'.SECTION_THREE.SECTION_MPOWER.desc_2' | translate}}"></p> -->
        <div class="bl-row">
            <div class="bl-right">
                <img class="imagen-video" alt="" src="../../../../assets/images/{{brand}}-miles/best-travel.png">
            </div>
            <div class="bl-left">
                <h2 class="text-gradient" innerHTML="{{'LANDING.'+brand+'.SECTION_THREE.SECTION_MPOWER.power_wallet_title' | translate}}"><strong></strong></h2>
                <p innerHTML="{{'LANDING.'+brand+'.SECTION_THREE.SECTION_MPOWER.text_intro_1' | translate}}"></p>
            </div>


        </div>
    </div>
</section>
<section class="s-flex morepower">
    <div class="container">
        <h2 *ngIf="brand === 'credomatic'" class="text-gradient" innerHTML="{{'LANDING.'+brand+'.SECTION_THREE.SECTION_MPOWER.rewards_benefists_title' | translate}}"></h2>

        <div class="bl-row">

            <div class="bl-left">
                <h2 *ngIf="brand === 'scotia'" class="text-gradient" innerHTML="{{'LANDING.'+brand+'.SECTION_THREE.SECTION_MPOWER.rewards_benefists_title' | translate}}"><strong></strong></h2>
                <p innerHTML="{{'LANDING.'+brand+'.SECTION_THREE.SECTION_MPOWER.text_intro_2' | translate}}"></p>
                <ul class="items-number">
                    <li><span>{{'LANDING.'+brand+'.SECTION_FIRST.SECTION1.1' | translate}}</span>
                        <p innerHTML="{{'LANDING.'+brand+'.SECTION_THREE.SECTION_MPOWER.rewards_list_1' | translate}}"></p>
                    </li>
                    <li><span>{{'LANDING.'+brand+'.SECTION_FIRST.SECTION1.2' | translate}}</span>
                        <p innerHTML="{{'LANDING.'+brand+'.SECTION_THREE.SECTION_MPOWER.rewards_list_2' | translate}}"></p>
                    </li>
                    <li><span>{{'LANDING.'+brand+'.SECTION_FIRST.SECTION1.3' | translate}}</span>
                        <p innerHTML="{{'LANDING.'+brand+'.SECTION_THREE.SECTION_MPOWER.rewards_list_3' | translate}}"></p>
                    </li>
                    <li><span>{{'LANDING.'+brand+'.SECTION_FIRST.SECTION1.4' | translate}}</span>
                        <p innerHTML="{{'LANDING.'+brand+'.SECTION_THREE.SECTION_MPOWER.rewards_list_4' | translate}}"></p>
                    </li>
                    <li *ngIf="brand === 'scotia'"><span>{{'LANDING.'+brand+'.SECTION_FIRST.SECTION1.5' | translate}}</span>
                        <p innerHTML="{{'LANDING.'+brand+'.SECTION_THREE.SECTION_MPOWER.rewards_list_5' | translate}}"></p>
                    </li>
                </ul>

            </div>

            <div class="bl-right">
                <img class="imagen-video" alt="" src="../../../../assets/images/{{brand}}-miles/rewards-benefits.png">
            </div>
        </div>
    </div>
</section>

<!--END NUEVA SECCION-->