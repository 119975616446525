import { ActivityBookingRulesRequest } from './../models/new-activity/activity-booking-rules-request.model';
import { ActivityResult } from 'src/app/models/new-activity/activity-availability-response.model';
import { Observable } from 'rxjs';
import { DataService } from 'src/app/services/data.service';
import { SearchedService } from './../component/searched/searched.service';
import { ProductHandlerService } from './product-handler.service';
import { EProduct } from './../component/searched/models/searched.model';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { HotelBookingRulesRequest } from '../models/new-hotel/hotel-booking-rules-request.model';
import { HotelContentResponse } from '../models/hotel/response/hotel-content-response.model';
import { HotelAvailabilityRequest } from '../models/hotel/request/hotel-availability-request.model';
import { DataStoreService } from './data-store.service';
import { HotelAvailabilityOptions } from '../models/new-hotel/hotel-availability-response.model';
import { FlightAvailabilityRequest } from '../models/flights/flightavailabilityrequest.model';
import { CarAvailabilityRequest } from '../pages/home/search/search-cars/search-cars.component';
import { RentacarAvailabilityOption } from '../models/new-rentacar/rentacar-availability-option.model';
import { RentacarBookingRulesRequest } from '../models/new-rentacar/rentacar-booking-rules-request.model';
import { IActivityRequestParameters } from '../models/activities/response/activity-availability.model';
import { LanguageService } from '../component/language/language.service';
import { StoreServiceLibrary } from '@wearenovae/novae-core-services';
import { keysUniversal } from 'src/app/resources/keys.resources';

@Injectable({
  providedIn: 'root'
})
export class RequestHandlerService {

  keyURL = sessionStorage.getItem('key');

  private middlewareUrl: string;
  public availabilityUrl: string;
  public contentUrl: string;
  public bookingRulesUrl: string;

  public availabilityRequest: HotelAvailabilityRequest | FlightAvailabilityRequest | CarAvailabilityRequest | IActivityRequestParameters;

  public bookingRulesResponse: any;

  private productType: EProduct;

  constructor(
    private httpClient: HttpClient,
    private dataStore: DataStoreService,
    private dataService: DataService,
    private productHandlerService: ProductHandlerService,
    private searchedService: SearchedService,
    private languageService: LanguageService,
    public storeServiceLibrary: StoreServiceLibrary
  ) {
    if (this.keyURL === 'localhost') {
      this.middlewareUrl = '/api/';
    } else {
      this.middlewareUrl = this.storeServiceLibrary.getJustOneUniversalPropertie(keysUniversal.middleware_url);
    }
  }

  setProductVariables(productType: EProduct) {
    this.productType = productType;
    switch (this.productType) {
      case EProduct.HOTELS:
        this.availabilityUrl = 'hotels/availability';
        this.contentUrl = 'hotels/content';
        this.bookingRulesUrl = 'hotels/booking-rules';
        this.availabilityRequest = this.dataStore.hotelDataSearch;
        break;
      case EProduct.FLIGHTS:
        this.availabilityUrl = 'flights/availability';
        this.contentUrl = null;
        this.bookingRulesUrl = null;
        this.availabilityRequest = this.dataService.flightAvailabilityRequest;
        break;
      case EProduct.CARS:
        this.availabilityUrl = 'rentacar/getAvailability';
        this.contentUrl = null;
        this.bookingRulesUrl = 'rentacar/bookingRules';
        this.availabilityRequest = this.dataStore.carDataSearch;
        break;
      case EProduct.ACTIVITIES:
        this.availabilityUrl = 'activities/availability';
        this.contentUrl = null;
        this.bookingRulesUrl = 'activities/getBookingRules';
        this.availabilityRequest = this.dataService.activityRequestParameters;
        break;
    }
  }

  get bookingRulesRequest(): HotelBookingRulesRequest | RentacarBookingRulesRequest | ActivityBookingRulesRequest {
    let product: HotelAvailabilityOptions | RentacarAvailabilityOption | ActivityResult;
    let searched: any;
    switch (this.productType) {
      case EProduct.HOTELS:
        product = (this.productHandlerService.selectedProduct as HotelAvailabilityOptions);
        searched = this.searchedService.getSearchedHotel();
        return {
          bookingRulesRequest: {
            hotelCode: {
              code: product.hotelCode
            },
            hotelOption: {
              ratePlancode: product.selectedOption.ratePlanCode
            },
            hotelSearch: {
              checkIn: product.checkIn,
              checkOut: product.checkOut,
              destinationCode: this.dataStore.hotelDataSearch.destinationCode
            },
            language: this.languageService.systemLanguajeChange.value
          }
        };
        break;
      case EProduct.CARS:
        product = (this.productHandlerService.selectedProduct as RentacarAvailabilityOption);
        searched = this.searchedService.getSearchedCar();
        return {
          ratePlanCode: product.ratePlanCode,
          providerCurrency: product.price.providerCurrency,
          providerTotalPrice: product.price.providerTotalPrice,
          language: this.languageService.systemLanguajeChange.value
        };
        break;
      case EProduct.ACTIVITIES:
        product = (this.productHandlerService.selectedProduct as ActivityResult);
        searched = this.searchedService.getSearchedActivity();
        return {
          ratePlanCode: product.ratePlanCode,
          targetCurrency: product.selectedOption.selectedDate.price.currency,
          providerCurrency: product.selectedOption.selectedDate.price.providerCurrency,
          language: this.languageService.systemLanguajeChange.value
        };
        break;
    }

  }

  sendAvailabilityRequest() {
    return this.httpClient.post<any>(this.middlewareUrl + this.availabilityUrl, this.availabilityRequest);
  }

  sendBookingRulesRequest(request: HotelBookingRulesRequest | RentacarBookingRulesRequest) {
    return new Promise<any>((resolve, reject) => {
      if (this.bookingRulesUrl) {
        this.httpClient.post<any>(this.middlewareUrl + this.bookingRulesUrl, request).subscribe(res => {
          this.bookingRulesResponse = res;
          resolve(this.bookingRulesResponse);
        }, err => {
          reject(err);
        });
      } else {
        resolve({});
      }
    });
  }

  sendContentRequest(req) {
    if (this.contentUrl) {
      return this.httpClient.post<HotelContentResponse[]>(this.middlewareUrl + this.contentUrl + '?ids=' + req, {});
    } else {
      return new Observable<any>(observer => {
        observer.error();
      });
    }
  }

}
