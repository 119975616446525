<div class="row">
    <div class="col-12">
        <h2 innerHTML="{{'PURCHASE_SUMARY.BENEFIT.benefits' | translate}}">
            Beneficios</h2>
    </div>
</div>
<div class="row">
    <div class="col-12"><strong>{{  benefit.productName }} -
            {{ benefit.price.totalPrice }} {{ benefit.productCurrency }} anual
        </strong></div>
</div>
<div class="row" style="padding-bottom: 1vh;">
    <div class="col-12"><strong>{{ translationsBenefit['beneficiaries'] }}:
        </strong>{{ beneficiaries }}</div>
</div>
<div class="row"
    style="border-top: 2px dashed var(--color-line); padding-top: 1vh; font-size: 15px;">
    <div class="col-6">
        <div style="text-align: center; font-size: 1em;">
            <strong
                innerHTML="{{'PURCHASE_SUMARY.BENEFIT.start_date' | translate}}">
                Inicio Vigencia:</strong>
        </div>
        <div>
            <mat-icon aria-hidden="false"
                style="color:var(--primary-color); text-align: center; font-size: 4em; width: 100%;">
                calendar_today
            </mat-icon>
        </div>
        <div style="text-align: center; font-size: 0.8em;">
            <strong>
                {{ benefitInitDate }}
            </strong>
        </div>
    </div>
    <div class="col-6" style="border-left: 1px solid var(--color-line);">
        <div style="text-align: center; font-size: 1em;">
            <strong
                innerHTML="{{'PURCHASE_SUMARY.BENEFIT.end_date' | translate}}">
                Fin Vigencia:</strong>
        </div>
        <div>
            <mat-icon aria-hidden="false"
                style="color:var(--primary-color); text-align: center; font-size: 4em; width: 100%;">
                calendar_today
            </mat-icon>
        </div>
        <div style="text-align: center; font-size: 0.8em;">
            <strong>
                {{ benefitEndDate }}
            </strong>
        </div>
    </div>
</div>