import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';

import { DataService } from 'src/app/services/data.service';
import { DataStoreService } from '../../services/data-store.service';
import { EProduct } from '../../component/searched/models/searched.model';

@Injectable({
  providedIn: 'root'
})
export class FixedRatesGuard implements CanActivate {

  validateTokenCheck: boolean = false;

  constructor(public data: DataService,
    public dataStore: DataStoreService,
    private router: Router) {

  }

  canActivate() {
    if (this.data.token) {
      return true;
    } else {
      this.data.productSelected = EProduct.HOME;
      this.router.navigateByUrl('home');
      return false;
    }
  }


}
