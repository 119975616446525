<div *ngIf="type !== EProduct.BENEFITS && type !== EProduct.CARS">
<div [ngClass]="{buyerInputClickClose: show}" (click)="showFormFn()">
</div>
<mat-form-field>
  <input matInput (click)="showFormFn()" placeholder="{{buyerPlaceholder}}" readonly>
</mat-form-field>
<app-passengers *ngIf="type === EProduct.FLIGHTS" [ngClass]="{show: !show}"></app-passengers>
<app-guests  *ngIf="type === EProduct.HOTELS" [ngClass]="{show: !show}"></app-guests>
<app-driver *ngIf="type === EProduct.CARS" [ngClass]="{show: !show}"></app-driver>
<!--<app-beneficiary *ngIf="type === EProduct.BENEFITS" [ngClass]="{show: !show}"></app-beneficiary>-->
<app-acitvity-passenger *ngIf="type === EProduct.ACTIVITIES" [ngClass]="{show: !show}"></app-acitvity-passenger>
</div>

<div class="input driver" *ngIf="type === EProduct.CARS">
  <label>{{'SEARCH_CARS.drivers_age'|translate}}</label>
  <mat-form-field>
    <mat-label innerHTML="{{'SEARCH_CARS.select_age'|translate}}">
      Select age
    </mat-label>
    <mat-select >
      <mat-option *ngFor="let age of driverAges" [value]="age">
        {{age}}
      </mat-option>
    </mat-select>
    <!--<mat-error *ngIf="validatorC"
               innerHTML="{{'SEARCH_CARS.select_age'|translate}}">
      Select age
    </mat-error>-->
  </mat-form-field>
</div>
