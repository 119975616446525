import { Component, OnInit, AfterViewInit } from '@angular/core';
import { DataService } from '../../../services/data.service';
import { ActionEventService } from '../../../services/action-event.service';
import { InitService } from '../../../services/init.service';
import { EProduct } from '../../searched/models/searched.model';
import { StoreServiceLibrary } from '@wearenovae/novae-core-services';
import { keysUniversal } from 'src/app/resources/keys.resources';
import { DataStoreService } from 'src/app/services/data-store.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-error-page',
  templateUrl: './error-page.component.html',
  styleUrls: ['./error-page.component.scss']
})
export class ErrorPageComponent implements OnInit, AfterViewInit {

  get brand() {
    return this.dataStore.brand;
  }

  theUri: string;

  constructor(private init: InitService,
    private dataStore: DataStoreService,
    private route: ActivatedRoute,
    private actionEventService: ActionEventService,
    private storeLibrary: StoreServiceLibrary) {
    
      
  }

  ngOnInit(): void {
    this.validateParameters();
  }

  ngAfterViewInit() {
  }

  validateParameters() {
    this.route.queryParams
      .subscribe(params => {
        this.theUri = params.uri;
      });
  }
}
