import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormArray, Validators } from '@angular/forms';
import { LanguageService } from 'src/app/component/language/language.service';
import { TranslateService } from '@ngx-translate/core';
import { BuyerComponent } from '../buyer.component';


@Component({
  selector: 'app-acitvity-passenger',
  templateUrl: './acitvity-passenger.component.html',
  styleUrls: ['./acitvity-passenger.component.scss']
})
export class AcitvityPassengerComponent implements OnInit {

  /**
   * valor en forma string
   */
  valueString: string;

  translations = [];

  ages = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9,
    10, 11, 12, 13, 14, 15, 16, 17, 18, 19,
    20, 21, 22, 23, 24, 25, 26, 27, 28, 29,
    30, 31, 32, 33, 34, 35, 36, 37, 38, 39,
    40, 41, 42, 43, 44, 45, 46, 47, 48, 49,
    50, 51, 52, 53, 54, 55, 56, 57, 58, 59,
    60, 61, 62, 63, 64, 65, 66, 67, 68, 69,
    70, 71, 72, 73, 74, 75, 76, 77, 78, 79,
    80, 81, 82, 83, 84, 85, 86, 87, 88, 89,
    90, 91, 92, 93, 94, 95, 96, 97, 98, 99];

  /**
   * cuando es true muestra el panel
   */


  @ViewChild('inputAges') inputAges;

  form = this.fb.group({
    persons: this.fb.array([])
  });

  min = 1;
  /**
   * texto que reemplaza la palabra "persona"
   */
  public personText = 'Person';

  /**
   * retorna todas las personas
   */
  getPersons() {
    return this.form.get('persons') as FormArray;
  }

  /**
   * retorna las personas que le cargaron la edad
   */
  getPersonsWithAge(): FormArray {
    const persosnWithAge = new FormArray([]);
    this.getPersons().controls.forEach((person) => {
      const age = person.get('age').value;
      if (!isNaN(age) && age !== '') {
        persosnWithAge.push(person);
      }
    });

    return persosnWithAge;
  }

  /**
   * emitir valores seleccionados
   */
  onApply() {
    this.buyer.onApply(this.form);
    /*this.apply.emit(this.getPersonsWithAge());*/
    this.calculateValueString();

  }

  /**
   * value del form como string
   */
  calculateValueString() {
    let valueText = '';
    valueText += this.personText;
    const personsWithAge = this.getPersonsWithAge();
    if (personsWithAge.controls.length === 0) {
      valueText += ' (-)';
    } else {
      personsWithAge.controls.forEach(person => {
        valueText += ' (' + person.get('age').value + ')';
      });

      // si hay una sola persona y tiene un año (la que va a hacer la actividad)
      if (personsWithAge.controls.length === 1 && +personsWithAge.controls[0].get('age').value === 1) {
        valueText += ' ' + this.translations['year_old'];
      } else {
        valueText += ' ' + this.translations['years_old'];
      }
    }

    this.valueString = valueText;
  }

  constructor(
    private fb: FormBuilder,
    private languageService: LanguageService,
    private translateService: TranslateService,
    private buyer: BuyerComponent
  ) { }

  ngOnInit() {
    this.completeToMin();
    this.languageService.systemLanguajeChange.subscribe((langeage) => {
      if (langeage) {
        this.getTranslations();
      }
    });
  }

  private getTranslations() {
    this.translateService.get([
      'INPUT_AGES'
    ]).subscribe((trans) => {
      this.translations = trans['INPUT_AGES'];
    });
  }

  /**
   * si hay menos que el mínimo agregamos personas hasta llegar al mínimo
   */
  private completeToMin() {
    let defaultNumberOfPersons = 1;
    if (this.min !== undefined && this.min > defaultNumberOfPersons) {
      defaultNumberOfPersons = this.min;
    }
    while (this.getPersons().length < defaultNumberOfPersons) {
      this.addPerson();
    }
  }

  /**
   * añadir una persona
   */
  addPerson(): void {
    const person = this.fb.group({
      id: this.getPersons().length,
      age: ['', [
        Validators.required,
      ]]
    });

    this.getPersons().push(person);
  }

  /**
   * remover una persona del form
   */
  removePersonByIndex(index, event) {
    event.stopPropagation();
    try {
      const persons = this.getPersons();
      const newPersonsnArray = persons.removeAt(index);
      this.form.get('persons').setValue(newPersonsnArray);
    } catch (e) { }
  }

  /**
   * scroll panel to bottom
   */
  scrollToBottom(el: HTMLElement) {
    setTimeout(() => {
      el.scrollTop = el.scrollHeight;
    });
  }
}
