import { environment } from 'src/environments/environment';
import { IRegister } from './model/register.model';
import { DataService } from 'src/app/services/data.service';
import { SmartProfileService } from './../../../services/smart-profile.service';
import { Injectable } from '@angular/core';
import { DataStoreService } from 'src/app/services/data-store.service';
import {
  StoreServiceLibrary,
  AuthService,
  UserData,
  AccountSumaryDto,
  IdentificationDto,
  AddtionalInformationDto,
  SecurityObject,
  AuthRegisterRequest, AuthGetAccount2Response, ValidatePinRequest, AuthGetAccount2Request, ValidatePinResponse, AuthUpdateAccountRequest, ResetPasswordResponse
} from '@wearenovae/novae-core-services';
import { BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { registerLocaleData } from '@angular/common';
import { IPreRegisterStep2 } from './model/pre-register-step2.model';
import { IPreRegisterStep3 } from './model/pre-register-step3.model';
import { IPreRegisterStep1 } from './model/pre-register-step1.model';
import { IRegisterCard } from './model/card.model';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { ActionEventService } from '../../../services/action-event.service';
import { LoginComponent } from './login.component';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  useJwtToken: boolean;


  get brand() {
    return this.dataStore.brand;
  }

  get channelId() {
    return this.dataStore.channelID.toString();
  }

  get ccBin() {
    return environment.brands[this.brand].ccBin;
  }

  loginChange = new BehaviorSubject<boolean>(this.isLogged());

  constructor(
    private httpClient: HttpClient,
    private dataStore: DataStoreService,
    private dataService: DataService,
    private _storeLibrary: StoreServiceLibrary,
    private _authService: AuthService,
    private _smartProfileService: SmartProfileService,
    private translate: TranslateService,
    private router: Router,
    private activateRouter: ActivatedRoute,
    private actionEventService: ActionEventService,
    private dialog: MatDialog

  ) {
    this.useJwtToken = this.dataService.getJwtUse();
  }

  /**
   * Validar mail y hacer Login
   */
  login = (email, pass, language) => {
    this.setUserData(email, pass, language);
    return new Promise<any>((resolve, reject) => {
      this._authService.validateMail(email, this.channelId, this.useJwtToken).toPromise().then(res => {
        if (res.accountResultDto.accountId) {
          this.sendLogin().then(response => {
            resolve(response);
          }).catch(err => {
            reject(err);
          });
        } else {
          reject();
        }
      }).catch(err => {
        reject(err);
      });
    });
  }

  /**
   * Enviar Login, set data de SmartProfile
   */
  sendLogin = () => {
    return new Promise<any>((resolve, reject) => {
      this._authService.login(this.channelId, this.useJwtToken).toPromise()
        .then(res => {
          this.saveToken(res.authenticationToken);
          this.setSmartProfileData(res.authenticationToken).then(() => {
            this.loginChange.next(true);
            resolve(res);
          });
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  /**
   * Set data de SmartProfile
   */
  setSmartProfileData = (token) => (this._smartProfileService.getAccountMethod(token));

  /**
   * Set data de SmartProfilePereferences
   */

  /**
   * Guardar Token
   */
  saveToken = (token) => (this.dataService.saveToken(token));

  setUserData(email, pass, language) {
    const userData: UserData = new UserData();
    userData.password = pass;
    userData.language = language;
    userData.accountSumaryDto = new AccountSumaryDto();
    userData.accountSumaryDto.emailaddress = email;
    this._storeLibrary.setUserData(userData);
  }

  register(data: IRegister) {
    this.storeRegisterData(data);
    const userData = this._storeLibrary.getUserData();
    let request: any;

    if (userData.bin) {
      request = this.converteruserDataToRegisterUserRequest(userData, userData.bin, data.register, data.accountId);
    } else {
      request = this.converteruserDataToRegisterUserRequest(userData, this.ccBin, data.register, data.accountId);
    }
    return new Promise<any>((resolve, reject) => {
      this.sendRegister(request).then(res => {
        const token = res.registerAuthResponse.authenticationToken;
        userData.authenticationToken = res.registerAuthResponse.authenticationToken;
        this._storeLibrary.setUserData(userData);
        this.saveToken(token);
        this.setSmartProfileData(token).then(() => {
          resolve({ username: data.firstName });
        });
      }).catch(err => {
        reject(err);
      });
    });
  }

  validatePin(pin: string, certificate: string) {
    let reqValidatePin = {
      tokenToValidate: pin,
      certificateNumber: certificate,
      language: 'en'
    };

    return this._authService.validatePin(reqValidatePin, this.useJwtToken);
  }

  updateProfile(data: IRegister) {
    this.storeRegisterData(data);
    let userData = this._storeLibrary.getUserData();
    return new Promise<any>((resolve, reject) => {
      this.sendUpdate().then(res => {
        resolve(res);
      }).catch(err => {
        reject(err);
      });
    });
  }

  /**
   * Enviar Login, set data de SmartProfile
   */
  sendRegister(request: AuthRegisterRequest) {
    return new Promise<any>((resolve, reject) => {
      this._authService.registerUserV2(request, null, this.useJwtToken).toPromise().then(res => {
        resolve(res);
      }).catch(err => {
        reject(err.message);
      });
    });
  }


  validateChannelIdAndEmail(email: string, channelid) {
    let userData: UserData = this._storeLibrary.getUserData();
    if (userData['accountSumaryDto']) {
      userData.accountSumaryDto.emailaddress = email;
      this._storeLibrary.setUserData(userData);
    } else {
      userData.accountSumaryDto = new AccountSumaryDto();
      userData.accountSumaryDto.emailaddress = email;
      this._storeLibrary.setUserData(userData);
    }
    return new Promise<any>((resolve, reject) => {
      this._authService.getAccount2(channelid, this.useJwtToken).toPromise().then(res => {
        resolve(res);
      }).catch(err => {
        reject(err.message);
      });
    });
  }

  /**
   * Enviar Login, set data de SmartProfile
   */
  sendUpdate() {
    return new Promise<any>((resolve, reject) => {
      this._authService.updateAccount(this.useJwtToken).toPromise().then(res => {
        resolve(res);
      }).catch(err => {
        reject(err.message);
      });
    });
  }

  storeUserData(firstName, lastName, email) {
    const userData: UserData = new UserData;

    userData.accountSumaryDto = new AccountSumaryDto();
    userData.accountSumaryDto.firstName = firstName;
    userData.accountSumaryDto.lastName1 = lastName;
    userData.accountSumaryDto.emailaddress = email;

    this._storeLibrary.setUserData(userData);
  }

  storeRegisterData(data: IRegister) {

    const userData: UserData = this._storeLibrary.getUserData();

    if (!userData.accountSumaryDto) {
      userData.accountSumaryDto = new AccountSumaryDto();
    }

    userData.accountSumaryDto.emailaddress = data.email;
    userData.password = data.password;

    userData.accountSumaryDto.firstName = data.firstName;
    userData.accountSumaryDto.lastName1 = data.lastName;
    userData.accountSumaryDto.streetAddress = data.address;

    userData.accountSumaryDto.identificationDto = new IdentificationDto();
    userData.accountSumaryDto.identificationDto.typeId = data.docType;
    userData.accountSumaryDto.identificationDto.number = data.numberId;

    userData.accountSumaryDto.addtionalInformationDto = new AddtionalInformationDto();
    userData.accountSumaryDto.addtionalInformationDto.cityName = data.cityName;
    userData.accountSumaryDto.addtionalInformationDto.isoCountry = data.countryIso;
    userData.accountSumaryDto.addtionalInformationDto.phoneNumber = data.phone;

    userData.accountSumaryDto.title = '';
    userData.accountSumaryDto.dateBirthday = '';

    userData.accountSumaryDto.phoneNumber = data.phone;
    userData.accountSumaryDto.indicativeCode = data.countryIsoPhone;
    userData.termsAndConditions = true;
    userData.language = data.language;

    if (data.certificateNumber) {
      userData.certificateNumber = data.certificateNumber;
    }

    this._storeLibrary.setUserData(userData);
  }

  converteruserDataToRegisterUserRequest(userData: UserData, binCard: string, register: boolean, accountId: string = undefined): AuthRegisterRequest {

    const request: AuthRegisterRequest = new AuthRegisterRequest();

    const creditCard = {
      bin: binCard
    };

    if (userData.certificateNumber) {
      request.certificateNumber = userData.certificateNumber;
    }

    request.register = register;

    request.channelId = this.channelId;

    if (userData && userData.accountSumaryDto) {

      if (!userData.accountSumaryDto.addtionalInformationDto.isoCountry) {
        userData.accountSumaryDto.addtionalInformationDto.isoCountry = 'JM';
      }
      if (!userData.accountSumaryDto.addtionalInformationDto.cityName) {
        userData.accountSumaryDto.addtionalInformationDto.cityName = 'Kingston';
      }
      if (!userData.accountSumaryDto.addtionalInformationDto.phoneNumber) {
        userData.accountSumaryDto.addtionalInformationDto.phoneNumber = '10000000000';
      }
      if (!userData.accountSumaryDto.addtionalInformationDto.state) {
        userData.accountSumaryDto.addtionalInformationDto.state = ' ';
      }
      if (!userData.accountSumaryDto.addtionalInformationDto.zipCode) {
        userData.accountSumaryDto.addtionalInformationDto.zipCode = '1001';
      }
      //
      if (!userData.accountSumaryDto.identificationDto.typeId) {
        userData.accountSumaryDto.identificationDto.typeId = 'PAS';
      }
      if (!userData.accountSumaryDto.identificationDto.number) {
        userData.accountSumaryDto.identificationDto.number = String(Math.round(Math.random() * 9999999999));
      }
      if (!userData.accountSumaryDto.streetAddress) {
        const now = new Date();
        userData.accountSumaryDto.streetAddress = `ADDRESS${now.getDate()}`;
      }

      if (userData.accountSumaryDto.identificationDto) {
        request.identificationType = 'LID';
        request.identificationNumber = userData.accountSumaryDto.identificationDto.number;
      }
      if (userData.accountSumaryDto.addtionalInformationDto) {
        request.phoneNumber = userData.accountSumaryDto.addtionalInformationDto.phoneNumber;
        //
        request.state = ' ';
        if (userData.accountSumaryDto.addtionalInformationDto.state) {
          request.state = userData.accountSumaryDto.addtionalInformationDto.state;
        }
        //
        request.country = 'US';
        request.city = userData.accountSumaryDto.addtionalInformationDto.cityName;
        request.zipCode = userData.accountSumaryDto.addtionalInformationDto.zipCode;
      }
      request.firstName = userData.accountSumaryDto.firstName;
      //
      request.middleName = ' ';
      if (userData.accountSumaryDto.middleName) {
        request.middleName = userData.accountSumaryDto.middleName;
      }
      //
      request.lastName = userData.accountSumaryDto.lastName1;
      //
      request.lastName2 = ' ';
      if (userData.accountSumaryDto.middleName) {
        request.lastName2 = userData.accountSumaryDto.lastName2;
      }
      //
      request.emailAddress = userData.accountSumaryDto.emailaddress;
      request.streetAddress = userData.accountSumaryDto.streetAddress;

    }

    request.accountId = accountId;
    request.password = userData.password;
    request.termsAndConditions = (!userData.termsAndConditions ? 'false' : 'true');
    request.language = (!userData.language ? 'en' : 'es');
    request.creditCard = creditCard;
    request.security = SecurityObject.getSecurityObject();
    request.validateToken = false;
    return request;
  }

  async logout() {
    this.saveToken('');
    // await this.dataService.saveSmartProfile(new AuthGetAccount2Response());
    await this._smartProfileService.unloadSmartProfile();
    await this.router.navigate(['travel']);
    sessionStorage.removeItem('tabActive');
    sessionStorage.removeItem('activityRequestParameters');
    this.loginChange.next(false);
    this.isLogged()
  }

  isLogged() {
    return !!this.dataService.token;
  }

  getUsername() {
    return this.dataService.smartProfile.accountResultDto.accountSumaryDto.firstName;
  }

  /**
   * validar token
   * retorna true si es válido, o false si es inválido (o no se puede validar)
   */
  validateUserToken(): Promise<any> {
    // FIXME: cambiar por el llamado a backend para validar el token
    return new Promise<boolean>((resolve) => {
      setTimeout(() => {
        
        resolve(true);
      }, 50);
    });
    //return this.httpClient.get<any[]>(environment.middlewareurl + 'validateUserToken').toPromise();
  }

  validateEmail(register: IPreRegisterStep1): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      const req: AuthGetAccount2Request = new AuthGetAccount2Request();
      req.channelId = +this.channelId;
      req.language = register.language;
      req.email = register.email;
      req.validateUser = false;
      req.security = SecurityObject.getSecurityObject();
      this._authService.getAccount2V2(req, null, this.useJwtToken).toPromise().then(res => {
        const accountSummaryDto = res.accountResultDto.accountSumaryDto;
        // Si el usuario tiene nombre y apellido es porque fue pre-cargado
        // Si es Igual el status, va a volver a enviar el correo.
        resolve(res);
      }).catch(err => {
        return reject(false);
      });
    });
  }



  validateCard(card: IPreRegisterStep2): Promise<any> {
    return new Promise<AuthGetAccount2Response>((resolve, reject) => {
      const req: AuthGetAccount2Request = new AuthGetAccount2Request();
      req.channelId = +this.channelId;
      req.language = card.language;
      req.email = card.email;
      req.bin = card.bin;
      req.product = card.product;
      req.validateUser = false;
      req.security = SecurityObject.getSecurityObject();
      this._authService.getAccount2V2(req, null, this.useJwtToken).toPromise().then(res => {
        const accountSummaryDto = res.accountResultDto.accountSumaryDto;
        // Si el usuario tiene nombre y apellido es porque fue pre-cargado
        if (accountSummaryDto.status === '8'
          && res.accountResultDto.accountId !== undefined
          && res.accountResultDto.accountId !== null
          && res.accountResultDto.accountId.length > 0) {
          req.validateUser = true;
          this._authService.getAccount2V2(req, null, this.useJwtToken).toPromise().then(res => {
            return resolve(res)
          }).catch(err => {
            return reject(false);
          });
        } else {
          return reject(false);
        }
      }).catch(err => {
        return reject(false);
      });
    });
  }

  validateCardAndPerson(person: IPreRegisterStep3): Promise<any> {
    return new Promise<AuthGetAccount2Response | any>((resolve, reject) => {
      const req: AuthGetAccount2Request = new AuthGetAccount2Request();
      req.channelId = +this.channelId;
      req.language = person.language;
      req.email = person.email;
      req.bin = person.bin;
      req.product = person.product;
      req.name = person.firstName
      req.lastName = person.lastName
      req.validateUser = false;
      req.security = SecurityObject.getSecurityObject();
      this._authService.getAccount2V2(req, null, this.useJwtToken).toPromise().then(res => {
        const accountSummaryDto = res.accountResultDto.accountSumaryDto;
        // Si el usuario tiene nombre y apellido es porque fue pre-cargado
        if (accountSummaryDto.status === '8'
          && res.accountResultDto.accountId !== undefined
          && res.accountResultDto.accountId !== null
          && res.accountResultDto.accountId.length > 0) {
          req.validateUser = true;
          this._authService.getAccount2V2(req, null, this.useJwtToken).toPromise().then(res => {
            return resolve(res)
          }).catch(err => {
            return reject(false);
          })
        } else {
          return reject(false);
        }
      }).catch(err => {
        return reject(false);
      });
    });
  }

  validateConfirmation(token: string, certificateNumber: string, language: string): Promise<ValidatePinResponse> {
    return new Promise<ValidatePinResponse>((resolve, reject) => {
      const validatePin = new ValidatePinRequest();
      validatePin.tokenToValidate = token;
      validatePin.certificateNumber = certificateNumber;
      validatePin.language = language;
      this._authService.validatePin(validatePin, this.useJwtToken).toPromise().then((data: ValidatePinResponse) => {
        resolve(data);
      }).catch((err: string) => {
        reject(err);
      });
    });
  }


  resetPassword(channel: string, token: string, newPwd: string, confirmPwd: string): Promise<ResetPasswordResponse> {
    return new Promise<ResetPasswordResponse>((resolve, reject) => {
      this._authService.resetPassword(channel, token, newPwd, confirmPwd, this.useJwtToken
        ).toPromise().then((data: ResetPasswordResponse) => {
        resolve(data);
      }).catch((err: string) => {
        reject(err);
      });
    });
  }


  /**
 * @method validateRegisterParams
 * le envia el obj de registro a LoginComponent para su registro.
 */
  validateRegisterParams() {
    this.activateRouter.queryParams.subscribe((params) => {
      if (params.p && params.email) {
        let channel = '';
        if (params.channelid) {
          channel = params.channelid;
        } else {
          if (environment.brands[this.dataStore.brand].channelId) {
            channel = environment.brands[this.dataStore.brand].channelId;
          }
        }
        const objUserRegister = {
          idC: params.idC,
          pin: params.p,
          name: decodeURI(params.name),
          lname: decodeURI(params.lname),
          email: params.email,
          channelid: channel,
          aid: params.aid
        };
        this.actionEventService.sendParamsUserRegister(objUserRegister);
        this.dialog.open(LoginComponent, {
          panelClass: 'login-dialog',
          data: objUserRegister
        });
      }
    });
  }
}
