import { Component, OnInit, ElementRef, Input, AfterContentInit } from '@angular/core';
import { DataStoreService } from '../../../services/data-store.service';
import { StoreServiceLibrary } from '@wearenovae/novae-core-services';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-section-two-scotia',
  templateUrl: './section-two-scotia.component.html',
  styleUrls: ['./section-two-scotia.component.scss']
})
export class SectionTwoScotiaComponent implements OnInit, AfterContentInit {

  @Input() brand: string;
  urlsVideos: any = environment.brands[this.dataStore.brand].videosLandingEN.sectionTwo;

  constructor(private dataStore: DataStoreService,
    private store: StoreServiceLibrary,
    private translate: TranslateService,
    private el: ElementRef) { }

  ngOnInit() {
    this.validateLanguaje();
    this.changeLanguaje();
  }

  ngAfterContentInit() {
    this.changeLanguaje();

  }

  changeLanguaje() {
    this.translate.onLangChange.subscribe((data) => {
      if (data.lang === 'es') {
        this.urlsVideos = environment.brands[this.dataStore.brand].videosLandingES.sectionTwo;
      } else {
        this.urlsVideos = environment.brands[this.dataStore.brand].videosLandingEN.sectionTwo;
      }
    });
  }




  openSection() {
    const tag = this.el.nativeElement.querySelector('.bl-open');
    const tagBtn = this.el.nativeElement.querySelector('.button');
    tag.classList.toggle('open');
    tagBtn.classList.toggle('active');
  }


  closeSection() {
    const tag = this.el.nativeElement.querySelector('.bl-open');
    const tagBtn = this.el.nativeElement.querySelector('.button');
    tag.classList.remove('open');
    tagBtn.classList.remove('active');
  }

  validateLanguaje() {
    let lang = this.translate.currentLang;
    if (lang === 'es') {
      this.urlsVideos = environment.brands[this.dataStore.brand].videosLandingES.sectionTwo;
    } else {
      this.urlsVideos = environment.brands[this.dataStore.brand].videosLandingEN.sectionTwo;
    }
  }

}
