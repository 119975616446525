import { Component, OnInit, ElementRef, AfterViewInit, ChangeDetectorRef, ViewChild } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { DataStoreService } from '../../services/data-store.service';
import { SmartProfileService } from '../../services/smart-profile.service';
import { ActivatedRoute, Router } from '@angular/router';
import { LanguageService } from '../../component/language/language.service';
import { OcultadorService } from '../home/ocultador.service';
import { InitService } from '../../services/init.service';
import { ActionEventService } from '../../services/action-event.service';
import { TranslateService } from '@ngx-translate/core';
import { StoreServiceLibrary } from '@wearenovae/novae-core-services';
import { LoginService } from '../../container/shared/login/login.service';
import { EProduct } from '../../component/searched/models/searched.model';
import { environment } from '../../../environments/environment';
import { SwiperComponent, SwiperDirective, SwiperModule, SwiperConfigInterface, SwiperPaginationInterface } from 'ngx-swiper-wrapper';

import data from 'src/app/resources/screenAndEventsTracking.json';
import { GoogleAnalytics } from '../../resources/GoogleAnalytics.resource';

declare var window: any;
declare var navigator: any;

@Component({
  selector: 'app-myrewards-salesdeck',
  templateUrl: './myrewards-salesdeck.component.html',
  styleUrls: ['./myrewards-salesdeck.component.scss']
})
export class MyrewardsSalesdeckComponent implements OnInit, AfterViewInit {

  @ViewChild('video1')
  public video1: ElementRef;
  @ViewChild('video2')
  public video2: ElementRef;

  public show_video: boolean;
  public url: string;

  urlsVideos: any = environment.brands[this.dataStoreService.brand].videosLandingEN.salesDeck;
  componentIsLoad = false;
  linkWhatsApp = environment.brands[this.dataStoreService.brand].otherLinksEN.salesDeck.whatsapp.url;
  linkPDF = environment.brands[this.dataStoreService.brand].otherLinksEN.salesDeck.pdf_1.url;
  linkgo2Redeem = environment.brands[this.dataStoreService.brand].otherLinksEN.salesDeck.url_go2redeem.url;

  imgBgMovilesSales = '';
  imgIphoneTops = '';
  imgIphoneSwitch = '';
  lang = false;
  imgPower = `./assets/images/myrewards-miles/iphone-power.png`;


  get brand() {
    return this.dataStoreService.brand;
  }

  public bigSliderConfig: SwiperConfigInterface = {
    direction: 'horizontal',
    slidesPerView: 3,
    breakpoints: {
      991: {
        initialSlide: 1,
        slidesPerView: 3,
        freeMode: false,
        init: false,
        centeredSlides: true,
        allowSlidePrev: false,
        allowSlideNext: false 
      },
      768: {
        initialSlide: 1,
        slidesPerView: 1.5,
        freeMode: false,
        init: true,
        centeredSlides: true,
        autoHeight: true,
        setWrapperSize: true,
        pagination: {
          el: '.swiper-pagination',
        },
      },
      540: {
        initialSlide: 1,
        slidesPerView: 1,
        freeMode: false,
        init: true,
        centeredSlides: true,
        autoHeight: true,
        setWrapperSize: true,
        pagination: {
          el: '.swiper-pagination',
        },
      },
    },
  };

  @ViewChild(SwiperComponent) swiper: SwiperComponent;

  contentCard2 = [
    {
      titulo: 'Miles Care Premium',
      precioUno: '99',
      precioDos: '1,000',
      precioTres: '600',
      precioCuatro: '75',
      precioCinco: '100,000',
      precioSeis: '500'
    },
    {
      titulo: 'Miles Care Exclusive',
      precioUno: '179',
      precioDos: '2,000',
      precioTres: '800',
      precioCuatro: '100',
      precioCinco: '250,000',
      precioSeis: '1,000'
    },
    {
      titulo: 'Miles Care Privilege',
      precioUno: '249',
      precioDos: '3,000',
      precioTres: '2,000',
      precioCuatro: '200',
      precioCinco: '2,000,000',
      precioSeis: '3,000'
    }
  ];

  constructor(
    private dataService: DataService,
    private dataStoreService: DataStoreService,
    private smartProfile: SmartProfileService,
    private route: ActivatedRoute,
    private languageService: LanguageService,
    public ocultadorService: OcultadorService,
    private initService: InitService,
    private actionEventService: ActionEventService,
    private translate: TranslateService,
    private store: StoreServiceLibrary,
    private router: Router,
    private loginService: LoginService,
    private el: ElementRef,
    private cdRef: ChangeDetectorRef,
  ) {

  }
  ngAfterViewInit(): void {
    this.componentIsLoad = true;
    this.changeLanguaje();
  }

  ngOnInit() {
    // this.trackingManager.setCurrentScreen('/home', data.WELCOME.welcomeScreen);

    this.cdRef.detectChanges();
    this.getBrandRoute();
    this.aboutLoadNav();
    // this.dataService.productSelected = EProduct.FLIGHTS;
    // this.dataStoreService.preloaderImg = EProduct.FLIGHTS;
    this.initService.isInitializedBrand.subscribe((isInitialized) => {
      if (isInitialized) {
        let lang = environment.brands[this.dataStoreService.brand].defaultLanguaje;
        if (environment.brands[this.dataStoreService.brand].config.isLangNavigator) {

          let defaultLang;

          if (window.navigator.language) {
            defaultLang = window.navigator.language;
          } else if (navigator.language) {
            defaultLang = window.language;
          } else if (navigator.userLanguage) {
            defaultLang = window.userLanguage;
          }

          const splitLanguaje = defaultLang.split('-');
          if (splitLanguaje[0]) {
            defaultLang = splitLanguaje[0];
          }

          if (defaultLang === 'es' || defaultLang === 'en') {
            lang = defaultLang;
          }
        }


        this.translate.use(lang);
        this.translate.setDefaultLang(lang);
        this.languageService.setSystemLanguaje(lang);
        this.store.setItemInStorage('lang', lang);
        this.validateRegisterParams();
        setTimeout(() => {
          this.checkGetUserOnUrl();
        });
        if (this.dataService.token) {
          this.smartProfile.getAccountMethod(this.dataService.token);
        }
        GoogleAnalytics.setCurrentScreen('/home');
      }
      switch (this.brand) {
        case 'ncb':
          this.dataService.productSelected = EProduct.REWARDS;
          this.dataStoreService.preloaderImg = EProduct.GENERAL;
          break;
        case 'firstcitizens':
          this.dataService.productSelected = EProduct.HOME;
          this.dataStoreService.preloaderImg = EProduct.GENERAL;
          break;
        case 'myrewards':
          this.dataService.productSelected = EProduct.HOME;
          this.dataStoreService.preloaderImg = EProduct.GENERAL;
          break;
        case 'cibc':
          this.dataService.productSelected = EProduct.HOME;
          this.dataStoreService.preloaderImg = EProduct.GENERAL;
          break;

      }

    });
  }

  changeLanguaje() {
    this.changeLang(this.translate.currentLang);
    this.translate.onLangChange.subscribe((data) => {
      this.changeLang(data.lang);
    });
  }

  changeLang(lang) {
    if (lang === 'es') {
      this.urlsVideos = environment.brands[this.dataStoreService.brand].videosLandingES.salesDeck;
      this.linkWhatsApp = environment.brands[this.dataStoreService.brand].otherLinksES.salesDeck.whatsapp.url;
      this.linkPDF = environment.brands[this.dataStoreService.brand].otherLinksES.salesDeck.pdf_1.url;
      this.imgBgMovilesSales = './assets/images/myrewards-miles/es/bg-mobiles-sales.png';
      this.imgIphoneTops = './assets/images/myrewards-miles/es/iphone-tops.png';
      this.imgIphoneSwitch = './assets/images/myrewards-miles/es/phone-transfer.png';
      this.linkgo2Redeem = environment.brands[this.dataStoreService.brand].otherLinksES.salesDeck.url_go2redeem.url;
      this.lang = false;
      this.imgPower = `./assets/images/myrewards-miles/es/iphone-power.png`;
    } else {
      this.urlsVideos = environment.brands[this.dataStoreService.brand].videosLandingEN.salesDeck;
      this.linkWhatsApp = environment.brands[this.dataStoreService.brand].otherLinksEN.salesDeck.whatsapp.url;
      this.linkPDF = environment.brands[this.dataStoreService.brand].otherLinksEN.salesDeck.pdf_1.url;
      this.imgBgMovilesSales = './assets/images/myrewards-miles/bg-mobiles-sales.png';
      this.imgIphoneTops = './assets/images/myrewards-miles/iphone-tops.png';
      this.imgIphoneSwitch = './assets/images/myrewards-miles/phone-transfer.png';
      this.linkgo2Redeem = environment.brands[this.dataStoreService.brand].otherLinksEN.salesDeck.url_go2redeem.url;
      this.lang = true;
      this.imgPower = `./assets/images/myrewards-miles/iphone-power.png`;
    }
    this.video1.nativeElement.src = this.urlsVideos.one.url;
  }

  /**
   * verificar si el usuario llegó en la url
   */
  private checkGetUserOnUrl() {
    this.route.queryParams.subscribe((params) => {
      if (params.tokenAuth) {
        this.actionEventService.sendCollapseApp(true);
        const tokn = params.tokenAuth.replace(/ /g, '+');
        this.dataService.token = encodeURI(tokn);
        this.dataService.idApplication = params.idApplication;
        this.dataStoreService.channelID = params.idChannel;
        this.languageService.setSystemLanguaje(params.language);
        this.smartProfile.getAccountMethod(this.dataService.token);

        // como abrieron bitacora, ya logueados
        this.ocultadorService.ocultar = true;
        this.router.navigateByUrl('travel');
      }
    });
  }

  /**
 * @method getBrandRoute optiene el brand por parametro para su configuracion
 */

  getBrandRoute() {
    this.route.queryParams.subscribe((params: any) => {
      if (params.brand) {
        this.dataService.saveBrand(params.brand);
        this.actionEventService.sendBrandDynamic(params.brand);
        this.dataService.productSelected = EProduct.HOME;
        this.dataStoreService.preloaderImg = EProduct.GENERAL;
      }
    });
  }

  /**
* @param EProduct.HOME es para colocar el active en el producto seleccionado
* @param EProduct.GENERAL imagen que se le settea a el loaderService
*/

  aboutLoadNav() {
    this.actionEventService.isAboutLoad.subscribe((isAbout) => {
      this.dataService.productSelected = EProduct.HOME;
      this.dataStoreService.preloaderImg = EProduct.GENERAL;
    });
  }

  /**
   * @method validateRegisterParams
   * le envia el obj de registro a LoginComponent para su registro.
   */
  validateRegisterParams() {
    this.loginService.validateRegisterParams();
  }

  // showVideoModal(url_video){ 
  //   this.show_video = true;
  //   this.url = url_video;
  //   const video = this.el.nativeElement.querySelector('.video-modal-content video');
  //   video.autoplay = true;
  //   video.load();
  //   video.muted = true;
  // }
  showVideoModal(num_video) {
    let url_video = this.urlsVideos[num_video].url;
    this.show_video = true;
    this.url = url_video;
    const video = this.el.nativeElement.querySelector('.video-modal-content video');
    video.autoplay = true;
    video.load();
    video.muted = true;
  }

  closeVideoModal() {
    this.show_video = false;
    const video = this.el.nativeElement.querySelector('.video-modal-content video');
    video.autoplay = false;
    video.load();
  }

  openGoToRedeem() {
    window.open(this.linkgo2Redeem, "_blank");
  }

  openGoToPdf() {
    window.open(this.linkPDF, "_blank");
  }

  openSection(number: any){
    if(number == 1){
      let tag = this.el.nativeElement.querySelector("section.first-learn");
      let tag2 = this.el.nativeElement.querySelector("section.second-learn");
      let tagBtn = this.el.nativeElement.querySelector(".row-shoponline .bl-item:first-child button");
      let tagBtn2 = this.el.nativeElement.querySelector(".row-shoponline .bl-item:last-child button");
      tag.classList.toggle('open');
      tag2.classList.remove('open');
      tagBtn.classList.toggle('active');
      tagBtn2.classList.remove('active');
    }else if(number == 2){
      let tag = this.el.nativeElement.querySelector("section.first-learn");
      let tag2 = this.el.nativeElement.querySelector("section.second-learn");
      let tagBtn = this.el.nativeElement.querySelector(".row-shoponline .bl-item:first-child button");
      let tagBtn2 = this.el.nativeElement.querySelector(".row-shoponline .bl-item:last-child button");
      tag2.classList.toggle('open');
      tag.classList.remove('open');
      tagBtn.classList.remove('active');
      tagBtn2.classList.toggle('active');
    }
    
  }

  closeSection(number: any){
    if(number == 1){
      let tag = this.el.nativeElement.querySelector("section.first-learn");
      let tagBtn = this.el.nativeElement.querySelector(".row-shoponline .bl-item:first-child button");
      tag.classList.remove('open');
      tagBtn.classList.remove('active');
    }else if(number == 2){
      let tag2 = this.el.nativeElement.querySelector("section.second-learn");
      let tagBtn2 = this.el.nativeElement.querySelector(".row-shoponline .bl-item:last-child button");
      tag2.classList.remove('open');
      tagBtn2.classList.remove('active'); 
    }
  }

  // openSectionBenefits() {
  //   const tag = this.el.nativeElement.querySelector('.benefits-voyage');
  //   const tagButton = this.el.nativeElement.querySelector('.btn-b');
  //   tag.classList.toggle('open');
  //   tagButton.classList.toggle('active');
  // }



  // closeSectionBenefits() {
  //   const tag = this.el.nativeElement.querySelector('.benefits-voyage');
  //   const tagButton = this.el.nativeElement.querySelector('.btn-b');
  //   tag.classList.remove('open');
  //   tagButton.classList.remove('active');
  // }

  openSectionBenefits(number: any) {
    if(number == 1){
      const tag = this.el.nativeElement.querySelector('.benefits-voyage');
      const tag2 = this.el.nativeElement.querySelector('.bl-tablas');
      const tagBtn = this.el.nativeElement.querySelector('.bl-left .btn-b');
      const tagBtn2 = this.el.nativeElement.querySelector('.bl-right .btn-b');
      tag.classList.toggle('open');
      tag2.classList.remove('open');
      tagBtn.classList.toggle('active');
      tagBtn2.classList.remove('active');
    }else if(number == 2){
      const tag = this.el.nativeElement.querySelector('.benefits-voyage');
      const tag2 = this.el.nativeElement.querySelector('.bl-tablas');
      const tagBtn = this.el.nativeElement.querySelector('.bl-left .btn-b');
      const tagBtn2 = this.el.nativeElement.querySelector('.bl-right .btn-b');
      tag2.classList.toggle('open');
      tag.classList.remove('open');
      tagBtn.classList.remove('active');
      tagBtn2.classList.toggle('active');
    }else{
      const tag = this.el.nativeElement.querySelector('.benefits-voyage');
      const tagBtn = this.el.nativeElement.querySelector('.btn-b');
      tag.classList.toggle('open');
      tagBtn.classList.toggle('active');
    }
      
  }



  closeSectionBenefits(number: any) {
    if(number == 1){
      const tag = this.el.nativeElement.querySelector('.benefits-voyage');
      const tagBtn = this.el.nativeElement.querySelector('.bl-left .btn-b');
      tag.classList.remove('open');
      tagBtn.classList.remove('active');
    }else if(number == 2){
      let tag2 = this.el.nativeElement.querySelector(".bl-tablas");
      let tagBtn2 = this.el.nativeElement.querySelector(".bl-right .btn-b");
      tag2.classList.remove('open');
      tagBtn2.classList.remove('active'); 
    }else{
      const tag = this.el.nativeElement.querySelector('.benefits-voyage');
      const tagBtn = this.el.nativeElement.querySelector('.btn-b');
      tag.classList.remove('open');
      tagBtn.classList.remove('active');
    }
  }


}
