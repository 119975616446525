import { Component, Input, OnInit } from '@angular/core';
import { ConfirmationData } from '../confirmation.model';
import { RentacarAvailabilityOption } from 'src/app/models/new-rentacar/rentacar-availability-option.model';
import { IRange } from '../../searched/models/searched.model';
import { formatDate } from '@angular/common';
import { BehaviorSubject } from 'rxjs';
import { locale } from 'moment';

@Component({
  selector: 'app-confirmation-car',
  templateUrl: './confirmation-car.component.html',
  styleUrls: ['./confirmation-car.component.scss']
})
export class ConfirmationCarComponent implements OnInit {

  private get translationsDate() {
    return this.translations$.value['DATE'] || [];
}



  @Input() confirmationData: ConfirmationData;
  cars: RentacarAvailabilityOption[];
  constructor(
    private translations$: BehaviorSubject<any>,
    private language$: BehaviorSubject<any>) { }

  ngOnInit() {
    this.cars = [this.confirmationData.product as RentacarAvailabilityOption];
  }

  getPickUpDate(pickUpDate){
    return {
      start: formatDate(
        pickUpDate,
        this.translationsDate['format_weekday'],
        locale(this.language$.value)
      ).toString()
    };
  }
  getDropOffDate(dropOffDate){
    return {
      start: formatDate(
        dropOffDate,
        this.translationsDate['format_weekday'],
        locale(this.language$.value)
      ).toString()
    };
  }
}
