import {Component, Input, OnInit} from '@angular/core';
import { HotelCardComponent } from '../hotel-card.component';

@Component({
  selector: 'app-hotel-detail',
  templateUrl: './hotel-detail.component.html',
  styleUrls: ['./hotel-detail.component.scss']
})
export class HotelDetailComponent implements OnInit {

  room: any;
  refundable;
  iconClass;
  @Input() style = 1;
  constructor(private cardComponent: HotelCardComponent) {
  }

  ngOnInit() {
    const card = this.cardComponent.hotel;

    // TODO: Agosto 27 2020 => Validar que estos textos esten localizados para que puedan cambiar entre idiomas, se identifico que se encontraban quemados en español.
    this.room = card.Rooms[0];
    this.refundable = (card.Refundable === 'Y') ? 'Refundable' // 'Reembolsable'
      : (card.Refundable === 'N') ? 'Non Refundable' // 'No Reembolsable'
        : 'Check the cancellation policies in detail' // 'Consultar las políticas de cancelacion en el detalle';
    this.iconClass = (card.Refundable === 'Y') ? 'icon-tick-rounded'
                            : (card.Refundable === 'N') ? 'icon-alert'
                            : 'icon-alert-rounded';
  }


}
