<footer>
  <div class="container" *ngIf="brand != 'apap'">
    <div class="foot">
      <img src="./assets/images/{{brand}}-miles/logo-footer.png" alt="">
      <div class="bl-text bl-text--icon">
        <span class="" innerHTML="{{'LANDING.'+brand+'.FOOTER.all_rights' | translate:{ year: fullYear } }}"></span>
        <a class="underline_none" innerHTML="{{'LANDING.'+brand+'.FOOTER.all_rights_click_here' | translate}}"  (click)="openUrl('https://www.wearenovae.com/')" target="_blank"></a>
        <span class="" innerHTML="{{'LANDING.'+brand+'.FOOTER.all_rights2' | translate}}"></span>

        <span class="text-program" innerHTML="{{'LANDING.'+brand+'.FOOTER.learn_more' | translate}}"></span>
      </div>
      
      <div class="bl-text">
        <span class="" innerHTML="{{'LANDING.'+brand+'.FOOTER.terms' | translate}}"></span>
        <a class="" (click)="openUrl(loadUrl())" innerHTML="{{'LANDING.'+brand+'.FOOTER.term_click_here' | translate}}"></a>
        <span class="" innerHTML="{{'LANDING.'+brand+'.FOOTER.terms2' | translate}}"></span>
      </div>



<!-- 
ENG: M(logo)© 2022 novae - all rights reserved.              Program developed and managed by Miles by novae. 
Terms & Conditions apply.

ESP: M(logo) Todos los derechos reservados © 2022 por novae. Programa desarrollado y administrado por  Miles by novae. 
Aplican Términos y Condiciones.


      <span *ngIf="brand !== 'cibc'" [hidden]="brand === 'apap' || brand === 'bancoindustrial'"
        innerHTML="{{'LANDING.'+brand+'.FOOTER.terms' | translate}}">{{'LANDING.'+brand+'.FOOTER.terms' | translate}}
      </span>
      <a *ngIf="brand !== 'cibc' && brand !== 'apap'" [href]="urlData" (click)="loadUrl()" target="_blank"
        innerHTML="{{'LANDING.'+brand+'.FOOTER.click_here' | translate}}">{{'LANDING.'+brand+'.FOOTER.click_here' |
        translate}}</a>

      <span *ngIf="brand == 'cibc'"
        innerHTML="{{'LANDING.'+brand+'.FOOTER.terms'| translate : { urlData: urlData } }}">{{'LANDING.'+brand+'.FOOTER.terms'
        |
        translate}}</span>

      <div *ngIf="brand === 'apap' || brand === 'bancoindustrial'" class="tc-apap">

        <span innerHTML="{{'LANDING.'+brand+'.FOOTER.terms' | translate}}"></span>
        <span>
          {{'LANDING.'+brand+'.FOOTER.more_info' | translate}}
          <a [href]="urlData" (click)="loadUrl()" target="_blank">{{'LANDING.'+brand+'.FOOTER.click_here' |
            translate}}</a>
        </span>
      </div>
 -->

    </div>
    <span innerHTML="{{'LANDING.'+brand+'.FOOTER.all_brand' | translate}}"></span>
    <span *ngIf="brand === 'cibc'" innerHTML="{{'LANDING.'+brand+'.FOOTER.info' | translate}}"></span>

    <div *ngIf="brand == 'myrewards'" class="help-link">
      <hr>
      <a pageScroll href="#help"  (click)="goToHelp()">Help Center - FAQs</a>
    </div>
    


  </div>
    
  <div class="container" *ngIf="brand == 'apap'">
    <div class="foot">

      <img src="./assets/images/{{brand}}-miles/logo-footer.png" alt="">

      <div class="bl-text">
        <span class="" innerHTML="{{'LANDING.'+brand+'.FOOTER.terms' | translate}}"></span>
        <a class="" (click)="openUrl(loadUrl())" innerHTML="{{'LANDING.'+brand+'.FOOTER.term_click_here' | translate}}"></a>
        <span class="" innerHTML="{{'LANDING.'+brand+'.FOOTER.terms2' | translate}}"></span>
      </div>

      <span innerHTML="{{'LANDING.'+brand+'.FOOTER.more_info' | translate}}"></span>

      <span innerHTML="{{'LANDING.'+brand+'.FOOTER.all_brand' | translate}}"></span>
      
      <div class="bl-text bl-text--icon">
        <span class="" innerHTML="{{'LANDING.'+brand+'.FOOTER.all_rights' | translate}}"></span>
        <a class="underline_none" innerHTML="{{'LANDING.'+brand+'.FOOTER.all_rights_click_here' | translate}}"  (click)="openUrl('https://www.wearenovae.com/')" target="_blank"></a>
        <span class="" innerHTML="{{'LANDING.'+brand+'.FOOTER.all_rights2' | translate}}"></span>
  
        <span class="text-program" innerHTML="{{'LANDING.'+brand+'.FOOTER.learn_more' | translate}}"></span>
      </div>

    </div>
  </div>
</footer>