<!--Checkbox Texto Filters-->

<div *ngIf="filterGroupDataOptions && filterGroupData.groupType === 1"
  class="select-check">
  <!--  <form [formGroupName]="filterGroupData.groupName">-->
  <p class="filtersTitle">
    {{filterGroupData.groupTitle | translate}}
  </p>
  <ng-container *ngFor="let option of filterGroupDataOptions">
    <mat-checkbox
      *ngIf="isAvailableOption(filterGroupData.groupName, option.optionCode)"
      [checked]="option.optionChecked || false"
      [value]="option.optionsId"
      (change)="doFiltering(filterGroupData.groupName, filterGroupData.groupType, $event.checked, option.optionsId, $event, option.optionValue)">
      <!-- muestra la opción traducida (puede tener parámetros) -->
      <ng-container
        *ngIf="option.optionValueTranslate else optionValueNoTranslate">
        {{option.optionValueTranslate.text | translate: option.optionValueTranslate.params}}
      </ng-container>
      <!-- muestra la opción tal cual fue seteada -->
      <ng-template #optionValueNoTranslate>
        {{option.optionValue}}
      </ng-template>
    </mat-checkbox>
  </ng-container>
  <!--  </form>-->
</div>

<!--Checkbox Iconos Filters-->
<div *ngIf="filterGroupDataOptions && filterGroupData.groupType === 2"
  class="select-check">
  <h1 class="filtersTitle">{{filterGroupData.groupTitle | translate}}
  </h1>
  <div *ngFor="let option of filterGroupDataOptions">
    <ng-container
      *ngIf="isAvailableOption(filterGroupData.groupName, option.optionValue)">

      <mat-checkbox #optionsIcon [id]="option.optionValue"
        [checked]="option.optionChecked || false"
        [value]="option.optionsId"
        (change)="doFiltering(filterGroupData.groupName, filterGroupData.groupType, $event.checked, option.optionsId, $event, option.optionValue)">
      </mat-checkbox>
      <span class="checkboxLabel"><i [class]="filterGroupData.iconImg"
          aria-hidden="true"
          *ngFor="let Star of option.optionValue | numberToArrBis:{}"></i></span>
    </ng-container>
  </div>
</div>

<!--Slider Filters-->
<div *ngIf="filterGroupDataOptions && filterGroupData.groupType === 3"
  class="range">
  <!--Numbers-->
  <div *ngIf="filterGroupData.optionType === 0">
    <p class="filtersTitle">{{filterGroupData.groupTitle | translate}}
    </p>
    <ng5-slider [(value)]="minValue" [(highValue)]="maxValue"
      [options]="options"
      (userChangeEnd)="doFiltering(filterGroupData.groupName, filterGroupData.groupType, true, [], $event)">
    </ng5-slider>
  </div>
  <!--Dates RECIBE MINUTOS, MUESTRA HH:MM-->
  <div *ngIf="filterGroupData.optionType === 1">
    <p class="filtersTitle">{{filterGroupData.groupTitle | translate}}
    </p>
    <ng5-slider [(value)]="minValue" [(highValue)]="maxValue"
      [options]="options"
      (userChangeEnd)="doFiltering(filterGroupData.groupName, filterGroupData.groupType, true, [], $event)">
    </ng5-slider>
  </div>
  <!--Price-->
  <div *ngIf="filterGroupData.optionType === 2">
    <p class="filtersTitle">{{filterGroupData.groupTitle | translate}}
    </p>
    <ng5-slider [(value)]="minValue" [(highValue)]="maxValue"
      [options]="options"
      (userChangeEnd)="doFiltering(filterGroupData.groupName, filterGroupData.groupType, true, [], $event)">
    </ng5-slider>
  </div>
</div>

<!--Text Filter Autocomplete-->
<div *ngIf="filterGroupDataOptions && filterGroupData.groupType === 4"
  class="autocompletar-input">
  <p class="filtersTitle">{{filterGroupData.groupTitle | translate}}
  </p>
  <ng-select
    [items]= "filterService.autocompleteOptions"
    bindLabel="optionValue"
    bindValue="optionsId"
    [placeholder]="placeholderBuscar"
    (change)="doFiltering(filterGroupData.groupName, filterGroupData.groupType, true, [], $event)"
  >
    <ng-template ng-option-tmp let-item="item"
      let-search="searchTerm">
      <div>
        <span>{{item.optionValue}}</span>
      </div>
    </ng-template>
  </ng-select>
</div>
