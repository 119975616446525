<div id="vuelos" name="search-flight" class="filtro-flotante tab-panel">
    <div class="uno">
        <form [formGroup]="form" novalidate style="display: flex;">
            <div class="origen-destino">
                <div class="origenes-in bl-salida">
                    <ng-select formControlName="origen" class="autocompleteSearch" id="origen" [items]="allCities" bindLabel="name" groupBy="typeString" [searchFn]="returnTrue" dropdownPosition="bottom" placeholder="{{placeholderOrigin}}" [virtualScroll]="true" (search)="onSearch($event)"
                        [notFoundText]="notFoundText" #origen (change)="origen.blur()">
                        <ng-template ng-option-tmp let-item="item" let-search="searchTerm">
                            <div>
                                <span>{{item.name}}</span>
                            </div>
                        </ng-template>
                    </ng-select>
                    <div class="alert_red" *ngIf="originNotSelected" innerHTML="{{'SEARCH_FLIGHTS.please_indicate_origin'|translate}}">
                        Please indicate the origin of your trip
                    </div>
                    <div class="alert_red" *ngIf="validatorOriginDestiniDif" innerHTML="{{'SEARCH_FLIGHTS.must_be_different'|translate}}">
                        Origin and destination must be different
                    </div>
                </div>
                <div class="ciudades icon-invert" (click)="invertFlightSelection()">
                    <span class="icono-invertir"></span>
                </div>
                <div class="origenes-in bl-llegada">
                    <ng-select formControlName="destino" class="autocompleteSearch" id="destino" [items]="allCities" bindLabel="name" groupBy="typeString" [searchFn]="returnTrue" dropdownPosition="bottom" placeholder="{{placeholderDestination}}" [virtualScroll]="true" (search)="onSearch($event)"
                        [notFoundText]="notFoundText" #destino (change)="destino.blur()">
                        <ng-template ng-option-tmp let-item="item" let-search="searchTerm">
                            <div>
                                <span>{{item.name}}</span>
                            </div>
                        </ng-template>
                    </ng-select>
                    <div class="alert_red" *ngIf="destinationNotSelected" innerHTML="{{'SEARCH_FLIGHTS.please_indicate_destination'|translate}}">
                        Please indicate the destination of your trip
                    </div>
                    <div class="alert_red" *ngIf="validatorOriginDestiniDif" innerHTML="{{'SEARCH_FLIGHTS.must_be_different'|translate}}">
                        Origin and destination must be different
                    </div>
                </div>
            </div>
            <div class="bl-input-date">
                <div class="tramoClasss">
                    <label innerHTML="{{'SEARCH_FLIGHTS.journey'|translate}}">
            Journey
          </label>
                    <ng-select class="tramoClass" [searchable]="false" [clearable]="false" labelForId="Ida y vuelta" formControlName="tramo" (change)="tramoFn($event)">
                        <ng-option value="oneWay" [innerHTML]="'SEARCH_FLIGHTS.one_way'|translate">
                            One way
                        </ng-option>
                        <ng-option value="idaVuelta" [innerHTML]="'SEARCH_FLIGHTS.round_trip'|translate">
                            Round trip
                        </ng-option>
                    </ng-select>
                </div>

                <div class="fecha-ida">
                    <label [innerHTML]="'SEARCH_FLIGHTS.date'|translate">
            Date
          </label>
                    <div class="fecha-inputs" *ngIf="tramoCalendar === false">
                        <div class="bl-input-icon">
                            <input #dateStart name="dateStart" [(ngModel)]="selected" class="dateSelect form-control" (openedChange)="openedChangeStart($event)" type="text" appCalendarSearch readonly formControlName="dateSelect" emptyWeekRowClass="empty-week" placeholder="Select Date"
                                [linkedCalendars]="true" [showDropdowns]="true" [autoApply]="true" [locale]="locale" [singleDatePicker]="true" [minDate]="minDate" [dateShowTogether]="false" [isStart]="true" />
                        </div>
                        <div class="bl-input-icon icon-last">
                            <input #dateEnd name="dateEnd" [(ngModel)]="selected" class="dateSelect form-control" (openedChange)="openedChangeEnd($event)" type="text" appCalendarSearch readonly formControlName="dateSelect" emptyWeekRowClass="empty-week" placeholder="Select Date"
                                [linkedCalendars]="true" [showDropdowns]="true" [autoApply]="true" [locale]="locale" [singleDatePicker]="true" [minDate]="minDate" [dateShowTogether]="false" [isEnd]="true" />
                        </div>
                    </div>

                    <div class="fecha-inputs" *ngIf="tramoCalendar === true ">
                        <div class="bl-input-icon bl-oneway ">
                            <input [(ngModel)]="selected" class="dateSelect form-control" type="text" ngxDaterangepickerMd readonly formControlName="dateSelect" emptyWeekRowClass="empty-week" lastDayOfPreviousMonthClass="disabled-previous-day" firstDayOfNextMonthClass="disabled-nex-day"
                                placeholder="{{palceholderSelectDate}}" [showDropdowns]="true" [linkedCalendars]="true" [autoApply]="true" [locale]="locale" [singleDatePicker]="true" [minDate]="minDate" />
                        </div>
                    </div>
                    <div class="alert_red" *ngIf="dateNotSelected" [innerHTML]="'SEARCH_FLIGHTS.indicate_start_end'|translate">
                        You must indicate the start and end date of your trip
                    </div>
                </div>

                <div class="pasajero" (click)="showPassengerFormFn()" [attr.aria-expanded]="!showPassengerForm">
                    <label [innerHTML]="'SEARCH_FLIGHTS.passengers_and_class'|translate">
            Passengers and Class
          </label>
                    <input readonly class="pasangerForm" [placeholder]="placeholderPassenger" attr-open-app-pasajeros="true" />
                    <app-pasajeros [ngbCollapse]="showPassengerForm">
                    </app-pasajeros>
                    <div class="alert_red" *ngIf="passengersNotSelected" [innerHTML]="'SEARCH_FLIGHTS.number_of_passengers'|translate">
                        You must indicate the number of passengers
                    </div>
                    <div class="alert_red" *ngIf="dateNotSelected" [innerHTML]="'SEARCH_FLIGHTS.indicate_start_end'|translate">
                        You must indicate the start and end date of your trip
                    </div>
                </div>
            </div>
        </form>
        <div class="buscar" (click)="search()">
            <span [innerHTML]="'SEARCH_FLIGHTS.search'|translate">
        Search
      </span>
        </div>
    </div>
</div>