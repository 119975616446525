import { CarouselComponent } from './carousel/carousel.component';
import { PipesModule } from './../../pipes/pipes.module';
import { GalleryCarouselComponent } from './gallery-carousel.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SwiperModule } from 'ngx-swiper-wrapper';



@NgModule({
  declarations: [
    GalleryCarouselComponent,
    CarouselComponent
  ],
  exports: [
    GalleryCarouselComponent,
    CarouselComponent
  ],
  imports: [
    CommonModule,
    SwiperModule,
    PipesModule
  ]
})
export class GalleryCarouselModule { }
