import { Component, OnInit } from '@angular/core';
import { DataStoreService } from 'src/app/services/data-store.service';

@Component({
  selector: 'app-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss']
})
export class TabsComponent implements OnInit {

  tabOne = [
    {
      // tslint:disable-next-line:max-line-length
      lead: 'DROP_DOWN_BENEFIST.TAB_FLYCARE.boddy',
      tituloTop: 'DROP_DOWN_BENEFIST.TAB_FLYCARE.cancel_title_1',
      titulo: 'DROP_DOWN_BENEFIST.TAB_FLYCARE.cancel_title_1',
      precio: 'DROP_DOWN_BENEFIST.TAB_FLYCARE.cancel_price_1',
      parrafo: "DROP_DOWN_BENEFIST.TAB_FLYCARE.cancel_descript_1",
      img: '../../../assets/images/apap-miles/tab1-1.png'
    },
    {
      tituloTop: 'DROP_DOWN_BENEFIST.TAB_FLYCARE.interrupt_title_1',
      titulo: 'DROP_DOWN_BENEFIST.TAB_FLYCARE.interrupt_title_1',
      precio: 'DROP_DOWN_BENEFIST.TAB_FLYCARE.cancel_price_1',
      parrafo: "DROP_DOWN_BENEFIST.TAB_FLYCARE.interrupt_descript_1",
      parrafoDos: "DROP_DOWN_BENEFIST.TAB_FLYCARE.interrupt_descript_2",
      parrafoTres: "DROP_DOWN_BENEFIST.TAB_FLYCARE.interrupt_descript_3",
      img: '../../../assets/images/apap-miles/tab1-2.png'
    },
    {
      tituloTop: 'DROP_DOWN_BENEFIST.TAB_FLYCARE.lost_connection_title_1',
      titulo: 'DROP_DOWN_BENEFIST.TAB_FLYCARE.lost_connection_title_1',
      precio: 'DROP_DOWN_BENEFIST.TAB_FLYCARE.lost_connection_price_1',
      parrafo: "DROP_DOWN_BENEFIST.TAB_FLYCARE.lost_connection_descript_1",
      img: '../../../assets/images/apap-miles/tab1-3.png'
    },
    {
      tituloTop: 'DROP_DOWN_BENEFIST.TAB_FLYCARE.flight_delay_title_1',
      titulo: 'DROP_DOWN_BENEFIST.TAB_FLYCARE.flight_delay_title_2',
      precio: 'DROP_DOWN_BENEFIST.TAB_FLYCARE.flight_delay_price_1',
      parrafo: "DROP_DOWN_BENEFIST.TAB_FLYCARE.flight_delay_descript_1",
      img: '../../../assets/images/apap-miles/tab1-4.png'
    },
    {
      tituloTop: 'DROP_DOWN_BENEFIST.TAB_FLYCARE.lost_luggage_title_1',
      titulo: 'DROP_DOWN_BENEFIST.TAB_FLYCARE.lost_luggage_title_1',
      precio: 'DROP_DOWN_BENEFIST.TAB_FLYCARE.lost_luggage_price_1',
      // precioDos: 'DROP_DOWN_BENEFIST.TAB_FLYCARE.lost_luggage_price_2',
      parrafo: "DROP_DOWN_BENEFIST.TAB_FLYCARE.lost_luggage_descript_1",
      parrafoDos: "DROP_DOWN_BENEFIST.TAB_FLYCARE.lost_luggage_descript_2",
      parrafoTres: "DROP_DOWN_BENEFIST.TAB_FLYCARE.lost_luggage_descript_3",
      img: '../../../assets/images/apap-miles/tab1-5.png'
    }
  ];
  tabTwo = [
    {
      lead: 'DROP_DOWN_BENEFIST.TAB_STAYCARE.boddy',
      tituloTop: 'DROP_DOWN_BENEFIST.TAB_STAYCARE.medical_expenses_title_1',
      titulo: 'DROP_DOWN_BENEFIST.TAB_STAYCARE.medical_expenses_title_1',
      precio: 'DROP_DOWN_BENEFIST.TAB_STAYCARE.medical_expenses_price_1',
      parrafoUno: 'DROP_DOWN_BENEFIST.TAB_STAYCARE.medical_expenses_descript_1',
      // parrafo: "DROP_DOWN_BENEFIST.TAB_STAYCARE.medical_expenses_descript_1",
      parrafoDos: "DROP_DOWN_BENEFIST.TAB_STAYCARE.medical_expenses_descript_2",
      parrafoTres: "DROP_DOWN_BENEFIST.TAB_STAYCARE.medical_expenses_descript_3",
      parrafoCuatro: "DROP_DOWN_BENEFIST.TAB_STAYCARE.medical_expenses_descript_4",
      parrafoCinco: "DROP_DOWN_BENEFIST.TAB_STAYCARE.medical_expenses_descript_5",
      parrafoSeis: "DROP_DOWN_BENEFIST.TAB_STAYCARE.medical_expenses_descript_6",
      parrafoSiete: "DROP_DOWN_BENEFIST.TAB_STAYCARE.medical_expenses_descript_7",
      parrafoOcho: "DROP_DOWN_BENEFIST.TAB_STAYCARE.medical_expenses_descript_8",
      parrafoNueve: "DROP_DOWN_BENEFIST.TAB_STAYCARE.medical_expenses_descript_9",
      parrafoDiez: "DROP_DOWN_BENEFIST.TAB_STAYCARE.medical_expenses_descript_10",
      parrafoOnce: "DROP_DOWN_BENEFIST.TAB_STAYCARE.medical_expenses_descript_11",
      parrafoDoce: "DROP_DOWN_BENEFIST.TAB_STAYCARE.medical_expenses_descript_12",
      img: '../../../assets/images/apap-miles/tab2-1.png'
    },
    {
      tituloTop: 'DROP_DOWN_BENEFIST.TAB_STAYCARE.accidental_death_title_1',
      titulo: 'DROP_DOWN_BENEFIST.TAB_STAYCARE.accidental_death_title_1',
      precio: 'DROP_DOWN_BENEFIST.TAB_STAYCARE.accidental_death_price_1',
      parrafo: "DROP_DOWN_BENEFIST.TAB_STAYCARE.accidental_death_descript_1",
      img: '../../../assets/images/apap-miles/tab1-6.png'
    },
    {
      tituloTop: 'DROP_DOWN_BENEFIST.TAB_STAYCARE.evacuation_title_1',
      titulo: 'DROP_DOWN_BENEFIST.TAB_STAYCARE.evacuation_title_1',
      precio: 'DROP_DOWN_BENEFIST.TAB_STAYCARE.evacuation_price_1',
      parrafo: "DROP_DOWN_BENEFIST.TAB_STAYCARE.evacuation_descript_1",
      img: '../../../assets/images/apap-miles/tab1-7.png'
    },
  ];

  tabTrhee = [
    {
      lead: 'DROP_DOWN_BENEFIST.TAB_OTHER_BENEFITS.boddy',
      tituloTop: 'DROP_DOWN_BENEFIST.TAB_OTHER_BENEFITS.protection_title_1',
      titulo: 'DROP_DOWN_BENEFIST.TAB_OTHER_BENEFITS.protection_title_1',
      precio: 'DROP_DOWN_BENEFIST.TAB_OTHER_BENEFITS.protection_price_1',
      parrafo: "DROP_DOWN_BENEFIST.TAB_OTHER_BENEFITS.protection_descript_1",
      img: '../../../assets/images/apap-miles/tab3-3.png'
    },
    {
      tituloTop: 'DROP_DOWN_BENEFIST.TAB_OTHER_BENEFITS.stole_title_1',
      titulo: 'DROP_DOWN_BENEFIST.TAB_OTHER_BENEFITS.stole_title_1',
      precio: 'DROP_DOWN_BENEFIST.TAB_OTHER_BENEFITS.stole_price_1',
      parrafo: "DROP_DOWN_BENEFIST.TAB_OTHER_BENEFITS.stole_descript_1",
      img: '../../../assets/images/apap-miles/tab3-5.png'
    },
    {
      tituloTop: 'DROP_DOWN_BENEFIST.TAB_OTHER_BENEFITS.device_title_1',
      titulo: 'DROP_DOWN_BENEFIST.TAB_OTHER_BENEFITS.device_title_1',
      precio: 'DROP_DOWN_BENEFIST.TAB_OTHER_BENEFITS.device_price_1',
      parrafo: "DROP_DOWN_BENEFIST.TAB_OTHER_BENEFITS.device_descript_1",
      img: '../../../assets/images/apap-miles/tab3-1.png'
    },
    {
      tituloTop: 'DROP_DOWN_BENEFIST.TAB_OTHER_BENEFITS.app_mobile_title_1',
      titulo: 'DROP_DOWN_BENEFIST.TAB_OTHER_BENEFITS.app_mobile_title_1',
      precio: 'DROP_DOWN_BENEFIST.TAB_OTHER_BENEFITS.app_mobile_price_1',
      parrafo: "DROP_DOWN_BENEFIST.TAB_OTHER_BENEFITS.app_mobile_descript_1",
      img: '../../../assets/images/apap-miles/tab3-2.png'
    },

  ];
  constructor(
    private dataStore: DataStoreService,
  ) {
  }

  ngOnInit(): void {
    if (this.dataStore.brand != 'cibc') {
      this.tabTrhee.push(
        {
          tituloTop: 'DROP_DOWN_BENEFIST.TAB_OTHER_BENEFITS.risk_protection_title_1',
          titulo: 'DROP_DOWN_BENEFIST.TAB_OTHER_BENEFITS.risk_protection_title_1',
          precio: 'DROP_DOWN_BENEFIST.TAB_OTHER_BENEFITS.risk_protection_price_1',
          parrafo: "DROP_DOWN_BENEFIST.TAB_OTHER_BENEFITS.risk_protection_descript_1",
          img: '../../../assets/images/apap-miles/tab3-4.png'
        }
      );
    }
  }
}
