<div wmAnimate="bounceInUp" aos>
    <mat-tab-group mat-stretch-tabs mat-align-tabs="center" class="tab-externo">
        <mat-tab>

            <ng-template mat-tab-label>
                <span class="selector-full" innerHTML="{{'DROP_DOWN_BENEFIST.TAB_FLYCARE.title' | translate}}">FLYCARE</span>
            </ng-template>
            <mat-tab-group class="tab-hidden">
                <mat-tab *ngFor="let tabInt of tabOne">
                    <p class="parrafo-20-light" [innerHTML]="tabInt.lead | translate"></p>
                </mat-tab>
            </mat-tab-group>
            <mat-tab-group class="tab-interno primer-tab" mat-align-tabs="center">
                <mat-tab *ngFor="let tabInt of tabOne">
                    <ng-template mat-tab-label>
                        <span class="tab" [ngClass]="{
                            'avion-cerrar':tabInt.tituloTop === 'DROP_DOWN_BENEFIST.TAB_FLYCARE.cancel_title_1',
                            'avion-alerta':tabInt.tituloTop === 'DROP_DOWN_BENEFIST.TAB_FLYCARE.interrupt_title_1',
                            'avion-flechas':tabInt.tituloTop === 'DROP_DOWN_BENEFIST.TAB_FLYCARE.lost_connection_title_1',
                            'avion-reloj':tabInt.tituloTop === 'DROP_DOWN_BENEFIST.TAB_FLYCARE.flight_delay_title_1',
                            'maletas':tabInt.tituloTop === 'DROP_DOWN_BENEFIST.TAB_FLYCARE.lost_luggage_title_1'
                            }">{{tabInt.tituloTop | translate}}</span>
                    </ng-template>
                    <div class="content-tab">
                        <div class="texto-tab text-left">
                            <span class="titulo-tab" innerHTML="{{tabInt.titulo | translate}}"></span>
                            <span class="precio-tab" innerHTML="{{tabInt.precio | translate}}"><br>
                                <span *ngIf="tabInt.precioDos!=null && tabInt.precioDos!=''">
                                    <strong>{{tabInt.precioDos | translate}}</strong>
                                </span>
                            </span>
                            <p class="parrafo-tab" innerHTML="{{tabInt.parrafo | translate}}"></p>
                            <p class="parrafo-tab" *ngIf="tabInt.parrafoDos!=null && tabInt.parrafoDos!=''" innerHTML="{{tabInt.parrafoDos | translate}}"></p>
                            <p class="parrafo-tab" *ngIf="tabInt.parrafoTres!=null && tabInt.parrafoTres!=''" innerHTML="{{tabInt.parrafoTres | translate}}"></p>
                        </div>
                        <figure class="imagen-tabs"> <img src="{{tabInt.img}}" alt=""></figure>
                    </div>
                </mat-tab>
            </mat-tab-group>
        </mat-tab>

        <mat-tab>
            <ng-template mat-tab-label>
                <span class="selector-full" innerHTML="{{'DROP_DOWN_BENEFIST.TAB_STAYCARE.title' | translate}}">STAYCARE</span>
            </ng-template>
            <mat-tab-group class="tab-hidden">
                <mat-tab *ngFor="let tabInt of tabTwo">
                    <p class="parrafo-20-light" [innerHTML]="tabInt.lead | translate"></p>
                </mat-tab>
            </mat-tab-group>
            <mat-tab-group class="tab-interno second-tabs" mat-align-tabs="center">
                <mat-tab *ngFor="let tabInt of tabTwo">
                    <ng-template mat-tab-label>
                        <span class="tab" [ngClass]="{
                            'enfermera':tabInt.tituloTop === 'DROP_DOWN_BENEFIST.TAB_STAYCARE.medical_expenses_title_1',
                            'anillo':tabInt.tituloTop === 'DROP_DOWN_BENEFIST.TAB_STAYCARE.accidental_death_title_1',
                            'avion':tabInt.tituloTop === 'DROP_DOWN_BENEFIST.TAB_STAYCARE.evacuation_title_1'
                            }">{{tabInt.tituloTop | translate}}</span>
                    </ng-template>
                    <div class="content-tab tab-interno">
                        <div class="texto-tab text-left">
                            <span class="titulo-tab" innerHTML="{{tabInt.titulo | translate}}"></span>
                            <span class="precio-tab" innerHTML="{{tabInt.precio | translate}}">
                               
                            </span>
                            <p class="parrafo-tab" *ngIf="tabInt.parrafo!=null && tabInt.parrafo!=''" innerHTML="{{tabInt.parrafo | translate}} "></p>
                            <ul class="lista-tab" *ngIf="tabInt.titulo === 'DROP_DOWN_BENEFIST.TAB_STAYCARE.medical_expenses_title_1' ">
                                <li class="item-lista" *ngIf="tabInt.parrafoUno!=null && tabInt.parrafoUno!=''" innerHTML="{{tabInt.parrafoUno | translate}}"></li>
                                <li class="item-lista" *ngIf="tabInt.parrafoDos!=null && tabInt.parrafoDos!=''" innerHTML="{{tabInt.parrafoDos | translate}}"></li>
                                <li class="item-lista" *ngIf="tabInt.parrafoTres!=null && tabInt.parrafoTres!=''" innerHTML="{{tabInt.parrafoTres | translate}}"></li>
                                <li class="item-lista" *ngIf="tabInt.parrafoCuatro!=null && tabInt.parrafoCuatro!=''" innerHTML="{{tabInt.parrafoCuatro | translate}}"></li>
                                <li class="item-lista" *ngIf="tabInt.parrafoCinco!=null && tabInt.parrafoCinco!=''" innerHTML="{{tabInt.parrafoCinco | translate}}"></li>
                                <li class="item-lista" *ngIf="tabInt.parrafoSeis!=null && tabInt.parrafoSeis!=''" innerHTML="{{tabInt.parrafoSeis | translate}}"></li>
                                <li class="item-lista" *ngIf="tabInt.parrafoSiete!=null && tabInt.parrafoSiete!=''" innerHTML="{{tabInt.parrafoSiete | translate}}"></li>
                                <li class="item-lista" *ngIf="tabInt.parrafoOcho!=null && tabInt.parrafoOcho!=''" innerHTML="{{tabInt.parrafoOcho | translate}}"></li>
                                <li class="item-lista" *ngIf="tabInt.parrafoNueve!=null && tabInt.parrafoNueve!=''" innerHTML="{{tabInt.parrafoNueve | translate}}"></li>
                                <li class="item-lista" *ngIf="tabInt.parrafoDiez!=null && tabInt.parrafoDiez!=''" innerHTML="{{tabInt.parrafoDiez | translate}}"></li>
                                <li class="item-lista" *ngIf="tabInt.parrafoOnce!=null && tabInt.parrafoOnce!=''" innerHTML="{{tabInt.parrafoOnce | translate}}"></li>
                                <p class="parrafo-tab" *ngIf="tabInt.parrafoDoce!=null && tabInt.parrafoDoce!=''" innerHTML="{{tabInt.parrafoDoce | translate}}"></p>                                
                            </ul>
                        </div>
                        <figure class="imagen-tabs"> <img src="{{tabInt.img}}" alt=""></figure>
                    </div>
                </mat-tab>
            </mat-tab-group>

        </mat-tab>

        <mat-tab>
            <ng-template mat-tab-label>
                <span class="selector-full" innerHTML="{{'DROP_DOWN_BENEFIST.TAB_OTHER_BENEFITS.title' | translate}}">OTHER BENEFITS</span>
            </ng-template>
            <mat-tab-group class="tab-hidden">
                <mat-tab *ngFor="let tabInt of tabTrhee">

                    <p class="parrafo-20-light" [innerHTML]="tabInt.lead | translate"></p>
                </mat-tab>
            </mat-tab-group>
            <mat-tab-group class="tab-interno" mat-align-tabs="center">
                <mat-tab *ngFor="let tabInt of tabTrhee">
                    <ng-template mat-tab-label>
                        <span class="tab" [ngClass]="{
                            'celular':tabInt.tituloTop === 'DROP_DOWN_BENEFIST.TAB_OTHER_BENEFITS.protection_title_1',
                            'cajero':tabInt.tituloTop === 'DROP_DOWN_BENEFIST.TAB_OTHER_BENEFITS.stole_title_1',
                            'celular-compu':tabInt.tituloTop === 'DROP_DOWN_BENEFIST.TAB_OTHER_BENEFITS.device_title_1',
                            'billetera':tabInt.tituloTop === 'DROP_DOWN_BENEFIST.TAB_OTHER_BENEFITS.app_mobile_title_1',
                            'escudo':tabInt.tituloTop === 'DROP_DOWN_BENEFIST.TAB_OTHER_BENEFITS.risk_protection_title_1'
                            }">{{tabInt.tituloTop | translate}}</span>
                    </ng-template>
                    <div class="content-tab">
                        <div class="texto-tab text-left">
                            <span class="titulo-tab">{{tabInt.titulo | translate}}</span>
                            <span class="precio-tab">{{tabInt.precio | translate}} <br>
                            </span>
                            <p class="parrafo-tab">{{tabInt.parrafo | translate}}</p>
                        </div>
                        <figure class="imagen-tabs"> <img src="{{tabInt.img}}" alt=""></figure>
                    </div>

                </mat-tab>
            </mat-tab-group>
        </mat-tab>

    </mat-tab-group>
</div>